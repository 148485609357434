import { storageApi } from '@seeeverything/ui.util/src/storage/api.ts';
import { TenantConfiguration } from '@seeeverything/ui.util/src/redux/tenant/index.ts';
import { ModuleType } from '@seeeverything/ui.util/src/types.ts';

/**
 * Sets the initial value for the `module` key in local storage.
 * Optionally clears any existing values if specified.
 * Saves the resulting value in local storage for the next session.
 */
export const initializeModuleStorage = (
  tenantConfig: TenantConfiguration,
  preferredModule?: ModuleType,
) => {
  let current = preferredModule ?? storageApi.module.getLocalStorage();
  if (current && !isModuleEnabled(tenantConfig)(current)) {
    current = undefined;
  }

  const result = current || defaultModule(tenantConfig);
  if (result) storageApi.module.set(result);

  return result;
};

/**
 * Inspects the tenant config to determine the default (and allowed) module.
 * Optionally persists the value to storage if specified.
 */
const defaultModule = (tenantConfig: TenantConfiguration) => {
  const moduleConfig = tenantConfig?.modules;

  const result =
    (moduleConfig &&
      Object.keys(moduleConfig).find(
        (module: ModuleType) =>
          moduleConfig[module].isDefault && moduleConfig[module].isEnabled,
      )) ||
    undefined;

  return result as ModuleType | undefined;
};

/**
 * Inspects the tenant config, to determine if "changing modules" is possible.
 * This is determined by if there are at least two `module`'s enabled.
 */
export const canChangeModules = (tenantConfig: TenantConfiguration) => {
  const modules = tenantConfig.modules;
  return modules
    ? Object.keys(modules).filter(isModuleEnabled(tenantConfig)).length > 1
    : false;
};

/**
 * Returns an array of allowed modules.
 */
export const allowedModules = (tenantConfig: TenantConfiguration) => {
  const modules = tenantConfig.modules || {};
  return Object.keys(modules).filter(
    isModuleEnabled(tenantConfig),
  ) as ModuleType[];
};

/**
 * Inspects the tenant config, to determine if the specified module is enabled.
 */
const isModuleEnabled =
  (tenantConfig: TenantConfiguration) => (module: ModuleType) => {
    const modules = tenantConfig.modules;
    return Boolean(modules && modules[module]?.isEnabled);
  };
