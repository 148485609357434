/** @jsxImportSource @emotion/react */
import { map, Observable } from 'rxjs';
import { ofType, combineEpics } from 'redux-observable';
import { ReduxFormInstanceInterfaceConfirmRevertSignoff } from '@seeeverything/ui.forms/src/redux/form-instance/types.ts';
import { userRevertedSignoff } from '@seeeverything/ui.forms/src/redux/form-instance/instance/actions.ts';
import {
  showModalDialog,
  hideModalDialog,
} from '@seeeverything/ui.shell/src/redux/modalDialog/actions.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { TextInputConfirmationDialogContainer } from '@seeeverything/ui.shell/src/components/TextInputConfirmationDialog/index.ts';
import {
  GlobalAppActionType,
  GlobalAppEpicDependencies,
  GlobalAppState,
} from '../../../types.ts';

export const epics = combineEpics<
  GlobalAppActionType,
  GlobalAppActionType,
  GlobalAppState,
  GlobalAppEpicDependencies
>(handleConfirmRevert);

/**
 * Handles confirming reverting of signoff (reason capture).
 */
function handleConfirmRevert(
  action$: Observable<ReduxFormInstanceInterfaceConfirmRevertSignoff>,
) {
  return action$.pipe(
    ofType('ui.forms/instance/INTERFACE_CONFIRM_REVERT_SIGNOFF'),
    map((action) => {
      const { instanceId, personId, signature } = action.payload;

      const okActions = (reason: string) => [
        hideModalDialog(),
        userRevertedSignoff(instanceId, personId, signature, reason),
      ];

      const cancelActions = () => [hideModalDialog()];

      const elDialog = (
        <TextInputConfirmationDialogContainer
          title={'Reopen - Confirmation'}
          description={`This will remove the ${str.humanize(
            signature.type,
          )} sign-off. Are you sure you want to do this?`}
          inputLabel={'Reason (Required)'}
          inputError={'You must specify a reason for reopening.'}
          okActions={okActions}
          cancelActions={cancelActions}
        />
      );
      const modalDialogProps = {
        content: elDialog,
        width: 700,
        height: 392,
      };

      return showModalDialog(modalDialogProps);
    }),
  );
}
