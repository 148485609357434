/** @jsxImportSource @emotion/react */
import { BulkUploadFailedResultGrid } from '@seeeverything/ui.forms/src/components/BulkUploadFailedResultGrid/BulkUploadFailedResultGrid.tsx';
import { BulkUploadSuccessInstanceGrid } from '@seeeverything/ui.forms/src/components/BulkUploadSuccessInstanceGrid/BulkUploadSuccessInstanceGrid.tsx';
import { formBulkUploadClickThroughSlice } from '@seeeverything/ui.forms/src/redux/formBulkUploadClickThrough/index.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { useCallback, useMemo } from 'react';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';
import { SheetError } from '../SheetError/SheetError.tsx';
import { SheetLayout } from '../SheetLayout/SheetLayout.tsx';

export const SheetBulkUploadClickThroughGridContainer: React.FC = () => {
  const dispatch = useShellDispatch();
  const hasNextPage = useShellSelector(
    (state) => state.formBulkUploadClickThrough.hasNextPage,
  );

  const failedRows = useShellSelector(
    (state) => state.formBulkUploadClickThrough.failedRows.rows,
  );

  const isLoading = useShellSelector(
    (state) => state.formBulkUploadClickThrough.isLoading ?? false,
  );
  const isLoadingMore = useShellSelector(
    (state) => state.formBulkUploadClickThrough.isLoadingMore,
  );
  const jobId = useShellSelector(
    (state) => state.formBulkUploadClickThrough.jobId,
  );
  const loadedPage = useShellSelector(
    (state) => state.formBulkUploadClickThrough.currentPage,
  );

  const type = useShellSelector(
    (state) => state.formBulkUploadClickThrough.type,
  );

  const isError = useShellSelector(
    (state) => state.formBulkUploadClickThrough.error,
  );

  const loadError = useMemo(
    () =>
      isError
        ? `Unable to load ${
            type === 'Succeeded' ? 'created instances' : 'failed rows'
          }. Click here to retry.`
        : undefined,
    [isError, type],
  );
  const failedRowsOrderBys = useShellSelector(
    (state) => state.formBulkUploadClickThrough.failedRows.orderBys,
  );

  const chips = useShellSelector((state) => state.query.query.chips);
  const title = useMemo(() => {
    if (!chips?.length) return;
    return chips[chips.length - 1].label;
  }, [chips]);

  const locale = useShellSelector((state) => state.tenantState.tenant.locale);

  const handleColumnSort = useCallback(
    (columnId: string, direction: 'ASC' | 'DESC') => {
      dispatch(
        formBulkUploadClickThroughSlice.changeOrderBy({
          orderBy: {
            fieldName: columnId,
            direction: direction === 'ASC' ? 'Ascending' : 'Descending',
          },
        }),
      );
    },
    [dispatch],
  );

  const handleLoadMore = useCallback(
    () =>
      dispatch(
        formBulkUploadClickThroughSlice.loadClickThrough({
          jobId,
          type,
          loadNextPage: true,
        }),
      ),
    [dispatch, jobId, type],
  );

  const handleErrorClicked = useCallback(
    () =>
      dispatch(
        formBulkUploadClickThroughSlice.loadClickThrough({
          jobId,
          type,
          loadNextPage: false,
        }),
      ),
    [dispatch, jobId, type],
  );

  if (loadError)
    return (
      <SheetError
        icon={'refresh'}
        message={loadError}
        onClick={handleErrorClicked}
        showIcon={true}
      />
    );

  return (
    <SheetLayout
      title={title}
      rightTools={
        type === 'Failed'
          ? [
              {
                id: 'DOWNLOAD_BULK_UPLOAD_JOB_FAILURES',
                icon: Icons.fileDownload,
                label: 'Download Failures',
              },
            ]
          : undefined
      }
    >
      {type === 'Succeeded' ? (
        <BulkUploadSuccessInstanceGrid
          hasNextPage={hasNextPage}
          isLoading={isLoading}
          isLoadingMore={isLoadingMore}
          loadedPage={loadedPage}
          loadMoreText={'Load more'}
          onColumnSort={handleColumnSort}
          onLoadMore={handleLoadMore}
        />
      ) : (
        <BulkUploadFailedResultGrid
          rows={failedRows}
          orderBy={failedRowsOrderBys}
          hasNextPage={hasNextPage}
          isLoading={isLoading}
          isLoadingMore={isLoadingMore}
          loadedPage={loadedPage}
          loadMoreText={'Load more'}
          onColumnSort={handleColumnSort}
          onLoadMore={handleLoadMore}
          locale={locale}
        />
      )}
    </SheetLayout>
  );
};
