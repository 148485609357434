import { StateObservable, ofType } from 'redux-observable';
import { Observable, filter, map } from 'rxjs';
import { loadComponentsFromCache, loadHeader } from '../actions.ts';
import {
  DashboardActionLoadComponentsFromCache,
  DashboardActionTemplateLoaded,
  GlobalDashboardsState,
} from '../types.ts';

export function loadHeaderOnCacheLoad(
  action$: Observable<DashboardActionLoadComponentsFromCache>,
) {
  return action$.pipe(
    ofType('ui.dashboards/dashboard/CACHE/LOAD_COMPONENTS'),
    map(() => loadHeader()),
  );
}

export function loadFromCacheEpic(
  action$: Observable<DashboardActionTemplateLoaded>,
  state$: StateObservable<GlobalDashboardsState>,
) {
  return action$.pipe(
    ofType('ui.dashboards/dashboard/TEMPLATE_LOADED'),
    filter(({ payload: { isFromCache = false } }) => Boolean(isFromCache)),
    map(({ payload: { template } }) =>
      loadComponentsFromCache(
        template.id,
        state$.value.tenantState.tenant.module,
        template.entityId,
        template.kind === 'team' ? template.entity : undefined,
      ),
    ),
  );
}
