/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormDesigner } from '@seeeverything/ui.forms.designer/src/components/FormDesigner/FormDesigner.tsx';
import {
  DesignerChangeEventHandler,
  DesignerComponentKind,
  DesignerEventOccurredHandler,
} from '@seeeverything/ui.forms.designer/src/components/types.ts';
import {
  fileDelete,
  fileRejected,
  fileUpload,
} from '@seeeverything/ui.forms.designer/src/redux/designer/actions.digitalContent.ts';
import {
  addComponent,
  changeComponentProperty,
  deleteComponent,
  optionsAddNewOption,
  optionsChangeOptionText,
  optionsDeleteExistingOption,
} from '@seeeverything/ui.forms.designer/src/redux/designer/actions.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { MessageBar } from '@seeeverything/ui.primitives/src/components/MessageBar/MessageBar.tsx';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import { useCallback, useMemo } from 'react';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';
import { SheetError } from '../SheetError/index.ts';
import { useSheetFormIndexItems } from '../SheetForm/useSheetFormIndexItems.ts';
import { SheetLayout } from '../SheetLayout/SheetLayout.tsx';

export const SheetFormDesignerContainer: React.FC = () => {
  const dispatch = useShellDispatch();

  const isLoading = useShellSelector(
    (state) => state.formsDesignerEditor.isLoading,
  );
  const isDesigning = useShellSelector(
    (state) => state.formsDesignerEditor.isDesigning,
  );
  const lines = useShellSelector(
    (state) => state.formsDesignerEditor.draft?.lines,
  );
  const designerLines = useShellSelector(
    (state) => state.formsDesignerEditor.draft?.designerLines,
  );

  const digitalContentPackId = useShellSelector(
    (state) =>
      state.formsDesignerEditor.draft?.designerLines?.digitalContent?.packId,
  );

  const loadError = useShellSelector(
    (state) => state.formsDesignerEditor.loadError,
  );

  const saveError = useShellSelector(
    (state) => state.formsDesignerEditor.saveError,
  );

  const indexItems = useSheetFormIndexItems({ lines });

  const title = useShellSelector(
    (state) => `${state.tenantState.tenant.locale.label.form} Designer`,
  );

  const leftTools = useMemo(
    () => [
      {
        id: 'SAVE_DESIGNER_DEFINITION',
        icon: Icons.save,
        label: 'Save',
      },
      isDesigning
        ? {
            id: 'DESIGNER_EDIT',
            icon: Icons.document,
            label: 'Preview',
          }
        : {
            id: 'DESIGNER_EDIT',
            icon: Icons.editPencil,
            label: 'Design',
          },
    ],
    [isDesigning],
  );

  const handleOnChange = useCallback<DesignerChangeEventHandler>(
    (id, to, kind, payload) => {
      switch (kind) {
        case 'DROPDOWN':
        case 'LABEL':
        case 'SWITCH':
          dispatch(changeComponentProperty(id, to as string));
          break;

        case 'OTHER':
          if ((payload as any)?.labelChanged === true) {
            dispatch(
              optionsChangeOptionText(
                id,
                (payload as any).optionId,
                to as string,
              ),
            );
          }
          break;
      }
    },
    [dispatch],
  );

  const handleEventOccurred = useCallback<DesignerEventOccurredHandler>(
    (id, kind, payload) => {
      switch (kind) {
        case 'ADD': {
          dispatch(
            addComponent(
              `${id}-${uuid.generate()}`,
              id as DesignerComponentKind,
              payload?.insertBeforeId,
            ),
          );
          break;
        }

        case 'DELETE':
          dispatch(deleteComponent(id));
          break;

        case 'OTHER':
          if (payload?.newOption === true) {
            dispatch(optionsAddNewOption(id, `option-${uuid.generate()}`));
          }

          if (payload?.deleteOption === true) {
            dispatch(optionsDeleteExistingOption(id, payload.optionId));
          }
          break;
      }
    },
    [dispatch],
  );

  const handleFileDelete = useCallback(
    (fileId: string) => {
      dispatch(fileDelete({ digitalContentPackId, fileId }));
    },
    [digitalContentPackId, dispatch],
  );

  const handleFileUpload = useCallback(
    (files: File[]) => {
      files.forEach((file) => {
        dispatch(
          fileUpload({
            digitalContentPackId,
            fileId: uuid.generate(),
            file,
          }),
        );
      });
    },
    [digitalContentPackId, dispatch],
  );

  const handleFileRejected = useCallback(
    (files: File[], maxFileSize: number) =>
      dispatch(fileRejected(files, maxFileSize)),
    [dispatch],
  );

  const reloadOnClick = useCallback(() => window.location.reload(), []);

  const designerOptions = useMemo(
    () => ({
      onChange: handleOnChange,
      onEventOccurred: handleEventOccurred,
      onFileDelete: handleFileDelete,
      onFileUpload: handleFileUpload,
      onFileRejected: handleFileRejected,
    }),
    [
      handleEventOccurred,
      handleFileDelete,
      handleFileRejected,
      handleFileUpload,
      handleOnChange,
    ],
  );

  return loadError ? (
    <SheetError icon={'refresh'} onClick={reloadOnClick} />
  ) : (
    <div css={styles.base}>
      <SheetLayout
        header={
          saveError && (
            <MessageBar
              message={'Something went wrong while saving.'}
              type={'Error'}
            />
          )
        }
        indexItems={indexItems}
        isSpinning={isLoading}
        scrollSmooth={true}
        rightContentOffset={isDesigning ? 23 : 0}
        title={title}
        leftTools={leftTools}
        rightTools={[
          {
            id: 'PUBLISH_DRAFT_TEMPLATE',
            icon: Icons.cloudUpload,
            label: 'Publish',
          },
        ]}
      >
        <FormDesigner
          lines={lines}
          designerLines={designerLines}
          designerOptions={designerOptions}
          isDesigning={isDesigning}
        />
      </SheetLayout>
    </div>
  );
};

const styles = {
  base: css({
    position: 'relative',
    flex: '1 1 auto',
    boxSizing: 'border-box',
    display: 'flex',
  }),
};
