/** @jsxImportSource @emotion/react */
import { isAnyOf } from '@reduxjs/toolkit';
import { ImportTemplateDialogContainer } from '@seeeverything/ui.forms.designer/src/components/ImportTemplateDialog/ImportTemplateDialogContainer.tsx';
import { NewDraftDialogContainer } from '@seeeverything/ui.forms.designer/src/components/NewDraftDialog/NewDraftDialogContainer.tsx';
import { PublishDraftDialogContainer } from '@seeeverything/ui.forms.designer/src/components/PublishDraftDialog/PublishDraftDialogContainer.tsx';
import {
  saveDefinition,
  toggleDesigning,
  validateDefinition,
} from '@seeeverything/ui.forms.designer/src/redux/designer/actions.ts';
import {
  FormsDesignerDesignerSaveDefinitionFailedAction,
  FormsDesignerDesignerSavedDefinitionAction,
  FormsDesignerDesignerValidateDefinitionFailedAction,
  FormsDesignerDesignerValidateDefinitionSuccessAction,
} from '@seeeverything/ui.forms.designer/src/redux/designer/index.ts';
import {
  formsDesignerCreateTemplatesSlice,
  formsDesignerImportTemplatesSlice,
  formsDesignerPublishTemplatesSlice,
  formsDesignerViewTemplatesSlice,
} from '@seeeverything/ui.forms.designer/src/redux/index.ts';
import {
  hideModalDialog,
  showModalDialog,
} from '@seeeverything/ui.shell/src/redux/modalDialog/actions.ts';
import { chipRemovedExternally } from '@seeeverything/ui.shell/src/redux/query/actions.ts';
import { showStatusBar } from '@seeeverything/ui.shell/src/redux/sheets/actions.ts';
import { SheetToolbarClickAction } from '@seeeverything/ui.shell/src/redux/sheets/types.ts';
import { isValidationError } from '@seeeverything/ui.util/src/graphql/validationError.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { StateObservable, combineEpics, ofType } from 'redux-observable';
import {
  EMPTY,
  Observable,
  concatAll,
  filter,
  from,
  map,
  mergeMap,
  of,
} from 'rxjs';
import { GlobalAppEpicDependencies, GlobalAppState } from '../../../types.ts';
import { addFormsDesignerTemplateChip } from '../../config.sheets/query.ts';

export const epics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalAppState,
  GlobalAppEpicDependencies
>(
  cancelFormDesignerTemplatesDialog,
  hidePublishOnFinishedEpic,
  createTemplateToolbarButtonClick,
  importTemplateToolbarButtonClick,
  publishDraftToolbarButtonClick,
  saveDraftDefinition,
  showStatusBarCloseSheetOnPublishedTemplate,
  showStatusBarOnDeleteTemplateFailed,
  showStatusBarOnDeleteTemplateSuccess,
  showStatusBarOnFormDesignerValidationFailed,
  showStatusBarOnFormTemplateDefinitionSaved,
  showStatusBarOnFormTemplateDefinitionSaveFailed,
  showStatusBarOnImportedTemplate,
  showStatusBarOnTemplatesLoadError,
  togglePreviewAndDesign,
  validateDraftDefinition,
  viewTemplateDraft,
);

function showStatusBarOnDeleteTemplateFailed(action$: Observable<ReduxAction>) {
  return action$.pipe(
    filter(formsDesignerViewTemplatesSlice.deleteTemplateFailed.match),
    map((action) => {
      const errorMessage = action.payload.graphQLErrors?.some((error) =>
        isValidationError(error),
      )
        ? `Could not delete the template '${action.payload.name}' as it is being used in an active schedule.`
        : `Something went wrong while trying to delete the template '${action.payload.name}'. Please try again later.`;

      return showStatusBar('ERROR', errorMessage);
    }),
  );
}

function showStatusBarOnDeleteTemplateSuccess(
  action$: Observable<ReduxAction>,
) {
  return action$.pipe(
    filter(formsDesignerViewTemplatesSlice.deletedTemplate.match),
    map((action) =>
      showStatusBar(
        'SUCCESS',
        `Template '${action.payload.name}' was successfully removed.`,
      ),
    ),
  );
}

function showStatusBarCloseSheetOnPublishedTemplate(
  action$: Observable<ReduxAction>,
) {
  return action$.pipe(
    filter(formsDesignerPublishTemplatesSlice.publishedTemplate.match),
    map((action) =>
      from([
        showStatusBar(
          'SUCCESS',
          action.payload.name
            ? `Template '${action.payload.name}' successfully published.`
            : `Template successfully published.`,
        ),
        chipRemovedExternally(true),
      ]),
    ),
    concatAll(),
  );
}

function showStatusBarOnImportedTemplate(action$: Observable<ReduxAction>) {
  return action$.pipe(
    filter(formsDesignerImportTemplatesSlice.importedTemplate.match),
    map(() => showStatusBar('SUCCESS', `Template successfully imported.`)),
  );
}

function showStatusBarOnFormTemplateDefinitionSaved(
  action$: Observable<FormsDesignerDesignerSavedDefinitionAction>,
) {
  return action$.pipe(
    ofType('ui.forms.designer/designer/SAVED_DEFINITION'),
    filter((action) => action.payload.source === 'save'),
    map(() => showStatusBar('SUCCESS', 'Draft template saved.')),
  );
}

function showStatusBarOnFormTemplateDefinitionSaveFailed(
  action$: Observable<FormsDesignerDesignerSaveDefinitionFailedAction>,
) {
  return action$.pipe(
    ofType('ui.forms.designer/designer/SAVE_DEFINITION_FAILED'),
    map(() =>
      showStatusBar(
        'ERROR',
        `Draft template failed to save - please try again in a few moments.`,
        15000,
      ),
    ),
  );
}

function showStatusBarOnFormDesignerValidationFailed(
  action$: Observable<FormsDesignerDesignerValidateDefinitionFailedAction>,
) {
  return action$.pipe(
    ofType('ui.forms.designer/designer/VALIDATE_DEFINITION_FAILED'),
    map(() =>
      showStatusBar('ERROR', 'Please check for required fields and try again.'),
    ),
  );
}

function createTemplateToolbarButtonClick(
  action$: Observable<SheetToolbarClickAction>,
) {
  return action$.pipe(
    ofType('ui.shell/sheets/TOOLBAR_CLICK'),
    filter((action) => action.payload.toolId === 'CREATE_FORM_TEMPLATE'),
    map(() =>
      showModalDialog({
        content: <NewDraftDialogContainer />,
        width: 740,
      }),
    ),
  );
}

function importTemplateToolbarButtonClick(
  action$: Observable<SheetToolbarClickAction>,
) {
  return action$.pipe(
    ofType('ui.shell/sheets/TOOLBAR_CLICK'),
    filter((action) => action.payload.toolId === 'IMPORT_FORM_TEMPLATE'),
    map(() =>
      showModalDialog({
        content: <ImportTemplateDialogContainer />,
        width: 740,
      }),
    ),
  );
}

function viewTemplateDraft(action$: Observable<ReduxAction>) {
  return action$.pipe(
    filter(
      isAnyOf(
        formsDesignerCreateTemplatesSlice.createdDraft.match,
        formsDesignerViewTemplatesSlice.openDraft.match,
      ),
    ),
    map((action) =>
      from([
        addFormsDesignerTemplateChip(action.payload.id, action.payload.name),
      ]),
    ),
    concatAll(),
  );
}

function cancelFormDesignerTemplatesDialog(action$: Observable<ReduxAction>) {
  return action$.pipe(
    filter(
      isAnyOf(
        formsDesignerImportTemplatesSlice.importedTemplate.match,
        formsDesignerCreateTemplatesSlice.createdDraft.match,
        formsDesignerCreateTemplatesSlice.cancelDraftCreation.match,
        formsDesignerImportTemplatesSlice.cancelImport.match,
      ),
    ),
    map(hideModalDialog),
  );
}

function saveDraftDefinition(
  action$: Observable<SheetToolbarClickAction>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.shell/sheets/TOOLBAR_CLICK'),
    filter((action) => action.payload.toolId === 'SAVE_DESIGNER_DEFINITION'),
    mergeMap(async () => {
      const draft = state$.value.formsDesignerEditor.draft;
      return draft ? of(saveDefinition(draft.id, 'save')) : EMPTY;
    }),
    concatAll(),
  );
}

function togglePreviewAndDesign(action$: Observable<SheetToolbarClickAction>) {
  return action$.pipe(
    ofType('ui.shell/sheets/TOOLBAR_CLICK'),
    filter((action) => action.payload.toolId === 'DESIGNER_EDIT'),
    map(() => toggleDesigning()),
  );
}

function publishDraftToolbarButtonClick(
  action$: Observable<SheetToolbarClickAction>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.shell/sheets/TOOLBAR_CLICK'),
    filter(
      (action) =>
        action.payload.toolId === 'PUBLISH_DRAFT_TEMPLATE' &&
        Boolean(state$.value.formsDesignerEditor.draft),
    ),
    mergeMap(async () => {
      const draft = state$.value.formsDesignerEditor.draft;
      return from([
        saveDefinition(draft.id, 'publish'),
        validateDefinition(draft.id, draft.definition),
      ]);
    }),
    concatAll(),
  );
}

function validateDraftDefinition(
  action$: Observable<FormsDesignerDesignerValidateDefinitionSuccessAction>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.forms.designer/designer/VALIDATE_DEFINITION_SUCCESS'),
    filter(() => Boolean(state$.value.formsDesignerEditor.draft)),
    map(() => {
      const config = state$.value.tenantState.tenant?.configuration;
      const module = state$.value.tenantState.tenant?.module;
      const hasVisibilities = Boolean(
        config?.modules?.[module]?.formTemplateDesigner
          ?.hasTemplateVisibilities ?? true,
      );

      const draft = state$.value.formsDesignerEditor.draft;
      const existingName = draft?.name;
      const existingCategory = draft?.sourceTemplate?.category
        ? {
            id: draft.sourceTemplate.category.id,
            content: draft.sourceTemplate.category.name,
          }
        : undefined;
      const existingSourceTemplate = draft?.sourceTemplate
        ? {
            id: draft.sourceTemplate.id,
            content: draft.sourceTemplate.name,
          }
        : undefined;

      return from([
        formsDesignerPublishTemplatesSlice.initialize({
          name: existingName,
          sourceTemplate: existingSourceTemplate,
          category: existingCategory,
        }),
        showModalDialog({
          content: (
            <PublishDraftDialogContainer hasVisibilities={hasVisibilities} />
          ),
          width: 740,
        }),
      ]);
    }),
    concatAll(),
  );
}

function hidePublishOnFinishedEpic(action$: Observable<ReduxAction>) {
  return action$.pipe(
    filter(
      isAnyOf(
        formsDesignerPublishTemplatesSlice.publishedTemplate.match,
        formsDesignerPublishTemplatesSlice.cancelPublish.match,
      ),
    ),
    map(hideModalDialog),
  );
}

function showStatusBarOnTemplatesLoadError(action$: Observable<ReduxAction>) {
  return action$.pipe(
    filter(formsDesignerViewTemplatesSlice.loadFailed.match),
    map(() =>
      showStatusBar(
        'ERROR',
        `An error occurred loading the designer templates.`,
      ),
    ),
  );
}
