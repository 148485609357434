/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import React from 'react';

interface IGoalScheduleStatusBarContainerProps {
  message: string;
  status?: 'ERROR' | 'SAVING' | 'INFO';
}

const THEME = (status: 'ERROR' | 'SAVING' | 'INFO') => {
  switch (status) {
    case 'ERROR':
      return {
        icon: 'errorOutline',
        iconColor: 'red',
        bg: 'rgba(255, 0, 0, 0.1)',
        border: 'rgba(255, 0, 0, 0.05)',
      };

    case 'SAVING':
      return {
        icon: 'clock',
        iconColor: color.format(-0.8),
        bg: color.create(COLORS.BLUE).alpha(0.1).css(),
        border: `solid 1px ${color.create(COLORS.BLUE).alpha(0.1).css()}`,
      };

    case 'INFO':
    default:
      return {
        icon: 'verifiedUser',
        iconColor: '#37BA54',
        bg: color.create(COLORS.BLUE).alpha(0.1).css(),
        border: `solid 1px ${color.create(COLORS.BLUE).alpha(0.1).css()}`,
      };
  }
};

/**
 * A status bar displayed at the top of the schedule page.
 */
export const GoalScheduleStatusBarContainer: React.FC<
  IGoalScheduleStatusBarContainerProps
> = ({ message, status }) => {
  const theme = THEME(status);
  const Icon = Icons[theme.icon];

  const computedStyles = {
    base: {
      backgroundColor: theme.bg,
      border: theme.border,
    },
  };

  return (
    <div css={[styles.base, computedStyles.base]}>
      <Icon size={20} fill={theme.iconColor} style={styles.statusIcon} />
      <Text
        color={color.format(-0.8)}
        style={styles.statusText}
        ellipsis={true}
      >
        {message}
      </Text>
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    alignItems: 'center',
    padding: 15,
  }),
  statusIcon: {
    width: 25,
    alignSelf: 'center',
  },
  statusText: css({
    paddingLeft: 15,
  }),
};
