/** @jsxImportSource @emotion/react */
import { formBulkUploadDownloadTemplateSlice } from '@seeeverything/ui.forms/src/redux/formBulkUploadDownloadTemplate/index.ts';
import { IActionBarButton } from '@seeeverything/ui.primitives/src/components/ModalDialog/components/ActionButtons/ActionButtons.tsx';
import { useCallback, useMemo } from 'react';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';
import { DownloadFormTemplateDialog } from './DownloadFormTemplateDialog.tsx';
import { DialogOuter } from './components/DialogOuter.tsx';

export const DownloadFormTemplateDialogContainer: React.FC = () => {
  const dispatch = useShellDispatch();
  const exportableQuestions = useShellSelector(
    (state) => state.formBulkUploadDownloadTemplate.exportableQuestions,
  );

  const isLoading = useShellSelector(
    (state) => state.formBulkUploadDownloadTemplate.isLoading,
  );

  const selectedTemplate = useShellSelector(
    (state) => state.formBulkUploadDownloadTemplate.selectedTemplate,
  );

  const handleExportClick = useCallback(() => {
    if (!selectedTemplate || !exportableQuestions.keys) return;

    const selectedQuestions = exportableQuestions.filter(
      (key) => key.isSelected,
    );

    dispatch(
      formBulkUploadDownloadTemplateSlice.exportTemplate({
        selectedTemplate,
        selectedQuestions,
      }),
    );
  }, [dispatch, exportableQuestions, selectedTemplate]);

  const actionButtons: IActionBarButton[] = useMemo(
    () => [
      {
        id: 'Cancel',
        label: 'Cancel',
        isEnabled: true,
        onAction: () =>
          dispatch(formBulkUploadDownloadTemplateSlice.cancelExport()),
      },
      {
        id: 'Export',
        label: 'Export',
        isEnabled: Boolean(selectedTemplate && !isLoading),
        onAction: handleExportClick,
      },
    ],
    [dispatch, handleExportClick, isLoading, selectedTemplate],
  );

  return (
    <DialogOuter actions={actionButtons}>
      <DownloadFormTemplateDialog
        exportableQuestions={exportableQuestions}
        isTemplateLoading={isLoading}
        selectedTemplate={selectedTemplate}
      />
    </DialogOuter>
  );
};
