import { isAnyOf } from '@reduxjs/toolkit';
import { formEditGoalSlice } from '@seeeverything/ui.forms/src/redux/form-edit-goal/index.ts';
import { formActionSlice } from '@seeeverything/ui.forms/src/redux/formAction/index.ts';
import { hideModalDialog } from '@seeeverything/ui.shell/src/redux/modalDialog/actions.ts';
import { showStatusBar } from '@seeeverything/ui.shell/src/redux/sheets/index.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { StateObservable, combineEpics } from 'redux-observable';
import { EMPTY, Observable, concatAll, filter, map, of } from 'rxjs';
import {
  GlobalAppActionType,
  GlobalAppEpicDependencies,
  GlobalAppState,
} from '../../../types.ts';

export const epics = combineEpics<
  GlobalAppActionType,
  GlobalAppActionType,
  GlobalAppState,
  GlobalAppEpicDependencies
>(hideGoalDialogOnCompleteEpic, showStatusBarOnActionV2Changed);

function showStatusBarOnActionV2Changed(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    filter(formActionSlice.saved.match),
    map((action) => {
      const actionLabel = state$.value.tenantState.tenant.locale.label.action;

      if (action.payload.isSuccess === true)
        return of(
          showStatusBar(
            'SUCCESS',
            `The ${actionLabel} was successfully saved.`,
          ),
        );

      if (action.payload.isSuccess === 'PARTIAL_SUCCESS')
        return of(
          showStatusBar(
            'WARN',
            `The ${actionLabel} was created, but some fields were not saved properly. Please check your details and try again.`,
          ),
        );

      if (action.payload.isSuccess === false)
        return of(
          showStatusBar(
            'ERROR',
            `Something went wrong trying to save your ${actionLabel}. Please try again.`,
          ),
        );

      return EMPTY;
    }),
    concatAll(),
  );
}

function hideGoalDialogOnCompleteEpic(action$: Observable<ReduxAction>) {
  return action$.pipe(
    filter(
      isAnyOf(
        formEditGoalSlice.createdGoal.match,
        formEditGoalSlice.updatedGoal.match,
      ),
    ),
    map((action) =>
      action.payload.isSuccess
        ? hideModalDialog()
        : showStatusBar(
            'ERROR',
            'Something went wrong trying to save your goal. Please try again.',
          ),
    ),
  );
}
