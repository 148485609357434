import { UpdateGoalPayload } from '@seeeverything/ui.forms/src/redux/form-edit-goal/formEditGoalSlice.ts';
import { IFormGoalItem } from '@seeeverything/ui.forms/src/redux/form-edit-goal/types.ts';
import momentTz from 'moment-timezone';
import { GlobalAppState } from '../../types.ts';
import { trackEvent } from './util.ts';

export const generateGoalModifyEvents = (
  payload: UpdateGoalPayload,
  state: GlobalAppState,
) => {
  const initialValues = state.formEditGoal.initialValues;
  if (!initialValues) return [];
  if (initialValues.type !== 'EXISTING') return [];

  const originalGoal = initialValues.goal;
  const timezone = state.tenantState.tenant.configuration.timezone;

  return [
    goalCompletedEvent(payload, originalGoal, timezone),
    payload.note && trackEvent('goal_add_note', { goalId: originalGoal.id }),
  ].filter(Boolean);
};

const goalCompletedEvent = (
  payload: UpdateGoalPayload,
  originalGoal: IFormGoalItem,
  timezone: string,
) => {
  if (payload.newStatus !== 'Completed') return;

  const dueBy = momentTz(payload.newDueBy ?? originalGoal.dueBy).tz(timezone);

  return trackEvent('goal_completed', {
    goalId: originalGoal.id,
    completedOnTime: momentTz().tz(timezone).isSameOrBefore(dueBy),
  });
};
