/** @jsxImportSource @emotion/react */
import { IconWrapper } from '@seeeverything/ui.primitives/src/components/IconWrapper/IconWrapper.tsx';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { TextFieldDropdown } from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';
import { css } from '@emotion/react';

export interface INewTemplateProps {
  categoriesIsLoading: boolean;
  category?: ISelectionListItem;
  categoryErrorText?: string;
  categorySelections: ISelectionListItem[];
  hasVisibilities?: boolean;
  name?: string;
  nameErrorText?: string;
  onCategoryChange: (to: ISelectionListItem) => void;
  onNameChange: (to: string) => void;
  onVisibilityChange: (to: ISelectionListItem) => void;
  visibility?: ISelectionListItem;
  visibilityErrorText?: string;
  visibilitySelections: ISelectionListItem[];
}

export const NewTemplate = ({
  categoriesIsLoading,
  category,
  categoryErrorText,
  categorySelections,
  hasVisibilities,
  name,
  nameErrorText,
  onCategoryChange,
  onNameChange,
  onVisibilityChange,
  visibility,
  visibilityErrorText,
  visibilitySelections,
}: INewTemplateProps) => (
  <>
    <div css={styles.bodyFull}>
      <IconWrapper icon={'infoCard'} style={styles.bodyField}>
        <TextField
          id={'name'}
          value={name}
          label={'Template Name'}
          onChange={onNameChange}
          error={nameErrorText}
        />
      </IconWrapper>
    </div>
    <div css={styles.bodyFull}>
      <IconWrapper icon={'list'} style={styles.bodyField}>
        <TextFieldDropdown
          id={'category'}
          label={'Template Category'}
          selections={categorySelections}
          isLoading={categoriesIsLoading}
          value={category}
          onChange={onCategoryChange}
          error={categoryErrorText}
        />
      </IconWrapper>
    </div>
    <div css={styles.bodyFull}>
      {hasVisibilities ? (
        <IconWrapper icon={'verifiedUser'} style={styles.bodyField}>
          <TextFieldDropdown
            id={'visibility'}
            label={'Template Visibility'}
            value={visibility}
            onChange={onVisibilityChange}
            error={visibilityErrorText}
            selections={visibilitySelections}
          />
        </IconWrapper>
      ) : undefined}
    </div>
  </>
);

const styles = {
  bodyFull: css({
    width: '100%',
  }),
  bodyField: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    width: '100%',
    padding: 15,
    boxSizing: 'border-box',
  }),
};
