/** @jsxImportSource @emotion/react */
import Dialog from '@material-ui/core/Dialog/index.js';
import DialogActions from '@material-ui/core/DialogActions/index.js';
import DialogContent from '@material-ui/core/DialogContent/index.js';
import DialogTitle from '@material-ui/core/DialogTitle/index.js';
import { LabelButton } from '@seeeverything/ui.primitives/src/components/LabelButton/LabelButton.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';

export interface IAlertDialog {
  title: string;
  bodyText: string;
  onConfirmClick: () => void;
  onCancelClick: () => void;
  isVisible: boolean;
}

export const AlertDialog: React.FC<IAlertDialog> = ({
  bodyText,
  isVisible,
  onConfirmClick,
  onCancelClick,
  title,
}) => (
  <Dialog open={isVisible}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <Text>{bodyText}</Text>
    </DialogContent>
    <DialogActions>
      <LabelButton
        label={'OK'}
        onClick={onConfirmClick}
        backgroundAlways={true}
      />
      <LabelButton
        label={'Cancel'}
        onClick={onCancelClick}
        backgroundAlways={true}
      />
    </DialogActions>
  </Dialog>
);
