import { formsQuery } from '@se/data/forms/query/index.ts';
import { orgHierarchyQuery } from '@se/data/orgHierarchy/query/index.ts';
import { isLineVisible } from '@seeeverything/ui.forms/src/hooks/util.ts';
import {
  ReduxFormInstanceAnswerChange,
  ReduxFormInstanceClearErrors,
  ReduxFormInstanceServerCreate,
  ReduxFormInstanceServerLoaded,
  ReduxFormInstanceServerSignoffSuccess,
} from '@seeeverything/ui.forms/src/redux/form-instance/types.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { AnalyticsEvent } from '@seeeverything/ui.util/src/redux/analytics/types.ts';
import { last, splitEvery, uniq } from 'ramda';
import { GlobalAppActionType, GlobalAppState } from '../../types.ts';
import { calculateDateDuration, trackEvent } from './util.ts';

export const generateFormInstanceCreateEvent = async (
  action: ReduxFormInstanceServerCreate,
) => [
  trackEvent('form_create', {
    formCategory: action.payload.categoryName,
    formInstanceId: action.payload.instanceId,
    formType: action.payload.templateName,
  }),
];

export const generateFormInstanceViewEvent = async (
  action: ReduxFormInstanceServerLoaded,
) => [
  trackEvent('form_view', {
    formCategory: action.payload.instance.template?.category.name,
    formInstanceId: action.payload.instance.id,
    formType: action.payload.instance.template?.name,
  }),
];

export const generateFormInstanceEditEvent = async (
  action: ReduxFormInstanceAnswerChange,
  state: GlobalAppState,
): Promise<AnalyticsEvent[]> => {
  const { instanceId, change } = action.payload;

  if (change.type === 'DELETE') return [];

  const instance = state.formInstance.instances[instanceId];
  if (!instance) return [];

  const questionKey = change.toAnswer.lineId;
  const lines = state.formInstance.instances[instanceId]?.lines ?? {};
  const answers = state.formInstance.instances[instanceId].answers;

  const tenantTimezone = state.tenantState.tenant.configuration.timezone;

  const visibleQuestions = Object.values(lines).filter(
    (line) =>
      isLineVisible(line, answers, tenantTimezone) &&
      (line.type === 'inputs' || line.type.includes('answer')),
  );

  const everyTenthQuestion = splitEvery(10, visibleQuestions)
    .filter((batch) => batch.length === 10)
    .map((batch) => last(batch))
    .map((line) => line.id);

  if (!everyTenthQuestion.includes(questionKey)) return [];

  const totalLinesWithAnswer = uniq(
    Object.values(answers).map(({ lineId }) => lineId),
  ).length;

  return [
    trackEvent('form_edit', {
      visibleAnswers: visibleQuestions.length,
      editedAnswers: totalLinesWithAnswer,
    }),
  ];
};

export const generateFormInstanceMismatchedAnswersEvent = async (
  action: ReduxFormInstanceClearErrors,
  state: GlobalAppState,
): Promise<AnalyticsEvent[]> => {
  if (action.payload.reason !== 'MISMATCH_RECONCILIATION') return [];

  const { answerKeys, instanceId } = action.payload;
  const instance = state.formInstance.instances[instanceId];
  const formTemplateId = instance.template.id;

  return [
    trackEvent('form_signoff_with_mismatched_answers', {
      formInstanceId: instanceId,
      formTemplateId,
      formCategory: instance.template?.category.name,
      formType: instance.template?.name,
      formDifferences: answerKeys.join(', '),
    }),
  ];
};

export const generateFormInstanceIdEvent = async (
  action: GlobalAppActionType,
  eventIdentifier: string,
  state: GlobalAppState,
) => {
  const formInstanceId = (() => {
    switch (action.type) {
      case 'ui.forms/file/DOWNLOAD_REQUEST':
      case 'ui.forms/file/UPLOAD':
        return action.payload.formInstanceId;
      case 'ui.forms/instance/EXPORT_INSTANCE_TO_PDF':
      case 'ui.forms/instance/SERVER_REVERT_SIGNOFF_SUCCESS': {
        const instanceId = action.payload.instanceId;
        const formInstance = state.formInstance.instances[instanceId];

        return formInstance.signOffs?.length === 1 ? instanceId : undefined;
      }
      default:
        return undefined;
    }
  })();

  return [trackEvent(eventIdentifier, { formInstanceId })];
};

export const generateFormInstanceSignOffEvents = async (
  action: ReduxFormInstanceServerSignoffSuccess,
  state: GlobalAppState,
  client: IGraphQLClient,
): Promise<AnalyticsEvent[]> => {
  const formInstanceId = action.payload.instanceId;
  const signOffEvent: AnalyticsEvent = trackEvent('form_sign-off', {
    formInstanceId,
    participantType:
      action.payload.signature.type === 'SUBJECT' ? 'subject' : 'owner',
  });
  const formInstance = state.formInstance.instances[formInstanceId];

  if (formInstance.status.status !== 'Completed') return [signOffEvent];

  const template = await getFormTemplate(client, formInstance.template.id);
  const formSubjectPath =
    formInstance.subject.kind === 'Team'
      ? formInstance.subject.path
      : (await getSubject(client, formInstance.subject?.id))?.primaryMemberTeam
          ?.path;

  const completionEvent = trackEvent('form_complete', {
    formCreatedBy: formInstance.createdBy?.id,
    formInstanceId,
    formOpenDuration: calculateDateDuration(
      formInstance.createdAt,
      formInstance.status.lastUpdated,
    ),
    formSubjectId: formInstance.subject.id,
    formSubjectPath,
    formSubjectType: formInstance.subject.kind,
    formVisibilityRule: template?.visibilityLimitations,
  });

  return [signOffEvent, completionEvent];
};

const getSubject = async (client: IGraphQLClient, personId: string) => {
  const response = await orgHierarchyQuery.getPerson(client, personId);
  return response.isSuccess ? response.data : undefined;
};

const getFormTemplate = async (client: IGraphQLClient, templateId: string) => {
  const response = await formsQuery.getFormTemplate(client, templateId);
  return response.isSuccess ? response.data : undefined;
};
