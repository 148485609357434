/** @jsxImportSource @emotion/react */
import {
  COLUMNS,
  COLUMNS_NO_TYPE,
  GoalsActionsGridContainer,
} from '@seeeverything/ui.forms/src/components/Grid/GoalsActionsGridContainer.tsx';
import { goalsActionsGridLabel } from '@seeeverything/ui.forms/src/components/Grid/util.ts';
import { goalsActionsGridSlice } from '@seeeverything/ui.forms/src/redux/goalsActionsGrid/index.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { useCallback, useMemo } from 'react';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';
import { SheetLayout } from '../SheetLayout/index.ts';

export interface ISheetGoalsActionsGridContainerProps {
  entityId: string;
  entityType: 'PERSON' | 'TEAM';
  startDate: string;
  endDate: string;
  dateRangeLabel: string;
}

export const SheetGoalsActionsGridContainer: React.FC<
  ISheetGoalsActionsGridContainerProps
> = ({ entityId, entityType, startDate, endDate, dateRangeLabel }) => {
  const dispatch = useShellDispatch();

  const module = useShellSelector((state) => state.tenantState.tenant.module);

  const columns = useMemo(
    () => (module !== 'coaching' ? COLUMNS_NO_TYPE : COLUMNS),
    [module],
  );
  const actionLabel = useShellSelector((state) =>
    str.titleCase(state.tenantState.tenant.locale.label.action),
  );

  const dueByDirection = useShellSelector(
    (state) => state.formGoalActionsGrid.orderDueByDirection,
  );

  const issueActionLabel = useShellSelector((state) =>
    str.titleCase(state.tenantState.tenant.locale.label.issueAction),
  );

  const orderBy = useShellSelector(
    (state) => state.formGoalActionsGrid.orderBy,
  );

  const showCompleted = useShellSelector(
    (state) => state.formGoalActionsGrid.showCompleted,
  );

  const downloadSpreadsheet = useCallback(
    () =>
      dispatch(
        goalsActionsGridSlice.downloadGrid({
          columnHeaderLabels: columns.map(({ label }) => label),
          queryArgs: {
            key: 'goalsAndActions' as const,
            actionLabel,
            dueByDirection,
            endDate,
            entityId,
            entityType,
            issueActionLabel,
            module,
            orderBy,
            sheetType: 'FULL_SHEET',
            showCompleted,
            spreadsheetDownload: true,
            startDate,
          },
          title: goalsActionsGridLabel({ module, dateRangeLabel, actionLabel }),
        }),
      ),
    [
      actionLabel,
      columns,
      dateRangeLabel,
      dispatch,
      dueByDirection,
      endDate,
      entityId,
      entityType,
      issueActionLabel,
      module,
      orderBy,
      showCompleted,
      startDate,
    ],
  );

  const chips = useShellSelector((state) => state.query.query.chips);
  const title = useMemo(() => {
    if (!chips?.length) return;
    return chips[chips.length - 1]?.label;
  }, [chips]);

  return (
    <SheetLayout
      rightTools={[
        {
          id: 'DOWNLOAD_GRID',
          icon: Icons.cloudDownload,
          onClick: downloadSpreadsheet,
          tooltip: 'Download spreadsheet',
        },
      ]}
      title={title}
    >
      <GoalsActionsGridContainer
        entityId={entityId}
        entityType={entityType}
        startDate={startDate}
        endDate={endDate}
        dateRangeLabel={dateRangeLabel}
        inViewport={true}
        sheetType={'FULL_SHEET'}
      />
    </SheetLayout>
  );
};
