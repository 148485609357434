/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { Icons, IIconProps } from '../Icon/index.ts';
import { Text } from '../Text/Text.tsx';

const COLORS = {
  Error: {
    background: 'rgba(255, 0, 0, 0.1)',
    accent: 'rgba(255,0,0,0.4)',
    border: 'rgba(255, 0, 0, 0.05)',
    icon: 'RED',
    text: color.format(-0.7),
  },
  Info: {
    background: '#e0e0e0',
    accent: '#a0a0a0',
    border: '#d8d8d8',
    icon: '#a0a0a0',
    text: color.format(-0.7),
  },
  Success: {
    background: '#e6f7e6',
    accent: '#31ae29',
    border: '#e6f7e6',
    icon: 'GREEN',
    text: color.format(-0.7),
  },
  Validation: {
    background: 'rgba(255, 0, 0, 0.1)',
    accent: 'rgba(255,0,0,0.4)',
    border: 'rgba(255, 0, 0, 0.05)',
    icon: 'RED',
    text: color.format(-0.7),
  },
  Warning: {
    background: '#fefbdf',
    accent: '#fecf0b',
    border: '#fefbdf',
    icon: '#fecf0b',
    text: color.format(-0.7),
  },
};

const ICONS = {
  Error: function IconError(props: IIconProps) {
    return <Icons.errorOutline size={16} {...props} />;
  },
  Info: function IconInfo(props: IIconProps) {
    return <Icons.info size={16} {...props} />;
  },
  Success: function IconSuccess(props: IIconProps) {
    return <Icons.tickDone size={16} {...props} />;
  },
  Validation: function IconValidation(props: IIconProps) {
    return <Icons.errorOutline size={16} {...props} />;
  },
  Warning: function IconWarning(props: IIconProps) {
    return <Icons.warning size={16} {...props} />;
  },
};

export interface IMessageBarProps {
  type: 'Error' | 'Info' | 'Success' | 'Validation' | 'Warning';
  message: string;
  style?: SerializedStyles;
}

export const MessageBar = ({ message, style, type }: IMessageBarProps) => {
  const computedStyles = useMemo(
    () => ({
      base: css([
        {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          height: 40,
          backgroundColor: COLORS[type].background,
          border: `solid 1px ${COLORS[type].border}`,
        },
        style,
      ]),
      accent: css({
        width: 10,
        height: 42,
        backgroundColor: COLORS[type].accent,
      }),
    }),
    [type, style],
  );

  const Icon = ICONS[type];

  return (
    <div css={computedStyles.base}>
      <div css={computedStyles.accent} />
      <div css={styles.iconContainer}>
        <Icon fill={COLORS[type].icon} />
      </div>
      <Text color={COLORS[type].text}>{message}</Text>
    </div>
  );
};

const styles = {
  iconContainer: css({
    paddingTop: 4,
    paddingLeft: 10,
    width: 25,
  }),
};
