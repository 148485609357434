import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { isEmpty } from 'ramda';
import { GridCellDecorator } from '../../types.ts';
import { matchTargetValue } from '../../util/util.ts';

export const targetDecorator: GridCellDecorator = (args) => {
  const { columnDefinition, datapoint } = args;
  if (columnDefinition?.contentMap) {
    const alignment = columnDefinition.align || 'left';

    const match = matchTargetValue(columnDefinition.contentMap, datapoint);

    if (!match) {
      return !isEmpty(
        columnDefinition.contentMap.filter((c) => Boolean(c.icon || c.label)),
      )
        ? {
            text: {
              color: color.format(-0.5),
              size: 14,
              weight: 400,
              datapoint: { displayValue: '-' },
            },
            alignment,
          }
        : {};
    }

    const { icon, label } = match;
    const Icon = icon && Icons[icon];
    if (Icon) {
      return {
        icon: {
          label,
          icon,
          color: match.color,
          size: 25,
        },
      };
    }

    if (match.textIcon && Icons[match.textIcon]) {
      return {
        icon: {
          icon: match.textIcon,
          color: match.textIconColor || match.color,
          size: 16,
        },
        text: {
          color: match.color,
          weight: match.weight,
          datapoint,
        },
      };
    }

    return {
      text: {
        color: match.color,
        weight: match.weight,
        label: match.label,
      },
    };
  }
  return {};
};
