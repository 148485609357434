/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import * as R from 'ramda';
import { DashboardCommentDescriptionListItem } from '../../../types.ts';

export const hasDescriptionValue = (
  item: DashboardCommentDescriptionListItem,
) => !R.isNil(item.description) && !R.isEmpty(item.description);

export interface ICommentsDescriptionListItemProps {
  title: string;
  description: string;
}

export const CommentsDescriptionListItem = (
  props: ICommentsDescriptionListItemProps,
) => (
  <div>
    <Text
      color={color.format(-0.4)}
      weight={900}
      size={14}
      style={styles.commentListText}
    >
      {props.title}
      {':'}
    </Text>
    <Text color={color.format(-0.4)} size={14} style={styles.commentListText}>
      {props.description}
    </Text>
  </div>
);

const styles = {
  commentListText: css({
    marginBottom: 4,
    marginRight: 4,
  }),
};
