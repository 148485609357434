import { isAnyOf } from '@reduxjs/toolkit';
import { formEditGoalSlice } from '@seeeverything/ui.forms/src/redux/form-edit-goal/index.ts';
import { GoalDialogContainer } from '@seeeverything/ui.shell/src/components/GoalDialog/GoalDialogContainer.tsx';
import { showModalDialog } from '@seeeverything/ui.shell/src/redux/modalDialog/actions.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { combineEpics } from 'redux-observable';
import { Observable, filter, map } from 'rxjs';
import { GlobalAppEpicDependencies, GlobalAppState } from '../../../types.ts';

export const epics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalAppState,
  GlobalAppEpicDependencies
>(showGoalDialogEpic);

function showGoalDialogEpic(action$: Observable<ReduxAction>) {
  return action$.pipe(
    filter(
      isAnyOf(
        formEditGoalSlice.loadGoal.match,
        formEditGoalSlice.createDraft.match,
      ),
    ),
    map((action) =>
      showModalDialog({
        content: <GoalDialogContainer source={action.payload.source} />,
        width: 1500,
      }),
    ),
  );
}
