/** @jsxImportSource @emotion/react */
import { DashboardGridContainer } from '@seeeverything/ui.dashboards/src/components/DashboardGrid/DashboardGridContainer.tsx';
import { dashboardGridsSlice } from '@seeeverything/ui.dashboards/src/redux/index.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { useCallback, useMemo } from 'react';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';
import { SheetError } from '../SheetError/SheetError.tsx';
import { SheetLayout } from '../SheetLayout/index.ts';

export interface ISheetGridV2ContainerProps {
  dashboardKey: string;
  dashboardType:
    | 'CLICK_THROUGH_GRID'
    | 'GRID'
    | 'COACHING_DASHBOARD_TEAM'
    | 'COACHING_DASHBOARD_PERSON';
}

export const SheetGridV2Container: React.FC<ISheetGridV2ContainerProps> = ({
  dashboardKey,
  dashboardType,
}) => {
  const dispatch = useShellDispatch();

  const gridId = useMemo(
    () =>
      dashboardType === 'CLICK_THROUGH_GRID'
        ? clickThroughDashboardKey(dashboardKey)
        : dashboardKey,
    [dashboardKey, dashboardType],
  );

  const gridState = useShellSelector((state) =>
    dashboardType === 'CLICK_THROUGH_GRID'
      ? state.dashboardsV2.CLICK_THROUGH_GRID[gridId]
      : state.dashboardsV2.GRID[gridId],
  );

  const chips = useShellSelector((state) => state.query.query.chips);
  const title = useMemo(() => {
    if (!chips?.length) return;
    return chips[chips.length - 1]?.label;
  }, [chips]);

  const module = useShellSelector((state) => state.tenantState.tenant.module);

  const template = useShellSelector((state) => state.dashboardsV2.template);

  const gridKey = useMemo(() => {
    if (!template) return;
    if (!gridState) return;

    return dashboardGridsSlice.utils.createGridKey({
      module,
      gridId,
      dataSetId: gridState.clickThrough?.dataSetId,
      rowId: gridState.clickThrough?.rowId,
      columnId: gridState.clickThrough?.columnId,
      entityId: template.entityId,
    });
  }, [gridId, gridState, module, template]);

  const showError = useShellSelector((state) => {
    if (state.dashboardsV2.error) return true;
    return Boolean(gridState?.dataState.error);
  });

  const downloadSpreadsheet = useCallback(
    () =>
      dispatch(
        dashboardGridsSlice.downloadToSpreadsheet({
          gridId,
          gridKey,
          entityPath: gridState?.entityPath,
        }),
      ),
    [dispatch, gridId, gridKey, gridState?.entityPath],
  );

  const hasClearAllFilters = useShellSelector((state) => {
    const columnFiltersState = state.dashboardGrids.columnFilters[gridKey];
    if (!columnFiltersState) return false;

    return Object.values(columnFiltersState).some(
      (columnState) => columnState.selectedFilters?.length > 0,
    );
  });

  const clearAllColumnFilters = useCallback(() => {
    dispatch(dashboardGridsSlice.clearAllColumnFilters({ gridKey }));
  }, [dispatch, gridKey]);

  const isLoading = !gridState || !template;

  return showError ? (
    <SheetError
      isSpinning={isLoading}
      message={
        'There was a problem retrieving your data. You may not have permission to view this.'
      }
      detail={
        'Please contact your administrator if you think you are seeing this in error or would like to change your permissions.'
      }
    />
  ) : (
    <SheetLayout
      isSpinning={isLoading}
      title={title}
      overrideNoScrollPanel={true}
      rightTools={[
        hasClearAllFilters && {
          id: 'CLEAR_FILTERS',
          icon: Icons.filterListOff,
          onClick: clearAllColumnFilters,
          tooltip: 'Clears all column filters, showing all data.',
        },
        {
          id: 'DOWNLOAD_GRID',
          icon: Icons.cloudDownload,
          onClick: downloadSpreadsheet,
          tooltip: 'Download spreadsheet',
        },
      ].filter(Boolean)}
    >
      {!isLoading && (
        <DashboardGridContainer
          id={gridState.id}
          inViewport={true}
          sheetType={'FULL_SHEET'}
          dashboardType={
            dashboardType === 'CLICK_THROUGH_GRID'
              ? 'CLICK_THROUGH_GRID'
              : 'GRID'
          }
        />
      )}
    </SheetLayout>
  );
};

const clickThroughDashboardKey = (key: string): string => {
  if (key.includes('CLICK_THROUGH_GRID')) {
    const [, dashboardKey] = key.split(':');
    return dashboardKey.split('&')[0];
  }
  return key;
};
