import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/client/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { PageInfoResponse } from '@seeeverything/ui.util/src/types.ts';
import gql from 'graphql-tag';
import { QueryResult } from '../../types.ts';
import { DashboardClickThrough, DashboardTimespanVariable } from './types.ts';
import { addDisplayValueToFilter } from './util.ts';

export type DashboardFilterStatement = {
  columnId: string;
  filters: string[];
};

export type GridFilterArgs = {
  clickThrough?: DashboardClickThrough;
  columnId: string;
  dataSetId: string;
  entityId: string;
  entityType: 'Person' | 'Team';
  filterStatements?: DashboardFilterStatement[];
  gridId: string;
  pageNumber: number;
  search?: string;
  templateId: string;
  timespan: DashboardTimespanVariable;
};

export type DashboardColumnFilter = {
  value: string;
  displayValue: string;
};

export type DashboardGridFilterDto = {
  pageInfo: PageInfoResponse;
  filters: DashboardColumnFilter[];
};

export const getGridFilters = async (
  client: IGraphQLClient,
  args: GridFilterArgs,
): Promise<QueryResult<DashboardGridFilterDto>> => {
  try {
    const response = await client.query<{
      dashboards: {
        gridFilters: {
          pageInfo: PageInfoResponse;
          nodes: Array<{ value?: string; isNull?: boolean }>;
        };
      };
    }>({
      query: gql`
        query DashboardGridFilters(
          $clickThrough: DashboardClickThroughInput
          $columnId: ID!
          $dataSetId: ID!
          $entityId: ID!
          $entityType: DashboardPersonOrTeam!
          $filterStatements: [DashboardFilterStatementInput]
          $gridId: ID!
          $optionSearch: String
          $pageNumber: Int!
          $pageSize: Int!
          $templateId: ID!
          $timespan: DashboardTimespanFilterInput
        ) {
          dashboards {
            gridFilters(
              clickThrough: $clickThrough
              columnId: $columnId
              dataSetId: $dataSetId
              entityId: $entityId
              entityType: $entityType
              filterStatements: $filterStatements
              gridId: $gridId
              optionSearch: $optionSearch
              pagination: { pageNumber: $pageNumber, size: $pageSize }
              templateId: $templateId
              timespan: $timespan
            ) {
              pageInfo {
                currentPage
                hasNextPage
                pageSize
                totalCount
              }
              nodes {
                value
                isNull
              }
            }
          }
        }
      `,
      fetchPolicy: 'cache-first',
      variables: {
        clickThrough: args.clickThrough,
        columnId: args.columnId,
        dataSetId: args.dataSetId,
        entityId: args.entityId,
        entityType: args.entityType,
        filterStatements: args.filterStatements,
        gridId: args.gridId,
        optionSearch: args.search,
        pageNumber: args.pageNumber,
        pageSize: 50,
        templateId: args.templateId,
        timespan: args.timespan,
      },
    });

    const gridFilters = response.data.dashboards.gridFilters;
    if (!gridFilters) {
      log.error('Failed to retrieve dashboard grid filters');
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: {
        pageInfo: gridFilters.pageInfo,
        filters: gridFilters.nodes.map(({ value, isNull }) =>
          addDisplayValueToFilter(!isNull ? value : ''),
        ),
      },
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query dashboard grid filters - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
