/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormIssueCause } from '@se/data/forms/types.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import { IssueSecondaryCause } from './IssueSecondaryCause.tsx';

export interface IIssueSecondaryCausesDialogProps {
  issueCauses: FormIssueCause[];
  onCauseToggled: (issueCauseId: string) => void;
  onCauseDetailsChanged: (issueCauseId: string, to: string) => void;
  validationCauseIds: string[];
  canEdit: boolean;
  formLabel: string;
}

export const IssueSecondaryCausesDialog = ({
  formLabel,
  issueCauses,
  onCauseToggled,
  onCauseDetailsChanged,
  validationCauseIds,
  canEdit,
}: IIssueSecondaryCausesDialogProps) => (
  <div css={styles.base}>
    <Text
      size={48}
      color={color.format(-0.2)}
      weight={FontWeight.light}
      style={styles.header}
    >
      {'Secondary Causes'}
    </Text>
    <Text style={styles.description}>
      {canEdit
        ? 'Select any further/wider items that you believe are secondary causes.'
        : `The following items are selected as secondary causes of this issue. To make updates, you will need to re-open the ${formLabel}.`}
    </Text>
    <div css={styles.list}>
      {issueCauses.map((cause) => (
        <IssueSecondaryCause
          key={cause.issueCauseId}
          issueCauseId={cause.issueCauseId}
          canEdit={canEdit}
          details={cause.notes}
          isSelected={cause.isSelected}
          label={cause.label}
          onCauseDetailsChanged={onCauseDetailsChanged}
          onCauseToggled={onCauseToggled}
          showValidationError={validationCauseIds.some(
            (id) => id === cause.issueCauseId,
          )}
        />
      ))}
    </div>
  </div>
);

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: 10,
    margin: 20,
    maxHeight: 700,
  }),
  list: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    margin: '0 4px',
    overflow: 'hidden',
    overflowY: 'auto',
  }),
  description: css({
    margin: '0 16px',
  }),
  header: css({
    margin: '0 12px',
  }),
};
