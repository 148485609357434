import {
  assocPath,
  length,
  map,
  max,
  mergeRight,
  pipe,
  reduce,
  repeat,
  zipWith,
} from 'ramda';

export interface IChartData {
  key: string;
  value: string;
}
export interface IChartSeries {
  key: string;
  data: IChartData[];
}

/**
 * Converts a single series object into a simple flat array.
 */
export function flattenSeries(series: IChartSeries) {
  return series.data.map(({ key, value }) => ({
    name: key,
    [series.key]: isNaN(Number(value)) ? value : Number(value),
  }));
}

/**
 * Converts all series objects into a single flat array.
 */
export function mergeSeries(series: IChartSeries[]) {
  if (!series?.length) {
    return [];
  }

  const longest = pipe(map(length), reduce(max, 0));
  const flattened = series.map(flattenSeries);
  const combineSeriesArrays = reduce(
    zipWith(mergeRight),
    // N.B. zip needs initial acc to have enough values to match other inputs.
    repeat({}, longest(flattened) as number),
  );
  const mergedSeries = combineSeriesArrays(flattened);
  const seriesWithDepth = mergedSeries.map(mapKeysToDepth);

  return seriesWithDepth;
}

/**
 * Maps the keys of an object to their associated depth-wise paths.
 * This takes keys that have any dots (.) in their key names and creates a path like that in the object.
 *
 * @example
 * const object = {
 *    a: 'foo',
 *    b.c: 5,
 *    b.d: 3,
 *    b.e: 15,
 * }
 *
 * mapKeysToDepth(object)
 * // [{
 * //   a: 'foo'
 * //   b: {
 * //     c: 5,
 * //     d: 3,
 * //     e: 15,
 * //   }
 * // }]
 */
export const mapKeysToDepth = (object: object): object =>
  Object.entries(object).reduce((acc, [key, value]) => {
    const keyPath = key.split('.');
    return assocPath(keyPath, value, acc);
  }, {});
