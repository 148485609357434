/** @jsxImportSource @emotion/react */
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { css } from '@emotion/react';

export interface IEmptyProps {
  text?: string;
}
export const Empty = ({
  text = 'There are no recent comments to show.',
}: IEmptyProps) => (
  <Text color={color.format(-0.4)} size={14} style={styles.base}>
    {text}
  </Text>
);

const styles = {
  base: css({ margin: 8 }),
};
