import { BusinessUnit } from '@se/data/orgHierarchy/types.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';

export const businessUnitDropdownItems = (
  businessUnits: Record<string, BusinessUnit>,
): ISelectionListItem[] => {
  const selectionItems = Object.values(businessUnits).map((config) => ({
    id: `bu:${config.key}`,
    content: config.label,
    icon: Icons[config.icon],
  }));

  return selectionItems.length
    ? [
        {
          id: 'sectionBusinessUnit',
          type: 'SECTION',
          content: 'Business Unit',
        },
        ...selectionItems,
      ]
    : [];
};
