/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  FormsBulkUploadValidateFileUploadRow,
  FormsBulkUploadValidationRowStatus,
} from '@se/data/forms/types.ts';
import { formBulkUploadImportFileSlice } from '@seeeverything/ui.forms/src/redux/formBulkUploadImportFile/index.ts';
import {
  DataGrid,
  IDataGridColumn,
  RenderDataGridCell,
} from '@seeeverything/ui.primitives/src/components/DataGrid/index.ts';
import { IRenderGridCellArgs } from '@seeeverything/ui.primitives/src/components/Grid/types.ts';
import { TenantLocale } from '@seeeverything/ui.util/src/redux/tenant/index.ts';
import { useCallback, useMemo } from 'react';
import { useFormsDispatch } from '../../../redux/store.ts';
import { toStatusItem } from '../../../util/util.bulkUpload.ts';
import { StatusFilterCounts, StatusFilters } from './StatusFilters.tsx';

export type ValidationResultsProps = {
  locale: TenantLocale;
  rows: FormsBulkUploadValidateFileUploadRow[];
  statusCounts: StatusFilterCounts;
  statusFilters: FormsBulkUploadValidationRowStatus[];
};

/**
 * A grid view for results from a file validation from an import run.
 */
export const ValidationResults: React.FC<ValidationResultsProps> = ({
  locale,
  rows = [],
  statusCounts,
  statusFilters = [],
}) => {
  const dispatch = useFormsDispatch();
  const columns = useMemo(() => createColumns(locale), [locale]);

  const handleStatusChange = useCallback(
    (to: FormsBulkUploadValidationRowStatus) => {
      dispatch(
        formBulkUploadImportFileSlice.statusFilterChange({ filterValue: to }),
      );
    },
    [dispatch],
  );

  const data = useMemo(
    () =>
      rows.map((row) => ({
        id: row.rowNumber,
        data: [
          row.rowNumber,
          row.associatedEntities.subject,
          row.associatedEntities.assignedTo,
          row.status,
        ],
      })),
    [rows],
  );

  const renderCell = useMemo(
    (): RenderDataGridCell => (rowProps) => {
      if (rows && rowProps.columnIndex === COLUMN_INDEX_STATUS) {
        const row = rows.find(({ rowNumber }) => rowNumber === rowProps.row.id);
        return row ? toStatusItem(locale, row) : undefined;
      }
      return undefined;
    },
    [locale, rows],
  );

  const cellTheme = useCallback(
    ({ columnIndex, row }: IRenderGridCellArgs) => {
      const column = columns[columnIndex];
      if (!rows.some(({ rowNumber }) => rowNumber === row.id)) return {};

      return column.id === 'status'
        ? {
            baseStyle: styles.statusBase,
            baseStyle_active: styles.statusBase,
          }
        : {};
    },
    [columns, rows],
  );

  return (
    <div css={styles.base}>
      <StatusFilters
        onFilterChange={handleStatusChange}
        statusCounts={statusCounts}
        statusFilters={statusFilters}
      />
      <div css={styles.grid}>
        <DataGrid
          id={'BulkUploadValidationGrid'}
          columns={columns}
          data={data}
          isScrollable={true}
          isVirtualized={false}
          renderCellContents={renderCell}
          cellThemeOverrides={cellTheme}
        />
      </div>
    </div>
  );
};

const createColumns = (locale: TenantLocale): IDataGridColumn[] => [
  {
    id: 'rowNumber',
    label: 'Row',
    width: '*',
    borderRight: true,
    isSortable: false,
  },
  {
    id: 'subject',
    label: locale.label.formSubject,
    width: '2*',
    borderRight: true,
    isSortable: false,
  },
  {
    id: 'assignedTo',
    label: locale.label.formAssignedTo,
    width: '2*',
    borderRight: true,
    isSortable: false,
  },
  {
    id: 'status',
    label: 'Status',
    width: '5*',
    borderRight: true,
    isSortable: false,
  },
];
const COLUMN_INDEX_STATUS = 3;

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  }),
  statusBase: css({
    padding: '0 8px',
  }),
  grid: css({
    position: 'relative',
    height: 400,
  }),
};
