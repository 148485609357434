/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/constants.ts';

export interface IMaskProps {
  height: number;
  opacity?: number;
  color?: string;
}

export const Mask: React.FC<IMaskProps> = (props) => {
  const styles = {
    mask: css({
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      height: props.height,
      opacity: props.opacity,
      backgroundImage: `linear-gradient(-180deg, ${color
        .create(props.color || COLORS.BLUE)
        .alpha(1)
        .css()} 16%, ${color
        .create(props.color || COLORS.BLUE)
        .alpha(0)
        .css()} 100%)`,
    }),
  };
  return <div css={styles.mask} />;
};
