import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';

export interface ISaveDraftDefinitionResult {
  success: boolean;
  exception?: any;
}

export async function saveDraftDefinition(
  client: IGraphQLClient,
  id: string,
  definition: object,
) {
  try {
    const json = JSON.stringify(definition);
    return saveDraftDefinitionJson(client, id, json);
  } catch (error) {
    return fail(error);
  }
}

export async function saveDraftDefinitionJson(
  client: IGraphQLClient,
  id: string,
  definitionJson?: string,
): Promise<ISaveDraftDefinitionResult> {
  try {
    const response = await client.mutate<any>({
      mutation,
      variables: { id, definitionJson },
    });

    return succeeded(response) ? complete() : fail(response.errors);
  } catch (error) {
    return fail(error);
  }
}

const succeeded = (response: any) =>
  response &&
  response.data &&
  response.data.forms &&
  response.data.forms.updateFormsDesignerDraftDefinition &&
  response.data.forms.updateFormsDesignerDraftDefinition.ok;

const complete = () => ({
  success: true,
});

const fail = (exception: any) => {
  log.error(`Error while updating draft definition: ${exception}.`, exception);

  return {
    success: false,
    exception,
  };
};

const mutation = gql`
  mutation UpdateDesignerDraftDefinition($id: ID!, $definitionJson: String!) {
    forms {
      updateFormsDesignerDraftDefinition(
        input: { id: $id, definition: $definitionJson }
      ) {
        ok
      }
    }
  }
`;
