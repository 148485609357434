/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { IconButton } from '@seeeverything/ui.primitives/src/components/IconButton/IconButton.tsx';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { useCallback, useMemo } from 'react';
import {
  DistributionListRuleType,
  DistributionListType,
} from '../../../redux/distributionList/types.ts';
import { editDistributionListSlice } from '../../../redux/editDistributionList/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../../redux/store.ts';
import { PositionTitlesMultiSelectContainer } from './PositionTitlesMultiSelectContainer.tsx';
import { RuleRowButton } from './RuleRowButton.tsx';
import { TeamsBelowContainer } from './TeamsBelowContainer.tsx';
import { TeamsMultiSelectContainer } from './TeamsMultiSelectContainer.tsx';

export interface IRuleContainerProps {
  ruleId: string;
  listType: DistributionListType;
  ruleDropdownSelections: ISelectionListItem[];
}

export const RuleContainer: React.FC<IRuleContainerProps> = ({
  ruleId,
  listType,
  ruleDropdownSelections,
}) => {
  const dispatch = useFormsDispatch();

  const rule = useFormsSelector((state) =>
    state.formEditDistributionList.draft.rules?.find((r) => r.id === ruleId),
  );

  const isEnabled = useMemo(() => {
    if (!rule) return true;
    if (!rule?.selections) return true;
    return !rule.selections.length;
  }, [rule]);

  const buttonLabel = rule?.type ?? 'Select Rule';

  const selectedRule = rule?.type && {
    id: rule.type,
    value: rule.type,
    isEnabled: true,
    isSelectable: true,
  };

  const handleDeleteRule = useCallback(() => {
    dispatch(editDistributionListSlice.deleteRuleRow(ruleId));
  }, [dispatch, ruleId]);

  const handleRuleTypeChange = useCallback(
    (to: ISelectionListItem) => {
      dispatch(
        editDistributionListSlice.updateRuleRow({
          id: ruleId,
          selections: [],
          type: to.id.toString() as DistributionListRuleType,
        }),
      );
    },
    [dispatch, ruleId],
  );

  return (
    <div css={styles.container}>
      <div css={styles.containerItem}>
        <RuleRowButton
          buttonWidth={
            rule.type === DistributionListRuleType.IncludeTeamsBelow
              ? 125
              : undefined
          }
          dropdownItems={ruleDropdownSelections}
          dropdownWidth={listType === 'Team' ? 230 : 292}
          error={rule.errors && !selectedRule ? rule.errors : undefined}
          isEnabled={isEnabled}
          label={buttonLabel}
          onSelect={handleRuleTypeChange}
        />
      </div>
      <div css={styles.containerItem}>
        {rule.type === DistributionListRuleType.IncludeTeamsBelow && (
          <TeamsBelowContainer ruleId={ruleId} />
        )}
        {rule.type === DistributionListRuleType.IncludePositionTitles && (
          <PositionTitlesMultiSelectContainer ruleId={rule.id} />
        )}
        {rule.type === DistributionListRuleType.ExcludePositionTitles && (
          <PositionTitlesMultiSelectContainer ruleId={rule.id} />
        )}
        {rule.type === DistributionListRuleType.IncludeTeams && (
          <TeamsMultiSelectContainer ruleId={rule.id} />
        )}
        {rule.type === DistributionListRuleType.ExcludeTeams && (
          <TeamsMultiSelectContainer ruleId={rule.id} />
        )}
      </div>
      <div css={styles.containerItem}>
        <IconButton
          icon={<Icons.delete title={'Delete'} />}
          onClick={handleDeleteRule}
          tooltip={'Delete Rule'}
        />
      </div>
    </div>
  );
};

const styles = {
  container: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  }),
  containerItem: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
  }),
};
