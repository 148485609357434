/** @jsxImportSource @emotion/react */
import { GridCellDecorator } from '../../types.ts';

export const maskedDecorator: GridCellDecorator = (args) => {
  const { cellTheme, gridState, datapoint } = args;
  if (!gridState) {
    return {};
  }

  if (datapoint.isMasked) {
    return {
      icon: {
        color: cellTheme.textColor,
      },
      text: {
        color: cellTheme.textColor,
        italic: true,
      },
    };
  }

  return {};
};
