import { GridCellDecorator } from '../../types.ts';

export const alignmentDecorator: GridCellDecorator = (args) => {
  const columnDefinition = args.columnDefinition;
  if (!columnDefinition?.align) {
    return { alignment: 'left' };
  }

  return { alignment: columnDefinition.align };
};
