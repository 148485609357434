/** @jsxImportSource @emotion/react */
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import { css } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { BadgeType } from '../types.ts';

export interface IBadgeProps {
  value?: number | string;
  type: BadgeType;
}

/**
 * A badge with a status number.
 */
export const Badge: React.FC<IBadgeProps> = ({
  value = 0,
  type = 'default',
}) => (
  <div css={type === 'default' ? styles.default : styles.error}>
    <Text
      weight={FontWeight.black}
      size={12}
      color={type === 'default' ? color.format(-0.4) : 'rgba(0,0,0,0.4)'}
      textShadow={[1, 0.6]}
      selectable={false}
      style={styles.text}
    >
      {value}
    </Text>
  </div>
);

const styles = {
  default: css({
    boxSizing: 'border-box',
    backgroundColor: color.format(-0.1),
    padding: '0px 6px',
    borderRadius: 50,
    border: `solid 1px ${color.format(-0.05)}`,
    minWidth: 31,
    textAlign: 'center',
  }),
  error: css({
    boxSizing: 'border-box',
    backgroundColor: color.RED,
    padding: '0px 6px',
    borderRadius: 50,
    minWidth: 31,
    textAlign: 'center',
  }),
  text: css({ top: -1 }),
};
