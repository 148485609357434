/** @jsxImportSource @emotion/react */
import React from 'react';
import { ISelectionListItem } from '../SelectionList/types.ts';
import {
  ITextFieldDropdownProps,
  TextFieldDropdown,
} from './TextFieldDropdown.tsx';

export type TextFieldDropdownAsyncLoadOptions = {
  filter?: string;
  pageNumber?: number;
  pageSize?: number;
  queryParameters?: { [key: string]: any };
  selectionListItemMaxWidth?: string | number;
};

export interface ILoadPageResults {
  items: ISelectionListItem[];
  pageNumber: number;
  hasNextPage: boolean;
}

export type TextFieldDropdownAsyncLoad<
  O extends
    TextFieldDropdownAsyncLoadOptions = TextFieldDropdownAsyncLoadOptions,
> = (options?: O) => Promise<ILoadPageResults>;

export interface ITextFieldDropdownAsyncProps extends ITextFieldDropdownProps {
  load?: TextFieldDropdownAsyncLoad;
  loadPageSize?: number;
  shouldReloadOnOpen?: boolean;
  loadedSelectionsFilter?: (
    items: ISelectionListItem[],
  ) => ISelectionListItem[]; // Allows the caller to provide an additional filter to loaded selections without recalling the async load function.
  reloadFlag?: boolean | number | string; // If set, dropdown will reload when this flag changes.
}

interface ITextFieldDropdownAsyncState {
  selections: ISelectionListItem[];
  filter: string;
  pageNumber: number;
  hasNextPage: boolean;
  isLoaded: boolean;
  shouldPreventLoad: boolean;
  shouldReloadCurrentItems: boolean;
}

/**
 * Provides an async props handler for loading dropdown items.
 */
export const TextFieldDropdownAsync: React.FC<ITextFieldDropdownAsyncProps> = (
  props,
) => {
  const {
    load,
    loadPageSize,
    onInputChanged,
    loadedSelectionsFilter,
    shouldReloadOnOpen = false,
    onOpen,
    ...textFieldDropdownProps
  } = props;

  const [state, setState] = React.useState<ITextFieldDropdownAsyncState>({
    selections: [],
    filter: '',
    pageNumber: 0,
    hasNextPage: true,
    isLoaded: false,
    shouldPreventLoad: false,
    shouldReloadCurrentItems: false,
  });

  const mergeSelections = (
    current: ISelectionListItem[],
    next: ISelectionListItem[],
  ) => [...current, ...next];

  const setInputFilter = (input: string, reason: 'reset' | 'input') => {
    if (!state.shouldPreventLoad) {
      setState((prevState) => ({
        ...prevState,
        filter: reason === 'input' ? input : '',
        isLoaded: false,
        pageNumber: 0,
        hasNextPage: true,
        selections: [],
      }));
    }
    onInputChanged?.(input, reason);
  };

  // Reload flag has changed so the data will be refetched.
  React.useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      shouldReloadCurrentItems: true,
      selections: [],
      isLoaded: false,
    }));
  }, [props.reloadFlag]);

  React.useEffect(() => {
    if (!state.isLoaded) {
      const currentItems = state.selections;
      const filter = state.filter;

      if (!load) {
        return undefined;
      }

      const reason = state.shouldReloadCurrentItems ? 'RELOAD' : 'NEXT_PAGE';
      if (
        !state.shouldReloadCurrentItems &&
        (!state.hasNextPage || state.shouldPreventLoad)
      ) {
        return undefined;
      }

      let active = true;

      const nextPageNumber = state.shouldReloadCurrentItems
        ? 1
        : state.pageNumber + 1;

      (async () => {
        const { items: nextItems, hasNextPage: nextHasNextPage } = await load({
          filter,
          pageSize: loadPageSize,
          pageNumber: nextPageNumber,
        });

        if (active) {
          // Optimization to prevent any further loading if all results are returned with an empty filter.
          const shouldPreventLoad = nextHasNextPage === false && filter === '';

          const merged =
            reason === 'NEXT_PAGE'
              ? mergeSelections(currentItems, nextItems)
              : nextItems;

          setState((prevState) => ({
            ...prevState,
            shouldPreventLoad,
            selections: merged,
            pageNumber: nextPageNumber,
            hasNextPage: nextHasNextPage,
            isLoaded: true,
            shouldReloadCurrentItems: false,
          }));
        }
      })();

      return () => {
        active = false;
      };
    }

    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isLoaded, state.filter, state.shouldReloadCurrentItems]);

  const handleDropdownOpened = () => {
    if (shouldReloadOnOpen) {
      setState((prevState) => ({
        ...prevState,
        shouldReloadCurrentItems: true,
        selections: [],
        isLoaded: false,
      }));
    }
    onOpen?.();
  };

  return (
    <TextFieldDropdown
      {...textFieldDropdownProps}
      selections={
        loadedSelectionsFilter
          ? loadedSelectionsFilter(state.selections)
          : state.selections
      }
      onInputChanged={setInputFilter}
      isLoading={!state.isLoaded && state.selections.length === 0}
      shouldFilter={true}
      onOpen={handleDropdownOpened}
    />
  );
};
