import {
  IEntityDictionary,
  IEntityConfig,
  IEntityConfigs,
} from '../../../api/api.queryBuilder/types.ts';
import { IIcon } from '@seeeverything/ui.primitives/src/components/Icon/types.ts';

export interface IGetEntityOptions {
  entities: IEntityDictionary;
  type: string;
  value?: string;
}

/**
 * Looks up an icon from the given entities.
 */
export function getIcon(options: IGetEntityOptions): IIcon | undefined {
  const entity = getEntity(options);
  return entity?.icon;
}

/**
 * Looks up an icon from the given entities.
 */
export function getLabel(options: IGetEntityOptions): string | undefined {
  const entity = getEntity(options);
  return entity?.label;
}

/**
 * Retrieves the given entity from a dictionary.
 */
export function getEntity(
  options: IGetEntityOptions,
): IEntityConfig | undefined {
  const { entities, type, value } = options;
  const entity = entities[type];
  return (
    (entity && value && (entity as IEntityConfigs)[value]) ||
    (entity as IEntityConfig)
  );
}
