import * as R from 'ramda';
import gql from 'graphql-tag';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';

type DeleteFormTemplateResult = {
  success: boolean;
  exception?: any;
};

export async function deleteFormTemplate(
  client: IGraphQLClient,
  designerDraftId: string,
  templateId?: string,
): Promise<DeleteFormTemplateResult> {
  try {
    //If template has been published, check successfully archived before archiving draft
    if (templateId) {
      const templateArchiveResponse = await client.mutate<any>({
        mutation: mutationArchiveTemplate,
        variables: { templateId },
      });

      if (!isSuccessTemplateOnResponse(templateArchiveResponse))
        return fail(templateArchiveResponse.errors);
    }

    const draftArchiveResponse = await client.mutate<any>({
      mutation: mutationArchiveDraft,
      variables: { designerDraftId },
    });

    return isSuccessDraftOnResponse(draftArchiveResponse)
      ? complete()
      : fail(draftArchiveResponse.errors);
  } catch (error) {
    return fail(error);
  }
}

const isSuccessTemplateOnResponse = R.path([
  'data',
  'forms',
  'archiveFormTemplate',
  'ok',
]);
const isSuccessDraftOnResponse = R.path([
  'data',
  'forms',
  'archiveDesignerDraft',
  'ok',
]);

const complete = () => ({
  success: true,
});

const fail = (exception: any) => {
  log.error(`Error while archiving template: ${exception}.`, exception);

  return {
    success: false,
    exception,
  };
};

const mutationArchiveTemplate = gql`
  mutation ArchiveFormTemplate($templateId: ID!) {
    forms {
      archiveFormTemplate(id: $templateId) {
        ok
      }
    }
  }
`;

const mutationArchiveDraft = gql`
  mutation ArchiveDesignerDraft($designerDraftId: ID!) {
    forms {
      archiveDesignerDraft(id: $designerDraftId) {
        ok
      }
    }
  }
`;
