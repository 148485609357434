import * as types from './types.ts';

const DEFAULT_STATE: types.SheetsState = {
  statusBar: {
    kind: 'ERROR',
    message: '',
    isShowing: false,
    autoHideDuration: 5000,
  },
  dateRange: {},
};

export const reducer = (
  state: types.SheetsState = DEFAULT_STATE,
  action: types.SheetsAction,
): types.SheetsState => {
  switch (action.type) {
    case 'ui.shell/sheets/DATE_RANGE/CLEAR_DATE_RANGE': {
      return {
        ...state,
        dateRange: {
          ...state.dateRange,
          start: undefined,
          end: undefined,
          showStartValidation: false,
          showEndValidation: false,
        },
      };
    }

    case 'ui.shell/sheets/DATE_RANGE/CHANGE_DATE_RANGE': {
      const kindKey = action.payload.kind === 'START' ? 'start' : 'end';
      const validationKey =
        kindKey === 'start' ? 'showStartValidation' : 'showEndValidation';

      return {
        ...state,
        dateRange: {
          ...state.dateRange,
          [kindKey]: action.payload.to,
          [validationKey]: false,
        },
      };
    }

    case 'ui.shell/sheets/DATE_RANGE/FIELD_REQUIRED': {
      const fieldRequired =
        action.payload.required === 'START_DATE'
          ? 'showStartValidation'
          : 'showEndValidation';
      return {
        ...state,
        dateRange: {
          ...state.dateRange,
          [fieldRequired]: true,
        },
      };
    }

    case 'ui.shell/sheets/ADD_SHEET': {
      return {
        ...state,
        current: action.payload.sheet,
      };
    }

    case 'ui.shell/sheets/REMOVE_SHEET':
      return {
        ...state,
        current: undefined,
      };

    case 'ui.shell/sheets/CLEAR':
      return {
        ...state,
        current: undefined,
      };

    case 'ui.shell/sheets/HIDE_STATUS_BAR':
      return {
        ...state,
        statusBar: {
          ...state.statusBar,
          isShowing: false,
        },
      };

    case 'ui.shell/sheets/SHOW_STATUS_BAR':
      return {
        ...state,
        statusBar: {
          isShowing: true,
          kind: action.payload.kind,
          message: action.payload.message,
          autoHideDuration: action.payload.autoHideDuration,
        },
      };

    case 'ui.shell/sheets/SHOW_ALERT_DIALOG':
      return {
        ...state,
        alertDialogId: action.payload.id,
      };

    case 'ui.shell/sheets/HIDE_ALERT_DIALOG':
      return {
        ...state,
        alertDialogId: undefined,
      };

    default:
      return state;
  }
};
