import { getBusinessUnits } from '@se/data/orgHierarchy/utils/businessUnits.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { QueryConfig } from '@seeeverything/ui.shell/src/api/api.queryBuilder/QueryConfig.ts';
import {
  TenantConfiguration,
  TenantLocale,
} from '@seeeverything/ui.util/src/redux/tenant/index.ts';
import { getBusinessEntities, getModules } from './entities.ts';
import { dropdownFactory } from './factory.tsx';
import * as router from './routes/index.ts';

export async function init(
  tenantConfig: TenantConfiguration,
  locale: TenantLocale,
) {
  const businessUnits = getBusinessUnits(tenantConfig);

  return new QueryConfig()
    .entities({
      bu: Object.entries(businessUnits).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: {
            label: value.label,
            icon: Icons[value.icon],
          },
        }),
        {},
      ),
      module: getModules(tenantConfig),
      ...getBusinessEntities(locale),
    })
    .dropdownFactory(dropdownFactory)
    .routes(router.team)
    .routes(router.people)
    .routes(router.form)
    .routes(router.root);
}
