/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { IDesignerCommonProps } from '../types.ts';
import { DesignerSection } from '../DesignerSection/DesignerSection.tsx';
import { getValidationError, handleChangeEvent } from '../util.ts';
import {
  IFormFile,
  IFormRejectedFile,
} from '@seeeverything/ui.forms/src/redux/form-file/types.ts';
import { FILE_UPLOAD_DEFAULTS } from '../../common/constants.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { FormFilesPanel } from '@seeeverything/ui.forms/src/components/FormFilesPanel/FormFilesPanel.tsx';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { Checkbox } from '@seeeverything/ui.primitives/src/components/Checkbox/Checkbox.tsx';
import { Switch } from '../Switch/Switch.tsx';

export interface IDesignerDigitalContentProps extends IDesignerCommonProps {
  commentsEnabled?: boolean;
  commentsGuidance?: string;
  description?: string;
  designerHintText?: string;
  files?: IFormFile[];
  isEnabled?: boolean;
  rejections?: IFormRejectedFile[];
  title?: string;
}

export const DesignerDigitalContent: React.FC<IDesignerDigitalContentProps> = ({
  commentsEnabled,
  commentsGuidance,
  description,
  designerHintText = 'Marks the Digital Content section as enabled.',
  files,
  isEnabled,
  onChange,
  onEventOccurred,
  onFileDelete,
  onFileUpload,
  onFileRejected,
  rejections,
  showBoundaryLines,
  showBullet = true,
  title,
  validationError,
}) => {
  const handleRejectedFile = React.useCallback(
    (rejectedFiles: File[]) => {
      onFileRejected?.(rejectedFiles, FILE_UPLOAD_DEFAULTS.maxSize);
    },
    [onFileRejected],
  );

  const computedStyles = {
    base: css({
      border: showBoundaryLines
        ? `dashed 1px ${color.format(-0.2)}`
        : 'solid 1px rgba(0, 0, 0, 0)',
      borderRadius: showBoundaryLines ? 8 : undefined,
    }),
  };

  const elDesignerContentSummary = (
    <DesignerSection
      id={'digital-content-title'}
      showBoundaryLines={false}
      title={title}
      label={'Summary'}
      onChange={onChange}
      onEventOccurred={onEventOccurred}
      style={styles.designerSection}
      error={getValidationError(validationError?.fields, 'title')}
    />
  );

  const elDesignerContentDescription = (
    <TextField
      id={'digital-content-description'}
      onChange={handleChangeEvent(
        'digital-content-description',
        'LABEL',
        onChange,
      )}
      label={'Description'}
      style={styles.field}
      value={description}
      multiline={true}
      error={getValidationError(validationError?.fields, 'description')}
    />
  );

  const elDesignerContentAllowComments = (
    <Checkbox
      label={'Allow Comments'}
      isChecked={commentsEnabled}
      isEnabled={true}
      helperText={
        'Enables participants to reply to this content with their comments.'
      }
      style={styles.checkbox}
      onCheck={handleChangeEvent(
        `digital-content-comments-enabled`,
        'SWITCH',
        onChange,
      )}
    />
  );

  const elDesignerContentCommentsGuidance = (
    <TextField
      id={'digital-content-comments-guidance'}
      onChange={handleChangeEvent(
        'digital-content-comments-guidance',
        'LABEL',
        onChange,
      )}
      label={'Comment Guidance'}
      style={styles.field}
      value={commentsGuidance}
      multiline={true}
      error={getValidationError(validationError?.fields, 'commentsGuidance')}
    />
  );

  const elFileUploadPanel = (
    <div css={styles.field}>
      <Text style={styles.fileUploadLabel}>{'Content Files'}</Text>
      <FormFilesPanel
        allowedMimeTypes={FILE_UPLOAD_DEFAULTS.allowedMimeTypes}
        allowedTypesReadable={FILE_UPLOAD_DEFAULTS.allowedTypesReadable}
        canDelete={true}
        canUpload={true}
        emptySize={'small'}
        files={files}
        maxSize={FILE_UPLOAD_DEFAULTS.maxSize}
        onDelete={onFileDelete}
        onFilesRejected={handleRejectedFile}
        onFilesUpload={onFileUpload}
        rejections={rejections}
      />
    </div>
  );

  return (
    <div>
      <div css={[styles.base, computedStyles.base]}>
        {elDesignerContentSummary}
        {elDesignerContentDescription}
        {elFileUploadPanel}
        {elDesignerContentAllowComments}
        {commentsEnabled && elDesignerContentCommentsGuidance}
      </div>
      <Switch
        id={'digital-content-enabled'}
        isEnabled={isEnabled}
        canDelete={false}
        showBoundaryLines={showBoundaryLines}
        descriptionToEnable={designerHintText}
        descriptionToDisable={designerHintText}
        editingLabel={'Enabled'}
        onChange={onChange}
        showBullet={showBullet}
        onEventOccurred={onEventOccurred}
      />
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    justifyContent: 'center',
    flex: '1 1 auto',
    minHeight: 80,
    marginBottom: 5,
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  }),
  field: css({
    width: '100%',
    marginBottom: 20,
    paddingRight: 20,
    paddingLeft: 40,
  }),
  checkbox: css({
    width: '100%',
    marginBottom: 20,
    paddingRight: 20,
    paddingLeft: 40,
  }),
  fileUploadLabel: css({
    marginBottom: 20,
    color: 'rgba(0,0,0,0.45)',
    fontSize: '12px',
  }),
  designerSection: css({ flex: '1 1 auto' }),
};
