/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import React from 'react';

const BLEED = 9;

export interface ISheetProps {
  children?: React.ReactNode;
  backgroundColor?: string;
  hasSideShadow?: boolean;
  top?: number;
  left?: number;
  right?: number;
  isFirst?: boolean;
  isLast?: boolean;
}

/**
 * A single sheet container.
 */
export class Sheet extends React.Component<ISheetProps, object> {
  public render() {
    const {
      top = 0,
      left = 0,
      right = 0,
      hasSideShadow = false,
      isFirst = false,
      isLast = false,
    } = this.props;

    const topShadowColor = isFirst ? -0.08 : hasSideShadow ? -0.1 : -0.18;

    const styles = {
      base: css({
        position: 'absolute',
        top: top - BLEED,
        right: left - BLEED,
        bottom: 0,
        left: right - BLEED,
        boxSizing: 'border-box',
        overflow: 'hidden',
      }),
      content: css({
        backgroundColor: this.props.backgroundColor || '#fff',
        position: 'absolute',
        top: BLEED,
        right: BLEED,
        bottom: 0,
        left: BLEED,
        borderRadius: '3px 3px 0 0',
        display: 'flex',
        overflow: 'hidden',
        boxSizing: 'border-box',
      }),
    };

    const shadow = {
      top: css({
        position: 'absolute',
        top: -BLEED,
        right: BLEED,
        left: BLEED,
        height: 20,
        overflow: 'hidden',
      }),
      inner: css({
        position: 'absolute',
        right: 0,
        bottom: -13,
        left: 0,
        boxShadow: `0px -5px 5px ${color.format(topShadowColor)}`,
        height: 10,
      }),
      side: css({
        position: 'absolute',
        top: BLEED + 5,
        right: BLEED,
        bottom: 0,
        left: BLEED,
        boxShadow: `0px 0px 15px ${color.format(-0.18)}`,
      }),
    };

    return (
      <div css={styles.base}>
        <div css={css(hasSideShadow === true ? shadow.side : undefined)} />
        <div css={shadow.top}>
          <div css={shadow.inner} />
        </div>

        <div css={styles.content}>{isLast && this.props.children}</div>
      </div>
    );
  }
}
