/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { useEffect, useState } from 'react';
import { useViewport } from '../../../hooks/useViewport.ts';
import { Icons } from '../../Icon/Icons.tsx';
import { Spinner } from '../../Spinner/index.ts';
import { TextButton } from '../../TextButton/TextButton.tsx';

export interface ILoadMoreButtonProps {
  onClick: () => void;
  onInView?: () => void;
  isLoading?: boolean;
  size: 'LARGE' | 'MEDIUM';
  text?: string;
  icon?: string;
  dataTest?: string;
}

const BORDER_COLOR = color.format(-0.1);

export const LoadMoreButton: React.FC<ILoadMoreButtonProps> = ({
  dataTest,
  icon = 'launch',
  isLoading = false,
  onClick,
  onInView,
  size,
  text = 'Load more',
}) => {
  const [forwardedRef, inViewport] = useViewport();
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    if (!onInView) return;

    if (inViewport && !isInView) {
      onInView();
      setIsInView(true);
    }

    if (!inViewport && isInView) setIsInView(false);
  }, [inViewport, isInView, onInView]);

  const border = `solid 1px ${BORDER_COLOR}`;
  const styles = {
    base: css({
      position: 'relative',
      height: size === 'LARGE' ? 37 : 27,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      borderBottom: border,
      boxSizing: 'border-box',
    }),
  };

  const elButton = !isLoading && (
    <TextButton
      onClick={onClick}
      size={14}
      buttonSize={size === 'LARGE' ? 'medium' : 'small'}
      icon={icon && Icons[icon]}
      fullWidth={true}
      iconSide={'RIGHT'}
    >
      {text}
    </TextButton>
  );
  return (
    <div data-test={dataTest} css={styles.base} ref={forwardedRef}>
      {isLoading && <Spinner size={24} center={true} />}
      {elButton}
    </div>
  );
};
