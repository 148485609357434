/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ExportableQuestion } from '@seeeverything/ui.forms/src/redux/formBulkUploadDownloadTemplate/formBulkUploadDownloadTemplateSlice.ts';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Checkbox } from '@seeeverything/ui.primitives/src/components/Checkbox/Checkbox.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { useCallback } from 'react';
import { QuestionSelectionEvent } from '../types.ts';

export interface IQuestionsSelectionProps {
  exportableQuestions: ExportableQuestion[];
  onQuestionSelected: (e: QuestionSelectionEvent) => void;
  onSectionHeadingSelected: (e: QuestionSelectionEvent) => void;
}

/**
 * List of checkboxes for selecting form template question keys.
 */
export const QuestionsSelection: React.FC<IQuestionsSelectionProps> = ({
  exportableQuestions,
  onQuestionSelected,
  onSectionHeadingSelected,
}) => {
  const handleCheckBoxChange = useCallback(
    (id: string, isSelected: boolean, isEnabled = true) =>
      () =>
        isEnabled ? onQuestionSelected({ id, isSelected }) : undefined,
    [onQuestionSelected],
  );

  const handleHeadingClicked = useCallback(
    (id: string, isSelected: boolean, isEnabled = true) =>
      () =>
        isEnabled ? onSectionHeadingSelected({ id, isSelected }) : undefined,
    [onSectionHeadingSelected],
  );

  return (
    <div css={styles.base}>
      {exportableQuestions
        .filter((question) => question.isDownloadOnly !== true)
        .map(
          ({
            id,
            label,
            isSelected,
            isEnabled,
            isHeading,
            isHiddenByDefault,
          }) =>
            isHeading ? (
              <Button
                key={id}
                style={styles.headingContainer(isEnabled)}
                onClick={handleHeadingClicked(id, !isSelected, isEnabled)}
                hoverAlpha={0.1}
              >
                <div>
                  <Checkbox
                    labelStyle={styles.checkboxLabel}
                    isChecked={isSelected === true}
                    isEnabled={isEnabled}
                    indeterminate={isSelected === 'indeterminate'}
                  />
                  <Text style={styles.headingText} cursor={'inherit'}>
                    {label ?? id}
                  </Text>
                </div>
              </Button>
            ) : (
              <Button
                key={id}
                style={styles.checkbox(isEnabled, isHiddenByDefault)}
                onClick={handleCheckBoxChange(id, !isSelected, isEnabled)}
                isEnabled={isEnabled}
              >
                <div css={styles.checkboxItem}>
                  <Checkbox
                    key={id}
                    indeterminate={isSelected === 'indeterminate'}
                    isChecked={isSelected === true}
                    isEnabled={isEnabled}
                    labelStyle={styles.checkboxLabel}
                  />
                  <Text cursor={'inherit'} size={15}>
                    {label ?? id}
                  </Text>
                </div>
              </Button>
            ),
        )}
    </div>
  );
};

const styles = {
  headingText: css({
    fontSize: 12,
    lineHeight: 2,
    textTransform: 'uppercase',
    fontWeight: 700,
    textShadow: `0px 0px ${color.format(1)}`,
    color: color.format(-0.35),
    top: 0,
  }),
  checkboxLabel: {
    marginRight: 0,
    cursor: 'inherit',
  },
  headingContainer: (isEnabled: boolean) => ({
    display: 'flex',
    backgroundColor: '#F4F4F4',
    padding: '0 12px',
    justifyContent: 'flex-start',
    cursor: isEnabled === false ? 'not-allowed' : 'pointer',
  }),
  checkboxItem: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }),
  checkbox: (isEnabled: boolean, isHiddenByDefault: boolean) => ({
    padding: '0 12px',
    fontStyle: isHiddenByDefault ? 'italic' : undefined,
    cursor: isEnabled === false ? 'not-allowed' : 'pointer',
    ':hover': {
      backgroundColor: color.format(-0.06),
    },
  }),
  base: css({
    maxHeight: '40vh',
    flex: 1,
    overflow: 'auto',
    margin: '12px 0',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  }),
};
