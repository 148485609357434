import { log } from '@seeeverything/ui.util/src/log/log.ts';
import {
  dashboardChartSchema,
  DashboardChartSchemaTypes,
} from '../schema/dashboardChartSchema.ts';
import { IDashboardChart } from '../types.ts';

const BAR_WIDTH: { [key: string]: number } = {
  'extra-small': 20,
  small: 35,
  medium: 50,
  large: 70,
  'extra-large': 85,
};

/**
 * Attempts to parse and translate to a set of chart props.
 */
export function parser(item: any, index: number): IDashboardChart | undefined {
  if (!item.chart) {
    return;
  }

  const validatedParse = dashboardChartSchema.chart.safeParse(item.chart);
  if (!validatedParse.success) {
    log.error(
      new Error(`Chart with id ${item.chart.id} failed schema validation`),
    );
    return;
  }

  const chart = validatedParse.data;

  const dataKeyIconMapping = createDataKeyIconMapping(chart);

  return {
    componentType: 'CHART',
    index,
    dataState: {
      dataSetId: chart['data-set'],
      isLoaded: false,
      isLoading: false,
      forceReload: false,
    },
    group: chart.group,
    isHidden: false,
    title: chart.title,
    kind: chart.kind,
    id: chart.id,
    legend: { icons: dataKeyIconMapping },
    tooltip: { icons: dataKeyIconMapping },
    bars: chart.bars?.map((bar) => ({
      dataKey: bar['data-key'],
      stackId: bar['stack-id'],
      name: bar.label,
      color: bar.color,
      stroke: bar.color,
      strokeWidth: 0.1,
      maxBarSize: BAR_WIDTH[bar.width],
      background: bar.background
        ? {
            fill: bar.background,
            stroke: 'rgba(0, 0, 0, 0.05)',
          }
        : undefined,
    })),
    lines: chart.lines?.map((line) => ({
      dataKey: line['data-key'],
      name: line.label,
      stroke: line.color,
      strokeWidth: line['stroke-width'] || 2,
      dataIsPercentage: line['data-is-percentage'],
    })),
    barCategoryGap: chart['bar-category-gap'],
  };
}

const createDataKeyIconMapping = (
  chart: DashboardChartSchemaTypes['Chart'],
): Record<string, string> => {
  const result: Record<string, string> = {};

  if (chart.bars) {
    chart.bars.forEach((bar) => {
      const key = bar['data-key'];
      result[key] = 'barChart';
    });
  }

  if (chart.lines) {
    chart.lines.forEach((line) => {
      const key = line['data-key'];
      result[key] = 'showChart';
    });
  }

  return result;
};
