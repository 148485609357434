/** @jsxImportSource @emotion/react */
import { BulkUploadFileImportDialog } from '@seeeverything/ui.forms/src/components/BulkUploadFileImportDialog/BulkUploadFileImportDialog.tsx';
import { formBulkUploadImportFileSlice } from '@seeeverything/ui.forms/src/redux/formBulkUploadImportFile/index.ts';
import { IActionBarButton } from '@seeeverything/ui.primitives/src/components/ModalDialog/index.ts';
import DialogOuter from '@seeeverything/ui.shell/src/components/DialogOuter/DialogOuter.tsx';
import { hideModalDialog } from '@seeeverything/ui.shell/src/redux/modalDialog/actions.ts';
import {
  useShellDispatch,
  useShellSelector,
} from '@seeeverything/ui.shell/src/redux/store.ts';
import { useMemo } from 'react';

export const BulkUploadFileImportDialogContainer: React.FC = () => {
  const dispatch = useShellDispatch();

  const validatedFile = useShellSelector(
    (state) => state.formBulkUploadImportFile.validatedFile,
  );

  const validationResult = useShellSelector(
    (state) => state.formBulkUploadImportFile.validationResult,
  );

  const isLoading = useShellSelector(
    (state) => state.formBulkUploadImportFile.isLoading,
  );

  const workflowType = useShellSelector(
    (state) => state.formBulkUploadImportFile.importSettings.workflow,
  );

  const createAutomatedActions = useShellSelector(
    (state) =>
      state.formBulkUploadImportFile.importSettings.createAutomatedActions,
  );

  const module = useShellSelector((state) => state.tenantState.tenant.module);

  const includeAutomatedActions = useMemo(
    () => module === 'compliance',
    [module],
  );

  const hasDuplicateRows = useMemo(
    () =>
      validationResult?.rows.find((row) =>
        row.errors.some((error) => error.code === 'DUPLICATED_PREVIOUS_IMPORT'),
      ),
    [validationResult],
  );

  const isValid =
    validationResult &&
    validationResult.errors.length === 0 &&
    !validationResult.rows.every((row) => row.status === 'Error');

  const actionButtons: IActionBarButton[] = useMemo(
    () => [
      {
        id: 'Cancel',
        label: 'Cancel',
        isEnabled: true,
        onAction: () => {
          dispatch(hideModalDialog());
          dispatch(formBulkUploadImportFileSlice.clear());
        },
      },
      {
        id: 'Import',
        label: 'Import',
        isEnabled: Boolean(!isLoading && isValid),
        onAction: () => {
          if (!isValid) return;
          if (hasDuplicateRows)
            return dispatch(
              formBulkUploadImportFileSlice.showDuplicateRowImportDialog(),
            );

          dispatch(
            formBulkUploadImportFileSlice.importFile({
              file: validatedFile,
              workflow: workflowType,
              createAutomatedActions,
            }),
          );
        },
      },
    ],
    [
      createAutomatedActions,
      dispatch,
      hasDuplicateRows,
      isLoading,
      isValid,
      validatedFile,
      workflowType,
    ],
  );

  return (
    <DialogOuter actions={actionButtons}>
      <BulkUploadFileImportDialog
        isLoading={isLoading}
        validationResult={validationResult}
        createAutomatedActions={createAutomatedActions}
        includeAutomatedActions={includeAutomatedActions}
      />
    </DialogOuter>
  );
};
