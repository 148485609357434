import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';

export type FormsDesignerReduxDropdownsState = {
  designerTemplates: {
    isLoading: boolean;
    items: ISelectionListItem[];
  };
};

const DEFAULT_STATE: FormsDesignerReduxDropdownsState = {
  designerTemplates: {
    isLoading: false,
    items: [],
  },
};

const slice = createSlice({
  name: 'libs/formsDesigner/dropdowns',
  initialState: DEFAULT_STATE,
  reducers: {
    loadDesignerTemplatesPage(state) {
      state.designerTemplates.items = [];
      state.designerTemplates.isLoading = true;
    },
    loadedDesignerTemplatesPage(
      state,
      action: PayloadAction<ISelectionListItem[]>,
    ) {
      state.designerTemplates.items = action.payload;
      state.designerTemplates.isLoading = false;
    },
  },
});

export const { loadDesignerTemplatesPage, loadedDesignerTemplatesPage } =
  slice.actions;
export const reducer = slice.reducer;
export type State = FormsDesignerReduxDropdownsState;
