/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { DashboardColumnFilter } from '@se/data/dashboards/query/gridFilters.ts';
import { CommonStyles } from '@seeeverything/ui.primitives/src/common/commonStyles.ts';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { IDataGridColumn } from '@seeeverything/ui.primitives/src/components/DataGrid/types.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { InputAdornment } from '@seeeverything/ui.primitives/src/components/InputAdornment/InputAdornment.tsx';
import { LoadMore } from '@seeeverything/ui.primitives/src/components/LoadMore/LoadMore.tsx';
import { Popup } from '@seeeverything/ui.primitives/src/components/Popup/Popup.tsx';
import { SkeletonDetailedItemsList } from '@seeeverything/ui.primitives/src/components/SkeletonDetailedItemsList/SkeletonDetailedItemsList.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useCallback, useMemo } from 'react';
import {
  dashboardGridsSlice,
  useDashboardsDispatch,
  useDashboardsSelector,
} from '../../../redux/index.ts';

export interface IFilterPopupContainerProps {
  column: IDataGridColumn;
  gridId: string;
  gridKey: string;
  onColumnSort: (columnId: string, direction: 'ASC' | 'DESC') => void;
  onColumnFilterSelection: (
    columnId: string,
    filter: DashboardColumnFilter,
    to: boolean,
  ) => void;
  onHidePopup: () => void;
  showPopupForColumnId: string;
}

export const FilterPopupContainer: React.FC<IFilterPopupContainerProps> = ({
  column,
  gridId,
  gridKey,
  onColumnFilterSelection,
  onColumnSort,
  onHidePopup,
  showPopupForColumnId,
}) => {
  const dispatch = useDashboardsDispatch();

  const sort = useDashboardsSelector((state) => {
    const sortState = state.dashboardGrids.sort[gridKey];
    if (!sortState) return;

    return sortState.orderBys?.find((o) => o.columnId === column.id)?.direction;
  });

  const displayFilters = useDashboardsSelector((state) => {
    const filtersState = state.dashboardGrids.columnFilters[gridKey];

    const columnFiltersState = filtersState?.[column.id];
    if (!columnFiltersState) return [];

    return columnFiltersState.search
      ? columnFiltersState.searchFilters
      : columnFiltersState.loadedFilters;
  });

  const selectedFilters = useDashboardsSelector((state) => {
    const filtersState = state.dashboardGrids.columnFilters[gridKey];

    const columnFiltersState = filtersState?.[column.id];
    return columnFiltersState?.selectedFilters;
  });

  const hasNextPage = useDashboardsSelector((state) => {
    const filtersState = state.dashboardGrids.columnFilters[gridKey];

    const columnFiltersState = filtersState?.[column.id];
    return columnFiltersState?.filtersHasNextPage;
  });

  const lastPage = useDashboardsSelector((state) => {
    const filtersState = state.dashboardGrids.columnFilters[gridKey];

    const columnFiltersState = filtersState?.[column.id];
    return columnFiltersState?.lastLoadedPage;
  });

  const search = useDashboardsSelector((state) => {
    const filtersState = state.dashboardGrids.columnFilters[gridKey];

    const columnFiltersState = filtersState?.[column.id];
    return columnFiltersState?.search;
  });

  const hasSearch = search || hasNextPage || lastPage > 1;

  const isLoadingFilters = useDashboardsSelector((state) => {
    const filtersState = state.dashboardGrids.columnFilters[gridKey];

    const columnFiltersState = filtersState?.[column.id];
    return columnFiltersState?.isLoading;
  });

  const handleSortAscending = useCallback(() => {
    onColumnSort(column.id, 'ASC');
  }, [column.id, onColumnSort]);

  const handleSortDescending = useCallback(() => {
    onColumnSort(column.id, 'DESC');
  }, [column.id, onColumnSort]);

  const handleLoadNextPage = useCallback(
    () =>
      dispatch(
        dashboardGridsSlice.loadColumnFilters({
          gridId,
          gridKey,
          columnId: column.id,
        }),
      ),
    [column.id, dispatch, gridId, gridKey],
  );

  const handleClearColumnFilters = useCallback(
    () =>
      dispatch(
        dashboardGridsSlice.clearSelectedColumnFilters({
          gridKey,
          columnId: column.id,
        }),
      ),
    [column.id, dispatch, gridKey],
  );

  const handleSearchChange = useCallback(
    (to: string) => {
      dispatch(
        dashboardGridsSlice.updateColumnFilterSearch({
          gridId,
          gridKey,
          columnId: column.id,
          search: to,
        }),
      );
    },
    [column.id, dispatch, gridId, gridKey],
  );

  const canSort = column.isSortable !== false;
  const canFilter = column.isFilterable === true;

  const elSort = canSort && (
    <div css={styles.group}>
      <div css={styles.title}>
        <Text
          size={13}
          selectable={false}
          uppercase={true}
          weight={700}
          color={color.format(-0.4)}
        >
          {'Sort'}
        </Text>
      </div>
      <Button onClick={handleSortAscending}>
        <div css={styles.itemButton}>
          <Icons.sortAscending />
          <Text
            color={color.format(-0.6)}
            cursor={'inherit'}
            selectable={false}
            size={15}
          >
            {'Sort ascending (A-Z)'}
          </Text>
          {sort === 'Ascending' && (
            <div css={styles.tick}>
              <Icons.tickDone fill={COLORS.GREEN_TICK} />
            </div>
          )}
        </div>
      </Button>
      <Button onClick={handleSortDescending}>
        <div css={styles.itemButton}>
          <Icons.sortDescending />
          <Text
            color={color.format(-0.6)}
            cursor={'inherit'}
            selectable={false}
            size={15}
          >
            {'Sort descending (Z-A)'}
          </Text>
          {sort === 'Descending' && (
            <div css={styles.tick}>
              <Icons.tickDone fill={COLORS.GREEN_TICK} />
            </div>
          )}
        </div>
      </Button>
    </div>
  );

  const elFiltersList = useMemo(
    () =>
      !isLoadingFilters && (
        <>
          {!displayFilters?.length && (
            <Text css={styles.emptyText} color={color.format(-0.6)}>
              {search
                ? 'There are no results available. Update or remove your search to get more results.'
                : 'No filters available.'}
            </Text>
          )}
          {displayFilters?.map((filter) => {
            const isSelected = selectedFilters.some(
              ({ value }) => value === filter.value,
            );
            const showTick = !selectedFilters?.length || isSelected;
            const tickColor = isSelected
              ? COLORS.GREEN_TICK
              : color.format(-0.2);
            return (
              <Button
                key={`Filter-${filter.value}`}
                onClick={() =>
                  onColumnFilterSelection(column.id, filter, !isSelected)
                }
              >
                <div css={styles.itemButton}>
                  <Icons.filterList />
                  <Text
                    color={color.format(-0.6)}
                    cursor={'inherit'}
                    selectable={false}
                    ellipsis={true}
                    tooltip={
                      !filter.value
                        ? `Filter where ${column.label.toLowerCase()} is empty.`
                        : filter.displayValue
                    }
                    size={15}
                    italic={!filter.value}
                  >
                    {filter.displayValue}
                  </Text>
                  {showTick && (
                    <div css={styles.tick}>
                      <Icons.tickDone fill={tickColor} />
                    </div>
                  )}
                </div>
              </Button>
            );
          })}
        </>
      ),
    [
      column.id,
      column.label,
      displayFilters,
      isLoadingFilters,
      onColumnFilterSelection,
      selectedFilters,
      search,
    ],
  );

  const elFilters = canFilter && (
    <div css={styles.group}>
      <div css={styles.title}>
        <Text
          size={13}
          selectable={false}
          uppercase={true}
          weight={700}
          color={color.format(-0.4)}
        >
          {'Filters'}
        </Text>
        {Boolean(selectedFilters?.length) && (
          <div css={styles.clearSelections}>
            <Button
              onClick={handleClearColumnFilters}
              style={{
                margin: '-5px -10px -5px -5px',
                padding: '5px 10px 5px 5px',
              }}
            >
              <div css={styles.clearButton}>
                <Icons.clear size={13} />
                <Text
                  color={COLORS.BLUE}
                  uppercase={true}
                  cursor={'pointer'}
                  size={12}
                  weight={700}
                  align={'right'}
                >
                  {'Clear Filters'}
                </Text>
              </div>
            </Button>
          </div>
        )}
      </div>
      {hasSearch && (
        <div css={styles.searchBox}>
          <TextField
            id={`${column.id}-search`}
            variant={'outlined'}
            InputProps={{
              autoFocus: true,
              startAdornment: (
                <InputAdornment position={'start'}>
                  <Icons.search fill={color.format(-0.18)} />
                </InputAdornment>
              ),
            }}
            onChange={handleSearchChange}
            placeholder={'Search...'}
            value={search}
          />
        </div>
      )}
      <div css={styles.filterScrollContainer}>
        {elFiltersList}
        {isLoadingFilters && <SkeletonDetailedItemsList />}
        {!search && hasNextPage && (
          <div css={styles.loadMore} key={lastPage}>
            <LoadMore onInView={handleLoadNextPage} />
          </div>
        )}
      </div>
    </div>
  );

  if (!canSort && !canFilter) return;
  if (showPopupForColumnId !== column.id) return;

  return (
    <Popup onHide={onHidePopup} hideOnClick={false}>
      <div css={styles.base}>
        {elSort}
        {elFilters}
      </div>
    </Popup>
  );
};

const styles = {
  base: css({
    ...CommonStyles.BoxShadowGroup,
    display: 'flex',
    flexDirection: 'column',
    width: 500,
  }),
  title: css({
    display: 'flex',
    flexDirection: 'row',
    padding: '5px 10px',
    backgroundColor: color.format(-0.1),
    borderBottom: `solid 1px ${color.format(-0.05)}`,
    alignItems: 'center',
  }),
  group: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  itemButton: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
    padding: '5px 10px',
    minHeight: 28,
  }),
  searchBox: css({
    padding: '5px 10px',
  }),
  filterScrollContainer: css({
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 416,
    overflowY: 'auto',
  }),
  tick: css({
    marginLeft: 'auto',
  }),
  loadMore: css({
    position: 'relative',
    maxHeight: 50,
  }),
  clearSelections: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    marginLeft: 'auto',
  }),
  clearButton: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    alignItems: 'center',
    justifyContent: 'flex-end',
  }),
  emptyText: css({
    padding: 10,
  }),
};
