/** @jsxImportSource @emotion/react */
import React from 'react';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import {
  LabelButton,
  LabelButtonDropdownClickEvent,
} from '@seeeverything/ui.primitives/src/components/LabelButton/index.ts';

const CONFIRM = 'CONFIRM';
const CANCEL = 'CANCEL';
const SIGNOFF_DROPDOWN: ISelectionListItem[] = [
  {
    dataTest: 'forms-buttonRevert-top-dropdown-confirmOption',
    id: CONFIRM,
    icon: Icons.undo,
    iconColor: { default: COLORS.GREEN_TICK, selected: COLORS.WHITE },
    content: { text: 'OK', description: 'Reopen for further changes.' },
  },
  {
    dataTest: 'forms-buttonRevert-top-dropdown-cancelOption',
    id: CANCEL,
    icon: Icons.arrowBack,
    content: { text: 'Cancel', description: 'Leave in current state.' },
  },
];

export interface IButtonRevertProps {
  onRevert?: () => void;
  isEnabled?: boolean;
}

export const ButtonRevert: React.FC<IButtonRevertProps> = (props) => {
  const { isEnabled = true, onRevert } = props;
  const [showDropdown, setShowDropdown] = React.useState(false);

  const handleClick = () => {
    setShowDropdown(true);
  };

  const handleDropdownClick = (e: LabelButtonDropdownClickEvent) => {
    hideDropdown();
    if (e.id === CONFIRM) {
      onRevert?.();
    }
  };

  const hideDropdown = () => {
    setShowDropdown(false);
  };

  const dropdown = showDropdown
    ? { width: 230, items: SIGNOFF_DROPDOWN }
    : undefined;

  return (
    <LabelButton
      dataTest={'forms-buttonRevert-topRevertButton'}
      label={'Signed-off'}
      capitalized={true}
      leftIcon={Icons.arrowDropDown}
      rightIcon={Icons.check}
      theme={'DARK'}
      size={'MEDIUM'}
      dropdown={dropdown}
      onClick={handleClick}
      onDropdownClick={handleDropdownClick}
      onDropdownHide={hideDropdown}
      isEnabled={isEnabled}
    />
  );
};
