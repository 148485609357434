import { z } from 'zod';

const chartBar = z.object({
  color: z.string(),
  'data-key': z.string(),
  label: z.string(),
  background: z.string().optional(),
  'stack-id': z.string().optional(),
  width: z
    .enum(['extra-small', 'small', 'medium', 'large', 'extra-large'])
    .default('small'),
});

const chartLine = z.object({
  color: z.string(),
  'stroke-width': z.number(),
  'data-key': z.string(),
  label: z.string(),
  'data-is-percentage': z.boolean().default(false),
});

const chart = z.object({
  bars: z.array(chartBar).optional(),
  'bar-category-gap': z.string().optional(),
  'data-set': z.string(),
  group: z.string().optional(),
  id: z.string(),
  kind: z.enum(['COMPOSED', 'VERTICAL']),
  lines: z.array(chartLine).optional(),
  title: z.string().optional(),
});

export const dashboardChartSchema = {
  chartBar,
  chartLine,
  chart,
};

export type DashboardChartSchemaTypes = {
  ChartBar: z.infer<typeof chartBar>;
  ChartLine: z.infer<typeof chartLine>;
  Chart: z.infer<typeof chart>;
};
