/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { formsQuery } from '@se/data/forms/query/index.ts';
import { FormGoalCategory } from '@se/data/forms/types.ts';
import { SimpleEntity } from '@se/data/types.ts';
import { GoalDialog } from '@seeeverything/ui.forms/src/components/GoalDialog/GoalDialog.tsx';
import { GoalInteractionSource } from '@seeeverything/ui.forms/src/redux/form-edit-goal/formEditGoalSlice.ts';
import { formEditGoalSlice } from '@seeeverything/ui.forms/src/redux/form-edit-goal/index.ts';
import { FormGoalStatus } from '@seeeverything/ui.forms/src/redux/form-edit-goal/types.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { ActionBar } from '@seeeverything/ui.primitives/src/components/ModalDialog/components/ActionBar/ActionBar.tsx';
import { IActionBarButton } from '@seeeverything/ui.primitives/src/components/ModalDialog/index.ts';
import {
  IListItemLabel,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { Spinner } from '@seeeverything/ui.primitives/src/components/Spinner/index.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import {
  todayInTenantTz,
  useDateContext,
} from '@seeeverything/ui.primitives/src/hooks/useDateContext.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useGraphQL } from '@seeeverything/ui.util/src/graphql/GraphQLProvider.tsx';
import { GoalAdditionalField } from '@seeeverything/ui.util/src/redux/tenant/types.ts';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import momentTz from 'moment-timezone';
import { useCallback, useMemo, useState } from 'react';
import { hideModalDialog } from '../../redux/modalDialog/actions.ts';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';
import { getStatusDropdownProvider } from './util.tsx';

export interface IGoalDialogContainerProps {
  source: GoalInteractionSource;
}

export const GoalDialogContainer: React.FC<IGoalDialogContainerProps> = ({
  source,
}) => {
  const dispatch = useShellDispatch();
  const { client } = useGraphQL();

  const dateContext = useDateContext();
  const minimumDueDate = todayInTenantTz(dateContext.tenantTimezone);

  const { data: formGoalCategoryData, isLoading: isLoadingFormGoalCategories } =
    useQuery({
      placeholderData: keepPreviousData,
      queryKey: [{ key: 'goalSchedules.formGoalCategories' }],
      queryFn: async () => {
        const response = await formsQuery.getFormGoalCategories(client, {
          fetchAllPages: true,
        });

        return response.isSuccess ? response.data : null;
      },
    });

  const formGoalCategorySelections = useMemo(() => {
    if (!formGoalCategoryData) return [];
    return formGoalCategoryData.formGoalCategories.map(
      (goalCategory): ISelectionListItem<IListItemLabel, FormGoalCategory> => ({
        id: goalCategory.id,
        content: { text: goalCategory.name },
        value: goalCategory,
        icon: Icons.myLocation,
        iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
      }),
    );
  }, [formGoalCategoryData]);

  const isLoading = useShellSelector((state) => state.formEditGoal.isLoading);
  const hasInitialized = useShellSelector((state) =>
    Boolean(state.formEditGoal.initialValues),
  );

  const isLoadError = useShellSelector(
    (state) => state.formEditGoal.isLoadError,
  );
  const isSaving = useShellSelector((state) => state.formEditGoal.isSaving);

  const initialValues = useShellSelector(
    (state) => state.formEditGoal.initialValues,
  );

  const formInstanceId = useShellSelector((state) => {
    const editGoalInitialValues = state.formEditGoal.initialValues;
    if (!editGoalInitialValues) return;

    return editGoalInitialValues.type === 'NEW'
      ? editGoalInitialValues.formInstanceId
      : editGoalInitialValues.goal.formInstanceId;
  });

  const instanceSubject = useShellSelector((state) => {
    if (!formInstanceId) return;

    const instance = state.formInstance.instances[formInstanceId];
    if (!instance) return;

    const subject = instance.subject;
    if (!subject) return;
    if (subject.kind === 'Team') return;

    return {
      id: subject.id,
      label: subject.name,
      type: 'PERSON',
    } as const;
  });

  const hasActiveChildActions = useMemo(() => {
    if (!initialValues) return false;
    if (initialValues.type === 'NEW') return false;
    return initialValues.goal.hasActiveChildActions;
  }, [initialValues]);

  const [draftDescription, setDraftDescription] = useState<string>(undefined);
  const [showDescriptionValidation, setShowDescriptionValidation] =
    useState(false);

  const updateDescription = useCallback((to: string) => {
    setShowDescriptionValidation(false);
    setDraftDescription(to);
  }, []);

  const description = useMemo(() => {
    if (draftDescription !== undefined) return draftDescription;
    if (!initialValues) return '';
    if (initialValues.type === 'NEW') return '';
    return initialValues.goal.description ?? '';
  }, [draftDescription, initialValues]);

  const [draftDueBy, setDraftDueBy] = useState<string>(undefined);
  const [showDueByValidation, setShowDueByValidation] = useState(false);

  const updateDueBy = useCallback(
    (to: string) => {
      setShowDueByValidation(false);

      const toEndOfDay = momentTz(to)
        .tz(dateContext.tenantTimezone)
        .endOf('day');
      setDraftDueBy(toEndOfDay.toISOString());
    },
    [dateContext.tenantTimezone],
  );

  const dueBy = useMemo(() => {
    if (draftDueBy !== undefined) return draftDueBy;
    if (!initialValues) return '';
    if (initialValues.type === 'NEW') return '';
    return initialValues.goal.dueBy ?? '';
  }, [draftDueBy, initialValues]);

  const [draftAssignedTo, setDraftAssignedTo] = useState<SimpleEntity>();
  const [showAssignedToValidation, setShowAssignedToValidation] =
    useState(false);

  const updateAssignedTo = useCallback((to: SimpleEntity) => {
    setShowAssignedToValidation(false);
    setDraftAssignedTo(to);
  }, []);

  const assignedTo = useMemo(() => {
    if (draftAssignedTo !== undefined) return draftAssignedTo;
    if (!initialValues) return undefined;
    if (initialValues.type === 'NEW') return initialValues.assignedTo;
    return initialValues.goal.assignedTo;
  }, [draftAssignedTo, initialValues]);

  const [draftStatus, setDraftStatus] = useState<FormGoalStatus>();

  const existingStatus = useMemo(() => {
    if (!initialValues) return undefined;

    return initialValues.type === 'NEW'
      ? initialValues.status
      : initialValues.goal.status.value;
  }, [initialValues]);

  const status = useMemo(() => {
    if (draftStatus !== undefined) return draftStatus;
    if (!initialValues) return undefined;

    return initialValues.type === 'NEW'
      ? initialValues.status
      : initialValues.goal.status.value;
  }, [draftStatus, initialValues]);

  const [note, setNote] = useState<string>();

  const [draftGoalCategory, setDraftGoalCategory] =
    useState<FormGoalCategory>();
  const [showGoalCategoryValidation, setShowGoalCategoryValidation] =
    useState(false);

  const updateDraftGoalCategory = useCallback((to: FormGoalCategory) => {
    setDraftGoalCategory(to);
    setShowGoalCategoryValidation(false);
  }, []);

  const goalCategory = useMemo(() => {
    if (draftGoalCategory) return draftGoalCategory;
    if (!initialValues) return undefined;
    if (initialValues.type === 'NEW') return initialValues.goalCategory;
    return initialValues.goal.goalCategory;
  }, [draftGoalCategory, initialValues]);

  const canChangeGoalCategory = useMemo(() => {
    if (!initialValues) return false;
    if (initialValues.type !== 'NEW') return false;
    return !initialValues.goalCategory;
  }, [initialValues]);

  const [draftAdditionalFields, setDraftAdditionalFields] = useState<
    GoalAdditionalField[]
  >([]);

  const additionalFields = useMemo(() => {
    if (!initialValues) return [];

    const initialFields =
      initialValues.type === 'NEW'
        ? initialValues.additionalFields
        : initialValues.goal.additionalFields;

    if (!initialFields?.length) return [];
    if (!draftAdditionalFields.length) return initialFields;

    return initialFields.map(
      (field) =>
        draftAdditionalFields.find(({ key }) => key === field.key) ?? field,
    );
  }, [draftAdditionalFields, initialValues]);

  const updateAdditionalFields = useCallback((update: GoalAdditionalField) => {
    setDraftAdditionalFields((existingFields) => {
      const existing = existingFields.find(({ key }) => key === update.key);
      if (!existing) return [...existingFields, update];

      return existingFields.map((field) =>
        field.key !== update.key ? field : update,
      );
    });
  }, []);

  const previousGoalNotes = useShellSelector(
    (state) => state.formEditGoal.goalNotes.notes,
  );
  const previousGoalNotesLoading = useShellSelector(
    (state) => state.formEditGoal.goalNotes.isLoading,
  );

  const actionLabel = useShellSelector(
    (state) => state.tenantState.tenant.locale.label.action,
  );

  const statusDropdownProvider = useMemo(() => {
    if (!initialValues) return;

    const existingGoal =
      initialValues?.type === 'EXISTING' ? initialValues.goal : undefined;
    const canRevert = existingGoal?.permissions.revert ?? false;
    const canClose = existingGoal?.permissions.close ?? false;
    const canCancel = existingGoal?.permissions.cancel ?? false;

    return getStatusDropdownProvider(
      existingStatus,
      canRevert,
      canClose,
      canCancel,
      hasActiveChildActions,
      actionLabel,
    );
  }, [initialValues, existingStatus, hasActiveChildActions, actionLabel]);

  const hasGoalCategories = Boolean(formGoalCategorySelections?.length);

  const showPreviousGoalNotes = useCallback(() => {
    dispatch(formEditGoalSlice.showPreviousGoalNotes());
  }, [dispatch]);

  const createGoal = useCallback(() => {
    if (!initialValues) return;
    if (initialValues.type !== 'NEW') return;

    if (!draftDescription) setShowDescriptionValidation(true);
    if (!draftDueBy) setShowDueByValidation(true);
    if (!assignedTo) setShowAssignedToValidation(true);
    if (hasGoalCategories && !draftGoalCategory && !initialValues.goalCategory)
      setShowGoalCategoryValidation(true);

    const isValid =
      draftDescription &&
      draftDueBy &&
      assignedTo &&
      (!hasGoalCategories || draftGoalCategory || initialValues.goalCategory);

    if (!isValid) return;
    dispatch(
      formEditGoalSlice.createGoal({
        goal: {
          id: uuid.generate(),
          formInstanceId: initialValues.formInstanceId,
          description: draftDescription,
          dueBy: draftDueBy,
          additionalFields,
          assignedTo,
          note,
          goalCategory: draftGoalCategory ?? initialValues.goalCategory,
          status: { value: 'Open' },
        },
        source,
      }),
    );
  }, [
    additionalFields,
    assignedTo,
    dispatch,
    draftDescription,
    draftDueBy,
    draftGoalCategory,
    hasGoalCategories,
    initialValues,
    note,
    source,
  ]);

  const updateGoal = useCallback(() => {
    if (!initialValues) return;
    if (initialValues.type !== 'EXISTING') return;

    const hasChanged = Boolean(
      draftAdditionalFields.length ||
        draftAssignedTo ||
        draftDescription ||
        draftDueBy ||
        draftStatus ||
        note,
    );

    if (!hasChanged) {
      dispatch(hideModalDialog());
      return;
    }

    dispatch(
      formEditGoalSlice.updateGoal({
        initialGoal: initialValues.goal,
        source,
        newDescription: draftDescription,
        newDueBy: draftDueBy,
        newAdditionalFields: draftAdditionalFields,
        newAssignedTo: draftAssignedTo,
        note,
        newStatus: draftStatus,
      }),
    );
  }, [
    dispatch,
    draftAdditionalFields,
    draftAssignedTo,
    draftDescription,
    draftDueBy,
    draftStatus,
    initialValues,
    note,
    source,
  ]);

  const cancel = useCallback(() => {
    dispatch(hideModalDialog());
    dispatch(formEditGoalSlice.clear());
  }, [dispatch]);

  const isExistingGoal = initialValues?.type === 'EXISTING';

  const actionButtons = useMemo<IActionBarButton[]>(
    () => [
      {
        dataTest: 'goalDialogContainer-cancelButton',
        id: 'Cancel',
        label: isLoadError ? 'Dismiss' : 'Cancel',
        isEnabled: !isSaving && !isLoading,
        onAction: cancel,
      },
      {
        dataTest: 'goalDialogContainer-okButton',
        id: 'OK',
        label: 'OK',
        isEnabled: !isSaving && !isLoading && !isLoadError,
        onAction: isExistingGoal ? updateGoal : createGoal,
      },
    ],
    [
      isLoadError,
      isSaving,
      isLoading,
      cancel,
      isExistingGoal,
      updateGoal,
      createGoal,
    ],
  );

  const isEditable = useMemo(() => {
    if (isSaving) return false;
    if (isLoading) return false;
    if (!initialValues) return false;

    if (!['Open', 'Overdue'].includes(existingStatus)) return false;

    return initialValues.type === 'NEW' || initialValues.goal.permissions.edit;
  }, [initialValues, isLoading, isSaving, existingStatus]);

  const isSpinning = isLoading || isSaving;

  return (
    <div css={styles.base}>
      {isSpinning && (
        <div>
          <Spinner center={true} />
        </div>
      )}
      {isLoadError && (
        <div css={styles.errorBase}>
          <Text color={'#555555'} align={'center'}>
            {`There was a problem loading this goal. Please contact your support team.`}
          </Text>
        </div>
      )}
      {!isLoading && !isLoadingFormGoalCategories && hasInitialized && (
        <div css={isSaving ? styles.mask : undefined}>
          <GoalDialog
            additionalFields={additionalFields}
            assignedTo={assignedTo}
            canChangeGoalCategory={canChangeGoalCategory}
            canShowPreviousAnswers={isExistingGoal}
            completedAt={
              isExistingGoal ? initialValues.goal.completedAt : undefined
            }
            description={description}
            dueBy={dueBy}
            goalCategory={goalCategory}
            goalCategorySelections={formGoalCategorySelections}
            hasGoalCategories={hasGoalCategories}
            instanceSubject={instanceSubject}
            isEditable={isEditable}
            minimumDueDate={minimumDueDate}
            note={note}
            onAdditionalFieldsChange={updateAdditionalFields}
            onAssignedToChange={updateAssignedTo}
            onDescriptionChange={updateDescription}
            onDueByChange={updateDueBy}
            onGoalCategoryChange={updateDraftGoalCategory}
            onNoteChange={setNote}
            onShowPreviousGoalNotes={showPreviousGoalNotes}
            onStatusChange={setDraftStatus}
            previousNotes={previousGoalNotes}
            previousNotesLoading={previousGoalNotesLoading}
            showAssignedToValidation={showAssignedToValidation}
            showDescriptionValidation={showDescriptionValidation}
            showDueByValidation={showDueByValidation}
            showGoalCategoryValidation={showGoalCategoryValidation}
            status={status}
            statusDropdownProvider={statusDropdownProvider}
          />
        </div>
      )}
      <ActionBar actions={actionButtons} />
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-between',
    position: 'relative',
    minHeight: 400,
  }),
  mask: css({
    opacity: 0.2,
  }),
  errorBase: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 400,
  }),
};
