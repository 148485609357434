import { isAnyOf } from '@reduxjs/toolkit';
import { formEditGoalSlice } from '@seeeverything/ui.forms/src/redux/form-edit-goal/index.ts';
import { formActionSlice } from '@seeeverything/ui.forms/src/redux/formAction/index.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { EMPTY, Observable, concatAll, filter, map, of } from 'rxjs';
import { dashboardGridsSlice } from '../dashboardGrids/index.ts';

export function reloadGridOnGoalChangedEpic(action$: Observable<ReduxAction>) {
  return action$.pipe(
    filter(
      isAnyOf(
        formEditGoalSlice.createdGoal.match,
        formEditGoalSlice.updatedGoal.match,
        formActionSlice.saved.match,
      ),
    ),
    filter((action) => action.payload.isSuccess !== false),
    map((action) =>
      action.payload.source.type === 'DASHBOARD'
        ? of(
            dashboardGridsSlice.reloadGrid({
              gridKey: action.payload.source.gridKey,
            }),
          )
        : EMPTY,
    ),
    concatAll(),
  );
}
