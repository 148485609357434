import { AnalyticsEvent } from '@seeeverything/ui.util/src/redux/analytics/types.ts';
import { IUpdatedModule } from '../../config/config.redux/app/types.ts';
import { trackEvent } from './util.ts';

export const generateModuleChangedEvents = (action: IUpdatedModule) =>
  new Promise<AnalyticsEvent[]>((resolve) =>
    resolve([
      trackEvent('user_toggle_module', {
        destinationModule: action.payload.to,
        source: action.payload.source,
      }),
    ]),
  );
