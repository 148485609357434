/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { GoalsActionsGridContainer } from '@seeeverything/ui.forms/src/components/Grid/GoalsActionsGridContainer.tsx';
import { CommonStyles } from '@seeeverything/ui.primitives/src/common/commonStyles.ts';
import { useTrackInViewport } from '@seeeverything/ui.primitives/src/hooks/useTrackInViewport.ts';
import moment from 'moment';
import { useMemo } from 'react';
import { DASHBOARD_INTERSECTION_OPTIONS } from '../common/constants.ts';
import { dateRangeFromFilter, toDateFilter } from '../data/util.ts';
import { useDashboardsSelector } from '../redux/store.ts';
import { IDashboardGoalsAndActions } from '../types.ts';

export interface IFactoryGoalsActionsContainerProps {
  entityId: string;
  entityType: 'PERSON' | 'TEAM';
  item: IDashboardGoalsAndActions;
  parentId?: string;
}

export const FactoryGoalsActionsContainer: React.FC<
  IFactoryGoalsActionsContainerProps
> = ({ entityId, entityType, item, parentId }) => {
  const [forwardedRef, inViewport] = useTrackInViewport({
    dataId: item.id,
    parentDataId: parentId,
    observerOptions: DASHBOARD_INTERSECTION_OPTIONS,
  });

  const dateFilter = useDashboardsSelector((state) => toDateFilter(state));

  const dateRange = useMemo(() => {
    const { startDate, endDate } = dateRangeFromFilter(
      dateFilter.selection,
      dateFilter.financialYearStartMonth,
      dateFilter.selectedCustomDates,
    );

    return {
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD'),
    };
  }, [dateFilter]);

  const scrollToDataId = useDashboardsSelector(
    (state) => state.scroll.containers.scrollPanel?.scrollToDataId,
  );

  return (
    <div
      css={
        scrollToDataId === item.id
          ? [styles.base, CommonStyles.ScrollPulse]
          : [styles.base]
      }
      id={item.id}
      data-id={item.id}
      parent-data-id={parentId}
      ref={forwardedRef}
    >
      <GoalsActionsGridContainer
        dashboardId={item.id}
        dateRangeLabel={dateFilter.selection.toLabel(dateFilter)}
        endDate={dateRange.endDate}
        entityId={entityId}
        entityType={entityType}
        inViewport={inViewport}
        sheetType={'DASHBOARD'}
        startDate={dateRange.startDate}
      />
    </div>
  );
};

const styles = {
  base: css({
    margin: '30px 30px 0 30px',
  }),
};
