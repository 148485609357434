/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Sheet } from './components/Sheet/Sheet.tsx';

export interface ISheet {
  content?: React.ReactNode;
}

export interface ISheetsPortraitProps {
  length: number;
  content: React.ReactNode;
}

/**
 * Sheet container system.
 */
export const SheetsPortrait: React.FC<ISheetsPortraitProps> = ({
  content,
  length,
}) => {
  const numSheets = Math.min(length, 3);
  const sheetElements = Array.from({ length: Math.min(length, 3) }).map(
    (_, index) => {
      const isFirst = index === 0;
      const isLast = index === numSheets - 1;
      const top = index * 7;
      const paddingX = (numSheets - index) * 5;

      return (
        <Sheet
          key={index}
          top={top}
          left={paddingX}
          right={paddingX}
          isFirst={isFirst}
          isLast={isLast}
          hasSideShadow={isLast}
        >
          {content}
        </Sheet>
      );
    },
  );

  return <div css={styles.base}>{sheetElements}</div>;
};

const styles = {
  base: css({
    flex: '1 1 auto',
    position: 'relative',
    boxSizing: 'border-box',
  }),
};
