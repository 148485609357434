/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Spinner } from '@seeeverything/ui.primitives/src/components/Spinner/index.ts';
import { SEEEVERYTHING_CDN } from '@seeeverything/ui.util/src/constants/cdn.ts';

export const LoadingFullScreen: React.FC = () => (
  <div css={styles.base}>
    <img
      src={`${SEEEVERYTHING_CDN}/formimages/SeeEverything_Site_Logo.png`}
      css={styles.logo}
    />
    <Spinner size={60} />
  </div>
);

const styles = {
  base: css({
    backgroundColor: 'white',
    position: 'absolute',
    inset: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 45,
  }),
  logo: css({
    backgroundColor: 'white',
    height: 'auto',
    maxWidth: 500,
    minWidth: 250,
    width: '50%',
  }),
};
