/** @jsxImportSource @emotion/react */
import { Chart } from '@seeeverything/ui.charts/src/components/Chart/Chart.tsx';
import { TooltipValueFormatterItem } from '@seeeverything/ui.charts/src/components/CustomTooltip/types.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { propsAreEqualFastCompare } from '@seeeverything/ui.util/src/react/memoFastCompare.ts';
import { toPercentageString } from '@seeeverything/ui.util/src/value/value.ts';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { loadDashboardComponent } from '../../redux/actions.ts';
import {
  useDashboardsDispatch,
  useDashboardsSelector,
} from '../../redux/store.ts';

export interface IDashboardChartContainerProps {
  id: string;
  inViewport: boolean;
}

const View: React.FC<IDashboardChartContainerProps> = ({ id, inViewport }) => {
  const dispatch = useDashboardsDispatch();
  const [hasLoaded, setHasLoaded] = useState(false);

  const chart = useDashboardsSelector((state) => state.dashboardsV2.CHART[id]);

  const [hasLoadedWhileInViewport, setHasLoadedWhileInViewport] =
    useState(false);

  const isLoading = useMemo(
    () => !chart?.dataState.isLoaded || chart?.dataState.isLoading,
    [chart?.dataState.isLoaded, chart?.dataState.isLoading],
  );

  useEffect(() => {
    if (hasLoadedWhileInViewport) return;
    if (!inViewport) return;
    if (isLoading) return;
    setHasLoadedWhileInViewport(true);
  }, [isLoading, hasLoadedWhileInViewport, inViewport]);

  const forceReload = chart?.dataState.forceReload;
  const isHidden = chart?.isHidden;
  const chartData = chart?.dataState?.data?.data ?? [];
  const isError = chart?.dataState?.error;

  const formatDisplayValue = useCallback(
    (item: TooltipValueFormatterItem, value: string) => {
      const isPercentage =
        item.payload?.[`${item.dataKey}-formatAsPercentage`] === true;
      if (!isPercentage) return value;

      const number = parseFloat(value);
      return isNaN(number) ? value : toPercentageString(number / 100, 2);
    },
    [],
  );

  useEffect(() => {
    if (forceReload) setHasLoaded(false);
  }, [forceReload]);

  useEffect(() => {
    if (hasLoaded) return;
    if (!inViewport) return;

    dispatch(loadDashboardComponent('CHART', id));
    setHasLoaded(true);
  }, [inViewport, hasLoaded, dispatch, id]);

  if (isHidden) return;
  if (isError)
    return (
      <Text color={color.format(-0.5)}>
        {'There was a problem loading this chart. Please try again.'}
      </Text>
    );

  return (
    <Chart
      barCategoryGap={chart?.barCategoryGap}
      bars={chart?.bars}
      data={chartData}
      grid={chart?.grid}
      height={chart?.height}
      id={id}
      isLoading={isLoading || !hasLoadedWhileInViewport}
      kind={chart?.kind}
      layout={chart?.layout}
      legend={chart?.legend}
      lines={chart?.lines}
      title={chart?.title}
      tooltip={chart?.tooltip}
      tooltipDisplayKey={'name'}
      valueFormatter={formatDisplayValue}
    />
  );
};

export const DashboardChartContainer = memo(View, propsAreEqualFastCompare);
