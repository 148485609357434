/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Checkbox } from '@seeeverything/ui.primitives/src/components/Checkbox/Checkbox.tsx';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import React from 'react';
import { Bullet } from '../Bullet/index.ts';
import { DesignerSignoffFieldProps, IDesignerCommonProps } from '../types.ts';
import { handleChangeEvent } from '../util.ts';

export interface IDesignerSignoffPersonProps extends IDesignerCommonProps {
  inputProps?: DesignerSignoffFieldProps;
  participant: 'Reviewer' | 'Subject';
}

export class DesignerSignoffPerson extends React.Component<
  IDesignerSignoffPersonProps,
  object
> {
  public render() {
    const defaultInputProps: DesignerSignoffFieldProps = {
      label: '',
      canChangeIsSignoffRequired: false,
      isRequired: true,
    };

    const {
      id,
      inputProps = defaultInputProps,
      showBoundaryLines,
      showBullet = true,
      participant = 'Reviewer',
      style,
      onChange,
      error,
    } = this.props;

    const computedStyles = {
      base: css({
        border: showBoundaryLines
          ? `dashed 1px ${color.format(-0.2)}`
          : 'solid 1px rgba(0, 0, 0, 0)',
        borderRadius: showBoundaryLines ? 8 : undefined,
      }),
    };

    const elDesignerContent = (
      <div css={styles.editingContent}>
        <TextField
          id={`designer-signoff-title-${id}-${participant}`}
          onChange={handleChangeEvent(`${id}.label`, 'LABEL', onChange)}
          label={`${participant} Label`}
          style={styles.fullWidth}
          value={inputProps.label}
          error={error}
        />

        <div css={styles.checkBox}>
          <Checkbox
            label={'Required'}
            isChecked={inputProps.isRequired}
            isEnabled={inputProps.canChangeIsSignoffRequired}
            helperText={
              'Marks sign-off as being required for this participant.'
            }
            style={css({ margin: '0 -3px' })}
            onCheck={handleChangeEvent(`${id}.checkbox`, 'SWITCH', onChange)}
          />
        </div>
      </div>
    );

    const elOuter = <div css={styles.content}>{elDesignerContent}</div>;

    return (
      <div css={[styles.outer, style]}>
        {showBullet && <Bullet />}
        <div css={[styles.base, computedStyles.base]}>{elOuter}</div>
      </div>
    );
  }
}

const styles = {
  outer: css({
    position: 'relative',
  }),
  base: css({
    display: 'flex',
    justifyContent: 'center',
    minHeight: 132,
    padding: '8px 20px 6px 40px',
    marginBottom: 5,
  }),
  content: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  }),
  editingContent: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  checkBox: css({
    paddingTop: 15,
  }),
  fullWidth: css({
    width: '100%',
  }),
};
