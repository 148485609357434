/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import { GridCellDecorator } from '../../types.ts';

export const goalsAndActionsDecorator: GridCellDecorator = (args) => {
  const { cellArgs, columnDefinition, cellTheme, gridState, viewEntity } = args;
  if (!gridState) {
    return {};
  }

  const entity = cellArgs.row.entity;
  if (
    entity &&
    (entity.type === 'Goal' || entity.type === 'Action') &&
    viewEntity &&
    columnDefinition?.canLaunch
  ) {
    const icon = entity.type === 'Goal' ? 'myLocation' : undefined;

    const hasGoals = () =>
      Boolean(
        gridState?.dataState?.data?.data?.some(
          (d: any) => d.entity?.type === 'Goal',
        ),
      );

    const leftIndent = Boolean(entity.type === 'Action' && hasGoals());

    return {
      alignment: columnDefinition.align,
      icon: {
        icon,
        leftIndent,
        divider: true,
        color: COLORS.BLUE ?? cellTheme.textColor,
        size: 14,
      },
      text: {
        ellipsis: true,
        size: 14,
        weight: FontWeight.medium,
        color: cellTheme.textColor,
        italic: cellTheme.italic,
        style: css({ width: '100%' }),
      },
    };
  }

  return {};
};
