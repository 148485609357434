import { digitalContentLoad } from '@seeeverything/ui.forms/src/redux/form-digital-content/actions.ts';
import { ReduxFormDigitalContentLoaded } from '@seeeverything/ui.forms/src/redux/form-digital-content/types.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { GlobalReduxEpicDependenciesType } from '@seeeverything/ui.util/src/redux/types.ts';
import moment from 'moment';
import { StateObservable, ofType } from 'redux-observable';
import { Observable, filter, map, mergeMap } from 'rxjs';
import { PAGE_SIZE_FULL_GRID } from '../../common/constants.ts';
import * as data from '../../data/index.ts';
import {
  DigitalContentPack,
  IContentPackSelectionListItem,
  digitalContentPacksPaged,
} from '../../data/index.ts';
import { dateRangeFromFilter, toDateFilter } from '../../data/util.ts';
import { ComponentType } from '../../types.ts';
import {
  componentError,
  digitalContentFilterChanged,
  loaded,
  loadedDigitalContentPacksPage,
} from '../actions.ts';
import {
  DashboardActionDigitalContentFilterChanged,
  DashboardActionDigitalContentLoad,
  DashboardActionLoadDigitalContentPacks,
  DashboardV2ReduxState,
  GlobalDashboardsState,
} from '../types.ts';

const componentType: ComponentType = 'DIGITAL_CONTENT';

export function digitalContentComponentOnLoadEpic(
  action$: Observable<DashboardActionDigitalContentLoad>,
  state$: StateObservable<DashboardV2ReduxState>,
  { client }: GlobalReduxEpicDependenciesType,
) {
  return action$.pipe(
    ofType('ui.dashboards/dashboard/DIGITAL_CONTENT/LOAD'),
    filter(() => Boolean(state$.value.dashboardsV2.template)),
    mergeMap(async (action) => {
      const { id } = action.payload;
      const dateFilter = toDateFilter(state$.value);

      const dateRangeFilter = dateFilter
        ? dateRangeFromFilter(
            dateFilter.selection,
            dateFilter.financialYearStartMonth,
            dateFilter.selectedCustomDates,
          )
        : undefined;

      try {
        const packs = (
          await data.digitalContentPacksPaged(
            client,
            dateRangeFilter?.startDate,
            dateRangeFilter?.endDate,
            true,
          )
        )?.contentPacks;

        const firstPack = packs?.[0];

        const selectionItem = firstPack
          ? toContentPackSelectionListItem(firstPack)
          : undefined;

        return digitalContentFilterChanged(id, selectionItem);
      } catch (e) {
        return componentError(componentType, id, e);
      }
    }),
  );
}

export function digitalContentComponentLoadedEpic(
  action$: Observable<ReduxFormDigitalContentLoaded>,
  state$: StateObservable<DashboardV2ReduxState>,
) {
  return action$.pipe(
    ofType('ui.forms/digital-content/LOADED'),
    filter(
      (action) =>
        Boolean(action.payload?.dashboardComponentId) &&
        Boolean(state$.value.dashboardsV2.template),
    ),
    map((action) => {
      const { dashboardComponentId = '' } = action.payload;

      try {
        return loaded(componentType, dashboardComponentId, undefined, false);
      } catch (e) {
        return componentError(componentType, dashboardComponentId, e);
      }
    }),
  );
}

export function reloadDigitalContentComponentOnFiltersChangedEpic(
  action$: Observable<DashboardActionDigitalContentFilterChanged>,
  state$: StateObservable<DashboardV2ReduxState>,
) {
  return action$.pipe(
    ofType('ui.dashboards/dashboard/DIGITAL_CONTENT/FILTER_CHANGED'),
    filter(({ payload }) =>
      Boolean(
        Boolean(state$.value.dashboardsV2.template) &&
          payload.selectedContentPack,
      ),
    ),
    map(({ payload }) => {
      const { id, selectedContentPack } = payload;
      return digitalContentLoad(selectedContentPack.id, id);
    }),
  );
}

export function loadDigitalContentPacksPageEpic(
  action$: Observable<DashboardActionLoadDigitalContentPacks>,
  _: StateObservable<GlobalDashboardsState>,
  { client }: GlobalReduxEpicDependenciesType,
) {
  return action$.pipe(
    ofType('ui.dashboards/dashboard/DIGITAL_CONTENT/LOAD_CONTENT_PACKS_PAGE'),
    mergeMap(async (action) => {
      const { startDate, endDate } = action.payload;

      try {
        const result = await digitalContentPacksPaged(
          client,
          startDate,
          endDate,
          true,
          1,
          PAGE_SIZE_FULL_GRID,
        );

        const dropdownItems = result.contentPacks.map(
          toContentPackSelectionListItem,
        );

        return loadedDigitalContentPacksPage(dropdownItems, false);
      } catch (err) {
        log.error(
          new Error(
            `Something went wrong trying to get digital content packs (dropdown), ${err.message}`,
          ),
        );
      }
      return loadedDigitalContentPacksPage([], false);
    }),
  );
}

const toContentPackSelectionListItem = (
  item: DigitalContentPack,
): IContentPackSelectionListItem => ({
  id: item.id,
  content: {
    text: `${item.summary} ${
      item.publishDate
        ? `on ${moment(item.publishDate).format('D MMM YY')}`
        : '( not published )'
    }`,
  },
  icon: Icons.list,
  iconColor: { default: '#bbbbbb', selected: '#bbbbbb' },
  value: item,
});
