import { orgHierarchyQuery } from '@se/data/orgHierarchy/query/index.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import {
  IListItemLabel,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/client/types.ts';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { PersonSelection, PersonSubject, TeamSubject } from './types.ts';

export const useSubjectTeamQuery = (
  client: IGraphQLClient,
  instanceLabel: string,
  subject?: PersonSubject | TeamSubject,
) => {
  const { data, isFetching } = useQuery({
    placeholderData: keepPreviousData,
    enabled: subject?.type === 'TEAM',
    queryKey: [{ key: 'peopleDropdown.subjectTeam', subject }],
    queryFn: async () => {
      const response = await orgHierarchyQuery.getTeamManagers(
        client,
        subject.id,
      );
      if (!response.isSuccess) return [];

      return response.data
        .map((person) =>
          toManagerItem(
            {
              email: person.email,
              firstName: person.firstName,
              id: person.id,
              isActive: person.isActive,
              lastName: person.lastName,
              positionTitle: person.positionTitle,
              type: 'TEAM_MANAGER',
            },
            subject.label,
            `Related to ${instanceLabel}`,
          ),
        )
        .filter(Boolean);
    },
  });

  return {
    subjectTeamManagers: data,
    isFetchingSubjectTeamManagers: isFetching,
  };
};

const toManagerItem = (
  person: PersonSelection,
  subjectLabel: string,
  category: string,
): ISelectionListItem<IListItemLabel, PersonSelection> => {
  const name = `${person.firstName} ${person.lastName}`.trim();

  return {
    id: person.id,
    icon: Icons.supervisorAccount,
    value: person,
    category,
    content: {
      description: [person.positionTitle, person.email]
        .filter(Boolean)
        .join(' - '),
      text: name,
      secondaryText: `(Manager of ${subjectLabel})`,
    },
  };
};
