import { FormLineByIdExtended } from '../../components/FormDesignerContent/types.ts';
import { locale } from './locale.ts';
import {
  FormDesignerReduxDesignerState,
  FormsDesignerDesignerOptionsAddNewOption,
} from './types.ts';

export function optionsAddOptionReducer(
  state: FormDesignerReduxDesignerState,
  action: FormsDesignerDesignerOptionsAddNewOption,
) {
  if (!state.draft || !state.draft.designerLines) {
    return state;
  }

  return {
    ...state,
    draft: {
      ...state.draft,
      designerLines: addNewOption(
        state.draft.designerLines,
        action.payload.id,
        action.payload.optionId,
      ),
    },
  };
}

const addNewOption = (
  designerLines: FormLineByIdExtended,
  id: string | number,
  optionId: string | number,
): FormLineByIdExtended => ({
  ...designerLines,
  [id]: {
    ...designerLines[id],
    optionItems: {
      ...designerLines[id].optionItems,
      options: {
        ...designerLines[id].optionItems.options,
        [optionId]: {
          id: optionId,
          label: locale.defaultLabel.options,
        },
      },
    },
  },
});
