import { combineEpics } from 'redux-observable';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { epics as editorEpics } from './designer/epics.ts';
import { createTemplatesEpics } from './epics.createTemplates.ts';
import { dropdownsEpics } from './epics.dropdowns.ts';
import { importTemplatesEpics } from './epics.importTemplates.ts';
import { publishTemplatesEpics } from './epics.publishTemplates.ts';
import { viewTemplatesEpics } from './epics.viewTemplates.ts';
import { FormsDesignerRootState } from './store.ts';

export const formsDesignerRootEpic = combineEpics<
  ReduxAction,
  ReduxAction,
  FormsDesignerRootState
>(
  editorEpics,
  createTemplatesEpics,
  dropdownsEpics,
  importTemplatesEpics,
  publishTemplatesEpics,
  viewTemplatesEpics,
);
