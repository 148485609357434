/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { is } from 'ramda';
import { TooltipIcons } from '../types.ts';

export interface ITooltipItemProps {
  name: string;
  item: any;
  value: string;
  icons: TooltipIcons;
  color?: string;
  isActive?: boolean;
}
export const TooltipItem = (props: ITooltipItemProps) => {
  const { name, color: textColor, item, icons, isActive = true } = props;

  const value = props.value === '' ? '-' : props.value;

  const styles = {
    base: css({
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: 5,
      width: '100%',
      opacity: isActive ? 1 : 0.4,
    }),
    text: css({
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      position: 'relative',
      padding: '0px 8px 5px 5px',
      marginRight: 7,
      width: '100%',
    }),
    left: css({
      padding: '0px 15px 1px 0px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    }),
    right: css({
      paddingBottom: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    }),
  };

  const toIcon = (key: string, defaultIcon: string): string => {
    if (is(String, icons)) return icons as string;
    return icons[key] ?? defaultIcon;
  };

  const icon = toIcon(item.dataKey, 'team');
  const Icon = Icons[icon];

  const elKey = textColor && (
    <div>
      <Icon size={13} fill={textColor} />
    </div>
  );

  return (
    <div css={styles.base}>
      {elKey}
      <div css={styles.text}>
        <Text
          weight={400}
          size={14}
          color={color.format(-0.5)}
          style={styles.left}
          selectable={false}
          ellipsis={true}
          block={true}
        >
          {name}
        </Text>
        <Text
          weight={400}
          size={14}
          color={color.format(-0.5)}
          style={styles.right}
          selectable={false}
          ellipsis={true}
          block={true}
        >
          {value}
        </Text>
      </div>
    </div>
  );
};
