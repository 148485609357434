import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { QueryRouter } from '@seeeverything/ui.shell/src/api/api.queryBuilder/QueryRouter.ts';

/**
 * Registers chip visual formatters - used by the QB to know
 * how to display different chip types.
 * Note: some entities (teams chip, people chip) are handled separately
 * (refer /config/config.queryBuilder/entities.ts).
 */
export const formatChips = (router: QueryRouter) => {
  router.formatChip('bu').subscribe(({ req, res }) => {
    res.format({
      canEdit: false,
      label: req.chip.label,
    });
  });

  router.formatChip('goalsActionsGrid').subscribe(({ req, res }) => {
    res.format({
      canEdit: false,
      label: req.chip.label,
      icon: (req.chip.icon && Icons[req.chip.icon]) || Icons.clipboardTick,
    });
  });

  router.formatChip('gridV2').subscribe(({ req, res }) => {
    res.format({
      canEdit: false,
      label: req.chip.label,
      icon: (req.chip.icon && Icons[req.chip.icon]) || Icons.document,
    });
  });

  router.formatChip('clickThroughGrid').subscribe(({ req, res }) => {
    res.format({
      canEdit: false,
      label: req.chip.label,
      icon: (req.chip.icon && Icons[req.chip.icon]) || Icons.document,
    });
  });

  router.formatChip('bulkUploadSucceeded').subscribe(({ req, res }) => {
    res.format({
      canEdit: false,
      label: req.chip.label,
      icon: (req.chip.icon && Icons[req.chip.icon]) || Icons.document,
    });
  });

  router.formatChip('bulkUploadFailed').subscribe(({ req, res }) => {
    res.format({
      canEdit: false,
      label: req.chip.label,
      icon: (req.chip.icon && Icons[req.chip.icon]) || Icons.document,
    });
  });

  router.formatChip('commentsList').subscribe(({ req, res }) => {
    res.format({
      canEdit: false,
      label: req.chip.label,
      icon: (req.chip.icon && Icons[req.chip.icon]) || Icons.document,
    });
  });

  router.formatChip('auditLog').subscribe(({ req, res }) => {
    res.format({
      canEdit: false,
      label: 'Audit Log',
      icon: (req.chip.icon && Icons[req.chip.icon]) || Icons.infoCard,
    });
  });

  router.formatChip('settings').subscribe(({ req, res }) => {
    res.format({
      canEdit: false,
      label: req.chip.label,
      icon: settingsIcon(req.chip.value),
    });
  });

  router.formatChip('formsDesignerTemplate').subscribe(({ req, res }) => {
    res.format({
      canEdit: false,
      label: req.chip.label,
      icon: (req.chip.icon && Icons[req.chip.icon]) || Icons.web,
    });
  });
};

const settingsIcon = (value: string) => {
  if (value === 'schedules') return Icons.settings;
  if (value === 'automatedActions') return Icons.bulbAndSparkles;
  if (value === 'formsBulkUpload') return Icons.publish;
  if (value === 'formsDesignerTemplates') return Icons.web;
  return Icons.settings;
};
