import { configureStore, Dispatch } from '@reduxjs/toolkit';
import {
  dashboardsReducers,
  dashboardsRootEpic,
} from '@seeeverything/ui.dashboards/src/redux/store.ts';
import { reducer as formsDesignerEditorReducer } from '@seeeverything/ui.forms.designer/src/redux/designer/index.ts';
import {
  formsDesignerCreateTemplatesSlice,
  formsDesignerDropdownsSlice,
  formsDesignerImportTemplatesSlice,
  formsDesignerPublishTemplatesSlice,
  formsDesignerRootEpic,
  formsDesignerViewTemplatesSlice,
} from '@seeeverything/ui.forms.designer/src/redux/index.ts';
import {
  formsReducers,
  formsRootEpic,
} from '@seeeverything/ui.forms/src/redux/index.ts';
import {
  shellReducers,
  shellRootEpic,
} from '@seeeverything/ui.shell/src/redux/index.ts';
import { submitToSegmentEpic } from '@seeeverything/ui.util/src/redux/analytics/epic.ts';
import { ReduxAnalyticsDependencies } from '@seeeverything/ui.util/src/redux/analytics/types.ts';
import { logActionsEpic } from '@seeeverything/ui.util/src/redux/debug/epics.ts';
import { debugSlice } from '@seeeverything/ui.util/src/redux/debug/index.ts';
import { scrollSlice } from '@seeeverything/ui.util/src/redux/scroll/index.ts';
import { tenantSlice } from '@seeeverything/ui.util/src/redux/tenant/index.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import {
  shallowEqual,
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux';
import { createEpicMiddleware } from 'redux-observable';
import { BehaviorSubject, switchMap } from 'rxjs';
import { analyticsInfoProvider } from '../analytics/analyticsInfoProvider.ts';
import { analyticsClient } from '../analytics/segmentAnalyticsClient.ts';
import { db } from '../common/index.ts';
import { appRootEpic } from '../config/config.redux/app/index.ts';
import { env } from '../env.ts';
import {
  EnhancedStore,
  GlobalAppEpicDependencies,
  GlobalAppState,
} from '../types.ts';

const analyticsDependencies = (): ReduxAnalyticsDependencies => {
  if (!env.ANALYTICS_ENABLED()) return {};

  return {
    analyticsClient,
    analyticsInfoProvider,
  };
};

const { client, uploadClient, batchClient } = db;

const dependencies = {
  ...analyticsDependencies(),
  client,
  uploadClient,
  batchClient,
};

const epicMiddleware = createEpicMiddleware<
  ReduxAction,
  ReduxAction,
  GlobalAppState,
  GlobalAppEpicDependencies
>({ dependencies });

export const store = configureStore({
  reducer: {
    ...shellReducers,
    ...formsReducers,
    ...dashboardsReducers,
    debug: debugSlice.reducer,
    scroll: scrollSlice.reducer,
    tenantState: tenantSlice.reducer,
    formsDesignerEditor: formsDesignerEditorReducer,
    formsDesignerCreateTemplates: formsDesignerCreateTemplatesSlice.reducer,
    formsDesignerDropdowns: formsDesignerDropdownsSlice.reducer,
    formsDesignerImportTemplates: formsDesignerImportTemplatesSlice.reducer,
    formsDesignerPublishTemplates: formsDesignerPublishTemplatesSlice.reducer,
    formsDesignerViewTemplates: formsDesignerViewTemplatesSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: false,
    }).concat(epicMiddleware),
}) as EnhancedStore;

const appEpic$ = new BehaviorSubject(appRootEpic);
const appEpic: typeof appRootEpic = (...args) =>
  appEpic$.pipe(switchMap((epic) => epic(...args)));

const shellEpic$ = new BehaviorSubject(shellRootEpic);
const shellEpic: typeof shellRootEpic = (...args) =>
  shellEpic$.pipe(switchMap((epic) => epic(...args)));

const formsEpic$ = new BehaviorSubject(formsRootEpic);
const formsEpic: typeof formsRootEpic = (...args) =>
  formsEpic$.pipe(switchMap((epic) => epic(...args)));

const formsDesignerEpic$ = new BehaviorSubject(formsDesignerRootEpic);
const formsDesignerEpic: typeof formsDesignerRootEpic = (...args) =>
  formsDesignerEpic$.pipe(switchMap((epic) => epic(...args)));

const dashboardsEpic$ = new BehaviorSubject(dashboardsRootEpic);
const dashboardsEpic: typeof dashboardsRootEpic = (...args) =>
  dashboardsEpic$.pipe(switchMap((epic) => epic(...args)));

const debugEpic$ = new BehaviorSubject(logActionsEpic);
const debugEpic: typeof logActionsEpic = (...args) =>
  debugEpic$.pipe(switchMap((epic) => epic(...args)));

const segmentEpic$ = new BehaviorSubject(submitToSegmentEpic);
const segmentEpic: typeof submitToSegmentEpic = (...args) =>
  segmentEpic$.pipe(switchMap((epic) => epic(...args)));

epicMiddleware.run(appEpic);
epicMiddleware.run(shellEpic);
epicMiddleware.run(formsEpic);
epicMiddleware.run(formsDesignerEpic);
epicMiddleware.run(dashboardsEpic);
epicMiddleware.run(debugEpic);
epicMiddleware.run(segmentEpic);

if (module.hot) {
  module.hot.accept('../config/config.redux/app', async () => {
    const nextRootEpic = (await import('../config/config.redux/app/index.ts'))
      .appRootEpic;
    appEpic$.next(nextRootEpic);
  });

  module.hot.accept('@seeeverything/ui.shell/src/redux', async () => {
    const nextRootEpic = (
      await import('@seeeverything/ui.shell/src/redux/index.ts')
    ).shellRootEpic;
    shellEpic$.next(nextRootEpic);
  });

  module.hot.accept(
    [
      '@seeeverything/ui.forms/src/redux',
      '@seeeverything/ui.forms/src/redux/editGoalSchedule/editGoalScheduleEpics',
    ],
    async () => {
      const nextRootEpic = (
        await import('@seeeverything/ui.forms/src/redux/index.ts')
      ).formsRootEpic;
      formsEpic$.next(nextRootEpic);
    },
  );

  module.hot.accept('@seeeverything/ui.forms.designer/src/redux', async () => {
    const nextRootEpic = (
      await import('@seeeverything/ui.forms.designer/src/redux/index.ts')
    ).formsDesignerRootEpic;
    formsDesignerEpic$.next(nextRootEpic);
  });

  module.hot.accept(
    '@seeeverything/ui.dashboards/src/redux/store',
    async () => {
      const nextRootEpic = (
        await import('@seeeverything/ui.dashboards/src/redux/store.ts')
      ).dashboardsRootEpic;
      dashboardsEpic$.next(nextRootEpic);
    },
  );

  module.hot.accept(
    '@seeeverything/ui.util/src/redux/debug/epics',
    async () => {
      const nextRootEpic = (
        await import('@seeeverything/ui.util/src/redux/debug/epics.ts')
      ).logActionsEpic;
      debugEpic$.next(nextRootEpic);
    },
  );

  module.hot.accept(
    '@seeeverything/ui.util/src/redux/analytics/epic',
    async () => {
      const nextRootEpic = (
        await import('@seeeverything/ui.util/src/redux/analytics/epic.ts')
      ).submitToSegmentEpic;
      segmentEpic$.next(nextRootEpic);
    },
  );
}

export type AppDispatch = Dispatch<ReduxAction>;
export type AppRootState = ReturnType<typeof store.getState>;

export const useAppDispatch = useDispatch<Dispatch<ReduxAction>>;
export const useAppSelector: TypedUseSelectorHook<AppRootState> = (selector) =>
  useSelector(selector, shallowEqual);
