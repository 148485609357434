/** @jsxImportSource @emotion/react */
import { useCallback, useMemo } from 'react';
import { IconWrapper } from '@seeeverything/ui.primitives/src/components/IconWrapper/IconWrapper.tsx';
import { TextFieldDropdown } from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';
import { FormActionPermissions } from '../../../redux/formAction/formActionSlice.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import {
  FormActionStatus,
  FormActionStatusWithOverdue,
} from '@se/data/forms/types.ts';

export interface IActionStatusDropdownProps {
  actionLabel: string;
  computedStatusChange?: FormActionStatusWithOverdue;
  draftStatus?: FormActionStatusWithOverdue;
  initialStatus?: FormActionStatusWithOverdue;
  isEnabled: boolean;
  onChange: (to: FormActionStatus) => void;
  permissions?: FormActionPermissions;
}

export const ActionStatusDropdown: React.FC<IActionStatusDropdownProps> = ({
  actionLabel,
  computedStatusChange,
  draftStatus,
  initialStatus,
  isEnabled,
  onChange,
  permissions,
}) => {
  const isInitial = initialStatus === draftStatus;
  const isComputed = Boolean(computedStatusChange);

  const statusSelection = useMemo(
    () =>
      getSelectionOption(
        actionLabel,
        computedStatusChange ?? draftStatus,
        isInitial,
        isComputed,
      ),
    [actionLabel, computedStatusChange, draftStatus, isInitial, isComputed],
  );

  const selections = useMemo(
    () => getSelections(actionLabel, draftStatus, initialStatus, permissions),
    [actionLabel, draftStatus, initialStatus, permissions],
  );

  const handleStatusChange = useCallback(
    ({ id }: { id: FormActionStatus }) => onChange(id),
    [onChange],
  );

  return (
    <IconWrapper icon={'info'}>
      <TextFieldDropdown
        id={'actionStatus'}
        isEnabled={isEnabled}
        label={'Status'}
        onChange={handleStatusChange}
        selections={selections}
        value={statusSelection}
        variant={'standard'}
      />
    </IconWrapper>
  );
};

const openStatus = (
  isInitial: boolean,
  isComputed: boolean,
  actionLabel: string,
) => ({
  id: 'Open',
  icon: () => <Icons.info fill={COLORS.BLUE} />,
  content: {
    text: isComputed ? 'Open (from Verification)' : 'Open',
    secondaryText: isInitial ? '(Current)' : undefined,
    description: isInitial
      ? `Continue working on this ${actionLabel}.`
      : `Re-open this ${actionLabel}.`,
  },
});

const overdueStatus = (isInitial: boolean, actionLabel: string) => ({
  id: 'Overdue',
  icon: () => <Icons.accessTime fill={COLORS.BLUE} />,
  content: {
    text: 'Overdue',
    secondaryText: isInitial ? '(Current)' : undefined,
    description: `Continue working on this ${actionLabel}.`,
  },
});

const completedStatus = (isInitial: boolean, actionLabel: string) => ({
  id: 'Completed',
  icon: () => <Icons.verifiedUser fill={'#2FAA47'} />,
  content: {
    text: 'Completed',
    secondaryText: isInitial ? '(Current)' : undefined,
    description: isInitial
      ? `This ${actionLabel} is complete/closed.`
      : `Complete/close this ${actionLabel}.`,
  },
});

const cancelledStatus = (isInitial: boolean, actionLabel: string) => ({
  id: 'Cancelled',
  icon: () => <Icons.cancel fill={'#F33A30'} />,
  content: {
    text: 'Canceled',
    secondaryText: isInitial ? '(Current)' : undefined,
    description: isInitial
      ? `This ${actionLabel} is canceled.`
      : `Cancel/remove this ${actionLabel}.`,
  },
});

const getSelectionOption = (
  actionLabel: string,
  status: FormActionStatusWithOverdue,
  isInitial: boolean,
  isComputed: boolean,
) => {
  if (!status) return;

  if (status === 'Open') return openStatus(isInitial, isComputed, actionLabel);
  if (status === 'Overdue') return overdueStatus(isInitial, actionLabel);
  if (status === 'Completed') return completedStatus(isInitial, actionLabel);
  if (status === 'Cancelled') return cancelledStatus(isInitial, actionLabel);
};

const getSelections = (
  actionLabel: string,
  status: FormActionStatusWithOverdue,
  initialStatus: FormActionStatusWithOverdue,
  permissions?: FormActionPermissions,
) => {
  if (!permissions) return [];

  const isInitial = status === initialStatus;

  return [
    !isInitial && getSelectionOption(actionLabel, initialStatus, true, false),
    permissions.revert && openStatus(false, false, actionLabel),
    permissions.close && completedStatus(false, actionLabel),
    permissions.cancel && cancelledStatus(false, actionLabel),
  ].filter(Boolean);
};
