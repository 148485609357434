import moment from 'moment';
import * as types from './types.ts';

const DEFAULT_STATE: types.CsvExportState = {
  startDate: moment().toISOString(),
  endDate: moment().toISOString(),
  dataSetId: '',
  categories: [],
  templates: [],
  selectedFilters: [],
  areTemplatesAndCategoriesLoading: true,
};

export function reducer(
  state: types.CsvExportState = DEFAULT_STATE,
  action: types.CsvExportAction,
) {
  switch (action.type) {
    case 'ui.shell/csvExport/DATE_CHANGED': {
      const { type, to } = action.payload;
      return {
        ...state,
        endDate: type === 'END' ? to : state.endDate,
        startDate: type === 'START' ? to : state.startDate,
      };
    }

    case 'ui.shell/csvExport/SELECTED_FILTERS_CHANGED':
      return {
        ...state,
        selectedFilters: action.payload.to,
      };

    case 'ui.shell/csvExport/FILTERS_LOADED': {
      const { templates, categories } = action.payload;
      return {
        ...state,
        templates,
        categories,
        areTemplatesAndCategoriesLoading: false,
      };
    }

    case 'ui.shell/csvExport/LOAD': {
      return {
        ...state,
        endDate: action.payload.endDate,
        startDate: action.payload.startDate,
        dataSetId: action.payload.dataSetId,
        forSheet: action.payload.sheetArgs,
        selectedFilters: ['ALL'],
      };
    }

    case 'ui.shell/csvExport/REINITIALIZE':
      return DEFAULT_STATE;

    default:
      return state;
  }
}
