import { IntersectionOptions } from 'react-intersection-observer';

export const MONTH_OCTOBER = 10;

export const DASHBOARD_INTERSECTION_OPTIONS: IntersectionOptions = {
  rootMargin: '-194px 0px 0px 0px',
  delay: 150,
};

export const PAGE_SIZE_DASHBOARD_GRID = 10;
export const PAGE_SIZE_FULL_GRID = 100;
