import { CellTheme } from '@seeeverything/ui.primitives/src/components/DataGrid/themes.ts';
import { IDataGridHeaderGroup } from '@seeeverything/ui.primitives/src/components/DataGrid/types.ts';
import { OrderBy } from '@seeeverything/ui.util/src/types.ts';
import {
  IDashboardClickThroughGrid,
  IDashboardExtendedGridColumn,
  IDashboardGrid,
} from '../types.ts';
import { parseCategoryMap } from './parse.categoryMap.ts';
import { parseContentMap } from './parse.contentMap.ts';

/**
 * Translates the definition of a YAML grid to a dashboard grid.
 */
export function parser(item: any, index = 0): IDashboardGrid | undefined {
  const gridDefinition = item.grid ?? item['click-through-grid'];
  if (!gridDefinition) return;

  return {
    componentType: item['click-through-grid'] ? 'CLICK_THROUGH_GRID' : 'GRID',
    id: gridDefinition.id,
    icon: gridDefinition.icon,
    columns: gridDefinition.columns.map(parseColumn),
    group: gridDefinition.group,
    name: gridDefinition.name,
    footer: gridDefinition.footer,
    index,
    title: gridDefinition.title,
    headerGroups: parseGroupsDefinition(gridDefinition['header-groups']),
    level: 'h3',
    dataState: {
      dataSetId: gridDefinition['data-set'],
      isLoaded: false,
      isLoading: false,
      forceReload: false,
    },
    isHidden: false,
    tagStyles: parseRowStyle(gridDefinition['row-style']),
    pageSize: gridDefinition['page-size'],
    hasExpandButton: gridDefinition['has-expand-button'] ?? true,
    initialOrderBys: initialOrderBy(gridDefinition['default-sort-order']),
    tempFormStatusExcludeCompleted: gridDefinition.columns.some(
      (column: any) => column['form-filter'] === 'exclude-completed',
    ),
  };
}

const parseGroupsDefinition = (
  groupsDefinition: any[],
): IDataGridHeaderGroup[] =>
  groupsDefinition?.length
    ? groupsDefinition.map((groupDefinition: any) => ({
        groupId: groupDefinition['group-id'],
        linkableHeader: { scrollToId: groupDefinition['scroll-to-id'] },
      }))
    : undefined;

const initialOrderBy = (definition: any): OrderBy[] => {
  if (!definition) return [];

  if (Array.isArray(definition) && definition.length > 0) {
    return definition.map(({ id, direction }: any) => ({
      fieldName: id,
      direction,
    }));
  }

  if (definition.id && definition.direction)
    return [{ fieldName: definition.id, direction: definition.direction }];

  return [];
};

const parseColumn = (columnDefinition: any): IDashboardExtendedGridColumn => ({
  ...columnDefinition,
  borderRight: columnDefinition['border-right'],
  isSortable: columnDefinition['is-sortable'],
  isFilterable: columnDefinition['is-filterable'],
  canLaunch: columnDefinition['can-launch'],
  isHidden: columnDefinition['is-hidden'] === true,
  contentMap: parseContentMap(columnDefinition['content-map']),
  trendMap: parseContentMap(columnDefinition['trend-map']),
  categoryMap: parseCategoryMap(columnDefinition['category-map']),
  clickThrough: parseClickThrough(columnDefinition['click-through']),
});

const parseClickThrough = (clickThrough: any): IDashboardClickThroughGrid =>
  clickThrough
    ? {
        gridId: clickThrough['grid-id'],
        definitionGridId: clickThrough['definition-grid-id'],
        type: clickThrough.type || 'SESSIONS',
      }
    : undefined;

const parseRowStyle = (rowStyle: any) =>
  rowStyle?.tags?.reduce(
    (acc: Record<string, CellTheme>, value: any) => ({
      ...acc,
      [value.id]: value.style,
    }),
    {},
  );
