/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { authApi } from '@seeeverything/ui.auth/src/api/api.ts';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Popup } from '@seeeverything/ui.primitives/src/components/Popup/Popup.tsx';
import {
  IListItemLabel,
  ISelectionListItem,
  SelectionListClickEvent,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { SelectionListHierarchyViewport } from '@seeeverything/ui.primitives/src/components/SelectionListHierarchyViewport/SelectionListHierarchyViewport.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { TenantModuleConfiguration } from '@seeeverything/ui.util/src/redux/tenant/types.ts';
import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  logout,
  showChangePasswordDialog,
} from '../../../redux/app/actions.ts';
import { chipAddedExternally } from '../../../redux/query/actions.ts';
import { changeModule } from '../../../redux/sheets/actions.ts';
import { useShellDispatch, useShellSelector } from '../../../redux/store.ts';

type SelectionId =
  | 'applications'
  | 'applications:coaching'
  | 'applications:compliance'
  | 'applications:cadence'
  | 'applications:reporting'
  | 'settings'
  | 'settings:schedules'
  | 'settings:formsDesigner'
  | 'settings:formsBulkUpload'
  | 'settings:automatedActions'
  | 'profile'
  | 'profile:tenant'
  | 'profile:password'
  | 'profile:logout';

type Selection = ISelectionListItem<IListItemLabel, void, SelectionId>;

const moduleToSelectionItem = (
  module: ModuleType,
  config: TenantModuleConfiguration,
): Selection => ({
  id: `applications:${module}`,
  content: { text: config.label },
  icon: config.icon && Icons[config.icon],
});

export interface IModuleDropdownContainerProps {
  variant: 'QUERY_BUILDER' | 'DASHBOARD';
}

export const ModuleDropdownContainer: React.FC<
  IModuleDropdownContainerProps
> = ({ variant }) => {
  const dispatch = useShellDispatch();
  const [dropdownShowing, setDropdownShowing] = useState(false);

  const reportingUrl = useShellSelector(
    (state) => state.tenantState.reportingUrl,
  );

  const module = useShellSelector((state) => state.tenantState.tenant?.module);

  const selectedModule = useShellSelector(
    (state) =>
      state.tenantState.tenant?.configuration.modules?.[
        state.tenantState.tenant?.module
      ],
  );

  const lastChip = useShellSelector(
    (state) => state.query.query.chips[state.query.query.chips.length - 1],
  );

  const selectionItems = useCreateSelectionItems(variant);

  const toggleShowingDropdown = useCallback(
    () => setDropdownShowing((current) => !current),
    [],
  );

  const hideDropdown = useCallback(() => setDropdownShowing(false), []);

  const handleDropdownItemSelected = useCallback(
    (e: SelectionListClickEvent<SelectionId>) => {
      switch (e.id) {
        case 'applications:coaching': {
          dispatch(changeModule(module, 'coaching', variant));
          break;
        }

        case 'applications:compliance': {
          dispatch(changeModule(module, 'compliance', variant));
          break;
        }

        case 'applications:cadence': {
          dispatch(changeModule(module, 'cadence', variant));
          break;
        }

        case 'applications:reporting': {
          window.open(reportingUrl, '_blank');
          break;
        }

        case 'settings:schedules': {
          if (lastChip?.type === 'settings' && lastChip?.value === 'schedules')
            return;
          dispatch(
            chipAddedExternally(
              {
                type: 'settings',
                value: 'schedules',
                label: e.item.content.text,
              },
              true,
            ),
          );
          break;
        }

        case 'settings:formsDesigner': {
          if (
            lastChip?.type === 'settings' &&
            lastChip?.value === 'formsDesignerTemplates'
          )
            return;
          dispatch(
            chipAddedExternally(
              {
                type: 'settings',
                value: 'formsDesignerTemplates',
                label: e.item.content.text,
              },
              true,
            ),
          );
          break;
        }

        case 'settings:formsBulkUpload': {
          if (
            lastChip?.type === 'settings' &&
            lastChip?.value === 'formsBulkUpload'
          )
            break;

          dispatch(
            chipAddedExternally(
              {
                type: 'settings',
                value: 'formsBulkUpload',
                label: e.item.content.text,
              },
              true,
            ),
          );
          break;
        }

        case 'settings:automatedActions': {
          if (
            lastChip?.type === 'settings' &&
            lastChip?.value === 'automatedActions'
          )
            break;

          dispatch(
            chipAddedExternally(
              {
                type: 'settings',
                value: 'automatedActions',
                label: e.item.content.text,
              },
              true,
            ),
          );
          break;
        }

        case 'profile:tenant': {
          window.location.assign('/tenant');
          break;
        }

        case 'profile:password': {
          dispatch(showChangePasswordDialog());
          break;
        }

        case 'profile:logout': {
          dispatch(logout());
          break;
        }
      }

      hideDropdown();
    },
    [
      hideDropdown,
      dispatch,
      module,
      variant,
      reportingUrl,
      lastChip?.type,
      lastChip?.value,
    ],
  );

  const computedStyles = useMemo(
    () => ({
      chipDashboard: css({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 40,
        backgroundColor: dropdownShowing ? '#ebebeb' : undefined,
        padding: '0 12px',
        cursor: 'pointer',
      }),
      chipQueryBuilder: css({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 40,
        backgroundColor: color.format(dropdownShowing ? 0.4 : 0.24),
        border: `solid 1px ${color.format(0.15)}`,
        borderRadius: 2,
        padding: '0 4px 0 12px',
        cursor: 'pointer',
      }),
    }),
    [dropdownShowing],
  );

  const Icon = useMemo(
    () => (selectedModule?.icon ? Icons[selectedModule?.icon] : undefined),
    [selectedModule?.icon],
  );

  const buttonColor = variant === 'DASHBOARD' ? COLORS.BLUE : 'white';
  const buttonHoverAlpha = variant === 'DASHBOARD' ? undefined : 0.1;

  const elChip = (
    <Button
      fill={variant === 'DASHBOARD' ? undefined : 'white'}
      onClick={toggleShowingDropdown}
      hoverAlpha={dropdownShowing ? undefined : buttonHoverAlpha}
    >
      <div
        css={
          variant === 'DASHBOARD'
            ? computedStyles.chipDashboard
            : computedStyles.chipQueryBuilder
        }
      >
        <div css={styles.chipContent}>
          {Icon && (
            <Icon
              fill={buttonColor}
              cursor={'inherit'}
              style={{ paddingRight: 5 }}
            />
          )}
          <Text
            color={buttonColor}
            size={16}
            cursor={'inherit'}
            ellipsis={true}
          >
            {selectedModule?.label}
          </Text>
        </div>
        {variant === 'QUERY_BUILDER' && (
          <Icons.arrowDropDown
            fill={buttonColor}
            cursor={'inherit'}
            size={32}
          />
        )}
      </div>
    </Button>
  );

  return (
    <div css={styles.base} data-test={`shell-moduleDropdown-${variant}`}>
      {elChip}
      {dropdownShowing && (
        <Popup onHide={hideDropdown}>
          <div css={styles.dropdown}>
            <SelectionListHierarchyViewport
              column={{ items: selectionItems, isScrollable: false }}
              isScrollable={false}
              isStateful={false}
              keys$={null}
              onClick={handleDropdownItemSelected}
            />
          </div>
        </Popup>
      )}
    </div>
  );
};

const useCreateSelectionItems = (
  variant: 'QUERY_BUILDER' | 'DASHBOARD',
): Selection[] => {
  const [canChangePassword, setCanChangePassword] = useState(false);

  useEffect(() => {
    const resolveCanChangePassword = async () =>
      setCanChangePassword(await authApi.canChangePassword());

    resolveCanChangePassword();
  }, []);

  const permissions = useShellSelector((state) => ({
    schedules: state.app.permissions.scheduleManage,
    formDesigner: state.app.permissions.templateManage,
    bulkFormLoader: state.app.permissions.bulkFormLoadManage,
    canCreateAutomatedActions: state.app.permissions.automatedActionManage,
    reportingEnabled:
      state.tenantState.reportingUrl &&
      state.tenantState.tenant.authenticatedUser.permissions.canAccessReporting,
  }));

  const locale = useShellSelector((state) => state.tenantState.tenant.locale);

  const module = useShellSelector((state) => state.tenantState.tenant.module);

  const modulesConfig = useShellSelector(
    (state) => state.tenantState.tenant.configuration.modules,
  );
  const isMultiTenant = useShellSelector(
    (state) => state.tenantState.isMultiTenant,
  );

  const applications = useMemo<Selection[]>(() => {
    const result: Selection[] = [
      {
        id: 'applications',
        content: { text: 'Applications' },
        type: 'SECTION',
      },
    ];

    if (modulesConfig.coaching?.isEnabled)
      result.push(moduleToSelectionItem('coaching', modulesConfig.coaching));

    if (modulesConfig.compliance?.isEnabled)
      result.push(
        moduleToSelectionItem('compliance', modulesConfig.compliance),
      );

    if (modulesConfig.cadence?.isEnabled)
      result.push(moduleToSelectionItem('cadence', modulesConfig.cadence));

    if (permissions.reportingEnabled && variant === 'QUERY_BUILDER')
      result.push({
        id: 'applications:reporting',
        content: { text: 'Reporting' },
        icon: Icons.autoGraph,
      });

    return result;
  }, [
    modulesConfig.cadence,
    modulesConfig.coaching,
    modulesConfig.compliance,
    permissions.reportingEnabled,
    variant,
  ]);

  const settings = useMemo<Selection[]>(() => {
    if (variant === 'DASHBOARD') return [];

    const showSchedules = permissions.schedules;
    const showFormDesigner = permissions.formDesigner;
    const showBulkFormLoader =
      module !== 'cadence' && permissions.bulkFormLoader;
    const showAutomatedActions = permissions.canCreateAutomatedActions;

    if (
      !showSchedules &&
      !showFormDesigner &&
      !showBulkFormLoader &&
      !showAutomatedActions
    )
      return [];

    const result: Selection[] = [
      {
        id: 'settings',
        content: { text: `${module} Administration` },
        type: 'SECTION',
      },
    ];

    if (showSchedules) {
      result.push({
        id: 'settings:schedules',
        content: { text: locale.label.schedules },
        icon: Icons.settings,
      });
    }

    if (showFormDesigner)
      result.push({
        id: 'settings:formsDesigner',
        content: { text: `${locale.label.form} Designer` },
        icon: Icons.web,
      });

    if (showBulkFormLoader)
      result.push({
        id: 'settings:formsBulkUpload',
        content: { text: `${locale.label.form} Loader` },
        icon: Icons.publish,
      });

    if (showAutomatedActions)
      result.push({
        id: 'settings:automatedActions',
        content: { text: 'Smart Actions' },
        icon: Icons.bulbAndSparkles,
      });

    return result;
  }, [
    variant,
    permissions.schedules,
    permissions.formDesigner,
    permissions.bulkFormLoader,
    permissions.canCreateAutomatedActions,
    module,
    locale.label.form,
    locale.label.schedules,
  ]);

  const profile = useMemo<Selection[]>(() => {
    if (variant === 'DASHBOARD') return [];

    const result: Selection[] = [
      { id: 'profile', content: { text: 'Profile' }, type: 'SECTION' },
    ];

    if (canChangePassword)
      result.push({
        id: 'profile:password',
        content: { text: 'Change Password' },
        icon: Icons.lock,
      });

    if (isMultiTenant)
      result.push({
        id: 'profile:tenant',
        content: { text: 'Switch Tenant' },
        icon: Icons.company,
      });

    result.push({
      id: 'profile:logout',
      content: { text: 'Logout' },
      icon: Icons.logout,
    });

    return result;
  }, [canChangePassword, isMultiTenant, variant]);

  return useMemo(
    () => [].concat(...applications, ...settings, ...profile),
    [applications, profile, settings],
  );
};

const styles = {
  base: css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 40,
  }),
  chipContent: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  dropdown: css({
    backgroundColor: 'white',
    border: 'solid 1px #d0d0d0',
    boxShadow: '0 2px 4px rgb(0 0 0 / 15%), 0 0 0 1px rgb(0 0 0 / 15%)',
    borderRadius: '0 0 3px 3px',
    minWidth: 280,
    zIndex: 51, // Appears above the dashboard scrollbars.
  }),
};
