import { authApi } from '@seeeverything/ui.auth/src/api/api.ts';
import { graphql } from '@seeeverything/ui.util/src/graphql/index.ts';
import { ResolveHeadersType } from '@seeeverything/ui.util/src/graphql/links/headers.ts';
import { storageApi } from '@seeeverything/ui.util/src/storage/api.ts';
import { env } from '../env.ts';

const resolveHeaders: ResolveHeadersType = {
  authorization: async () => {
    try {
      const token = await authApi.getAccessToken();
      if (!token) await authApi.logout(true);

      return token ? `bearer ${token}` : undefined;
    } catch {
      await authApi.logout(true);
    }
  },
  'SE-Timezone': async () => storageApi.timezone.getSessionStorage(),
  module: async () => storageApi.module.getSessionStorage(),
  Tenant: async () => storageApi.tenant.getSessionStorage(),
};

export const client = graphql.init(env.GRAPHQL_ENDPOINT(), {
  resolveHeaders,
});

export const batchClient = graphql.init(env.GRAPHQL_ENDPOINT(), {
  resolveHeaders,
  link: {
    type: 'BatchHttpLink',
    options: {
      batchInterval: 10,
      batchMax: 3,
    },
  },
});

export const uploadClient = graphql.init(env.GRAPHQL_ENDPOINT(), {
  resolveHeaders,
  link: { type: 'UploadLink', options: {} },
});
