import {
  DashboardDateFilterPeriod,
  DashboardTimespanVariable,
} from '@se/data/dashboards/query/types.ts';
import moment from 'moment';
import { DashboardV2ReduxState } from '../redux/types.ts';
import {
  DashboardDateFilter,
  DashboardDateFilterSelection,
  IDashboardDateFilterRepeatingFrequency,
  IDateRange,
} from '../types.ts';

export const toTimespanVariable = (
  dateFilter?: DashboardDateFilterSelection,
): DashboardTimespanVariable => {
  if (!dateFilter?.selection) return;

  const amount = toAmount(dateFilter);
  const period = toPeriod(dateFilter);
  const startDate = toStartDate(dateFilter);
  const endDate = toEndDate(dateFilter);

  return {
    span: {
      period,
      amount,
      startDate,
      endDate,
    },
  };
};

export const toDateFilter = (
  state: DashboardV2ReduxState,
): DashboardDateFilterSelection | undefined => {
  const settings = state.dashboardsV2.settings;
  if (!settings) return undefined;

  const defaultDateFilter = state.dashboardsV2.HEADER?.defaultDateFilter;
  if (!defaultDateFilter) return undefined;

  const dateFilters = state.dashboardsV2.HEADER?.dateFilters;
  const selectedDateFilterId = settings.selectedDateFilterId;

  const dateFilter =
    (selectedDateFilterId &&
      dateFilters?.find((filter) => filter.id === selectedDateFilterId)) ??
    defaultDateFilter;

  return {
    selection: dateFilter,
    financialYearStartMonth: settings.financialYearStartMonth,
    selectedCustomDates: settings.selectedCustomDates,
  };
};

const toPeriod = (
  dateFilter: DashboardDateFilterSelection,
): DashboardDateFilterPeriod => {
  switch (dateFilter.selection.kind) {
    case 'TODAY_BACKWARDS':
      return dateFilter.selection.period;

    case 'CUSTOM_RANGE': {
      if (!dateFilter.selectedCustomDates)
        throw new Error('Custom range with no dates selected');

      const { startDateISO, endDateISO } = dateFilter.selectedCustomDates;
      const start = moment(startDateISO);
      const end = moment(endDateISO);
      const daysDiff = end.diff(start, 'd') + 1;

      if (daysDiff < 30) return 'Day';
      if (daysDiff < 180) return 'Week';

      return 'Month';
    }

    case 'REPEATING_FREQUENCY':
      return dateFilter.selection.period;
  }
};

const toAmount = (dateFilter: DashboardDateFilterSelection): number => {
  switch (dateFilter.selection.kind) {
    case 'TODAY_BACKWARDS':
      return dateFilter.selection.amount;

    case 'CUSTOM_RANGE': {
      if (!dateFilter.selectedCustomDates)
        throw new Error('Custom range with no dates selected');
      return 0;
    }

    case 'REPEATING_FREQUENCY':
      return dateFilter.selection.amount;
  }
};

const toEndDate = (
  dateFilter: DashboardDateFilterSelection,
): string | undefined => {
  switch (dateFilter.selection.kind) {
    case 'TODAY_BACKWARDS':
    case 'REPEATING_FREQUENCY':
      return undefined;

    case 'CUSTOM_RANGE': {
      if (!dateFilter.selectedCustomDates)
        throw new Error('Custom range with no dates selected');

      const { endDateISO } = dateFilter.selectedCustomDates;
      return moment(endDateISO).format('YYYY-MM-DD');
    }
  }
};

const toStartDate = (
  dateFilter: DashboardDateFilterSelection,
): string | undefined => {
  switch (dateFilter.selection.kind) {
    case 'TODAY_BACKWARDS':
      return undefined;

    case 'CUSTOM_RANGE': {
      if (!dateFilter.selectedCustomDates) {
        throw new Error('Custom range with no dates selected');
      }
      const { startDateISO } = dateFilter.selectedCustomDates;
      return moment(startDateISO).format('YYYY-MM-DD');
    }

    case 'REPEATING_FREQUENCY':
      return moment(
        (
          dateFilter.selection as IDashboardDateFilterRepeatingFrequency
        ).startDate({
          financialYearStartMonth: dateFilter.financialYearStartMonth,
        }),
      ).format('YYYY-MM-DD');
  }
};

export const dateRangeFromFilter = (
  dateFilter: DashboardDateFilter | undefined,
  financialYearStartMonth: number | undefined,
  selectedCustomDates?: { startDateISO: string; endDateISO: string },
): IDateRange => {
  switch (dateFilter?.kind) {
    case 'CUSTOM_RANGE':
      return selectedCustomDates
        ? {
            startDate: selectedCustomDates.startDateISO,
            endDate: selectedCustomDates.endDateISO,
          }
        : {};

    case 'REPEATING_FREQUENCY':
      return {
        startDate: dateFilter
          .startDate({ financialYearStartMonth })
          .toISOString(),
        endDate: dateFilter.endDate({ financialYearStartMonth }).toISOString(),
      };

    case 'TODAY_BACKWARDS':
      return {
        startDate: dateFilter.startDate.toISOString(),
        endDate: dateFilter.endDate.toISOString(),
      };

    default:
      return {};
  }
};
