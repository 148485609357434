/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useCallback } from 'react';
import { DashboardComment } from '../../../types.ts';
import { CommentsDescription } from './CommentsDescription.tsx';

export interface ICommentProps {
  comment: DashboardComment;
  onFormClicked: (instanceId: string, instanceLabel: string) => void;
}

export const Comment: React.FC<ICommentProps> = ({
  comment,
  onFormClicked,
}) => {
  const handleFormClicked = useCallback(() => {
    if (!comment.form) return;
    onFormClicked(comment.form.id, comment.form.name);
  }, [comment.form, onFormClicked]);

  return (
    <div key={`comment-${comment.id}`} css={styles.base}>
      <Button
        isEnabled={Boolean(comment.form)}
        onClick={handleFormClicked}
        style={{ padding: 5, margin: -5 }}
      >
        <div css={styles.button}>
          {Boolean(comment.form) && <Icons.launch size={16} />}
          <Text
            color={comment.form ? COLORS.BLUE : color.format(-1)}
            size={14}
            weight={400}
            cursor={'inherit'}
          >
            {`About ${comment.subject} on ${comment.occurredAt}`}
          </Text>
        </div>
      </Button>
      <CommentsDescription description={comment.description} />
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    padding: 8,
    borderBottom: `1px solid ${color.format(-0.1)}`,
    gap: 5,
  }),
  button: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 6,
  }),
};
