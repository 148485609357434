/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import { StatusBar } from './StatusBar.tsx';
import { hideStatusBar } from '../../redux/sheets/actions.ts';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';

export const StatusBarContainer = () => {
  const dispatch = useShellDispatch();
  const isShowing = useShellSelector(
    (state) => state.sheets.statusBar.isShowing,
  );
  const kind = useShellSelector((state) => state.sheets.statusBar.kind);
  const message = useShellSelector((state) => state.sheets.statusBar.message);
  const autoHideDuration = useShellSelector(
    (state) => state.sheets.statusBar.autoHideDuration,
  );

  const hide = useCallback(() => dispatch(hideStatusBar()), [dispatch]);

  return (
    <StatusBar
      autoHideDuration={autoHideDuration}
      type={kind}
      message={message}
      isShowing={isShowing}
      onClose={hide}
    />
  );
};
