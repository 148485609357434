/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import { useMemo } from 'react';
import { IIcon } from '../../Icon/types.ts';
import { Text } from '../../Text/Text.tsx';
import { TextButton } from '../../TextButton/TextButton.tsx';
import { IDataGridColumn } from '../types.ts';

export interface IHeaderCellProps {
  border?: string;
  borderHeavy?: string;
  capitalized?: boolean;
  column: IDataGridColumn;
  displayValue: string;
  fontSize?: string | number;
  getHeaderIcon?: (columnId: string) => IIcon;
  gridId: string;
  isFirstRow: boolean;
  isLastColumn: boolean;
  isLastRow: boolean;
  onClick?: () => void;
  renderHeaderPopup?: (columnId: string) => React.ReactNode;
}

export const HeaderCell: React.FC<IHeaderCellProps> = ({
  border = `solid 1px ${color.format(-0.1)}`,
  borderHeavy = `solid 8px ${color.format(-0.1)}`,
  capitalized = false,
  column,
  displayValue,
  fontSize = 14,
  getHeaderIcon,
  isFirstRow,
  isLastColumn,
  isLastRow,
  onClick,
  renderHeaderPopup,
}) => {
  const {
    align = 'left',
    borderRight,
    isSortable = true,
    isHidden = false,
    isFilterable,
  } = column;

  const canShowPopup = (isFilterable || isSortable) && isLastRow;

  const isClickable = onClick || canShowPopup;

  const cursor = canShowPopup ? 'pointer' : 'default';
  const computedStyles = {
    base: css({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box',
      textAlign: align,
      minHeight: 40,
      padding: isHidden || isClickable ? 0 : '0 8px',
      width: isHidden ? 0 : undefined,
      borderBottom: isLastRow ? borderHeavy : border,
      borderRight:
        !isFirstRow && !isLastColumn && borderRight ? border : undefined,
      cursor,
    }),
    textButton: {
      flex: '1 1 auto',
      paddingLeft: 8,
      paddingRight: 8,
      minWidth: 0,
      height: 32,
      width: '100%',
      borderRadius: isLastRow ? 0 : 4,
    },
  };

  const elPopup = useMemo(
    () => isLastRow && renderHeaderPopup?.(column.id),
    [column.id, isLastRow, renderHeaderPopup],
  );

  return (
    <div css={computedStyles.base}>
      {isClickable ? (
        <TextButton
          size={fontSize}
          ellipsis={true}
          weight={FontWeight.black}
          color={isLastRow ? color.format(-0.5) : COLORS.BLUE}
          uppercase={capitalized}
          textButtonStyle={computedStyles.textButton}
          cursor={cursor}
          onClick={onClick}
          buttonSize={isLastRow ? 'small' : 'medium'}
          align={align}
          icon={getHeaderIcon?.(column.id)}
          iconSize={16}
          iconSide={'RIGHT'}
          iconStyle={styles.sortIcon}
        >
          {displayValue}
        </TextButton>
      ) : (
        <Text
          size={fontSize}
          ellipsis={true}
          weight={FontWeight.black}
          color={color.format(-0.5)}
          style={styles.text}
          cursor={cursor}
          uppercase={capitalized}
        >
          {displayValue}
        </Text>
      )}
      {elPopup}
    </div>
  );
};

const styles = {
  text: css({
    flex: '1 1 auto',
  }),
  sortIcon: {
    marginLeft: 'auto',
  },
};
