import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';

type CreatedDraftPayload = {
  id: string;
  name: string;
};

type ValidationNewPayload = {
  name?: string;
};

type ValidationOverwriteExistingPayload = {
  sourceTemplate?: string;
};

export type FormsDesignerReduxCreateTemplatesState = {
  createNewInputs: {
    name?: string;
    validationErrors: {
      name?: string;
    };
  };
  createFromExistingInputs: {
    rename?: string;
    sourceTemplate?: ISelectionListItem;
    validationErrors: {
      rename?: string;
      sourceTemplate?: string;
    };
  };
  selectedMethod: 'NEW' | 'COPY_FROM_EXISTING';
  status?: 'SAVING' | 'ERROR' | 'VALIDATION_ERROR';
  error?: string;
};

const DEFAULT_STATE: FormsDesignerReduxCreateTemplatesState = {
  createNewInputs: { validationErrors: {} },
  createFromExistingInputs: { validationErrors: {} },
  selectedMethod: 'NEW',
};

const slice = createSlice({
  name: 'libs/formsDesigner/templates/create',
  initialState: DEFAULT_STATE,
  reducers: {
    cancelDraftCreation(state) {
      state.createNewInputs = { validationErrors: {} };
      state.createFromExistingInputs = { validationErrors: {} };
      state.selectedMethod = 'NEW';
      state.status = undefined;
      state.error = undefined;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createdDraft(state, action: PayloadAction<CreatedDraftPayload>) {
      state.createNewInputs = { validationErrors: {} };
      state.createFromExistingInputs = { validationErrors: {} };
      state.selectedMethod = 'NEW';
      state.status = undefined;
      state.error = undefined;
    },
    createdDraftFailed(state) {
      state.status = 'ERROR';
      state.error =
        'Something went wrong. Please contact your support team or try again later.';
    },
    createDraft(state) {
      state.status = 'SAVING';
    },
    setSelectedMethod(
      state,
      action: PayloadAction<'NEW' | 'COPY_FROM_EXISTING'>,
    ) {
      state.selectedMethod = action.payload;
    },
    updateNameFieldOnNew(state, action: PayloadAction<string>) {
      state.createNewInputs.name = action.payload;
      state.createNewInputs.validationErrors.name = undefined;
    },
    updateRenameFieldOnCopyExisting(state, action: PayloadAction<string>) {
      state.createFromExistingInputs.rename = action.payload;
      state.createFromExistingInputs.validationErrors.rename = undefined;
    },
    updateSourceTemplateOnCopyExisting(
      state,
      action: PayloadAction<ISelectionListItem>,
    ) {
      state.createFromExistingInputs.sourceTemplate = action.payload;
      state.createFromExistingInputs.validationErrors.sourceTemplate =
        undefined;
    },
    validationErrorsOnNew(state, action: PayloadAction<ValidationNewPayload>) {
      state.createNewInputs.validationErrors.name = action.payload.name;
    },
    validationErrorsOnOverwriteExisting(
      state,
      action: PayloadAction<ValidationOverwriteExistingPayload>,
    ) {
      state.createFromExistingInputs.validationErrors.sourceTemplate =
        action.payload.sourceTemplate;
    },
  },
});

export const {
  cancelDraftCreation,
  createdDraft,
  createdDraftFailed,
  createDraft,
  setSelectedMethod,
  updateNameFieldOnNew,
  updateRenameFieldOnCopyExisting,
  updateSourceTemplateOnCopyExisting,
  validationErrorsOnNew,
  validationErrorsOnOverwriteExisting,
} = slice.actions;
export const reducer = slice.reducer;
export type State = FormsDesignerReduxCreateTemplatesState;
