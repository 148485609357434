/** @jsxImportSource @emotion/react */
import {
  FormDesignerContent,
  IDesignerFactoryOptions,
} from '../FormDesignerContent/FormDesignerContent.tsx';
import { DROPDOWN_TYPES } from '../../common/dropdowns.ts';
import { FormLineById } from '@seeeverything/ui.forms/src/redux/form-instance/types.ts';
import { FormLineByIdExtended } from '../FormDesignerContent/types.ts';

export interface IFormDesignerProps {
  lines?: FormLineById;
  designerLines?: FormLineByIdExtended;
  designerOptions?: IDesignerFactoryOptions;
  isDesigning?: boolean;
}

/**
 * A WYSIWYG designer component for Form Templates.
 */
export const FormDesigner = (props: IFormDesignerProps) => {
  const designerOptions = {
    ...props.designerOptions,
    canDelete: true,
    showBoundaryLines: true,
    dropdownTypes: DROPDOWN_TYPES,
  };

  return (
    <FormDesignerContent
      lines={props.lines}
      designerLines={props.designerLines}
      isDesigning={props.isDesigning}
      designerOptions={designerOptions}
    />
  );
};
