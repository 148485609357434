/** @jsxImportSource @emotion/react */
import {
  DataGrid,
  DataGridClickThroughBehavior,
  DataGridInfiniteScrollBehavior,
  DataGridIsLoadingBehavior,
  IDataGridClickThroughProps,
  IDataGridColumn,
  IDataGridInfiniteScrollBehaviorProps,
  IDataGridProps,
} from '@seeeverything/ui.primitives/src/components/DataGrid/index.ts';
import { IGridRow } from '@seeeverything/ui.primitives/src/components/Grid/types.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { pipe } from 'ramda';
import { useCallback, useMemo } from 'react';

import { useMomentTenantTimezone } from '@seeeverything/ui.primitives/src/hooks/useDateContext.ts';
import { formBulkUploadClickThroughSlice } from '../../redux/formBulkUploadClickThrough/index.ts';
import { formBulkUploadJobHistorySlice } from '../../redux/formBulkUploadJobHistory/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../redux/store.ts';
import { DataGridColumnFilter } from '../../types/types.ts';
import {
  DataGridFilteringBehavior,
  IDataGridFilteringBehaviorProps,
} from '../DataGridFiltering/DataGridFilteringBehavior.tsx';
import { BulkUploadColumns } from './types.ts';

const Grid = pipe(
  DataGridInfiniteScrollBehavior,
  DataGridClickThroughBehavior,
  DataGridIsLoadingBehavior,
  DataGridFilteringBehavior,
)(DataGrid) as React.FC<
  IDataGridProps &
    IDataGridClickThroughProps &
    IDataGridInfiniteScrollBehaviorProps &
    IDataGridFilteringBehaviorProps
>;

/**
 * A table of Bulk Upload Jobs.
 */
export const BulkUploadJobsGrid: React.FC = () => {
  const dispatch = useFormsDispatch();

  const momentTz = useMomentTenantTimezone();

  const jobs = useFormsSelector((state) => state.formBulkUploadJobHistory.jobs);

  const isLoading = useFormsSelector(
    (state) => state.formBulkUploadJobHistory.isLoading,
  );

  const isLoadingMore = useFormsSelector(
    (state) => state.formBulkUploadJobHistory.isLoadingMore,
  );

  const hasNextPage = useFormsSelector(
    (state) => state.formBulkUploadJobHistory.hasNextPage,
  );

  const loadedPage = useFormsSelector(
    (state) => state.formBulkUploadJobHistory.currentPage,
  );

  const orderBys = useFormsSelector(
    (state) => state.formBulkUploadJobHistory.orderBys,
  );

  const columnOrderBys = useMemo(
    () => ({
      [orderBys[0].fieldName]: orderBys[0].direction,
    }),
    [orderBys],
  );

  const handleClickThroughClicked = useCallback(
    (rowId: string, columnId: BulkUploadColumns) => {
      if (columnId === BulkUploadColumns.FAILED)
        dispatch(
          formBulkUploadClickThroughSlice.loadFailedClickThrough({
            jobId: rowId,
          }),
        );
      if (columnId === BulkUploadColumns.SUCCEEDED)
        dispatch(
          formBulkUploadClickThroughSlice.loadSucceededClickThrough({
            jobId: rowId,
          }),
        );
    },
    [dispatch],
  );

  const handleLoadMore = useCallback(
    () =>
      dispatch(formBulkUploadJobHistorySlice.loadJobs({ loadNextPage: true })),
    [dispatch],
  );

  const handleColumnSort = useCallback(
    (
      gridId: string,
      columnId: string,
      direction: 'Ascending' | 'Descending',
    ) => {
      if (gridId !== 'BulkUploadJobs') return;
      dispatch(
        formBulkUploadJobHistorySlice.sort({
          fieldName: columnId,
          direction,
        }),
      );
    },
    [dispatch],
  );

  const filters = useFormsSelector(
    (state) => state.formBulkUploadJobHistory.filters,
  );

  const columnFilters = useMemo(
    () => ({
      [BulkUploadColumns.UPLOADED_BY]: filters.createdBy,
      [BulkUploadColumns.TEMPLATE_NAMES]: filters.formTemplate,
    }),
    [filters],
  );

  const handleColumnFilterSelection = useCallback(
    (
      gridId: string,
      columnId: string,
      filter: DataGridColumnFilter,
      to: boolean,
    ) => {
      if (gridId !== 'BulkUploadJobs') return;
      if (
        columnId === BulkUploadColumns.UPLOADED_BY &&
        filter.type === 'PERSON'
      ) {
        dispatch(
          formBulkUploadJobHistorySlice.toggleFilterCreatedBy({
            filter,
            to,
          }),
        );
      }

      if (
        columnId === BulkUploadColumns.TEMPLATE_NAMES &&
        filter.type === 'FORM_TEMPLATE'
      ) {
        dispatch(
          formBulkUploadJobHistorySlice.toggleFilterFormTemplate({
            filter,
            to,
          }),
        );
      }
    },
    [dispatch],
  );

  const handleColumnFilterClearAll = useCallback(
    (gridId: string, columnId: string) => {
      if (gridId !== 'BulkUploadJobs') return;
      if (columnId === BulkUploadColumns.UPLOADED_BY) {
        dispatch(
          formBulkUploadJobHistorySlice.clearAllColumnFiltersCreatedBy(),
        );
      }

      if (columnId === BulkUploadColumns.TEMPLATE_NAMES) {
        dispatch(
          formBulkUploadJobHistorySlice.clearAllColumnFiltersFormTemplate(),
        );
      }
    },
    [dispatch],
  );

  const formLabel = useFormsSelector(
    (state) => state.tenantState.tenant.locale.label.form,
  );

  const data = useMemo<IGridRow[]>(
    () =>
      jobs.map((job) => ({
        id: job.id,
        data: [
          momentTz(job.uploadedAt).format('D MMM YY, h:mm a').toString(),
          job.templateNames,
          job.uploadedBy.name,
          str.humanize(job.jobStatus, true),
          job.succeeded,
          job.failed,
        ],
        canClickThrough: job.jobStatus !== 'Processing',
      })),
    [jobs, momentTz],
  );

  return (
    <Grid
      id={'BulkUploadJobs'}
      clickThroughColumnIds={CLICK_THROUGH_COLUMNS}
      onClickThroughColumnClick={handleClickThroughClicked}
      isLoading={isLoading}
      isScrollable={true}
      isVirtualized={true}
      columns={createColumns(formLabel)}
      data={data}
      onLoadPage={handleLoadMore}
      isLoadingPage={isLoadingMore}
      loadedPage={loadedPage}
      loadMoreText={'Load more'}
      hasNextPage={hasNextPage}
      columnSelectedOrderBys={columnOrderBys}
      columnSelectedFilters={columnFilters}
      onColumnSort={handleColumnSort}
      onColumnFilterSelection={handleColumnFilterSelection}
      onColumnFilterClearAll={handleColumnFilterClearAll}
    />
  );
};

const CLICK_THROUGH_COLUMNS = [
  BulkUploadColumns.SUCCEEDED,
  BulkUploadColumns.FAILED,
];
const createColumns = (formLabel: string): IDataGridColumn[] => [
  {
    id: BulkUploadColumns.UPLOADED_AT,
    label: 'Uploaded Date',
    width: '2*',
    borderRight: true,
    isSortable: true,
  },
  {
    id: BulkUploadColumns.TEMPLATE_NAMES,
    label: `${formLabel} Types`,
    width: '5*',
    borderRight: true,
    isSortable: false,
    isFilterable: true,
    type: 'FORM_TEMPLATE',
  },
  {
    id: BulkUploadColumns.UPLOADED_BY,
    label: 'Uploaded By',
    width: '3*',
    borderRight: true,
    isSortable: true,
    isFilterable: true,
    type: 'PEOPLE',
  },
  {
    id: BulkUploadColumns.JOB_STATUS,
    label: 'Status',
    width: '2*',
    borderRight: true,
    isSortable: false,
  },
  {
    id: BulkUploadColumns.SUCCEEDED,
    label: 'Created',
    align: 'center',
    width: '2*',
    borderRight: true,
    isSortable: false,
  },
  {
    id: BulkUploadColumns.FAILED,
    label: 'Failed',
    align: 'center',
    width: '2*',
    borderRight: true,
    isSortable: false,
  },
];
