import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/client/types.ts';
import {
  GlobalReduxEpicDependenciesType,
  ReduxAction,
} from '@seeeverything/ui.util/src/redux/types.ts';
import { exportToXlsx } from '@seeeverything/ui.util/src/xlsx/index.ts';
import moment from 'moment';
import { StateObservable } from 'redux-observable';
import { EMPTY, Observable, concatAll, filter, mergeMap } from 'rxjs';
import { utils } from 'xlsx';
import { PAGE_SIZE_FULL_GRID } from '../../common/constants.ts';
import { queryGrid } from '../../data/grid.ts';
import { toDateFilter, toTimespanVariable } from '../../data/index.ts';
import {
  addTrendAndDisplayValues,
  applyContentMapping,
} from '../../factory/renderers/dataFormatting.ts';
import { GridDataRow } from '../../types.ts';
import { GridQueryArguments } from '../dashboardGrids/dashboardGridsSlice.ts';
import { dashboardGridsSlice } from '../dashboardGrids/index.ts';
import { GlobalDashboardsState } from '../types.ts';

/**
 * Epic that downloads grid data and outputs an xlsx spreadsheet
 */
export function downloadGridDataEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalDashboardsState>,
  { client }: GlobalReduxEpicDependenciesType,
) {
  return action$.pipe(
    filter(dashboardGridsSlice.downloadToSpreadsheet.match),
    mergeMap(async (action) => {
      const gridId = action.payload.gridId;
      const isClickThroughGrid = Boolean(
        state$.value.dashboardsV2.CLICK_THROUGH_GRID[gridId],
      );

      const gridState = isClickThroughGrid
        ? state$.value.dashboardsV2.CLICK_THROUGH_GRID[gridId]
        : state$.value.dashboardsV2.GRID[gridId];

      const module = state$.value.tenantState.tenant.module;
      const template = state$.value.dashboardsV2.template;
      const gridKey = dashboardGridsSlice.utils.createGridKey({
        module,
        gridId,
        entityId: template.entityId,
        dataSetId: gridState.clickThrough?.dataSetId,
        rowId: gridState.clickThrough?.rowId,
        columnId: gridState.clickThrough?.columnId,
      });

      const dataSetId = gridState.dataState.dataSetId;

      const orderByState = state$.value.dashboardGrids.sort[gridKey];
      const columnFilters = state$.value.dashboardGrids.columnFilters[gridKey];

      const filterStatements = columnFilters
        ? Object.entries(columnFilters)
            .filter(
              ([, columnState]) => columnState.selectedFilters?.length > 0,
            )
            .map(([columnId, columnState]) => ({
              columnId,
              filters: columnState.selectedFilters.map(({ value }) => value),
            }))
        : [];

      const dateFilterState = toDateFilter(state$.value);

      const queryArgs: GridQueryArguments = {
        clickThrough: gridState.clickThrough,
        dashboardType: isClickThroughGrid ? 'CLICK_THROUGH_GRID' : 'GRID',
        dataSetId,
        entityId: template.entityId,
        entityType: template.kind,
        filterStatements,
        gridId,
        includeFooter: false,
        isQueryEnabled: true,
        module: state$.value.tenantState.tenant.module,
        orderBy: [orderByState.inactiveEntity, ...orderByState.orderBys]
          .filter(Boolean)
          .map((sort) => ({
            fieldName: sort.columnId,
            direction: sort.direction,
          })),
        pageSize: PAGE_SIZE_FULL_GRID,
        templateId: template.id,
        timespan: toTimespanVariable(dateFilterState),
        tempFormStatusExcludeCompleted:
          gridState.tempFormStatusExcludeCompleted,
      };

      const headerRow = gridState.columns
        .filter((header) => header.isHidden !== true)
        .map(({ label }) => label);

      const cellRows = await getCellRows(client, queryArgs);
      const cellDisplayValues = cellRows.map((row) =>
        gridState.columns
          .filter((column) => column.isHidden !== true)
          .map((column) => {
            const dataPoint = row.data.find(({ key }) => key === column.id);
            const comparisonPeriod = row.data.find(
              ({ key }) => key === `${column.id}.comparison-period`,
            );
            const formattedDataPoint = dataPoint
              ? addTrendAndDisplayValues(
                  dataPoint,
                  comparisonPeriod,
                  column,
                  row,
                  state$.value.tenantState.tenant.locale,
                )
              : { value: 'N/A', displayValue: 'N/A' };

            return applyContentMapping(formattedDataPoint, column).displayValue;
          }),
      );

      // Better performance with larger data sets.
      cellDisplayValues.unshift(headerRow);

      const title = gridState.name;

      exportToXlsx({
        exportFileName: `export-${title}.xlsx`,
        title,
        sheetName:
          title.length >= 30
            ? `Seeeverything-${moment().format('D MMM YY')}.xlsx`
            : title.replace(/[\\/,*:?]/g, () => ''),
        worksheetData: getWorkSheetFromData(cellDisplayValues),
      });

      return EMPTY;
    }),
    concatAll(),
  );
}

const getCellRows = async (
  client: IGraphQLClient,
  queryArgs: GridQueryArguments,
) => {
  let hasNextPage = true;
  let pageNumber = 1;
  const allData: GridDataRow[] = [];

  while (hasNextPage) {
    const { pageSize, ...rest } = queryArgs;

    const responsePage = await queryGrid(client, {
      pagination: { pageNumber, pageSize },
      ...rest,
    });

    hasNextPage = Boolean(responsePage.pagination.hasNextPage);
    pageNumber = responsePage.pagination?.currentPage + 1;
    allData.push(...responsePage.data);
  }

  return allData;
};

const EXCEL_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

const fromIndex = (index: number) => {
  if (index < 0) return '';

  let tempIndex = index;
  let result = '';

  if (tempIndex >= 26) {
    const nextDigit = (tempIndex - (tempIndex % 26)) / 26 - 1;
    if (nextDigit >= 0) {
      result = fromIndex(nextDigit);
    }
    tempIndex %= 26;
  }

  return result + EXCEL_CHARS[tempIndex];
};

const getWorkSheetFromData = (cellDisplayValues: string[][]) => {
  const percentageCells: string[] = [];

  const withNumberConversions = cellDisplayValues.map((row, rowIndex) =>
    row.map((columnCell, columnIndex) => {
      if (isPercentage(columnCell)) {
        percentageCells.push(`${fromIndex(columnIndex)}${rowIndex + 1}`);
        return convertPercentageToNumber(columnCell);
      }

      const asNumber = columnCell && Number(columnCell);
      return isNaN(asNumber) ? columnCell : asNumber;
    }),
  );

  const workSheet = utils.aoa_to_sheet(withNumberConversions);
  percentageCells.forEach((cell) => (workSheet[cell].z = '0.0%'));

  return workSheet;
};

const isPercentage = (input: string) => {
  if (!input.endsWith('%')) return false;

  const number = input.substring(0, input.length - 1);
  return !isNaN(Number(number));
};

const convertPercentageToNumber = (input: string) => {
  const number = input.substring(0, input.length - 1);
  return Number(number) / 100;
};
