/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryFormTemplateCategories } from '@se/data/forms/hooks/useQueryFormTemplateCategories.ts';
import { MessageBar } from '@seeeverything/ui.primitives/src/components/MessageBar/MessageBar.tsx';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { Spinner } from '@seeeverything/ui.primitives/src/components/Spinner/index.ts';
import { TabStrip } from '@seeeverything/ui.primitives/src/components/TabStrip/TabStrip.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TitledDialog } from '@seeeverything/ui.primitives/src/components/TitledDialog/TitledDialog.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  formsDesignerDropdownsSlice,
  formsDesignerPublishTemplatesSlice,
} from '../../redux/index.ts';
import {
  useFormsDesignerDispatch,
  useFormsDesignerSelector,
} from '../../redux/store.ts';
import { NewTemplate } from './components/NewTemplate.tsx';
import { OverwriteExisting } from './components/OverwriteExisting.tsx';
import { VISIBILITY_SELECTIONS } from './constants.ts';

const TABS = [
  { id: 'NEW', label: 'New Template' },
  { id: 'OVERWRITE_EXISTING', label: 'Overwrite Existing' },
];

export interface IPublishDraftDialogContainerProps {
  hasVisibilities: boolean;
}

export const PublishDraftDialogContainer: React.FC<
  IPublishDraftDialogContainerProps
> = ({ hasVisibilities }) => {
  const { categorySelections, categoriesLoading } =
    useQueryFormTemplateCategories();

  const isUnmounted = useRef(false);
  const dispatch = useFormsDesignerDispatch();

  useEffect(
    () => () => {
      isUnmounted.current = true;
    },
    [],
  );

  useEffect(() => {
    dispatch(formsDesignerDropdownsSlice.loadDesignerTemplatesPage());
  }, [dispatch]);

  const selectedMethod = useFormsDesignerSelector(
    (state) => state.formsDesignerPublishTemplates.selectedMethod,
  );

  const newTemplateVisibility = useFormsDesignerSelector(
    (state) => state.formsDesignerPublishTemplates.createNewInputs.visibility,
  );

  const newTemplateVisibilityErrorText = useFormsDesignerSelector(
    (state) =>
      state.formsDesignerPublishTemplates.createNewInputs.validationErrors
        .visibility,
  );

  const newTemplateName = useFormsDesignerSelector(
    (state) => state.formsDesignerPublishTemplates.createNewInputs.name,
  );

  const newTemplateNameErrorText = useFormsDesignerSelector(
    (state) =>
      state.formsDesignerPublishTemplates.createNewInputs.validationErrors.name,
  );

  const newTemplateCategory = useFormsDesignerSelector(
    (state) => state.formsDesignerPublishTemplates.createNewInputs.category,
  );

  const newTemplateCategoryErrorText = useFormsDesignerSelector(
    (state) =>
      state.formsDesignerPublishTemplates.createNewInputs.validationErrors
        .category,
  );

  const overwriteExistingTemplate = useFormsDesignerSelector(
    (state) =>
      state.formsDesignerPublishTemplates.overwriteExistingInputs
        .existingTemplate,
  );

  const overwriteExistingTemplateErrorText = useFormsDesignerSelector(
    (state) =>
      state.formsDesignerPublishTemplates.overwriteExistingInputs
        .validationErrors.existingTemplate,
  );

  const isSpinning = useFormsDesignerSelector(
    (state) => state.formsDesignerPublishTemplates.isSpinning,
  );

  const generalError = useFormsDesignerSelector(
    (state) => state.formsDesignerPublishTemplates.generalError,
  );

  const templates = useFormsDesignerSelector(
    (state) => state.formsDesignerDropdowns.designerTemplates.items,
  );

  const templatesIsLoading = useFormsDesignerSelector(
    (state) => state.formsDesignerDropdowns.designerTemplates.isLoading,
  );

  const cancelImport = useCallback(
    () => dispatch(formsDesignerPublishTemplatesSlice.cancelPublish()),
    [dispatch],
  );

  const setSelectedMethod = useCallback(
    (to: 'NEW' | 'OVERWRITE_EXISTING') =>
      dispatch(formsDesignerPublishTemplatesSlice.setSelectedMethod(to)),
    [dispatch],
  );

  const updateNewTemplateCategory = useCallback(
    (to: ISelectionListItem) =>
      dispatch(formsDesignerPublishTemplatesSlice.updateCategoryFieldOnNew(to)),
    [dispatch],
  );

  const updateNewTemplateName = useCallback(
    (to: string) =>
      dispatch(formsDesignerPublishTemplatesSlice.updateNameFieldOnNew(to)),
    [dispatch],
  );

  const updateNewTemplateVisibility = useCallback(
    (to: ISelectionListItem) =>
      dispatch(
        formsDesignerPublishTemplatesSlice.updateVisibilityFieldOnNew(to),
      ),
    [dispatch],
  );

  const updateOverwriteExistingTemplate = useCallback(
    (to: ISelectionListItem) =>
      dispatch(
        formsDesignerPublishTemplatesSlice.updateExistingTemplateFieldOnOverwrite(
          to,
        ),
      ),
    [dispatch],
  );

  const publishTemplate = useCallback(() => {
    switch (selectedMethod) {
      case 'NEW': {
        const validationRequired = !newTemplateName || !newTemplateCategory;

        if (validationRequired) {
          dispatch(
            formsDesignerPublishTemplatesSlice.validationErrorsOnNew({
              category: !newTemplateCategory
                ? 'Please select a category.'
                : undefined,
              name: !newTemplateName ? 'Please enter a name.' : undefined,
            }),
          );
          return;
        }
        break;
      }

      case 'OVERWRITE_EXISTING': {
        if (!overwriteExistingTemplate) {
          dispatch(
            formsDesignerPublishTemplatesSlice.validationErrorsOnOverwriteExisting(
              {
                existingTemplate: !overwriteExistingTemplate
                  ? 'Please select an existing template to overwrite.'
                  : undefined,
              },
            ),
          );
          return;
        }
        break;
      }
    }

    dispatch(formsDesignerPublishTemplatesSlice.publishTemplate());
  }, [
    dispatch,
    newTemplateCategory,
    newTemplateName,
    overwriteExistingTemplate,
    selectedMethod,
  ]);

  const actions = useMemo(
    () => [
      {
        id: 'Cancel',
        label: 'Cancel',
        isEnabled: true,
        onAction: cancelImport,
      },
      { id: 'Publish', label: 'Publish', onAction: publishTemplate },
    ],
    [cancelImport, publishTemplate],
  );

  const elSpinnerOverlay = useMemo(
    () =>
      isSpinning ? (
        <div css={styles.spinnerOverlay}>
          <Spinner />
          <Text style={styles.overlayText}>{'Publishing draft...'}</Text>
        </div>
      ) : undefined,
    [isSpinning],
  );

  const elError = generalError ? (
    <MessageBar
      message={generalError}
      style={styles.messageBar}
      type={'Error'}
    />
  ) : undefined;

  const elTabs = (
    <div css={styles.tabsContainer}>
      <TabStrip
        tabs={TABS}
        selectedId={selectedMethod}
        onSelectionChanged={setSelectedMethod}
      />
    </div>
  );

  const elSelectedTab =
    selectedMethod === 'NEW' ? (
      <NewTemplate
        category={newTemplateCategory}
        categoryErrorText={newTemplateCategoryErrorText}
        categorySelections={categorySelections}
        categoriesIsLoading={categoriesLoading}
        hasVisibilities={hasVisibilities}
        name={newTemplateName}
        nameErrorText={newTemplateNameErrorText}
        onCategoryChange={updateNewTemplateCategory}
        onNameChange={updateNewTemplateName}
        onVisibilityChange={updateNewTemplateVisibility}
        visibility={newTemplateVisibility}
        visibilityErrorText={newTemplateVisibilityErrorText}
        visibilitySelections={VISIBILITY_SELECTIONS}
      />
    ) : (
      <OverwriteExisting
        selectedTemplate={overwriteExistingTemplate}
        selectedTemplateErrorText={overwriteExistingTemplateErrorText}
        onTemplateChange={updateOverwriteExistingTemplate}
        templates={templates}
        templatesIsLoading={templatesIsLoading}
      />
    );

  return (
    <div css={styles.base}>
      {elSpinnerOverlay}
      <TitledDialog
        title={'Publish Template'}
        actions={actions}
        style={styles.dialog}
      >
        <div css={styles.bodyFull}>
          {elTabs}
          {elError}
          {elSelectedTab}
        </div>
      </TitledDialog>
    </div>
  );
};

const styles = {
  base: css({
    position: 'relative',
    backgroundColor: '#ffffff',
    flex: '1 1 auto',
  }),
  dialog: css({
    height: 538,
  }),
  bodyFull: css({
    width: '100%',
  }),
  spinnerOverlay: css({
    position: 'absolute',
    inset: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: color.format(0.8),
    zIndex: 1,
  }),
  messageBar: css({
    marginLeft: 16,
  }),
  overlayText: css({
    paddingLeft: 8,
  }),
  tabsContainer: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    background: color.format(1),
    width: '100%',
    padding: '0 20px',
  }),
};
