/** @jsxImportSource @emotion/react */
import React from 'react';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { DatePicker } from '@seeeverything/ui.primitives/src/components/DatePicker/DatePicker.tsx';
import { css } from '@emotion/react';

export interface IDateLabelProps {
  label: string;
  value?: string;
  onChange?: (to: string) => void;
  minDate?: string;
  maxDate?: string;
  dateError?: string;
}
export const DateLabel: React.FC<IDateLabelProps> = ({
  label,
  value,
  onChange,
  minDate,
  maxDate,
  dateError,
}) => (
  <div css={styles.base}>
    <Text
      weight={FontWeight.medium}
      color={color.format(-0.6)}
      style={styles.label}
    >
      {label}
    </Text>
    <DatePicker
      id={`date-label-${label}`}
      label={''}
      value={value}
      onChange={onChange}
      minDate={minDate}
      maxDate={maxDate}
      error={dateError}
    />
  </div>
);

const styles = {
  base: css({
    display: 'flex',
    alignItems: 'baseline',
    padding: 8,
  }),
  label: css({ minWidth: 125 }),
};
