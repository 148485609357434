import { AppAction, AppState } from './types.ts';

const DEFAULT_STATE: AppState = {
  permissions: {
    scheduleManage: false,
    templateManage: false,
    instanceCreate: false,
    bulkFormLoadManage: false,
    automatedActionManage: false,
  },
  isInitialized: false,
};

export function reducer(
  state: AppState = DEFAULT_STATE,
  action: AppAction,
): AppState {
  switch (action.type) {
    case 'ui.shell/app/LOGGING_OUT':
      return {
        ...state,
        authState: 'LOGGING_OUT',
      };

    case 'ui.shell/app/ERROR_STATE':
      return {
        ...state,
        errorState: true,
      };

    case 'ui.shell/app/INITIALIZE_PERMISSIONS':
      return {
        ...state,
        permissions: action.payload.permissions,
      };

    case 'ui.shell/app/INITIALIZING':
      return {
        ...state,
        isInitialized: false,
      };

    case 'ui.shell/app/INITIALIZED':
      return {
        ...state,
        isInitialized: true,
      };

    default:
      return state;
  }
}
