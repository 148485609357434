/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import moment from 'moment';
import { DateLabel } from './components/DateLabel.tsx';
import { css } from '@emotion/react';

export interface IDateRangeSelectorProps {
  endDate?: string;
  maxAllowedDays?: number;
  onChange?: (id: 'START' | 'END', to: string) => void;
  showEndDateValidation?: boolean;
  showStartDateValidation?: boolean;
  startDate?: string;
}

export const DateRangeSelector: React.FC<IDateRangeSelectorProps> = ({
  endDate,
  maxAllowedDays,
  onChange,
  showEndDateValidation,
  showStartDateValidation,
  startDate,
}) => {
  const handleDateChange = useCallback(
    (id: 'START' | 'END') => (to: string) => onChange?.(id, to),
    [onChange],
  );
  return (
    <div css={styles.base}>
      <DateLabel
        label={'Start Date:'}
        value={startDate}
        onChange={handleDateChange('START')}
        minDate={
          maxAllowedDays && endDate
            ? moment(endDate).subtract(maxAllowedDays, 'd').toISOString()
            : undefined
        }
        maxDate={endDate ? moment(endDate).toISOString() : undefined}
        dateError={showStartDateValidation ? 'This is required.' : undefined}
      />
      <DateLabel
        label={'End Date:'}
        value={endDate}
        onChange={handleDateChange('END')}
        minDate={startDate ? moment(startDate).toISOString() : undefined}
        maxDate={
          maxAllowedDays && startDate
            ? moment(startDate).add(maxAllowedDays, 'd').toISOString()
            : undefined
        }
        dateError={showEndDateValidation ? 'This is required.' : undefined}
      />
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%',
  }),
};
