/** @jsxImportSource @emotion/react */
import { makeStyles } from '@material-ui/core';
import { TreeItem as MaterialTreeItem } from '@material-ui/lab';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import React from 'react';
import { CommonStyles } from '../../../common/commonStyles.ts';

const useItemStyles = makeStyles({
  root: {
    '&$selected > $group': {
      // Changes the tree border for a group when it's parent item is selected.
      borderColor: COLORS.BLUE,
    },
    '&$selected > $content $label': {
      // Changes the label appearance when item selected.
      color: 'white',
      backgroundColor: `${COLORS.BLUE} !important`, // '!important' to override the style coming in from the theme here.
      padding: '2px 8px',
    },
    '&$selected > $content $label div': {
      // For when the label is a Text component
      color: 'white',
    },
    '&$selected > $content $iconContainer svg': {
      // Changes the icon color when item selected.
      fill: `${COLORS.BLUE} !important`,
    },
  },
  label: {
    borderRadius: 2,
    fontSize: 13,
    color: color.format(-0.8),
    backgroundColor: 'inherit',
    overflow: 'visible',
    whiteSpace: `nowrap`,
    ...CommonStyles.MaterialCubicTransitions,
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px solid ${color.format(-0.1)}`,
  },
  // Important to have these blank styles here to allow selector targetting in the root style.
  content: {},
  selected: {},
  iconContainer: {},
});

export interface ITreeItem {
  id: string;
  label: string | React.ReactNode;
  children?: ITreeItem[];
}

export interface ITreeItemProps {
  item: ITreeItem;
}

/**
 * Component that recursively creates itself for display in a <TreeView />.
 */
export const TreeItem: React.FC<ITreeItemProps> = (props) => {
  const classStyles = useItemStyles();
  const { item } = props;
  return (
    <MaterialTreeItem
      nodeId={item.id}
      label={item.label}
      classes={{
        root: classStyles.root,
        content: classStyles.content,
        iconContainer: classStyles.iconContainer,
        selected: classStyles.selected,
        label: classStyles.label,
        group: classStyles.group,
      }}
    >
      {item.children?.map((child) => (
        <TreeItem key={`tree-item-${child.id}`} item={child} />
      ))}
    </MaterialTreeItem>
  );
};
