/** @jsxImportSource @emotion/react */
import React from 'react';
import * as R from 'ramda';
import { css } from '@emotion/react';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TooltipItem } from './components/TooltipItem.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { ICustomTooltipProps } from './types.ts';

/**
 * Provides a custom `Tooltip` for use within Recharts.
 */
export const CustomTooltip: React.FC<ICustomTooltipProps> = ({
  active,
  payload,
  staticHeader,
  payloadHeaderKey,
  valueFormatter,
  shouldReverseItems = false,
  shouldSortAlphabetically = false,
  icons = 'group',
  inactiveKeys = [],
}) => {
  if (!active) {
    return null;
  }

  let items: any[] = payload.filter(
    (item: any) => item.name && item.value !== undefined,
  );

  if (shouldReverseItems) {
    items = R.reverse(items);
  }

  if (shouldSortAlphabetically) {
    items = R.sort((a, b) => a.name.localeCompare(b.name), items);
  }

  const headerText =
    R.isNil(staticHeader) || R.isEmpty(staticHeader)
      ? getPayloadHeaderValue(payload, payloadHeaderKey)
      : staticHeader;

  if (!headerText && !items.length) {
    return null;
  }

  const elItems = items.map((item, index) => (
    <TooltipItem
      key={`tooltip-item-${item.dataKey ?? index}`}
      item={item}
      name={item.name}
      icons={icons}
      value={
        valueFormatter ? valueFormatter(item, itemValue(item)) : itemValue(item)
      }
      color={item.stroke}
      isActive={!inactiveKeys.includes(item.dataKey)}
    />
  ));

  const elHeader = headerText && (
    <div css={styles.header}>
      <Text color={color.format(-0.8)} selectable={false}>
        {headerText}
      </Text>
    </div>
  );

  return (
    <div css={styles.base}>
      {elHeader}
      {elItems}
    </div>
  );
};

const styles = {
  base: css({
    border: 'solid 1px grey',
    borderRadius: 3,
    backgroundColor: color.format(0.95),
  }),
  header: css({
    padding: 5,
  }),
};

const itemValue = (item: any) => {
  const dataKey = item.dataKey;

  if (!dataKey) {
    return item.value;
  }

  const originalValue = item.payload?.[`${dataKey}-original`];
  return originalValue || item.value;
};

function getPayloadHeaderValue(payload: any, key?: string) {
  const firstPayload =
    (payload && payload[0] && payload[0].payload) || undefined;

  return (firstPayload && key && firstPayload[key]) || undefined;
}
