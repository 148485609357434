/** @jsxImportSource @emotion/react */
import { BulkUploadJobsGrid } from '@seeeverything/ui.forms/src/components/BulkUploadJobsGrid/BulkUploadJobsGrid.tsx';
import { formBulkUploadJobHistorySlice } from '@seeeverything/ui.forms/src/redux/formBulkUploadJobHistory/index.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { useTenantLocale } from '@seeeverything/ui.primitives/src/hooks/useTenantLocale.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { useCallback, useMemo } from 'react';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';
import { SheetLayout } from '../SheetLayout/SheetLayout.tsx';

export const SheetFormsBulkUploadHistoryContainer: React.FC = () => {
  const dispatch = useShellDispatch();
  const formLabel = useTenantLocale((l) => l.label.form);

  const title = useMemo(() => `${formLabel} Loader`, [formLabel]);

  const hasClearAllFilters = useShellSelector((state) => {
    const columnFiltersState = state.formBulkUploadJobHistory.filters;
    if (!columnFiltersState) return false;

    return Object.values(columnFiltersState).some(
      (columnState) => columnState.length > 0,
    );
  });

  const clearAllColumnFilters = useCallback(() => {
    dispatch(formBulkUploadJobHistorySlice.clearAllColumnFilters());
  }, [dispatch]);

  return (
    <SheetLayout
      title={title}
      leftTools={[
        {
          id: 'DOWNLOAD_FORM_TEMPLATE',
          icon: Icons.fileDownload,
          label: 'Download Template',
        },
        {
          id: 'IMPORT_BULK_UPLOAD_FILE',
          icon: Icons.publish,
          label: `Upload ${str.plural(formLabel)}`,
        },
      ]}
      rightTools={[
        hasClearAllFilters && {
          id: 'CLEAR_FILTERS',
          icon: Icons.filterListOff,
          onClick: clearAllColumnFilters,
          tooltip: 'Clears all column filters, showing all data.',
        },
        {
          id: 'IMPORT_BULK_UPLOAD_REFRESH',
          icon: Icons.refresh,
          label: 'Refresh List',
        },
      ]}
    >
      <BulkUploadJobsGrid />
    </SheetLayout>
  );
};
