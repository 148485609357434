/** @jsxImportSource @emotion/react */
import React from 'react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { css } from '@emotion/react';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import { CsvDateRangeType } from './types.ts';
import {
  ITreeItem,
  TreeView,
} from '@seeeverything/ui.primitives/src/components/TreeView/index.ts';
import { DatePicker } from '@seeeverything/ui.primitives/src/components/DatePicker/DatePicker.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';

export interface ICsvDialogProps {
  startDate?: string;
  endDate?: string;
  selectedFilters?: string[];
  filters?: ITreeItem[];
  areFiltersLoading?: boolean;
  onFilterSelected?: (selectedFilters?: string[] | string) => void;
  onDateChange?: (dateType: CsvDateRangeType, to: string) => void;
}

/**
 * Dialog content for specifying filters against a CSV export.
 */
export const CsvDialog: React.FC<ICsvDialogProps> = (props) => {
  const {
    selectedFilters,
    filters,
    startDate,
    endDate,
    onFilterSelected,
    onDateChange,
    areFiltersLoading,
  } = props;

  const handleDateChange = (dateType: CsvDateRangeType) => (to: string) =>
    onDateChange?.(dateType, to);

  const elStartDatePicker = (
    <DatePicker
      id={'start-date'}
      label={'Start Date'}
      value={startDate}
      onChange={handleDateChange('START')}
      maxDate={endDate}
    />
  );

  const elEndDatePicker = (
    <DatePicker
      id={'end-date'}
      label={'End Date'}
      value={endDate}
      minDate={startDate}
      onChange={handleDateChange('END')}
    />
  );

  const elFilterTree = filters && (
    <>
      <Text color={color.format(-0.8)} size={15}>
        {'Categories/Templates:'}
      </Text>
      <TreeView
        isLoading={areFiltersLoading}
        onItemSelect={onFilterSelected}
        selected={selectedFilters}
        items={filters}
        isMultiSelect={true}
      />
      <Text
        color={color.format(-0.6)}
        weight={FontWeight.thin}
        size={13}
        italic={true}
      >
        {'Use control-click to select multiple items.'}
      </Text>
    </>
  );

  return (
    <div css={styles.base}>
      <div css={styles.header}>
        <div css={styles.title}>
          <Text size={48} color={color.format(-0.2)} weight={FontWeight.light}>
            {'Download to CSV'}
          </Text>
        </div>
      </div>
      <div css={styles.body}>
        <div css={styles.dateRow}>
          <div css={styles.dateLeft}>{elStartDatePicker}</div>
          <div css={styles.dateRight}>{elEndDatePicker}</div>
        </div>
        {elFilterTree}
      </div>
    </div>
  );
};

const styles = {
  base: css({
    overflow: 'hidden',
    width: '100%',
    flex: '1 1 auto',
  }),
  header: css({
    height: 96,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'stretch',
  }),
  title: css({
    flex: '1 1 auto',
    margin: '20px 0 0 34px',
  }),
  body: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    padding: '0 33px 33px 33px',
  }),
  dateRow: css({
    display: 'flex',
    marginBottom: 30,
  }),
  dateLeft: css({
    width: '45%',
    marginRight: '10%',
  }),
  dateRight: css({
    width: '45%',
  }),
};
