import moment from 'moment';
import { is } from 'ramda';
import { toPercentageString } from '@seeeverything/ui.util/src/value/value.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';

/* Data Formatting */
export const formatPercentageString = (fraction: number, precision?: number) =>
  is(Number, fraction) ? toPercentageString(fraction, precision) : '-';

export function formatDateString(
  date: string,
  withTime = true,
  defaultValue?: string,
) {
  const format = withTime ? 'D MMM YY, h:mm a' : 'D MMM YY';
  return date ? moment(date).format(format) : defaultValue;
}

export function formatTimeString(date: string, defaultValue?: string) {
  return date ? moment(date).format('h:mm a') : defaultValue;
}

// TODO Better localize of the 'Cancelled' form status.
export function formatCancelledStatus(status?: string) {
  return status === 'Cancelled' ? 'Canceled' : status;
}

export function formatEnumString(value?: string) {
  return value ? str.humanize(value, true) : undefined;
}
