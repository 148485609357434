/** @jsxImportSource @emotion/react */
import React from 'react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { css } from '@emotion/react';

const Bullet = () => (
  <div css={styles.base}>
    <div css={styles.inner} />
  </div>
);

const styles = {
  base: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: -28,
  }),
  inner: css({
    width: 7,
    height: 7,
    border: `solid 1px ${color.format(-0.2)}`,
    borderRadius: 5,
    backgroundColor: 'white',
  }),
};

export default React.memo(Bullet);
