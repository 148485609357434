/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { TabStrip } from '@seeeverything/ui.primitives/src/components/TabStrip/TabStrip.tsx';
import { useMemo, useState } from 'react';
import { useFormsSelector } from '../../../redux/store.ts';
import { ActionAuditContainer } from '../../ActionAudit/ActionAuditContainer.tsx';
import { ActionInstanceDetailsContainer } from './ActionInstanceDetailsContainer.tsx';
import { ActionIssueReviewDetailsContainer } from './ActionIssueReviewDetailsContainer.tsx';

export const ActionTabStripContainer: React.FC = () => {
  const formLabel = useFormsSelector(
    (state) => state.tenantState.tenant.locale.label.form,
  );

  const module = useFormsSelector((state) => state.tenantState.tenant.module);

  const hasInstanceDetails = useFormsSelector((state) => {
    const instance = Boolean(state.formActionV2.formInstance);
    if (!instance) return false;

    const tenantModule = state.tenantState.tenant.module;
    const issue = Boolean(state.formActionV2.issue);
    if (issue && tenantModule === 'compliance') return false;

    return true;
  });

  const hasIssueReviewDetails = useFormsSelector((state) =>
    Boolean(state.formActionV2.issue),
  );

  const tabs = useMemo(
    () =>
      [
        hasInstanceDetails && {
          id: 'instanceDetails',
          label: `${formLabel} details`,
          isEnabled: true,
        },
        hasIssueReviewDetails && {
          id: 'issueReviewDetails',
          label:
            module === 'compliance' ? `${formLabel} details` : `Issue details`,
          isEnabled: true,
        },
        {
          id: 'actionAudit',
          label: 'History',
          isEnabled: true,
        },
      ].filter(Boolean),
    [formLabel, hasInstanceDetails, hasIssueReviewDetails, module],
  );

  const [selectedTabId, setSelectedTabId] = useState(tabs[0].id);

  return (
    Boolean(tabs.length) && (
      <div css={styles.base}>
        <TabStrip
          selectedId={selectedTabId}
          onSelectionChanged={setSelectedTabId}
          tabs={tabs}
        />
        {selectedTabId === 'instanceDetails' && (
          <ActionInstanceDetailsContainer />
        )}
        {selectedTabId === 'issueReviewDetails' && (
          <ActionIssueReviewDetailsContainer />
        )}
        {selectedTabId === 'actionAudit' && <ActionAuditContainer />}
      </div>
    )
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  }),
};
