/** @jsxImportSource @emotion/react */
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useCallback, useMemo } from 'react';
import { DataGridToggle } from '../DataGrid/types.ts';
import { DropdownList } from '../DropdownList/DropdownList.tsx';
import { Icons } from '../Icon/Icons.tsx';
import {
  ISelectionListItem,
  SelectionListClickEvent,
} from '../SelectionList/types.ts';

const STATUS_ICON_COLOR = {
  default: COLORS.GREEN_TICK,
  selected: COLORS.WHITE,
};

const SORT_DIVIDER = {
  id: 'SORT_DIVIDER',
  type: 'DIVIDER',
} as const;

export interface IDataGridColumnFilterProps {
  isSortAllowed: boolean;
  isToggleAllowed: boolean;
  onSortChanged?: (to: 'ASC' | 'DESC') => void;
  onToggleChanged?: (toggleId: string, to: boolean) => void;
  sort?: 'ASC' | 'DESC';
  title?: string;
  toggles?: DataGridToggle[];
}

/**
 * Displays a list of filter and sort options for a grid column.
 */
export const DataGridColumnFilter: React.FC<IDataGridColumnFilterProps> = ({
  isSortAllowed,
  isToggleAllowed,
  onSortChanged,
  onToggleChanged,
  sort,
  title = 'Untitled',
  toggles = [],
}) => {
  const handleItemClick = useCallback(
    (e: SelectionListClickEvent) => {
      if (e.id === 'SORT_ASC') onSortChanged?.('ASC');
      if (e.id === 'SORT_DESC') onSortChanged?.('DESC');

      const toggle = toggles.find((t) => t.id === e.id);
      if (toggle) onToggleChanged?.(toggle.id, !toggle.isToggled);
    },
    [onSortChanged, onToggleChanged, toggles],
  );

  const items = useMemo((): ISelectionListItem[] => {
    const sortItems = isSortAllowed
      ? [
          {
            id: 'SORT_ASC',
            icon: Icons.sortAscending,
            content: `Sort ascending (A-Z)`,
            statusIcon: sort === 'ASC' ? Icons.tickDone : undefined,
            statusIconColor: STATUS_ICON_COLOR,
          },
          {
            id: 'SORT_DESC',
            icon: Icons.sortDescending,
            content: `Sort descending (Z-A)`,
            statusIcon: sort === 'DESC' ? Icons.tickDone : undefined,
            statusIconColor: STATUS_ICON_COLOR,
          },
          SORT_DIVIDER,
        ]
      : [];

    const toggleItems =
      isToggleAllowed && toggles.length
        ? [
            ...toggles.map((t) => ({
              id: t.id,
              icon: Icons.filter,
              content: t.label,
              statusIcon: t.isToggled ? Icons.tickDone : undefined,
              statusIconColor: STATUS_ICON_COLOR,
            })),
            SORT_DIVIDER,
          ]
        : [];

    const allItems: ISelectionListItem[] = [
      { id: 'title', type: 'SECTION', content: title },
      ...sortItems,
      ...toggleItems,
    ];

    // If last item is a divider, remove it.
    if (allItems[allItems.length - 1].type === 'DIVIDER') {
      allItems.pop();
    }

    return allItems;
  }, [isSortAllowed, isToggleAllowed, sort, title, toggles]);

  return (
    <DropdownList
      items={items}
      minWidth={300}
      width={300}
      onClick={handleItemClick}
    />
  );
};
