import { IQueryHandler } from '@seeeverything/ui.shell/src/api/api.queryBuilder/types.ts';

export const isEditingBusinessUnit = ({ req }: IQueryHandler) =>
  Boolean(!req.isHandled && req.selection?.chip?.type === 'bu');

export const isEmpty = ({ req }: IQueryHandler) =>
  Boolean(
    (!req.isHandled && !req.query) ||
      !req.query.chips ||
      req.query.chips.length === 0,
  );

export const isEditingBusinessEntity = ({ req }: IQueryHandler) =>
  Boolean(!req.isHandled && !req.selection && req.query.chips.length > 0);

export const isEditingNonModule = ({ req }: IQueryHandler) =>
  Boolean(!req.isHandled && !req.selection && req.query.chips.length === 0);

export const isUnhandledButNotEmpty = ({ req }: IQueryHandler) =>
  Boolean(!req.isHandled && req.context);
