import { FormLineById } from '@seeeverything/ui.forms/src/redux/form-instance/types.ts';
import { includes, pipe } from 'ramda';
import { findLineByInputId } from '../../data/util.ts';
import {
  FormDesignerReduxDesignerState,
  FormsDesignerIntegrationDeleteComponentFromTemplate,
} from './types.ts';

export function deleteComponentFromTemplateReducer(
  state: FormDesignerReduxDesignerState,
  action: FormsDesignerIntegrationDeleteComponentFromTemplate,
) {
  if (!state.draft || !state.draft.lines) {
    return state;
  }

  return {
    ...state,
    draft: {
      ...state.draft,
      lines: removeComponent(action.payload.id, state.draft.lines),
    },
  };
}

const removeSection =
  (id: string) =>
  (lines: FormLineById): FormLineById => {
    if (!lines[id]) {
      return lines;
    }

    const removed = { ...lines };
    delete removed[id];
    return removed;
  };

const removeTextLine =
  (id: string) =>
  (lines: FormLineById): FormLineById => {
    const textLine = findLineByInputId(lines, id);

    if (!textLine) {
      return lines;
    }

    const removed = { ...lines };
    delete removed[textLine.id];
    return removed;
  };

const removeOptions =
  (id: string) =>
  (lines: FormLineById): FormLineById =>
    Object.values(lines)
      .filter((line) => line.id !== id)
      .map((line) => ({ [line.id]: line }))
      .reduce((a, v) => ({ ...a, ...v }), {});

const removeSpeechBlock =
  (id: string) =>
  (lines: FormLineById): FormLineById =>
    Object.values(lines)
      .filter((line) => line.id !== id)
      .map((line) => ({ [line.id]: line }))
      .reduce((a, v) => ({ ...a, ...v }), {});

const STANDARD_HEADER_KEYS = [
  'header-section-title',
  'reportingDate',
  'dueDate',
  'assignedTo',
  'subject',
];

const removeComponent = (id: string, lines: FormLineById): FormLineById => {
  if (includes(id, STANDARD_HEADER_KEYS)) {
    // Cannot delete header components.
    return lines;
  }

  const remove = pipe(
    removeSection(id),
    removeTextLine(id),
    removeOptions(id),
    removeSpeechBlock(id),
  );

  return remove(lines);
};
