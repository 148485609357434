import { ChangePasswordInAppContainer } from '@seeeverything/ui.shell/src/components/ChangePasswordInApp/ChangePasswordInAppContainer.tsx';
import { AppShowChangePasswordDialogAction } from '@seeeverything/ui.shell/src/redux/app/types.ts';
import { showModalDialog } from '@seeeverything/ui.shell/src/redux/modalDialog/actions.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { combineEpics, ofType } from 'redux-observable';
import { Observable, map } from 'rxjs';
import { GlobalAppEpicDependencies, GlobalAppState } from '../../../types.ts';

export const epics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalAppState,
  GlobalAppEpicDependencies
>(showChangePasswordInAppDialog);

function showChangePasswordInAppDialog(
  action$: Observable<AppShowChangePasswordDialogAction>,
) {
  return action$.pipe(
    ofType('ui.shell/app/SHOW_CHANGE_PASSWORD_DIALOG'),
    map(() =>
      showModalDialog({
        content: <ChangePasswordInAppContainer />,
        width: 450,
      }),
    ),
  );
}
