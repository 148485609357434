import { QueryOptions } from '@apollo/client';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import gql from 'graphql-tag';
import * as R from 'ramda';
import { DashboardDateFilterSelection, IDataPayload } from '../types.ts';
import { NotFoundError } from './errors.ts';
import { metricsToDictionary } from './headerMetric.ts';
import { toTimespanVariable } from './util.ts';

export interface ITeamHeaderDetails {
  teamId: string;
  templateId: string;
  dateFilter?: DashboardDateFilterSelection;
  options?: QueryOptions;
}

export async function teamHeaderDetails(
  client: IGraphQLClient,
  options: ITeamHeaderDetails,
): Promise<IDataPayload> {
  const { teamId, templateId, dateFilter, options: gqlOptions } = options;

  const response = await client.query<any>({
    fetchPolicy: 'network-only', // Default to network-only.
    ...gqlOptions,
    query: GqlTeamDetails,
    variables: {
      teamId,
      templateId,
      timespan: toTimespanVariable(dateFilter),
    },
  });

  if (R.isNil(response.data.dashboards.team.header)) {
    throw new NotFoundError('team', teamId);
  }

  return {
    pagination: {
      hasNextPage: false,
      currentPage: 1,
      totalCount: 0,
      pageSize: 0,
    },
    data: {
      teamId: response.data.dashboards.team.header.id,
      name: response.data.dashboards.team.header.name,
      path: response.data.dashboards.team.header.path,
      team: {
        id: response.data.dashboards.team.header.id,
        path: response.data.dashboards.team.header.path,
      },
      description: `Team Members: ${response.data.dashboards.team.header.membershipCount}`,
      kind: 'team' as const,
      hasChildTeams: response.data.dashboards.team.header.hasChildTeams,
      metrics: metricsToDictionary(
        response.data.dashboards.team.header.metrics,
      ),
    },
  };
}

const GqlTeamDetails = gql`
  query DashboardTeamHeaderData(
    $teamId: ID!
    $templateId: ID!
    $timespan: DashboardTimespanFilterInput
  ) {
    dashboards {
      team(id: $teamId) {
        header(templateId: $templateId, timespan: $timespan) {
          id
          name
          path
          membershipCount
          hasChildTeams
          metrics {
            key
            value
          }
        }
      }
    }
  }
`;
