/** @jsxImportSource @emotion/react */
import { map, filter, Observable } from 'rxjs';
import { combineEpics } from 'redux-observable';
import {
  hideModalDialog,
  showModalDialog,
} from '@seeeverything/ui.shell/src/redux/modalDialog/actions.ts';
import { TextInputConfirmationDialogContainer } from '@seeeverything/ui.shell/src/components/TextInputConfirmationDialog/index.ts';
import {
  GlobalAppActionType,
  GlobalAppEpicDependencies,
  GlobalAppState,
} from '../../../types.ts';
import { getUserAppealPermissions } from '@seeeverything/ui.forms/src/util/util.instance.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { appealSlice } from '@seeeverything/ui.forms/src/redux/formInstanceAppeal/index.ts';

export const epics = combineEpics<
  GlobalAppActionType,
  GlobalAppActionType,
  GlobalAppState,
  GlobalAppEpicDependencies
>(handleValidateAppealSuccess);

/**
 * Handles post-validation of the form after a user-initiated appeal of a form.
 */
function handleValidateAppealSuccess(action$: Observable<ReduxAction>) {
  return action$.pipe(
    filter(appealSlice.confirmAppeal.match),
    map((action) => {
      const { instance, personId, signature } = action.payload;

      const okActions = (reason: string) => [
        hideModalDialog(),
        appealSlice.serverAppeal({
          instanceId: instance.id,
          signature,
          request: reason,
        }),
      ];

      const cancelActions = () => [hideModalDialog()];

      const modalDialogProps = {
        content: (
          <TextInputConfirmationDialogContainer
            title={'Appeal - Confirmation'}
            description={
              getUserAppealPermissions(instance, personId).canAppealAsManager
                ? 'This will request an appeal on the outcome. Are you sure you want to do this?'
                : 'This will request an appeal on the outcome. Ensure you have agreement from your manager in advance. Are you sure you want to do this?'
            }
            inputLabel={'Appeal Reason (Required)'}
            inputError={'You must specify a reason for appeal.'}
            okActions={okActions}
            cancelActions={cancelActions}
          />
        ),
        width: 700,
        height: 392,
      };

      return showModalDialog(modalDialogProps);
    }),
  );
}
