/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useTrackInViewport } from '@seeeverything/ui.primitives/src/hooks/useTrackInViewport.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { propsAreEqualFastCompare } from '@seeeverything/ui.util/src/react/memoFastCompare.ts';
import { memo, useEffect, useState } from 'react';
import { DASHBOARD_INTERSECTION_OPTIONS } from '../common/constants.ts';
import { CommentsList } from '../components/CommentsList/CommentsList.tsx';
import { loadDashboardComponent } from '../redux/actions.ts';
import {
  useDashboardsDispatch,
  useDashboardsSelector,
} from '../redux/index.ts';

export interface IFactoryCommentsListContainerProps {
  id: string;
  parentId?: string;
}

const View: React.FC<IFactoryCommentsListContainerProps> = ({
  id,
  parentId,
}) => {
  const dispatch = useDashboardsDispatch();
  const [hasLoaded, setHasLoaded] = useState(false);

  const [forwardedRef, inViewport] = useTrackInViewport({
    dataId: id,
    parentDataId: parentId,
    observerOptions: DASHBOARD_INTERSECTION_OPTIONS,
  });

  const commentsListState = useDashboardsSelector(
    (state) => state.dashboardsV2.COMMENTS_LIST[id],
  );

  const forceReload = commentsListState?.dataState.forceReload;

  useEffect(() => {
    if (forceReload) setHasLoaded(false);
  }, [forceReload]);

  useEffect(() => {
    if (hasLoaded) return;
    if (!inViewport) return;

    dispatch(
      loadDashboardComponent('COMMENTS_LIST', id, commentsListState?.orderBy),
    );
    setHasLoaded(true);
  }, [commentsListState?.orderBy, dispatch, hasLoaded, id, inViewport]);

  const dataState = commentsListState?.dataState;

  const isError = dataState?.error;
  const isLoading = Boolean(!dataState?.isLoaded || dataState?.isLoading);

  const [hasLoadedWhileInViewport, setHasLoadedWhileInViewport] =
    useState(false);

  useEffect(() => {
    if (hasLoadedWhileInViewport) return;
    if (!inViewport) return;
    if (isLoading) return;
    setHasLoadedWhileInViewport(true);
  }, [isLoading, hasLoadedWhileInViewport, inViewport]);

  return (
    <div
      data-id={id}
      parent-data-id={parentId}
      ref={forwardedRef}
      css={styles.base}
    >
      {isError && (
        <Text color={color.format(-0.5)}>
          {'There was a problem. Please try again.'}
        </Text>
      )}
      {!isError && (
        <CommentsList
          dashboardComponentId={id}
          canLoadMore={true}
          isLoading={isLoading || !hasLoadedWhileInViewport}
          type={'DASHBOARD_COMPONENT'}
        />
      )}
    </div>
  );
};

const styles = {
  base: css({
    margin: '3px 30px 30px 35px',
    paddingTop: 30,
    marginTop: 30,
  }),
};

export const FactoryCommentsListContainer = memo(
  View,
  propsAreEqualFastCompare,
);
