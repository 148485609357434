/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import { hideAlertDialog } from '../../redux/sheets/actions.ts';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';
import { AlertDialog } from './AlertDialog.tsx';

export const AlertDialogContainer: React.FC = () => {
  const dispatch = useShellDispatch();

  const alertDialogId = useShellSelector((state) => state.sheets.alertDialogId);
  const handleConfirmClick = useCallback(() => {
    dispatch(hideAlertDialog(alertDialogId, 'Confirm'));
  }, [alertDialogId, dispatch]);

  const handleCancelClick = useCallback(
    () => dispatch(hideAlertDialog(alertDialogId, 'Cancel')),
    [alertDialogId, dispatch],
  );

  return (
    <AlertDialog
      onConfirmClick={handleConfirmClick}
      onCancelClick={handleCancelClick}
      isVisible={Boolean(alertDialogId)}
      title={'Are you sure you want to continue?'}
      bodyText={'Your changes will not be saved.'}
    />
  );
};
