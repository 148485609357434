/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as R from 'ramda';
import React from 'react';
import isEqual from 'react-fast-compare';
import { LegendItem } from './components/LegendItem.tsx';
import { ICustomLegendProps } from './types.ts';

/**
 * Provides a custom `Legend` for use within Recharts.
 */
const CustomLegend: React.FC<ICustomLegendProps> = ({
  shouldReverseItems = false,
  inactiveKeys = [],
  payload = [],
  icons,
  onItemMouseLeave,
  onItemMouseEnter,
  onItemClicked,
}) => {
  let items = R.uniqWith(
    (first: any, second: any) => first.value === second.value,
    payload,
  );

  if (shouldReverseItems) {
    items = R.reverse(items);
  }

  const elItems = items.map((item) => (
    <LegendItem
      key={`legend-span-${item.dataKey}`}
      item={item}
      icons={icons}
      isActive={!inactiveKeys.includes(item.dataKey)}
      onClick={onItemClicked}
      onMouseEnter={onItemMouseEnter}
      onMouseLeave={onItemMouseLeave}
    />
  ));

  return <div css={styles.base}>{elItems}</div>;
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginLeft: 60,
  }),
};

/**
 * Recharts supplies mouse coordinate data in props, which causes
 * a lot of unnecessary renders.
 */
const shouldSkipRender = (
  prevProps: React.PropsWithChildren<ICustomLegendProps>,
  nextProps: React.PropsWithChildren<ICustomLegendProps>,
) =>
  prevProps.shouldReverseItems === nextProps.shouldReverseItems &&
  prevProps.icons === nextProps.icons &&
  prevProps.inactiveKeys === nextProps.inactiveKeys &&
  isEqual(prevProps.payload, nextProps.payload) &&
  prevProps.onItemMouseLeave === nextProps.onItemMouseLeave &&
  prevProps.onItemMouseEnter === nextProps.onItemMouseEnter &&
  prevProps.onItemClicked === nextProps.onItemClicked;

export default React.memo(CustomLegend, shouldSkipRender);
