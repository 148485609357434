import * as R from 'ramda';
import { findInputById, removeIds } from './util.ts';
import { FormLineById } from '@seeeverything/ui.forms/src/redux/form-instance/types.ts';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import {
  IActionPlanLine,
  IFileUploadLine,
  ISignoffLine,
  ISectionLine,
  IDateAnswerLine,
  IDropdownAnswerLine,
  ITextAnswerLine,
  IAttendanceLine,
  IDigitalContentLine,
} from '@seeeverything/ui.forms/src/types/types.ts';
import { FormLineByIdExtended } from '../components/FormDesignerContent/types.ts';
import { FormLineDesignerExtensions } from '../components/types.ts';
import { RadioOption } from '@seeeverything/ui.primitives/src/components/CheckboxRadioGroup/index.ts';
import { TenantModuleConfiguration } from '@seeeverything/ui.util/src/redux/tenant/types.ts';

/**
 * Fixed content (ids) on forms that cannot be removed/moved.
 */
const STATIC_CONTENT_IDS = [
  'header-section-title',
  'signoff',
  'actionPlan',
  'fileUpload',
  'digitalContent',
  'attendance',
];

/**
 * Fixed input fields (ids) on forms that cannot be removed/moved.
 */
const HEADER_INPUT_IDS = ['reportingDate', 'dueDate', 'subject', 'assignedTo'];

/**
 * Transforms form lines into designer lines.
 */
export const toDesignerLines = (
  lines: FormLineById,
  config: TenantModuleConfiguration['formTemplateDesigner'],
): FormLineById => {
  const headers = toHeaders(lines, config);
  const content = toContentLines(lines);
  const actionPlan = toActionPlan(lines);
  const fileUpload = toFileUpload(lines);
  const signoff = toSignoff(lines);
  const attendance = toAttendance(lines);
  const digitalContent = toDigitalContent(lines);

  return {
    ...headers,
    ...intersperseAddLines(content),
    ...digitalContent,
    ...fileUpload,
    ...actionPlan,
    ...attendance,
    ...signoff,
  };
};

const toActionPlan = (lines: FormLineByIdExtended): FormLineByIdExtended => {
  const actionPlanLine = lines.actionPlan as
    | (IActionPlanLine & FormLineDesignerExtensions)
    | undefined;
  if (!actionPlanLine) {
    return {};
  }

  return {
    actionPlan: {
      id: 'action-plan',
      type: 'actionPlan',
      showIndex: true,
      title: actionPlanLine.title ?? 'Action Plan',
      isEnabled: actionPlanLine.isEnabled,
      designerHintText: actionPlanLine.designerHintText,
      validationError: actionPlanLine.validationError,
    },
  };
};

const toFileUpload = (lines: FormLineById): FormLineByIdExtended => {
  const fileUploadLine = lines.fileUpload as
    | (IFileUploadLine & FormLineDesignerExtensions)
    | undefined;
  if (!fileUploadLine) {
    return {};
  }

  return {
    fileUpload: {
      id: 'file-upload',
      type: 'fileUpload',
      showIndex: true,
      title: fileUploadLine.title ?? 'Files',
      isEnabled: fileUploadLine.isEnabled,
      validationError: fileUploadLine.validationError,
    },
  };
};

const toSignoff = (lines: FormLineById): FormLineByIdExtended => {
  const signoffLine = lines.signoff as ISignoffLine | undefined;
  if (!signoffLine) return {};

  return {
    signoff: {
      id: 'signoff',
      type: 'signoff',
      showIndex: true,
      title: signoffLine.title,
      reviewerLabel: signoffLine.reviewerLabel,
      subject: {
        label: signoffLine.subjectLabel,
        isRequired: signoffLine.subjectRequired,
        canChangeIsSignoffRequired: true,
      },
    },
  };
};

/**
 * Builds the header structure (designer lines) from the supplied form lines.
 */
const toHeaders = (
  lines: FormLineById,
  config: TenantModuleConfiguration['formTemplateDesigner'],
): FormLineByIdExtended => {
  const headerLine = lines['header-section-title'] as
    | (ISectionLine & FormLineDesignerExtensions)
    | undefined;

  const reportingDate = findInputById(
    lines,
    'reportingDate',
  ) as IDateAnswerLine;
  const dueDate = findInputById(lines, 'dueDate') as IDateAnswerLine;
  const subject = findInputById(lines, 'subject') as IDropdownAnswerLine;
  const assignedTo = findInputById(lines, 'assignedTo') as ITextAnswerLine;

  if (!headerLine || !reportingDate || !dueDate || !subject || !assignedTo) {
    return {};
  }

  const { canPersonAsSubject = true, canTeamAsSubject = false } = config;

  return {
    headers: {
      type: 'standardHeaders',
      id: 'headers',
      sectionTitle: headerLine.title,
      reportingDateLabel: reportingDate.floatingText,
      dueDateLabel: dueDate.floatingText,
      subject: {
        label: subject.floatingText,
        kind: subject.dropdownListName?.toLowerCase(),
        canChangeKind: canTeamAsSubject && canPersonAsSubject,
      },
      reviewer: {
        label: assignedTo.floatingText,
        kind: 'people',
        canChangeKind: false,
      },
      validationError: headerLine.validationError,
    },
  };
};

/**
 * Strips header/footer lines and translates the remaining content lines into designer lines.
 */
const toContentLines = (lines: FormLineById): FormLineByIdExtended => {
  const contentLines = removeIds(lines, STATIC_CONTENT_IDS, HEADER_INPUT_IDS);

  return R.reject(
    R.isNil,
    Object.keys(contentLines).map((key) => {
      const line = contentLines[key];
      if (line.type === 'inputs') {
        const newValue = {
          type: 'textField',
          id: line.inputs?.[0].id,
          title: line.inputs?.[0].floatingText,
        };

        return { [key]: newValue };
      }

      if (line.type === 'section') {
        const newValue = {
          type: 'section',
          id: line.id,
          title: line.title,
        };

        return { [key]: newValue };
      }

      if (line.type === 'speechBlock') {
        const newValue = {
          type: 'speechBlock',
          id: line.id,
          title: line.text,
        };

        return { [key]: newValue };
      }

      if (line.type === 'question') {
        const newValue = {
          type: 'textBlock',
          id: line.id,
          title: line.label,
        };

        return { [key]: newValue };
      }

      if (line.type === 'switchAnswer') {
        const newValue = {
          type: 'switchAnswer',
          id: line.id,
          title: line.label,
        };

        return { [key]: newValue };
      }

      if (line.type === 'optionsAnswer') {
        const newValue = {
          type: 'options',
          id: line.id,
          optionItems: {
            title: line.question,
            options: line.options?.length
              ? (line.options as RadioOption[])
                  .map((option) => ({ id: option.id, label: option.label }))
                  .reduce((a, v) => ({ ...a, [v.id]: { ...v } }), {})
              : {},
            orientation: line.direction || 'vertical',
          },
        };

        return { [key]: newValue };
      }

      return undefined;
    }),
  ).reduce((a, v) => ({ ...a, ...v }), {});
};

const toAttendance = (lines: FormLineById): FormLineByIdExtended => {
  const attendanceLine = lines.attendance as
    | (IAttendanceLine & FormLineDesignerExtensions)
    | undefined;
  if (!attendanceLine) {
    return {};
  }

  return {
    attendance: {
      id: 'attendance',
      type: 'attendance',
      showIndex: true,
      title: attendanceLine.title ?? 'Attendance',
      isEnabled: attendanceLine.isEnabled,
      trackAttendanceHintText: attendanceLine.trackAttendanceHintText,
      mandatoryFollowUp: attendanceLine.mandatoryFollowUp,
      mandatoryFollowUpHintText: attendanceLine.mandatoryFollowUpHintText,
      validationError: attendanceLine.validationError,
    },
  };
};

const toDigitalContent = (lines: FormLineById): FormLineByIdExtended => {
  const digitalContent = lines.digitalContent as
    | (IDigitalContentLine & FormLineDesignerExtensions)
    | undefined;
  if (!digitalContent) {
    return {};
  }

  const {
    commentsEnabled,
    commentsGuidance,
    validationError,
    description,
    isEnabled,
    files,
    rejections,
    packId,
  } = digitalContent;

  return {
    digitalContent: {
      id: 'digitalContent',
      type: 'digitalContent',
      description,
      commentsEnabled,
      commentsGuidance,
      isEnabled,
      files,
      rejections,
      packId,
      showIndex: true,
      title: digitalContent.title ?? 'Digital Content',
      validationError,
    },
  };
};

/**
 * Includes an 'add' line before each component line.
 */
export const intersperseAddLines = (lines: FormLineById = {}): FormLineById => {
  // Remove existing add lines. Use after modifying draft.
  const isNotAddLine = (key: string) => (lines as any)[key].type !== 'add';
  return R.mergeAll([
    ...Object.keys(lines).filter(isNotAddLine).map(lineWithOptionalAdd(lines)),
    addLine(),
  ]);
};

const lineWithOptionalAdd = (lines: FormLineByIdExtended) => {
  const canAddBeforeLine = (key: string | number) =>
    STATIC_CONTENT_IDS.includes(lines[key].type) === false;

  return (key: string | number) =>
    canAddBeforeLine(key)
      ? {
          ...addLine({ key: `add-before-${key}`, beforeId: key }),
          [key]: lines[key],
        }
      : { [key]: lines[key] };
};

/**
 * Add new component.
 */
interface IAddLineProps {
  expand?: boolean;
  key?: string;
  beforeId?: string | number;
}
const addLine = ({
  expand = false,
  key = 'add',
  beforeId,
}: IAddLineProps = {}) => ({
  [key]: {
    id: `add-${uuid.generate()}`,
    type: 'add',
    expand,
    beforeId,
  },
});
