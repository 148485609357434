/** @jsxImportSource @emotion/react */
import React from 'react';
import { is } from 'ramda';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';

export interface ITextWrapperProps {
  axis?: 'xAxis' | 'yAxis';
  showTextLabels?: boolean;
  fontSize?: number;
  fontWeight?: FontWeight;
  x?: number;
  y?: number;
  payload?: any;
  dx?: number;
  dy?: number;
  textAnchor?: 'middle' | 'start';
}

/**
 * Provides a custom `TextWrapper` for use within Recharts.
 */
const TextWrapper: React.FC<ITextWrapperProps> = ({
  axis,
  dx = axis === 'yAxis' ? -6 : 0,
  dy = axis === 'yAxis' ? 6 : 12,
  fontSize = 10,
  fontWeight = FontWeight.bold,
  payload = {},
  showTextLabels = true,
  textAnchor = 'middle',
  x,
  y,
}) => {
  const text = is(String, payload.value)
    ? payload.value.toUpperCase()
    : payload.value;

  return (
    <g transform={`translate(${x},${y})`}>
      {showTextLabels && (
        <text
          dx={dx}
          dy={dy}
          x={0}
          y={0}
          fill={'#666'}
          fontSize={fontSize}
          fontWeight={fontWeight}
          textAnchor={textAnchor}
        >
          {text}
        </text>
      )}
    </g>
  );
};

export default React.memo(TextWrapper);
