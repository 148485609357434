import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { monitoring } from '@seeeverything/ui.util/src/monitoring/monitoring.ts';
import { GlobalReduxEpicDependenciesType } from '@seeeverything/ui.util/src/redux/types.ts';
import { OrderBy } from '@seeeverything/ui.util/src/types.ts';
import { equals } from 'ramda';
import { StateObservable, ofType } from 'redux-observable';
import { EMPTY, Observable, concatAll, filter, mergeMap, of } from 'rxjs';
import { NotFoundErrorKind } from '../../data/errors.ts';
import { queryGrid } from '../../data/grid.ts';
import { personChart } from '../../data/person.chart.ts';
import { teamChart } from '../../data/team.chart.ts';
import { toDateFilter, toTimespanVariable } from '../../data/util.ts';
import {
  ComponentType,
  IDashboardComponent,
  IDashboardDataState,
  IDashboardGrid,
} from '../../types.ts';
import * as actions from '../actions.ts';
import {
  DashboardActionLoad,
  DashboardV2ReduxState,
  GlobalDashboardsState,
} from '../types.ts';

export function loadComponentEpic(
  action$: Observable<DashboardActionLoad>,
  state$: StateObservable<GlobalDashboardsState>,
  { client }: GlobalReduxEpicDependenciesType,
) {
  return action$.pipe(
    ofType('ui.dashboards/dashboard/COMPONENT/LOAD'),
    filter(() => Boolean(state$.value.dashboardsV2.template)),
    mergeMap(async (action) => {
      const { componentType, id, orderBy } = action.payload;
      const state = state$.value.dashboardsV2;

      try {
        const getDashboardEntity = () => ({
          id: state$.value.dashboardsV2.template?.entityId,
          type: state$.value.dashboardsV2.template?.kind,
          module: state$.value.tenantState.tenant?.module,
          dateFilterId: toDateFilter(state$.value)?.selection?.id,
        });
        const initialEntity = getDashboardEntity();

        const componentState =
          componentType !== 'HEADER' ? state[componentType]?.[id] : undefined;

        const dataState = componentState?.dataState;
        if (!dataState)
          throw new Error(`Cannot access dataState for component '${id}'.`);

        const { loadMore, pageSize } = action.payload;

        const entityType = state.template.kind;

        const data = await loadV2Data(
          client,
          state$.value,
          componentType,
          id,
          entityType,
          state.template.entityId,
          dataState.dataSetId,
          dataState,
          state.template.id,
          loadMore,
          pageSize,
          orderBy,
          componentState,
        );

        const currentEntity = getDashboardEntity();
        const dashboardChangedSinceLoad = !equals(initialEntity, currentEntity);

        return dashboardChangedSinceLoad
          ? EMPTY
          : of(
              actions.loaded(
                componentType,
                id,
                data,
                loadMore && componentType !== 'CHART',
              ),
            );
      } catch (e) {
        if (componentType === 'HEADER' && e.kind === NotFoundErrorKind) {
          // NotFound for header is a permissions error and fatal to the whole dashboard.
          return of(actions.errorMessage(e.toString()));
        } else {
          return of(actions.componentError(componentType, id, e));
        }
      }
    }),
    concatAll(),
  );
}

/**
 * Loads a V2 dashboard chart/grid.
 */
const loadV2Data = async (
  client: IGraphQLClient,
  state: DashboardV2ReduxState,
  componentType: ComponentType,
  componentId: string,
  entityType: 'person' | 'team',
  entityId: string,
  dataSetId: string,
  dataState: IDashboardDataState,
  templateId: string,
  loadMore: boolean,
  pageSize: number,
  orderBy: OrderBy[],
  componentState: IDashboardComponent,
) => {
  if (componentType === 'CHART') {
    if (entityType === 'person') {
      const chart = personChart(client, {
        personId: entityId,
        chartId: componentId,
        dataSetId,
        templateId,
        dateFilter: toDateFilter(state),
      });
      monitoring.addPageView(`/dashboard/person/chart/${dataSetId}`);
      return chart;
    }

    if (entityType === 'team') {
      const chart = teamChart(client, {
        teamId: entityId,
        chartId: componentId,
        dataSetId,
        templateId,
        dateFilter: toDateFilter(state),
      });
      monitoring.addPageView(`/dashboard/team/chart/${dataSetId}`);
      return chart;
    }

    throw new Error(
      `No chart handler available for entity type: ${entityType}`,
    );
  }

  if (componentType === 'COMMENTS_LIST') {
    const currentPage =
      (loadMore && dataState.data?.pagination?.currentPage) || 0;

    const dateFilter = toDateFilter(state);

    // SheetGridV2 doesn't use factory.grid and doesn't pass Grid footer prop.
    const grid = componentState as IDashboardGrid;

    const clickThrough = grid.clickThrough;

    const includeFooter = grid.footer !== false && !clickThrough;

    monitoring.addPageView(`/dashboard/${entityType}/grid/${dataSetId}`);
    return queryGrid(client, {
      clickThrough,
      dataSetId,
      entityId,
      entityType,
      filterStatements: [],
      gridId: componentId,
      includeFooter,
      orderBy,
      pagination: { pageNumber: currentPage + 1, pageSize },
      templateId,
      timespan: toTimespanVariable(dateFilter),
      tempFormStatusExcludeCompleted: false,
    });
  }

  // Unhandled component types don't load data.
  return undefined;
};
