import * as R from 'ramda';
import gql from 'graphql-tag';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { parseYaml } from '@seeeverything/ui.forms/src/parse/index.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { IDesignerEditableDraft } from '../model/types.ts';
import { toDesignerLines } from './data.editableDraft.designerLines.ts';
import { TenantModuleConfiguration } from '@seeeverything/ui.util/src/redux/tenant/types.ts';

export async function editableDraft(
  client: IGraphQLClient,
  id: string,
  config: TenantModuleConfiguration['formTemplateDesigner'],
): Promise<IDesignerEditableDraft> {
  try {
    const response = await client.query<DesignerTemplateResponse>({
      query,
      fetchPolicy: 'network-only',
      variables: { id },
    });

    const data = response.data.forms.formsDesignerDraft;
    const template = data && toDraft(data, config);

    if (R.isNil(template)) {
      throw new Error('Designer Draft not found');
    }

    return ensureDraft(template);
  } catch (e) {
    log.error('Unable to fetch Designer Draft', e);
    throw e;
  }
}

function toDraft(
  node: any,
  config: TenantModuleConfiguration['formTemplateDesigner'],
): IDesignerEditableDraft {
  const definition = node.definition && JSON.parse(node.definition);
  const lines = node.definition && parseYaml(node.definition);
  const designerLines = lines ? toDesignerLines(lines, config) : undefined;

  return {
    id: node.id,
    name: node.name,
    status: node.status,
    definition,
    lines,
    designerLines,
    sourceTemplate: node.sourceTemplate,
  };
}

function ensureDraft(template: IDesignerEditableDraft) {
  if (template.status && template.status.toUpperCase() !== 'DRAFT') {
    const error = new Error(
      `Designer Draft does not have expected status 'Draft': ${template}`,
    );
    log.error(error);
    throw error;
  }
  return template;
}

type DesignerTemplateResponse = {
  forms: {
    formsDesignerDraft: {
      id: string;
      name: string;
      definition: string;
      status: string;
      sourceTemplate: {
        id: string;
        name: string;
        category: {
          id: string;
          name: string;
        };
      };
    };
  };
};

const query = gql`
  query DesignerTemplate($id: ID!) {
    forms {
      formsDesignerDraft(id: $id) {
        id
        name
        definition
        status
        sourceTemplate {
          id
          name
          category {
            id
            name
          }
        }
      }
    }
  }
`;
