/** @jsxImportSource @emotion/react */
import { IconWrapper } from '@seeeverything/ui.primitives/src/components/IconWrapper/IconWrapper.tsx';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { TextFieldDropdown } from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';
import { css } from '@emotion/react';

export interface INewTemplateProps {
  categories?: ISelectionListItem[];
  categoriesIsLoading?: boolean;
  category?: ISelectionListItem;
  categoryErrorText?: string;
  definitionJson?: string;
  definitionJsonErrorText?: string;
  name?: string;
  nameErrorText?: string;
  onCategoryChange?: (to: string | ISelectionListItem) => void;
  onDefinitionJsonChange?: (to: string) => void;
  onNameChange?: (to: string) => void;
}

export const NewTemplate = ({
  categories,
  categoriesIsLoading,
  category,
  categoryErrorText,
  definitionJson,
  definitionJsonErrorText,
  name,
  nameErrorText,
  onCategoryChange,
  onDefinitionJsonChange,
  onNameChange,
}: INewTemplateProps) => (
  <>
    <div css={styles.bodyFull}>
      <IconWrapper icon={'infoCard'} style={styles.field}>
        <TextField
          id={'name'}
          value={name}
          label={'New Template Name'}
          onChange={onNameChange}
          error={nameErrorText}
        />
      </IconWrapper>
    </div>
    <div css={styles.bodyFull}>
      <IconWrapper icon={'list'} style={styles.field}>
        <TextFieldDropdown
          id={'category'}
          label={'Template Category'}
          isLoading={categoriesIsLoading}
          selections={categories}
          value={category}
          onChange={onCategoryChange}
          error={categoryErrorText}
        />
      </IconWrapper>
    </div>
    <div css={styles.bodyFull}>
      <IconWrapper icon={'description'} style={styles.field}>
        <TextField
          id={'definitionJson'}
          value={definitionJson}
          label={'Template Definition (JSON)'}
          multiline={true}
          maxRows={3}
          onChange={onDefinitionJsonChange}
          error={definitionJsonErrorText}
        />
      </IconWrapper>
    </div>
  </>
);

const styles = {
  bodyFull: css({
    width: '100%',
  }),
  field: css({
    width: '100%',
    padding: '15px',
    boxSizing: 'border-box',
  }),
};
