/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { SkeletonDetailedItemsList } from '@seeeverything/ui.primitives/src/components/SkeletonDetailedItemsList/index.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useMeasure } from '@seeeverything/ui.primitives/src/hooks/useMeasure.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/index.ts';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  loadDashboardComponent,
  viewDetail,
  viewEntity,
} from '../../redux/actions.ts';
import {
  useDashboardsDispatch,
  useDashboardsSelector,
} from '../../redux/store.ts';
import {
  DashboardComment,
  DashboardCommentType,
  DashboardDataRow,
  IDashboardCommentDataKeyItem,
} from '../../types.ts';
import { formatDateString } from '../../util/util.ts';
import { Comment, Empty, LoadMore } from './components/index.ts';

export interface ICommentsListProps {
  dashboardComponentId: string;
  canLoadMore?: boolean;
  isLoading?: boolean | 'MORE';
  type: 'FULL_SHEET' | 'DASHBOARD_COMPONENT';
}

export const CommentsList: React.FC<ICommentsListProps> = ({
  canLoadMore,
  dashboardComponentId,
  isLoading,
  type,
}) => {
  const dispatch = useDashboardsDispatch();

  const isInit = useRef(false);
  const [measureRef, commentsSize] = useMeasure({
    remeasureDependency: isLoading,
  });

  const [collapsed, setCollapsed] = useState(
    type === 'DASHBOARD_COMPONENT' && !isLoading && commentsSize?.height >= 270,
  );

  const commentsList = useDashboardsSelector(
    (state) => state.dashboardsV2.COMMENTS_LIST[dashboardComponentId],
  );

  const loadMoreText = useMemo(() => {
    if (!commentsList) return;
    if (type === 'FULL_SHEET') return;

    const hasMore = Boolean(
      commentsList.dataState?.data?.pagination?.hasNextPage,
    );
    return hasMore ? 'Load more' : 'Expand full screen';
  }, [commentsList, type]);

  const comments = useMemo(() => {
    if (isLoading) return [];

    const { dataKeys, dataState, kind } = commentsList;
    return dataState.data.data.map(
      (comment): DashboardComment => ({
        id: comment.id,
        subject: comment.data.find((d) => d.key === dataKeys.subject).value,
        description:
          kind === 'DESCRIPTION_LIST'
            ? getDescriptionFromDescriptionList(
                comment,
                dataKeys.description as IDashboardCommentDataKeyItem[],
              )
            : getDescriptionFromBasicComment(
                comment,
                dataKeys.description as string,
              ),
        occurredAt:
          formatDateString(
            comment.data.find((d) => d.key === dataKeys.occurredAt).value,
            {
              format: 'D MMM YYYY',
              defaultValue: '-',
            },
          ) || '-',
        form:
          comment.entity?.type === 'Form'
            ? { id: comment.entity.id, name: comment.entity.label }
            : undefined,
      }),
    );
  }, [commentsList, isLoading]);

  const handleViewFullSheet = useCallback(
    () => dispatch(viewDetail('COMMENTS_LIST', dashboardComponentId)),
    [dashboardComponentId, dispatch],
  );

  const handleLoadMore = useCallback(() => {
    if (!commentsList) return;
    dispatch(
      loadDashboardComponent(
        'COMMENTS_LIST',
        commentsList.id,
        commentsList.orderBy,
        true,
        commentsList.dataState.data?.pagination?.pageSize,
      ),
    );
  }, [commentsList, dispatch]);

  const handleViewForm = useCallback(
    (instanceId: string, instanceLabel: string) => {
      if (!commentsList) return;
      if (!instanceId) return;

      dispatch(
        viewEntity(dashboardComponentId, instanceId, 'Form', instanceLabel),
      );
    },
    [commentsList, dashboardComponentId, dispatch],
  );

  useEffect(() => {
    if (isInit.current) return undefined;
    if (isLoading) return undefined;
    if (!commentsSize?.height) return undefined;

    // Only do this once, and after we have items loaded.
    isInit.current = true;
    if (type === 'FULL_SHEET') return undefined;
    if (commentsSize.height >= 270) setCollapsed(true);
  }, [type, commentsSize?.height, isLoading]);

  const elLoading = isLoading === true && (
    <SkeletonDetailedItemsList
      itemStyle={styles.skeletonList}
      numberOfSkeletonItems={3}
    />
  );

  const elEmpty = !isLoading && !comments.length && (
    <Empty text={commentsList?.emptyText} />
  );

  const elComments = !elLoading && !elEmpty && (
    <div ref={measureRef} css={styles.commentsOuter}>
      {comments.map((comment, index) => (
        <Comment
          key={`comment-${index}`}
          comment={comment}
          onFormClicked={handleViewForm}
        />
      ))}
    </div>
  );

  const elLoadMore = canLoadMore && isLoading !== true && (
    <LoadMore
      loadMoreText={loadMoreText}
      onClick={
        type === 'DASHBOARD_COMPONENT' ? handleViewFullSheet : handleLoadMore
      }
      isLoading={isLoading === 'MORE'}
      hideBorderTop={Boolean(comments?.length)}
    />
  );

  const elTitle = type === 'DASHBOARD_COMPONENT' && (
    <Text
      uppercase={true}
      color={color.format(-0.4)}
      weight={600}
      size={15}
      style={styles.title}
    >
      {commentsList?.title}
    </Text>
  );

  const expand = useCallback(() => setCollapsed(false), []);

  const elExpand = collapsed && (
    <div css={styles.expand} onClick={expand}>
      <Text color={COLORS.BLUE} cursor={'pointer'}>
        {'Show more'}
      </Text>
      <Icons.arrowDropDown size={40} cursor={'pointer'} />
    </div>
  );

  return (
    <div css={collapsed ? [styles.base, styles.collapsed] : styles.base}>
      {elTitle}
      {elLoading}
      {elEmpty}
      {elComments}
      {elLoadMore}
      {elExpand}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    position: 'relative',
    overflow: 'hidden',
  }),
  collapsed: css({
    maxHeight: 300,
    cursor: 'pointer',
  }),
  expand: css({
    position: 'absolute',
    inset: 'auto 0 0 0',
    height: 35,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    borderTop: 'solid 6px #E5E5E5',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#fafafa',
    },
  }),
  title: css({
    borderBottom: `8px solid ${color.format(-0.1)}`,
    padding: '0px 8px 8px 8px',
  }),
  commentsOuter: css({
    display: 'flex',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    flexDirection: 'column',
    marginTop: 3,
  }),
  skeletonList: css({
    margin: 8,
  }),
};

const getDescriptionFromBasicComment = (
  comment: DashboardDataRow,
  description: string,
): DashboardCommentType => ({
  kind: 'BASIC_COMMENT',
  description:
    comment.data.find((d) => d.key === description)?.value ||
    'There was no comment provided.',
});

const getDescriptionFromDescriptionList = (
  comment: DashboardDataRow,
  descriptionItems: IDashboardCommentDataKeyItem[],
): DashboardCommentType => ({
  kind: 'DESCRIPTION_LIST',
  items: descriptionItems.map((descriptionItem) => ({
    title: descriptionItem.label,
    description:
      comment.data.find((d) => d.key === descriptionItem.description)?.value ||
      'There was no comment provided.',
  })),
});
