import { distributionListSlice } from '@seeeverything/ui.forms/src/redux/distributionList/index.ts';
import { goalScheduleSlice } from '@seeeverything/ui.forms/src/redux/goalSchedule/index.ts';
import { instanceScheduleSlice } from '@seeeverything/ui.forms/src/redux/instanceSchedule/index.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { combineEpics } from 'redux-observable';
import { Observable, filter, map } from 'rxjs';
import { GlobalShellEpicDependencies, GlobalShellState } from '../store.ts';
import * as sheetSchedulesSlice from './sheetSchedulesSlice.ts';

export const sheetSchedulesEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalShellState,
  GlobalShellEpicDependencies
>(loadDistributionListsEpic, loadGoalSchedulesEpic, loadInstanceSchedulesEpic);

function loadDistributionListsEpic(action$: Observable<ReduxAction>) {
  return action$.pipe(
    filter(sheetSchedulesSlice.selectScheduleType.match),
    filter((action) => action.payload.type === 'DISTRIBUTION_LIST'),
    map(() =>
      distributionListSlice.loadDistributionLists({ loadNextPage: false }),
    ),
  );
}

function loadGoalSchedulesEpic(action$: Observable<ReduxAction>) {
  return action$.pipe(
    filter(sheetSchedulesSlice.selectScheduleType.match),
    filter((action) => action.payload.type === 'GOAL_SCHEDULES'),
    map(() => goalScheduleSlice.loadGoalSchedules({ loadNextPage: false })),
  );
}

function loadInstanceSchedulesEpic(action$: Observable<ReduxAction>) {
  return action$.pipe(
    filter(sheetSchedulesSlice.selectScheduleType.match),
    filter((action) => action.payload.type === 'INSTANCE_TIME_SCHEDULES'),
    map(() =>
      instanceScheduleSlice.loadInstanceSchedules({ loadNextPage: false }),
    ),
  );
}
