/** @jsxImportSource @emotion/react */
import React from 'react';
import { ErrorScreen } from '../../ErrorScreen/ErrorScreen.tsx';
import { log } from '@seeeverything/ui.util/src/log/log.ts';

export interface IErrorBoundaryShellProps {
  isErrorOverride?: boolean;
  background?: string;
  children?: React.ReactNode;
}
export interface IErrorBoundaryShellState {
  isError: boolean;
}

/**
 * Catches errors thrown by React components within the Admin App and appropriately renders
 * an error display, with logging of error.
 *
 * NOTE: Currently React does not support hooks for `getDerivedStateFromError` and `componentDidCatch`.
 * Please check https://reactjs.org/docs/hooks-faq.html#do-hooks-cover-all-use-cases-for-classes to see if this has changed
 * if you're looking to convert this to a function component.
 */
export class ErrorBoundaryShell extends React.Component<
  IErrorBoundaryShellProps,
  IErrorBoundaryShellState
> {
  public state: IErrorBoundaryShellState = {
    isError: false,
  };

  public static getDerivedStateFromError() {
    return { isError: true };
  }

  public componentDidCatch(error: any, info: any) {
    log.error('Unhandled error caught at a global level', error, info);
  }

  public render() {
    const { isErrorOverride, children } = this.props;
    const { isError } = this.state;

    if (isErrorOverride || isError) {
      return <ErrorScreen generalError={true} />;
    }
    return children;
  }
}
