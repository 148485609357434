import { DashboardDateFilterPeriod } from '@se/data/dashboards/query/types.ts';
import momentTz from 'moment-timezone';
import { MONTH_OCTOBER } from '../common/constants.ts';
import {
  DashboardDateFilter,
  DashboardDateFilterKind,
  DashboardDateFilterOptions,
  ParserOptions,
} from '../types.ts';

export function parseDateFilter(
  definition: any,
  options?: ParserOptions,
): DashboardDateFilter | DashboardDateFilter[] {
  const kind: DashboardDateFilterKind = definition.kind;
  switch (kind) {
    case 'TODAY_BACKWARDS':
      return {
        id: dateFilterId(definition),
        toLabel: dateFilterLabel({
          kind,
          definition,
          tenantTimezone: options?.tenantTimezone,
        }),
        endDate: momentTz()
          .tz(options?.tenantTimezone ?? 'Etc/UTC', false)
          .toDate(),
        startDate: momentTz()
          .tz(options?.tenantTimezone ?? 'Etc/UTC', false)
          .subtract(
            definition.amount,
            DATE_MAP[definition.period as DashboardDateFilterPeriod]?.unit,
          )
          .toDate(),
        period: definition.period,
        amount: definition.amount,
        isDefault: definition['is-default'] === true,
        kind: 'TODAY_BACKWARDS',
      };

    case 'CUSTOM_RANGE':
      return {
        id: dateFilterId(definition),
        toLabel: dateFilterLabel({
          kind,
          definition,
          tenantTimezone: options?.tenantTimezone,
        }),
        minAllowedStartDate:
          definition.minAllowedStartDate &&
          momentTz(definition.minAllowedStartDate)
            .tz(options?.tenantTimezone ?? 'Etc/UTC', true)
            .toDate(),
        maxAllowedEndDate:
          definition.maxAllowedEndDate &&
          momentTz(definition.maxAllowedEndDate)
            .tz(options?.tenantTimezone ?? 'Etc/UTC', true)
            .toDate(),
        isDefault: definition['is-default'] === true,
        kind: 'CUSTOM_RANGE',
      };

    case 'REPEATING_FREQUENCY': {
      const numRepeats = definition['max-num-repeats'];
      return Array.from({ length: numRepeats }).map(
        (_, index): DashboardDateFilter => {
          const recurrenceNumber = index + 1;

          return {
            id: dateFilterId(definition, recurrenceNumber),
            toLabel: dateFilterLabel({
              kind,
              definition,
              amount: definition.amount,
              recurrenceNumber,
              tenantTimezone: options?.tenantTimezone,
            }),
            isDefault: definition['is-default'] === true,
            period: definition.period,
            amount: definition.amount,
            startDate: ({
              financialYearStartMonth,
            }: DashboardDateFilterOptions) =>
              recurringDate(
                definition.period,
                'START',
                definition['start-day'],
                definition.amount,
                recurrenceNumber,
                financialYearStartMonth,
                options?.tenantTimezone,
              ).toDate(),
            endDate: ({
              financialYearStartMonth,
            }: DashboardDateFilterOptions) =>
              recurringDate(
                definition.period,
                'END',
                definition['start-day'],
                definition.amount,
                recurrenceNumber,
                financialYearStartMonth,
                options?.tenantTimezone,
              ).toDate(),
            recurrenceNumber,
            kind: 'REPEATING_FREQUENCY',
          };
        },
      );
    }

    default:
      /**
       * Backwards compatibility.
       * Remove once all dashboard templates have been migrated to include `kind`.
       */
      return {
        id: dateFilterId(definition),
        toLabel: dateFilterLabel({
          kind,
          definition,
          tenantTimezone: options?.tenantTimezone,
        }),
        period: definition.period,
        amount: definition.amount,
        endDate: momentTz()
          .tz(options?.tenantTimezone ?? 'Etc/UTC', false)
          .toDate(),
        startDate: momentTz()
          .tz(options?.tenantTimezone ?? 'Etc/UTC', false)
          .subtract(
            definition.amount,
            DATE_MAP[definition.period as DashboardDateFilterPeriod]?.unit,
          )
          .toDate(),
        isDefault: definition['is-default'] === true,
        kind: 'TODAY_BACKWARDS',
      };
  }
}

export function defaultDateFilter(dateFilters: DashboardDateFilter[]) {
  return dateFilters && dateFilters.find((filter) => filter.isDefault);
}

function dateFilterId(definition: any, index?: number) {
  if (definition.kind === 'CUSTOM_RANGE') {
    return 'CUSTOM_RANGE';
  }

  const startDay =
    definition['start-day'] === undefined ? '' : `-${definition['start-day']}`;

  const suffix = index === undefined ? '' : `-${index}`;
  return `${definition.amount}-${definition.period}${startDay}${suffix}`;
}

interface IDateFilterLabelOptions {
  kind: DashboardDateFilterKind;
  definition: any;
  amount?: number;
  recurrenceNumber?: number;
  tenantTimezone?: string;
}

const dateFilterLabel =
  ({
    kind,
    definition,
    amount,
    recurrenceNumber,
    tenantTimezone,
  }: IDateFilterLabelOptions) =>
  ({
    financialYearStartMonth = MONTH_OCTOBER,
    customOptions,
  }: DashboardDateFilterOptions) => {
    const { period } = definition;

    switch (kind) {
      case 'REPEATING_FREQUENCY': {
        const start = recurringDate(
          period,
          'START',
          definition['start-day'],
          amount,
          recurrenceNumber,
          financialYearStartMonth,
          tenantTimezone,
        );
        const end = recurringDate(
          period,
          'END',
          definition['start-day'],
          amount,
          recurrenceNumber,
          financialYearStartMonth,
          tenantTimezone,
        );

        const format = DATE_MAP[period as DashboardDateFilterPeriod]?.format;

        return `${definition.label} (${start.format(format)} to ${end.format(
          format,
        )})`;
      }

      case 'CUSTOM_RANGE':
        if (!customOptions) {
          return definition.label;
        }
        return `${definition.label} (${momentTz(customOptions.startDateISO)
          .tz(tenantTimezone ?? 'Etc/UTC', true)
          .format(DATE_MAP.Day.format)} to ${momentTz(customOptions.endDateISO)
          .tz(tenantTimezone ?? 'Etc/UTC', true)
          .format(DATE_MAP.Day.format)})`;

      default:
        return definition.label;
    }
  };

type DateMapType = {
  unit: momentTz.unitOfTime.DurationConstructor;
  format: string;
};

const DATE_MAP: {
  [key in DashboardDateFilterPeriod]: DateMapType;
} = {
  Day: {
    unit: 'd',
    format: 'D MMM YYYY',
  },
  Week: {
    unit: 'w',
    format: 'D MMM YYYY',
  },
  Month: {
    unit: 'M',
    format: 'D MMM YYYY',
  },
  Quarter: {
    unit: 'M',
    format: 'MMM YYYY',
  },
  Year: {
    unit: 'y',
    format: 'MMM YYYY',
  },
};

const recurringDate = (
  period: DashboardDateFilterPeriod,
  kind: 'START' | 'END',
  startDay: any,
  amount = 1,
  recurrenceNumber = 1,
  financialYearStartMonth: number = MONTH_OCTOBER,
  tenantTimezone?: string,
) => {
  const timezone = tenantTimezone ?? 'Etc/UTC';

  const unit = DATE_MAP[period].unit;

  const recurrenceMultiplier =
    kind === 'START' ? recurrenceNumber : recurrenceNumber - 1;

  const endDaysOffset = kind === 'END' ? 1 : 0;

  const today = momentTz().tz(timezone, false);

  if (startDay === 'MONTH_START_DAY') {
    const startOfFollowingMonth = momentTz()
      .tz(timezone, false)
      .startOf('M')
      .add(1, 'M');

    return startOfFollowingMonth
      .subtract(amount * recurrenceMultiplier, unit)
      .subtract(endDaysOffset, 'd');
  }

  if (startDay === 'WEEK_START_DAY') {
    const startOfWeek = momentTz()
      .tz(timezone, false)
      .startOf('w')
      .add(1, 'd')
      .add(1, 'w');

    return startOfWeek
      .subtract(amount * recurrenceMultiplier, unit)
      .subtract(endDaysOffset, 'd');
  }

  if (startDay === 'FINANCIAL_YEAR_START_DAY') {
    const hasCurrentFYStarted = today.month() >= financialYearStartMonth;

    const startOfFinancialYear = momentTz()
      .tz(timezone, false)
      .set('M', financialYearStartMonth)
      .startOf('M')
      .add(hasCurrentFYStarted ? 1 : 0, 'y');

    return startOfFinancialYear
      .subtract(amount * recurrenceMultiplier, unit)
      .subtract(endDaysOffset, 'd');
  }

  if (startDay === 'FINANCIAL_QUARTER_START_DAY') {
    const financialQuarterStartMonths = [
      financialYearStartMonth % 12,
      (financialYearStartMonth + 3) % 12,
      (financialYearStartMonth + 6) % 12,
      (financialYearStartMonth + 9) % 12,
    ];

    const currentMonth = today.month();

    const startOfFollowingQuarter = getStartOfNextQuarter(
      financialQuarterStartMonths,
      currentMonth,
      timezone,
      today,
    );

    return startOfFollowingQuarter
      .subtract(amount * recurrenceMultiplier, unit)
      .subtract(endDaysOffset, 'd');
  }

  return today;
};

const getNumMonthsAway = (currentMonth: number, targetMonth: number) => {
  if (targetMonth > currentMonth) return targetMonth - currentMonth;

  return targetMonth + (11 - currentMonth) + 1;
};

const getStartOfNextQuarter = (
  financialQuarterStartMonths: number[],
  currentMonth: number,
  timezone: string,
  today: momentTz.Moment,
) => {
  const monthsAwayFromEachQuarter = financialQuarterStartMonths.map(
    (quarterStartMonth) => getNumMonthsAway(currentMonth, quarterStartMonth),
  );

  const closestQuarterIndex = monthsAwayFromEachQuarter.indexOf(
    Math.min(...monthsAwayFromEachQuarter),
  );

  const nextQuarterStartMonth =
    financialQuarterStartMonths[closestQuarterIndex];

  const nextQuarterStartMoment = momentTz()
    .tz(timezone, false)
    .set('M', nextQuarterStartMonth)
    .startOf('M');

  const nextQuarterIsNextYear = nextQuarterStartMonth < currentMonth;
  if (nextQuarterIsNextYear) nextQuarterStartMoment.set('y', today.year() + 1);

  return nextQuarterStartMoment;
};
