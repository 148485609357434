import { log } from '@seeeverything/ui.util/src/log/log.ts';
import {
  AnalyticsRequest,
  AnalyticsResponse,
} from '@seeeverything/ui.util/src/redux/analytics/types.ts';
import { GlobalAppActionType, GlobalAppState } from '../types.ts';
import { getAnalyticsEvents } from './analyticsEventMapper.ts';

export const analyticsInfoProvider = async ({
  action,
  state$,
  client,
}: AnalyticsRequest<
  GlobalAppActionType,
  GlobalAppState
>): Promise<AnalyticsResponse> => {
  try {
    const events = await getAnalyticsEvents(action, state$.value, client);
    return events ? { events } : undefined;
  } catch (error) {
    log.error(
      new Error(
        `AnalyticsInfoProvider Error: Error gathering analytics info for ${action.type} action. ${error}`,
      ),
    );
  }
};
