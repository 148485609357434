/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CommonStyles } from '@seeeverything/ui.primitives/src/common/commonStyles.ts';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { urlDeepLinkUtil } from '@seeeverything/ui.util/src/urlDeepLink/index.ts';
import { useCallback } from 'react';
import { useFormsSelector } from '../../../redux/store.ts';

export const ActionInstanceDetailsContainer: React.FC = () => {
  const formLabel = useFormsSelector(
    (state) => state.tenantState.tenant.locale.label.form,
  );

  const formSubjectLabel = useFormsSelector(
    (state) => state.tenantState.tenant.locale.label.formSubject,
  );

  const instanceDetails = useFormsSelector(
    (state) => state.formActionV2.formInstance,
  );

  const goalDetails = useFormsSelector((state) => state.formActionV2.goal);

  const instanceSubject = useFormsSelector(
    (state) => state.formActionV2.formInstanceSubject,
  );

  const tenant = useFormsSelector((state) => state.tenantState.tenant.id);
  const module = useFormsSelector((state) => state.tenantState.tenant.module);

  const openInstanceUrl = useCallback(() => {
    if (!instanceDetails) return;
    if (!tenant) return;
    if (!module) return;

    const url = urlDeepLinkUtil.generateUrlPath({
      tenant,
      module,
      chips: [
        {
          type: 'forms',
          value: instanceDetails.id,
          label: instanceDetails.template.name,
        },
      ],
    });
    window.open(url, '_blank');
  }, [instanceDetails, tenant, module]);

  const elGoalDetails = goalDetails && (
    <div css={styles.contentGroup}>
      <div css={styles.contentRow}>
        <Icons.myLocation fill={'#a0a0a0'} tooltip={'Goal Details'} />
        <div css={styles.headerText}>
          <Text css={styles.goalCategory}>{`${
            goalDetails.category ?? 'Goal'
          }: `}</Text>
          <Text ellipsis={true} css={styles.goalDescription}>
            {goalDetails.description}
          </Text>
        </div>
      </div>
      <div css={styles.detailFields}>
        {goalDetails.assignedTo && (
          <div css={styles.detailField}>
            <Icons.face fill={'#a0a0a0'} tooltip={'Goal Assigned To'} />
            <Text>{goalDetails.assignedTo.name}</Text>
          </div>
        )}
        <div css={styles.detailField}>
          <Icons.info fill={'#a0a0a0'} tooltip={'Goal Status'} />
          <Text>{goalDetails.statusDisplayValue}</Text>
        </div>
        {goalDetails.datesDisplayValue && (
          <div css={styles.detailField}>
            <Icons.notifications fill={'#a0a0a0'} tooltip={'Goal Dates'} />
            <Text>{goalDetails.datesDisplayValue}</Text>
          </div>
        )}
      </div>
    </div>
  );

  const elInstanceDetails = instanceDetails && (
    <div css={styles.contentGroup}>
      <div css={styles.contentRow}>
        <Icons.dashboard fill={'#a0a0a0'} tooltip={`${formLabel} Details`} />
        <div css={styles.headerText}>
          <Text>{`${formLabel}: `}</Text>
          <Button onClick={openInstanceUrl}>
            <div css={styles.instanceLaunchButton}>
              <Text color={COLORS.BLUE} cursor={'pointer'}>
                {instanceDetails.template.name}
              </Text>
              <Icons.launch />
            </div>
          </Button>
        </div>
      </div>
      <div css={styles.detailFields}>
        {instanceSubject && (
          <div css={styles.detailField}>
            {instanceSubject.type === 'PERSON' ? (
              <Icons.face
                fill={'#a0a0a0'}
                tooltip={`${formLabel} ${formSubjectLabel}`}
              />
            ) : (
              <Icons.group fill={'#a0a0a0'} />
            )}
            <Text>{instanceSubject.label}</Text>
          </div>
        )}
        <div css={styles.detailField}>
          <Icons.info fill={'#a0a0a0'} tooltip={`${formLabel} Status`} />
          <Text>{instanceDetails.statusDisplayValue}</Text>
        </div>
        {instanceDetails.datesDisplayValue && (
          <div css={styles.detailField}>
            <Icons.notifications
              fill={'#a0a0a0'}
              tooltip={`${formLabel} Dates`}
            />
            <Text>{instanceDetails.datesDisplayValue}</Text>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div css={styles.base}>
      {elGoalDetails}
      {elInstanceDetails}
    </div>
  );
};

const styles = {
  base: css({
    ...CommonStyles.BoxShadowGroup,
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    padding: 12,
  }),
  contentGroup: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    ':hover': {
      backgroundColor: color.format(-0.06),
      padding: 10,
      margin: -10,
    },
  }),
  contentRow: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    alignItems: 'center',
  }),
  headerText: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    alignItems: 'center',
    maxWidth: 'calc(100% - 35px)',
  }),
  detailFields: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '5%',
    paddingLeft: 40,
    alignItems: 'center',
  }),
  detailField: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    alignItems: 'center',
  }),
  instanceLaunchButton: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  }),
  goalCategory: css({
    flex: '1 0 auto',
  }),
  goalDescription: css({
    flex: '1 1 auto',
  }),
};
