import { StateObservable, ofType } from 'redux-observable';
import { Observable, filter, map } from 'rxjs';
import { toggleGridTool } from '../actions.ts';
import {
  DashboardActionGridToolButtonClicked,
  GlobalDashboardsState,
} from '../types.ts';

export function toggleOnGridToolsClickEpic(
  action$: Observable<DashboardActionGridToolButtonClicked>,
  state$: StateObservable<GlobalDashboardsState>,
) {
  return action$.pipe(
    ofType('ui.dashboards/dashboard/GRID_TOOLS/BUTTON_CLICKED'),
    filter((action) => action.payload.id !== 'OPEN_GRID'),
    map((action) => {
      const { id, parentId } = action.payload;
      return toggleGridTool(
        parentId,
        id.toString(),
        state$.value.tenantState.tenant.module,
      );
    }),
  );
}
