/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ScrollContainer } from '@seeeverything/ui.primitives/src/components/Scroll/ScrollContainer.tsx';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { Spinner } from '@seeeverything/ui.primitives/src/components/Spinner/index.ts';
import { IToolButton } from '@seeeverything/ui.primitives/src/components/ToolButtonSet/types.ts';
import { useMediaQuery } from '@seeeverything/ui.primitives/src/hooks/useMediaQuery.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { propsAreEqualFastCompare } from '@seeeverything/ui.util/src/react/memoFastCompare.ts';
import { memo, useState } from 'react';
import { SheetIndex } from '../SheetIndex/SheetIndex.tsx';
import { ISheetIndexItem } from '../SheetIndex/types.ts';
import { SheetToolbarContainer } from '../SheetToolbar/SheetToolbarContainer.tsx';

export interface ISheetLayoutProps {
  children?: React.ReactNode;
  dateFilters?: ISelectionListItem[];
  header?: React.ReactElement;
  headerCollapsedHeight?: number;
  headerExpandedHeight?: number;
  indexItems?: ISheetIndexItem[];
  isSpinning?: boolean;
  leftContent?: React.ReactNode;
  leftPanelBottomContent?: React.ReactNode;
  leftTools?: IToolButton[];
  moreItems?: ISelectionListItem[];
  onScroll?: (scrollTop: number) => void;
  overrideNoScrollPanel?: boolean;
  rightContent?: React.ReactNode;
  rightContentOffset?: number;
  rightTools?: IToolButton[];
  rootId?: string;
  scrollOffset?: number;
  scrollSmooth?: boolean;
  selectedToolbarToolIds?: string[];
  title?: string;
}

const View: React.FC<ISheetLayoutProps> = ({
  children,
  dateFilters,
  header,
  indexItems,
  isSpinning,
  leftContent,
  leftPanelBottomContent,
  leftTools,
  moreItems,
  onScroll,
  overrideNoScrollPanel,
  rightContent,
  rightContentOffset = 0,
  rightTools,
  rootId,
  scrollOffset = 35,
  scrollSmooth,
  selectedToolbarToolIds,
  title,
}) => {
  const canExpand = useMediaQuery('(min-width:800px)');
  const [isExpanded, setIsExpanded] = useState(canExpand);

  const computedStyles = {
    inner: css({
      position: 'absolute',
      inset: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'stretch',
      opacity: isSpinning ? 0.3 : 1,
    }),
  };

  return (
    <div css={styles.base}>
      <div css={computedStyles.inner}>
        <SheetToolbarContainer
          dateFilters={dateFilters}
          leftContent={leftContent}
          leftTools={leftTools}
          moreItems={moreItems}
          rightContent={rightContent}
          rightTools={rightTools}
          selectedToolIds={selectedToolbarToolIds}
          title={title}
        />
        {header}
        <div css={styles.content}>
          <div css={styles.sheetIndex}>
            {indexItems && (
              <SheetIndex
                items={indexItems}
                isExpanded={isExpanded}
                onCollapseExpandClicked={setIsExpanded}
                scrollOffset={scrollOffset}
                scrollSmooth={scrollSmooth}
              />
            )}
            {isExpanded ? leftPanelBottomContent : undefined}
          </div>
          <div
            css={[
              styles.scrollPanel,
              indexItems && styles.scrollPanelIndexOffset,
            ]}
          >
            {overrideNoScrollPanel ? (
              children
            ) : (
              <ScrollContainer
                containerId={'scrollPanel'}
                onScroll={onScroll}
                paddingLeft={indexItems ? 16 : 0}
                paddingRight={rightContentOffset}
                rootId={rootId}
              >
                {children}
              </ScrollContainer>
            )}
          </div>
        </div>
      </div>
      {isSpinning && <Spinner center={true} />}
    </div>
  );
};

const styles = {
  base: css({
    position: 'relative',
    flex: '1 1 auto',
    boxSizing: 'border-box',
  }),
  content: css({
    display: 'flex',
    flexDirection: 'row',
    flex: '1 1 auto',
    height: '100%',
  }),
  scrollPanel: css({
    position: 'relative',
    boxSizing: 'border-box',
    flex: '1 1 auto',
  }),
  scrollPanelIndexOffset: css({
    display: 'flex',
    marginLeft: -16,
  }),
  sheetIndex: css({
    position: 'relative',
    backgroundColor: color.format(-0.02),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    border: `solid 1px ${color.format(-0.05)}`,
  }),
};

export const SheetLayout = memo(View, propsAreEqualFastCompare);
