/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import React from 'react';
import { locale } from '../../redux/designer/locale.ts';
import { Bullet } from '../Bullet/index.ts';
import { IDesignerCommonProps } from '../types.ts';
import {
  getValidationError,
  handleChangeEvent,
  handleEventOccurred,
} from '../util.ts';
import { Option } from './components/Option.tsx';
import { OptionAdd } from './components/OptionAdd.tsx';

export type DesignerOption = {
  id: string | number;
  label?: string;
};

export type DesignerOptionItem = {
  title: string;
  orientation: DesignerOptionsOrientation;
  options: { [id: string]: DesignerOption };
};

export type DesignerOptionsOrientation = 'vertical' | 'horizontal';

export interface IDesignerOptionsProps extends IDesignerCommonProps {
  optionItems?: DesignerOptionItem;
}

export class DesignerOptions extends React.Component<
  IDesignerOptionsProps,
  object
> {
  public render() {
    const defaultOptionItems: DesignerOptionItem = {
      title: locale.defaultLabel.options,
      orientation: 'vertical',
      options: {},
    };

    const {
      id,
      optionItems = defaultOptionItems,
      canDelete,
      showBoundaryLines,
      showBullet = true,
      style,
      onChange,
      onEventOccurred,
      validationError,
    } = this.props;

    const options =
      optionItems.options &&
      Object.keys(optionItems.options).map((key) => ({
        id: key,
        label: optionItems.options[key].label,
      }));

    const computedStyles = {
      base: css({
        border: showBoundaryLines
          ? `dashed 1px ${color.format(-0.2)}`
          : 'solid 1px rgba(0, 0, 0, 0)',
        borderRadius: showBoundaryLines ? 8 : undefined,
      }),
    };

    const elDesignerContent = (
      <div css={styles.designerContent}>
        <TextField
          id={`designer-options-title-${id}`}
          onChange={handleChangeEvent(id, 'LABEL', onChange)}
          label={'Question Text'}
          style={styles.fullWidth}
          value={optionItems.title}
          multiline={true}
          error={getValidationError(validationError?.fields, 'title')}
        />
        {options &&
          options.map((option) => (
            <Option
              id={option.id}
              key={option.id}
              label={option.label}
              onLabelChanged={handleChangeEvent(id, 'OTHER', onChange, {
                labelChanged: true,
                optionId: option.id,
              })}
              onDelete={handleEventOccurred(id, 'OTHER', onEventOccurred, {
                deleteOption: true,
                optionId: option.id,
              })}
              error={getValidationError(validationError?.fields, option.id)}
            />
          ))}
        <OptionAdd
          onClick={handleEventOccurred(id, 'OTHER', onEventOccurred, {
            newOption: true,
          })}
        />
      </div>
    );

    const elDelete = canDelete && (
      <div title={'Remove Question'}>
        <Icons.delete
          onClick={handleEventOccurred(id, 'DELETE', onEventOccurred)}
          cursor={'pointer'}
          fill={color.format(-0.4)}
          size={20}
        />
      </div>
    );

    const elOuter = (
      <div css={styles.content}>
        <div css={styles.editIcons}>{elDelete}</div>
        {elDesignerContent}
      </div>
    );

    return (
      <div css={[styles.outer, style]}>
        {showBullet && <Bullet />}
        <div css={[styles.base, computedStyles.base]}>{elOuter}</div>
      </div>
    );
  }
}

const styles = {
  outer: css({
    position: 'relative',
  }),
  base: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 250,
    padding: '8px 20px 6px 10px',
    marginBottom: 5,
  }),
  content: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  }),
  designerContent: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: 10,
  }),
  editIcons: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 30,
    height: '100%',
  }),
  fullWidth: css({
    width: '100%',
  }),
};
