/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { InstanceScheduleFrequency } from '@se/data/forms/query/instanceScheduleTypes.ts';
import {
  DataGrid,
  DataGridFilter,
  DataGridInfiniteScrollBehavior,
  DataGridIsLoadingBehavior,
  DataGridStatusToggleSortableBehavior,
  IDataGridColumn,
  IDataGridInfiniteScrollBehaviorProps,
  IDataGridProps,
  IDataGridStatusToggleSortableBehaviorProps,
} from '@seeeverything/ui.primitives/src/components/DataGrid/index.ts';
import {
  GridClickEvent,
  IGridRow,
} from '@seeeverything/ui.primitives/src/components/Grid/types.ts';
import { OutsideAlerter } from '@seeeverything/ui.primitives/src/components/OutsideAlerter/OutsideAlerter.tsx';
import { Overlay } from '@seeeverything/ui.primitives/src/components/Overlay/Overlay.tsx';
import { useMomentTenantTimezone } from '@seeeverything/ui.primitives/src/hooks/useDateContext.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { pipe } from 'ramda';
import { useCallback, useMemo } from 'react';
import { editInstanceScheduleSlice } from '../../redux/editInstanceSchedule/index.ts';
import { instanceScheduleSlice } from '../../redux/instanceSchedule/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../redux/store.ts';
import { InstanceScheduleEditPanelContainer } from './components/InstanceScheduleEditPanelContainer.tsx';

const getFormScheduleColumns = (formLabel: string): IDataGridColumn[] => [
  {
    id: 'createdAt',
    label: 'Created',
    width: '1*',
    isFilterable: false,
    isSortable: true,
  },
  {
    id: 'name',
    label: 'Name',
    width: '3*',
    isFilterable: false,
    isSortable: true,
  },
  {
    id: 'formTemplate',
    label: `${formLabel} Type`,
    width: '2*',
    isFilterable: false,
    isSortable: false,
  },
  {
    id: 'distributionList',
    label: 'Distribution List',
    width: '2*',
    isFilterable: false,
    isSortable: false,
  },
  {
    id: 'frequency',
    label: 'Schedule',
    width: '2*',
    isFilterable: false,
    isSortable: false,
  },
  {
    id: 'nextRun',
    label: 'Next Run',
    width: '1*',
    isFilterable: false,
    isSortable: false,
  },
  {
    id: 'status',
    label: 'Status',
    width: '1*',
    isFilterable: true,
    isSortable: false,
    filterToggles: [
      {
        id: 'FILTER_SHOW_INACTIVE',
        columnId: 'status',
        label: 'Include Inactive',
        isToggled: false,
      },
    ],
  },
  {
    id: 'lastUpdated',
    label: 'Last Updated By',
    width: '2*',
    isFilterable: false,
    isSortable: false,
  },
];

const getFrequencyGridLabel = (
  frequency: InstanceScheduleFrequency,
): string => {
  switch (frequency) {
    case 'OneOff':
      return 'One Off';

    case 'Annual':
      return 'Repeating Annual';

    case 'Quarterly':
      return 'Repeating Quarterly';

    case 'Monthly':
      return 'Repeating Monthly';

    case 'Weekly':
      return 'Repeating Weekly';

    case 'ContinuousExcludingExisting':
    case 'ContinuousIncludingExisting':
      return 'Added to Distribution List';
    default:
      return '-';
  }
};

const Grid = pipe(
  DataGridInfiniteScrollBehavior,
  DataGridStatusToggleSortableBehavior,
  DataGridIsLoadingBehavior,
)(DataGrid) as React.FC<
  IDataGridInfiniteScrollBehaviorProps &
    IDataGridStatusToggleSortableBehaviorProps &
    IDataGridProps
>;

export const InstanceScheduleGridContainer: React.FC = () => {
  const dispatch = useFormsDispatch();

  const momentTenantTimezone = useMomentTenantTimezone();

  const formLabel = useFormsSelector(
    (state) => state.tenantState.tenant.locale.label.form,
  );

  const draft = useFormsSelector((state) => state.editInstanceSchedule.draft);
  const showAll = useFormsSelector((state) => state.instanceSchedule.showAll);
  const orderBy = useFormsSelector((state) => state.instanceSchedule.orderBy);

  const hasNextPage = useFormsSelector(
    (state) => state.instanceSchedule.hasNextPage,
  );
  const isLoading = useFormsSelector(
    (state) => state.instanceSchedule.isLoading,
  );
  const isLoadingMore = useFormsSelector(
    (state) => state.instanceSchedule.isLoadingMore,
  );
  const loadedPage = useFormsSelector(
    (state) => state.instanceSchedule.currentPage,
  );

  const schedules = useFormsSelector(
    (state) => state.instanceSchedule.schedules,
  );

  const filters = useMemo<DataGridFilter[]>(
    () => [
      {
        columnId: orderBy.fieldName,
        sort: orderBy.direction === 'Ascending' ? 'ASC' : 'DESC',
      },
      {
        columnId: 'status',
        toggles: [
          {
            id: 'FILTER_SHOW_INACTIVE',
            columnId: 'status',
            label: 'Show Inactive',
            isToggled: showAll,
          },
        ],
      },
    ],
    [showAll, orderBy],
  );

  const handleEditInstanceSchedule = useCallback(
    ({ rowId }: GridClickEvent) => {
      if (rowId === 'load-more') return;

      const schedule = schedules.find((s) => s.id === rowId);
      if (!schedule) return;

      dispatch(editInstanceScheduleSlice.editSchedule(schedule));
    },
    [dispatch, schedules],
  );

  const handleShowDismissChangesConfirmation = useCallback(() => {
    dispatch(editInstanceScheduleSlice.alertConfirmDismissChanges());
  }, [dispatch]);

  const handleToggleShowInactive = useCallback(
    (columnId: string, toggleId: string, to: boolean) => {
      if (columnId !== 'status') return;
      if (toggleId !== 'FILTER_SHOW_INACTIVE') return;
      dispatch(instanceScheduleSlice.toggleFilterShowInactive({ to }));
    },
    [dispatch],
  );

  const handleColumnSort = useCallback(
    (columnId: string, direction: 'ASC' | 'DESC') => {
      const to = direction === 'ASC' ? 'Ascending' : 'Descending';

      if (columnId === 'createdAt')
        dispatch(instanceScheduleSlice.sortByCreated({ to }));

      if (columnId === 'name')
        dispatch(instanceScheduleSlice.sortByName({ to }));
    },
    [dispatch],
  );

  const handleLoadPage = useCallback(() => {
    dispatch(
      instanceScheduleSlice.loadInstanceSchedules({ loadNextPage: true }),
    );
  }, [dispatch]);

  const columns = useMemo(() => getFormScheduleColumns(formLabel), [formLabel]);

  const rowData = useMemo<IGridRow[]>(
    () =>
      schedules.map((scheduleItem) => ({
        id: scheduleItem.id,
        data: [
          momentTenantTimezone(scheduleItem.createdAt).format(`D MMM YY`),
          scheduleItem.name,
          scheduleItem.template.name,
          scheduleItem.distributionList.name,
          getFrequencyGridLabel(scheduleItem.frequency),
          scheduleItem.nextRunAt
            ? momentTenantTimezone(scheduleItem.nextRunAt).format(`D MMM YY`)
            : '-',
          str.humanize(scheduleItem.status, true),
          scheduleItem.updatedBy.name,
        ],
      })),
    [momentTenantTimezone, schedules],
  );

  const elOverlay = draft && (
    <OutsideAlerter
      onClickedOutside={handleShowDismissChangesConfirmation}
      style={styles.outsideAlerter}
    >
      <InstanceScheduleEditPanelContainer />
    </OutsideAlerter>
  );

  return (
    <Overlay overlay={elOverlay} isVisible={Boolean(elOverlay)}>
      <Grid
        id={'InstanceSchedules'}
        data={rowData}
        columns={columns}
        onColumnStatusToggled={handleToggleShowInactive}
        onColumnSort={handleColumnSort}
        filters={filters}
        onClick={handleEditInstanceSchedule}
        isScrollable={true}
        isVirtualized={true}
        hasNextPage={hasNextPage}
        loadedPage={loadedPage}
        isLoading={isLoading}
        isLoadingPage={isLoadingMore}
        onLoadPage={handleLoadPage}
      />
    </Overlay>
  );
};

const styles = {
  outsideAlerter: css({
    position: 'absolute',
    inset: 0,
    overflow: 'hidden',
  }),
};
