/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CommonStyles } from '@seeeverything/ui.primitives/src/common/commonStyles.ts';
import { Transition } from '@seeeverything/ui.primitives/src/components/Transition/index.ts';
import React from 'react';
import { DesignerSection } from '../DesignerSection/DesignerSection.tsx';
import { Switch } from '../Switch/Switch.tsx';
import { IDesignerCommonProps } from '../types.ts';
import { getValidationError } from '../util.ts';

export interface IDesignerAttendanceProps extends IDesignerCommonProps {
  title?: string;
  isEnabled?: boolean;
  trackAttendanceHintText?: string;
  mandatoryFollowUp?: boolean;
  mandatoryFollowUpHintText?: string;
}

export const DesignerAttendance = React.memo(function DesignerAttendance(
  props: IDesignerAttendanceProps,
) {
  const {
    title = 'Attendance',
    showBoundaryLines,
    showBullet = true,
    style,
    onChange,
    onEventOccurred,
    isEnabled = true,
    mandatoryFollowUp = false,
    trackAttendanceHintText = 'Marks attendance as enabled.',
    mandatoryFollowUpHintText = 'Mandatory follow-up if absent or away?',
    validationError,
  } = props;

  return (
    <div css={(styles.outer, style)}>
      <DesignerSection
        id={'attendance-title'}
        canDelete={false}
        showBoundaryLines={showBoundaryLines}
        title={title}
        showBullet={showBullet}
        onChange={onChange}
        onEventOccurred={onEventOccurred}
        error={getValidationError(validationError?.fields, 'title')}
      />
      <div css={styles.base}>
        <Switch
          id={'attendance-enabled'}
          isEnabled={isEnabled}
          canDelete={true}
          showBoundaryLines={showBoundaryLines}
          descriptionToEnable={trackAttendanceHintText}
          descriptionToDisable={trackAttendanceHintText}
          editingLabel={'Track Attendance'}
          onChange={onChange}
          onEventOccurred={onEventOccurred}
          style={styles.trackAttendanceCheckBox(!isEnabled)}
        />
        <Transition.Fade in={isEnabled} style={styles.halfWidth}>
          <Switch
            id={'attendance-mandatoryFollowUp'}
            isEnabled={isEnabled && mandatoryFollowUp}
            canDelete={true}
            showBullet={false}
            showBoundaryLines={showBoundaryLines}
            descriptionToEnable={mandatoryFollowUpHintText}
            descriptionToDisable={mandatoryFollowUpHintText}
            editingLabel={'Mandatory Follow-Up'}
            onChange={onChange}
            onEventOccurred={onEventOccurred}
          />
        </Transition.Fade>
      </div>
    </div>
  );
});

const styles = {
  outer: css({
    position: 'relative',
  }),
  base: css({
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  }),
  halfWidth: {
    flex: '0 0 49.5%',
    minWidth: 286,
  },
  trackAttendanceCheckBox: (isFullWidth: boolean) =>
    css({
      flex: `0 0 ${isFullWidth ? 100 : 49.5}%`,
      minWidth: 286,
      ...CommonStyles.MaterialCubicTransitions,
    }),
};
