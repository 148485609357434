/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { formBulkUploadImportFileSlice } from '@seeeverything/ui.forms/src/redux/formBulkUploadImportFile/index.ts';
import { IActionBarButton } from '@seeeverything/ui.primitives/src/components/ModalDialog/components/ActionButtons/ActionButtons.tsx';
import Spinner from '@seeeverything/ui.primitives/src/components/Spinner/Spinner.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useMemo } from 'react';
import { hideModalDialog } from '../../redux/modalDialog/actions.ts';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';
import { DialogOuter } from '../DialogOuter/index.ts';

export const BulkUploadDuplicateRowWarningDialogContainer: React.FC = () => {
  const dispatch = useShellDispatch();

  const file = useShellSelector(
    (state) => state.formBulkUploadImportFile.validatedFile,
  );

  const workflowType = useShellSelector(
    (state) => state.formBulkUploadImportFile.importSettings.workflow,
  );

  const createAutomatedActions = useShellSelector(
    (state) =>
      state.formBulkUploadImportFile.importSettings.createAutomatedActions,
  );

  const isLoading = useShellSelector(
    (state) => state.formBulkUploadImportFile.isLoading,
  );

  const actionButtons: IActionBarButton[] = useMemo(
    () => [
      {
        id: 'Back',
        label: 'Back',
        isEnabled: !isLoading,
        onAction: () => {
          dispatch(hideModalDialog());
          dispatch(formBulkUploadImportFileSlice.cancelDuplicateRowImport());
        },
      },
      {
        id: 'Continue',
        label: 'Continue',
        isEnabled: !isLoading,
        onAction: () => {
          dispatch(
            formBulkUploadImportFileSlice.importFile({
              file,
              workflow: workflowType,
              createAutomatedActions,
            }),
          );
        },
      },
    ],
    [createAutomatedActions, dispatch, file, isLoading, workflowType],
  );

  return (
    <DialogOuter actions={actionButtons}>
      <div css={styles.base}>
        {isLoading ? (
          <Spinner center={true} />
        ) : (
          <div css={styles.content}>
            <Text size={14} weight={900}>
              {'Possible Duplicates'}
            </Text>
            <Text>
              {
                'Some rows have duplicate information to previously loaded data. Continue?'
              }
            </Text>
          </div>
        )}
      </div>
    </DialogOuter>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    minWidth: 350,
    minHeight: 100,
    padding: 25,
    position: 'relative',
  }),
  content: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
  }),
};
