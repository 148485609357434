/** @jsxImportSource @emotion/react */
import {
  DataGrid,
  DataGridFilter,
  DataGridStatusToggleSortableBehavior,
  IDataGridColumn,
  IRenderDataGridCellArgs,
} from '@seeeverything/ui.primitives/src/components/DataGrid/index.ts';
import { GridClickEvent } from '@seeeverything/ui.primitives/src/components/Grid/types.ts';
import { useCallback, useMemo } from 'react';
import { IDesignerDraft } from '../../model/types.ts';
import { CellSettingsIcon } from './components/CellSettingsIcon.tsx';

export interface IFormTemplatesProps {
  filters?: DataGridFilter[];
  menuPopupTemplateId?: string;
  onColumnSort: (columnId: string, direction: 'ASC' | 'DESC') => void;
  onDeleteTemplate: (id: string) => void;
  onHideTemplateMenuPopup: (id: string) => void;
  onOpenTemplate: (id: string) => void;
  onShowTemplateMenuPopup: (id: string) => void;
  templates: IDesignerDraft[];
}

const Grid = DataGridStatusToggleSortableBehavior(DataGrid);

/**
 * A table of Form Templates.
 */
export const FormTemplates: React.FC<IFormTemplatesProps> = ({
  filters,
  menuPopupTemplateId,
  onColumnSort,
  onDeleteTemplate,
  onHideTemplateMenuPopup,
  onOpenTemplate,
  onShowTemplateMenuPopup,
  templates,
}) => {
  const handleOpenTemplate = useCallback(
    ({ rowId }: GridClickEvent) => onOpenTemplate(rowId),
    [onOpenTemplate],
  );

  const renderMenuColumn = useCallback(
    (args: IRenderDataGridCellArgs) => {
      if (args.columnIndex !== COLUMN_INDEX_LAST_UPDATED_BY) return;

      const templateId = args.row.id.toString();

      const canOpen = args.row.data[COLUMN_INDEX_STATUS] === 'Draft';
      const isPopupVisible = Boolean(menuPopupTemplateId === templateId);

      const value = args.row.data[args.columnIndex];

      return (
        <CellSettingsIcon
          value={value}
          canOpen={canOpen}
          canDelete={true}
          rowIndex={args.rowIndex}
          onDelete={() => onDeleteTemplate(templateId)}
          onOpen={() => onOpenTemplate(templateId)}
          onShowPopup={() => onShowTemplateMenuPopup(templateId)}
          onHidePopup={() => onHideTemplateMenuPopup(templateId)}
          isPopupVisible={isPopupVisible}
        />
      );
    },
    [
      menuPopupTemplateId,
      onDeleteTemplate,
      onOpenTemplate,
      onShowTemplateMenuPopup,
      onHideTemplateMenuPopup,
    ],
  );

  const data = useMemo(
    () =>
      templates.map((template) => ({
        id: template.id,
        data: [
          template.createdAt,
          template.name,
          template.category,
          template.createdBy,
          template.updatedAt,
          template.status,
          template.updatedBy,
        ],
      })),
    [templates],
  );

  return (
    <Grid
      id={'formTemplates'}
      data={data}
      onDoubleClick={handleOpenTemplate}
      columns={COLUMNS}
      isVirtualized={false}
      renderCellContents={renderMenuColumn}
      onColumnSort={onColumnSort}
      filters={filters}
    />
  );
};

const COLUMN_INDEX_STATUS = 5;
const COLUMN_INDEX_LAST_UPDATED_BY = 6;

const COLUMNS: IDataGridColumn[] = [
  {
    id: 'createdAt',
    label: 'Created',
    width: 120,
    align: 'center',
    borderRight: true,
    isSortable: true,
  },
  {
    id: 'name',
    label: 'Name',
    width: '3*',
    borderRight: true,
  },
  {
    id: 'category',
    label: 'Category',
    width: '2*',
    borderRight: true,
    isFilterable: false,
    isSortable: false,
  },
  {
    id: 'creator',
    label: 'Creator',
    width: '2*',
    borderRight: true,
    isFilterable: false,
    isSortable: false,
  },
  {
    id: 'updatedAt',
    label: 'Modified',
    align: 'center',
    width: 120,
  },
  {
    id: 'status',
    label: 'Status',
    width: 120,
    align: 'center',
    borderRight: true,
  },
  {
    id: 'lastUpdatedBy',
    label: 'Last updated by',
    width: '2*',
    isFilterable: false,
    isSortable: false,
  },
];
