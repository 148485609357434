/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { formsQuery } from '@se/data/forms/query/index.ts';
import {
  InstanceScheduleDueDateExpression,
  InstanceScheduleFrequency,
  InstanceScheduleStatus,
} from '@se/data/forms/query/instanceScheduleTypes.ts';
import { FormTemplate } from '@se/data/forms/types.ts';
import { getFormTemplateSubjectType } from '@se/data/forms/utils.ts';
import { DistributionList } from '@se/data/orgHierarchy/query/distributionList.ts';
import { orgHierarchyQuery } from '@se/data/orgHierarchy/query/index.ts';
import { DatePicker } from '@seeeverything/ui.primitives/src/components/DatePicker/DatePicker.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { IconWrapper } from '@seeeverything/ui.primitives/src/components/IconWrapper/IconWrapper.tsx';
import {
  IListItemLabel,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { Switch } from '@seeeverything/ui.primitives/src/components/Switch/Switch.tsx';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { TextFieldDropdown } from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';
import Transition from '@seeeverything/ui.primitives/src/components/Transition/Transition.tsx';
import {
  tomorrowInTenantTz,
  useDateContext,
} from '@seeeverything/ui.primitives/src/hooks/useDateContext.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useGraphQL } from '@seeeverything/ui.util/src/graphql/GraphQLProvider.tsx';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { editInstanceScheduleSlice } from '../../../redux/editInstanceSchedule/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../../redux/store.ts';
import {
  DAY_OF_WEEK_SELECTIONS,
  DUE_PERIOD_SELECTIONS,
  FACILITATOR_SELECTIONS,
  FREQUENCY_SELECTIONS,
  STATUS_SELECTIONS,
} from './constants.ts';

export const InstanceScheduleFieldsContainer: React.FC = () => {
  const { client } = useGraphQL();
  const dispatch = useFormsDispatch();

  const dateContext = useDateContext();
  const tomorrow = tomorrowInTenantTz(dateContext.tenantTimezone);

  const fieldErrors = useFormsSelector(
    (state) => state.editInstanceSchedule.errors?.fieldErrors,
  );
  const isLineManagerEnabled = useFormsSelector(
    (state) =>
      state.tenantState.tenant.features.query.settingsAssignedToLineManager,
  );

  const formLabel = useFormsSelector(
    (state) => state.tenantState.tenant.locale.label.form,
  );

  const original = useFormsSelector(
    (state) => state.editInstanceSchedule.original,
  );

  const draft = useFormsSelector((state) => state.editInstanceSchedule.draft);

  const module = useFormsSelector((state) => state.tenantState.tenant.module);

  const { data: formTemplateData, isLoading: isLoadingFormTemplates } =
    useQuery({
      placeholderData: keepPreviousData,
      queryKey: [{ key: 'instanceSchedules.formTemplates', module }],
      queryFn: async ({ queryKey }) => {
        const templatesResponse = await formsQuery.getFormTemplates(client, {
          module: queryKey[0].module,
          fetchAllPages: true,
          includeDefinition: true,
        });

        return templatesResponse.isSuccess ? templatesResponse.data : null;
      },
    });

  const formTemplateSelections = useMemo(() => {
    if (!formTemplateData) return [];
    return formTemplateData.formTemplates.map(
      (template): ISelectionListItem<IListItemLabel, FormTemplate> => ({
        id: template.id,
        content: { text: template.name, description: template.category.name },
        value: template,
        icon: Icons.document,
        iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
      }),
    );
  }, [formTemplateData]);

  const formTemplateSelection = useMemo(() => {
    if (!draft.template) return;

    const defaultSelection = {
      id: draft.template.id,
      content: { text: draft.template.name },
    };

    if (!formTemplateSelections.length) return defaultSelection;

    const result = formTemplateSelections.find(
      (item) => item.id === draft.template.id,
    );
    return result ?? defaultSelection;
  }, [draft.template, formTemplateSelections]);

  const { data: distributionListData, isLoading: isLoadingDistributionLists } =
    useQuery({
      placeholderData: keepPreviousData,
      enabled: Boolean(draft.subjectType),
      queryKey: [
        {
          key: 'instanceSchedules.distributionLists',
          module,
          typeFilter: draft.subjectType,
        },
      ],
      queryFn: async ({ queryKey }) => {
        const templatesResponse = await orgHierarchyQuery.getDistributionLists(
          client,
          {
            typeFilter: queryKey[0].typeFilter,
            module: queryKey[0].module,
            fetchAllPages: true,
          },
        );

        return templatesResponse.isSuccess ? templatesResponse.data : null;
      },
    });

  const distributionListSelections = useMemo(() => {
    if (!distributionListData) return [];
    return distributionListData.distributionLists.map(
      (list): ISelectionListItem<IListItemLabel, DistributionList> => ({
        id: list.id,
        content: { text: list.name, description: '' },
        value: list,
        icon: Icons.list,
        iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
      }),
    );
  }, [distributionListData]);

  const distributionListSelection = useMemo(() => {
    if (!draft.distributionList) return;

    const defaultSelection = {
      id: draft.distributionList.id,
      content: { text: draft.distributionList.name },
    };

    if (!distributionListSelections.length) return defaultSelection;

    const result = distributionListSelections.find(
      (item) => item.id === draft.distributionList.id,
    );
    return result ?? defaultSelection;
  }, [distributionListSelections, draft.distributionList]);

  const isNew = !draft?.id;

  const canEdit = useMemo(
    () => !original || original.status === 'NotStarted',
    [original],
  );

  const canEndSchedule = useMemo(() => {
    if (!original) return false;
    if (original.status === 'NotStarted') return true;
    if (original.status === 'Started') return true;
    return false;
  }, [original]);

  const handleUpdateName = useCallback(
    (to: string) => dispatch(editInstanceScheduleSlice.updateName(to)),
    [dispatch],
  );

  const frequencySelection = useMemo(() => {
    if (!draft.frequency) return;

    // Single frequency selection for both continuous options.
    // These two frequencies differ by the "Apply to existing members" switch.
    const frequencySelectionId =
      draft.frequency === 'ContinuousExcludingExisting'
        ? 'ContinuousIncludingExisting'
        : draft.frequency;

    return FREQUENCY_SELECTIONS.find(
      (item) => item.value === frequencySelectionId,
    );
  }, [draft.frequency]);

  const handleUpdateFrequency = useCallback(
    (to: ISelectionListItem<IListItemLabel, InstanceScheduleFrequency>) => {
      const frequency = to?.value;
      if (!frequency) return;

      dispatch(editInstanceScheduleSlice.updateFrequency(frequency));
    },
    [dispatch],
  );

  const handleUpdateFormTemplate = useCallback(
    (to: ISelectionListItem<IListItemLabel, FormTemplate>) => {
      if (!to?.value) return;

      const subjectType = getFormTemplateSubjectType(
        to.value.currentDefinition.definition,
      );
      dispatch(
        editInstanceScheduleSlice.updateFormTemplate({
          template: to.value,
          subjectType,
        }),
      );
      if (draft.subjectType && subjectType !== draft.subjectType)
        dispatch(editInstanceScheduleSlice.clearDistributionList());
    },
    [dispatch, draft.subjectType],
  );

  const handleUpdateDistributionList = useCallback(
    (to: ISelectionListItem<IListItemLabel, DistributionList>) => {
      if (!to?.value) return;
      dispatch(editInstanceScheduleSlice.updateDistributionList(to.value));
    },
    [dispatch],
  );

  const statusSelections = useMemo(() => {
    if (!canEndSchedule) return [];
    if (!original) return [];

    return STATUS_SELECTIONS.filter(
      (selection) =>
        selection.value === 'Inactive' || selection.value === original.status,
    );
  }, [canEndSchedule, original]);

  const statusSelection = useMemo(
    () =>
      STATUS_SELECTIONS.find((selection) => selection.value === draft.status),
    [draft.status],
  );

  const handleUpdateStatus = useCallback(
    (to: ISelectionListItem<IListItemLabel, InstanceScheduleStatus>) => {
      dispatch(editInstanceScheduleSlice.updateStatus(to.value));
    },
    [dispatch],
  );

  const facilitatorSelection = useMemo(() => {
    if (!draft.assignedToSelector) return;

    return FACILITATOR_SELECTIONS.find(
      (item) => item.id === draft.assignedToSelector,
    );
  }, [draft.assignedToSelector]);

  const handleUpdateFacilitator = useCallback(
    (to: ISelectionListItem<IListItemLabel, 'Manager' | 'LineManager'>) => {
      if (!to?.value) return;
      dispatch(editInstanceScheduleSlice.updateFacilitator(to.value));
    },
    [dispatch],
  );

  const duePeriodSelection = useMemo(() => {
    if (!draft.dueDateExpression) return;

    return DUE_PERIOD_SELECTIONS.find(
      (item) => item.value === draft.dueDateExpression,
    );
  }, [draft.dueDateExpression]);

  const handleUpdateApplyToExistingMembers = useCallback(
    (to: boolean) => {
      dispatch(editInstanceScheduleSlice.updateApplyToExistingMembers(to));
    },
    [dispatch],
  );

  const handleUpdateStartDate = useCallback(
    (to: string) => dispatch(editInstanceScheduleSlice.updateStartDate(to)),
    [dispatch],
  );

  const handleUpdateEndDate = useCallback(
    (to: string) => dispatch(editInstanceScheduleSlice.updateEndDate(to)),
    [dispatch],
  );

  const handleUpdateDuePeriod = useCallback(
    (
      to: ISelectionListItem<IListItemLabel, InstanceScheduleDueDateExpression>,
    ) => {
      if (!to?.value) return;
      dispatch(editInstanceScheduleSlice.updateDueDateExpression(to.value));
    },
    [dispatch],
  );

  const handleUpdateReportingDateExpression = useCallback(
    (to: string) =>
      dispatch(editInstanceScheduleSlice.updateReportingDateExpression(to)),
    [dispatch],
  );

  const dayOfWeekSelection = useMemo(() => {
    if (!draft.reportingDateExpression) return;
    return DAY_OF_WEEK_SELECTIONS.find(
      (item) => item.value === draft.reportingDateExpression,
    );
  }, [draft.reportingDateExpression]);

  const handleUpdateDayOfWeek = useCallback(
    (to: ISelectionListItem<IListItemLabel, string>) => {
      if (!to?.value) return;
      dispatch(
        editInstanceScheduleSlice.updateReportingDateExpression(to.value),
      );
    },
    [dispatch],
  );

  const distributionListText = useMemo(() => {
    if (draft.subjectType === 'Person') return 'Distribution List (People)';
    if (draft.subjectType === 'Team') return 'Distribution List (Teams)';
    return 'Distribution List';
  }, [draft.subjectType]);

  const entityLabel = useMemo(() => {
    if (draft.subjectType === 'Person') return 'people';
    if (draft.subjectType === 'Team') return 'teams';
    return 'members';
  }, [draft.subjectType]);

  const isAddedToDistributionList =
    draft.frequency === 'ContinuousExcludingExisting' ||
    draft.frequency === 'ContinuousIncludingExisting';

  return (
    <div css={styles.base}>
      <div css={styles.row}>
        <IconWrapper icon={'message'} style={styles.field}>
          <TextField
            id={'Name'}
            label={'Name'}
            value={draft.name}
            isEnabled={canEdit}
            onChange={handleUpdateName}
            error={fieldErrors.name}
          />
        </IconWrapper>
      </div>
      <div css={styles.row}>
        <IconWrapper icon={'document'} style={styles.field}>
          <TextFieldDropdown
            error={fieldErrors.template}
            id={'formTemplate'}
            label={`${formLabel} Type`}
            value={formTemplateSelection}
            isEnabled={canEdit}
            selections={formTemplateSelections}
            onChange={handleUpdateFormTemplate}
            isLoading={isLoadingFormTemplates}
          />
        </IconWrapper>
      </div>
      <Transition.Collapse
        in={Boolean(draft.subjectType || isLineManagerEnabled)}
      >
        {Boolean(draft.subjectType || isLineManagerEnabled) && (
          <div css={styles.row}>
            {Boolean(draft.subjectType) && (
              <IconWrapper icon={'list'} style={styles.field}>
                <TextFieldDropdown
                  error={fieldErrors.distributionList}
                  id={'distributionList'}
                  label={distributionListText}
                  value={distributionListSelection}
                  isEnabled={canEdit}
                  selections={distributionListSelections}
                  onChange={handleUpdateDistributionList}
                  isLoading={isLoadingDistributionLists}
                />
              </IconWrapper>
            )}
            {isLineManagerEnabled && (
              <IconWrapper icon={'face'} style={styles.field}>
                <TextFieldDropdown
                  id={'assignedToSelector'}
                  label={'Facilitator'}
                  value={facilitatorSelection}
                  isEnabled={canEdit}
                  selections={FACILITATOR_SELECTIONS}
                  onChange={handleUpdateFacilitator}
                />
              </IconWrapper>
            )}
          </div>
        )}
      </Transition.Collapse>
      <div css={styles.row}>
        <IconWrapper icon={'update'} style={styles.field}>
          <TextFieldDropdown
            id={'frequency'}
            label={'Schedule'}
            selections={FREQUENCY_SELECTIONS}
            value={frequencySelection}
            isEnabled={canEdit}
            onChange={handleUpdateFrequency}
            error={fieldErrors.frequency}
          />
        </IconWrapper>
        <Transition.Collapse in={isAddedToDistributionList}>
          {isAddedToDistributionList && (
            <div css={styles.switchField}>
              <Switch
                label={`Apply to existing ${entityLabel}?`}
                labelPosition={'start'}
                isChecked={draft.frequency === 'ContinuousIncludingExisting'}
                onChange={handleUpdateApplyToExistingMembers}
                isEnabled={canEdit}
                tooltip={`Existing ${entityLabel} in the distribution list will also have the ${formLabel} created for them, on the date selected as the effective date.`}
                style={css({})}
              />
            </div>
          )}
        </Transition.Collapse>
      </div>
      <div css={styles.row}>
        <IconWrapper icon={'dateRange'} style={styles.field}>
          <DatePicker
            id={'startDate'}
            isEnabled={canEdit}
            value={draft.startDate}
            label={isAddedToDistributionList ? 'Effective Date' : 'Start Date'}
            onChange={handleUpdateStartDate}
            minDate={tomorrow}
            error={fieldErrors.startDate}
            dataTest={'forms-formScheduleFields-startDateField'}
          />
        </IconWrapper>
        <IconWrapper icon={'dateRange'} style={styles.field}>
          <DatePicker
            id={'endDate'}
            isEnabled={canEdit || canEndSchedule}
            value={draft.endDate}
            label={'End Date'}
            onChange={handleUpdateEndDate}
            dataTest={'forms-formScheduleFields-endDateField'}
            error={fieldErrors.endDate}
          />
        </IconWrapper>
      </div>
      <div css={styles.row}>
        <IconWrapper icon={'notifications'} style={styles.field}>
          <TextFieldDropdown
            id={'due'}
            label={'Due'}
            selections={DUE_PERIOD_SELECTIONS}
            value={duePeriodSelection}
            isEnabled={canEdit}
            onChange={handleUpdateDuePeriod}
            error={fieldErrors.dueDateExpression}
          />
        </IconWrapper>
        {draft.frequency === 'OneOff' && (
          <IconWrapper icon={'dateRange'} style={styles.field}>
            <DatePicker
              id={'Meeting / Review Date'}
              isEnabled={canEdit}
              value={draft.reportingDateExpression}
              label={'Meeting / Review Date'}
              onChange={handleUpdateReportingDateExpression}
              minDate={tomorrow}
            />
          </IconWrapper>
        )}
        {draft.frequency === 'Weekly' && (
          <IconWrapper icon={'update'} style={styles.field}>
            <TextFieldDropdown
              id={'dayOfWeek'}
              isEnabled={canEdit}
              value={dayOfWeekSelection}
              label={'Meeting / Review Day'}
              selections={DAY_OF_WEEK_SELECTIONS}
              onChange={handleUpdateDayOfWeek}
            />
          </IconWrapper>
        )}
      </div>
      <div css={styles.row}>
        <IconWrapper icon={'settings'} style={styles.field}>
          <TextFieldDropdown
            id={'status'}
            label={'Status'}
            selections={statusSelections}
            value={statusSelection}
            isEnabled={canEndSchedule}
            onChange={handleUpdateStatus}
          />
        </IconWrapper>
        {!isNew && (
          <IconWrapper icon={'dateRange'} style={styles.field}>
            <DatePicker
              id={'nextRunDate'}
              label={'Next Run Date'}
              value={draft.nextRunAt}
              isEnabled={false}
            />
          </IconWrapper>
        )}
      </div>
      {!isNew && (
        <div css={styles.row}>
          <IconWrapper icon={'face'} style={styles.field}>
            <TextField
              id={'updatedBy'}
              label={'Last Updated By'}
              value={original?.updatedBy.name}
              isEnabled={false}
            />
          </IconWrapper>
          <IconWrapper icon={'dateRange'} style={styles.field}>
            <DatePicker
              id={'updatedAt'}
              label={'Last Updated At'}
              value={original?.updatedAt}
              isEnabled={false}
            />
          </IconWrapper>
        </div>
      )}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    gap: 10,
    padding: '15px 5px',
  }),
  field: css({
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  }),
  switchField: css({
    display: 'flex',
    flex: 1,
    alignItems: 'flex-end',
    minHeight: 50,
  }),
  row: css({
    display: 'flex',
    gap: 15,
    alignItems: 'flex-start',
    ':hover': {
      backgroundColor: color.format(-0.1),
      margin: '-7px -15px',
      padding: '7px 15px',
    },
  }),
};
