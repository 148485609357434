/** @jsxImportSource @emotion/react */
import { IDataGridProps } from './DataGrid.tsx';

export interface IDataGridInfiniteScrollBehaviorProps {
  isLoadingPage?: boolean;
  loadedPage?: number;
  onLoadPage?: () => void;
}

/**
 * Provides a DataGrid with "infinite scrolling" behavior.
 */
export const DataGridInfiniteScrollBehavior = (
  DataGrid: React.FC<IDataGridProps>,
): React.FC<IDataGridInfiniteScrollBehaviorProps & IDataGridProps> =>
  function InfiniteDataGrid({
    isLoadingPage,
    isLoading,
    hasNextPage,
    onLoadPage,
    ...rest
  }) {
    const canLoadMore = hasNextPage && !isLoadingPage && !isLoading;
    const isLoadingMore = isLoadingPage && !isLoading ? 'MORE' : isLoading;
    const onLoadMore = canLoadMore ? onLoadPage : undefined;

    return (
      <DataGrid
        {...rest}
        hasNextPage={hasNextPage}
        isLoading={isLoadingMore}
        onLoadMore={onLoadMore}
        onLoadMoreInView={onLoadPage}
      />
    );
  };
