/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { Text } from '../Text/Text.tsx';
import { IActionBarButton, ActionBar } from '../ModalDialog/index.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';

export interface ITitledDialogProps {
  title: string;
  children: ReactNode;
  details?: ReactNode;
  actions?: IActionBarButton[];
  style?: SerializedStyles;
}

/**
 * A dialog component with a styled title and support for action buttons (i.e. OK / Cancel).
 */
export const TitledDialog: React.FC<ITitledDialogProps> = ({
  title,
  children,
  details,
  actions = [],
  style,
}) => (
  <div css={[styles.base, style]}>
    <div css={styles.header}>
      <div css={styles.title}>
        <Text size={48} color={color.format(-0.2)} weight={FontWeight.light}>
          {title}
        </Text>
      </div>
      {details && <div css={styles.details}>{details}</div>}
    </div>
    <div css={styles.body}>{children}</div>
    {Boolean(actions.length) && (
      <ActionBar actions={actions} style={styles.actions} />
    )}
  </div>
);

const styles = {
  base: css({
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  }),
  header: css({
    height: 96,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'stretch',
  }),
  title: css({
    flex: '1',
    margin: '20px 0 0 34px',
  }),
  details: css({
    margin: '30px 33px 0 0',
  }),
  body: css({
    display: 'flex',
    padding: '0 33px 33px 33px',
    flexGrow: 1,
  }),
  actions: css({
    padding: '0 0 3px 0',
  }),
};
