/** @jsxImportSource @emotion/react */
import { useShellSelector } from '../../redux/store.ts';
import { SheetError } from '../SheetError/SheetError.tsx';
import { SheetAudit } from './SheetAudit.tsx';

export const SheetAuditContainer: React.FC = () => {
  const audit = useShellSelector((state) => state.audit);

  return audit.isError ? (
    <SheetError
      isSpinning={audit.isLoading}
      message={`There was a problem retrieving your data. You may not have permission to view this.`}
      detail={`Please contact your administrator if you think you are seeing this in error or would like to change your permissions.`}
    />
  ) : (
    <SheetAudit logEntries={audit.logEntries} isSpinning={audit.isLoading} />
  );
};
