import { SimpleEntity } from '@se/data/types.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import {
  IRecursiveFetchAllOptions,
  recursiveFetchAll,
} from '@seeeverything/ui.util/src/graphql/graphql/recursiveFetchAll.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import gql from 'graphql-tag';
import { combineEpics, StateObservable } from 'redux-observable';
import { filter, mergeMap, Observable } from 'rxjs';
import { IDesignerTemplate } from '../model/types.ts';
import * as formsDesignerDropdownsSlice from './formsDesignerDropdownsSlice.ts';
import {
  GlobalFormsDesignerEpicDependencies,
  GlobalFormsDesignerState,
} from './store.ts';

export const dropdownsEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsDesignerState
>(loadDesignerTemplatesPageEpic);

function loadDesignerTemplatesPageEpic(
  action$: Observable<ReduxAction>,
  _: StateObservable<GlobalFormsDesignerState>,
  { client }: GlobalFormsDesignerEpicDependencies,
) {
  type Response = {
    forms: {
      formsDesignerTemplates: {
        pageInfo: {
          hasNextPage: boolean;
          currentPage: number;
        };
        nodes: Array<{
          id: string;
          name: string;
          category: SimpleEntity;
        }>;
      };
    };
  };

  const resolveHasNextPageForDesignerTemplates = (response: Response) =>
    Boolean(response?.forms.formsDesignerTemplates.pageInfo?.hasNextPage);

  return action$.pipe(
    filter(formsDesignerDropdownsSlice.loadDesignerTemplatesPage.match),
    mergeMap(async () => {
      const pageSize = 100;
      const page = 1;

      const fetchOptions: IRecursiveFetchAllOptions = {
        client,
        query: pagedGqlQueryLoadDesignerTemplates,
        pageSize,
      };

      try {
        const responsePages = await recursiveFetchAll(
          fetchOptions,
          resolveHasNextPageForDesignerTemplates,
          page,
          'network-only',
        );

        const designerTemplates = responsePages.reduce<IDesignerTemplate[]>(
          (acc, val) =>
            val ? acc.concat(val.forms.formsDesignerTemplates.nodes) : acc,
          [],
        );

        const formattedTemplates = designerTemplates.map((template) => ({
          id: template.id,
          value: template.id,
          icon: Icons.document,
          content: {
            text: template.name,
            description: template.category.name,
          },
        }));

        return formsDesignerDropdownsSlice.loadedDesignerTemplatesPage(
          formattedTemplates,
        );
      } catch (error) {
        log.error(
          new Error(`Unable to query designer dropdowns - ${error.message}`),
        );
        return formsDesignerDropdownsSlice.loadedDesignerTemplatesPage([]);
      }
    }),
  );
}

const pagedGqlQueryLoadDesignerTemplates = gql`
  query DesignerTemplates($pageNumber: Int, $pageSize: Int) {
    forms {
      formsDesignerTemplates(
        pagination: { pageNumber: $pageNumber, size: $pageSize }
        orderBy: [{ fieldName: "name", direction: Ascending }]
      ) {
        pageInfo {
          hasNextPage
          currentPage
        }
        nodes {
          id
          name
          category {
            id
            name
          }
        }
      }
    }
  }
`;
