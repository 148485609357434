/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Skeleton } from '@seeeverything/ui.primitives/src/components/Skeleton/Skeleton.tsx';

/**
 * Skeleton for loading BFL question selection.
 */
export const QuestionsSelectionSkeleton: React.FC = () => (
  <div css={styles.base}>
    {Array.from({ length: 12 }).map((_, i) => (
      <div key={i} style={{ display: 'flex', margin: '11px 0' }}>
        <Skeleton variant={'rect'} height={20} width={20} />
        <Skeleton
          variant={'rect'}
          height={20}
          style={{ flex: 1, marginLeft: 13 }}
        />
      </div>
    ))}
  </div>
);

const styles = {
  base: css({
    padding: 12,
    display: 'flex',
    flexDirection: 'column',
    height: '40vh',
    overflow: 'hidden',
  }),
};
