/** @jsxImportSource @emotion/react */
import { AutomatedActionsConfiguration } from '@seeeverything/ui.forms/src/components/AutomatedActionsConfiguration/AutomatedActionsConfiguration.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useShellSelector } from '../../redux/store.ts';
import { SheetLayout } from '../SheetLayout/index.ts';

export const SheetAutomatedActions: React.FC = () => {
  const showAll = useShellSelector(
    (state) => state.automatedActionConfiguration.showAll,
  );

  return (
    <SheetLayout
      overrideNoScrollPanel={true}
      title={'Configure Smart Actions'}
      moreItems={[
        {
          id: 'AUTOMATED_ACTIONS_SHOW_HISTORICAL',
          icon: Icons.history,
          content: { text: 'Include historical smart actions?' },
          statusIcon: Icons.tickDone,
          statusIconColor: {
            default: showAll ? COLORS.GREEN_TICK : color.format(-0.05),
            selected: showAll ? COLORS.GREEN_TICK : color.format(-0.05),
          },
        },
      ]}
    >
      <AutomatedActionsConfiguration />
    </SheetLayout>
  );
};
