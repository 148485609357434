/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { IDataGridColumn } from '@seeeverything/ui.primitives/src/components/DataGrid/types.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { InputAdornment } from '@seeeverything/ui.primitives/src/components/InputAdornment/InputAdornment.tsx';
import { SkeletonDetailedItemsList } from '@seeeverything/ui.primitives/src/components/SkeletonDetailedItemsList/SkeletonDetailedItemsList.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useGraphQL } from '@seeeverything/ui.util/src/graphql/GraphQLProvider.tsx';
import { ascend, sortWith } from 'ramda';
import { useCallback, useMemo, useState } from 'react';
import { usePeopleQuery } from '../../../query/usePeopleQuery.ts';
import { PeopleColumnFilter } from '../../../types/types.ts';

export type DataGridPersonFilterProps = {
  column: IDataGridColumn;
  gridId: string;
  filters: PeopleColumnFilter[];
  onColumnFilterSelection: (
    gridId: string,
    columnId: string,
    filter: PeopleColumnFilter,
    to: boolean,
  ) => void;
  onColumnFilterClearAll: (gridId: string, columnId: string) => void;
};

export const DataGridPersonFilter: React.FC<DataGridPersonFilterProps> = ({
  column,
  gridId,
  filters,
  onColumnFilterClearAll,
  onColumnFilterSelection,
}) => {
  const canFilter = column.isFilterable === true && column.type === 'PEOPLE';

  const formatPersonName = (person: PeopleColumnFilter) =>
    `${person.value.firstName} ${person.value.lastName}`.trim();

  const sortFilters = sortWith<PeopleColumnFilter>([
    ascend((person) => formatPersonName(person)),
  ]);

  const [search, setSearch] = useState<string>();
  const [initialFilters] = useState<PeopleColumnFilter[]>(sortFilters(filters));

  const { client } = useGraphQL();

  const { people, isFetchingPeople } = usePeopleQuery(client, {
    inputFilter: search,
    isEnabled: true,
    includeInactive: true,
  });

  const displayList = useMemo(() => {
    if (isFetchingPeople) return;

    const list: PeopleColumnFilter[] = [];

    if (!search) list.push(...initialFilters);

    list.push(
      ...people
        .filter(
          (person) => !list.some((filter) => filter.value.id === person.id),
        )
        .map(
          (person): PeopleColumnFilter => ({
            type: 'PERSON',
            value: person.value,
          }),
        ),
    );
    return list;
  }, [initialFilters, isFetchingPeople, people, search]);

  const handleClearColumnFilters = useCallback(() => {
    setSearch(undefined);
    onColumnFilterClearAll(gridId, column.id);
  }, [column.id, gridId, onColumnFilterClearAll]);

  const handleSearchChange = useCallback((to: string) => {
    setSearch(to);
  }, []);

  const handleColumnFilterSelection = useCallback(
    (person: PeopleColumnFilter, isSelected: boolean) => {
      const exists = filters.some(
        (filter) => filter.value.id === person.value.id,
      );

      if (exists && isSelected) return;
      if (!exists && !isSelected) return;

      onColumnFilterSelection(gridId, column.id, person, isSelected);
    },
    [column.id, filters, gridId, onColumnFilterSelection],
  );

  const elFiltersList = useMemo(
    () =>
      !isFetchingPeople && (
        <>
          {!displayList?.length && (
            <Text css={styles.emptyText} color={color.format(-0.6)}>
              {search
                ? 'There are no results available. Update or remove your search to get more results.'
                : 'No filters available.'}
            </Text>
          )}
          {displayList?.map((person) => {
            const isSelected = filters.some(
              ({ value }) => value === person.value,
            );
            const showTick = isSelected;
            const tickColor = isSelected
              ? COLORS.GREEN_TICK
              : color.format(-0.2);
            return (
              <Button
                key={`Filter-${person.value.id}`}
                onClick={() => handleColumnFilterSelection(person, !isSelected)}
              >
                <div css={styles.itemButton}>
                  <Icons.filterList />
                  <Text
                    color={
                      person.value.isActive
                        ? color.format(-0.6)
                        : color.format(-0.4)
                    }
                    cursor={'inherit'}
                    selectable={false}
                    ellipsis={true}
                    italic={!person.value.isActive}
                    tooltip={formatPersonName(person)}
                    size={15}
                  >
                    {formatPersonName(person)}
                  </Text>
                  {showTick && (
                    <div css={styles.tick}>
                      <Icons.tickDone fill={tickColor} />
                    </div>
                  )}
                </div>
              </Button>
            );
          })}
        </>
      ),
    [
      displayList,
      filters,
      handleColumnFilterSelection,
      isFetchingPeople,
      search,
    ],
  );

  if (!canFilter) return;

  return (
    <div css={styles.group}>
      <div css={styles.title}>
        <Text
          size={13}
          selectable={false}
          uppercase={true}
          weight={700}
          color={color.format(-0.4)}
        >
          {'Filters'}
        </Text>
        {Boolean(filters.length) && (
          <div css={styles.clearSelections}>
            <Button
              onClick={handleClearColumnFilters}
              style={{
                margin: '-5px -10px -5px -5px',
                padding: '5px 10px 5px 5px',
              }}
            >
              <div css={styles.clearButton}>
                <Icons.clear size={13} />
                <Text
                  color={COLORS.BLUE}
                  uppercase={true}
                  cursor={'pointer'}
                  size={12}
                  weight={700}
                  align={'right'}
                >
                  {'Clear Filters'}
                </Text>
              </div>
            </Button>
          </div>
        )}
      </div>
      <div css={styles.searchBox}>
        <TextField
          id={`${column.id}-search`}
          variant={'outlined'}
          InputProps={{
            autoFocus: true,
            startAdornment: (
              <InputAdornment position={'start'}>
                <Icons.search fill={color.format(-0.18)} />
              </InputAdornment>
            ),
          }}
          onChange={handleSearchChange}
          placeholder={'Search...'}
          value={search}
        />
      </div>
      <div css={styles.filterScrollContainer}>
        {elFiltersList}
        {isFetchingPeople && <SkeletonDetailedItemsList />}
      </div>
    </div>
  );
};

const styles = {
  title: css({
    display: 'flex',
    flexDirection: 'row',
    padding: '5px 10px',
    backgroundColor: color.format(-0.1),
    borderBottom: `solid 1px ${color.format(-0.05)}`,
    alignItems: 'center',
  }),
  group: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  itemButton: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
    padding: '5px 10px',
    minHeight: 28,
  }),
  searchBox: css({
    padding: '5px 10px',
  }),
  filterScrollContainer: css({
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 416,
    overflowY: 'auto',
  }),
  tick: css({
    marginLeft: 'auto',
  }),
  clearSelections: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    marginLeft: 'auto',
  }),
  clearButton: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    alignItems: 'center',
    justifyContent: 'flex-end',
  }),
  emptyText: css({
    padding: 10,
  }),
};
