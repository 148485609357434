/** @jsxImportSource @emotion/react */
import { useCallback } from 'react';
import { css } from '@emotion/react';
import { TitledDialog } from '@seeeverything/ui.primitives/src/components/TitledDialog/TitledDialog.tsx';
import { DateRangeSelector } from './DateRangeSelector.tsx';
import {
  confirmDateRange,
  dateRangeRequired,
  cancelDateRange,
  changeDateRange,
} from '../../redux/sheets/actions.ts';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';

export const DateRangeDialogContainer: React.FC = () => {
  const dispatch = useShellDispatch();
  const startDate = useShellSelector((state) => state.sheets.dateRange.start);
  const endDate = useShellSelector((state) => state.sheets.dateRange.end);
  const showStartDateValidation = useShellSelector(
    (state) => state.sheets.dateRange.showStartValidation,
  );
  const showEndDateValidation = useShellSelector(
    (state) => state.sheets.dateRange.showEndValidation,
  );
  const maxAllowedDays = useShellSelector(
    (state) =>
      state.tenantState.tenant?.configuration.reportingDates?.maxHistoricalDays,
  );

  const cancel = useCallback(() => {
    dispatch(cancelDateRange('DASHBOARDS'));
  }, [dispatch]);

  const confirm = useCallback(() => {
    if (!startDate) dispatch(dateRangeRequired('DASHBOARDS', 'START_DATE'));
    if (!endDate) dispatch(dateRangeRequired('DASHBOARDS', 'END_DATE'));

    if (!startDate && !endDate) return;
    dispatch(confirmDateRange('DASHBOARDS'));
  }, [dispatch, endDate, startDate]);

  const handleDateChange = useCallback(
    (id: 'START' | 'END', to: string) => {
      dispatch(changeDateRange(id, to, 'DASHBOARDS'));
    },
    [dispatch],
  );

  return (
    <TitledDialog
      title={'Date Range'}
      actions={[
        { id: 'Cancel', label: 'Cancel', isEnabled: true, onAction: cancel },
        { id: 'Confirm', label: 'Confirm', isEnabled: true, onAction: confirm },
      ]}
      style={styles.base}
    >
      <DateRangeSelector
        endDate={endDate}
        maxAllowedDays={maxAllowedDays}
        onChange={handleDateChange}
        showEndDateValidation={showEndDateValidation}
        showStartDateValidation={showStartDateValidation}
        startDate={startDate}
      />
    </TitledDialog>
  );
};

const styles = {
  base: css({
    width: 475,
    height: 300,
  }),
};
