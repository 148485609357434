import { file as fileUtil } from '@seeeverything/ui.util/src/file/index.ts';
import { IFormsDesignerRejectedFile } from './types.digitalContent.ts';

export const rejectedFileMetadata =
  (maxSize: number) =>
  (file: File): IFormsDesignerRejectedFile => ({
    name: file.name,
    extension: fileUtil.getExtension(file.name, undefined),
    size: file.size,
    displaySize: fileUtil.getReadableSize(file.size),
    reason: file.size > maxSize ? 'size' : 'type',
  });

export const uploadedFileMetadata = (id: string, file: File) => ({
  ...coreFileMetadata(id, file),
  canDelete: true,
  canDownload: false,
});

export const uploadFileMetadata = (id: string, file: File) => ({
  ...coreFileMetadata(id, file),
  canDelete: false,
  canDownload: false,
});

const coreFileMetadata = (id: string, file: File) => ({
  id,
  displaySize: fileUtil.getReadableSize(file.size),
  size: file.size,
  extension: fileUtil.getExtension(file.name),
  name: file.name,
  uploadedByName: '',
});
