/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import {
  CheckboxGroup,
  CheckboxGroupEvent,
} from '@seeeverything/ui.primitives/src/components/CheckboxRadioGroup/CheckboxGroup.tsx';
import { OutsideAlerter } from '@seeeverything/ui.primitives/src/components/OutsideAlerter/OutsideAlerter.tsx';
import { Popper } from '@seeeverything/ui.primitives/src/components/Popper/Popper.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { ObjectInspector } from '@seeeverything/ui.test/src/components/ObjectInspector/ObjectInspector.tsx';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { queryMutation$ } from '@seeeverything/ui.util/src/graphql/client/GraphQLClient.ts';
import { GqlRequestResponse } from '@seeeverything/ui.util/src/graphql/client/types.ts';
import { uniq } from 'ramda';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

type QueryItem = {
  name: string;
  type: string;
  text: string;
  variables: object;
  data: any;
  error: any;
};

/**
 * GraphQL section (in the debug panel).
 */
export const GraphQLDebug = () => {
  const [items, setItems] = useState<QueryItem[]>([]);

  useEffect(() => {
    const subscription = queryMutation$.subscribe((value) => {
      const payload = value.payload;

      const data =
        payload.kind === 'query' || payload.kind === 'mutation'
          ? payload.response.data
          : undefined;

      const error =
        payload.kind === 'queryWithError' ||
        payload.kind === 'mutationWithError'
          ? payload.error
          : undefined;

      setItems((current) =>
        current.concat({
          name: name(value),
          type: payload.kind,
          text: text(value),
          variables: value.payload.request.variables,
          data,
          error,
        }),
      );
    });

    return () => subscription.unsubscribe();
  }, []);

  const [filterOpen, setFiltersOpen] = useState(false);
  const showFilters = useCallback(() => setFiltersOpen(true), []);
  const hideFilters = useCallback(() => setFiltersOpen(false), []);

  const queryNames = useMemo(
    () => uniq(items.map((item) => item.name).sort()),
    [items],
  );

  const [selectedQueryNames, setSelectedQueryNames] = useState<string[]>([]);
  const onFiltersChanged = useCallback((e: CheckboxGroupEvent) => {
    setSelectedQueryNames((current) =>
      e.to ? current.concat(e.id) : current.filter((x) => x !== e.id),
    );
  }, []);

  const filteredItems = useMemo(
    () =>
      selectedQueryNames.length
        ? items.filter((item) => selectedQueryNames.includes(item.name))
        : items,
    [items, selectedQueryNames],
  );

  const clearAll = useCallback(() => {
    setItems([]);
    setSelectedQueryNames([]);
  }, []);

  const ref = useRef(null);

  return (
    <div ref={ref} css={styles.base}>
      <div css={styles.buttonOuter}>
        <Button onClick={clearAll} style={styles.button}>
          <Text
            color={COLORS.BLUE}
            align={'center'}
            cursor={'inherit'}
            css={css({ padding: 5 })}
          >
            {'Clear GraphQL'}
          </Text>
        </Button>
        <Button
          onClick={showFilters}
          style={styles.button}
          isEnabled={Boolean(items.length)}
        >
          <Text
            color={COLORS.BLUE}
            align={'center'}
            cursor={'inherit'}
            css={css({ padding: 5 })}
          >
            {'Query Filters'}
          </Text>
        </Button>
      </div>
      <Popper open={filterOpen} anchorEl={ref.current}>
        <OutsideAlerter
          onClickedOutside={hideFilters}
          ignoreClassNames={['div[role="tooltip"]']}
        >
          <div css={styles.popup}>
            <CheckboxGroup
              options={queryNames.map((name) => ({
                id: name,
                label: name,
                isEnabled: true,
              }))}
              value={selectedQueryNames.map((name) => ({
                id: name,
                checked: true,
              }))}
              onChange={onFiltersChanged}
              title={'Select Query Filters'}
            />
          </div>
        </OutsideAlerter>
      </Popper>
      <ObjectInspector name={'queries'} data={filteredItems} expandLevel={1} />
    </div>
  );
};

const styles = {
  base: css({ display: 'flex', flexDirection: 'column', gap: 10 }),
  popup: css({
    backgroundColor: 'white',
    border: 'solid 1px #808080',
    borderRadius: 5,
    padding: 20,
    maxHeight: 500,
    overflowY: 'auto',
  }),
  buttonOuter: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    flex: '1 1 auto',
    gap: 10,
  }),
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 1 auto',
    minHeight: 30,
    border: `solid 1px ${COLORS.BLUE}`,
    borderRadius: 3,
  },
};

const name = (item: GqlRequestResponse) => {
  if (item.payload.kind === 'query' || item.payload.kind === 'queryWithError')
    return (item.payload.request.query.definitions[0] as any).name.value;

  if (
    item.payload.kind === 'mutation' ||
    item.payload.kind === 'mutationWithError'
  )
    return (item.payload.request.mutation.definitions[0] as any).name.value;
};

const text = (item: GqlRequestResponse) => {
  if (item.payload.kind === 'query' || item.payload.kind === 'queryWithError')
    return item.payload.request.query.loc.source.body;

  if (
    item.payload.kind === 'mutation' ||
    item.payload.kind === 'mutationWithError'
  )
    return item.payload.request.mutation.loc.source.body;
};
