import { Person } from '@se/data/orgHierarchy/types.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import {
  IListItemLabel,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/client/types.ts';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import gql from 'graphql-tag';
import { PersonSelection } from './types.ts';

export const usePersonQuery = (
  client: IGraphQLClient,
  category: string,
  subjectRelation: 'OTHER' | 'SELF' | 'SUBJECT',
  personId?: string,
  secondaryText?: string,
) => {
  const { data, isFetching } = useQuery({
    placeholderData: keepPreviousData,
    enabled: Boolean(personId),
    queryKey: [
      {
        key: 'peopleDropdown.person',
        personId,
        category,
        secondaryText,
        subjectRelation,
      },
    ],
    queryFn: async ({ queryKey }) => {
      const queryArgs = queryKey[0];
      const response = await client.query<{
        orgHierarchy: { person: Person };
      }>({
        query: gql`
          query DropdownPerson($id: ID!) {
            orgHierarchy {
              person(id: $id) {
                id
                email
                firstName
                lastName
                isActive
                positionTitle
              }
            }
          }
        `,
        fetchPolicy: 'cache-first',
        variables: { id: queryArgs.personId },
      });

      const person = response.data.orgHierarchy.person;
      if (!person) return null;

      return toDropdownItem(
        {
          id: person.id,
          email: person.email,
          firstName: person.firstName,
          isActive: person.isActive,
          lastName: person.lastName,
          positionTitle: person.positionTitle,
          type: queryArgs.subjectRelation,
        },
        queryArgs.category,
        queryArgs.secondaryText,
      );
    },
  });

  return {
    data,
    isFetching,
  };
};

const toDropdownItem = (
  person: PersonSelection,
  category: string,
  secondaryText?: string,
): ISelectionListItem<IListItemLabel, PersonSelection> => ({
  id: person.id,
  icon: Icons.face,
  value: person,
  category,
  content: {
    description: [person.positionTitle, person.email]
      .filter(Boolean)
      .join(' - '),
    text: `${person.firstName} ${person.lastName}`.trim(),
    secondaryText: secondaryText && `(${secondaryText})`,
  },
});
