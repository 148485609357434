import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { IEntityConfigs } from '@seeeverything/ui.shell/src/api/api.queryBuilder/types.ts';

const SECTION_BUSINESS_ENTITY: ISelectionListItem = {
  id: 'sectionBusinessEntity',
  type: 'SECTION',
  content: 'Business Entity',
};

const toBusinessEntityItem = (configs: IEntityConfigs) => (key: string) => ({
  id: key,
  content: configs[key].label,
  icon: configs[key].icon,
});

export const businessEntityDropdownItems = (
  configs: IEntityConfigs,
): ISelectionListItem[] => {
  const selectionItems = Object.keys(configs)
    .filter((key) => key !== 'bu')
    .map(toBusinessEntityItem(configs));

  return selectionItems.length
    ? [SECTION_BUSINESS_ENTITY, ...selectionItems]
    : [];
};
