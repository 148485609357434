/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';

export interface IIconButtonsProps {
  dataTest?: string;
  isStateEmpty: boolean;
  isFocused: boolean;
  onClearClick?: () => void;
}

/**
 * The set of icon buttons on the right of the <QueryBuilder>.
 */
export const IconButtons: React.FC<IIconButtonsProps> = ({
  isStateEmpty,
  isFocused,
  onClearClick,
}) => (
  <div css={styles.base}>
    {isStateEmpty ? (
      <div>
        <Icons.search
          fill={isFocused ? color.format(-0.4) : color.format(0.8)}
        />
      </div>
    ) : (
      <div
        data-test={'shell-iconButtons-clearQueryBuilderButton'}
        onClick={onClearClick}
      >
        <Icons.clear
          fill={isFocused ? color.format(-0.4) : color.format(0.8)}
          cursor={'pointer'}
        />
      </div>
    )}
  </div>
);

const styles = {
  base: css({
    height: 24,
    marginTop: 2,
  }),
};
