/** @jsxImportSource @emotion/react */
import React from 'react';
import { Skeleton } from '../../Skeleton/Skeleton.tsx';

// Height of a skeleton item.
const SKELETON_HEIGHT = 24;
// Margin of an item in from its parent in the tree.
const ITEM_MARGIN = 26;
// Describes the skeleton tree as depths of each item where 0 is a top most parent, 1 is its child and so on.
const SKELETON_TREE_SHAPE_DEPTH = [0, 1, 2, 2, 1, 2];

export interface ITreeSkeletonProps {
  animation?: 'wave' | 'pulse';
}

/**
 * Loading Skeleton for a <TreeView />.
 */
export const TreeSkeleton: React.FC<ITreeSkeletonProps> = (props) => {
  const { animation = 'wave' } = props;

  const elSkeletonItems = SKELETON_TREE_SHAPE_DEPTH.map((depth, i) => (
    <TreeSkeletonItem
      key={`tree-skeleton-item-${i}`}
      depth={depth}
      animation={animation}
      height={SKELETON_HEIGHT}
    />
  ));

  return <>{elSkeletonItems}</>;
};

interface ITreeSkeletonItemProps {
  depth: number;
  height?: number;
  animation?: 'wave' | 'pulse';
}

/**
 * Item in a skeleton tree with a depth starting at 0 meaning top-most node in the tree.
 */
const TreeSkeletonItem: React.FC<ITreeSkeletonItemProps> = (props) => (
  <Skeleton
    animation={props.animation}
    height={props.height}
    style={{ marginLeft: props.depth * ITEM_MARGIN }}
  />
);
