import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { queryGrid } from '../../data/grid.ts';
import { GridQueryArguments } from '../../redux/dashboardGrids/dashboardGridsSlice.ts';

export const useGridQuery = (
  client: IGraphQLClient,
  args: GridQueryArguments,
) =>
  useInfiniteQuery({
    queryKey: [
      {
        key: `${module}-${args.dashboardType}-${args.gridId}`,
        clickThrough: args.clickThrough,
        dataSetId: args.dataSetId,
        entityId: args.entityId,
        entityType: args.entityType,
        filterStatements: args.filterStatements,
        gridId: args.gridId,
        includeFooter: args.includeFooter,
        module: args.module,
        orderBy: args.orderBy,
        pageSize: args.pageSize,
        reloadFlag: args.reloadFlag,
        templateId: args.templateId,
        timespan: args.timespan,
        tempFormStatusExcludeCompleted: args.tempFormStatusExcludeCompleted,
      },
    ],
    queryFn: async ({ pageParam, queryKey }) => {
      const {
        clickThrough,
        dataSetId,
        entityId,
        entityType,
        filterStatements,
        gridId,
        includeFooter,
        orderBy,
        pageSize,
        templateId,
        timespan,
        tempFormStatusExcludeCompleted,
      } = queryKey[0];

      return queryGrid(client, {
        clickThrough,
        dataSetId,
        entityId,
        entityType,
        filterStatements,
        gridId,
        includeFooter,
        orderBy,
        pagination: { pageSize, pageNumber: pageParam ?? 1 },
        templateId,
        timespan,
        tempFormStatusExcludeCompleted,
      });
    },
    getNextPageParam: (latestPage) =>
      latestPage ? latestPage.pagination?.currentPage + 1 : 1,
    enabled: args.isQueryEnabled,
    placeholderData: keepPreviousData,
    initialPageParam: 1,
  });
