// TODO: Merge with global locale.

export const locale = {
  defaultLabel: {
    section: 'Untitled',
    switchAnswer: 'Untitled',
    textField: 'Untitled',
    speechBlock: `
**Speech Block**

This typically contains something like an overview, to help guide users through the process of completing this document. This can also contain *markdown*, as well as [**hyperlinking (see this link for more details on Markdown)**](https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet).

To see the rendered Markdown, click the *Preview* button above.`,
    textBlock: `
**Text Block**

This is typically a question, or some helpful text to help guide users through the process of completing the next one or two question(s), or alternatively can be used for sub-headings. This can also contain *markdown*, as well as [**hyperlinking (see this link for more details on Markdown)**](https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet).

To see the rendered Markdown, click the *Preview* button above.`,
    options: 'Untitled',
    default: 'Untitled',
  },
};
