/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { ActionDialogV2Container } from '@seeeverything/ui.forms/src/components/ActionDialogV2/ActionDialogV2Container.tsx';
import { useLocalStorage } from '@seeeverything/ui.primitives/src/hooks/useLocalStorage.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/constants.ts';
import { StorageKey } from '@seeeverything/ui.util/src/storage/api.ts';
import { useCallback, useEffect } from 'react';
import { IQueryData } from '../../api/api.queryBuilder/types.ts';
import * as actions from '../../redux/app/actions.ts';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';
import { DashboardV2TemplatesByModule } from '../types.ts';
import { Shell } from './Shell.tsx';
import { ShellModalDialogContainer } from './components/ShellModalDialogContainer.tsx';
import { Splash } from './components/Splash.tsx';
import { SheetContentFactory } from './types.ts';

export interface IShellContainerProps {
  queryData: IQueryData;
  sheetContentFactory?: SheetContentFactory;
  dashboardV2Templates?: DashboardV2TemplatesByModule;
}

/**
 * The root application shell.
 */
export const ShellContainer: React.FC<IShellContainerProps> = ({
  queryData,
  sheetContentFactory,
  dashboardV2Templates,
}) => {
  const dispatch = useShellDispatch();
  const splash = useShellSelector(
    (state) => state.tenantState.tenant?.configuration?.splash,
  );
  const sheet = useShellSelector((state) => state.sheets.current);
  const isLoggingOut = useShellSelector(
    (state) => state.app.authState === 'LOGGING_OUT',
  );
  const isErrorState = useShellSelector(
    (state) => state.app.errorState === true,
  );
  const isAppInitializing = useShellSelector(
    (state) => !state.app.isInitialized,
  );

  const returnHome = useCallback(
    () => dispatch(actions.returnHome()),
    [dispatch],
  );

  const [isAuthenticated] = useLocalStorage(StorageKey.IsAuthenticated);

  useEffect(() => {
    if (isAuthenticated === null) dispatch(actions.logout());
  }, [dispatch, isAuthenticated]);

  const computedStyles = {
    base: css({
      position: 'absolute',
      inset: 0,
      backgroundColor: splash?.backgroundColor || COLORS.BLUE,
    }),
  };

  return (
    <div css={computedStyles.base}>
      {!isErrorState && splash && (
        <Splash
          showMask={!isLoggingOut && splash.maskEnabled !== false}
          maskOpacity={!sheet ? 0.7 : 1}
          background={splash.imageLocation}
          backgroundSize={splash.backgroundSize}
          backgroundPosition={splash.backgroundPosition}
          color={splash.maskColor}
          maskHeight={splash.maskHeight}
          opacity={splash.opacity}
          top={splash.top}
        />
      )}
      <Shell
        queryData={queryData}
        sheetContentFactory={sheetContentFactory}
        isEmpty={!sheet}
        isSheetsLoading={isAppInitializing}
        isLoggingOut={isLoggingOut}
        isErrorState={isErrorState}
        dashboardV2Templates={dashboardV2Templates}
        onHomeClick={returnHome}
      />
      <ShellModalDialogContainer />
      <ActionDialogV2Container />
    </div>
  );
};
