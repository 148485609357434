/** @jsxImportSource @emotion/react */
import { IconWrapper } from '@seeeverything/ui.primitives/src/components/IconWrapper/IconWrapper.tsx';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { TextFieldDropdown } from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';
import { css } from '@emotion/react';

export interface IOverwriteExistingProps {
  selectedTemplate?: ISelectionListItem;
  selectedTemplateErrorText?: string;
  onTemplateChange?: (to: ISelectionListItem) => void;
  templates?: ISelectionListItem[];
  templatesIsLoading?: boolean;
}

export const OverwriteExisting = ({
  onTemplateChange,
  selectedTemplate,
  selectedTemplateErrorText,
  templates,
  templatesIsLoading,
}: IOverwriteExistingProps) => (
  <div css={styles.bodyFull}>
    <IconWrapper icon={'document'} style={styles.bodyField}>
      <TextFieldDropdown
        id={'selectedTemplate'}
        value={selectedTemplate}
        label={'Existing Template'}
        onChange={onTemplateChange}
        error={selectedTemplateErrorText}
        selections={templates}
        isLoading={templatesIsLoading}
      />
    </IconWrapper>
  </div>
);

const styles = {
  bodyFull: css({
    width: '100%',
  }),
  bodyField: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'stretch',
    width: '100%',
    padding: 15,
    boxSizing: 'border-box',
  }),
};
