import { DashboardFilterStatement } from '@se/data/dashboards/query/gridFilters.ts';
import { DashboardTimespanVariable } from '@se/data/dashboards/query/types.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { OrderBy, PageInfoRequest } from '@seeeverything/ui.util/src/types.ts';
import gql from 'graphql-tag';
import { IGridDataPayload } from '../types.ts';
import { NotFoundError } from './errors.ts';
import { DashboardClickThrough } from './types.ts';

type ResponseDataRow = {
  key: string;
  tags: string[];
  entity: {
    id: string;
    type:
      | 'Person'
      | 'Team'
      | 'Form'
      | 'Goal'
      | 'Action'
      | 'Category'
      | 'Answer';
    label: string;
  };
  data: Array<{ key: string; value?: string }>;
};

type ResponseColumn = {
  key: string;
  maskIfSensitive: boolean;
};

type ResponseGrid = {
  rows: {
    pageInfo: {
      currentPage: number;
      hasNextPage: boolean;
      pageSize: number;
      totalCount: number;
    };
    nodes: ResponseDataRow[];
  };
  footer?: ResponseDataRow[];
  columns?: ResponseColumn[];
};

export interface IQueryGridOptions {
  clickThrough?: DashboardClickThrough;
  dataSetId: string;
  entityId: string;
  entityType: 'person' | 'team';
  filterStatements: DashboardFilterStatement[];
  gridId: string;
  includeFooter: boolean;
  orderBy: OrderBy[];
  pagination: PageInfoRequest;
  templateId: string;
  timespan: DashboardTimespanVariable;
  tempFormStatusExcludeCompleted: boolean;
}

export async function queryGrid(
  client: IGraphQLClient,
  options: IQueryGridOptions,
): Promise<IGridDataPayload> {
  const { entityId, entityType, templateId } = options;

  const grid =
    entityType === 'person'
      ? await personResponseGrid(client, options)
      : await teamResponseGrid(client, options);

  return {
    pagination: grid.rows.pageInfo,
    data: grid.rows.nodes.map((node) => ({
      id: node.key,
      tags: node.tags,
      entity: node.entity,
      data: node.data,
    })),
    entityType,
    entityId,
    templateId,
    footer: grid.footer?.map((node) => ({
      id: `footer-${node.key}`,
      tags: node.tags,
      entity: node.entity,
      data: node.data,
    })),
  };
}

const personResponseGrid = async (
  client: IGraphQLClient,
  options: IQueryGridOptions,
) => {
  const { pageNumber, pageSize } = options.pagination;

  const formInstanceStatus = options.tempFormStatusExcludeCompleted
    ? ({ include: [], exclude: ['Completed'] } as const)
    : undefined;

  const variables = {
    entityId: options.entityId,
    filterStatements: options.filterStatements,
    gridId: options.gridId,
    dataSetId: options.dataSetId,
    pageSize,
    pageNumber,
    templateId: options.templateId,
    orderBy: options.orderBy,
    clickThrough: options.clickThrough,
    timespan: options.timespan,
    formInstanceStatus,
  };

  const includeFooter = options.includeFooter && pageNumber === 1;

  const response = await client.query<{
    dashboards: { person: { grid: ResponseGrid } };
  }>({
    fetchPolicy: 'network-only',
    query: queryPersonGrid(includeFooter),
    variables,
  });

  if (!response.data.dashboards.person.grid)
    throw new NotFoundError('person', options.entityId);

  const rows = response.data.dashboards.person.grid.rows;
  const maskedColumns = response.data.dashboards.person.grid.columns
    ?.filter((column) => column.maskIfSensitive)
    .map((column) => column.key);

  return {
    ...response.data.dashboards.person.grid,
    rows: {
      ...rows,
      nodes: rows.nodes.map((node: ResponseDataRow) => ({
        ...node,
        data: node.data.map((data) => ({
          ...data,
          isMasked:
            node.tags?.includes('IsSensitive') &&
            maskedColumns?.includes(data.key),
        })),
      })),
    },
  };
};

const teamResponseGrid = async (
  client: IGraphQLClient,
  options: IQueryGridOptions,
) => {
  const { pageNumber, pageSize } = options.pagination;

  const formInstanceStatus = options.tempFormStatusExcludeCompleted
    ? ({ include: [], exclude: ['Completed'] } as const)
    : undefined;

  const variables = {
    entityId: options.entityId,
    filterStatements: options.filterStatements,
    gridId: options.gridId,
    dataSetId: options.dataSetId,
    pageSize,
    pageNumber,
    templateId: options.templateId,
    orderBy: options.orderBy,
    clickThrough: options.clickThrough,
    timespan: options.timespan,
    formInstanceStatus,
  };

  const includeFooter = options.includeFooter && pageNumber === 1;

  const response = await client.query<{
    dashboards: { team: { grid: ResponseGrid } };
  }>({
    fetchPolicy: 'network-only',
    query: queryTeamGrid(includeFooter),
    variables,
  });

  if (!response.data.dashboards.team.grid)
    throw new NotFoundError('team', options.entityId);

  const rows = response.data.dashboards.team.grid.rows;
  const maskedColumns = response.data.dashboards.team.grid.columns
    ?.filter((column) => column.maskIfSensitive)
    .map((column) => column.key);

  return {
    ...response.data.dashboards.team.grid,
    rows: {
      ...rows,
      nodes: rows.nodes.map((node: ResponseDataRow) => ({
        ...node,
        data: node.data.map((data) => ({
          ...data,
          isMasked:
            node.tags?.includes('IsSensitive') &&
            maskedColumns?.includes(data.key),
        })),
      })),
    },
  };
};

const gqlDataFragment = gql`
  fragment DataRow on DashboardGridRow {
    key
    tags
    entity {
      id
      type
      label
    }
    data {
      key
      value
    }
  }
`;

const queryPersonGrid = (includeFooter: boolean) => gql`
  query DashboardPersonGridData(
    $entityId: ID!
    $filterStatements: [DashboardFilterStatementInput]!
    $dataSetId: ID!
    $gridId: ID!
    $pageSize: Int!
    $pageNumber: Int!
    $templateId: ID!
    $orderBy: [OrderByInput!]
    $timespan: DashboardTimespanFilterInput
    $formInstanceStatus: DashboardFormInstanceStatusFilterInput
    $clickThrough: DashboardClickThroughInput
  ) {
    dashboards {
      person(id: $entityId) {
        grid(
          dataSetId: $dataSetId
          id: $gridId
          templateId: $templateId
          pagination: { size: $pageSize, pageNumber: $pageNumber }
          filterStatements: $filterStatements
          orderBy: $orderBy
          timespan: $timespan
          formInstanceStatus: $formInstanceStatus
          clickThrough: $clickThrough
        ) {
          rows {
            pageInfo {
              currentPage
              hasNextPage
              pageSize
              totalCount
            }
            nodes {
              ...DataRow
            }
          }
          ${includeFooter ? `footer { ...DataRow }` : ''}
          columns {
            key
            maskIfSensitive
          }
        }
      }
    }
  }
  ${gqlDataFragment}
`;

const queryTeamGrid = (includeFooter: boolean) => gql`
  query DashboardTeamGridData(
    $entityId: ID!
    $filterStatements: [DashboardFilterStatementInput]!
    $dataSetId: ID!
    $gridId: ID!
    $pageSize: Int!
    $pageNumber: Int!
    $templateId: ID!
    $orderBy: [OrderByInput!]
    $timespan: DashboardTimespanFilterInput
    $formInstanceStatus: DashboardFormInstanceStatusFilterInput
    $clickThrough: DashboardClickThroughInput
  ) {
    dashboards {
      team(id: $entityId) {
        grid(
          dataSetId: $dataSetId
          filterStatements: $filterStatements
          id: $gridId
          templateId: $templateId
          pagination: { size: $pageSize, pageNumber: $pageNumber }
          orderBy: $orderBy
          timespan: $timespan
          formInstanceStatus: $formInstanceStatus
          clickThrough: $clickThrough
        ) {
          rows {
            pageInfo {
              currentPage
              hasNextPage
              pageSize
              totalCount
            }
            nodes {
              ...DataRow
            }
          }
          ${includeFooter ? `footer { ...DataRow }` : ''}
          columns {
            key
            maskIfSensitive
          }
        }
      }
    }
  }
  ${gqlDataFragment}
`;
