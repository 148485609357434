/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { ActionBar } from '../ActionBar/ActionBar.tsx';
import { IActionBarButton } from '../ActionButtons/ActionButtons.tsx';

export interface IInnerDialogProps {
  actions?: IActionBarButton[];
  width: number;
  height: number;
  children: React.ReactNode;
}

export const InnerDialog: React.FC<IInnerDialogProps> = ({
  actions,
  width = 0,
  height = 0,
  children,
}) => {
  const actionBarHeight = actions?.length > 0 ? 48 : 0;

  const computedStyles = {
    content: css({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      backgroundColor: 'white',
      flex: '1 1 auto',
      justifyContent: 'stretch',
      maxHeight: height - actionBarHeight,
      overflowX: 'hidden',
      overflowY: 'auto',
      width,
    }),
  };

  return (
    <div css={styles.base}>
      <div css={computedStyles.content}>{children}</div>
      {actions?.length > 0 && <ActionBar actions={actions} />}
    </div>
  );
};

const styles = {
  base: css({
    flex: '1 1 auto',
    boxSizing: 'border-box',
    width: '100%',
    border: `solid 1px ${color.format(-0.2)}`,
    borderTop: 0,
    borderRadius: '0 0 4px 4px',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.17)',
  }),
};
