/** @jsxImportSource @emotion/react */
import React from 'react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { css } from '@emotion/react';
import { Spinner } from '@seeeverything/ui.primitives/src/components/Spinner/index.ts';
import { TextButton } from '@seeeverything/ui.primitives/src/components/TextButton/TextButton.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';

export interface ILoadMoreProps {
  onClick: () => void;
  isLoading?: boolean;
  loadMoreText?: string;
  icon?: string | false;
  hideBorderTop?: boolean;
}

export const LoadMore: React.FC<ILoadMoreProps> = ({
  icon = 'launch',
  isLoading = false,
  loadMoreText = 'Load more',
  onClick,
  hideBorderTop = false,
}) => {
  const styles = React.useMemo(
    () => ({
      base: css({
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        borderTop: hideBorderTop
          ? undefined
          : `solid 1px ${color.format(-0.1)}`,
        borderBottom: `solid 1px ${color.format(-0.1)}`,
        boxSizing: 'border-box',
      }),
      spinner: css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 32,
      }),
    }),
    [hideBorderTop],
  );

  return (
    <div css={styles.base}>
      {isLoading && (
        <div css={styles.spinner}>
          <Spinner />
        </div>
      )}
      {!isLoading && (
        <TextButton
          onClick={onClick}
          size={14}
          icon={icon ? Icons[icon] : undefined}
          iconSide={'RIGHT'}
        >
          {loadMoreText}
        </TextButton>
      )}
    </div>
  );
};
