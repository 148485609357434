/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CommonStyles } from '@seeeverything/ui.primitives/src/common/commonStyles.ts';
import { ContentPlaceholder } from '@seeeverything/ui.primitives/src/components/ContentPlaceholder/ContentPlaceholder.tsx';
import { useTrackInViewport } from '@seeeverything/ui.primitives/src/hooks/useTrackInViewport.ts';
import { propsAreEqualFastCompare } from '@seeeverything/ui.util/src/react/memoFastCompare.ts';
import { memo, useEffect, useState } from 'react';
import { DASHBOARD_INTERSECTION_OPTIONS } from '../common/constants.ts';
import { DashboardGridContainer } from '../components/DashboardGrid/DashboardGridContainer.tsx';
import { useDashboardsSelector } from '../redux/store.ts';

export interface IFactoryGridContainerProps {
  parentId?: string;
  id: string;
}

const View: React.FC<IFactoryGridContainerProps> = ({ parentId, id }) => {
  const [forwardedRef, inViewport] = useTrackInViewport({
    dataId: id,
    parentDataId: parentId,
    observerOptions: DASHBOARD_INTERSECTION_OPTIONS,
  });

  const [isInit, setIsInit] = useState(inViewport);

  useEffect(() => {
    if (isInit) return;
    if (!inViewport) return;
    setIsInit(true);
  }, [inViewport, isInit]);

  const scrollToDataId = useDashboardsSelector(
    (state) => state.scroll.containers.scrollPanel?.scrollToDataId,
  );

  return (
    <div
      id={id}
      css={
        scrollToDataId === id
          ? [styles.base, CommonStyles.ScrollPulse]
          : [styles.base]
      }
      data-id={id}
      parent-data-id={parentId}
      ref={forwardedRef}
    >
      {isInit ? (
        <DashboardGridContainer
          id={id}
          inViewport={inViewport}
          sheetType={'DASHBOARD'}
          dashboardType={'GRID'}
        />
      ) : (
        <div css={styles.loadingPlaceholder}>
          <ContentPlaceholder kind={'LIST'} emptyText={''} hintText={''} />
        </div>
      )}
    </div>
  );
};

const styles = {
  base: css({
    margin: '3px 30px 0px 35px',
  }),
  loadingPlaceholder: css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 300,
    width: '100%',
    textAlign: 'center',
    paddingTop: 50,
  }),
};

export const FactoryGridContainer = memo(View, propsAreEqualFastCompare);
