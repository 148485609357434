/** @jsxImportSource @emotion/react */
/**
 * See:
 *    https://github.com/xyc/react-inspector
 */
import { SerializedStyles } from '@emotion/react';
import { Inspector } from 'react-inspector';

export interface IObjectInspectorProps {
  data?: object;
  name?: string;
  expandLevel?: number;
  showNonenumerable?: boolean;
  expandPaths?: string | string[];
  style?: SerializedStyles;
  theme?: 'chromeLight' | 'chromeDark';
  isTable?: boolean;
}

/**
 * Views an Object as a visual tree.
 */
export const ObjectInspector: React.FC<IObjectInspectorProps> = ({
  name,
  data,
  expandLevel = 1,
  showNonenumerable = false,
  expandPaths,
  style,
  theme = 'chromeLight',
  isTable = false,
}) => (
  <div css={style}>
    <Inspector
      data={data}
      name={name}
      expandLevel={expandLevel}
      showNonenumerable={showNonenumerable}
      expandPaths={expandPaths}
      theme={theme}
      table={isTable}
    />
  </div>
);
