import { FormLineByIdExtended } from '../../components/FormDesignerContent/types.ts';
import {
  FormDesignerReduxDesignerState,
  FormsDesignerDesignerOptionsDeleteExistingOption,
} from './types.ts';

export function optionsDeleteOptionReducer(
  state: FormDesignerReduxDesignerState,
  action: FormsDesignerDesignerOptionsDeleteExistingOption,
) {
  if (!state.draft || !state.draft.designerLines) {
    return state;
  }

  return {
    ...state,
    draft: {
      ...state.draft,
      designerLines: deleteOption(
        state.draft.designerLines,
        action.payload.id,
        action.payload.optionId,
      ),
    },
  };
}

const deleteOption = (
  designerLines: FormLineByIdExtended,
  id: string | number,
  optionId: string | number,
): FormLineByIdExtended => {
  const options = { ...designerLines[id].optionItems.options };
  delete options[optionId];

  return {
    ...designerLines,
    [id]: {
      ...designerLines[id],
      optionItems: {
        ...designerLines[id].optionItems,
        options: { ...options },
      },
    },
  };
};
