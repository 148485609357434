import {
  AnalyticsAction,
  AnalyticsEvent,
} from '@seeeverything/ui.util/src/redux/analytics/types.ts';
import moment from 'moment';

export const calculateDateDuration = (startDate: string, endDate: string) => {
  const from = moment(startDate);
  const to = moment(endDate);

  return to.diff(from, 'days');
};

export const identifyEvent = (
  eventIdentifier: string,
  payload: object,
): AnalyticsEvent => ({
  action: AnalyticsAction.identify,
  eventIdentifier,
  payload,
});

export const trackEvent = (
  eventIdentifier: string,
  payload: object = {},
): AnalyticsEvent => ({
  action: AnalyticsAction.track,
  eventIdentifier,
  payload,
});
