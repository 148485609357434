/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { configurationQuery } from '@se/data/configuration/query/index.ts';
import { authApi } from '@seeeverything/ui.auth/src/api/api.ts';
import { LoadingFullScreen } from '@seeeverything/ui.auth/src/components/LoadingFullScreen/LoadingFullScreen.tsx';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { ErrorScreen } from '@seeeverything/ui.shell/src/components/ErrorScreen/ErrorScreen.tsx';
import { TenantSelection } from '@seeeverything/ui.shell/src/components/TenantSelection/TenantSelection.tsx';
import { SegmentProvider } from '@seeeverything/ui.util/src/analytics/SegmentProvider.tsx';
import { GraphQLProvider } from '@seeeverything/ui.util/src/graphql/GraphQLProvider.tsx';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import {
  DatadogApi,
  monitoring,
} from '@seeeverything/ui.util/src/monitoring/index.ts';
import { TenantConfiguration } from '@seeeverything/ui.util/src/redux/tenant/types.ts';
import { storageApi } from '@seeeverything/ui.util/src/storage/api.ts';
import { decodeBase64 } from '@seeeverything/ui.util/src/str/str.base64.ts';
import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { NextPageContext } from 'next';
import { useRouter } from 'next/router.js';
import { useCallback, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { db } from '../common/index.ts';
import { DebuggableApp } from '../components/App/DebuggableApp.tsx';
import { env } from '../env.ts';
import { store } from '../redux/configureStore.ts';
import { DateFilterType } from '../types.ts';
import NotAuthorized from './auth/401.tsx';

const queryClient = new QueryClient();

/**
 * Query-string props.
 */
export interface IHomeQueryProps {
  columnFilters?: string;
  columnSorts?: string;
  dateEnd?: string;
  dateFilter?: string;
  dateStart?: string;
  module?: ModuleType;
  path?: string;
  tenant?: string;
  /**
   * Version, for future backwards-compatibility.
   * `v` moniker is intentionally left short to keep URL path as short as possible.
   */
  v?: string;
}
export interface IHomeProps {
  query?: IHomeQueryProps;
}

const initializeDatadog = () => {
  if (env.DD_RUM_ENABLED())
    return new DatadogApi()
      .init({
        applicationId: env.DD_APP_ID(),
        clientToken: env.DD_CLIENT_TOKEN(),
        service: env.DD_SERVICE(),
        version: env.VERSION(),
        env: env.DD_ENVIRONMENT_LABEL(),
        trackUserInteractions: env.DD_TRACK_INTERACTIONS(),
      })
      .enableCrashReporting()
      .enableRum();
};

export default function Home({ query }: IHomeProps) {
  const router = useRouter();
  const [error, setError] = useState(false);
  const [isTenantSelected, setIsTenantSelected] = useState(false);
  const [isRememberToggleSelected, setIsRememberToggleSelected] =
    useState(true);
  const [tenantConfigs, setTenantConfigs] = useState<
    Record<string, TenantConfiguration>
  >({});
  const [userTenantIds, setUserTenantIds] = useState<string[]>([]);
  const [selectedTenant, setSelectedTenant] = useState<ISelectionListItem>();
  const [isInit, setIsInit] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [path, setPath] = useState<string>();
  const [dateFilter, setDateFilter] = useState<DateFilterType>();
  const [columnSorts, setColumnSorts] = useState<Record<string, string>>();
  const [columnFilters, setColumnFilters] =
    useState<Record<string, string[]>>();
  const [module, setModule] = useState<ModuleType>();

  const { client, batchClient, uploadClient } = db;

  const queryTenant = query?.tenant;
  const queryModule = query?.module;
  const queryPath = query?.path;

  useEffect(() => {
    if (isInit) return;
    (async () => {
      setIsAuthenticated(await authApi.getIsAuthenticated());

      try {
        const datadogMonitor = initializeDatadog();
        monitoring.create([datadogMonitor]);

        const tenantIds = Object.keys(await authApi.getTenants(client)) || [];

        const configsResponse =
          await configurationQuery.getTenantConfigurations(
            batchClient,
            tenantIds,
          );
        if (!configsResponse.isSuccess) return;

        const configs = configsResponse.data;

        const queryTenantValid = Boolean(tenantIds?.includes(queryTenant));
        const queryValid = Boolean(
          queryTenantValid &&
            configs[queryTenant].modules?.[queryModule]?.isEnabled,
        );

        if (queryValid) {
          storageApi.tenant.set(queryTenant);
          storageApi.timezone.set(configs[queryTenant].timezone);
          storageApi.module.set(queryModule);
          setIsTenantSelected(true);
          setSelectedTenant({ id: queryTenant });
          setModule(queryModule);
          setPath(queryPath);

          const dateFilterId =
            query?.dateFilter &&
            decodeBase64(decodeURIComponent(query.dateFilter));

          const customStart =
            query?.dateStart &&
            decodeBase64(decodeURIComponent(query.dateStart));

          const customEnd =
            query?.dateEnd && decodeBase64(decodeURIComponent(query.dateEnd));

          const queryDateFilter = dateFilterId
            ? { id: dateFilterId, customStart, customEnd }
            : undefined;

          if (queryDateFilter) setDateFilter(queryDateFilter);

          setColumnFilters(
            query?.columnFilters
              ? JSON.parse(
                  decodeBase64(decodeURIComponent(query.columnFilters)),
                )
              : undefined,
          );

          setColumnSorts(
            query?.columnSorts
              ? JSON.parse(decodeBase64(decodeURIComponent(query.columnSorts)))
              : undefined,
          );

          setIsInit(true);
          return;
        }

        const useRememberedTenant = storageApi.getTenantRememberSelection();
        const rememberedTenantId = storageApi.tenant.getLocalStorage();
        const rememberedTenantIsValid = Boolean(
          useRememberedTenant &&
            rememberedTenantId &&
            tenantIds.includes(rememberedTenantId),
        );

        if (rememberedTenantIsValid) {
          storageApi.tenant.set(rememberedTenantId);
          storageApi.timezone.set(configs[rememberedTenantId].timezone);
          storageApi.module.set(storageApi.module.getLocalStorage());
          setIsTenantSelected(true);
          setSelectedTenant({ id: rememberedTenantId });
          setIsInit(true);
          return;
        }

        if (tenantIds.length === 1) {
          const selectedTenantId = tenantIds[0];
          storageApi.tenant.set(selectedTenantId);
          storageApi.timezone.set(configs[selectedTenantId].timezone);
          setIsTenantSelected(true);
          setSelectedTenant({ id: selectedTenantId });
          setIsInit(true);
          return;
        }

        // Multi-tenant + tenant selection required.
        const initialTenantId = tenantIds[0];

        const defaultSelection = configs[initialTenantId]
          ? toSelectionListItem(initialTenantId, configs[initialTenantId])
          : undefined;

        setIsRememberToggleSelected(useRememberedTenant ?? true);
        setSelectedTenant(defaultSelection);
        setTenantConfigs(configs);
        setUserTenantIds(tenantIds);
        setIsInit(true);
      } catch (err) {
        log.error(
          new Error(`An error occurred during app start-up - ${err.message}`),
        );
        setError(true);
      }
    })();
  }, [batchClient, client, isInit, queryPath, queryModule, queryTenant, query]);

  const handleNextClicked = useCallback(() => {
    const tenantId = selectedTenant?.id?.toString();
    const remember = isRememberToggleSelected;

    storageApi.tenant.set(tenantId);
    storageApi.timezone.set(tenantConfigs[tenantId].timezone);
    setIsTenantSelected(true);
    storageApi.setTenantRememberSelection(remember);
  }, [isRememberToggleSelected, tenantConfigs, selectedTenant?.id]);

  if (error) return <ErrorScreen generalError={true} />;

  if (!isInit) return <LoadingFullScreen />;

  if (!isAuthenticated) {
    storageApi.returnPath.set(window.location.href);
    router.push('/auth/login');
    return <LoadingFullScreen />;
  }

  if (isTenantSelected) {
    return (
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <GraphQLProvider
            batchClient={batchClient}
            client={client}
            uploadClient={uploadClient}
          >
            <SegmentProvider segmentWriteKey={env.SEGMENT_WRITE_KEY()}>
              <DebuggableApp
                initialPath={path}
                initialDateFilter={dateFilter}
                initialModule={module}
                initialTenant={selectedTenant?.id?.toString()}
                initialColumnSorts={columnSorts}
                initialColumnFilters={columnFilters}
                store={store}
              />
            </SegmentProvider>
          </GraphQLProvider>
        </QueryClientProvider>
      </Provider>
    );
  }

  if (userTenantIds.length === 0) return <NotAuthorized />;

  const defaultTenantId = Object.keys(tenantConfigs)[0];
  const defaultConfig = tenantConfigs[defaultTenantId];

  const selectedOrDefault =
    selectedTenant ??
    (defaultConfig && toSelectionListItem(defaultTenantId, defaultConfig));

  return (
    <div css={styles.base}>
      <TenantSelection
        tenants={userTenantIds}
        input={selectedOrDefault}
        rememberToggleChecked={isRememberToggleSelected}
        configs={tenantConfigs}
        onInputChanged={setSelectedTenant}
        onNextClicked={handleNextClicked}
        onToggleChanged={setIsRememberToggleSelected}
      />
    </div>
  );
}

const styles = {
  base: css({
    position: 'absolute',
    inset: 0,
    background: 'radial-gradient(#40404b, #111118) rgba(34,34,40,0.94)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
};

const toSelectionListItem = (
  id: string,
  config: TenantConfiguration,
): ISelectionListItem => ({
  id,
  content: { text: config.displayName, description: id },
});

export async function getServerSideProps(context: NextPageContext) {
  const props: IHomeProps = {};

  if (context.query && context.query?.v === '1') {
    props.query = context.query;
  }

  return { props };
}
