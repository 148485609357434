import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DashboardColumnFilter,
  DashboardFilterStatement,
} from '@se/data/dashboards/query/gridFilters.ts';
import { dashboardsQuery } from '@se/data/dashboards/query/index.ts';
import { DashboardTimespanVariable } from '@se/data/dashboards/query/types.ts';
import { decodeBase64 } from '@seeeverything/ui.util/src/str/str.base64.ts';
import {
  ModuleType,
  OrderBy,
  PageInfoResponse,
} from '@seeeverything/ui.util/src/types.ts';
import { Chip } from '@seeeverything/ui.util/src/urlDeepLink/urlDeepLink.ts';
import {
  ClickThroughGridValuesType,
  DashboardClickThrough,
} from '../../data/types.ts';

type GridKey = string;
type ColumnId = string;

type GridOrderBy = {
  columnId: string;
  direction: 'Ascending' | 'Descending';
};

type GridSort = {
  inactiveEntity?: GridOrderBy;
  orderBys: GridOrderBy[];
};

type GridColumnFilters = {
  filtersHasNextPage: boolean;
  filtersLoadError?: boolean;
  isLoading: boolean;
  isLoaded: boolean;
  lastLoadedPage: number;
  loadedFilters: DashboardColumnFilter[];
  search?: string;
  searchFilters: DashboardColumnFilter[];
  selectedFilters: DashboardColumnFilter[];
};

export type GridQueryArguments = {
  clickThrough?: DashboardClickThrough;
  dashboardType: 'CLICK_THROUGH_GRID' | 'GRID';
  dataSetId: string;
  entityId: string;
  entityType: 'person' | 'team';
  filterStatements: DashboardFilterStatement[];
  gridId: string;
  includeFooter: boolean;
  isQueryEnabled: boolean;
  module: ModuleType;
  orderBy: OrderBy[];
  pageSize: number;
  reloadFlag?: boolean;
  templateId: string;
  timespan: DashboardTimespanVariable;
  tempFormStatusExcludeCompleted: boolean; // Remove when all tenants migrated to new data queries.
};

export type DashboardGridsState = {
  sort: Record<GridKey, GridSort>;
  reloadFlag: Record<GridKey, boolean>;
  showPopupForColumnId: Record<GridKey, ColumnId>;
  columnFilters: Record<GridKey, Record<ColumnId, GridColumnFilters>>;
};

const DEFAULT_STATE: DashboardGridsState = {
  sort: {},
  reloadFlag: {},
  columnFilters: {},
  showPopupForColumnId: {},
};

const slice = createSlice({
  name: 'libs/dashboards/dashboardGrids',
  initialState: DEFAULT_STATE,
  reducers: {
    initializeGrid(
      state,
      action: PayloadAction<{
        gridKey: GridKey;
        inactiveEntity?: GridOrderBy;
        orderBys: GridOrderBy[];
        columnSelectedFilters?: Record<ColumnId, string[]>;
      }>,
    ) {
      const { gridKey, inactiveEntity, orderBys, columnSelectedFilters } =
        action.payload;

      state.reloadFlag[gridKey] = false;

      if (!state.sort[gridKey])
        state.sort[gridKey] = { orderBys, inactiveEntity };

      if (!state.columnFilters[gridKey]) state.columnFilters[gridKey] = {};

      if (columnSelectedFilters) {
        const allColumns = [
          ...Object.keys(columnSelectedFilters),
          ...Object.keys(state.columnFilters[gridKey]),
        ];

        allColumns.forEach((columnId) => {
          const initialFilters = columnSelectedFilters[columnId] ?? [];
          state.columnFilters[gridKey][columnId] = {
            filtersHasNextPage: false,
            filtersLoadError: false,
            isLoading: true,
            isLoaded: false,
            lastLoadedPage: 0,
            loadedFilters: [],
            searchFilters: [],
            selectedFilters: initialFilters.map(
              dashboardsQuery.utils.addDisplayValueToFilter,
            ),
          };
        });
      }
    },
    downloadToSpreadsheet(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _: PayloadAction<{
        gridId: string;
        gridKey: GridKey;
        entityPath?: string;
      }>,
    ) {
      return state;
    },
    reloadGrid(state, action: PayloadAction<{ gridKey: GridKey }>) {
      const gridKey = action.payload.gridKey;
      state.reloadFlag[gridKey] = !state.reloadFlag[gridKey];
    },
    sortGrid(
      state,
      action: PayloadAction<{ gridKey: GridKey; orderBy: GridOrderBy }>,
    ) {
      const { gridKey, orderBy } = action.payload;
      state.sort[gridKey].orderBys = [orderBy];
    },
    updateColumnFilterSelection(
      state,
      action: PayloadAction<{
        gridKey: GridKey;
        columnId: string;
        filter: DashboardColumnFilter;
        to: boolean;
      }>,
    ) {
      const { gridKey, columnId, filter, to } = action.payload;

      const columnsState = state.columnFilters[gridKey];
      const columnState = columnsState[columnId];

      if (to) {
        columnState.selectedFilters.push(filter);
        columnState.selectedFilters.sort((a, b) => {
          if (a.value === null) return 1;
          if (b.value === null) return -1;
          return a.displayValue > b.displayValue ? 1 : -1;
        });
      } else {
        columnState.selectedFilters = columnState.selectedFilters.filter(
          (f) => f.value !== filter.value,
        );
      }

      Object.entries(columnsState).forEach(([id, column]) => {
        if (id !== columnId) {
          // Clear other already loaded columns to support cascading filters.
          column.isLoaded = false;
          column.isLoading = true;
          column.loadedFilters = [];
          column.lastLoadedPage = 0;
        }
      });
    },
    showColumnPopup(
      state,
      action: PayloadAction<{
        gridId: string;
        gridKey: GridKey;
        columnId: string;
      }>,
    ) {
      const { gridKey, columnId } = action.payload;
      state.showPopupForColumnId[gridKey] = columnId;

      const columnsState = state.columnFilters[gridKey];
      const columnState = columnsState[columnId];
      if (!columnState) return;

      const selectedFilters = columnState.selectedFilters ?? [];

      const loadedFiltersWithoutSelected = columnState.loadedFilters
        .filter((f) => !selectedFilters.some((sf) => sf.value === f.value))
        .sort((a, b) => {
          if (a.value === null) return 1;
          if (b.value === null) return -1;
          return a.displayValue > b.displayValue ? 1 : -1;
        });

      columnState.loadedFilters = selectedFilters.concat(
        loadedFiltersWithoutSelected,
      );
    },
    hideColumnPopup(
      state,
      action: PayloadAction<{ gridKey: GridKey; columnId: string }>,
    ) {
      const { gridKey, columnId } = action.payload;
      state.showPopupForColumnId[gridKey] = undefined;

      const columnsState = state.columnFilters[gridKey];
      const columnState = columnsState[columnId];
      if (columnState) columnState.search = '';
    },
    loadColumnFiltersError(
      state,
      action: PayloadAction<{ gridKey: GridKey; columnId: string }>,
    ) {
      const { gridKey, columnId } = action.payload;

      const columnsState = state.columnFilters[gridKey];
      const columnState = columnsState[columnId];

      columnState.isLoading = false;
      columnState.filtersLoadError = true;
    },
    loadColumnFilters(
      state,
      action: PayloadAction<{
        gridId: string;
        gridKey: GridKey;
        columnId: string;
        search?: string;
      }>,
    ) {
      const { gridKey, columnId } = action.payload;

      const columnsState = state.columnFilters[gridKey];
      if (!columnsState) return state;

      if (!columnsState[columnId]) {
        columnsState[columnId] = {
          filtersHasNextPage: false,
          filtersLoadError: false,
          isLoading: true,
          isLoaded: false,
          lastLoadedPage: 0,
          loadedFilters: [],
          searchFilters: [],
          selectedFilters: [],
        };
      }

      if (columnsState[columnId].search)
        columnsState[columnId].isLoading = true;
    },
    loadedColumnFilters(
      state,
      action: PayloadAction<{
        gridKey: GridKey;
        columnId: string;
        filters: DashboardColumnFilter[];
        pageInfo: PageInfoResponse;
        search?: string;
      }>,
    ) {
      const { gridKey, columnId, filters, pageInfo, search } = action.payload;

      const columnsState = state.columnFilters[gridKey];
      const columnState = columnsState[columnId];
      columnState.isLoading = false;
      columnState.isLoaded = true;
      columnState.filtersLoadError = false;

      const isSearch = Boolean(columnState.search);
      const isSearchResult = Boolean(search);
      if (isSearch && isSearchResult && search !== columnState.search)
        return state;

      if (isSearch) {
        columnState.searchFilters = filters;
        return;
      }

      const filtersWithoutSelected = filters.filter(
        (f) => !columnState.selectedFilters.some((sf) => sf.value === f.value),
      );

      const existingFilters =
        pageInfo.currentPage === 1
          ? columnState.selectedFilters
          : columnState.loadedFilters;

      columnState.loadedFilters = existingFilters.concat(
        filtersWithoutSelected,
      );

      columnState.filtersHasNextPage = pageInfo.hasNextPage;
      columnState.lastLoadedPage = pageInfo.currentPage;
    },
    clearAllColumnFilters(state, action: PayloadAction<{ gridKey: GridKey }>) {
      const { gridKey } = action.payload;

      state.showPopupForColumnId[gridKey] = undefined;

      const columnsState = state.columnFilters[gridKey];
      Object.entries(columnsState).forEach(([, filters]) => {
        filters.selectedFilters = [];
        filters.search = '';
        filters.isLoaded = false;
        filters.isLoading = true;
        filters.loadedFilters = [];
        filters.lastLoadedPage = 0;
      });
    },
    clearSelectedColumnFilters(
      state,
      action: PayloadAction<{ gridKey: GridKey; columnId: string }>,
    ) {
      const { gridKey, columnId } = action.payload;

      const columnsState = state.columnFilters[gridKey];
      const columnState = columnsState[columnId];

      columnState.selectedFilters = [];
      columnState.search = '';

      Object.entries(columnsState).forEach(([cId, filters]) => {
        if (cId !== columnId) {
          // Other columns are cleared to support cascading filters.
          filters.isLoaded = false;
          filters.isLoading = true;
          filters.loadedFilters = [];
          filters.lastLoadedPage = 0;
        }
      });
    },
    updateColumnFilterSearch(
      state,
      action: PayloadAction<{
        gridId: string;
        gridKey: GridKey;
        columnId: string;
        search: string;
      }>,
    ) {
      const { gridKey, columnId, search } = action.payload;

      const columnsState = state.columnFilters[gridKey];

      const columnState = columnsState[columnId];
      columnState.search = search;
      columnState.isLoading = Boolean(search);
    },
    invalidateCachedColumnFilters(state) {
      Object.values(state.columnFilters).forEach((gridState) => {
        Object.values(gridState).forEach((columnFilterState) => {
          columnFilterState.isLoaded = false;
          columnFilterState.isLoading = true;
          columnFilterState.loadedFilters = [];
          columnFilterState.lastLoadedPage = 0;
        });
      });
    },
  },
});

export const {
  clearAllColumnFilters,
  clearSelectedColumnFilters,
  downloadToSpreadsheet,
  hideColumnPopup,
  initializeGrid,
  invalidateCachedColumnFilters,
  loadColumnFilters,
  loadColumnFiltersError,
  loadedColumnFilters,
  reloadGrid,
  showColumnPopup,
  sortGrid,
  updateColumnFilterSearch,
  updateColumnFilterSelection,
} = slice.actions;
export const reducer = slice.reducer;
export type State = DashboardGridsState;

type GridKeyArgs = {
  module: ModuleType;
  entityId: string;
  gridId: string;
  dataSetId?: string;
  rowId?: string;
  columnId?: string;
};

export const utils = {
  createGridKey: ({
    module,
    entityId,
    gridId,
    dataSetId,
    rowId,
    columnId,
  }: GridKeyArgs) =>
    [gridId, entityId, module, dataSetId, rowId, columnId]
      .filter(Boolean)
      .join('|'),
  reduceSortState: (sortState?: GridSort) => {
    if (!sortState?.orderBys?.length) return;
    return sortState.orderBys.reduce(
      (acc, { columnId, direction }) => {
        acc[columnId] = direction;
        return acc;
      },
      {} as Record<string, string>,
    );
  },
  reduceColumnFiltersState: (
    columnFilters?: Record<string, GridColumnFilters>,
  ) => {
    if (!columnFilters) return;
    return Object.entries(columnFilters).reduce(
      (acc, [columnId, filterState]) => {
        if (filterState.selectedFilters.length > 0) {
          acc[columnId] = filterState.selectedFilters.map((f) => f.value);
        }
        return acc;
      },
      {} as Record<string, string[]>,
    );
  },
  getUrlLinkParameters: (
    queryBuilderChips: Chip[],
    state: State,
    module: ModuleType,
    entityId?: string,
  ) => {
    if (!queryBuilderChips?.length) return;
    if (!entityId) return;

    const lastChip = queryBuilderChips[queryBuilderChips.length - 1];
    const lastChipType = lastChip.type;
    if (!['gridV2', 'clickThroughGrid'].includes(lastChipType)) return;

    const clickThroughValues =
      lastChipType === 'clickThroughGrid'
        ? (JSON.parse(
            decodeBase64(lastChip.value),
          ) as ClickThroughGridValuesType)
        : undefined;

    const gridKey = utils.createGridKey({
      module,
      gridId: clickThroughValues?.sourceGridId ?? lastChip.value,
      entityId,
      dataSetId: clickThroughValues?.sourceDataSetId,
      rowId: clickThroughValues?.sourceGridRowId,
      columnId: clickThroughValues?.sourceGridColId,
    });

    const sortState = state.sort[gridKey];
    const columnFiltersState = state.columnFilters[gridKey];

    if (!sortState || !columnFiltersState) return;

    return {
      columnSorts: utils.reduceSortState(state.sort[gridKey]),
      columnFilters: utils.reduceColumnFiltersState(
        state.columnFilters[gridKey],
      ),
    };
  },
};
