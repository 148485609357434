import { QueryOptions } from '@apollo/client';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import gql from 'graphql-tag';
import * as R from 'ramda';
import { DashboardDateFilterSelection, IDataPayload } from '../types.ts';
import { mergeSeries } from './chart.ts';
import { NotFoundError } from './errors.ts';
import { toTimespanVariable } from './util.ts';

export interface IPersonChartOptions {
  personId: string;
  chartId: string;
  dataSetId: string;
  templateId: string;
  dateFilter?: DashboardDateFilterSelection;
  options?: QueryOptions;
}

export async function personChart(
  client: IGraphQLClient,
  options: IPersonChartOptions,
): Promise<IDataPayload> {
  const {
    personId,
    chartId,
    dataSetId,
    templateId,
    dateFilter,
    options: gqlOptions,
  } = options;
  const response = await client.query<any>({
    fetchPolicy: 'network-only', // Default to network-only.
    ...gqlOptions,
    query: GqlPersonChart,
    variables: {
      personId,
      chartId,
      dataSetId,
      templateId,
      timespan: toTimespanVariable(dateFilter),
    },
  });

  if (R.isNil(response.data.dashboards.person.chart)) {
    throw new NotFoundError('person', personId);
  }

  const chart = response.data.dashboards.person.chart;
  return {
    pagination: {
      currentPage: 1,
      hasNextPage: false,
      totalCount: 0,
      pageSize: 0,
    },
    data: (chart && chart.series && mergeSeries(chart.series)) || [],
  };
}

const GqlPersonChart = gql`
  query DashboardPersonChartData(
    $personId: ID!
    $chartId: ID!
    $dataSetId: ID!
    $templateId: ID!
    $timespan: DashboardTimespanFilterInput
  ) {
    dashboards {
      person(id: $personId) {
        chart(
          dataSetId: $dataSetId
          id: $chartId
          templateId: $templateId
          timespan: $timespan
        ) {
          key
          series {
            key
            data {
              key
              value
            }
          }
        }
      }
    }
  }
`;
