import { getBusinessUnits } from '@se/data/orgHierarchy/utils/businessUnits.ts';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { QueryRouter } from '@seeeverything/ui.shell/src/api/api.queryBuilder/QueryRouter.ts';
import { IQueryRequest } from '@seeeverything/ui.shell/src/api/api.queryBuilder/types.ts';
import {
  TenantConfiguration,
  TenantLocale,
} from '@seeeverything/ui.util/src/redux/tenant/types.ts';
import { delay } from '@seeeverything/ui.util/src/timer/timer.ts';
import * as R from 'ramda';
import { filter } from 'rxjs';
import { getInitializedApp } from '../../../../app.ts';
import { IInitializedApp } from '../../../../types.ts';
import { getBusinessEntities } from '../../entities.ts';
import { calculateDropdownHeight, optionsDropdown } from '../helpers.tsx';
import * as filters from './root.filters.ts';
import { formatChips } from './router.formatChip.ts';
import { businessEntityDropdownItems } from './util.businessEntity.ts';
import { businessUnitDropdownItems } from './util.businessUnit.ts';

export const router = new QueryRouter();

// Set chip visual properties for most non-complex chips.
formatChips(router);

/**
 * When returning to an existing root chip, this handles showing
 * the dropdown items.
 */
router
  .dropdown(
    'isEditingBusinessUnit',
    /\//,
    'Handles the dropdown for editing/returning to a single chip in the QB.',
  )
  .pipe(filter(filters.isEditingBusinessUnit))
  .subscribe(({ req, res }) => {
    res.dropdown({
      component: 'LIST',
      width: 300,
      height: 50,
      props: createDropdown(req),
    });
  });

/**
 * Handles showing the dropdown items when no chips are selected.
 */
router
  .dropdown(
    'isEmpty',
    /^\//,
    'Handles top level queries (i.e. empty, no input).',
  )
  .pipe(filter(filters.isEmpty))
  .subscribe(({ req, res }) => {
    res.dropdown({
      component: 'LIST',
      width: 300,
      height: 50,
      props: createDropdown(req),
    });
  });

/**
 * Handles showing the dropdown items for business entities (i.e. not BU or Module).
 */
router
  .dropdown(
    'isBusinessEntity',
    /^\//,
    'Handles showing the default dropdown at all points (except the first) in the query hierarchy.',
  )
  .pipe(filter(filters.isEditingBusinessEntity))
  .subscribe(async ({ req, res }) => {
    res.dropdown({
      component: 'LIST',
      width: 300,
      height: 50,
      props: optionsDropdown(req, req.query.filter),
    });
  });

/**
 * Handles showing the dropdown items for business entities and BU (i.e. not module).
 */
router
  .dropdown(
    'hasOnlyModuleChip',
    /^\//,
    'Handles showing the default dropdown at all points (except the first) in the query hierarchy.',
  )
  .pipe(filter(filters.isEditingNonModule))
  .subscribe(({ req, res }) => {
    res.dropdown({
      component: 'LIST',
      width: 300,
      height: 50,
      props: createDropdown(req),
    });
  });

/**
 * Handles all other cases not handled above.
 */
router
  .autocomplete('all', /^\//)
  .pipe(filter(filters.isUnhandledButNotEmpty))
  .subscribe(({ req, res }) => {
    const { context, query, selection } = req;
    const id = (context && context.id) || '';

    const isEditingBusinessUnit = () => Boolean(id.split(':')[0] === 'bu');
    const isEditingSettings = () => Boolean(id.split(':')[0] === 'settings');
    const isEditingChip = () =>
      Boolean(selection && selection.chipIndex !== undefined);

    // Handles updating the BU or Settings chips.
    // These chips don't require editing and are fully set from the dropdown.
    if (isEditingBusinessUnit() || isEditingSettings()) {
      const [type, value] = id.split(':');

      res.query({
        chips: [{ type, value, label: context.label }],
        filter: '',
      });
      return;
    }

    // Handles updating any chips not covered in the conditions above.
    // Assumes this chip is "editable" (i.e. text on the chip can be changed).
    if (isEditingChip()) {
      res.editingChip(selection.chipIndex);
      res.query({
        chips: R.reject(R.isNil, [
          ...query.chips.slice(0, selection.chipIndex),
          context.id ? { type: context.id } : undefined,
        ]),
        filter: '',
      });
      return;
    }

    // Can only be adding a chip at this point.
    // Assumes this chip is "editable" (i.e. text on the chip can be changed).
    const chips = R.reject(R.isNil, [
      ...query.chips,
      context.id ? { type: context.id } : undefined,
    ]);
    res.editingChip(chips.length - 1);
    res.query({ chips, filter: '' });
  });

type OrgItemsOptions = {
  tenantConfig: TenantConfiguration;
  locale: TenantLocale;
};
const orgItems = ({
  tenantConfig,
  locale,
}: OrgItemsOptions): ISelectionListItem[] => {
  const businessUnits = getBusinessUnits(tenantConfig);
  const businessEntities = getBusinessEntities(locale);

  return [
    ...businessUnitDropdownItems(businessUnits),
    ...businessEntityDropdownItems(businessEntities),
  ];
};

async function pollUntilInitialized(app: IInitializedApp) {
  while (app.store.getState().app.isInitialized === false) {
    await delay(10);
  }
}

/**
 * Root-level on query builder.
 * Used to serve dropdown items for modules (if configured on),
 * or otherwise business units / other business entities.
 */
async function createDropdown(req: IQueryRequest) {
  /**
   * When changing modules, the tenant config gets updated - but isn't propagated to
   * the redux store right away (in time for the below). This delay(0) allows deferring
   * by a CPU tick which means the config will be updated.
   *
   * The impact of not having this means that certain configs/labels won't update in the QB
   * (thus will not be using the correct values).
   */
  await delay(0);
  const app = getInitializedApp();

  await pollUntilInitialized(app);

  const tenantConfig = app.store.getState().tenantState.tenant?.configuration;

  const items = orgItems({
    tenantConfig,
    locale: app.store.getState().tenantState.tenant?.locale,
  });

  return {
    filter: req.query.filter,
    column: { isScrollable: false, items },
    height: calculateDropdownHeight(items),
  };
}
