/** @jsxImportSource @emotion/react */
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import {
  DashboardCommentType,
  DashboardCommentDescriptionListItem,
  DashboardBasicCommentType,
} from '../../../types.ts';
import {
  CommentsDescriptionListItem,
  hasDescriptionValue,
} from './CommentsDescriptionListItem.tsx';

export interface ICommentsDescriptionProps {
  description: DashboardCommentType;
}

export const CommentsDescription = ({
  description,
}: ICommentsDescriptionProps) => {
  if (description.kind === 'BASIC_COMMENT') {
    return <BasicComment {...description} />;
  }

  const elItems = description.items
    .filter(hasDescriptionValue)
    .map(toListItemComment);

  return <div>{elItems}</div>;
};

const BasicComment = ({ description }: DashboardBasicCommentType) => (
  <div>
    <Text color={color.format(-0.4)} size={14}>
      {description}
    </Text>
  </div>
);

const toListItemComment = (
  props: DashboardCommentDescriptionListItem,
  i: number,
) => (
  <div key={`${props.title}-${i}`}>
    <CommentsDescriptionListItem
      title={props.title}
      description={props.description}
    />
  </div>
);
