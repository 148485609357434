import { QueryBuilderNextAction } from '@seeeverything/ui.shell/src/redux/query/types.ts';
import { monitoring } from '@seeeverything/ui.util/src/monitoring/index.ts';
import { storageApi } from '@seeeverything/ui.util/src/storage/api.ts';
import { last } from 'ramda';
import { StateObservable, combineEpics, ofType } from 'redux-observable';
import { EMPTY, Observable, concatAll, filter, mergeMap } from 'rxjs';
import { env } from '../../../env.ts';
import {
  GlobalAppActionType,
  GlobalAppEpicDependencies,
  GlobalAppState,
} from '../../../types.ts';

export const epics = combineEpics<
  GlobalAppActionType,
  GlobalAppActionType,
  GlobalAppState,
  GlobalAppEpicDependencies
>(setMonitoringCustomDataOnQueryChanged);

function setMonitoringCustomDataOnQueryChanged(
  action$: Observable<QueryBuilderNextAction>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.shell/query/NEXT'),
    filter(() => env.DD_RUM_ENABLED()),
    mergeMap(async () => {
      const state = state$.value;
      const tenant = state.tenantState.tenant?.id;
      const chips = state.query?.query?.chips;

      if (chips?.length) {
        const { type, value } = last(chips);

        monitoring.setCustomData({
          tenant,
          [type]: value,
          module: storageApi.module.getSessionStorage(),
          version: env.VERSION(),
        });
      }

      return EMPTY;
    }),
    concatAll(),
  );
}
