/** @jsxImportSource @emotion/react */
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { isEmpty } from 'ramda';
import { GridCellDecorator, IDashboardGridRow } from '../../types.ts';

export const linkDecorator: GridCellDecorator = (args) => {
  const {
    cellArgs,
    columnDefinition,
    cellTheme,
    linkHandler,
    launchHandler,
    categoryClickedHandler,
    viewEntity,
    datapoint,
  } = args;

  const gridRow = cellArgs.row as IDashboardGridRow;
  const cellValue = datapoint.value;
  const entity = gridRow.entity;
  const isUnderTest =
    gridRow.tags?.includes('TeamUnderTest') && columnDefinition?.canLaunch;
  const isLaunchingCategory =
    entity?.type === 'Category' && columnDefinition?.canLaunch;

  // Can't launch entities without IDs, teams under test, or categories, or is sensitive tag.
  if (
    isEmpty(entity?.id) ||
    isUnderTest ||
    isLaunchingCategory ||
    gridRow.tags?.includes('IsSensitive')
  ) {
    return {};
  }
  const canLinkCategory =
    columnDefinition?.categoryMap?.some((c) => c.value === cellValue) &&
    categoryClickedHandler;
  const canLaunchEntity = viewEntity && columnDefinition?.canLaunch;
  const canLinkClickThroughGrid =
    columnDefinition?.clickThrough && Number(cellValue) > 0;
  if (canLaunchEntity || canLinkClickThroughGrid || canLinkCategory) {
    return {
      dataTest: `dashboards-dataGridLink-${entity?.type}`,
      text: {
        ellipsis: true,
        size: 14,
        weight: cellTheme.fontWeight,
        color: cellTheme.italic
          ? color.create(COLORS.BLUE).desaturate(3).hex()
          : COLORS.BLUE,
        italic: cellTheme.italic,
      },
      launch: {
        handler: canLaunchEntity ? launchHandler(cellArgs) : undefined,
      },
      link: {
        handler: canLinkClickThroughGrid
          ? linkHandler(cellArgs)
          : canLinkCategory
            ? categoryClickedHandler?.(cellArgs)
            : undefined,
      },
    };
  }
  return {};
};
