/** @jsxImportSource @emotion/react */
import { useMemo } from 'react';
import { css } from '@emotion/react';
import { useFormsSelector } from '../../../redux/store.ts';
import { FormActionTemplateQuestion } from '@se/data/forms/types.ts';
import { ActionQuestionTextContainer } from './ActionQuestion/ActionQuestionTextContainer.tsx';
import { ActionQuestionDropdownContainer } from './ActionQuestion/ActionQuestionDropdownContainer.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';

export const ActionQuestionsContainer: React.FC = () => {
  const actionTemplate = useFormsSelector(
    (state) => state.formActionV2.actionTemplate,
  );

  const fieldRows = useMemo(
    () =>
      actionTemplate.questions.reduce(
        (acc: FormActionTemplateQuestion[][], field) => {
          const { rowIndex, columnIndex } = field.position;

          if (!acc[rowIndex])
            acc[rowIndex] = [
              {
                id: 'Placeholder',
                type: 'Placeholder',
                isRequired: false,
                position: { rowIndex, columnIndex: 0 },
              },
              {
                id: 'Placeholder',
                type: 'Placeholder',
                isRequired: false,
                position: { rowIndex, columnIndex: 1 },
              },
            ];
          acc[rowIndex][columnIndex] = field;
          return acc;
        },
        [],
      ),
    [actionTemplate.questions],
  );

  return (
    <>
      {fieldRows.map((row, rowIndex) => (
        <div key={`questionRow-${rowIndex}`} css={styles.row}>
          {row.map((field) => (
            <div
              css={
                field.position.columnIndex === 0 ? styles.left : styles.right
              }
              key={field.id}
            >
              {field.type === 'Text' && (
                <ActionQuestionTextContainer
                  questionId={field.id}
                  columnIndex={field.position.columnIndex}
                  siblingQuestionId={
                    row[field.position.columnIndex === 0 ? 1 : 0]?.id
                  }
                />
              )}
              {field.type === 'Dropdown' && (
                <ActionQuestionDropdownContainer
                  questionId={field.id}
                  columnIndex={field.position.columnIndex}
                  siblingQuestionId={
                    row[field.position.columnIndex === 0 ? 1 : 0]?.id
                  }
                />
              )}
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

const styles = {
  row: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    margin: -10,
    padding: 10,
    ':hover': {
      backgroundColor: color.format(-0.06),
    },
    alignItems: 'flex-end',
  }),
  left: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 18,
    flex: '1 1 65%',
  }),
  right: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    gap: 18,
    flex: '1 1 35%',
  }),
};
