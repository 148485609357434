import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import {
  IListItemLabel,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useGraphQL } from '@seeeverything/ui.util/src/graphql/GraphQLProvider.tsx';
import { useUtilSelector } from '@seeeverything/ui.util/src/redux/store.ts';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { getFormTemplateCategories } from '../query/formTemplateCategories.ts';
import { FormTemplateCategory } from '../types.ts';

export const useQueryFormTemplateCategories = (showAll = false) => {
  const { client } = useGraphQL();
  const module = useUtilSelector((state) => state.tenantState.tenant.module);

  const { data, isLoading } = useQuery({
    enabled: true,
    queryKey: [{ key: 'FormTemplateCategories', module }],
    queryFn: async ({ queryKey }) => {
      const response = await getFormTemplateCategories(client, {
        module: queryKey[0].module,
        fetchAllPages: true,
        showAll,
      });
      return response.isSuccess ? response.data : null;
    },
    placeholderData: keepPreviousData,
  });

  const categorySelections = useMemo<
    ISelectionListItem<IListItemLabel, FormTemplateCategory>[]
  >(() => {
    if (!data?.formTemplateCategories?.length) return [];

    return data.formTemplateCategories.map((category) => ({
      id: category.id,
      content: { text: category.name },
      value: category,
      icon: Icons.clipboardList,
      iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
    }));
  }, [data]);

  return {
    categories: data?.formTemplateCategories ?? [],
    categorySelections,
    categoriesLoading: isLoading,
  };
};
