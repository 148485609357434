import { ChipType } from '@seeeverything/ui.util/src/urlDeepLink/urlDeepLink.ts';
import * as R from 'ramda';
import {
  ChipKey,
  IChip,
  IChipData,
} from '../../../../api/api.queryBuilder/types.ts';
import { IInternalState } from '../../types.ts';
import { getChipForKey } from './selectors.ts';

/**
 * UTILS
 * =============
 *
 * This file contains utility functions for the Query Builder state.
 * These functions should not generally be called from an external source - if they are,
 * they should be refactored into an action.
 *
 */

/**
 * Append a string value to the end of the current text input.
 */
export const keyGenerator = (() => {
  let key = 1;
  return () => key++;
})();

/**
 * Helper method to create a chip with metadata.
 */
export function createChip({
  type,
  value,
}: { type?: string; value?: string } = {}): IChip {
  return {
    key: keyGenerator(),
    data: {
      type: (type || '') as ChipType,
      value: value || '',
    },
  };
}

/**
 * Remove any zero-width characters (\u200B) from, and trim, a string
 */
const trimAndStripZeroWidthChars = (value: string) =>
  value.trim().replace(/\u200B/g, '');

/**
 * Check if a chip is "empty" (type OR value is empty)
 */
export function isChipEmpty(chip: IChip): boolean {
  const value = chip.data.value
    ? trimAndStripZeroWidthChars(chip.data.value)
    : '';
  return R.isEmpty(value) || R.isNil(value);
}

/**
 * Remove any chips that are empty
 */
export function ensureAllChipsAreNotEmpty(chips: IChip[]): IChip[] {
  // TODO: Strip empty
  const indexOfEmptyChip = R.findIndex(isChipEmpty, chips);
  if (indexOfEmptyChip === -1) {
    return chips;
  }

  const newChips = chips.slice(0, indexOfEmptyChip);
  return newChips;
}

/**
 * Return a new state object, with all chips' values trimmed
 * @param state the Query Builder state
 */
function trimAllChipValues(state: IInternalState): IInternalState {
  return {
    ...state,
    chips: state.chips.map((chip) => {
      const value = chip.data.value
        ? trimAndStripZeroWidthChars(chip.data.value)
        : undefined;
      return {
        ...chip,
        data: {
          ...chip.data,
          value,
        },
      };
    }) as IChip[],
  };
}

/**
 * Set a chip as editing.
 */
export function updateCurrentEditingChip(
  state: IInternalState,
  key: ChipKey,
): IInternalState {
  const chip = getChipForKey(state, key);
  let currentEditingKey: number | undefined = key;
  if (!chip) {
    currentEditingKey = undefined;
  }

  const editingChip = currentEditingKey
    ? { key: currentEditingKey }
    : undefined;

  return {
    ...state,
    editingChip,
  };
}

/**
 * Set all chips as not editing.
 */
export function clearCurrentKeyEditingState(
  state: IInternalState,
): IInternalState {
  return {
    ...state,
    editingChip: undefined,
  };
}

/**
 * Clear chip selection.
 */
export function clearCurrentSelectionState(
  state: IInternalState,
): IInternalState {
  return {
    ...state,
    selectedChip: undefined,
  };
}

/**
 * Clear text input focus.
 */
export function clearTextInputFocusState(
  state: IInternalState,
): IInternalState {
  return {
    ...state,
    textInputFocused: false,
  };
}

export function clearInputBlurredState(state: IInternalState): IInternalState {
  return {
    ...state,
    inputBlurred: false,
  };
}

/**
 * Clear chip selection state and edit state.
 */
export const sanitizeState = (state: IInternalState) =>
  R.pipe(
    clearCurrentKeyEditingState,
    clearCurrentSelectionState,
    clearTextInputFocusState,
    clearInputBlurredState,
  )(state);

/**
 * Update a chip's data, returning a new state object.
 */
export function updateChip(
  state: IInternalState,
  key: ChipKey,
  data: Partial<IChipData>,
): IInternalState {
  const chip = getChipForKey(state, key);
  if (!chip) {
    return state;
  }

  const newChip = {
    ...chip,
    data: {
      ...chip.data,
      ...data,
    },
  };

  const chips = state.chips.map((existingChip) =>
    existingChip.key !== key ? existingChip : newChip,
  );
  const newState: IInternalState = {
    ...state,
    chips,
  };

  return newState;
}

/**
 * Replace all the chips of the state with a new list of chips
 * @param state the Query Builder state
 * @param chips the new chips to replace the existing chips
 */
export function replaceChips(
  state: IInternalState,
  chips: IChip[],
): IInternalState {
  return {
    ...state,
    chips,
  };
}

/**
 * Remove all chips with empty(ish) values, and all chips to the right of those chips.
 * @param state the Query Builder state
 */
export function clearAllEmptyChips(state: IInternalState): IInternalState {
  const stateWithChipValueSanitized = trimAllChipValues(state);
  const chipStateWithoutEmptyChips = ensureAllChipsAreNotEmpty(
    stateWithChipValueSanitized.chips,
  );
  const stateWithoutEmptyChips = replaceChips(
    state,
    chipStateWithoutEmptyChips,
  );

  return stateWithoutEmptyChips;
}

/**
 * Deletes all chips after a certain point
 * @param state the Query Builder state
 * @param sliceAt the index to delete chips after
 */
export function deleteAllChipsAfter(
  state: IInternalState,
  sliceAt: number,
): IInternalState {
  const chips = state.chips;
  if (chips.length === 0) {
    return state;
  }
  if (sliceAt > chips.length - 1 || sliceAt < 0) {
    return state;
  }

  const newChips = chips.slice(0, sliceAt);

  return replaceChips(state, newChips);
}

/**
 * Focus the text input, and clears any chip editing state
 * @param state the Query Builder state
 */
export function focusTextInput(state: IInternalState): IInternalState {
  return {
    ...state,
    selectedChip: undefined,
    editingChip: undefined,
    textInputFocused: true,
  };
}
