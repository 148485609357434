import { IQuery } from '../types.ts';

/**
 * Converts a QB query into a URL.
 */
export function toUrl(query?: IQuery) {
  if (!query || isQueryEmpty(query)) {
    return '/';
  }
  const filter = (query.filter && query.filter.trim()) || '';

  let url = query.chips.reduce((acc, chip) => {
    const type = chip && chip.type && chip.type.toString().trim();
    const value = (chip && chip.value && chip.value.toString().trim()) || '';
    const part = value ? `${type}:${value}` : type;
    return `${acc}/${part}`;
  }, '');

  if (filter) {
    if (!url) {
      url = '/';
    }
    url = `${url}?filter=${filter}`;
  }

  return `${url}`;
}

/**
 * Determines whether the given query contains no value.
 */
export function isQueryEmpty(query: IQuery) {
  return query && query.chips.length === 0 && !query.filter.trim();
}

/**
 * Determines whether the given query matches a URL pattern.
 */
export function isPathMatch(pattern: RegExp, query: IQuery) {
  const queryUrl = toUrl(query);

  const matches = queryUrl.match(pattern);
  return Boolean(matches && matches.length > 0);
}
