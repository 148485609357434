export const VISIBILITY_SELECTIONS = [
  {
    id: '',
    content: { text: 'No restrictions (default)' },
  },
  {
    id: 'NotVisibleToSubject',
    content: { text: 'Not visible to subject' },
  },
  {
    id: 'NotVisibleToSubjectTillSignOff',
    content: { text: 'Not visible to subject until sign-off' },
  },
  {
    id: 'NotVisibleToSubjectOrManagersTillSignOff',
    content: {
      text: 'Not visible to subject or managers until sign-off',
    },
  },
  {
    id: 'VisibleToQaTeamOnly',
    content: { text: 'Visible to QA team only' },
  },
];
