/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';

export interface IDividerProps {
  margin?: number;
}

export const Divider = ({ margin = 10 }: IDividerProps) => {
  const styles = {
    base: css({
      paddingTop: margin,
      marginBottom: margin,
      boxSizing: 'border-box',
      borderBottom: `solid 1px ${color.format(-0.1)}`,
    }),
  };
  return <div css={styles.base} />;
};
