/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { useMouseOver } from '@seeeverything/ui.primitives/src/hooks/useMouseOver.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import React from 'react';

export interface IOptionCircleProps {
  innerIcon?: string;
  onClick?: () => void;
}

export const OptionCircle: React.FC<IOptionCircleProps> = ({
  innerIcon,
  onClick,
}) => {
  const [isMouseOver, handleMouseEnter, handleMouseLeave] = useMouseOver();

  const Icon = innerIcon && Icons[innerIcon];
  const computedStyles = {
    base: css({
      cursor: innerIcon && 'pointer',
      backgroundColor: isMouseOver && innerIcon ? COLORS.BLUE : '#F2F2F2',
      border:
        isMouseOver && innerIcon
          ? `solid 3px ${COLORS.BLUE}`
          : 'solid 3px #DDDDDD',
    }),
  };

  return (
    <div
      css={[styles.base, computedStyles.base]}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {innerIcon && <Icon size={20} fill={isMouseOver ? 'white' : '#C7C7C7'} />}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 26,
    width: 26,
    borderRadius: '100%',
    marginRight: 10,
  }),
};
