/** @jsxImportSource @emotion/react */
import * as R from 'ramda';
import { css } from '@emotion/react';
import {
  ISelectionListColumn,
  SelectionListClickEventHandler,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { OutsideAlerter } from '@seeeverything/ui.primitives/src/components/OutsideAlerter/OutsideAlerter.tsx';
import { SelectionList } from '@seeeverything/ui.primitives/src/components/SelectionList/SelectionList.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';

export interface ITemplateOptionsPopupProps {
  showOpen?: boolean;
  showDelete?: boolean;
  isFocused?: boolean;
  onFocusChange?: (e?: any) => void;
  onSelectionChanged?: (to: string) => void;
  rowIndex?: number;
}

const handleSelectionChanged =
  (onSelectionChanged?: (e?: any) => void): SelectionListClickEventHandler =>
  (e) => {
    if (onSelectionChanged && e?.id) {
      onSelectionChanged(e.id);
    }
  };

export const TemplateOptionsPopup = (props: ITemplateOptionsPopupProps) => {
  const { rowIndex } = props;

  const computedStyles = {
    base: css({
      height: props.showDelete && props.showOpen ? 78 : 40,
      top: rowIndex !== undefined ? 30 + rowIndex * 27 : undefined,
      right: rowIndex !== undefined ? 20 : undefined,
    }),
  };
  return (
    <div css={[styles.base, computedStyles.base]}>
      <OutsideAlerter
        style={styles.selectionOuter}
        onClickedOutside={props.onFocusChange}
      >
        <SelectionList
          isScrollable={true}
          size={'SMALL'}
          columns={[SELECTION_ITEMS_COLUMN(props)]}
          visibleColumns={[0]}
          onClick={handleSelectionChanged(props.onSelectionChanged)}
        />
      </OutsideAlerter>
    </div>
  );
};

const SELECTION_ITEMS_COLUMN = ({
  showOpen = false,
  showDelete = false,
}: ITemplateOptionsPopupProps): ISelectionListColumn => ({
  items: R.reject(R.isNil, [
    showOpen
      ? {
          id: 'OPEN',
          icon: Icons.editPencil,
          value: 'open',
          content: 'Open',
        }
      : undefined,
    showDelete
      ? {
          id: 'DELETE',
          icon: Icons.delete,
          value: 'delete',
          content: 'Delete',
        }
      : undefined,
  ]),
});

const styles = {
  base: css({
    backgroundColor: 'white',
    position: 'absolute',
    display: 'flex',
    width: 177,
    zIndex: 6,
  }),
  selectionOuter: css({
    flex: '1 1 auto',
    display: 'flex',
    border: `solid 1px #DEDEDE`,
    borderRadius: 3,
    boxShadow: '0px 2px 4px 1px rgba(0, 0, 0, 0.4)',
  }),
};
