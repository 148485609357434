import { IDashboardCategoryMapping } from '../types.ts';

export function parseCategoryMap(
  categoryMap?: any,
): IDashboardCategoryMapping[] | undefined {
  if (!categoryMap?.length) {
    return undefined;
  }

  return categoryMap.map((setting: any) => ({
    value: setting.value,
    scrollToId: setting['scroll-to-id'],
  }));
}
