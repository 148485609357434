import { FormLineById } from '@seeeverything/ui.forms/src/redux/form-instance/types.ts';
import { IDesignerDigitalContent } from '../../model/index.ts';
import { FormsDesignerDigitalContentPackLoadedAction } from './types.digitalContent.ts';
import { FormDesignerReduxDesignerState } from './types.ts';

export function copyFilesToTemplate(
  state: FormDesignerReduxDesignerState,
  action: FormsDesignerDigitalContentPackLoadedAction,
): FormDesignerReduxDesignerState {
  if (!state.draft?.designerLines || !state.draft.lines) {
    return state;
  }

  return {
    ...state,
    draft: {
      ...state.draft,
      lines: {
        ...state.draft.lines,
        ...updateDigitalContent(state.draft.lines, action.payload),
      },
    },
  };
}

const updateDigitalContentFiles = (
  lines: FormLineById,
  payload: IDesignerDigitalContent,
) => ({
  digitalContent: {
    ...lines.digitalContent,
    files: payload.files,
  },
});

const updateDigitalContent = (
  lines: FormLineById,
  payload: IDesignerDigitalContent,
): FormLineById => ({
  ...updateDigitalContentFiles(lines, payload),
});
