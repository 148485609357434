/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { MessageBar } from '@seeeverything/ui.primitives/src/components/MessageBar/MessageBar.tsx';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { Spinner } from '@seeeverything/ui.primitives/src/components/Spinner/index.ts';
import { TabStrip } from '@seeeverything/ui.primitives/src/components/TabStrip/TabStrip.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TitledDialog } from '@seeeverything/ui.primitives/src/components/TitledDialog/TitledDialog.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { useCallback, useEffect, useMemo } from 'react';
import {
  formsDesignerCreateTemplatesSlice,
  formsDesignerDropdownsSlice,
} from '../../redux/index.ts';
import {
  useFormsDesignerDispatch,
  useFormsDesignerSelector,
} from '../../redux/store.ts';
import { CreateFromCopy } from './components/CreateFromCopy.tsx';
import { CreateNewDraft } from './components/CreateNewDraft.tsx';

const TABS = [
  { id: 'NEW', label: 'New' },
  { id: 'COPY_FROM_EXISTING', label: 'Copy from Existing' },
];

export const NewDraftDialogContainer = () => {
  const dispatch = useFormsDesignerDispatch();

  useEffect(() => {
    dispatch(formsDesignerDropdownsSlice.loadDesignerTemplatesPage());
  }, [dispatch]);

  const createNewName = useFormsDesignerSelector(
    (state) => state.formsDesignerCreateTemplates.createNewInputs.name,
  );

  const createNewNameErrorText = useFormsDesignerSelector(
    (state) =>
      state.formsDesignerCreateTemplates.createNewInputs.validationErrors.name,
  );

  const createFromCopyName = useFormsDesignerSelector(
    (state) =>
      state.formsDesignerCreateTemplates.createFromExistingInputs.rename,
  );

  const createFromCopyNameErrorText = useFormsDesignerSelector(
    (state) =>
      state.formsDesignerCreateTemplates.createFromExistingInputs
        .validationErrors.rename,
  );

  const selectedMethod = useFormsDesignerSelector(
    (state) => state.formsDesignerCreateTemplates.selectedMethod,
  );

  const createFromCopySourceTemplate = useFormsDesignerSelector(
    (state) =>
      state.formsDesignerCreateTemplates.createFromExistingInputs
        .sourceTemplate,
  );

  const createFromCopySourceTemplateErrorText = useFormsDesignerSelector(
    (state) =>
      state.formsDesignerCreateTemplates.createFromExistingInputs
        .validationErrors.sourceTemplate,
  );

  const generalError = useFormsDesignerSelector(
    (state) => state.formsDesignerCreateTemplates.error,
  );

  const isCreating = useFormsDesignerSelector(
    (state) => state.formsDesignerCreateTemplates.status === 'SAVING',
  );

  const templates = useFormsDesignerSelector(
    (state) => state.formsDesignerDropdowns.designerTemplates.items,
  );

  const templatesIsLoading = useFormsDesignerSelector(
    (state) => state.formsDesignerDropdowns.designerTemplates.isLoading,
  );

  const createDraftTemplate = useCallback(() => {
    switch (selectedMethod) {
      case 'NEW':
        if (!createNewName) {
          dispatch(
            formsDesignerCreateTemplatesSlice.validationErrorsOnNew({
              name: 'Please enter a name.',
            }),
          );
          return;
        }
        break;

      case 'COPY_FROM_EXISTING':
        if (!createFromCopySourceTemplate) {
          dispatch(
            formsDesignerCreateTemplatesSlice.validationErrorsOnOverwriteExisting(
              { sourceTemplate: 'Please select a source template to copy.' },
            ),
          );
          return;
        }
        break;
    }
    dispatch(formsDesignerCreateTemplatesSlice.createDraft());
  }, [createFromCopySourceTemplate, createNewName, dispatch, selectedMethod]);

  const changeTab = useCallback(
    (to: 'NEW' | 'COPY_FROM_EXISTING') =>
      dispatch(formsDesignerCreateTemplatesSlice.setSelectedMethod(to)),
    [dispatch],
  );

  const updateCreateNewName = useCallback(
    (to: string) =>
      dispatch(formsDesignerCreateTemplatesSlice.updateNameFieldOnNew(to)),
    [dispatch],
  );

  const updateCopyFromExistingName = useCallback(
    (to: string) =>
      dispatch(
        formsDesignerCreateTemplatesSlice.updateRenameFieldOnCopyExisting(to),
      ),
    [dispatch],
  );

  const updateCopyFromExistingSourceTemplate = useCallback(
    (to: ISelectionListItem) =>
      dispatch(
        formsDesignerCreateTemplatesSlice.updateSourceTemplateOnCopyExisting(
          to,
        ),
      ),
    [dispatch],
  );

  const cancel = useCallback(
    () => dispatch(formsDesignerCreateTemplatesSlice.cancelDraftCreation()),
    [dispatch],
  );

  const actions = useMemo(
    () => [
      { id: 'Cancel', label: 'Cancel', isEnabled: true, onAction: cancel },
      { id: 'Create', label: 'Create', onAction: createDraftTemplate },
    ],
    [cancel, createDraftTemplate],
  );

  const elTabStrip = useMemo(
    () => (
      <div css={styles.tabsContainer}>
        <TabStrip
          tabs={TABS}
          selectedId={selectedMethod}
          onSelectionChanged={changeTab}
        />
      </div>
    ),
    [changeTab, selectedMethod],
  );

  const elError = useMemo(
    () =>
      generalError ? (
        <MessageBar
          message={generalError}
          style={styles.messageBar}
          type={'Error'}
        />
      ) : undefined,
    [generalError],
  );

  const elSelectedTab = useMemo(
    () =>
      selectedMethod === 'NEW' ? (
        <CreateNewDraft
          name={createNewName}
          nameErrorText={createNewNameErrorText}
          onNameChange={updateCreateNewName}
        />
      ) : (
        <CreateFromCopy
          name={createFromCopyName}
          nameErrorText={createFromCopyNameErrorText}
          onNameChange={updateCopyFromExistingName}
          onSourceTemplateChange={updateCopyFromExistingSourceTemplate}
          sourceTemplate={createFromCopySourceTemplate}
          sourceTemplateErrorText={createFromCopySourceTemplateErrorText}
          templates={templates}
          templatesIsLoading={templatesIsLoading}
        />
      ),
    [
      createFromCopyName,
      createFromCopyNameErrorText,
      createFromCopySourceTemplate,
      createFromCopySourceTemplateErrorText,
      createNewName,
      createNewNameErrorText,
      selectedMethod,
      templates,
      templatesIsLoading,
      updateCopyFromExistingName,
      updateCopyFromExistingSourceTemplate,
      updateCreateNewName,
    ],
  );

  return (
    <div css={styles.base}>
      {isCreating && (
        <div css={styles.overlayBase}>
          <Spinner />
          <Text style={styles.overlayText}>{'Creating new draft...'}</Text>
        </div>
      )}
      <TitledDialog
        title={'Create Draft'}
        actions={actions}
        style={styles.dialog}
      >
        <div css={styles.bodyFull}>
          {elTabStrip}
          {elError}
          {elSelectedTab}
        </div>
      </TitledDialog>
    </div>
  );
};

const styles = {
  base: css({
    position: 'relative',
    backgroundColor: '#ffffff',
    flex: '1 1 auto',
  }),
  dialog: css({
    height: 335,
  }),
  bodyFull: css({
    width: '100%',
  }),
  overlayBase: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    position: 'absolute',
    inset: 0,
    backgroundColor: color.format(0.8),
    zIndex: 1,
  }),
  messageBar: css({
    marginLeft: 16,
  }),
  overlayText: css({
    paddingLeft: 8,
  }),
  tabsContainer: css({
    display: 'flex',
    flexDirection: 'column',
    background: color.format(1),
    width: '100%',
    padding: '0 20px',
  }),
};
