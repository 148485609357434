import { IDashboardDigitalContent } from '../types.ts';

/**
 * Attempts to parse values to construct a digital-content.
 */
export function parser(
  item: any,
  index: number,
): IDashboardDigitalContent | undefined {
  const digitalContent = item['digital-content'];
  if (!digitalContent) {
    return;
  }

  return {
    id: digitalContent.id,
    index,
    componentType: 'DIGITAL_CONTENT',
    packSelector: {
      label: digitalContent['pack-selector']?.label ?? 'DIGITAL CONTENT',
      selectedContentPack: undefined,
    },
    dataState: {
      dataSetId: '',
      isLoaded: false,
      isLoading: false,
      forceReload: false,
      data: {},
    },
  };
}
