/** @jsxImportSource @emotion/react */
import InputBase from '@material-ui/core/InputBase/InputBase.js';
import React from 'react';
import { CSSProperties } from '../../types/types.ts';

export type InputFieldChangeEvent = {
  from: string;
  to: string;
};
export type InputFieldChangeEventHandler = (e: InputFieldChangeEvent) => void;

export interface IInputFieldProps {
  value?: string;
  style?: CSSProperties;
  onChange?: InputFieldChangeEventHandler;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
}

/**
 * A text input, built with InputBase.
 */
export const InputField: React.FC<IInputFieldProps> = ({
  onChange,
  onFocus,
  onBlur,
  style,
  value: propsValue = '',
}) => {
  const [value, setValue] = React.useState(propsValue);
  const [size, setSize] = React.useState(propsValue.length || 1);

  const handleOnChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    setValue(e.target.value);
    setSize(e.target.value?.length || 1);
    onChange?.({ from: value, to: e.target.value });
  };

  return (
    <InputBase
      value={value}
      style={style}
      onChange={handleOnChange}
      onFocus={onFocus}
      onBlur={onBlur}
      autoFocus={true}
      inputProps={{ size }}
    />
  );
};
