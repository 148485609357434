import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import {
  shallowEqual,
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux';
import { combineEpics } from 'redux-observable';
import {
  dashboardGridsEpics,
  dashboardGridsSlice,
} from './dashboardGrids/index.ts';
import { toggleOnChartToolsClickEpic } from './epics/chartToolButtonClickedEpic.ts';
import {
  digitalContentComponentLoadedEpic,
  digitalContentComponentOnLoadEpic,
  loadDigitalContentPacksPageEpic,
  reloadDigitalContentComponentOnFiltersChangedEpic,
} from './epics/digitalContentComponentEpic.ts';
import { downloadGridDataEpic } from './epics/downloadGridDataEpic.ts';
import { toggleOnGridToolsClickEpic } from './epics/gridToolButtonClickedEpic.ts';
import { initComponentsEpic } from './epics/initComponentsEpic.ts';
import {
  clearTrackedDataIdsEpic,
  initTemplateEpic,
} from './epics/initTemplateEpic.ts';
import { loadComponentEpic } from './epics/loadComponentsEpics.ts';
import {
  loadFromCacheEpic,
  loadHeaderOnCacheLoad,
} from './epics/loadFromCache.ts';
import {
  loadHeaderEpic,
  loadHeaderOnDateFiltersChanged,
} from './epics/loadHeaderEpic.ts';
import { reloadGridOnGoalChangedEpic } from './epics/reloadGridOnGoalChanged.ts';
import { rememberScrollPositionEpics } from './epics/rememberScrollPositionEpic.ts';
import { reducer as dashboardItemReducer } from './reducers.ts';
import { GlobalDashboardsState } from './types.ts';

export const dashboardsReducers = {
  dashboardsV2: dashboardItemReducer,
  dashboardGrids: dashboardGridsSlice.reducer,
};

export const dashboardsRootEpic = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalDashboardsState
>(
  clearTrackedDataIdsEpic,
  digitalContentComponentLoadedEpic,
  digitalContentComponentOnLoadEpic,
  downloadGridDataEpic,
  initComponentsEpic,
  initTemplateEpic,
  loadComponentEpic,
  loadFromCacheEpic,
  loadHeaderOnDateFiltersChanged,
  loadHeaderEpic,
  loadHeaderOnCacheLoad,
  reloadDigitalContentComponentOnFiltersChangedEpic,
  reloadGridOnGoalChangedEpic,
  rememberScrollPositionEpics,
  toggleOnChartToolsClickEpic,
  toggleOnGridToolsClickEpic,
  loadDigitalContentPacksPageEpic,
  dashboardGridsEpics,
);

export const useDashboardsDispatch = () => useDispatch<any>();
export const useDashboardsSelector: TypedUseSelectorHook<
  GlobalDashboardsState
> = (selector) => useSelector(selector, shallowEqual);
