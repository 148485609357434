import { personById } from '../../data/data.people.ts';
import { GlobalAppState } from '../../types.ts';
import { identifyEvent, trackEvent } from './util.ts';

export const generateUserAuthorizedEvents = async (state: GlobalAppState) => [
  await getAuthorizationIdentifyEvent(state),
  trackEvent('user_authorized', {}),
];

const getAuthorizationIdentifyEvent = async (state: GlobalAppState) => {
  const tenant = state.tenantState.tenant;
  const primaryTeam = tenant.authenticatedUser.primaryTeam;
  const user = await personById(tenant.authenticatedUser.id);
  const roleTeamMemberships = user.roles.map((role) => role.name);

  return identifyEvent(state.tenantState.tenant.authenticatedUser.id, {
    managerTeamLevel:
      primaryTeam?.role === 'Manager'
        ? primaryTeam.path.split('/').length
        : undefined,
    positionTitle: user.positionTitle,
    roleTeamMember: roleTeamMemberships.length > 0,
    roleTeamMemberships,
    userType: primaryTeam?.role,
    userTeamFullPath: primaryTeam?.path,
  });
};
