/** @jsxImportSource @emotion/react */
import { IconWrapper } from '@seeeverything/ui.primitives/src/components/IconWrapper/IconWrapper.tsx';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { css } from '@emotion/react';

export interface ICreateNewDraftProps {
  name?: string;
  nameErrorText?: string;
  onNameChange?: (to: string) => void;
}

export const CreateNewDraft = ({
  name = '',
  nameErrorText,
  onNameChange,
}: ICreateNewDraftProps) => (
  <IconWrapper icon={'editPencil'} style={styles.field}>
    <TextField
      id={'name'}
      value={name}
      label={'Template Name'}
      onChange={onNameChange}
      error={nameErrorText}
    />
  </IconWrapper>
);

const styles = {
  field: css({
    width: '100%',
    padding: '15px',
    boxSizing: 'border-box',
  }),
};
