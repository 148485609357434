/** @jsxImportSource @emotion/react */
import { DashboardHeader } from '@seeeverything/ui.dashboards/src/components/DashboardHeader/DashboardHeader.tsx';
import { dashboardFactory } from '@seeeverything/ui.dashboards/src/factory/index.ts';
import { componentParsers } from '@seeeverything/ui.dashboards/src/parse/index.ts';
import { DashboardComponent } from '@seeeverything/ui.dashboards/src/types.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { Spinner } from '@seeeverything/ui.primitives/src/components/Spinner/index.ts';
import { useDateContext } from '@seeeverything/ui.primitives/src/hooks/useDateContext.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { propsAreEqualFastCompare } from '@seeeverything/ui.util/src/react/memoFastCompare.ts';
import { scrollSlice } from '@seeeverything/ui.util/src/redux/scroll/index.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';
import { CreateNewFormInstanceButton } from '../CreateNewFormInstanceButton/CreateNewFormInstanceButton.tsx';
import { SheetError } from '../SheetError/SheetError.tsx';
import { ISheetIndexItem } from '../SheetIndex/types.ts';
import { SheetLayout } from '../SheetLayout/SheetLayout.tsx';
import { ModuleDropdownContainer } from '../Shell/components/ModuleDropdownContainer.tsx';

type DashboardType = 'COACHING_DASHBOARD_PERSON' | 'COACHING_DASHBOARD_TEAM';

export interface ISheetDashboardV2ContainerProps {
  id: string;
  leftPanelBottomContent?: React.ReactElement;
  dashboardType: DashboardType;
}

const useDashboardStateSelectors = () => {
  const dispatch = useShellDispatch();
  const module = useShellSelector((state) => state.tenantState.tenant.module);
  const dashboardHeader = useShellSelector(
    (state) => state.dashboardsV2.HEADER,
  );
  const template = useShellSelector((state) => state.dashboardsV2.template);
  const error = useShellSelector((state) => state.dashboardsV2.error);

  const canCreateForm = useShellSelector(
    (state) => state.app.permissions.instanceCreate,
  );

  const scrollToIdOnLoaded = useShellSelector(
    (state) => state.dashboardsV2.scrollToPosition,
  );

  const dashboardSettings = useShellSelector(
    (state) => state.dashboardsV2.settings,
  );

  const dateFilters = useMemo(() => {
    const dateFilterState = dashboardHeader?.dateFilters;
    if (!dateFilterState) return undefined;

    return dateFilterState.map((filter) => {
      const isSelected = filter.id === dashboardSettings?.selectedDateFilterId;
      const fill = isSelected ? COLORS.GREEN_TICK : 'rgba(0, 0, 0, 0)';

      return {
        id: filter.id,
        dataTest: `shell-dateFilterListItem-${filter.id}`,
        icon: function TickIcon() {
          return <Icons.tickDone fill={fill} />;
        },
        content: {
          text: filter.toLabel({
            financialYearStartMonth: dashboardSettings?.financialYearStartMonth,
            customOptions: dashboardSettings?.selectedCustomDates,
          }),
        },
      };
    });
  }, [dashboardHeader, dashboardSettings]);

  return {
    dispatch,
    module,
    template,
    error,
    scrollToIdOnLoaded,
    dateFilters,
    canCreateForm,
  };
};

const EXPANDED_HEIGHT = 150;
const COLLAPSED_HEIGHT = 50;

const View: React.FC<ISheetDashboardV2ContainerProps> = ({
  dashboardType,
  id,
  leftPanelBottomContent,
}) => {
  const {
    canCreateForm,
    dateFilters,
    dispatch,
    error,
    module,
    scrollToIdOnLoaded,
    template,
  } = useDashboardStateSelectors();

  const [hasScrolled, setHasScrolled] = useState(false);

  const dateContext = useDateContext();

  const [dashboardItems, setDashboardItems] = useState<
    JSX.Element[] | undefined
  >();

  const dashboardComponents = useMemo(
    () =>
      template?.definition
        ? componentParsers(template.definition, undefined, {
            tenantTimezone: dateContext.tenantTimezone,
          })
        : undefined,
    [dateContext.tenantTimezone, template?.definition],
  );

  const prevHeight = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(EXPANDED_HEIGHT);

  const moreItems = useCreateExportItems(dashboardType);

  const handleUpdateHeaderHeightOnContentScrolled = useCallback(
    (scrollTop: number) => {
      const nextHeight = getHeaderHeight(scrollTop);
      if (nextHeight === prevHeight.current) return;

      setHeaderHeight(nextHeight);

      // Some shorter dashboards result in the 'height' and 'scrollTop' to oscillate between the same two values indefinitely.
      // Some dashboards have a sheet index destination that overlaps a specific pixel range with the header collapsing,
      //  or in very rare cases precisely scrolling to the same location manually.
      // Please do not remove unless rewriting.
      setTimeout(() => {
        prevHeight.current = nextHeight;
      }, 5);
    },
    [],
  );

  // Delegate the render of the dashboard items to next loop of work to prevent thread blocking for first paint.
  useEffect(() => {
    if (!dashboardComponents) return undefined;

    const handler = setTimeout(() => {
      const result = dashboardFactory(
        dashboardComponents,
        id.toString(),
        dashboardType === 'COACHING_DASHBOARD_TEAM' ? 'TEAM' : 'PERSON',
      );
      setDashboardItems(result);
    }, 0);

    return () => {
      clearTimeout(handler);
    };
  }, [dashboardComponents, id, dashboardType, module]);

  const indexItems = useMemo(
    () => dashboardComponents && buildIndex(dashboardComponents),
    [dashboardComponents],
  );

  const scrollToId = useCallback(
    (dataId: string) => {
      dispatch(
        scrollSlice.scrollToDataId({
          scrollContainerId: 'scrollPanel',
          dataId,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (hasScrolled) return;
    if (!template || !dashboardItems || error) return;

    const dataId =
      scrollToIdOnLoaded &&
      dashboardComponents?.find((c) => c.id === scrollToIdOnLoaded)?.id;

    if (dataId) scrollToId(dataId);
    setHasScrolled(true);
  }, [
    dashboardComponents,
    dashboardItems,
    error,
    hasScrolled,
    scrollToId,
    scrollToIdOnLoaded,
    template,
    dispatch,
  ]);

  if (!template || !dashboardItems) return <Spinner center={true} />;

  if (error)
    return (
      <SheetError
        message={
          'There was a problem retrieving your data. You may not have permission to view this.'
        }
        detail={
          'Please contact your administrator if you think you are seeing this in error or would like to change your permissions.'
        }
      />
    );

  return (
    <SheetLayout
      dateFilters={dateFilters}
      leftContent={canCreateForm && <CreateNewFormInstanceButton />}
      header={<DashboardHeader height={headerHeight} />}
      indexItems={indexItems}
      leftPanelBottomContent={leftPanelBottomContent}
      rightContent={<ModuleDropdownContainer variant={'DASHBOARD'} />}
      onScroll={handleUpdateHeaderHeightOnContentScrolled}
      moreItems={moreItems}
    >
      {dashboardItems}
    </SheetLayout>
  );
};

const useCreateExportItems = (
  dashboardType: DashboardType,
): ISelectionListItem[] => {
  const module = useShellSelector((state) => state.tenantState.tenant.module);
  const locale = useShellSelector((state) => state.tenantState.tenant.locale);

  const actionLabel = str.plural(str.titleCase(locale.label.action));

  const section: ISelectionListItem = {
    id: 'EXPORT_SECTION',
    type: 'SECTION',
    content: 'Download to CSV',
    icon: Icons.fileDownload,
  };

  switch (module) {
    case 'coaching':
      return [
        section,
        {
          id: 'DASHBOARD_EXPORT_SUMMARY',
          icon: Icons.fileDownload,
          content: 'Summaries',
          dataTest: 'toolbar-exportTool-summaries',
        },
        {
          id: 'DASHBOARD_EXPORT_DETAILS',
          icon: Icons.fileDownload,
          content: 'Details',
          dataTest: 'toolbar-exportTool-details',
        },
        {
          id: 'DASHBOARD_EXPORT_GOALS',
          icon: Icons.fileDownload,
          content: `Goals and ${str.pluralize.plural(str.titleCase(actionLabel))}`,
          dataTest: 'toolbar-exportTool-coachingGoalsAndActions',
        },
        {
          id: 'DASHBOARD_EXPORT_ACTIONS',
          icon: Icons.fileDownload,
          content: str.pluralize.plural(str.titleCase(actionLabel)),
          dataTest: 'toolbar-exportTool-coachingActions',
        },
      ];

    case 'cadence':
      return dashboardType === 'COACHING_DASHBOARD_PERSON'
        ? [
            section,
            {
              id: 'DASHBOARD_EXPORT_ACTIONS',
              icon: Icons.fileDownload,
              content: str.pluralize.plural(str.titleCase(actionLabel)),
            },
          ]
        : [
            section,
            {
              id: 'DASHBOARD_EXPORT_SUMMARY',
              icon: Icons.fileDownload,
              content: 'Summaries',
              dataTest: 'toolbar-exportTool-summaries',
            },
            {
              id: 'DASHBOARD_EXPORT_ACTIONS',
              icon: Icons.fileDownload,
              content: str.pluralize.plural(str.titleCase(actionLabel)),
            },
          ];

    case 'compliance':
      return [
        section,
        {
          id: 'DASHBOARD_EXPORT_SUMMARY',
          icon: Icons.fileDownload,
          content: 'Summaries',
          dataTest: 'toolbar-exportTool-summaries',
        },
        {
          id: 'DASHBOARD_EXPORT_DETAILS',
          icon: Icons.fileDownload,
          content: 'Details',
        },
        {
          id: 'DASHBOARD_EXPORT_ACTIONS',
          icon: Icons.fileDownload,
          content: str.pluralize.plural(str.titleCase(actionLabel)),
        },
      ];
  }
};

const getHeaderHeight = (scrollTop = 0) => {
  const top = EXPANDED_HEIGHT - scrollTop + 40;
  if (top < COLLAPSED_HEIGHT) return COLLAPSED_HEIGHT;

  return Math.min(EXPANDED_HEIGHT, top);
};

const buildIndex = (items: DashboardComponent[]): ISheetIndexItem[] =>
  items
    .filter((item) => item.level !== undefined && item.title !== undefined)
    .map((item) => ({
      id: item.id,
      label: item.title ?? '',
      icon: item.icon,
      type: item.level === 'h1' ? 'ROOT' : 'CHILD',
    }));

export const SheetDashboardV2Container = memo(View, propsAreEqualFastCompare);
