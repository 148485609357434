import {
  useEffect,
  useState,
  useRef,
  useCallback,
  MutableRefObject,
} from 'react';

export interface UseMeasureArgs {
  listenOnWindowResize?: boolean;
  remeasureDependency?: unknown;
}

const safeWindow = () => (typeof window !== 'undefined' ? window : undefined);

/**
 * Hook for measuring the size of a component after it has rendered.
 * @param UseMeasureArgs
 * -
 * - **listenOnWindowResize** Optional (defaults to true). If true, will trigger a measurement on global window resize events.
 * - **remeasureDependency** Optional dependency to trigger a measure if this value is changed.
 * @returns [forwardRef, size]
 * - **forwardRef** - Ref to pass to component to measure
 * - **size** - Value containing component dimensions
 *
 * @example
 * const [ forwardRef, size ] = useMeasure();
 * return (
 *  <>
 *    <div ref={forwardRef}>{dynamicText}</div>
 *    <pre>{JSON.stringify(size)}</pre>
 *  </>
 * )
 */
export const useMeasure = (
  args: UseMeasureArgs = {},
): [MutableRefObject<any>, Partial<DOMRect>] => {
  const { listenOnWindowResize = true, remeasureDependency } = args;

  const [size, setSize] = useState<Partial<DOMRect>>({});
  const forwardRef = useRef(null);

  const measure = useCallback(() => {
    setSize(forwardRef.current?.getBoundingClientRect());
  }, []);

  useEffect(() => {
    measure();
  }, [remeasureDependency, measure]);

  useEffect(() => {
    measure();

    if (listenOnWindowResize) safeWindow()?.addEventListener('resize', measure);

    return () =>
      listenOnWindowResize
        ? safeWindow()?.removeEventListener('resize', measure)
        : undefined;
  }, [listenOnWindowResize, measure]);

  return [forwardRef, size];
};
