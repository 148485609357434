/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { IconWrapper } from '@seeeverything/ui.primitives/src/components/IconWrapper/IconWrapper.tsx';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { TextFieldDropdown } from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';

export interface IOverwriteExistingTemplateProps {
  definitionJson?: string;
  definitionJsonErrorText?: string;
  onDefinitionJsonChange?: (to: string) => void;
  onSelectedTemplateChange?: (to: ISelectionListItem) => void;
  selectedTemplate?: ISelectionListItem;
  selectedTemplateErrorText?: string;
  templates?: ISelectionListItem[];
  templatesIsLoading?: boolean;
}

export const OverwriteExistingTemplate = ({
  definitionJson,
  definitionJsonErrorText,
  onDefinitionJsonChange,
  onSelectedTemplateChange,
  selectedTemplate,
  selectedTemplateErrorText,
  templates,
  templatesIsLoading,
}: IOverwriteExistingTemplateProps) => (
  <>
    <div css={styles.bodyFull}>
      <IconWrapper icon={'document'} style={styles.field}>
        <TextFieldDropdown
          id={'selectedTemplate'}
          value={selectedTemplate}
          label={'Existing Template'}
          isLoading={templatesIsLoading}
          selections={templates}
          onChange={onSelectedTemplateChange}
          error={selectedTemplateErrorText}
        />
      </IconWrapper>
    </div>
    <div css={styles.bodyFull}>
      <IconWrapper icon={'description'} style={styles.field}>
        <TextField
          id={'definitionJson'}
          value={definitionJson}
          label={'Template Definition (JSON)'}
          multiline={true}
          maxRows={3}
          onChange={onDefinitionJsonChange}
          error={definitionJsonErrorText}
        />
      </IconWrapper>
    </div>
  </>
);

const styles = {
  bodyFull: css({
    width: '100%',
  }),
  field: css({
    width: '100%',
    padding: '15px',
    boxSizing: 'border-box',
  }),
};
