import { FormLineById } from '@seeeverything/ui.forms/src/redux/form-instance/types.ts';
import { DesignerComponentKind } from '../../components/types.ts';
import { IDesignerEditableDraft } from '../../model/types.ts';
import * as types from './types.ts';
import { FormsDesignerDesignerValidateDefinitionSuccessAction } from './types.ts';

export function load(id: string): types.FormsDesignerDesignerLoadDraftAction {
  return {
    type: 'ui.forms.designer/designer/LOAD_DRAFT',
    payload: { id },
  };
}

export function loaded(
  draft: IDesignerEditableDraft,
): types.FormsDesignerDesignerLoadedDraftAction {
  return {
    type: 'ui.forms.designer/designer/LOADED_DRAFT',
    payload: draft,
  };
}

export function loadFailed(): types.FormsDesignerDesignerLoadDraftFailedAction {
  return {
    type: 'ui.forms.designer/designer/LOAD_DRAFT_FAILED',
    payload: {},
  };
}

export function editDefinition(
  id: string,
): types.FormsDesignerDesignerEditDefinitionAction {
  return {
    type: 'ui.forms.designer/designer/EDIT_DEFINITION',
    payload: { id },
  };
}

export function saveDefinition(
  id: string,
  source: 'save' | 'publish',
): types.FormsDesignerDesignerSaveDefinitionAction {
  return {
    type: 'ui.forms.designer/designer/SAVE_DEFINITION',
    payload: { id, source },
  };
}

export function savedDefinition(
  id: string,
  definition: any[],
  source: string,
): types.FormsDesignerDesignerSavedDefinitionAction {
  return {
    type: 'ui.forms.designer/designer/SAVED_DEFINITION',
    payload: { id, definition, source },
  };
}

export function saveDefinitionFailed(): types.FormsDesignerDesignerSaveDefinitionFailedAction {
  return {
    type: 'ui.forms.designer/designer/SAVE_DEFINITION_FAILED',
    payload: {},
  };
}

export function validateDefinition(
  id: string,
  definition: any[],
): types.FormsDesignerDesignerValidateDefinitionAction {
  return {
    type: 'ui.forms.designer/designer/VALIDATE_DEFINITION',
    payload: { id, definition },
  };
}

export function validateDefinitionSuccess(
  id: string,
): FormsDesignerDesignerValidateDefinitionSuccessAction {
  return {
    type: 'ui.forms.designer/designer/VALIDATE_DEFINITION_SUCCESS',
    payload: { id },
  };
}

export function validateDefinitionFailed(
  id: string,
  designerLines: FormLineById,
): types.FormsDesignerDesignerValidateDefinitionFailedAction {
  return {
    type: 'ui.forms.designer/designer/VALIDATE_DEFINITION_FAILED',
    payload: { id, designerLines },
  };
}

export function toggleDesigning(): types.FormsDesignerDesignerToggleDesigningAction {
  return {
    type: 'ui.forms.designer/designer/TOGGLE_DESIGNING',
    payload: {},
  };
}

/**
 * DESIGNER COMPONENTS
 */
export function changeComponentProperty(
  id: string,
  to = '',
): types.FormsDesignerDesignerChangeComponentProperty {
  return {
    type: 'ui.forms.designer/designer/component/CHANGE_COMPONENT_PROPERTY',
    payload: { id, to },
  };
}

export function deleteComponent(
  id: string,
): types.FormsDesignerDesignerDeleteComponent {
  return {
    type: 'ui.forms.designer/designer/component/DELETE_COMPONENT',
    payload: { id },
  };
}

export function addComponent(
  id: string,
  kind: DesignerComponentKind,
  insertBeforeId?: string | number,
): types.FormsDesignerDesignerAddComponent {
  return {
    type: 'ui.forms.designer/designer/component/ADD_COMPONENT',
    payload: { id, kind, insertBeforeId },
  };
}

export function optionsChangeOptionText(
  id: string,
  optionId: string,
  to: string,
): types.FormsDesignerDesignerOptionsChangeOptionText {
  return {
    type: 'ui.forms.designer/designer/component/OPTIONS/CHANGE_TEXT',
    payload: { id, optionId, to },
  };
}

export function optionsAddNewOption(
  id: string,
  optionId: string,
): types.FormsDesignerDesignerOptionsAddNewOption {
  return {
    type: 'ui.forms.designer/designer/component/OPTIONS/ADD_OPTION',
    payload: { id, optionId },
  };
}

export function optionsDeleteExistingOption(
  id: string,
  optionId: string,
): types.FormsDesignerDesignerOptionsDeleteExistingOption {
  return {
    type: 'ui.forms.designer/designer/component/OPTIONS/DELETE_OPTION',
    payload: { id, optionId },
  };
}

export function designerLinesChanged(
  lines: FormLineById,
): types.FormsDesignerDesignerLinesChanged {
  return {
    type: 'ui.forms.designer/designer/LINES_CHANGED',
    payload: { lines },
  };
}

/**
 * DESIGNER <-> TEMPLATE INTEGRATION
 */
export function copyComponentFromDesignerToTemplate(
  id: string,
): types.FormsDesignerIntegrationCopyDesignerComponentToTemplate {
  return {
    type: 'ui.forms.designer/integration/COPY_DESIGNER_COMPONENT_TO_TEMPLATE',
    payload: { id },
  };
}

export function deleteComponentFromTemplate(
  id: string,
): types.FormsDesignerIntegrationDeleteComponentFromTemplate {
  return {
    type: 'ui.forms.designer/integration/DELETE_COMPONENT_FROM_TEMPLATE',
    payload: { id },
  };
}

export function addComponentToTemplate(
  id: string,
  type: DesignerComponentKind,
  insertBeforeId?: string | number,
): types.FormsDesignerIntegrationAddComponentToTemplate {
  return {
    type: 'ui.forms.designer/integration/ADD_COMPONENT_TO_TEMPLATE',
    payload: { id, type, insertBeforeId },
  };
}
