import { authApi } from '@seeeverything/ui.auth/src/api/api.ts';
import { loggingOut } from '@seeeverything/ui.shell/src/redux/app/actions.ts';
import { AppLogoutAction } from '@seeeverything/ui.shell/src/redux/app/types.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { combineEpics, ofType } from 'redux-observable';
import { Observable, mergeMap } from 'rxjs';
import { GlobalAppEpicDependencies, GlobalAppState } from '../../../types.ts';

export const epics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalAppState,
  GlobalAppEpicDependencies
>(logoutEpic);

/**
 * Issue a `loggingOut` to inform the UI to show the "logging out" screen and spinner.
 */
function logoutEpic(action$: Observable<AppLogoutAction>) {
  return action$.pipe(
    ofType('ui.shell/app/LOG_OUT'),
    mergeMap(async () => {
      await authApi.logout();
      return loggingOut();
    }),
  );
}
