import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import {
  IChipData,
  IQuery,
  IQuerySelection,
  queryBuilder,
  QueryFocusTarget,
} from '../../api/index.ts';
import {
  QueryBuilderBlurAction,
  QueryBuilderChipAddedExternallyAction,
  QueryBuilderChipRemovedExternallyAction,
  QueryBuilderChipStartedEditingAction,
  QueryBuilderChipStoppedEditingAction,
  QueryBuilderDisableAction,
  QueryBuilderEnableAction,
  QueryBuilderFocusAction,
  QueryBuilderModuleChangedFromSheetAction,
  QueryBuilderModuleChangedFromUrlAction,
  QueryBuilderNextAction,
  QueryBuilderReplaceChipsAction,
} from './types.ts';

export * from './actions.dropdown.ts';

export function focus(
  target: QueryFocusTarget = 'INPUT',
): QueryBuilderFocusAction {
  return {
    type: 'ui.shell/query/FOCUS',
    payload: { target },
  };
}

export function blur(): QueryBuilderBlurAction {
  return {
    type: 'ui.shell/query/BLUR',
    payload: {},
  };
}

export function enable(): QueryBuilderEnableAction {
  return {
    type: 'ui.shell/query/ENABLE',
    payload: {},
  };
}

export function disable(): QueryBuilderDisableAction {
  return {
    type: 'ui.shell/query/DISABLE',
    payload: {},
  };
}

export const queryChangedFromSheet = (
  query: IQuery,
  module: ModuleType,
  source: QueryBuilderModuleChangedFromSheetAction['payload']['source'],
): QueryBuilderModuleChangedFromSheetAction => ({
  type: 'ui.shell/query/MODULE_CHANGED_FROM_SHEET',
  payload: { query, module, source },
});

export function next(
  changeSource: QueryBuilderNextAction['payload']['changeSource'],
  query: IQuery,
  selection?: IQuerySelection,
  forceRefresh?: boolean,
  eventName?: string,
): QueryBuilderNextAction {
  return {
    type: 'ui.shell/query/NEXT',
    payload: {
      query,
      selection,
      url: queryBuilder.toUrl(query),
      changeSource: changeSource || 'CODE',
      forceRefresh,
      eventName,
    },
  };
}

export function chipAddedExternally(
  chipData: IChipData,
  shouldRunQuery?: boolean,
  eventName?: string,
): QueryBuilderChipAddedExternallyAction {
  return {
    type: 'ui.shell/query/ADD_CHIP',
    payload: {
      newChip: { ...chipData },
      shouldRunQuery,
      eventName,
    },
  };
}

export function chipRemovedExternally(
  shouldRunQuery?: boolean,
  eventName?: string,
): QueryBuilderChipRemovedExternallyAction {
  return {
    type: 'ui.shell/query/REMOVE_CHIP',
    payload: { shouldRunQuery, eventName },
  };
}

export function chipStartedEditing(
  index: number,
  chip: IChipData,
): QueryBuilderChipStartedEditingAction {
  return {
    type: 'ui.shell/query/CHIP_STARTED_EDITING',
    payload: { index, chip },
  };
}
export function chipStoppedEditing(
  index: number,
  chip: IChipData,
): QueryBuilderChipStoppedEditingAction {
  return {
    type: 'ui.shell/query/CHIP_STOPPED_EDITING',
    payload: { index, chip },
  };
}

export const replaceChips = (
  chips: IChipData[],
  replaceModuleChip = true,
): QueryBuilderReplaceChipsAction => ({
  type: 'ui.shell/query/REPLACE_CHIPS',
  payload: { chips, replaceModuleChip },
});

export const moduleChangedFromUrl = (
  query: IQuery,
  module: ModuleType,
): QueryBuilderModuleChangedFromUrlAction => ({
  type: 'ui.shell/query/MODULE_CHANGED_FROM_URL',
  payload: { query, module, source: 'URL' },
});
