/** @jsxImportSource @emotion/react */
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { InnerDialog } from './components/InnerDialog/InnerDialog.tsx';
import { IActionBarButton } from './components/ActionButtons/ActionButtons.tsx';
import { useMeasure } from '../../hooks/useMeasure.ts';

export interface IModalDialogProps {
  actions: IActionBarButton[];
  dialogWidth?: number;
  maskColor?: string | number;
  isShowing?: boolean;
  slideDuration?: number;
  paddingHorizontal?: number;
  paddingBottom?: number;
  minDialogWidth?: number;
  minDialogHeight?: number;
  children?: ReactNode;
}

/**
 * A dialog box that blocks interaction.
 */
export const ModalDialog: React.FC<IModalDialogProps> = ({
  actions,
  children,
  dialogWidth = 500,
  isShowing = false,
  maskColor = -0.5,
  minDialogHeight = 95,
  minDialogWidth = 290,
  paddingBottom = 30,
  paddingHorizontal = 50,
  slideDuration = 200,
}) => {
  const timeoutHandler = useRef<NodeJS.Timeout>(null);

  const [measureRef, size] = useMeasure({
    remeasureDependency: isShowing,
  });
  const [delayedIsShowing, setDelayedIsShowing] = useState(isShowing);

  useEffect(() => {
    clearTimeout(timeoutHandler.current);

    timeoutHandler.current = setTimeout(() => {
      setDelayedIsShowing(isShowing);
    }, slideDuration);
  }, [slideDuration, isShowing]);

  const computedStyles = useMemo(
    () => ({
      mask: css({
        display: isShowing ? 'unset' : 'none',
        position: 'absolute',
        inset: 0,
        width: '100%',
        height: '100%',
        transition: `background-color ${slideDuration}ms`,
        backgroundColor: color.format(maskColor),
        zIndex: 13,
        overflow: 'auto',
      }),
      dialogOuter: css({
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        top: isShowing ? 0 : '-100%',
        transition: `top ${slideDuration}ms`,
        zIndex: 14,
      }),
    }),
    [isShowing, maskColor, slideDuration],
  );

  const width = useMemo(() => {
    const maxScreenWidth = size?.width - paddingHorizontal * 2;

    const preferredWidth = Math.min(maxScreenWidth, dialogWidth);

    return Math.max(preferredWidth, minDialogWidth);
  }, [dialogWidth, minDialogWidth, paddingHorizontal, size?.width]);

  const height = useMemo(() => {
    const maxScreenHeight = size?.height - paddingBottom;

    return Math.max(maxScreenHeight, minDialogHeight);
  }, [minDialogHeight, paddingBottom, size?.height]);

  return (
    <>
      <div css={computedStyles.mask} ref={measureRef} />
      <div css={styles.base}>
        <div css={computedStyles.dialogOuter}>
          {isShowing || delayedIsShowing ? (
            <InnerDialog actions={actions} height={height} width={width}>
              {children}
            </InnerDialog>
          ) : (
            <div />
          )}
        </div>
      </div>
    </>
  );
};

const styles = {
  base: css({
    overflow: 'hidden',
  }),
};
