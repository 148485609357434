import { isAnyOf } from '@reduxjs/toolkit';
import { editDistributionListSlice } from '@seeeverything/ui.forms/src/redux/editDistributionList/index.ts';
import { editGoalScheduleSlice } from '@seeeverything/ui.forms/src/redux/editGoalSchedule/index.ts';
import { editInstanceScheduleSlice } from '@seeeverything/ui.forms/src/redux/editInstanceSchedule/index.ts';
import { goalScheduleSlice } from '@seeeverything/ui.forms/src/redux/goalSchedule/index.ts';
import { instanceScheduleSlice } from '@seeeverything/ui.forms/src/redux/instanceSchedule/index.ts';
import { GlobalFormsState } from '@seeeverything/ui.forms/src/redux/store.ts';
import { sheetSchedulesSlice } from '@seeeverything/ui.shell/src/redux/sheetSchedules/index.ts';
import { SheetScheduleType } from '@seeeverything/ui.shell/src/redux/sheetSchedules/sheetSchedulesSlice.ts';
import {
  showAlertDialog,
  showStatusBar,
} from '@seeeverything/ui.shell/src/redux/sheets/index.ts';
import {
  SheetHideAlertDialogAction,
  SheetToolbarClickAction,
} from '@seeeverything/ui.shell/src/redux/sheets/types.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { StateObservable, combineEpics, ofType } from 'redux-observable';
import { EMPTY, Observable, concatAll, filter, map, of } from 'rxjs';
import { GlobalAppEpicDependencies, GlobalAppState } from '../../../types.ts';

export const epics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalAppState,
  GlobalAppEpicDependencies
>(
  createToolbarButtonClick,
  handleAlertDismissEditingDistributionListEpic,
  handleAlertDismissEditingGoalScheduleEpic,
  handleAlertDismissEditingInstanceScheduleEpic,
  handleConfirmAlertDismissChangesEpic,
  showStatusBarOnDistributionListSave,
  showStatusBarOnInstanceScheduleSaved,
  showStatusBarOnScheduleItemsLoadError,
  switchScheduleTypeOnToolbarClick,
);

function createToolbarButtonClick(
  action$: Observable<SheetToolbarClickAction>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.shell/sheets/TOOLBAR_CLICK'),
    filter((action) => action.payload.toolId === 'CREATE_SCHEDULE'),
    map(() => {
      switch (state$.value.sheetSchedules.selectedScheduleType) {
        case 'INSTANCE_TIME_SCHEDULES':
          return of(editInstanceScheduleSlice.createNewDraft());
        case 'GOAL_SCHEDULES':
          return of(
            editGoalScheduleSlice.createNewDraft({
              initialGoalAdditionalFields:
                state$.value.tenantState.tenant.configuration
                  .goalAdditionalFields ?? [],
            }),
          );
        case 'DISTRIBUTION_LIST':
          return of(
            editDistributionListSlice.createDraftDistributionList({
              module: state$.value.tenantState.tenant.module,
            }),
          );
        default:
          return EMPTY;
      }
    }),
    concatAll(),
  );
}

function switchScheduleTypeOnToolbarClick(
  action$: Observable<SheetToolbarClickAction>,
) {
  return action$.pipe(
    ofType('ui.shell/sheets/TOOLBAR_CLICK'),
    filter((action) =>
      [
        'GOAL_SCHEDULES',
        'INSTANCE_TIME_SCHEDULES',
        'DISTRIBUTION_LIST',
      ].includes(action.payload.toolId.toString()),
    ),
    map((action) =>
      sheetSchedulesSlice.selectScheduleType({
        type: action.payload.toolId as SheetScheduleType,
      }),
    ),
  );
}

function showStatusBarOnScheduleItemsLoadError(
  action$: Observable<ReduxAction>,
) {
  return action$.pipe(
    filter(
      isAnyOf(
        instanceScheduleSlice.loadInstanceSchedulesFailed.match,
        goalScheduleSlice.errorLoadingGoalSchedules.match,
      ),
    ),
    map(() =>
      showStatusBar('ERROR', 'An error occurred loading the schedules.'),
    ),
  );
}

function handleAlertDismissEditingInstanceScheduleEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsState>,
) {
  return action$.pipe(
    filter(editInstanceScheduleSlice.alertConfirmDismissChanges.match),
    map(() => {
      const hasChanges = Boolean(
        state$.value.editInstanceSchedule.hasDraftChanged,
      );
      if (!hasChanges) return of(editInstanceScheduleSlice.dismiss());

      const isSaving = Boolean(state$.value.editInstanceSchedule.isSaving);
      return isSaving ? EMPTY : of(showAlertDialog('INSTANCE_TIME_SCHEDULES'));
    }),
    concatAll(),
  );
}

function handleAlertDismissEditingGoalScheduleEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    filter(editGoalScheduleSlice.alertConfirmDismissChanges.match),
    map(() => {
      const hasChanges = Boolean(state$.value.editGoalSchedule.hasDraftChanged);
      if (!hasChanges) return of(editGoalScheduleSlice.dismiss());

      const isSaving = Boolean(state$.value.editGoalSchedule.isSaving);
      return isSaving ? EMPTY : of(showAlertDialog('GOAL_SCHEDULES'));
    }),
    concatAll(),
  );
}

function handleAlertDismissEditingDistributionListEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    filter(
      editDistributionListSlice.showAlertDialogOnClickOutsideDistributionList
        .match,
    ),
    map(() => {
      const hasChanges = Boolean(
        state$.value.formEditDistributionList.hasDraftChanged,
      );
      if (!hasChanges)
        return of(editDistributionListSlice.cancelEditingDistributionList());

      const isSaving = Boolean(state$.value.formEditDistributionList.isSaving);
      return isSaving ? EMPTY : of(showAlertDialog('DISTRIBUTION_LIST'));
    }),
    concatAll(),
  );
}

function handleConfirmAlertDismissChangesEpic(
  action$: Observable<SheetHideAlertDialogAction>,
) {
  return action$.pipe(
    ofType('ui.shell/sheets/HIDE_ALERT_DIALOG'),
    filter(({ payload }) => payload.reason === 'Confirm'),
    map((action) => {
      if (action.payload.id === 'DISTRIBUTION_LIST')
        return of(editDistributionListSlice.cancelEditingDistributionList());

      if (action.payload.id === 'GOAL_SCHEDULES')
        return of(editGoalScheduleSlice.dismiss());

      if (action.payload.id === 'INSTANCE_TIME_SCHEDULES')
        return of(editInstanceScheduleSlice.dismiss());

      return EMPTY;
    }),
    concatAll(),
  );
}

function showStatusBarOnInstanceScheduleSaved(
  action$: Observable<ReduxAction>,
) {
  return action$.pipe(
    filter(
      isAnyOf(
        editInstanceScheduleSlice.saved.match,
        editGoalScheduleSlice.saved.match,
      ),
    ),
    map((action) =>
      showStatusBar(
        'SUCCESS',
        action.payload.reason === 'Deactivated'
          ? 'The schedule has been deactivated.'
          : 'The schedule has been saved.',
      ),
    ),
  );
}

function showStatusBarOnDistributionListSave(action$: Observable<ReduxAction>) {
  return action$.pipe(
    filter(editDistributionListSlice.saveDistributionListSuccess.match),
    map(() =>
      showStatusBar('SUCCESS', 'The distribution list has been saved.'),
    ),
  );
}
