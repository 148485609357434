/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { DesignerSection } from '../DesignerSection/DesignerSection.tsx';
import { DesignerSignoffPerson } from '../DesignerSignoffPerson/DesignerSignoffPerson.tsx';
import { DesignerSignoffFieldProps, IDesignerCommonProps } from '../types.ts';
import { getValidationError } from '../util.ts';

export interface IDesignerSignoffProps extends IDesignerCommonProps {
  title?: string;
  reviewerLabel?: string;
  subject?: DesignerSignoffFieldProps;
}

export class DesignerSignoff extends React.Component<
  IDesignerSignoffProps,
  object
> {
  public render() {
    const {
      reviewerLabel,
      subject,
      title = 'Sign-off',
      showBoundaryLines,
      showBullet = true,
      style,
      onChange,
      onEventOccurred,
      validationError,
    } = this.props;

    return (
      <div css={[styles.outer, style]}>
        <DesignerSection
          id={'signoff-title'}
          canDelete={false}
          showBoundaryLines={showBoundaryLines}
          title={title}
          showBullet={showBullet}
          onChange={onChange}
          onEventOccurred={onEventOccurred}
          error={getValidationError(validationError?.fields, 'title')}
        />
        <div css={styles.base}>
          <DesignerSignoffPerson
            id={'signoff-reviewer'}
            canDelete={false}
            inputProps={{
              label: reviewerLabel,
              isRequired: true,
              canChangeIsSignoffRequired: false,
            }}
            showBoundaryLines={showBoundaryLines}
            showBullet={true}
            style={styles.halfWidth}
            participant={'Reviewer'}
            onChange={onChange}
            onEventOccurred={onEventOccurred}
            error={getValidationError(validationError?.fields, 'reviewerLabel')}
          />
          <DesignerSignoffPerson
            id={'signoff-subject'}
            canDelete={false}
            inputProps={subject}
            showBoundaryLines={showBoundaryLines}
            showBullet={false}
            style={styles.halfWidth}
            participant={'Subject'}
            onChange={onChange}
            onEventOccurred={onEventOccurred}
            error={getValidationError(validationError?.fields, 'subject')}
          />
        </div>
      </div>
    );
  }
}

const styles = {
  outer: css({
    position: 'relative',
  }),
  base: css({
    display: 'flex',
    justifyContent: 'space-between',
  }),
  halfWidth: css({
    flex: '0 0 49.5%',
    minWidth: 286,
  }),
};
