import { dashboardGridsSlice } from '@seeeverything/ui.dashboards/src/redux/index.ts';
import {
  DashboardActionComponentViewDetail,
  DashboardActionLoaded,
} from '@seeeverything/ui.dashboards/src/redux/types.ts';
import { formEditGoalSlice } from '@seeeverything/ui.forms/src/redux/form-edit-goal/index.ts';
import { formActionSlice } from '@seeeverything/ui.forms/src/redux/formAction/index.ts';
import { formBulkUploadDownloadTemplateSlice } from '@seeeverything/ui.forms/src/redux/formBulkUploadDownloadTemplate/index.ts';
import { formBulkUploadImportFileSlice } from '@seeeverything/ui.forms/src/redux/formBulkUploadImportFile/index.ts';
import { auditSlice } from '@seeeverything/ui.shell/src/redux/audit/index.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { AnalyticsEvent } from '@seeeverything/ui.util/src/redux/analytics/types.ts';
import { tenantSlice } from '@seeeverything/ui.util/src/redux/tenant/index.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { GlobalAppActionType, GlobalAppState } from '../types.ts';
import {
  generateDashboardClickthroughEvent,
  generateDashboardDateChangeEvent,
  generateDashboardDrilldownEvent,
  generateDashboardHeaderLoadedEvent,
  generateDashboardToggleChangeEvent,
  generateSectionLabelEvent,
} from './event-mappers/dashboard-mapper.ts';
import {
  generateFormInstanceCreateEvent,
  generateFormInstanceEditEvent,
  generateFormInstanceIdEvent,
  generateFormInstanceMismatchedAnswersEvent,
  generateFormInstanceSignOffEvents,
  generateFormInstanceViewEvent,
} from './event-mappers/forms-mapper.ts';
import { generateGoalModifyEvents } from './event-mappers/goal-mapper.ts';
import { generateModuleChangedEvents } from './event-mappers/query-mapper.ts';
import {
  generateCsvExportResultCompletedEvent,
  generateDownloadCsvEvent,
  generateQueryBuilderInteractionEvent,
  generateSheetUpEvent,
  generateShellFeatureUsedEvent,
} from './event-mappers/shell-mapper.ts';
import { generateUserAuthorizedEvents } from './event-mappers/util-mapper.ts';
import { trackEvent } from './event-mappers/util.ts';

export const getAnalyticsEvents = async (
  action: GlobalAppActionType,
  state: GlobalAppState,
  client: IGraphQLClient,
): Promise<AnalyticsEvent[]> => {
  const events =
    (await analyticsEventDataMap[action.type]?.(action, state, client)) ??
    (await fromSliceActions(action, state)) ??
    [];

  if (!events.length) return [];

  return events.map((event) => ({
    ...event,
    payload: {
      tenant: state.tenantState.tenant.id,
      module: state.tenantState.tenant.module,
      emailDomain: state.tenantState.tenant.authenticatedUser?.emailDomain,
      ...event.payload,
    },
  }));
};

const fromSliceActions = (action: ReduxAction, state: GlobalAppState) => {
  if (tenantSlice.setAuthenticatedUser.match(action))
    return generateUserAuthorizedEvents(state);

  if (formEditGoalSlice.loadedGoal.match(action))
    return [trackEvent('goal_view', { goalId: action.payload.id })];

  if (formEditGoalSlice.showPreviousGoalNotes.match(action))
    return [
      trackEvent('goal_view_notes', { goalId: state.formEditGoal.goalId }),
    ];

  if (formEditGoalSlice.createdGoal.match(action) && action.payload.isSuccess)
    return [trackEvent('goal_create', { goalId: action.payload.goalId })];

  if (formEditGoalSlice.updateGoal.match(action))
    return generateGoalModifyEvents(action.payload, state);

  if (formEditGoalSlice.updatedGoal.match(action))
    return [trackEvent('goal_save', { goalId: action.payload.goalId })];

  if (auditSlice.load.match(action))
    return [
      trackEvent('form_audit_log_view', {
        formInstanceId: action.payload.instanceId,
      }),
    ];

  if (dashboardGridsSlice.downloadToSpreadsheet.match(action))
    return [
      trackEvent('dashboard_download_table', {
        sectionType: action.payload.gridId,
        subjectId: state.dashboardsV2.template.entityId,
        sourceType: state.dashboardsV2.template.kind,
        subjectPath: action.payload.entityPath,
      }),
    ];

  const formActionEvents = formActionSlice.analytics(action, trackEvent);
  if (formActionEvents) return formActionEvents;

  const formBulkUploadDownloadTemplateEvents =
    formBulkUploadDownloadTemplateSlice.analytics(
      action,
      state.formBulkUploadDownloadTemplate,
      trackEvent,
    );
  if (formBulkUploadDownloadTemplateEvents)
    return formBulkUploadDownloadTemplateEvents;

  const formBulkUploadImportFileEvents =
    formBulkUploadImportFileSlice.analytics(action, trackEvent);
  if (formBulkUploadImportFileEvents) return formBulkUploadImportFileEvents;
};

const analyticsEventDataMap: {
  [key in GlobalAppActionType['type']]?: (
    action: GlobalAppActionType,
    state: GlobalAppState,
    client: IGraphQLClient,
  ) => Promise<AnalyticsEvent[]>;
} = {
  'app/queryBuilder/UPDATED_MODULE': generateModuleChangedEvents,
  'ui.dashboards/dashboard/CHART_TOOLS/TOGGLE':
    generateDashboardToggleChangeEvent,
  'ui.dashboards/dashboard/COMPONENT/LOADED': (
    action: DashboardActionLoaded,
    state,
  ) => generateSectionLabelEvent(action, 'dashboard_section_load', state),
  'ui.dashboards/dashboard/COMPONENT/VIEW_DETAIL': (
    action: DashboardActionComponentViewDetail,
    state,
  ) => generateSectionLabelEvent(action, 'dashboard_load_more', state),
  'ui.dashboards/dashboard/COMPONENT/VIEW_ENTITY':
    generateDashboardDrilldownEvent,
  'ui.dashboards/dashboard/GRID_TOOLS/TOGGLE':
    generateDashboardToggleChangeEvent,
  'ui.dashboards/dashboard/GRID/VIEW_CLICK_THROUGH_GRID':
    generateDashboardClickthroughEvent,
  'ui.dashboards/dashboard/HEADER/LOADED': generateDashboardHeaderLoadedEvent,
  'ui.dashboards/dashboard/HEADER/SET_CUSTOM_DATE_RANGE':
    generateDashboardDateChangeEvent,
  'ui.dashboards/dashboard/HEADER/SET_DATE_FILTER':
    generateDashboardDateChangeEvent,
  'ui.forms/file/DOWNLOAD_REQUEST': (action, state) =>
    generateFormInstanceIdEvent(action, 'form_download_document', state),
  'ui.forms/file/UPLOAD': (action, state) =>
    generateFormInstanceIdEvent(action, 'form_upload_document', state),
  'ui.forms/instance/ANSWER/CHANGE': generateFormInstanceEditEvent,
  'ui.forms/instance/CLEAR_ERRORS_FOR_ANSWER_KEYS':
    generateFormInstanceMismatchedAnswersEvent,
  'ui.forms/instance/EXPORT_INSTANCE_TO_PDF': (action, state) =>
    generateFormInstanceIdEvent(action, 'form_download_pdf', state),
  'ui.forms/instance/SERVER_CREATE': generateFormInstanceCreateEvent,
  'ui.forms/instance/SERVER_LOADED': generateFormInstanceViewEvent,
  'ui.forms/instance/SERVER_REVERT_SIGNOFF_SUCCESS': (action, state) =>
    generateFormInstanceIdEvent(action, 'form_reopened', state),
  'ui.forms/instance/SERVER_SIGNOFF_SUCCESS': generateFormInstanceSignOffEvents,
  'ui.shell/csvExport/CONFIRMED': generateDownloadCsvEvent,
  'ui.shell/csvExport/EXPORT_READY_STATUS_CHECK_COMPLETED':
    generateCsvExportResultCompletedEvent,
  'ui.shell/csvExport/EXPORT_READY_STATUS_CHECK_FAILED':
    generateCsvExportResultCompletedEvent,
  'ui.shell/query/ADD_CHIP': generateShellFeatureUsedEvent,
  'ui.shell/query/DROPDOWN_LOADED': generateQueryBuilderInteractionEvent,
  'ui.shell/sheets/TOOLBAR_CLICK': generateSheetUpEvent,
};
