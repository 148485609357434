/** @jsxImportSource @emotion/react */
import { ModalDialog } from '@seeeverything/ui.primitives/src/components/ModalDialog/ModalDialog.tsx';
import { useShellSelector } from '../../../redux/store.ts';

export const ShellModalDialogContainer = () => {
  const actions = useShellSelector((state) => state.modalDialog.actions);
  const content = useShellSelector((state) => state.modalDialog.content);
  const isShowing = useShellSelector((state) => state.modalDialog.isShowing);
  const dialogWidth = useShellSelector((state) => state.modalDialog.width);

  return (
    <ModalDialog
      actions={actions}
      isShowing={isShowing}
      dialogWidth={dialogWidth}
      minDialogWidth={475}
      slideDuration={200}
    >
      {content}
    </ModalDialog>
  );
};
