import gql from 'graphql-tag';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/client/types.ts';

type ArchiveDigitalContentPackFileResponse = {
  digitalContent: {
    archiveDigitalContentPackFile: {
      ok: boolean;
    };
  };
};
export interface IArchiveDigitalContentPackFileResult {
  success: boolean;
  exception?: any;
}

export async function archiveDigitalContentPackFile(
  client: IGraphQLClient,
  digitalContentPackId: string,
  fileId: string,
): Promise<IArchiveDigitalContentPackFileResult> {
  try {
    const response = await client.mutate<ArchiveDigitalContentPackFileResponse>(
      {
        mutation,
        variables: { digitalContentPackId, fileId },
      },
    );

    return succeeded(response.data) ? { success: true } : fail(response.errors);
  } catch (error) {
    return fail(error);
  }
}

const succeeded = (data: ArchiveDigitalContentPackFileResponse) =>
  data?.digitalContent?.archiveDigitalContentPackFile?.ok;

const fail = (exception: any) => {
  log.error(
    `Error while creating digital content pack: ${exception}.`,
    exception,
  );

  return {
    success: false,
    exception,
  };
};

const mutation = gql`
  mutation ArchiveDigitalContentPackFile(
    $digitalContentPackId: ID!
    $fileId: ID!
  ) {
    digitalContent {
      archiveDigitalContentPackFile(
        input: { digitalContentPackId: $digitalContentPackId, fileId: $fileId }
      ) {
        ok
      }
    }
  }
`;
