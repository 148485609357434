/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  ToolButtonClickEvent,
  ToolButtonSet,
} from '@seeeverything/ui.primitives/src/components/ToolButtonSet/index.ts';
import { useTrackInViewport } from '@seeeverything/ui.primitives/src/hooks/useTrackInViewport.ts';
import { propsAreEqualFastCompare } from '@seeeverything/ui.util/src/react/memoFastCompare.ts';
import { memo, useCallback } from 'react';
import { DASHBOARD_INTERSECTION_OPTIONS } from '../../common/constants.ts';
import {
  chartToolButtonClicked,
  gridToolButtonClicked,
} from '../../redux/actions.ts';
import {
  useDashboardsDispatch,
  useDashboardsSelector,
} from '../../redux/store.ts';
import { IDashboardToolButton } from '../../types.ts';

export interface IDashboardToolButtonsContainerProps {
  id: string;
  type: 'CHART_TOOLS' | 'GRID_TOOLS';
  parentId?: string;
}

const View: React.FC<IDashboardToolButtonsContainerProps> = ({
  id,
  type,
  parentId,
}) => {
  const dispatch = useDashboardsDispatch();

  const [forwardedRef] = useTrackInViewport({
    dataId: id,
    parentDataId: parentId,
    observerOptions: DASHBOARD_INTERSECTION_OPTIONS,
  });

  const handleToolButtonClicked = useCallback(
    (e: ToolButtonClickEvent<IDashboardToolButton>) =>
      type === 'CHART_TOOLS'
        ? dispatch(chartToolButtonClicked(e.id.toString(), e.tool.parentId))
        : dispatch(gridToolButtonClicked(e.id.toString(), e.tool.parentId)),
    [dispatch, type],
  );

  const toolState = useDashboardsSelector((state) =>
    type === 'CHART_TOOLS'
      ? state.dashboardsV2.CHART_TOOLS[id]
      : state.dashboardsV2.GRID_TOOLS[id],
  );

  const toolButtons = toolState?.toolButtons ?? [];

  return (
    <div
      css={styles.base}
      data-id={id}
      parent-data-id={parentId}
      key={`tools-outer-${id}`}
      ref={forwardedRef}
    >
      <ToolButtonSet
        toolButtons={toolButtons}
        onClick={handleToolButtonClicked}
      />
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    paddingTop: 10,
    margin: '3px 30px 0px 35px',
  }),
};

export const DashboardToolButtonsContainer = memo(
  View,
  propsAreEqualFastCompare,
);
