/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';

const format = (value?: number | string) =>
  value === undefined || value === '' ? undefined : value;

function combine(
  prefix: 'margin' | 'padding',
  main: number | string | undefined,
  horizontal: number | string | undefined,
  vertical: number | string | undefined,
  top: number | string | undefined,
  right: number | string | undefined,
  bottom: number | string | undefined,
  left: number | string | undefined,
) {
  return {
    [`${prefix}Left`]: format(left || horizontal || main),
    [`${prefix}Right`]: format(right || horizontal || main),
    [`${prefix}Top`]: format(top || vertical || main),
    [`${prefix}Bottom`]: format(bottom || vertical || main),
  };
}

export interface ISpacerProps {
  children?: React.ReactNode;
  block?: boolean;
  display?: string;
  style?: SerializedStyles;
  background?: number | string | boolean;

  marginHorizontal?: number | string;
  marginVertical?: number | string;

  margin?: number | string;
  marginTop?: number | string;
  marginRight?: number | string;
  marginBottom?: number | string;
  marginLeft?: number | string;

  paddingHorizontal?: number | string;
  paddingVertical?: number | string;

  padding?: number | string;
  paddingTop?: number | string;
  paddingRight?: number | string;
  paddingBottom?: number | string;
  paddingLeft?: number | string;
}

/**
 *
 */
export const Spacer = ({
  children,
  style,
  block = false,
  background,
  display,

  marginHorizontal,
  marginVertical,

  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,

  paddingHorizontal,
  paddingVertical,

  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
}: ISpacerProps) => {
  const marginStyles = combine(
    'margin',
    margin,
    marginHorizontal,
    marginVertical,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
  );
  const paddingStyles = combine(
    'padding',
    padding,
    paddingHorizontal,
    paddingVertical,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
  );

  const baseStyles = {
    position: 'relative' as const,
    display: display || (block ? 'inline-block' : 'block'),
    backgroundColor: color.format(background),
  };

  return (
    <div css={css([baseStyles, marginStyles, paddingStyles, style])}>
      {children}
    </div>
  );
};
