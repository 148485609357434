/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { IDataGridCellTheme } from '@seeeverything/ui.primitives/src/components/DataGrid/index.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TextLink } from '@seeeverything/ui.primitives/src/components/TextLink/TextLink.tsx';
import { IGridCellDatapoint, IGridCellDecoration } from '../../types.ts';

export const renderFromDecoration = (
  datapoint: IGridCellDatapoint,
  theme: IDataGridCellTheme,
  decorations: IGridCellDecoration,
) => {
  // If the decorations don't specify anything apart from alignment,
  // let the default grid rendering handle it.
  if (
    decorations.icon === undefined &&
    decorations.launch === undefined &&
    decorations.text === undefined
  ) {
    return datapoint.displayValue;
  }

  const { icon, alignment = 'center' } = decorations;

  const Icon = icon?.icon && Icons[icon.icon];
  const elIcon = Icon && (
    <div css={styles.icon}>
      <Icon fill={icon?.color} size={icon?.size} />
    </div>
  );

  // determine from this check plus divider flag true
  const elLeftDivider = elIcon && decorations.icon?.divider && (
    <div css={styles.horizontalDivider} />
  );

  const TextComponent =
    decorations.link?.handler || decorations.launch?.handler ? TextLink : Text;

  const elText = decorations.text && (
    <div data-test={decorations.dataTest} css={styles.textOuter}>
      <TextComponent
        ellipsis={decorations.text.ellipsis || true}
        size={decorations.text.size || 14}
        weight={decorations.text.weight || theme.fontWeight || 400}
        color={decorations.text.color || theme.textColor}
        italic={decorations.text.italic || theme.italic || false}
        style={css(decorations.text.style || styles.text)}
        onClick={decorations.link?.handler || decorations.launch?.handler}
        tooltip={decorations.tooltip?.body}
        tooltipTheme={decorations.tooltip?.body ? 'LIGHT' : 'DARK'}
      >
        {decorations.text.label ??
          decorations.text.datapoint?.displayValue ??
          datapoint.displayValue}
      </TextComponent>
    </div>
  );

  const computedStyles = {
    base: css(
      styles.base,
      alignment === 'left'
        ? styles.baseLeft
        : alignment === 'right'
          ? styles.baseRight
          : styles.baseCenter,
    ),
  };

  return (
    <div css={computedStyles.base}>
      {elIcon}
      {elLeftDivider}
      {elText}
    </div>
  );
};

const styles = {
  baseLeft: css({
    display: 'flex',
    alignItems: 'center',
  }),
  baseCenter: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  baseRight: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  }),
  base: css({
    width: '100%',
  }),
  icon: css({ display: 'flex' }),
  horizontalDivider: css({ minWidth: 5 }),
  textOuter: css({
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  text: { width: '100%' },
};
