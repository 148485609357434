/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  ActionBar,
  IActionBarButton,
} from '@seeeverything/ui.primitives/src/components/ModalDialog/index.ts';
import { ScrollContainer } from '@seeeverything/ui.primitives/src/components/Scroll/ScrollContainer.tsx';
import Spinner from '@seeeverything/ui.primitives/src/components/Spinner/Spinner.tsx';
import Transition from '@seeeverything/ui.primitives/src/components/Transition/Transition.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { useMemo } from 'react';
import { editInstanceScheduleSlice } from '../../../redux/editInstanceSchedule/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../../redux/store.ts';
import { InstanceScheduleFieldsContainer } from './InstanceScheduleFieldsContainer.tsx';
import { InstanceScheduleStatusBarContainer } from './InstanceScheduleStatusBarContainer.tsx';

export const InstanceScheduleEditPanelContainer: React.FC = () => {
  const dispatch = useFormsDispatch();

  const isSaving = useFormsSelector(
    (state) => state.editInstanceSchedule.isSaving,
  );

  const hasChanges = useFormsSelector(
    (state) => state.editInstanceSchedule.hasDraftChanged,
  );

  const original = useFormsSelector(
    (state) => state.editInstanceSchedule.original,
  );

  const canSave = useMemo(() => {
    if (!hasChanges) return false;
    if (!original) return true;
    if (original.status === 'NotStarted') return true;
    if (original.status === 'Started') return true;
    return false;
  }, [hasChanges, original]);

  const dismissActions = useMemo<IActionBarButton>(
    () => ({
      id: 'CANCEL',
      label: 'Cancel',
      isEnabled: !isSaving,
      backgroundAlways: false,
      onAction: () => dispatch(editInstanceScheduleSlice.dismiss()),
    }),
    [isSaving, dispatch],
  );

  const saveAction = useMemo<IActionBarButton>(
    () => ({
      id: 'SAVE',
      label: 'Save',
      isEnabled: canSave && !isSaving,
      onAction: () => dispatch(editInstanceScheduleSlice.save()),
    }),
    [dispatch, canSave, isSaving],
  );

  return (
    <div css={styles.base}>
      <InstanceScheduleStatusBarContainer />
      <div css={styles.savingContainer}>
        <Transition.Fade in={isSaving} mountOnEnter={true} unmountOnExit={true}>
          <div css={styles.savingOverlay}>
            <Spinner />
          </div>
        </Transition.Fade>
        <ScrollContainer>
          <div css={styles.fieldsContainer}>
            <InstanceScheduleFieldsContainer />
          </div>
        </ScrollContainer>
      </div>
      <ActionBar
        actions={[dismissActions, saveAction]}
        style={styles.footerActions}
      />
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: 'white',
  }),
  fieldsContainer: css({
    padding: '5px 30px 5px 30px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: 'white',
  }),
  footerActions: css({
    borderTop: 'none',
    backgroundColor: 'rgb(237, 237, 237)',
    padding: '5px 15px 5px 15px',
  }),
  savingOverlay: css({
    position: 'absolute',
    inset: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    background: color.format(0.8),
    zIndex: 1,
  }),
  savingContainer: css({
    position: 'relative',
    flex: '1 1 auto',
  }),
};
