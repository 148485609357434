/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { DropdownList } from '@seeeverything/ui.primitives/src/components/DropdownList/DropdownList.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { IconButton } from '@seeeverything/ui.primitives/src/components/IconButton/IconButton.tsx';
import { Popup } from '@seeeverything/ui.primitives/src/components/Popup/Popup.tsx';
import {
  ISelectionListItem,
  SelectionListClickEventHandler,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { Transition } from '@seeeverything/ui.primitives/src/components/Transition/index.ts';
import { useMouseOver } from '@seeeverything/ui.primitives/src/hooks/useMouseOver.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import React from 'react';
import { IDesignerCommonProps } from '../types.ts';

export interface IAddComponentListProps extends IDesignerCommonProps {
  showBullet?: boolean;
  beforeId?: string | number;
  expand?: boolean;
  dataTest?: string;
}

export const AddComponentList = ({
  beforeId,
  onEventOccurred,
  showBullet,
  dataTest,
}: IAddComponentListProps) => {
  const [isMouseOver, handleMouseEnter, handleMouseLeave] = useMouseOver();
  const [isListShowing, setIsListShowing] = React.useState(false);
  const hideList = () => setIsListShowing(false);
  const showList = () => setIsListShowing(true);

  const highlight = isMouseOver || isListShowing;

  const handleClick = React.useCallback(
    (insertBeforeId?: string | number): SelectionListClickEventHandler =>
      (e) => {
        if (e.item?.value)
          onEventOccurred?.(e.item.value, 'ADD', { insertBeforeId });
      },
    [onEventOccurred],
  );

  const elHorizontal = (
    <Transition.Fade in={highlight}>
      <div css={styles.horizontal} />
    </Transition.Fade>
  );
  const elIcon = showBullet !== false && <PlusIcon />;

  const elList = isListShowing && (
    <Popup onHide={hideList}>
      <DropdownList
        items={SELECTION_ITEMS}
        onClick={handleClick(beforeId)}
        height={277}
      />
    </Popup>
  );

  return (
    <div
      css={styles.base}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={showList}
      data-test={dataTest}
    >
      {elHorizontal}
      {elIcon}
      {elList}
    </div>
  );
};

const PlusIcon = () => (
  <div css={styles.plusIconOuter}>
    <IconButton
      icon={<Icons.plus />}
      fill={COLORS.BLUE}
      tooltip={'Add a new component into the template'}
    />
  </div>
);

const styles = {
  base: css({
    position: 'relative',
    height: 28,
    paddingTop: 13,
    cursor: 'pointer',
  }),
  plusIconOuter: css({
    position: 'absolute',
    top: 1,
    left: -36,
  }),
  horizontal: css({
    borderTop: `2px solid ${COLORS.BLUE}`,
    height: 0,
  }),
};

const SELECTION_ITEMS: ISelectionListItem[] = [
  { id: 'section', type: 'SECTION', content: 'Add New Answer' },
  {
    id: 'heading',
    icon: Icons.textFormat,
    value: 'heading',
    content: 'Heading',
  },
  {
    id: 'speechBlock',
    icon: Icons.chatBubbleOutline,
    value: 'speechBlock',
    content: 'Speech Block',
  },
  {
    id: 'textBlock',
    icon: Icons.fontDownload,
    value: 'textBlock',
    content: 'Text Block',
  },
  {
    id: 'text',
    icon: Icons.textFormat,
    value: 'text',
    content: 'Text Input',
  },
  {
    id: 'switch',
    icon: Icons.thumbsUpDown,
    value: 'switch',
    content: 'Yes/No',
  },
  {
    id: 'options',
    icon: Icons.radioButtonChecked,
    value: 'options',
    content: 'Options',
  },
];
