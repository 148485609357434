/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormActionAuditEntry } from '@se/data/forms/query/actionAuditTypes.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Markdown } from '@seeeverything/ui.primitives/src/components/Markdown/Markdown.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import {
  FormatString,
  TenantLocale,
} from '@seeeverything/ui.util/src/redux/tenant/types.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import moment from 'moment';
import { useMemo } from 'react';

export interface IActionAuditEntryProps {
  entry: FormActionAuditEntry;
  locale: TenantLocale;
}

export const ActionAuditEntry: React.FC<IActionAuditEntryProps> = ({
  entry,
  locale,
}) => {
  const Icon = Icons[entry.icon];

  const formattedString = useMemo(() => {
    const formatter = locale.auditing.forms.action[entry.actionType];
    if (!formatter) return entry.action;

    const formatArgs = { locale, ...entry.payload };
    return tryStringFormat(formatter, formatArgs);
  }, [entry.action, entry.actionType, entry.payload, locale]);

  return (
    <div css={styles.auditContainer}>
      <Icon style={{ marginRight: 20 }} fill={color.format(-0.5)} />
      <div css={styles.auditContent}>
        <Markdown text={`**${entry.actionedBy.name}** ${formattedString}`} />
        <Text size={'small'}>
          {moment(entry.actionedAt).format('D MMM YYYY, h:mm a')}
        </Text>
      </div>
    </div>
  );
};

const tryStringFormat = (formatter: FormatString, args?: any) => {
  try {
    const format = typeof formatter === 'string' ? formatter : formatter(args);
    return str.format(format, args);
  } catch (error) {
    log.error('Unable to format message for action audit', error);
    return '[[unable to load the translation for this action]]';
  }
};

const styles = {
  auditContent: css({
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    maxWidth: 'calc(100% - 40px)',
  }),
  auditContainer: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'center',
    padding: 20,
    margin: -10,
    ':hover': {
      backgroundColor: color.format(-0.04),
    },
  }),
};
