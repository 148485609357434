import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';

type InitializePublishPayload = {
  name?: string;
  sourceTemplate?: ISelectionListItem;
  category?: ISelectionListItem;
};

type PublishedTemplatePayload = {
  id: string;
  name: string;
  templateId?: string;
};

type ValidationOverwriteExistingPayload = {
  existingTemplate?: string;
};

type ValidationNewPayload = {
  name?: string;
  category?: string;
  visibility?: string;
};

export type FormsDesignerReduxPublishTemplatesState = {
  selectedMethod: 'NEW' | 'OVERWRITE_EXISTING';
  isSpinning: boolean;
  generalError?: string;
  createNewInputs: {
    name?: string;
    category?: ISelectionListItem;
    visibility?: ISelectionListItem;
    validationErrors: {
      name?: string;
      category?: string;
      visibility?: string;
    };
  };
  overwriteExistingInputs: {
    existingTemplate?: ISelectionListItem;
    validationErrors: {
      existingTemplate?: string;
    };
  };
};

const DEFAULT_STATE: FormsDesignerReduxPublishTemplatesState = {
  selectedMethod: 'NEW',
  isSpinning: false,
  createNewInputs: { validationErrors: {} },
  overwriteExistingInputs: { validationErrors: {} },
};

const slice = createSlice({
  name: 'libs/formsDesigner/templates/publish',
  initialState: DEFAULT_STATE,
  reducers: {
    cancelPublish(state) {
      state.selectedMethod = 'NEW';
      state.isSpinning = false;
      state.createNewInputs = { validationErrors: {} };
      state.overwriteExistingInputs = { validationErrors: {} };
    },
    initialize(state, action: PayloadAction<InitializePublishPayload>) {
      state.createNewInputs.name = action.payload.name;
      state.createNewInputs.category = action.payload.category;
      state.overwriteExistingInputs.existingTemplate =
        action.payload.sourceTemplate;
    },
    setSelectedMethod(
      state,
      action: PayloadAction<'NEW' | 'OVERWRITE_EXISTING'>,
    ) {
      state.selectedMethod = action.payload;
      state.generalError = undefined;
    },
    publishTemplate(state) {
      state.isSpinning = true;
      state.generalError = undefined;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    publishedTemplate(state, action: PayloadAction<PublishedTemplatePayload>) {
      state.isSpinning = false;
      state.generalError = undefined;
    },
    publishTemplateFailed(
      state,
      action: PayloadAction<{ errorMessage: string }>,
    ) {
      state.isSpinning = false;
      state.generalError = action.payload.errorMessage;
    },
    updateCategoryFieldOnNew(state, action: PayloadAction<ISelectionListItem>) {
      state.createNewInputs.category = action.payload;
      state.createNewInputs.validationErrors.category = undefined;
      state.generalError = undefined;
    },
    updateNameFieldOnNew(state, action: PayloadAction<string>) {
      state.createNewInputs.name = action.payload;
      state.createNewInputs.validationErrors.name = undefined;
      state.generalError = undefined;
    },
    updateVisibilityFieldOnNew(
      state,
      action: PayloadAction<ISelectionListItem>,
    ) {
      state.createNewInputs.visibility = action.payload;
      state.createNewInputs.validationErrors.visibility = undefined;
      state.generalError = undefined;
    },
    updateExistingTemplateFieldOnOverwrite(
      state,
      action: PayloadAction<ISelectionListItem>,
    ) {
      state.overwriteExistingInputs.existingTemplate = action.payload;
      state.overwriteExistingInputs.validationErrors.existingTemplate =
        undefined;
      state.generalError = undefined;
    },
    validationErrorsOnNew(state, action: PayloadAction<ValidationNewPayload>) {
      const { category, name, visibility } = action.payload;
      state.createNewInputs.validationErrors.category = category;
      state.createNewInputs.validationErrors.name = name;
      state.createNewInputs.validationErrors.visibility = visibility;
    },
    validationErrorsOnOverwriteExisting(
      state,
      action: PayloadAction<ValidationOverwriteExistingPayload>,
    ) {
      state.overwriteExistingInputs.validationErrors.existingTemplate =
        action.payload.existingTemplate;
    },
  },
});

export const {
  cancelPublish,
  initialize,
  publishTemplate,
  publishedTemplate,
  publishTemplateFailed,
  setSelectedMethod,
  updateCategoryFieldOnNew,
  updateNameFieldOnNew,
  updateVisibilityFieldOnNew,
  updateExistingTemplateFieldOnOverwrite,
  validationErrorsOnNew,
  validationErrorsOnOverwriteExisting,
} = slice.actions;
export const reducer = slice.reducer;
export type State = FormsDesignerReduxPublishTemplatesState;
