import { FormLineById } from '@seeeverything/ui.forms/src/redux/form-instance/types.ts';
import { IInputsLine } from '@seeeverything/ui.forms/src/types/types.ts';

/**
 * Locates a nested input by inputId from the supplied form lines.
 */
export const findInputById = (lines: FormLineById, id: string) =>
  Object.values(lines)
    .filter((line) => line.type === 'inputs')
    .flatMap((line: IInputsLine) => line.inputs || [])
    .find((input) => input.id === id);

/**
 * Locates a line by one of its inputs (id).
 */
export const findLineByInputId = (
  lines: FormLineById,
  inputId: string,
): IInputsLine | undefined =>
  Object.values(lines).find(
    (line) =>
      line.type === 'inputs' &&
      (line.inputs || []).some((input) => input.id === inputId),
  ) as IInputsLine | undefined;

/**
 * Removes any lines that match the supplied ids or inputIds.
 */
export const removeIds = (
  lines: FormLineById,
  ids: string[],
  inputIds: string[],
): FormLineById => {
  const updated = { ...lines };
  for (const key of ids) {
    delete updated[key];
  }

  const textIdsToDelete = Object.keys(updated)
    .filter((key) => updated[key].type === 'inputs')
    .filter((key) => {
      const inputs = (updated[key] as IInputsLine).inputs || [];
      return inputs.some((input) => inputIds.some((id) => input.id === id));
    });

  for (const key of textIdsToDelete) {
    delete updated[key];
  }

  return updated;
};
