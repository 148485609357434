import { keyGenerator } from './state/util.ts';
import {
  IInternalState,
  IInternalEditingState,
  IInternalSelectionState,
} from '../types.ts';
import {
  IQuery,
  IEntityDictionary,
  IQuerySelection,
  IChipData,
} from '../../../api/api.queryBuilder/types.ts';

/**
 * Serialize the internal Query Builder state to an external, conformant, consistent representation.
 * Strips any UI state.
 * @param state the internal state of the Query Builder
 */
export function serialize(state: IInternalState): IQuery {
  const chips = state.chips.map((chip) => ({
    type: chip.data.type,
    value: chip.data.value,
    label: chip.data.label,
    icon: chip.data.icon,
  }));

  const filter = state.textInput;

  return {
    chips,
    filter,
  };
}

/**
 * Deserializes data into an internal state representation.
 */
export interface IDeserializeOptions {
  entities: IEntityDictionary;
  query?: IQuery;
  selection?: IQuerySelection;
}
export function deserialize(options: IDeserializeOptions): IInternalState {
  const { entities, query, selection } = options;
  const inputChips = (query && query.chips) || [];
  const filter = (query && query.filter) || '';

  // Chips.
  const chips = inputChips.map((inputChip) => ({
    key: keyGenerator(),
    data: {
      type: inputChip.type,
      value: inputChip.value,
      label: inputChip.label,
      icon: inputChip.icon,
    },
  }));

  const chipKey = (chip: IChipData) =>
    chips.find(
      ({ data }) => data.type === chip.type && data.value === chip.value,
    )?.key;

  // Selection.
  const editingChipKey =
    selection?.type === 'EDITING' ? chipKey(selection.chip) : undefined;

  const selectedChipKey =
    selection &&
    (selection.type === 'SELECTED' || selection.type === 'DROPDOWN')
      ? chipKey(selection.chip)
      : undefined;

  const editingChip: IInternalEditingState | undefined = editingChipKey
    ? { key: editingChipKey }
    : undefined;

  const selectionType =
    selection?.type === 'DROPDOWN' ? 'DROPDOWN' : 'SELECTED';

  const selectedChip: IInternalSelectionState | undefined = selectedChipKey
    ? { key: selectedChipKey, type: selectionType }
    : undefined;

  const data: IInternalState = {
    chips,
    editingChip,
    selectedChip,
    textInput: filter,
    entityDictionary: entities,
    textInputFocused: false,
    inputBlurred: false,
  };

  return data;
}
