/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { GoalScheduleStatus } from '@se/data/forms/query/goalScheduleTypes.ts';
import { FormGoalCategory } from '@se/data/forms/types.ts';
import { DistributionList } from '@se/data/orgHierarchy/query/distributionList.ts';
import { orgHierarchyQuery } from '@se/data/orgHierarchy/query/index.ts';
import { DatePicker } from '@seeeverything/ui.primitives/src/components/DatePicker/DatePicker.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { IconWrapper } from '@seeeverything/ui.primitives/src/components/IconWrapper/IconWrapper.tsx';
import {
  IListItemLabel,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { Switch } from '@seeeverything/ui.primitives/src/components/Switch/Switch.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { TextFieldDropdown } from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';
import { Transition } from '@seeeverything/ui.primitives/src/components/Transition/index.ts';
import {
  dayAfterDate,
  tomorrowInTenantTz,
  useDateContext,
  useMomentTenantTimezone,
} from '@seeeverything/ui.primitives/src/hooks/useDateContext.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useGraphQL } from '@seeeverything/ui.util/src/graphql/GraphQLProvider.tsx';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { editGoalScheduleSlice } from '../../../redux/editGoalSchedule/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../../redux/store.ts';
import { STATUS_SELECTIONS } from './constants.ts';

export type GoalScheduleFieldsContainerProps = {
  goalCategories: FormGoalCategory[];
  loadingGoalCategories: boolean;
};

export const GoalScheduleFieldsContainer: React.FC<
  GoalScheduleFieldsContainerProps
> = ({ goalCategories, loadingGoalCategories }) => {
  const { client } = useGraphQL();
  const dispatch = useFormsDispatch();

  const dateContext = useDateContext();
  const momentTenantTimezone = useMomentTenantTimezone();
  const tomorrow = tomorrowInTenantTz(dateContext.tenantTimezone);

  const goalAdditionalFields = useFormsSelector(
    (state) => state.editGoalSchedule.draft.goalAdditionalFields,
  );

  const fieldErrors = useFormsSelector(
    (state) => state.editGoalSchedule.errors.fieldErrors,
  );

  const module = useFormsSelector((state) => state.tenantState.tenant.module);

  const original = useFormsSelector((state) => state.editGoalSchedule.original);

  const draft = useFormsSelector((state) => state.editGoalSchedule.draft);

  const isStatusEditable = useMemo(() => {
    if (!original) return false;
    if (original.status !== 'Inactive') return true;

    if (momentTenantTimezone(original.startDate) > momentTenantTimezone())
      return true;

    if (!original.endDate) return false;

    return momentTenantTimezone(original.endDate) > momentTenantTimezone();
  }, [momentTenantTimezone, original]);

  const hasGoalCategories = useMemo(
    () => Boolean(goalCategories.length),
    [goalCategories],
  );

  const formGoalCategorySelections = useMemo(() => {
    if (!goalCategories) return [];
    return goalCategories.map(
      (goalCategory): ISelectionListItem<IListItemLabel, FormGoalCategory> => ({
        id: goalCategory.id,
        content: { text: goalCategory.name },
        value: goalCategory,
        icon: Icons.myLocation,
        iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
      }),
    );
  }, [goalCategories]);

  const formGoalCategorySelection = useMemo(() => {
    if (!draft.goalCategory) return;

    const defaultSelection = {
      id: draft.goalCategory.id,
      content: { text: draft.goalCategory.name },
    };

    if (!formGoalCategorySelections.length) return defaultSelection;

    const result = formGoalCategorySelections.find(
      (item) => item.id === draft.goalCategory.id,
    );
    return result ?? defaultSelection;
  }, [formGoalCategorySelections, draft.goalCategory]);

  const {
    data: distributionListData,
    isLoading: isLoadingDistributionListData,
  } = useQuery({
    placeholderData: keepPreviousData,
    queryKey: [{ key: 'goalSchedules.distributionLists' }],
    queryFn: async () => {
      const response = await orgHierarchyQuery.getDistributionLists(client, {
        fetchAllPages: true,
        module,
      });

      return response.isSuccess ? response.data : null;
    },
  });

  const distributionListSelections = useMemo(() => {
    if (!distributionListData) return [];
    return distributionListData.distributionLists.map(
      (list): ISelectionListItem<IListItemLabel, DistributionList> => ({
        id: list.id,
        content: { text: list.name },
        value: list,
        icon: Icons.list,
        iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
      }),
    );
  }, [distributionListData]);

  const distributionListSelection = useMemo(() => {
    if (!draft.distributionList) return;

    const defaultSelection = {
      id: draft.distributionList.id,
      content: { text: draft.distributionList.name },
    };

    if (!distributionListSelections.length) return defaultSelection;

    const result = distributionListSelections.find(
      (item) => item.id === draft.distributionList.id,
    );
    return result ?? defaultSelection;
  }, [distributionListSelections, draft.distributionList]);

  const canEdit = original?.status !== 'Inactive';
  const hasStarted = original?.status === 'Started';

  const statusSelections = useMemo(() => {
    if (!original) return [];

    return STATUS_SELECTIONS.filter((selection) => {
      if (selection.value === 'Inactive') return true;
      if (selection.value === original.status) return true;
      return original.status === 'Inactive' && selection.value === 'Active';
    });
  }, [original]);

  const statusSelection = useMemo(
    () =>
      STATUS_SELECTIONS.find((selection) => selection.value === draft.status),
    [draft.status],
  );

  const elAdditionalFields = useMemo(
    () =>
      goalAdditionalFields.map((additionalField) => (
        <div css={styles.row} key={`additional-field-${additionalField.key}`}>
          <TextField
            id={`additional-field-${additionalField.key}`}
            label={additionalField.label}
            value={additionalField.value}
            isEnabled={canEdit && !hasStarted}
            onChange={(to) =>
              dispatch(
                editGoalScheduleSlice.updateGoalAdditionalField({
                  fieldId: additionalField.key,
                  value: to,
                }),
              )
            }
            style={styles.field}
            maxRows={5}
          />
        </div>
      )),
    [canEdit, dispatch, goalAdditionalFields, hasStarted],
  );

  const handleUpdateGoalDescription = useCallback(
    (to: string) => {
      dispatch(editGoalScheduleSlice.updateGoalDescription(to));
    },
    [dispatch],
  );

  const handleGoalCategoryChanged = useCallback(
    (to: FormGoalCategory) => {
      dispatch(editGoalScheduleSlice.updateGoalCategory(to));
    },
    [dispatch],
  );

  const handleUpdateGoalDueDate = useCallback(
    (to: string) => {
      dispatch(editGoalScheduleSlice.updateGoalDueDate(to));
    },
    [dispatch],
  );

  const handleUpdateDistributionList = useCallback(
    (to: DistributionList) => {
      dispatch(editGoalScheduleSlice.updateDistributionList(to));
    },
    [dispatch],
  );

  const handleUpdateStartDate = useCallback(
    (to: string) => {
      dispatch(editGoalScheduleSlice.updateStartDate(to));
    },
    [dispatch],
  );

  const handleUpdateEndDate = useCallback(
    (to: string) => {
      dispatch(editGoalScheduleSlice.updateEndDate(to));
    },
    [dispatch],
  );

  const handleUpdateApplyToNewStaff = useCallback(
    (to: boolean) => {
      dispatch(editGoalScheduleSlice.updateApplyToNewStaff(to));
    },
    [dispatch],
  );

  const handleUpdateStatus = useCallback(
    (to: ISelectionListItem<IListItemLabel, GoalScheduleStatus>) => {
      dispatch(editGoalScheduleSlice.updateStatus(to.value));
    },
    [dispatch],
  );

  return (
    <>
      <Text style={styles.title}>{'Goal'}</Text>
      <div css={styles.row}>
        <TextField
          id={'Description'}
          label={'Description'}
          value={draft.goalDescription}
          isEnabled={canEdit && !hasStarted}
          onChange={handleUpdateGoalDescription}
          error={fieldErrors.goalDescription}
          style={styles.field}
          maxRows={10}
        />
      </div>
      {elAdditionalFields}
      {hasGoalCategories && (
        <div css={styles.row}>
          <IconWrapper icon={'myLocation'} style={styles.field}>
            <TextFieldDropdown
              id={'goalCategory'}
              value={formGoalCategorySelection}
              selections={formGoalCategorySelections}
              isLoading={loadingGoalCategories}
              isEnabled={canEdit && !hasStarted}
              label={'Goal Category'}
              onChange={handleGoalCategoryChanged}
              error={fieldErrors.goalCategory}
            />
          </IconWrapper>
        </div>
      )}
      <IconWrapper icon={'notifications'} style={styles.field}>
        <DatePicker
          id={'dueDate'}
          isEnabled={canEdit && !hasStarted}
          value={draft.goalDueDate}
          label={'Due Date'}
          onChange={handleUpdateGoalDueDate}
          minDate={draft.startDate ?? tomorrow}
          maxDate={draft.endDate}
          error={fieldErrors.goalDueDate}
          dataTest={'forms-goalScheduleFields-dueDateField'}
        />
      </IconWrapper>

      <Text style={styles.title}>{'Schedule'}</Text>

      <div css={styles.row}>
        <IconWrapper icon={'list'} style={styles.field}>
          <TextFieldDropdown
            id={'distributionList'}
            isEnabled={canEdit && !hasStarted}
            value={distributionListSelection}
            selections={distributionListSelections}
            isLoading={isLoadingDistributionListData}
            label={'Distribution List'}
            onChange={handleUpdateDistributionList}
            error={fieldErrors.distributionList}
          />
        </IconWrapper>
      </div>
      <div css={styles.row}>
        <IconWrapper icon={'dateRange'} style={styles.field}>
          <DatePicker
            id={'startDate'}
            isEnabled={canEdit && !hasStarted}
            value={draft.startDate}
            label={'Start Date'}
            onChange={handleUpdateStartDate}
            minDate={tomorrow}
            maxDate={draft.goalDueDate ?? draft.endDate}
            error={fieldErrors.startDate}
            dataTest={'forms-goalScheduleFields-startDateField'}
          />
        </IconWrapper>
        <Switch
          label={'Apply to new staff?'}
          isChecked={draft.applyToNewStaff ?? false}
          onChange={handleUpdateApplyToNewStaff}
          isEnabled={canEdit}
          style={styles.switch}
          labelStyle={styles.switchLabel}
          dataTest={'forms-goalScheduleFields-applyToNewStaffSwitch'}
          tooltip={
            'Staff added to the distribution list after the schedule has started will also have the goal created for them.'
          }
        />
        <Transition.Collapse
          in={draft.applyToNewStaff}
          style={styles.transition}
        >
          {draft.applyToNewStaff && (
            <IconWrapper icon={'dateRange'} style={styles.field}>
              <DatePicker
                id={'endDate'}
                isEnabled={canEdit}
                value={draft.endDate}
                label={'End Date'}
                onChange={handleUpdateEndDate}
                minDate={
                  draft.startDate
                    ? dayAfterDate(draft.startDate)
                    : dayAfterDate(tomorrow)
                }
                error={fieldErrors.endDate}
                dataTest={'forms-goalScheduleFields-endDateField'}
              />
            </IconWrapper>
          )}
        </Transition.Collapse>
      </div>
      <div css={styles.row}>
        <IconWrapper icon={'settings'} style={styles.field}>
          <TextFieldDropdown
            id={'status'}
            label={'Status'}
            value={statusSelection}
            selections={statusSelections}
            isEnabled={isStatusEditable}
            onChange={handleUpdateStatus}
          />
        </IconWrapper>
        {original && (
          <IconWrapper icon={'face'} style={styles.field}>
            <TextField
              id={'updatedBy'}
              label={'Last Updated By'}
              value={original.updatedBy.name}
              isEnabled={false}
            />
          </IconWrapper>
        )}
      </div>
    </>
  );
};

const styles = {
  row: css({
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
  }),
  title: css({
    fontWeight: FontWeight.bold,
    fontSize: 18,
    color: color.format(-0.6),
    padding: '20px 5px 20px 5px',
    display: 'flex',
    flex: '100%',
  }),
  field: css({
    flex: '1 1 240px',
    padding: 5,
    width: 'auto',
  }),
  switch: css({
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: 15,
  }),
  switchLabel: {
    marginLeft: 0,
  },
  transition: {
    flex: '1 1 auto',
  },
};
