/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { ContentPlaceholder } from '../ContentPlaceholder/ContentPlaceholder.tsx';
import { IDataGridProps } from './DataGrid.tsx';

export const DataGridIsLoadingBehavior = (
  DataGrid: React.FC<IDataGridProps>,
): React.FC<IDataGridProps> =>
  function DataGridIsLoading({ isLoading, ...rest }) {
    const [hasLoadedOnce, setHasLoadedOnce] = useState(false);

    useEffect(() => {
      if (hasLoadedOnce) return;
      if (!isLoading) setHasLoadedOnce(true);
    }, [hasLoadedOnce, isLoading]);

    if (!hasLoadedOnce && isLoading)
      return (
        <div css={styles.loadingPlaceholder}>
          <ContentPlaceholder kind={'LIST'} emptyText={''} hintText={''} />
        </div>
      );

    return (
      <div>
        <div css={isLoading ? styles.mask : undefined}>
          <DataGrid {...rest} isLoading={isLoading} />
        </div>
      </div>
    );
  };

const styles = {
  loadingPlaceholder: css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 300,
    width: '100%',
    textAlign: 'center',
    paddingTop: 50,
  }),
  mask: css({
    opacity: 0.6,
  }),
};
