import { StateObservable, combineEpics, ofType } from 'redux-observable';
import { Observable, concatAll, filter, from, map } from 'rxjs';
import { logout } from '../app/actions.ts';
import { RemoveSheetAction, SheetChangeModuleAction } from '../sheets/types.ts';
import { ShellAction, ShellGlobalState } from '../types.ts';
import {
  chipRemovedExternally,
  next,
  queryChangedFromSheet,
} from './actions.ts';
import {
  QueryBuilderChipAddedExternallyAction,
  QueryBuilderChipRemovedExternallyAction,
  QueryBuilderNextAction,
  QueryBuilderReplaceChipsAction,
} from './types.ts';

export const epics = combineEpics<ShellAction, ShellAction, ShellGlobalState>(
  nextQueryEpic,
  queryOnChipsReplacedEpic,
  changeModuleChipFromSheetEpic,
  removeChipOnRemoveSheetEpic,
  logoutEpic,
);

/**
 * When the query builder is updated externally, issue a `next` to
 * load/unload sheets to reflect the externally updated query builder.
 */
function nextQueryEpic(
  action$: Observable<
    | QueryBuilderChipAddedExternallyAction
    | QueryBuilderChipRemovedExternallyAction
  >,
  state$: StateObservable<ShellGlobalState>,
) {
  return action$.pipe(
    ofType('ui.shell/query/ADD_CHIP', 'ui.shell/query/REMOVE_CHIP'),
    filter((action) => action.payload.shouldRunQuery === true),
    map(({ payload: { eventName } }) => {
      const query = state$.value.query.query;
      return next('CODE', query, undefined, undefined, eventName);
    }),
  );
}

function queryOnChipsReplacedEpic(
  action$: Observable<QueryBuilderReplaceChipsAction>,
  state$: StateObservable<ShellGlobalState>,
) {
  return action$.pipe(
    ofType('ui.shell/query/REPLACE_CHIPS'),
    map(() => {
      const query = state$.value.query.query;
      return next('CODE', query, undefined, undefined);
    }),
  );
}
/**
 * Changes the module in the query builder when the change is launched from within a sheet.
 */
function changeModuleChipFromSheetEpic(
  action$: Observable<SheetChangeModuleAction>,
  state$: StateObservable<ShellGlobalState>,
) {
  return action$.pipe(
    ofType('ui.shell/sheets/CHANGE_MODULE'),
    map((action) =>
      from([
        next('CODE', state$.value.query.query),
        queryChangedFromSheet(
          state$.value.query.query,
          action.payload.to,
          action.payload.source,
        ),
      ]),
    ),
    concatAll(),
  );
}

function removeChipOnRemoveSheetEpic(action$: Observable<RemoveSheetAction>) {
  return action$.pipe(
    ofType('ui.shell/sheets/REMOVE_SHEET'),
    map(() => chipRemovedExternally(true)),
  );
}

function logoutEpic(action$: Observable<QueryBuilderNextAction>) {
  return action$.pipe(
    ofType('ui.shell/query/NEXT'),
    filter((action) =>
      Boolean(
        (action.payload.query.chips || []).find(
          (chip) => chip.type === 'logout',
        ),
      ),
    ),
    map(logout),
  );
}
