/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CommonStyles } from '@seeeverything/ui.primitives/src/common/commonStyles.ts';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { useCallback, useEffect } from 'react';
import { formActionSlice } from '../../redux/formAction/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../redux/store.ts';
import { ActionAuditEntry } from './components/ActionAuditEntry.tsx';
import { ActionAuditSkeleton } from './components/ActionAuditSkeleton.tsx';

export const ActionAuditContainer: React.FC = () => {
  const dispatch = useFormsDispatch();

  const actionId = useFormsSelector((state) => state.formActionV2.id);
  const locale = useFormsSelector((state) => state.tenantState.tenant.locale);

  const historyActionId = useFormsSelector(
    (state) => state.formActionV2.audit.actionId,
  );

  useEffect(() => {
    if (actionId === historyActionId) return;

    dispatch(formActionSlice.loadHistory({ actionId }));
  }, [actionId, dispatch, historyActionId]);

  const isLoading = useFormsSelector(
    (state) => state.formActionV2.audit.isLoading,
  );

  const loadingError = useFormsSelector(
    (state) => state.formActionV2.audit.loadingError,
  );

  const entries = useFormsSelector((state) => state.formActionV2.audit.entries);

  const handleReload = useCallback(
    () => dispatch(formActionSlice.loadHistory({ actionId })),
    [actionId, dispatch],
  );

  return (
    <div css={styles.container}>
      {isLoading && <ActionAuditSkeleton />}
      {!isLoading &&
        !loadingError &&
        entries.map((entry, index) => (
          <ActionAuditEntry
            key={`${entry.actionType}-${index}`}
            entry={entry}
            locale={locale}
          />
        ))}
      {loadingError && (
        <Button
          style={styles.loadError}
          onClick={handleReload}
          hoverAlpha={0.18}
          fill={COLORS.ORANGE_TRANSPARENT}
        >
          <>
            <Icons.contactSupport
              size={95}
              fill={COLORS.ORANGE_LIGHT}
              style={{ paddingBottom: 5 }}
            />
            <Text
              color={COLORS.ORANGE_DARK}
              size={18}
              align={'center'}
              cursor={'inherit'}
            >
              {`We aren't able to show history right now.`}
            </Text>
            <Text
              color={COLORS.ORANGE_DARK}
              size={14}
              align={'center'}
              cursor={'inherit'}
            >
              {`Click here to reload. If this problem continues, please try again later.`}
            </Text>
          </>
        </Button>
      )}
    </div>
  );
};

const styles = {
  container: css({
    ...CommonStyles.BoxShadowGroup,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    padding: 12,
    maxHeight: 300,
    overflowY: 'auto',
  }),
  loadError: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    padding: 12,
  } as const,
};
