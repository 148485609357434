/** @jsxImportSource @emotion/react */
import { IDataGridProps } from '@seeeverything/ui.primitives/src/components/DataGrid/DataGrid.tsx';
import { GridClickEvent } from '@seeeverything/ui.primitives/src/components/Grid/index.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { useCallback, useState } from 'react';
import { ColumnId, DataGridColumnFilter } from '../../types/types.ts';
import { DataGridFilterPopupContainer } from './components/DataGridFilterPopupContainer.tsx';

export interface IDataGridFilteringBehaviorProps {
  columnSelectedOrderBys: Record<ColumnId, 'Ascending' | 'Descending'>;
  columnSelectedFilters: Record<ColumnId, DataGridColumnFilter[]>;
  onColumnSort: (
    gridId: string,
    columnId: string,
    direction: 'Ascending' | 'Descending',
  ) => void;
  onColumnFilterSelection: (
    gridId: string,
    columnId: string,
    filter: DataGridColumnFilter,
    to: boolean,
  ) => void;
  onColumnFilterClearAll: (gridId: string, columnId: string) => void;
}

export const DataGridFilteringBehavior = (
  DataGrid: React.FC<IDataGridProps>,
): React.FC<IDataGridProps & IDataGridFilteringBehaviorProps> =>
  function FilterSortableDataGrid({
    id,
    columns,
    columnSelectedOrderBys,
    columnSelectedFilters,
    onColumnSort,
    onColumnFilterSelection,
    onColumnFilterClearAll,
    ...rest
  }) {
    const [showPopupForColumnId, setShowPopupForColumnId] = useState<string>();

    const handleColumnSort = useCallback(
      (gridId: string, columnId: string, direction: 'ASC' | 'DESC') => {
        onColumnSort(
          gridId,
          columnId,
          direction === 'ASC' ? 'Ascending' : 'Descending',
        );
      },
      [onColumnSort],
    );

    const handleHeaderClick = useCallback(
      (e: GridClickEvent) => {
        if (!e.isLastRow) return;

        if (showPopupForColumnId === e.columnId) return;

        const columnId = e.columnId;
        const column = columns.find((c) => c.id === e.columnId);
        const isFilterable = Boolean(column.isFilterable);
        const isSortable = column.isSortable ?? true;

        if (isFilterable) {
          setShowPopupForColumnId(e.columnId);
          return;
        }

        if (!isSortable) return;

        const currentSort = columnSelectedOrderBys[columnId];
        const nextSort = currentSort === 'Descending' ? 'ASC' : 'DESC';

        handleColumnSort(id, column.id, nextSort);
      },
      [
        columnSelectedOrderBys,
        columns,
        handleColumnSort,
        id,
        showPopupForColumnId,
      ],
    );

    const handleRenderHeaderPopup = useCallback(
      (columnId: string) => {
        if (!showPopupForColumnId) return;
        if (showPopupForColumnId !== columnId) return;

        const column = columns.find((c) => c.id === columnId);
        if (!column) return;

        const currentSort = columnSelectedOrderBys[columnId];
        const currentFilter = columnSelectedFilters[columnId] ?? [];

        return (
          <DataGridFilterPopupContainer
            column={column}
            sort={currentSort}
            filters={currentFilter}
            gridId={id}
            onColumnSort={handleColumnSort}
            onHidePopup={() => setShowPopupForColumnId(undefined)}
            showPopupForColumnId={showPopupForColumnId}
            onColumnFilterSelection={onColumnFilterSelection}
            onColumnFilterClearAll={onColumnFilterClearAll}
          />
        );
      },
      [
        columnSelectedOrderBys,
        columnSelectedFilters,
        columns,
        handleColumnSort,
        id,
        onColumnFilterClearAll,
        onColumnFilterSelection,
        showPopupForColumnId,
      ],
    );

    const getHeaderIcon = useCallback(
      (columnId: string) => {
        const sort = columnSelectedOrderBys[columnId];
        const isFiltered = columnSelectedFilters[columnId]?.length > 0;

        if (!isFiltered && !sort) return;

        if (isFiltered && sort) return Icons.viewHeadline;
        if (isFiltered) return Icons.filter;

        return sort === 'Ascending'
          ? Icons.sortAscending
          : Icons.sortDescending;
      },
      [columnSelectedOrderBys, columnSelectedFilters],
    );

    return (
      <DataGrid
        {...rest}
        id={id}
        onClick={() => void 0}
        columns={columns}
        onHeaderClick={handleHeaderClick}
        getHeaderIcon={getHeaderIcon}
        renderHeaderPopup={handleRenderHeaderPopup}
      />
    );
  };
