/** @jsxImportSource @emotion/react */
import { events } from '@seeeverything/ui.util/src/events.rx/index.ts';
import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { distinctUntilChanged, filter, map, Subject, takeUntil } from 'rxjs';
import {
  IDropdownActions,
  IQueryData,
  IQueryDropdownProps,
} from '../../api/api.queryBuilder/types.ts';
import {
  autocomplete,
  requestDropdown,
} from '../../redux/query/actions.dropdown.ts';
import { blur, focus } from '../../redux/query/actions.ts';
import { QueryState } from '../../redux/query/types.ts';
import { ShellAction, ShellState } from '../../redux/types.ts';
import { Dropdown } from './Dropdown.tsx';

const PADDING = 7;
const DEFAULT_WIDTH = 400;
const DEFAULT_HEIGHT = 200;

export interface IDropdownContainerProps {
  data: IQueryData;
  maxWidth: number;
  left: number;
  top: number;
}
interface IViewProps extends IDropdownContainerProps {
  queryState: QueryState;
  dispatch: (action: ShellAction) => void;
}

class View extends React.Component<IViewProps, object> {
  private unmounted$ = new Subject<void>();
  private props$ = new Subject<IViewProps>();
  private dropdownHidden$ = new Subject<void>();
  private autocomplete$ = new Subject<any>();
  private keydown$ = events.keydown$.pipe(takeUntil(this.unmounted$));
  private keyup$ = events.keydown$.pipe(takeUntil(this.unmounted$));

  private get dropdown() {
    return this.props.queryState.dropdown;
  }
  private get isLoading() {
    return this.dropdown && this.dropdown.isLoading;
  }
  private get focusState() {
    return this.props.queryState.focus;
  }

  public shouldComponentUpdate(nextProps: IViewProps) {
    return !R.equals(this.props, nextProps);
  }

  public componentWillUnmount() {
    this.unmounted$.next(null);
  }
  public componentDidMount() {
    const { dispatch } = this.props;
    const props$ = this.props$.pipe(takeUntil(this.unmounted$));
    const keydown$ = this.keydown$.pipe(filter(() => Boolean(this.focusState)));

    // Pass focus to the dropdown on DOWN keypress when the INPUT is focused.
    keydown$
      .pipe(filter((e) => e.code === 'ArrowDown'))
      .subscribe(() => this.captureFocus());

    // Invoke autocomplete on Enter.
    keydown$.pipe(filter((e) => e.code === 'Enter')).subscribe(() => {
      this.autocomplete$.next(null);
    });

    // Create clean stream of query changes.
    const nextQuery$ = props$.pipe(
      map((e) => e.queryState),
      distinctUntilChanged(
        (prev, next) =>
          R.equals(prev.query, next.query) &&
          R.equals(prev.selection, next.selection) &&
          prev.focus === next.focus,
      ),
    );

    // Request the next dropdown from route-business-logic upon query change.
    nextQuery$.subscribe((e) => {
      dispatch(requestDropdown(this.props.data, e.query, e.selection));
    });

    // Init.
    this.props$.next(this.props);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IViewProps) {
    this.props$.next(nextProps);
  }

  private captureFocus() {
    if (this.focusState !== 'DROPDOWN') {
      this.props.dispatch(focus('DROPDOWN'));
    }
  }

  private releaseFocus() {
    if (this.focusState !== 'INPUT') {
      this.props.dispatch(focus('INPUT'));
    }
  }

  private blur() {
    if (this.focusState) {
      this.props.dispatch(blur());
    }
  }

  private actions(): IDropdownActions {
    return {
      blur: () => this.blur(),
      releaseFocus: () => this.releaseFocus(),
      captureFocus: () => this.captureFocus(),
      autocomplete: (context: any) => this.autocomplete(context),
    };
  }

  private autocomplete(context: any) {
    const { data, dispatch, queryState } = this.props;
    const { query, selection } = queryState;

    const response = data.change({
      action: 'AUTOCOMPLETE',
      query,
      selection,
      context,
    });

    if (response?.query && !response.isCancelled) {
      dispatch(autocomplete(response.query, response.editingChip));
    }
  }

  /**
   * Create the dropdown content element.
   */
  private renderContent() {
    if (this.isLoading) {
      return;
    }
    const { data, queryState, dispatch } = this.props;
    const { dropdown, query, selection, activeInlineFilters } = queryState;
    if (!dropdown) {
      return;
    }

    const keydown$ = this.keydown$.pipe(
      takeUntil(this.dropdownHidden$),
      filter(() => this.focusState === 'DROPDOWN'),
    );
    const keyup$ = this.keyup$.pipe(
      takeUntil(this.dropdownHidden$),
      filter(() => this.focusState === 'DROPDOWN'),
    );

    const autocomplete$ = this.autocomplete$.pipe(
      takeUntil(this.dropdownHidden$),
      takeUntil(this.unmounted$),
    );

    // Prepare the dropdown's properties.
    const isActive = queryState.focus === 'DROPDOWN';
    const baseProps: IQueryDropdownProps = {
      data,
      query,
      selection,
      isActive,
      type: dropdown.component,
      actions: this.actions(),
      keydown$,
      keyup$,
      autocomplete$,
      dispatch,
      inlineFilters: dropdown.inlineFilters,
      activeInlineFilters,
    };
    const props = {
      ...baseProps,
      ...dropdown.props, // NB: Will never be a <Promise> (see isLoading guard above).
    };

    // Create the element.
    const el = data.dropdownElement(props);
    if (!el) {
      throw new Error(
        `DropdownFactory error. A dropdown of type '${props.type}' is not supported by the factory.`,
      );
    }

    return el;
  }

  /**
   * Calculate the dimensions and position of the dropdown.
   */
  private dimensions() {
    const maxWidth = this.props.maxWidth;
    const dropdown = this.props.queryState.dropdown;
    if (!dropdown) {
      return { left: -1, top: -1, width: -1 };
    }
    const dropdownWidth =
      dropdown.width === undefined ? DEFAULT_WIDTH : dropdown.width;

    // Calculate position and size.
    const right = this.props.maxWidth;
    const top = this.props.top + PADDING + 2;
    let left = this.props.left + PADDING;
    let width: number =
      dropdownWidth === '100%' ? right - PADDING * 2 : dropdownWidth;

    // Ensure width is less than available screen size.
    const MAX = maxWidth - PADDING * 2;
    if (width > MAX) {
      width = MAX;
    }

    // Ensure the dropdown does not fall beyond the right edge of the QB.
    if (width && left + width > right) {
      left = right - width - PADDING;
    }

    return { left, top, width };
  }

  /**
   * Main render method.
   */
  public render() {
    const { queryState } = this.props;
    const dropdown = queryState.dropdown;
    if (!dropdown) {
      return <div style={{ width: 0, height: 0 }} />;
    }

    // Calculate position and size.
    const { left, top, width } = this.dimensions();
    const padding = dropdown.padding || 0;

    return (
      <Dropdown
        isLoading={this.isLoading}
        left={left}
        top={top}
        width={width}
        height={dropdown.height || DEFAULT_HEIGHT}
        padding={padding}
        onMouseDown={this.handleClick}
        onHidden={this.handleDropdownHidden}
      >
        {this.renderContent()}
      </Dropdown>
    );
  }

  private handleDropdownHidden = () => this.dropdownHidden$.next(null);
  private handleClick = () => this.captureFocus();
}

const mapStateToProps = (state: ShellState) => ({
  queryState: state.query,
});
const mapDispatchToProps = (dispatch: (action: ShellAction) => void) => ({
  dispatch,
});
/**
 * The Redux container for a Dropdown.
 */
export const DropdownContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(View);
