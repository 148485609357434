/** @jsxImportSource @emotion/react */
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { AuditLogEntry } from '../../redux/audit/auditSlice.ts';
import { SheetLayout } from '../SheetLayout/index.ts';
import { AuditLog } from './components/AuditLog.tsx';

export interface ISheetAuditProps {
  isSpinning?: boolean;
  logEntries: AuditLogEntry[];
}

/**
 * A SheetLayout that displays an audit log.
 */
export const SheetAudit: React.FC<ISheetAuditProps> = ({
  isSpinning,
  logEntries,
}) => (
  <SheetLayout
    isSpinning={isSpinning}
    overrideNoScrollPanel={true}
    title={'Audit Log'}
    rightTools={[
      {
        id: 'AUDIT_LOG_REFRESH',
        icon: Icons.refresh,
        label: 'Refresh',
      },
    ]}
  >
    <AuditLog logEntries={logEntries} />
  </SheetLayout>
);
