import { update } from 'ramda';
import {
  FormDesignerReduxDesignerAction,
  FormDesignerReduxDesignerState,
} from './types.ts';
import { addComponentToTemplateReducer } from './reducer.integration.addComponentToTemplate.ts';
import { changeComponentPropertyReducer } from './reducer.component.changeComponentProperty.ts';
import { copyDesignerComponentToTemplateReducer } from './reducer.integration.copyDesignerToTemplate.ts';
import { copyDigitalContentPackIdToTemplate } from './reducer.digitalContent.createdDigitalContentPack.ts';
import { deleteComponentFromTemplateReducer } from './reducer.integration.deleteComponentFromTemplate.ts';
import { optionsAddOptionReducer } from './reducer.component.options.addOption.ts';
import { optionsDeleteOptionReducer } from './reducer.component.options.deleteOption.ts';
import { optionsChangeOptionTextReducer } from './reducer.component.options.changeText.ts';
import { uploadFileMetadata } from './fileMetadata.ts';
import { copyFilesToTemplate } from './reducer.digitalContent.copyFilesToTemplate.ts';
import { IFormFile } from '@seeeverything/ui.forms/src/redux/form-file/types.ts';
import { IDigitalContentLine } from '@seeeverything/ui.forms/src/types/types.ts';

const DEFAULT_STATE: FormDesignerReduxDesignerState = {
  isLoading: false,
  isSaving: false,
  isDesigning: false,
};

export function reducer(
  state: FormDesignerReduxDesignerState = DEFAULT_STATE,
  action: FormDesignerReduxDesignerAction,
): FormDesignerReduxDesignerState {
  switch (action.type) {
    case 'ui.forms.designer/designer/LOAD_DRAFT':
      return {
        ...state,
        draft: undefined,
        loadError: undefined,
        isLoading: true,
        isDesigning: false,
      };

    case 'ui.forms.designer/designer/LOADED_DRAFT':
      return {
        ...state,
        draft: action.payload,
        loadError: undefined,
        isLoading: false,
      };

    case 'ui.forms.designer/designer/LOAD_DRAFT_FAILED':
      return {
        ...state,
        loadError: true,
        isLoading: false,
      };

    case 'ui.forms.designer/designer/SAVE_DEFINITION':
      return {
        ...state,
        saveError: undefined,
        isSaving: true,
      };

    case 'ui.forms.designer/designer/SAVED_DEFINITION':
      return {
        ...state,
        saveError: undefined,
        isSaving: false,
        draft: state.draft
          ? {
              ...state.draft,
              definition: [...action.payload.definition],
            }
          : state.draft,
      };

    case 'ui.forms.designer/designer/SAVE_DEFINITION_FAILED':
      return {
        ...state,
        saveError: true,
        isSaving: false,
      };

    case 'ui.forms.designer/designer/TOGGLE_DESIGNING':
      return {
        ...state,
        isDesigning: !state.isDesigning,
      };

    case 'ui.forms.designer/designer/component/CHANGE_COMPONENT_PROPERTY':
      return changeComponentPropertyReducer(state, action);

    case 'ui.forms.designer/designer/component/OPTIONS/CHANGE_TEXT':
      return optionsChangeOptionTextReducer(state, action);

    case 'ui.forms.designer/designer/component/OPTIONS/ADD_OPTION':
      return optionsAddOptionReducer(state, action);

    case 'ui.forms.designer/designer/component/OPTIONS/DELETE_OPTION':
      return optionsDeleteOptionReducer(state, action);

    case 'ui.forms.designer/designer/LINES_CHANGED':
      return {
        ...state,
        draft: state.draft
          ? {
              ...state.draft,
              designerLines: action.payload.lines,
            }
          : undefined,
      };

    case 'ui.forms.designer/designer/VALIDATE_DEFINITION_FAILED': {
      if (!state.draft) {
        return state;
      }

      return {
        ...state,
        draft: {
          ...state.draft,
          designerLines: action.payload.designerLines,
        },
      };
    }

    case 'ui.forms.designer/integration/COPY_DESIGNER_COMPONENT_TO_TEMPLATE':
      return copyDesignerComponentToTemplateReducer(state, action);

    case 'ui.forms.designer/integration/DELETE_COMPONENT_FROM_TEMPLATE':
      return deleteComponentFromTemplateReducer(state, action);

    case 'ui.forms.designer/integration/ADD_COMPONENT_TO_TEMPLATE':
      return addComponentToTemplateReducer(state, action);

    case 'ui.forms.designer/designer/digitalContent/CREATED_PACK':
      return copyDigitalContentPackIdToTemplate(state, action);

    case 'ui.forms.designer/designer/digitalContent/LOADED_PACK': {
      if (!state.draft) {
        return state;
      }

      const newState = {
        ...state,
        draft: {
          ...state.draft,
          designerLines: {
            ...state.draft?.designerLines,
            digitalContent: {
              ...state.draft?.designerLines?.digitalContent,
              files: action.payload.files,
            },
          },
        },
      };

      return copyFilesToTemplate(newState, action);
    }

    case 'ui.forms.designer/designer/digitalContent/FILE_REJECTED': {
      if (!state.draft) {
        return state;
      }

      return {
        ...state,
        draft: {
          ...state.draft,
          designerLines: {
            ...state.draft?.designerLines,
            digitalContent: {
              ...state.draft?.designerLines?.digitalContent,
              rejections: action.payload.rejections,
            },
          },
        },
      };
    }

    case 'ui.forms.designer/designer/digitalContent/FILE_UPLOAD': {
      if (!state.draft) {
        return state;
      }
      return {
        ...state,
        draft: {
          ...state.draft,
          designerLines: {
            ...state.draft?.designerLines,
            digitalContent: {
              ...state.draft?.designerLines?.digitalContent,
              files: [
                ...(state.draft?.designerLines?.digitalContent.files || []),
                {
                  ...uploadFileMetadata(
                    action.payload.fileId,
                    action.payload.file,
                  ),
                  action: 'uploading',
                },
              ],
            },
          },
        },
      };
    }

    case 'ui.forms.designer/designer/digitalContent/FILE_UPLOADED':
      return setFileState(state, action.payload.fileId, {
        ...action.payload.metadata,
        action: undefined,
        error: undefined,
      });

    case 'ui.forms.designer/designer/digitalContent/FILE_ERROR_UPLOADING': {
      const errorFile = getFile(
        state.draft?.designerLines?.digitalContent.files,
        action.payload.fileId,
      );

      return setFileState(state, action.payload.fileId, {
        ...errorFile,
        error: action.payload.errorMessage,
      });
    }

    case 'ui.forms.designer/designer/digitalContent/FILE_DELETE': {
      return setFileState(state, action.payload.fileId, {
        ...getFile(
          state.draft?.designerLines?.digitalContent.files,
          action.payload.fileId,
        ),
        action: 'deleting',
        error: undefined,
      });
    }

    case 'ui.forms.designer/designer/digitalContent/FILE_DELETED': {
      if (!state.draft) {
        return state;
      }

      const digitalContentLine = state.draft?.lines
        ?.digitalContent as IDigitalContentLine;

      return {
        ...state,
        draft: {
          ...state.draft,
          designerLines: {
            ...state.draft?.designerLines,
            digitalContent: {
              ...state.draft?.designerLines?.digitalContent,
              files: deleteFile(
                state.draft?.designerLines?.digitalContent.files,
                action.payload.fileId,
              ),
            },
          },
          lines: {
            ...state.draft?.lines,
            digitalContent: {
              ...digitalContentLine,
              files: (digitalContentLine.files ?? []).filter(
                ({ id }) => id !== action.payload.fileId,
              ),
            },
          },
        },
      };
    }

    case 'ui.forms.designer/designer/digitalContent/FILE_ERROR_DELETING': {
      const errorFile = getFile(
        state.draft?.designerLines?.digitalContent.files,
        action.payload.fileId,
      );

      return setFileState(state, action.payload.fileId, {
        ...errorFile,
        error: action.payload.errorMessage,
      });
    }

    default:
      return state;
  }
}

function setFileState(
  state: FormDesignerReduxDesignerState,
  id: string,
  file: IFormFile,
) {
  if (!state.draft) return state;

  const digitalContentDesignerLine = state.draft.designerLines.digitalContent;
  const digitalContentLine = state.draft.lines
    .digitalContent as IDigitalContentLine;

  return {
    ...state,
    draft: {
      ...state.draft,
      designerLines: {
        ...state.draft.designerLines,
        digitalContent: {
          ...digitalContentDesignerLine,
          files: updateFiles(digitalContentDesignerLine.files, id, file),
        },
      },
      lines: {
        ...state.draft.lines,
        digitalContent: {
          ...digitalContentLine,
          files: (digitalContentLine.files ?? []).concat(file),
        },
      },
    },
  };
}

function deleteFile(files: IFormFile[], fileId: string): IFormFile[] {
  const fileIndex = files.findIndex((file) => file.id === fileId);

  return [...files.slice(0, fileIndex), ...files.slice(fileIndex + 1)];
}

function updateFiles(
  files: IFormFile[],
  fileId: string,
  updatedFile: IFormFile,
): IFormFile[] {
  const index = files.findIndex((file) => file.id === fileId);
  const file = files[index];

  return update(index, { ...file, ...updatedFile }, files);
}

function getFile(files: IFormFile[], fileId: string): IFormFile {
  const index = files.findIndex((file) => file.id === fileId);

  return files[index];
}
