import { Analytics, AnalyticsBrowser } from '@segment/analytics-next';
import { env } from '../env.ts';

let analytics: Analytics = undefined;

export const analyticsClient = async (): Promise<Analytics | undefined> => {
  if (typeof window === 'undefined') return undefined;
  if (!env.ANALYTICS_ENABLED()) return undefined;
  if (analytics) return analytics;

  const [response] = await AnalyticsBrowser.load({
    writeKey: env.SEGMENT_WRITE_KEY(),
  });

  analytics = response;
  return response;
};
