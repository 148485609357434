import { StateObservable } from 'redux-observable';
import { EMPTY, Observable, concatAll, filter, mergeMap } from 'rxjs';
import { log } from '../../log/log.ts';
import {
  GlobalReduxEpicDependenciesType,
  GlobalState,
  ReduxAction,
} from '../types.ts';
import { AnalyticsAction } from './types.ts';

export function submitToSegmentEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalState>,
  dependencies: GlobalReduxEpicDependenciesType,
) {
  return action$.pipe(
    filter(() =>
      Boolean(
        dependencies.analyticsInfoProvider &&
          state$.value.tenantState.tenant?.authenticatedUser?.id,
      ),
    ),
    mergeMap(async (action) => {
      try {
        const result = await dependencies.analyticsInfoProvider({
          action,
          state$,
          client: dependencies.client,
        });

        if (!result) return EMPTY;

        const client = await dependencies.analyticsClient();

        result.events.forEach(async (event) => {
          switch (event.action) {
            case AnalyticsAction.identify: {
              await client.identify(event.eventIdentifier, event.payload);
              break;
            }
            case AnalyticsAction.track:
            default:
              await client.track(event.eventIdentifier, event.payload);
              break;
          }
        });

        return EMPTY;
      } catch (error) {
        log.error(
          new Error(
            `submitToSegmentEpic Error: Error during analytics event submission. ${error}`,
          ),
        );
        return EMPTY;
      }
    }),
    concatAll(),
  );
}
