import { Observable } from 'rxjs';
import {
  IQueryData,
  IEntityDictionary,
  QueryDropdownFactory,
  IQueryHandler,
  IChipFormatHandler,
} from './types.ts';
import { QueryData } from './QueryData.ts';
import {
  QueryRouter,
  IQueryHandlerOptions,
  IChipFormatterOptions,
} from './QueryRouter.ts';

/**
 * The configuration API for the QueryBuilder data model.
 */
export class QueryConfig {
  public constructor() {
    this.queryData = new QueryData();
  }

  /**
   * The data API used by the QueryBuilder.
   */
  public get data() {
    return this.queryData as IQueryData;
  }
  private queryData: QueryData;

  /**
   * Adds the set of entities to the dictionary.
   */
  public entities(entities: IEntityDictionary) {
    this.queryData.entities = { ...this.queryData.entities, ...entities };
    return this;
  }

  /**
   * Registers a single dropdown handler.
   */
  public handle(
    key: string,
    pathPattern: RegExp,
    options?: IQueryHandlerOptions,
  ) {
    const router = new QueryRouter();
    const handler$ = router.handle(key, pathPattern, options);
    this.routes(router);
    return handler$ as Observable<IQueryHandler>;
  }

  /**
   * Registers a single chip formatter.
   */
  public formatChip(type: string, options: IChipFormatterOptions = {}) {
    const router = new QueryRouter();
    const handler$ = router.formatChip(type, options);
    this.routes(router);
    return handler$ as Observable<IChipFormatHandler>;
  }

  /**
   * Adds a set of routes from the given router.
   */
  public routes(router: QueryRouter) {
    const routes = new QueryRouter(this.queryData.routes).merge(router);
    this.queryData.routes = routes.toRoutes();
    this.queryData.chipFormatters = routes.toChipFormatters();
    return this;
  }

  /**
   * Adds a dropdown factory.
   */
  public dropdownFactory(factory: QueryDropdownFactory) {
    this.queryData.dropdownFactories = [
      ...this.queryData.dropdownFactories,
      factory,
    ];
    return this;
  }
}
