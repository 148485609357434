/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { init, is } from 'ramda';
import { useMemo } from 'react';
import { IRenderListRowArgs, List } from '../List/index.ts';
import { ListSelectionBehavior } from '../ListSelectionBehavior/index.ts';
import { InnerList } from './InnerList.tsx';
import {
  GridCellColumnSpanning,
  GridClickEventHandler,
  GridSelectionChangedEventHandler,
  IGridColumn,
  IGridFlexSize,
  IGridRow,
  IRenderGridCellArgs,
  RenderGridCell,
} from './types.ts';

export interface IGridProps<V extends object = undefined> {
  canHighlight?: boolean;
  cellColumnSpanning?: GridCellColumnSpanning;
  columns: IGridColumn[];
  data: IGridRow<V>[];
  isFocused?: boolean;
  isScrollable?: boolean;
  isVirtualized?: boolean;
  onClick?: GridClickEventHandler;
  onDoubleClick?: GridClickEventHandler;
  onMouseDown?: GridClickEventHandler;
  onSelectionChanged?: GridSelectionChangedEventHandler;
  renderCell?: RenderGridCell;
  rowHeight: number;
  tabIndex?: number;
}

/**
 * A scrollable list of items displayed across multiple columns.
 */
export const Grid = <V extends object = undefined>({
  canHighlight,
  cellColumnSpanning,
  columns,
  data = [],
  isFocused,
  isScrollable = false,
  isVirtualized,
  onClick,
  onDoubleClick,
  onMouseDown,
  onSelectionChanged,
  renderCell,
  rowHeight,
  tabIndex,
}: IGridProps<V>) => {
  const row = useMemo(
    () =>
      renderRow(
        data,
        columns,
        isVirtualized,
        rowHeight,
        cellColumnSpanning,
        renderCell,
        onClick,
        onDoubleClick,
        onMouseDown,
        canHighlight,
      ),
    [
      cellColumnSpanning,
      columns,
      data,
      isVirtualized,
      onClick,
      onDoubleClick,
      onMouseDown,
      renderCell,
      rowHeight,
      canHighlight,
    ],
  );

  return isVirtualized ? (
    <div css={styles.outerScrollable}>
      <ListSelectionBehavior
        items={data}
        isFocused={isFocused}
        tabIndex={tabIndex}
        style={styles.innerScrollable}
        onSelectionChanged={onSelectionChanged}
      >
        <InnerList
          items={data}
          renderItem={row}
          itemHeight={rowHeight}
          isFocused={isFocused}
        />
      </ListSelectionBehavior>
    </div>
  ) : (
    <List
      items={data}
      renderRow={row}
      onSelectionChanged={onSelectionChanged}
      isFocused={isFocused}
      isScrollable={isScrollable}
    />
  );
};

const renderRow = <V extends object = undefined>(
  gridData: IGridRow<V>[],
  columns: IGridColumn[],
  isVirtualized: boolean,
  rowHeight: number,
  cellColumnSpanning?: GridCellColumnSpanning,
  renderCell?: RenderGridCell,
  onClick?: GridClickEventHandler,
  onDoubleClick?: GridClickEventHandler,
  onMouseDown?: GridClickEventHandler,
  canHighlight?: boolean,
) =>
  function Row({
    id,
    data,
    index: rowIndex,
    isLast,
    isFocused,
    isSelected,
  }: IRenderListRowArgs) {
    const rowColumns = applyColumnSpanning(
      rowIndex,
      columns,
      cellColumnSpanning,
    );
    const visibleColumns = rowColumns.filter((c) => c.isHidden !== true);

    const cellClickedHandler =
      (
        reason: 'CLICK' | 'DOUBLE_CLICK' | 'MOUSE_DOWN',
        args: IRenderGridCellArgs,
      ) =>
      () => {
        const isFirstRow = rowIndex === 0;
        const isLastRow = rowIndex === gridData.length - 1;

        const event = {
          rowId: args.row.id,
          rowIndex: args.rowIndex,
          columnIndex: args.columnIndex,
          columnId: args.columnId,
          row: args.row,
          data,
          isFirstRow,
          isLastRow,
        };

        if (reason === 'CLICK') onClick?.(event);
        if (reason === 'DOUBLE_CLICK') onDoubleClick?.(event);
        if (reason === 'MOUSE_DOWN') onMouseDown?.(event);
      };

    const computedStyles = {
      row: css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        minHeight: !isVirtualized ? rowHeight : undefined,
        height: '100%',
        boxSizing: 'border-box',
        ':hover': {
          backgroundColor: canHighlight ? color.format(-0.05) : undefined,
        },
      }),
      hiddenColumn: css({
        visibility: 'hidden',
      }),
      column: (column: IGridColumn) =>
        css({
          flex: getFlex(column.width),
          minWidth: column.minWidth,
          overflow: 'hidden',
        }),
    };

    const cells = rowColumns.map((column, columnIndex) => {
      const props = {
        columnIndex,
        columnId: column.id,
        rowIndex,
        row: data,
        cellValue: data.data[columnIndex],
        isFirstRow: rowIndex === 0,
        isLastRow: isLast,
        isFirstColumn: columnIndex === 0,
        isLastColumn: columnIndex === visibleColumns.length - 1,
        isFocused,
        isSelected,
      };
      const cellKey = `${data.id}-${columnIndex}`;
      const cell = renderCell?.(props) ?? data.data[columnIndex];

      return (
        <div
          key={cellKey}
          onClick={cellClickedHandler('CLICK', props)}
          onDoubleClick={cellClickedHandler('DOUBLE_CLICK', props)}
          onMouseDown={cellClickedHandler('MOUSE_DOWN', props)}
          css={
            column.isHidden
              ? computedStyles.hiddenColumn
              : computedStyles.column(column)
          }
        >
          {cell}
        </div>
      );
    });

    return (
      <div key={id} css={computedStyles.row}>
        {cells}
      </div>
    );
  };

/**
 * Returns effective "spanned" columns for a row.
 * - Cells absorb width from columns further-right.
 * - All other properties are taken from first cell.
 * - Calls cellColumnSpanning to see how many more to merge.
 * - Unmodified columns are referenced in output.
 */
export const applyColumnSpanning = (
  rowIndex: number,
  columns: IGridColumn[],
  cellColumnSpanning?: GridCellColumnSpanning,
): IGridColumn[] => {
  if (!cellColumnSpanning) return columns;

  const moreColumns = (colIndex: number) => colIndex < columns.length - 1;

  type MergeType = {
    newCols: IGridColumn[];
    mergeNext: number | false;
  };

  return columns.reduce<MergeType>(
    (acc, column: IGridColumn, columnIndex) => {
      const { newCols, mergeNext } = acc;
      if (mergeNext) {
        // Merging with previous. Combine into last and reduce counter.
        const lastColumn = newCols[newCols.length - 1];
        const mergedColumn = {
          ...lastColumn,
          width: addFlexSizes(
            toFlexSize(lastColumn.width),
            toFlexSize(column.width),
          ),
        };
        return {
          newCols: [...init(newCols), mergedColumn],
          mergeNext: mergeNext - 1,
        };
      }
      // Not already merging: append column and get how many more (if any) merge into it.
      return {
        newCols: [...newCols, column],
        mergeNext:
          moreColumns(columnIndex) && cellColumnSpanning(rowIndex, columnIndex),
      };
    },
    { newCols: [], mergeNext: 0 },
  ).newCols;
};

const toFlexSize = (width: string | number | IGridFlexSize) => {
  // Fixed width.
  if (is(Number, width)) {
    return { basis: width as number, grow: 0 };
  }
  // Grow width.
  if (is(String, width)) {
    if (!isStarWidth(width as string)) {
      throw Error('IGridColumn width strings must be star-widths, e.g. 1*.');
    }
    return { basis: 0, grow: trimStarWidth(width as string) };
  }
  return width as IGridFlexSize;
};

const addFlexSizes = (a: IGridFlexSize, b: IGridFlexSize) => {
  const { basis: aBasis = 0, grow: aGrow = 0 } = a;
  const { basis: bBasis = 0, grow: bGrow = 0 } = b;
  return {
    basis: aBasis + bBasis,
    grow: aGrow + bGrow,
  };
};

const getFlex = (width: string | number | IGridFlexSize) => {
  const { basis, grow } = toFlexSize(width);
  return `${basis}px ${grow} 0`;
};

const isStarWidth = (width: string) => width && width.slice(-1) === '*';
const trimStarWidth = (width: string) =>
  width.length === 1 ? 1 : Number(width.slice(0, -1)); // '*' => 1

const styles = {
  outerScrollable: css({
    position: 'absolute',
    inset: 0,
  }),
  innerScrollable: css({
    position: 'absolute',
    inset: 0,
    margin: 0,
    padding: 0,
  }),
};
