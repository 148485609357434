/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { Tab } from './Tab.tsx';
import { ITab } from './types.ts';

export interface ITabStripProps {
  fontSize?: number;
  highlightColor?: number | string;
  labelColor?: number | string;
  onSelectionChanged?: (id: string) => void;
  selectedId?: string | number;
  style?: SerializedStyles;
  tabs?: ITab[];
  tabWidth?: number;
}

/**
 * A set of mutually exclusive button options in a horizontal strip.
 */
export const TabStrip: React.FC<ITabStripProps> = ({
  fontSize,
  highlightColor,
  labelColor,
  onSelectionChanged,
  selectedId,
  style,
  tabs = [],
  tabWidth,
}) => (
  <div css={[styles.base, style]}>
    {tabs.map((tab) => (
      <Tab
        id={tab.id}
        fontSize={fontSize}
        highlightColor={highlightColor}
        isEnabled={tab.isEnabled}
        isSelected={tab.id === selectedId}
        key={tab.id}
        label={tab.label}
        labelColor={labelColor}
        onClick={onSelectionChanged}
        width={tabWidth}
      />
    ))}
  </div>
);

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
  }),
};
