/** @jsxImportSource @emotion/react */
import { useCallback, useMemo } from 'react';
import { Button } from '../Button/Button.tsx';
import { Text } from '../Text/Text.tsx';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { css } from '@emotion/react';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';

export interface ITabProps {
  id: number | string;
  fontSize?: number;
  highlightColor?: number | string;
  isEnabled?: boolean;
  isSelected: boolean;
  label: string;
  labelColor?: number | string;
  onClick?: (id: number | string) => void;
  width?: number;
}

/**
 * A single tab button within a tab strip.
 */
export const Tab = ({
  id,
  fontSize = 14,
  highlightColor = COLORS.BLUE,
  isEnabled = true,
  isSelected,
  label,
  labelColor: propsLabelColor,
  onClick,
}: ITabProps) => {
  const labelColor = color.format(propsLabelColor);

  const styles = useMemo(
    () => ({
      base: css({
        position: 'relative',
        boxSizing: 'border-box',
        padding: '10px 30px',
        opacity: isEnabled ? 1 : 0.8,
        cursor: isEnabled ? 'pointer' : 'not-allowed',
      }),
      bar: css({
        position: 'absolute',
        inset: 'auto 0 0 0',
        height: 4,
        backgroundColor: color.format(highlightColor),
      }),
      text: css({
        whiteSpace: 'nowrap',
        textAlign: 'center',
        cursor: 'inherit',
      }),
    }),
    [highlightColor, isEnabled],
  );

  const handleClick = useCallback(() => onClick?.(id), [id, onClick]);

  return (
    <Button onClick={handleClick} isEnabled={isEnabled}>
      <div css={styles.base}>
        <Text
          size={fontSize}
          weight={FontWeight.bold}
          uppercase={true}
          color={labelColor}
          block={true}
          opacity={isSelected ? 1 : 0.6}
          style={styles.text}
        >
          {label}
        </Text>
        {isSelected && <div css={styles.bar} />}
      </div>
    </Button>
  );
};
