import { QueryOptions } from '@apollo/client';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import gql from 'graphql-tag';
import { PAGE_SIZE_DASHBOARD_GRID } from '../common/constants.ts';
import { ContentPacksPaged, DigitalContentPack } from './types.ts';

export async function digitalContentPacksPaged(
  client: IGraphQLClient,
  startDate?: string,
  endDate?: string,
  showCommentsEnabledOnly = true,
  pageNumber = 1,
  pageSize = PAGE_SIZE_DASHBOARD_GRID,
  options?: QueryOptions,
): Promise<ContentPacksPaged> {
  const response = await client.query<GraphQLContentPackResponse>({
    fetchPolicy: 'network-only', // No caching until module-changes invalidate cache.
    ...options,
    query: pagedContentPacksQuery,
    variables: {
      pageNumber,
      pageSize,
      startDate,
      endDate,
      showCommentsEnabledOnly,
    },
  });

  const contentPacks: DigitalContentPack[] =
    response.data.digitalContent.contentPacks.nodes?.map(
      (node): DigitalContentPack => ({
        id: node.id,
        summary: node.summary,
        publishDate: node.publishDate,
        commentsEnabled: node.commentsEnabled,
      }),
    ) ?? [];
  const pageInfo = response.data.digitalContent.contentPacks.pageInfo;

  return {
    hasNextPage: pageInfo.hasNextPage,
    currentPage: pageNumber,
    contentPacks: contentPacks,
  };
}

const pagedContentPacksQuery = gql`
  query DigitalContentPacks(
    $pageNumber: Int
    $pageSize: Int
    $startDate: DateTime
    $endDate: DateTime
    $showCommentsEnabledOnly: Boolean
  ) {
    digitalContent {
      contentPacks(
        orderBy: { fieldName: "publishDate", direction: Descending }
        pagination: { pageNumber: $pageNumber, size: $pageSize }
        startDate: $startDate
        endDate: $endDate
        showCommentsEnabledOnly: $showCommentsEnabledOnly
      ) {
        pageInfo {
          hasNextPage
        }
        nodes {
          id
          summary
          commentGuidance
          commentsEnabled
          publishDate
        }
      }
    }
  }
`;

type GraphQLContentPack = {
  id: string;
  summary: string;
  commentGuidance: string;
  publishDate: string;
  commentsEnabled: boolean;
};

type GraphQLContentPackResponse = {
  digitalContent: {
    contentPacks: {
      pageInfo: {
        hasNextPage: boolean;
        totalCount: number;
      };
      nodes?: GraphQLContentPack[];
    };
  };
};
