/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormsBulkUploadValidateFileUploadRow } from '@se/data/forms/types.ts';
import {
  DataGrid,
  DataGridFilter,
  DataGridInfiniteScrollBehavior,
  DataGridIsLoadingBehavior,
  DataGridStatusToggleSortableBehavior,
  IDataGridColumn,
  IDataGridInfiniteScrollBehaviorProps,
  IDataGridProps,
  IDataGridStatusToggleSortableBehaviorProps,
  RenderDataGridCell,
} from '@seeeverything/ui.primitives/src/components/DataGrid/index.ts';
import { IRenderGridCellArgs } from '@seeeverything/ui.primitives/src/components/Grid/types.ts';
import { TenantLocale } from '@seeeverything/ui.util/src/redux/tenant/index.ts';
import { OrderBy } from '@seeeverything/ui.util/src/types.ts';
import { pipe } from 'ramda';
import { useCallback, useMemo } from 'react';
import { toStatusItem } from '../../util/util.bulkUpload.ts';

const Grid = pipe(
  DataGridInfiniteScrollBehavior,
  DataGridStatusToggleSortableBehavior,
  DataGridIsLoadingBehavior,
)(DataGrid) as React.FC<
  IDataGridInfiniteScrollBehaviorProps &
    IDataGridStatusToggleSortableBehaviorProps &
    IDataGridProps
>;

export type BulkUploadFailedResultGridProps = {
  hasNextPage: boolean;
  isLoading: boolean;
  isLoadingMore: boolean;
  loadedPage: number;
  loadMoreText: string;
  locale: TenantLocale;
  onColumnSort: (columnId: string, direction: 'ASC' | 'DESC') => void;
  onLoadMore: () => void;
  orderBy: OrderBy[];
  rows?: FormsBulkUploadValidateFileUploadRow[];
};

/**
 * A grid view for results of failed rows from an import run.
 */
export const BulkUploadFailedResultGrid: React.FC<
  BulkUploadFailedResultGridProps
> = ({
  hasNextPage,
  isLoading = false,
  isLoadingMore,
  loadedPage,
  loadMoreText,
  locale,
  onColumnSort,
  onLoadMore,
  orderBy,
  rows = [],
}) => {
  const columns = useMemo(() => createColumns(locale), [locale]);

  const filters = useMemo<DataGridFilter[]>(
    () =>
      orderBy.map(({ fieldName, direction }) => ({
        columnId: fieldName,
        sort: direction === 'Ascending' ? 'ASC' : 'DESC',
      })),
    [orderBy],
  );

  const data = useMemo(
    () =>
      rows.map((row) => ({
        id: row.rowNumber,
        data: [
          row.rowNumber,
          row.associatedEntities.subject,
          row.associatedEntities.assignedTo,
          row.status,
        ],
      })),
    [rows],
  );

  const renderCell = useMemo(
    (): RenderDataGridCell => (rowProps) => {
      if (rows && rowProps.columnIndex === COLUMN_INDEX_STATUS) {
        const row = rows.find(({ rowNumber }) => rowNumber === rowProps.row.id);
        return row ? toStatusItem(locale, row) : undefined;
      }
      return undefined;
    },
    [locale, rows],
  );

  const cellTheme = useCallback(
    ({ columnIndex, row }: IRenderGridCellArgs) => {
      const column = columns[columnIndex];
      if (!rows.some(({ rowNumber }) => rowNumber === row.id)) return {};

      return column.id === 'status'
        ? {
            baseStyle: styles.statusBase,
            baseStyle_active: styles.statusBase,
          }
        : {};
    },
    [columns, rows],
  );

  return (
    <div css={styles.base}>
      <div css={styles.grid}>
        <Grid
          id={'BulkUploadFailedGrid'}
          columns={columns}
          data={data}
          filters={filters}
          hasNextPage={hasNextPage}
          isLoading={isLoading}
          isLoadingPage={isLoadingMore}
          isScrollable={true}
          isVirtualized={false}
          loadedPage={loadedPage}
          loadMoreText={loadMoreText}
          onColumnSort={onColumnSort}
          onLoadPage={onLoadMore}
          renderCellContents={renderCell}
          cellThemeOverrides={cellTheme}
        />
      </div>
    </div>
  );
};

const COLUMN_INDEX_STATUS = 3;

const createColumns = (locale: TenantLocale): IDataGridColumn[] => [
  {
    id: 'rowNumber',
    label: 'Row',
    width: '*',
    borderRight: true,
    isSortable: false,
  },
  {
    id: 'subject',
    label: locale.label.formSubject,
    width: '2*',
    borderRight: true,
    isSortable: false,
  },
  {
    id: 'assignedTo',
    label: locale.label.formAssignedTo,
    width: '2*',
    borderRight: true,
    isSortable: false,
  },
  {
    id: 'status',
    label: 'Status',
    width: '5*',
    borderRight: true,
    isSortable: false,
  },
];

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  }),
  statusBase: css({
    padding: '0 8px',
  }),
  grid: css({
    position: 'relative',
    height: 400,
  }),
};
