import { Observable, filter, mergeMap } from 'rxjs';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { StateObservable, combineEpics } from 'redux-observable';
import {
  GlobalFormsDesignerState,
  GlobalFormsDesignerEpicDependencies,
} from './store.ts';
import * as formsDesignerCreateTemplatesSlice from './formsDesignerCreateTemplatesSlice.ts';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import gql from 'graphql-tag';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { IListItemLabel } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';

export const createTemplatesEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsDesignerState
>(createDraftWithNewTemplateEpic, createDraftFromSourceTemplateEpic);

function createDraftWithNewTemplateEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsDesignerState>,
  { client }: GlobalFormsDesignerEpicDependencies,
) {
  type Response = {
    forms: { createFormsDesignerDraft: { ok: boolean } };
  };

  return action$.pipe(
    filter(formsDesignerCreateTemplatesSlice.createDraft.match),
    filter(
      () => state$.value.formsDesignerCreateTemplates.selectedMethod === 'NEW',
    ),
    mergeMap(async () => {
      const name =
        state$.value.formsDesignerCreateTemplates.createNewInputs.name;
      const id = uuid.generate();

      const configuration = state$.value.tenantState.tenant?.configuration;
      const module = state$.value.tenantState.tenant?.module;
      const definition =
        configuration && module
          ? configuration.modules[module]?.formTemplateDesigner
              ?.newTemplatesDefinition
          : undefined;

      try {
        const response = await client.mutate<Response>({
          mutation: gql`
            mutation CreateDesignerDraft(
              $id: ID!
              $name: String!
              $definition: String
            ) {
              forms {
                createFormsDesignerDraft(
                  input: { id: $id, name: $name, definition: $definition }
                ) {
                  ok
                }
              }
            }
          `,
          variables: { id, name, definition },
        });

        const success = response.data.forms.createFormsDesignerDraft.ok;
        if (success)
          return formsDesignerCreateTemplatesSlice.createdDraft({ id, name });
      } catch (error) {
        log.error(
          new Error(
            `Error while creating designer draft (new): ${error.message}`,
          ),
        );
      }

      return formsDesignerCreateTemplatesSlice.createdDraftFailed();
    }),
  );
}

function createDraftFromSourceTemplateEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsDesignerState>,
  { client }: GlobalFormsDesignerEpicDependencies,
) {
  type Response = {
    forms: { createFormsDesignerDraftFromTemplate: { ok: boolean } };
  };

  return action$.pipe(
    filter(formsDesignerCreateTemplatesSlice.createDraft.match),
    filter(
      () =>
        state$.value.formsDesignerCreateTemplates.selectedMethod ===
        'COPY_FROM_EXISTING',
    ),
    mergeMap(async () => {
      const draftState =
        state$.value.formsDesignerCreateTemplates.createFromExistingInputs;

      const id = uuid.generate();
      const templateId = draftState.sourceTemplate?.id.toString();
      const name =
        draftState.rename ??
        (draftState.sourceTemplate.content as IListItemLabel).text;

      try {
        const response = await client.mutate<Response>({
          mutation: gql`
            mutation CreateFormsDesignerDraftFromTemplate(
              $id: ID!
              $templateId: ID!
              $name: String
            ) {
              forms {
                createFormsDesignerDraftFromTemplate(
                  input: { id: $id, formTemplateId: $templateId, name: $name }
                ) {
                  ok
                }
              }
            }
          `,
          variables: { id, templateId, name },
        });

        const success =
          response.data.forms.createFormsDesignerDraftFromTemplate.ok;
        if (success)
          return formsDesignerCreateTemplatesSlice.createdDraft({ id, name });
      } catch (error) {
        log.error(
          new Error(`Error while creating designer draft: ${error.message}`),
        );
      }

      return formsDesignerCreateTemplatesSlice.createdDraftFailed();
    }),
  );
}
