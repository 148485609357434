import { IUpdatedModule } from './types.ts';
import { IQuery } from '@seeeverything/ui.shell/src/api/api.queryBuilder/types.ts';
import { ModuleType } from '@seeeverything/ui.util/src/types.ts';

export function updatedModule(
  to: ModuleType,
  query: IQuery,
  source: IUpdatedModule['payload']['source'],
): IUpdatedModule {
  return {
    type: 'app/queryBuilder/UPDATED_MODULE',
    payload: { to, query, source },
  };
}
