/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FormsBulkUploadValidationRowStatus } from '@se/data/forms/types.ts';
import { Chip } from '@seeeverything/ui.primitives/src/components/Chip/Chip.tsx';
import { useCallback } from 'react';

export interface IStatusFiltersProps {
  onFilterChange?: (filterValue: FormsBulkUploadValidationRowStatus) => void;
  statusCounts?: StatusFilterCounts;
  statusFilters?: FormsBulkUploadValidationRowStatus[];
}

export interface StatusFilterCounts {
  error: number;
  success: number;
  warning: number;
}

/**
 * A grid view for results from a file validation or failed rows from an import run.
 */
export const StatusFilters: React.FC<IStatusFiltersProps> = ({
  onFilterChange,
  statusCounts,
  statusFilters,
}) => {
  const handleErrorFilterClick = useCallback(
    () => onFilterChange?.('Error'),
    [onFilterChange],
  );
  const handleSuccessFilterClick = useCallback(
    () => onFilterChange?.('Success'),
    [onFilterChange],
  );
  const handleWarningFilterClick = useCallback(
    () => onFilterChange?.('Warning'),
    [onFilterChange],
  );

  return (
    <div css={styles.base}>
      <Chip
        key={'error'}
        label={`Error (${statusCounts?.error ?? 0})`}
        onClick={handleErrorFilterClick}
        isSelected={hasFilter('Error', statusFilters)}
      />
      <Chip
        key={'warning'}
        label={`Warning (${statusCounts?.warning ?? 0})`}
        onClick={handleWarningFilterClick}
        isSelected={hasFilter('Warning', statusFilters)}
      />
      <Chip
        key={'success'}
        label={`Success (${statusCounts?.success ?? 0})`}
        onClick={handleSuccessFilterClick}
        isSelected={hasFilter('Success', statusFilters)}
      />
    </div>
  );
};

const hasFilter = (filterType: string, filters?: string[]) =>
  filters?.some((filter) => filter === filterType);

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
  }),
};
