/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Mask } from './Mask.tsx';

export interface ISplashProps {
  showMask?: boolean;
  maskOpacity?: number;
  maskHeight?: number;
  background?: string;
  backgroundSize?: string;
  backgroundPosition?: string;
  color?: string;
  opacity?: number;
  top?: number;
}

export const Splash = (props: ISplashProps) => {
  const {
    showMask = false,
    maskOpacity = 0.7,
    background,
    backgroundSize = 'cover',
    backgroundPosition = 'center center',
    color,
    maskHeight = 240,
    opacity = 0.2,
    top = 0,
  } = props;

  const styles = {
    watermark: css({
      position: 'absolute',
      inset: `${top}px 0 0 0`,
      opacity,
      background:
        background && background !== 'none' ? `url(${background})` : undefined,
      backgroundSize,
      backgroundPosition,
    }),
  };
  return (
    <div>
      <div css={styles.watermark} />
      {showMask && (
        <Mask height={maskHeight} opacity={maskOpacity} color={color} />
      )}
    </div>
  );
};
