import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { StateObservable, combineEpics } from 'redux-observable';
import {
  EMPTY,
  Observable,
  concatAll,
  concatMap,
  filter,
  from,
  mergeMap,
  of,
} from 'rxjs';
import * as data from '../data/index.ts';

import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import * as formsDesignerPublishTemplatesSlice from './formsDesignerPublishTemplatesSlice.ts';
import * as formsDesignerViewTemplatesSlice from './formsDesignerViewTemplatesSlice.ts';
import {
  GlobalFormsDesignerEpicDependencies,
  GlobalFormsDesignerState,
} from './store.ts';

export const publishTemplatesEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsDesignerState
>(
  archiveOverwrittenDraftsEpic,
  publishDraftToNewTemplateEpic,
  publishDraftToOverwriteTemplateEpic,
);

function archiveOverwrittenDraftsEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsDesignerState>,
  { client }: GlobalFormsDesignerEpicDependencies,
) {
  return action$.pipe(
    filter(formsDesignerPublishTemplatesSlice.publishedTemplate.match),
    filter(
      () =>
        state$.value.formsDesignerPublishTemplates.selectedMethod ===
        'OVERWRITE_EXISTING',
    ),
    concatMap(async (action) => {
      const { id, name, templateId } = action.payload;

      try {
        const drafts = await data.drafts(
          client,
          state$.value.formsDesignerViewTemplates.orderBy,
          templateId,
        );
        if (!drafts) return EMPTY;

        const deleteActions = drafts
          .filter((draft) => draft.id !== id)
          .map((draft) =>
            formsDesignerViewTemplatesSlice.deleteTemplate({
              id: draft.id,
              deletePublishedTemplate: false,
            }),
          );

        return deleteActions.length ? from(deleteActions) : EMPTY;
      } catch {
        return of(
          formsDesignerViewTemplatesSlice.deleteTemplateFailed({ id, name }),
        );
      }
    }),
    concatAll(),
  );
}

function publishDraftToNewTemplateEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsDesignerState>,
  { client }: GlobalFormsDesignerEpicDependencies,
) {
  type Response = {
    forms: { publishFormsDesignerDraftToNewTemplate: { ok: boolean } };
  };
  return action$.pipe(
    filter(formsDesignerPublishTemplatesSlice.publishTemplate.match),
    filter(
      () =>
        state$.value.formsDesignerPublishTemplates.selectedMethod === 'NEW' &&
        Boolean(state$.value.formsDesignerEditor.draft?.id),
    ),
    mergeMap(async () => {
      const id = state$.value.formsDesignerEditor.draft.id;

      try {
        const name =
          state$.value.formsDesignerPublishTemplates.createNewInputs.name;
        const categoryId =
          state$.value.formsDesignerPublishTemplates.createNewInputs.category.id.toString();
        const definition = JSON.stringify(
          state$.value.formsDesignerEditor.draft.definition,
        );
        const visibilityLimitation =
          state$.value.formsDesignerPublishTemplates.createNewInputs.visibility?.id.toString();

        const response = await client.mutate<Response>({
          mutation: gql`
            mutation PublishDesignerDraftToNewTemplate(
              $id: ID!
              $name: String!
              $categoryId: ID!
              $definition: String!
              $visibilityLimitation: String
            ) {
              forms {
                publishFormsDesignerDraftToNewTemplate(
                  input: {
                    id: $id
                    name: $name
                    categoryId: $categoryId
                    definition: $definition
                    visibilityLimitation: $visibilityLimitation
                  }
                ) {
                  ok
                }
              }
            }
          `,
          variables: { id, name, categoryId, definition, visibilityLimitation },
        });
        if (response.data.forms.publishFormsDesignerDraftToNewTemplate.ok)
          return formsDesignerPublishTemplatesSlice.publishedTemplate({
            id,
            name,
          });
      } catch (error) {
        log.error(
          new Error(`Unable to publish draft with id ${id} - ${error.message}`),
        );
      }

      return formsDesignerPublishTemplatesSlice.publishTemplateFailed({
        errorMessage: 'Something went wrong. Please try again later.',
      });
    }),
  );
}

function publishDraftToOverwriteTemplateEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsDesignerState>,
  { client }: GlobalFormsDesignerEpicDependencies,
) {
  type Response = {
    forms: { publishFormsDesignerDraftToExistingTemplate: { ok: boolean } };
  };
  return action$.pipe(
    filter(formsDesignerPublishTemplatesSlice.publishTemplate.match),
    filter(
      () =>
        state$.value.formsDesignerPublishTemplates.selectedMethod ===
          'OVERWRITE_EXISTING' &&
        Boolean(state$.value.formsDesignerEditor.draft?.id),
    ),
    mergeMap(async () => {
      const id = state$.value.formsDesignerEditor.draft.id;

      try {
        const name = state$.value.formsDesignerEditor.draft.name;
        const templateId =
          state$.value.formsDesignerPublishTemplates.overwriteExistingInputs.existingTemplate.id.toString();
        const definition = JSON.stringify(
          state$.value.formsDesignerEditor.draft.definition,
        );
        const response = await client.mutate<Response>({
          mutation: gql`
            mutation PublishDesignerDraftToExistingTemplate(
              $id: ID!
              $templateId: ID!
              $definition: String!
            ) {
              forms {
                publishFormsDesignerDraftToExistingTemplate(
                  input: {
                    id: $id
                    formTemplateId: $templateId
                    definition: $definition
                  }
                ) {
                  ok
                }
              }
            }
          `,
          variables: { id, templateId, definition },
        });

        if (response.data.forms.publishFormsDesignerDraftToExistingTemplate.ok)
          return formsDesignerPublishTemplatesSlice.publishedTemplate({
            id,
            name,
            templateId,
          });
      } catch (error) {
        log.error(
          new Error(
            `Unable to publish draft to overwrite template with id ${id} - ${error.message}`,
          ),
        );
      }

      return formsDesignerPublishTemplatesSlice.publishTemplateFailed({
        errorMessage: 'Something went wrong. Please try again later.',
      });
    }),
  );
}
