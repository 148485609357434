/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Splash } from '../Shell/components/Splash.tsx';

export interface IErrorScreenProps {
  background?: string;
  backgroundColor?: string;
  errorColor?: string;
  errorDetail?: string;
  errorMessage?: string;
  icon?: string;
  iconColor?: string;
  onClick?: () => void;
  generalError?: boolean;
}

const DEFAULT_ERROR_MESSAGE =
  'Something went wrong. Why not try refreshing the page?';
const DEFAULT_ERROR_DETAIL =
  'If this problem persists, please contact your support team.';

/**
 * Provides the UI for a general error screen.
 */
export const ErrorScreen: React.FC<IErrorScreenProps> = ({
  background = '/ui.shell/images/Splash/watermark.jpg',
  backgroundColor,
  errorColor = COLORS.WHITE,
  errorDetail = DEFAULT_ERROR_DETAIL,
  errorMessage = DEFAULT_ERROR_MESSAGE,
  icon,
  iconColor = COLORS.WHITE,
  onClick,
  generalError = false,
}) => {
  const isInteractive = Boolean(onClick);
  const cursor = isInteractive ? 'pointer' : undefined;

  const handleClicked = React.useCallback(() => {
    onClick?.();
  }, [onClick]);

  const computedStyles = {
    base: css({
      position: 'absolute',
      inset: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: errorColor,
      backgroundColor,
    }),
    errorInner: {
      position: 'relative' as const,
      padding: 20,
      marginBottom: '5%',
      cursor,
    },
  };

  const Icon = icon ? Icons[icon] : undefined;

  return generalError ? (
    <div css={computedStyles.base}>
      <iframe
        src={'https://g.se-cdn.net/custom-errors/generic.html'}
        allowFullScreen={true}
        width={'100%'}
        height={'100%'}
        css={styles.iframe}
      />
    </div>
  ) : (
    <div css={computedStyles.base}>
      {background && <Splash background={background} />}
      <Button
        isEnabled={Boolean(isInteractive)}
        onClick={isInteractive ? handleClicked : undefined}
        style={computedStyles.errorInner}
      >
        <>
          {icon && (
            <div css={styles.iconContainer}>
              <Icon size={54} fill={iconColor} cursor={cursor} />
            </div>
          )}
          <div css={styles.errorText}>
            <Text color={errorColor} cursor={cursor}>
              {errorMessage}
            </Text>
          </div>
          {errorDetail && (
            <div css={[styles.errorText, styles.errorDetail]}>
              <Text color={errorColor} cursor={cursor}>
                {errorDetail}
              </Text>
            </div>
          )}
        </>
      </Button>
    </div>
  );
};

const styles = {
  errorText: css({
    width: 400,
    textAlign: 'center',
    cursor: 'inherit',
  }),
  errorDetail: css({
    marginTop: 20,
  }),
  iconContainer: css({
    cursor: 'inherit',
    marginBottom: 22,
    display: 'flex',
    justifyContent: 'center',
  }),
  iframe: css({
    border: 0,
  }),
};
