import { IChipData } from '@seeeverything/ui.shell/src/api/api.queryBuilder/types.ts';
import { chipAddedExternally } from '@seeeverything/ui.shell/src/redux/query/actions.ts';
import { ChipType } from '@seeeverything/ui.util/src/urlDeepLink/urlDeepLink.ts';

export interface IAddChipOptions {
  id?: string;
  type: ChipType;
  label?: string;
  icon?: string;
}

export function addChip(options: IAddChipOptions) {
  return addChipAction(options);
}

export function addChipAction(options: IAddChipOptions, shouldRunQuery = true) {
  return chipAddedExternally({ ...options, value: options.id }, shouldRunQuery);
}

export function addGridV2ChipAction(
  chipData: Partial<IChipData>,
  shouldRunQuery = true,
) {
  return addChipAction(
    {
      ...chipData,
      id: chipData.value,
      type: 'gridV2',
    },
    shouldRunQuery,
  );
}

export function addClickThroughGridChipAction(
  chipData: Partial<IAddChipOptions>,
  shouldRunQuery = true,
) {
  return addChipAction(
    {
      ...chipData,
      type: 'clickThroughGrid',
    },
    shouldRunQuery,
  );
}

export function addCommentsListChipAction(chip: Partial<IChipData>) {
  return addChipAction({
    ...chip,
    id: chip.value,
    type: 'commentsList',
  });
}

export function addAuditLogChip() {
  const options: IAddChipOptions = {
    id: 'Audit Log',
    label: 'Audit Log',
    type: 'auditLog',
  };

  return addChip(options);
}

export function addFormsDesignerTemplateChip(id: string, label: string) {
  const options: IAddChipOptions = {
    id,
    label,
    type: 'formsDesignerTemplate',
  };

  return addChip(options);
}
