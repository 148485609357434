/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { makeStyles, Chip as MaterialChip } from '@material-ui/core';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/constants.ts';
import { CSSProperties } from '../../types/types.ts';
import { Paper } from '../Paper/Paper.tsx';
import { Text } from '../Text/Text.tsx';

export interface IChipProps {
  icon?: React.ReactElement;
  isSelected?: boolean;
  label: string;
  labelStyle?: SerializedStyles;
  onClick?: () => void;
  style?: CSSProperties;
  tooltip?: string;
}

const useStyles = ({ icon, isSelected, style, onClick }: IChipProps) =>
  makeStyles({
    root: {
      border: `solid 1px ${color.format(-0.1)}`,
      borderRadius: 4,
      background: 'transparent',
      cursor: onClick ? 'pointer' : 'default',
      backgroundColor: isSelected ? COLORS.BLUE : color.format(-0.1),
      display: 'flex',
      flexDirection: 'row',
      padding: icon ? '0 12px 0 10px' : '0 12px',
      gap: 6,
      '&:hover': {
        background: isSelected
          ? color.create(COLORS.BLUE).brighten(0.2).css()
          : color.format(-0.2),
      },
      '&:focus': {
        backgroundColor: isSelected ? COLORS.BLUE : color.format(-0.1),
        '&:hover': {
          background: isSelected
            ? color.create(COLORS.BLUE).brighten(0.2).css()
            : color.format(-0.2),
        },
      },
      '& span': {
        padding: 0,
      },
      ...style,
    },
  })();

/**
 * See:
 *  https://material-ui.com/components/chips/
 *  https://material-ui.com/api/chip/
 */
export const Chip: React.FC<IChipProps> = (props) => {
  const { icon, label, labelStyle, onClick, isSelected, tooltip } = props;

  const classStyles = useStyles(props);

  const computedStyles = {
    label: css({
      color: isSelected ? 'white' : color.format(-0.8),
    }),
  };

  return (
    <Paper elevation={0} style={styles.chipContainer}>
      <MaterialChip
        onClick={onClick}
        label={
          <Text
            tooltip={tooltip}
            cursor={'inherit'}
            style={[computedStyles.label, labelStyle]}
          >
            {label}
          </Text>
        }
        classes={{ root: classStyles.root }}
        icon={icon}
      />
    </Paper>
  );
};

const styles = {
  chipContainer: {
    display: 'inline-block',
  },
};
