/** @jsxImportSource @emotion/react */
import { IRenderDataGridCellArgs } from '@seeeverything/ui.primitives/src/components/DataGrid/types.ts';
import { mergeDeepRight } from 'ramda';
import {
  DashboardV2ViewEntity,
  GridCellDecorator,
  IDashboardGrid,
  IDashboardGridRow,
  IGridCellDecoration,
} from '../../types.ts';
import { alignmentDecorator } from '../decorators/alignment.ts';
import { goalsAndActionsDecorator } from '../decorators/goalsAndActions.ts';
import { linkDecorator } from '../decorators/link.ts';
import { maskedDecorator } from '../decorators/masked.ts';
import { targetDecorator } from '../decorators/target.ts';
import { tooltipDecorator } from '../decorators/tooltip.tsx';
import { trendDecorator } from '../decorators/trend.ts';
import { renderFromDecoration } from './fromDecoration.tsx';

// These are executed in array order, with the first having highest precedence.
const decorators: GridCellDecorator[] = [
  goalsAndActionsDecorator,
  targetDecorator,
  trendDecorator,
  tooltipDecorator,
  linkDecorator,
  maskedDecorator,
  alignmentDecorator,
];

type CustomCellRenderOptions = {
  args: IRenderDataGridCellArgs<IDashboardGridRow>;
  viewEntity: DashboardV2ViewEntity;
  launchHandler: (
    args: IRenderDataGridCellArgs<IDashboardGridRow>,
  ) => () => void;
  linkHandler: (args: IRenderDataGridCellArgs<IDashboardGridRow>) => () => void;
  categoryClickedHandler?: (
    args: IRenderDataGridCellArgs<IDashboardGridRow>,
  ) => () => void;
  gridState?: IDashboardGrid;
};

export const customCellRender = (options: CustomCellRenderOptions) => {
  const {
    args,
    viewEntity,
    launchHandler,
    linkHandler,
    categoryClickedHandler,
    gridState,
  } = options;

  if (!gridState || !args.row.enrichedData) return;

  const columnDefinition = gridState.columns[args.columnIndex];
  const datapoint = args.row.enrichedData[args.columnIndex];
  const themeKey = cellTheme(args.rowIndex, gridState) || args.theme;
  const theme = args.getCellTheme(themeKey);

  const decoration = decorators
    .map((decorate) =>
      decorate({
        datapoint,
        cellTheme: theme,
        cellArgs: args,
        columnDefinition,
        launchHandler,
        viewEntity,
        gridState,
        linkHandler,
        categoryClickedHandler,
      }),
    )
    .reduce<IGridCellDecoration>(mergeDeepRight, {});

  return renderFromDecoration(datapoint, theme, decoration);
};

const cellTheme = (rowIndex: number, gridState: IDashboardGrid) => {
  if (!gridState) return;
  if (!gridState.dataState.data?.data?.length) return;
  if (!gridState.tagStyles) return;

  const tags = (gridState.dataState.data.data[rowIndex]?.tags || [])?.[0];
  if (!tags?.length) return;

  return gridState.tagStyles[tags[0]];
};
