/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {
  ISelectionListColumn,
  ISelectionListItem,
  SelectionListChangedEvent,
  SelectionListChangedEventHandler,
  SelectionListClickEventHandler,
  SelectionListEdgeEventHandler,
  SelectionListSize,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { SelectionListHierarchyViewport } from '@seeeverything/ui.primitives/src/components/SelectionListHierarchyViewport/SelectionListHierarchyViewport.tsx';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import * as R from 'ramda';
import React from 'react';
import { Observable } from 'rxjs';
import { isFuzzyMatch } from './util.ts';

export type DropdownListFilterType = 'DIM' | 'HIDE';
export interface IDropdownListProps {
  selectedId?: number | string;
  column?: ISelectionListColumn;
  columnTotal?: number;
  filter?: string;
  filterType?: DropdownListFilterType;
  isActive?: boolean;
  isScrollable?: boolean;
  size?: SelectionListSize;
  keys$?: Observable<React.KeyboardEvent>;
  onClick?: SelectionListClickEventHandler;
  onSelectionChanged?: SelectionListChangedEventHandler;
  onEdgeSelected?: SelectionListEdgeEventHandler;
}

/**
 * A query-builder dropdown with a selection list (single or multi-column).
 */
export class DropdownList extends React.Component<IDropdownListProps, object> {
  public UNSAFE_componentWillReceiveProps(nextProps: IDropdownListProps) {
    const { selectedId } = nextProps;
    if (selectedId !== undefined) {
      this.setState({ selectedId });
    }
  }

  public render() {
    const { filter, isActive, size = 'LARGE', filterType = 'DIM' } = this.props;
    const column = filterColumns(filterType, filter, this.props.column);
    const styles = {
      base: css({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        flex: '1 1 auto',
      }),
    };

    return (
      <div css={styles.base}>
        <SelectionListHierarchyViewport
          column={column}
          columnTotal={this.props.columnTotal || 1}
          selectedId={this.props.selectedId}
          size={size}
          emptyMessage={'Nothing to display.'}
          isScrollable={this.props.isScrollable}
          isFocused={isActive}
          isDimmedSelectable={false}
          keys$={this.props.keys$}
          tabIndex={-1}
          onSelectionChanged={this.handleSelectionChanged}
          onEdgeSelected={this.props.onEdgeSelected}
          onClick={this.props.onClick}
        />
      </div>
    );
  }

  private handleSelectionChanged = (e: SelectionListChangedEvent) => {
    const selectedId = e.to.item?.id;
    this.setState({ selectedId });

    this.props.onSelectionChanged?.(e);
  };
}

function filterColumns(
  filterType: DropdownListFilterType,
  filter?: string,
  column?: ISelectionListColumn,
) {
  if (!column) {
    return column;
  }
  if (!filter || !filter.trim()) {
    return column;
  }
  column = { ...column };

  // Update the dimmed state on items that are filtered.
  const activeItems: ISelectionListItem[] = [];
  const dimNonMatching = (item: ISelectionListItem) => {
    if (item.type && item.type !== 'ITEM') {
      return item; // Only evaluate actual items (not headers, divider etc).
    }
    const isDimmed = !isFuzzyMatch(item.content, filter);
    item = { ...item, isDimmed };
    if (!isDimmed) {
      activeItems.push(item);
    }
    return filterType === 'HIDE' ? (isDimmed ? undefined : item) : item;
  };

  const items = column.items
    .map((item) => dimNonMatching(item))
    .filter((item) => item && item.type !== 'ITEM');
  column.items = R.reject(R.isNil, items);

  // Make the icon of the first filtered/active icon blue
  // indicating that it is the default selection value (on ENTER).
  if (activeItems.length > 0) {
    activeItems[0].iconColor = { default: COLORS.BLUE, selected: COLORS.WHITE };
    activeItems[0].isHighlighted = true;
  }

  return column;
}
