/* eslint-disable no-console */
import { StateObservable } from 'redux-observable';
import { EMPTY, Observable, concatAll, filter, map } from 'rxjs';
import { IS_BROWSER } from '../../constants/constants.ts';
import { ReduxAction, GlobalState } from '../types.ts';

// These actions are quite spammy and generally not useful to log.
// If needed, use the redux devtools to see them.
const IGNORE_ACTIONS = [
  'global/scroll/trackDataIdInView',
  'global/scroll/clearScrollToDataId',
  'global/debug/setLoggingFlag',
  'global/debug/setStartsWithFilter',
];

export function logActionsEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalState>,
) {
  return action$.pipe(
    filter((action) => {
      if (IGNORE_ACTIONS.includes(action.type)) return false;

      const { isLoggingEnabled, loggingStartsWithFilter } = state$.value.debug;
      return Boolean(
        isLoggingEnabled &&
          IS_BROWSER &&
          action.type
            .toLowerCase()
            .startsWith(loggingStartsWithFilter?.toLowerCase()),
      );
    }),
    map((action) => {
      console.groupCollapsed(`%c${action.type}`, 'font-weight:normal');
      console.table(action.payload);
      console.groupEnd();
      return EMPTY;
    }),
    concatAll(),
  );
}
