/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { orgHierarchyQuery } from '@se/data/orgHierarchy/query/index.ts';
import { LabelButton } from '@seeeverything/ui.primitives/src/components/LabelButton/LabelButton.tsx';
import { MultiSelectList } from '@seeeverything/ui.primitives/src/components/MultiSelectList/MultiSelectList.tsx';
import { Popup } from '@seeeverything/ui.primitives/src/components/Popup/Popup.tsx';
import { useGraphQL } from '@seeeverything/ui.util/src/graphql/GraphQLProvider.tsx';
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { editDistributionListSlice } from '../../../redux/editDistributionList/index.ts';
import { useFormsDispatch, useFormsSelector } from '../../../redux/store.ts';

export interface IPositionTitlesMultiSelectContainer {
  ruleId: string;
}

export const PositionTitlesMultiSelectContainer: React.FC<
  IPositionTitlesMultiSelectContainer
> = ({ ruleId }) => {
  const dispatch = useFormsDispatch();
  const { client } = useGraphQL();

  const rule = useFormsSelector((state) =>
    state.formEditDistributionList.draft.rules.find((r) => r.id === ruleId),
  );

  const isEditable = useFormsSelector(
    (state) => state.formEditDistributionList.draft.canEdit === true,
  );

  const selections = useMemo(() => {
    if (!rule) return [];
    return rule.selections as string[];
  }, [rule]);

  const buttonLabel = useMemo(() => {
    if (!selections.length) return 'Select Position Titles';
    return selections.join(`, `);
  }, [selections]);

  const isError = Boolean(rule?.errors);
  const [filter, setFilter] = useState('');
  const [isDropdownShowing, setIsDropdownShowing] = useState(false);

  const handleItemSelectionChanged = useCallback(
    (id: string, checked: boolean) => {
      const nextSelections = checked
        ? [...selections, id]
        : selections.filter((s) => s !== id);

      dispatch(
        editDistributionListSlice.updateRuleRow({
          id: ruleId,
          selections: nextSelections.sort((a, b) => a.localeCompare(b)),
          type: rule.type,
        }),
      );
    },
    [dispatch, rule.type, ruleId, selections],
  );

  const showPopup = useCallback(() => {
    setFilter('');
    setIsDropdownShowing(true);
  }, []);

  const { data, isFetching, isLoading, fetchNextPage, hasNextPage } =
    useInfiniteQuery({
      placeholderData: keepPreviousData,
      queryKey: [{ key: 'positionTitles', filter }],
      queryFn: async ({ pageParam, queryKey }) => {
        const response = await orgHierarchyQuery.getPositionTitles(
          client,
          pageParam,
          queryKey[0].filter,
        );
        return response.isSuccess ? response.data : null;
      },
      getNextPageParam: (latestPage) => {
        if (!latestPage) return null;
        if (!latestPage.pageInfo.hasNextPage) return null;
        return latestPage.pageInfo.currentPage + 1;
      },
      initialPageParam: 1,
    });

  const dropdownItems = useMemo(() => {
    if (!data?.pages) return;

    return data.pages
      .filter(Boolean)
      .map((page) => page.positionTitles)
      .flat()
      .map((positionTitle) => ({
        id: positionTitle,
        label: positionTitle,
        checked: selections.includes(positionTitle),
      }));
  }, [data?.pages, selections]);

  return (
    <div css={css({ display: 'flex' })}>
      <LabelButton
        bold={!isError}
        capitalized={false}
        ellipsis={true}
        isEnabled={isEditable}
        label={buttonLabel}
        maxWidth={233}
        onClick={showPopup}
        theme={isError ? 'ERROR' : undefined}
      />
      {isDropdownShowing && (
        <Popup onHide={() => setIsDropdownShowing(false)} hideOnClick={false}>
          <MultiSelectList
            isLoading={isLoading}
            items={dropdownItems}
            filter={filter}
            onNextPageRequested={fetchNextPage}
            hasNextPage={isFetching ? 'LOADING' : hasNextPage}
            onItemSelectionChanged={handleItemSelectionChanged}
            onFilterChanged={setFilter}
          />
        </Popup>
      )}
    </div>
  );
};
