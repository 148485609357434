import { Action, Store } from 'redux';
import { Subject } from 'rxjs';

/**
 * Wraps a redux store within an observable.
 */
export function toStore$<S, A extends Action<any>>(store: Store<S, A>) {
  const subject = new Subject<S>();
  store.subscribe(() => subject.next(store.getState()));
  return subject;
}
