import { reducer as appReducer } from './app/reducer.ts';
import { auditSlice } from './audit/index.ts';
import { reducer as csvExportReducer } from './csvExport/reducer.ts';
import { reducer as modalDialogReducer } from './modalDialog/reducer.ts';
import { reducer as queryReducer } from './query/reducer.ts';
import { sheetSchedulesSlice } from './sheetSchedules/index.ts';
import { reducer as sheetsReducer } from './sheets/reducer.ts';

export const shellReducers = {
  app: appReducer,
  query: queryReducer,
  sheets: sheetsReducer,
  modalDialog: modalDialogReducer,
  audit: auditSlice.reducer,
  csvExport: csvExportReducer,
  sheetSchedules: sheetSchedulesSlice.reducer,
};
