import { IDigitalContentLine } from '@seeeverything/ui.forms/src/types/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { uuid } from '@seeeverything/ui.util/src/uuid/index.ts';
import gql from 'graphql-tag';
import moment from 'moment';
import { StateObservable, ofType } from 'redux-observable';
import { EMPTY, Observable, concatAll, filter, mergeMap, of } from 'rxjs';
import * as data from '../../data/index.ts';
import * as formsDesignerPublishTemplatesSlice from '../formsDesignerPublishTemplatesSlice.ts';
import {
  GlobalFormsDesignerEpicDependencies,
  GlobalFormsDesignerState,
} from '../store.ts';
import * as actions from './actions.digitalContent.ts';
import { uploadedFileMetadata } from './fileMetadata.ts';
import {
  FormsDesignerDigitalContentPackActivateAction,
  FormsDesignerDigitalContentPackDeactivateAction,
  FormsDesignerFileDelete,
  FormsDesignerFileUpload,
} from './types.digitalContent.ts';
import { FormsDesignerDesignerLoadedDraftAction } from './types.ts';
import { lineToJson } from './util.ts';

/**
 * Responsible for activating a digital content pack.
 */
export function activateDigitalContentPack(
  action$: Observable<FormsDesignerDigitalContentPackActivateAction>,
  state$: StateObservable<GlobalFormsDesignerState>,
  { client }: GlobalFormsDesignerEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms.designer/designer/digitalContent/ACTIVATE_PACK'),
    filter(() => {
      const draft = state$.value.formsDesignerEditor.draft;

      return (
        draft?.designerLines?.digitalContent?.isEnabled &&
        draft?.designerLines?.digitalContent?.packId
      );
    }),
    mergeMap(async () => {
      const id =
        state$.value.formsDesignerEditor.draft?.designerLines?.digitalContent
          ?.packId;

      const { success, exception } = await data.activateDigitalContentPack(
        client,
        id,
      );

      return success
        ? actions.activatedDigitalContentPack(id)
        : actions.activateDigitalContentPackFailed(exception);
    }),
  );
}

/**
 * Responsible for creating a new digital content pack.
 */
export function archiveDigitalContentPackFile(
  action$: Observable<FormsDesignerFileDelete>,
  _: StateObservable<GlobalFormsDesignerState>,
  { client }: GlobalFormsDesignerEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms.designer/designer/digitalContent/FILE_DELETE'),
    mergeMap(async (action) => {
      const { digitalContentPackId, fileId } = action.payload;

      const { success, exception } = await data.archiveDigitalContentPackFile(
        client,
        digitalContentPackId,
        fileId,
      );
      return success
        ? actions.fileDeleted(digitalContentPackId, fileId)
        : actions.fileErrorDeleting(fileId, exception);
    }),
  );
}

/**
 * Responsible for creating a new digital content pack.
 */
export function createDigitalContentPack(
  action$: Observable<FormsDesignerDesignerLoadedDraftAction>,
  state$: StateObservable<GlobalFormsDesignerState>,
  { client }: GlobalFormsDesignerEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms.designer/designer/LOADED_DRAFT'),
    filter(() => {
      const draft = state$.value.formsDesignerEditor.draft;

      return (
        draft?.designerLines?.digitalContent?.isEnabled &&
        !draft?.designerLines?.digitalContent?.packId
      );
    }),
    mergeMap(async () => {
      const id = uuid.generate();

      const { success, exception } = await data.createDigitalContentPack(
        client,
        id,
        false,
        '',
        'description',
        'Digital Content',
      );
      return success
        ? actions.createdDigitalContentPack(id)
        : actions.createDigitalContentPackFailed(exception);
    }),
  );
}

/**
 * Responsible for activating a digital content pack.
 */
export function deactivateDigitalContentPack(
  action$: Observable<FormsDesignerDigitalContentPackDeactivateAction>,
  _: StateObservable<GlobalFormsDesignerState>,
  { client }: GlobalFormsDesignerEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms.designer/designer/digitalContent/DEACTIVATE_PACK'),
    mergeMap(async (action) => {
      const { id } = action.payload;

      const { success, exception } = await data.deactivateDigitalContentPack(
        client,
        id,
      );

      return success
        ? actions.deactivatedDigitalContentPack(id)
        : actions.deactivateDigitalContentPackFailed(exception);
    }),
  );
}

/**
 * Responsible for loading a digital content pack.
 */
export function loadDigitalContentPack(
  action$: Observable<FormsDesignerDesignerLoadedDraftAction>,
  _: StateObservable<GlobalFormsDesignerState>,
  { client }: GlobalFormsDesignerEpicDependencies,
) {
  return action$.pipe(
    ofType('ui.forms.designer/designer/LOADED_DRAFT'),
    filter((action) =>
      action.payload?.designerLines?.digitalContent?.packId ? true : false,
    ),
    mergeMap(async (action) => {
      try {
        const digitalContentPack = await data.loadDigitalContentPack(
          client,
          action.payload?.designerLines?.digitalContent?.packId,
        );

        return actions.loadedDigitalContentPack(digitalContentPack);
      } catch (error) {
        return actions.loadDigitalContentPackFailed(error);
      }
    }),
  );
}

/**
 * Responsible for updating an existing digital content pack.
 */
export function updateDigitalContentPack(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsDesignerState>,
  { client }: GlobalFormsDesignerEpicDependencies,
) {
  return action$.pipe(
    filter(formsDesignerPublishTemplatesSlice.publishedTemplate.match),
    filter(() => {
      const lines = state$.value.formsDesignerEditor.draft?.lines;
      const templateJson = lineToJson(lines?.digitalContent);
      return Boolean(
        templateJson?.digitalContent && templateJson.digitalContent.isEnabled,
      );
    }),
    mergeMap(async (action) => {
      const id = action.payload.id;
      const draft = state$.value.formsDesignerEditor.draft;
      const templateJson = lineToJson(draft?.lines?.digitalContent);

      const { packId, commentsEnabled, commentsGuidance, description, title } =
        templateJson?.digitalContent as IDigitalContentLine;

      if (
        !packId ||
        commentsEnabled === undefined ||
        (commentsEnabled && !commentsGuidance) ||
        !description ||
        !title
      ) {
        log.error(
          new Error(
            'Trying to update digital content pack with incomplete state.',
          ),
          templateJson?.digitalContent,
        );
        return EMPTY;
      }

      const { success, exception } = await data.updateDigitalContentPack(
        client,
        {
          id: packId,
          commentsEnabled,
          commentGuidance: commentsGuidance,
          description,
          summary: title,
          publishDate:
            formsDesignerPublishTemplatesSlice.publishedTemplate.match(action)
              ? moment().toISOString()
              : undefined,
        },
      );
      return success
        ? of(actions.updatedDigitalContentPack(id))
        : of(actions.updateDigitalContentPackFailed(exception));
    }),
    concatAll(),
  );
}

/**
 * Responsible for uploading a file on the server (via GraphQL).
 */
export function uploadFile(
  action$: Observable<FormsDesignerFileUpload>,
  _: StateObservable<GlobalFormsDesignerState>,
  { uploadClient }: GlobalFormsDesignerEpicDependencies,
) {
  type Response = {
    digitalContent: { uploadDigitalContentPackFile: { ok: boolean } };
  };

  return action$.pipe(
    ofType('ui.forms.designer/designer/digitalContent/FILE_UPLOAD'),
    mergeMap(async (action) => {
      const { digitalContentPackId, fileId, file } = action.payload;

      try {
        const result = await uploadClient.mutate<Response>({
          mutation: gql`
            mutation UploadFileToMeeting(
              $digitalContentPackId: ID!
              $fileId: ID!
              $file: Upload!
            ) {
              digitalContent {
                uploadDigitalContentPackFile(
                  input: {
                    digitalContentPackId: $digitalContentPackId
                    fileId: $fileId
                    document: $file
                  }
                ) {
                  ok
                }
              }
            }
          `,
          variables: { digitalContentPackId, fileId, file },
        });

        if (!result.data.digitalContent.uploadDigitalContentPackFile.ok)
          throw new Error();

        return actions.fileUploaded(fileId, uploadedFileMetadata(fileId, file));
      } catch (err) {
        log.error('Error occurred while trying to upload file', err);
        return actions.fileErrorUploading(fileId, 'Could not upload file.');
      }
    }),
  );
}
