/** @jsxImportSource @emotion/react */
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import {
  IToolButton,
  ToolButtonClickEvent,
  ToolButtonDropdownClickEvent,
} from '@seeeverything/ui.primitives/src/components/ToolButtonSet/index.ts';
import { useCallback, useMemo } from 'react';
import {
  toolbarClick,
  toolbarDropdownClick,
} from '../../redux/sheets/actions.ts';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';
import { SheetToolbar } from './SheetToolbar.tsx';

export interface ISheetToolbarContainerProps {
  dateFilters?: ISelectionListItem[];
  leftContent?: React.ReactNode;
  leftTools?: IToolButton[];
  moreItems?: ISelectionListItem[];
  rightContent?: React.ReactNode;
  rightTools?: IToolButton[];
  selectedToolIds?: string[];
  title?: string;
}

/**
 * The title/toolbar of a Sheet.
 */
export const SheetToolbarContainer: React.FC<ISheetToolbarContainerProps> = ({
  dateFilters,
  leftContent,
  leftTools: propsLeftTools,
  moreItems,
  rightContent,
  rightTools,
  selectedToolIds,
  title,
}) => {
  const dispatch = useShellDispatch();

  const sheet = useShellSelector((state) => state.sheets.current);

  const chips = useShellSelector((state) => state.query.query.chips);

  const handleToolClick = useCallback(
    (e: ToolButtonClickEvent) => dispatch(toolbarClick(e.tool.id)),
    [dispatch],
  );

  const handleToolDropdownClick = useCallback(
    (e: ToolButtonDropdownClickEvent) =>
      dispatch(toolbarDropdownClick(sheet, e.toolId, e.itemId)),
    [dispatch, sheet],
  );

  const leftTools = useMemo<IToolButton[]>(() => {
    const lt = propsLeftTools ?? [];

    return chips.filter((c) => c.type !== 'bu' && Boolean(c.value)).length > 1
      ? [{ id: 'SHEET_UP', icon: Icons.arrowUp }, ...lt]
      : lt;
  }, [propsLeftTools, chips]);

  return (
    <SheetToolbar
      title={title}
      leftTools={leftTools}
      rightTools={rightTools}
      leftContent={leftContent}
      rightContent={rightContent}
      moreItems={moreItems}
      dateFilters={dateFilters}
      onClick={handleToolClick}
      onDropdownClick={handleToolDropdownClick}
      selectedToolIds={selectedToolIds}
    />
  );
};
