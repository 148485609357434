import {
  FormsBulkUploadFileValidationErrorPayload,
  FormsBulkUploadValidateFileError,
  FormsBulkUploadValidateFileUploadRow,
} from '@se/data/forms/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import {
  FormatString,
  TenantLocale,
} from '@seeeverything/ui.util/src/redux/tenant/types.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import * as R from 'ramda';
import { pipe } from 'ramda';

export const tryStringFormat = (
  errorCode: string,
  format: FormatString,
  args: { [key: string]: string | object },
) => {
  try {
    const computedFormat = typeof format === 'string' ? format : format(args);
    return str.format(computedFormat, args);
  } catch (error) {
    log.error(
      new Error(
        `Unable to format Bulk Upload error outcome of type '${errorCode}'. Error: ${error}`,
      ),
    );
    return undefined;
  }
};

export const toFormatArgs = (
  locale: TenantLocale,
  payload: FormsBulkUploadFileValidationErrorPayload[],
): { [key: string]: string | object } => {
  if (!payload) return { locale };

  return payload
    .filter((x) => !R.isNil(x.value))
    .reduce((acc, { key, value }) => ({ ...acc, [key]: value }), { locale });
};

export const toStatusItem = (
  locale: TenantLocale,
  row: FormsBulkUploadValidateFileUploadRow,
): string => {
  if (row.status === 'Success') {
    if (row.associatedEntities.automatedActionIds.length > 0)
      return `${str.humanize(row.status)}. ${row.associatedEntities.automatedActionIds.length} 
           ${str.plural('Smart Action', row.associatedEntities.automatedActionIds.length)} 
          will be automatically created.`;

    return str.humanize(row.status);
  }

  const rowOutcomes = row.errors.map((error) => {
    const localizedOutcome = locale.forms.bulkUpload[error.code];
    const args = toFormatArgs(locale, error.payload);

    return (
      (localizedOutcome &&
        tryStringFormat(error.code, localizedOutcome, args)) ??
      str.humanize(error.code)
    );
  });

  const withRowOutcomes = (text: string) =>
    rowOutcomes.length ? `${text} - ${rowOutcomes.join(' ')}` : text;

  const withLocalizedAction = (text: string) => {
    const errorCodeKey =
      `${row.status.toUpperCase()}_OUTCOME` as FormsBulkUploadValidateFileError['code'];

    const localizedAction = locale.forms.bulkUpload[errorCodeKey];
    return `${text} ${localizedAction}`;
  };

  const outcomeText = pipe(withRowOutcomes, withLocalizedAction)(row.status);

  return outcomeText ?? str.humanize(row.status);
};
