import {
  DashboardActionChartToolsToggle,
  DashboardActionComponentViewDetail,
  DashboardActionComponentViewEntity,
  DashboardActionGridToolsToggle,
  DashboardActionHeaderLoaded,
  DashboardActionLoaded,
  DashboardActionSetCustomDateRange,
  DashboardActionSetDateFilter,
  DashboardActionViewClickThroughGrid,
} from '@seeeverything/ui.dashboards/src/redux/types.ts';
import {
  DashboardDateFilter,
  IDashboardDateFilterRepeatingFrequency,
  IDashboardDateFilterTodayBackwards,
} from '@seeeverything/ui.dashboards/src/types.ts';
import { AnalyticsEvent } from '@seeeverything/ui.util/src/redux/analytics/types.ts';
import moment from 'moment';
import { GlobalAppState } from '../../types.ts';
import { calculateDateDuration, trackEvent } from './util.ts';

export type TemplateDateFilterValues = {
  dateDuration: number;
  dateLabel: string;
};

export const generateDashboardClickthroughEvent = (
  action: DashboardActionViewClickThroughGrid,
): Promise<AnalyticsEvent[]> =>
  new Promise<AnalyticsEvent[]>((resolve, reject) => {
    try {
      resolve([
        trackEvent('dashboard_click_clickthrough', {
          columnLabel: action.payload.sourceGrid.columnClicked.id,
          sectionLabel: action.payload.sourceGrid.id,
          sourceType: action.payload.forEntity.type,
        }),
      ]);
    } catch (error) {
      reject(error);
    }
  });

export const generateDashboardDateChangeEvent = (
  action: DashboardActionSetDateFilter | DashboardActionSetCustomDateRange,
  state: GlobalAppState,
): Promise<AnalyticsEvent[]> =>
  new Promise<AnalyticsEvent[]>((resolve, reject) => {
    try {
      const { HEADER, settings } = state.dashboardsV2;

      if (!HEADER) return undefined;

      const dateFilter = HEADER.dateFilters.find(
        ({ id }: DashboardDateFilter) =>
          action.type === 'ui.dashboards/dashboard/HEADER/SET_DATE_FILTER'
            ? id === action.payload.itemId
            : id === settings.selectedDateFilterId,
      ) as DashboardDateFilter;

      if (
        dateFilter.kind === 'CUSTOM_RANGE' &&
        action.type === 'ui.dashboards/dashboard/HEADER/SET_DATE_FILTER'
      )
        return undefined;

      const financialYearStartMonth =
        state.tenantState.tenant.configuration.reportingDates
          .financialYearStartMonth;

      resolve([
        trackEvent('dashboard_date_change', {
          dateDuration: calculateDashboardDatePeriodDuration(
            dateFilter,
            action.type ===
              'ui.dashboards/dashboard/HEADER/SET_CUSTOM_DATE_RANGE'
              ? {
                  startDateISO: action.payload.startDateISO,
                  endDateISO: action.payload.endDateISO,
                }
              : undefined,
          ),
          dateLabel: dateFilter.toLabel({
            financialYearStartMonth,
          }),
          dashboardSourceType: HEADER.dataState.data.data.kind,
        }),
      ]);
    } catch (error) {
      reject(error);
    }
  });

export const generateDashboardDrilldownEvent = (
  action: DashboardActionComponentViewEntity,
  state: GlobalAppState,
): Promise<AnalyticsEvent[]> =>
  new Promise<AnalyticsEvent[]>((resolve, reject) => {
    try {
      resolve([
        trackEvent('dashboard_click_drilldown', {
          destinationType: action.payload.entity.type,
          sectionLabel: action.payload.componentId,
          sourceType: state.dashboardsV2.HEADER.dataState.data?.data.kind,
        }),
      ]);
    } catch (error) {
      reject(error);
    }
  });

export const generateDashboardHeaderLoadedEvent = (
  action: DashboardActionHeaderLoaded,
  state: GlobalAppState,
): Promise<AnalyticsEvent[]> =>
  new Promise<AnalyticsEvent[]>((resolve, reject) => {
    try {
      const authenticatedUser = state.tenantState.tenant.authenticatedUser;
      const dateFilterValues = getTemplateDateFilterValues(state);
      const template = state.dashboardsV2.template;
      const teamPath = state.dashboardsV2.HEADER.dataState.data.data.path;
      const personPayload =
        authenticatedUser.id !== template.entityId && template.kind === 'person'
          ? {
              personId: template.entityId,
              personPath: teamPath,
            }
          : undefined;
      const teamPayload =
        template.kind === 'team'
          ? {
              teamId: template.entityId,
              teamDepth: teamPath.length,
              teamName: state.dashboardsV2.HEADER.dataState.data.data.name,
              teamPath: teamPath,
            }
          : undefined;

      resolve([
        trackEvent(getDashboardHeaderLoadedEventIdentifier(action, state), {
          ...personPayload,
          ...teamPayload,
          ...dateFilterValues,
        }),
      ]);
    } catch (error) {
      reject(error);
    }
  });

export const generateDashboardToggleChangeEvent = async (
  action: DashboardActionChartToolsToggle | DashboardActionGridToolsToggle,
  state: GlobalAppState,
) => {
  const dashboard = state.dashboardsV2;
  const { toolId, buttonId } = action.payload;
  const tool =
    action.type === 'ui.dashboards/dashboard/CHART_TOOLS/TOGGLE'
      ? dashboard.CHART_TOOLS[toolId]
      : dashboard.GRID_TOOLS[toolId];

  const toggle = tool.toolButtons.find(({ id }) => id === buttonId);
  return toggle
    ? [
        trackEvent('dashboard_click_select_toggle', {
          sectionLabel: tool.group,
          sourceType: dashboard.template.kind,
          toggleLabel: toggle.label,
        }),
      ]
    : [];
};

export const generateSectionLabelEvent = (
  action: DashboardActionComponentViewDetail | DashboardActionLoaded,
  eventIdentifier: string,
  state: GlobalAppState,
): Promise<AnalyticsEvent[]> => {
  const sectionId = action.payload.id;
  const section =
    action.payload.componentType === 'CHART'
      ? state.dashboardsV2.CHART[sectionId]
      : state.dashboardsV2.GRID[sectionId];
  const sectionLabel = section?.id;

  return new Promise<AnalyticsEvent[]>((resolve) =>
    resolve([trackEvent(eventIdentifier, { sectionLabel })]),
  );
};

const calculateDashboardDatePeriodDuration = (
  dateFilter: DashboardDateFilter,
  selectedCustomDates?: { startDateISO: string; endDateISO: string },
): number => {
  switch (dateFilter.kind) {
    case 'CUSTOM_RANGE': {
      return calculateDateDuration(
        selectedCustomDates.startDateISO,
        selectedCustomDates.endDateISO,
      );
    }
    case 'REPEATING_FREQUENCY': {
      const repeatingFrequencyDateFilter =
        dateFilter as IDashboardDateFilterRepeatingFrequency;
      const from = moment(repeatingFrequencyDateFilter.startDate.toString());
      const to = moment(repeatingFrequencyDateFilter.endDate.toString());

      return to.diff(from, 'days');
    }
    case 'TODAY_BACKWARDS':
    default: {
      const todayBackwardsDateFilter =
        dateFilter as IDashboardDateFilterTodayBackwards;
      const currentDate = moment();
      const pastDate = moment(currentDate).subtract(
        todayBackwardsDateFilter.amount,
        'M',
      );

      return currentDate.diff(pastDate, 'days');
    }
  }
};

const getDashboardHeaderLoadedEventIdentifier = (
  action: DashboardActionHeaderLoaded,
  state: GlobalAppState,
): string => {
  const eventName =
    action.payload.data.data.kind === 'team'
      ? 'dashboard_team_view'
      : state.tenantState.tenant.authenticatedUser.id ===
          state.dashboardsV2.template.entityId
        ? 'dashboard_own_view'
        : 'dashboard_person_view';

  return eventName;
};

const getTemplateDateFilterValues = (
  state: GlobalAppState,
): TemplateDateFilterValues => {
  const currentDateFilter = state.dashboardsV2.HEADER?.dateFilters?.find(
    ({ id }: DashboardDateFilter) =>
      id === state.dashboardsV2.settings.selectedDateFilterId,
  );

  return {
    dateDuration: calculateDashboardDatePeriodDuration(
      currentDateFilter,
      state.dashboardsV2.settings.selectedCustomDates,
    ),
    dateLabel: currentDateFilter.toLabel({}),
  };
};
