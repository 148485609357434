/** @jsxImportSource @emotion/react */
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/constants.ts';
import { useMouseOver } from '../../hooks/useMouseOver.ts';
import { Icons } from '../Icon/Icons.tsx';
import { TextButton } from '../TextButton/TextButton.tsx';
import { IDataGridProps } from './DataGrid.tsx';

export interface IDataGridDownloadBehaviorProps {
  onDownloadClicked: () => void;
}

export const DataGridDownloadBehavior = (
  DataGrid: React.FC<IDataGridProps>,
): React.FC<IDataGridDownloadBehaviorProps & IDataGridProps> =>
  function DataGridDownload({ onDownloadClicked, isLoading, ...rest }) {
    const [isMouseOver, handleMouseEnter, handleMouseLeave] = useMouseOver();

    const computedStyle = {
      position: 'absolute' as const,
      zIndex: 10,
      right: '45px',
      marginTop: '10px',
      background: COLORS.WHITE,
      border: 'solid 1px #cccccc',
      paddingLeft: '15px',
      width: '25px',
      minWidth: '40px',
      boxShadow: `0 1px ${color.format(-0.3)}`,
      transition: 'opacity 0.2s',
      opacity: isMouseOver ? 1 : 0,
      '&:hover': { background: '#ebebeb' },
    };

    return (
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {!isLoading && (
          <TextButton
            textButtonStyle={computedStyle}
            icon={Icons.cloudDownload}
            onClick={onDownloadClicked}
            tooltip={'Download spreadsheet'}
            transitionDuration={'0.5s'}
          />
        )}
        <DataGrid {...rest} isLoading={isLoading} />
      </div>
    );
  };
