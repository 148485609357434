/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { authApi } from '@seeeverything/ui.auth/src/api/api.ts';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { useLocalStorage } from '@seeeverything/ui.primitives/src/hooks/useLocalStorage.ts';
import { ObjectInspector } from '@seeeverything/ui.test/src/components/ObjectInspector/ObjectInspector.tsx';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { StorageKey } from '@seeeverything/ui.util/src/storage/api.ts';
import { jwtDecode } from 'jwt-decode';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { interval } from 'rxjs';
import { Divider } from './components/Divider.tsx';
import { GraphQLDebug } from './components/GraphQLDebug.tsx';
import { ReduxDebugContainer } from './components/ReduxDebug.tsx';

export interface IDebugProps {
  graphqlEndpoint?: string;
  tenant: string;
  version?: string;
}

/**
 * Debug panel.
 */
export const Debug = ({ graphqlEndpoint, tenant, version }: IDebugProps) => {
  const [idToken, setIdToken] = useState<string>(undefined);
  const [accessToken, setAccessToken] = useState<string>(undefined);
  const [expiresAt, setExpiresAt] = useState<string>(undefined);

  const [, setDebug] = useLocalStorage(StorageKey.Debug, true);

  const setAuthDetails = useCallback(async () => {
    try {
      setIdToken(await authApi.getIdToken());
      setAccessToken(await authApi.getAccessToken());
      setExpiresAt(await authApi.getExpiresAt());
    } catch {
      setIdToken(undefined);
      setAccessToken(undefined);
      setExpiresAt(undefined);
    }
  }, []);

  useEffect(() => {
    const subscription = interval(1000).subscribe(setAuthDetails);

    return () => subscription.unsubscribe();
  }, [setAuthDetails]);

  useEffect(() => {
    setAuthDetails();
  }, [setAuthDetails]);

  const closeDebug = useCallback(() => {
    setDebug(false);
  }, [setDebug]);

  const authInfoObjectInspection = useMemo(
    () => ({
      idTokenDecoded: idToken && jwtDecode(idToken),
      accessTokenDecoded: accessToken && jwtDecode(accessToken),
      authExpiryDate: expiresAt && new Date(expiresAt),
      tokens: {
        accessToken,
        idToken,
        expiresAt,
      },
    }),
    [accessToken, expiresAt, idToken],
  );

  return (
    <div css={styles.base}>
      <div className={'see-debug'} css={styles.inner}>
        <Button onClick={closeDebug} style={styles.button}>
          <Text style={styles.buttonText} cursor={'inherit'}>
            {'Close Debug Panel'}
          </Text>
        </Button>
        <Divider />
        <a href={'/tenant'}>{`Switch Tenant (currently ${tenant})`}</a>
        <Divider />
        {graphqlEndpoint && (
          <a
            href={graphqlEndpoint}
            target={'_blank'}
            rel={'noreferrer'}
            css={styles.a}
          >
            {graphqlEndpoint}
          </a>
        )}
        <Divider />
        <span>
          {`Version: `} <b>{version || 'Unspecified'}</b>
        </span>
        <Divider />
        <Button onClick={setAuthDetails} style={styles.button}>
          <Text style={styles.buttonText} cursor={'inherit'}>
            {'Refresh auth details'}
          </Text>
        </Button>
        <ObjectInspector name={'authDetails'} data={authInfoObjectInspection} />
        <Divider />
        <ReduxDebugContainer />
        <Divider />
        <GraphQLDebug />
        <Divider />
      </div>
    </div>
  );
};

const styles = {
  base: css({
    backgroundColor: 'white',
    width: '33%',
    minWidth: 450,
    display: 'flex',
    justifyContent: 'stretch',
    alignItems: 'stretch',
  }),
  inner: css({
    overflowX: 'hidden',
    overflowY: 'scroll',
    WebkitOverflowScrolling: 'touch',
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    boxSizing: 'border-box',
    padding: 10,
    paddingLeft: 15,
    paddingBottom: 80,
  }),
  a: css({
    textDecoration: 'none',
  }),
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 30,
    border: `solid 1px ${COLORS.BLUE}`,
    borderRadius: 3,
  },
  buttonText: css({
    color: COLORS.BLUE,
  }),
};
