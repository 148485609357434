import { FormGoalStatus } from '@seeeverything/ui.forms/src/redux/form-edit-goal/types.ts';
import { IFormDropdownProvider } from '@seeeverything/ui.forms/src/types/types.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { str } from '@seeeverything/ui.util/src/str/index.ts';

export const getStatusDropdownProvider = (
  status: FormGoalStatus,
  canRevert: boolean,
  canClose: boolean,
  canCancel: boolean,
  hasActiveChildActions: boolean,
  actionLabel: string,
): IFormDropdownProvider => ({
  name: 'status',
  load: async () => ({
    hasNextPage: false,
    pageNumber: 1,
    items: [
      status === 'Open' && {
        id: 'Open',
        icon: () => <Icons.info fill={COLORS.BLUE} />,
        content: {
          text: 'Open (Current)',
          description: 'Continue working on this goal.',
        },
      },
      status === 'Overdue' && {
        id: 'Overdue',
        icon: () => <Icons.accessTime fill={COLORS.BLUE} />,
        content: {
          text: 'Open (Current)',
          description: 'Continue working on this goal.',
        },
      },
      canRevert && {
        id: 'Open',
        icon: () => <Icons.info fill={COLORS.BLUE} />,
        content: {
          text: 'Open',
          description: 'Re-open this goal.',
        },
      },
      status === 'Completed' && {
        id: 'Completed',
        icon: () => <Icons.verifiedUser fill={'#2FAA47'} />,
        content: {
          text: 'Completed (Current)',
          description: 'This goal is complete/closed.',
        },
      },
      canClose && {
        id: 'Completed',
        icon: () => <Icons.verifiedUser fill={'#2FAA47'} />,
        content: {
          text: 'Completed',
          description: 'Complete/close this goal.',
        },
      },
      status === 'Cancelled' && {
        id: 'Cancelled',
        icon: () => <Icons.cancel fill={'#F33A30'} />,
        content: {
          text: 'Canceled (Current)',
          description: 'This goal is canceled.',
        },
      },
      canCancel && {
        id: 'Cancelled',
        icon: () => <Icons.cancel fill={'#F33A30'} />,
        content: {
          text: 'Canceled',
          description: 'Cancel/remove this goal.',
        },
      },
      hasActiveChildActions &&
        status !== 'Completed' && {
          id: 'Completed',
          isSelectable: false,
          isEnabled: false,
          icon: () => <Icons.factCheck fill={'#b2b2b2'} />,
          content: {
            text: 'Cannot be Closed',
            description: `Close all related ${str
              .plural(actionLabel)
              .toLowerCase()} first.`,
          },
        },
    ].filter(Boolean),
  }),
});
