import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SimpleEntity } from '@se/data/types.ts';
import { OrderBy } from '@seeeverything/ui.util/src/types.ts';
import { GraphQLFormattedError } from 'graphql';
import { IDesignerDraft } from '../model/types.ts';

type DesignerTemplate = {
  id: string;
  name: string;
  category: string;
  status: 'Draft' | 'Published';
  publishedTemplateId?: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
};

export type FormsDesignerReduxViewTemplatesState = {
  templates: DesignerTemplate[];
  isLoading?: boolean;
  popup?: string;
  orderBy: OrderBy;
};

const DEFAULT_STATE: FormsDesignerReduxViewTemplatesState = {
  templates: [],
  orderBy: {
    fieldName: 'updatedAt',
    direction: 'Descending',
  },
};

const slice = createSlice({
  name: 'libs/formsDesigner/templates/view',
  initialState: DEFAULT_STATE,
  reducers: {
    deleteTemplate(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        id: string;
        deletePublishedTemplate: boolean;
      }>,
    ) {
      state.popup = undefined;
    },
    deleteTemplateFailed(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        id: string;
        name: string;
        graphQLErrors?: GraphQLFormattedError[];
      }>,
    ) {
      return state;
    },
    deletedTemplate(state, action: PayloadAction<SimpleEntity>) {
      state.templates = state.templates.filter(
        (template) => template.id !== action.payload.id,
      );
    },
    load(state) {
      state.isLoading = true;
      state.popup = undefined;
    },
    loadFailed(state) {
      state.isLoading = false;
      state.templates = [];
    },
    loaded(state, action: PayloadAction<{ templates: IDesignerDraft[] }>) {
      state.isLoading = false;
      state.popup = undefined;
      state.templates = action.payload.templates;
    },
    hideTemplateRowPopup(state) {
      state.popup = undefined;
    },
    openDraft(
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<{
        id: string;
        name: string;
      }>,
    ) {
      state.popup = undefined;
    },
    showTemplateRowPopup(state, action: PayloadAction<{ templateId: string }>) {
      state.popup = action.payload.templateId;
    },
    sortTemplates(state, action: PayloadAction<{ orderBy: OrderBy }>) {
      state.orderBy = action.payload.orderBy;
    },
  },
});

export const {
  deletedTemplate,
  deleteTemplate,
  deleteTemplateFailed,
  hideTemplateRowPopup,
  load,
  loaded,
  loadFailed,
  openDraft,
  showTemplateRowPopup,
  sortTemplates,
} = slice.actions;
export const reducer = slice.reducer;
export type State = FormsDesignerReduxViewTemplatesState;
