/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import { TreeView as MaterialTreeView } from '@material-ui/lab';
import { Icons } from '../Icon/Icons.tsx';
import { TreeSkeleton, ITreeItem, TreeItem } from './components/index.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';

export interface ITreeViewProps {
  // Material UI props.
  defaultCollapseIcon?: JSX.Element;
  defaultEndIcon?: JSX.Element;
  defaultExpandIcon?: JSX.Element;
  defaultParentIcon?: JSX.Element;
  defaultExpanded?: string[]; // Node IDs.
  defaultSelected?: string[]; // Array if multiselect.
  expanded?: string[];
  selected?: string[];
  shouldDisableSelection?: boolean;
  isMultiSelect?: boolean;

  items: ITreeItem[];
  isLoading?: boolean;
  onItemSelect?: (selectedIds: string | string[]) => void;
  onItemToggle?: (expandedIds: string[]) => void;
}

/**
 * Renders a hierarchy of selectable items in a tree/directory structure.
 *
 * @see https://material-ui.com/components/tree-view/
 * @see https://material-ui.com/api/tree-view/
 */
export const TreeView: React.FC<ITreeViewProps> = (props) => {
  const {
    items,
    defaultCollapseIcon = <Icons.keyboardArrowDown fill={color.format(-0.6)} />,
    defaultExpandIcon = <Icons.chevronRight fill={color.format(-0.6)} />,
    defaultEndIcon,
    defaultParentIcon,
    shouldDisableSelection = false,
    defaultExpanded,
    defaultSelected = [],
    expanded,
    selected,
    isMultiSelect = false,
    onItemSelect,
    onItemToggle,
    isLoading,
  } = props;

  const handleNodeSelect = (event: any, nodeIds: string | string[]) =>
    onItemSelect?.(nodeIds);
  const handleNodeToggle = (event: any, nodeIds: string[]) =>
    onItemToggle?.(nodeIds);

  const elTreeItems = items.map((item) => (
    <TreeItem key={`tree-item-${item.id}`} item={item} />
  ));

  const elSkeleton = isLoading && <TreeSkeleton />;
  const elTreeView = (
    <MaterialTreeView
      defaultCollapseIcon={defaultCollapseIcon}
      defaultExpandIcon={defaultExpandIcon}
      defaultEndIcon={defaultEndIcon}
      defaultParentIcon={defaultParentIcon}
      disableSelection={shouldDisableSelection}
      defaultExpanded={defaultExpanded}
      defaultSelected={defaultSelected}
      expanded={expanded}
      selected={selected}
      multiSelect={isMultiSelect as true} // For typescript.
      onNodeSelect={handleNodeSelect}
      onNodeToggle={handleNodeToggle}
    >
      {elTreeItems}
    </MaterialTreeView>
  );

  const elContent = elSkeleton || elTreeView;

  return <div css={styles.base}>{elContent}</div>;
};

const styles = {
  base: css({
    flex: '1 1 auto',
    minHeight: 200,
    maxHeight: 200,
    marginTop: 10,
    marginBottom: 10,
    overflow: 'auto',
  }),
};
