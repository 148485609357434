/** @jsxImportSource @emotion/react */
import React from 'react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { css } from '@emotion/react';

const SpeechBlockBullet = () => (
  <div css={styles.base}>
    <div css={styles.inner}>
      <Icons.personOutline size={21} fill={color.format(-0.3)} />
    </div>
  </div>
);

const styles = {
  base: css({
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: -39,
  }),
  inner: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 29,
    height: 29,
    border: `solid 1px ${color.format(-0.2)}`,
    borderRadius: 29,
    backgroundColor: 'white',
  }),
};

export default React.memo(SpeechBlockBullet);
