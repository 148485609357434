/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Switch } from '@seeeverything/ui.primitives/src/components/Switch/Switch.tsx';
import { useCallback, useState } from 'react';
import { SwitchHiddenFieldsChangeEventHandler } from '../types.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';

export interface ISwitchHiddenFields {
  id: string;
  value?: boolean;
  isEnabled?: boolean;
  onChange?: SwitchHiddenFieldsChangeEventHandler;
}

export const SwitchHiddenFields: React.FC<ISwitchHiddenFields> = ({
  id,
  isEnabled,
  onChange,
}) => {
  const [value, setValue] = useState(false);

  const handleChange = useCallback(() => {
    const to = !value;
    setValue(to);
    onChange?.({ id, to: to ? 'true' : 'false' });
  }, [id, onChange, value]);

  return (
    <div css={styles.base}>
      <Icons.visibilityOff fill={'#b2b2b2'} />
      <div css={styles.switch} onClick={handleChange}>
        <Text color={color.format(-0.8)} size={15} cursor={'inherit'}>
          {'Show questions that are hidden by default:'}
        </Text>
        <Switch
          isChecked={value}
          isEnabled={isEnabled}
          label={value ? 'Yes' : 'No'}
          labelStyle={{ marginRight: 0 }}
        />
      </div>
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
  }),
  switch: css({
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '4px 8px',
    margin: '-4px -8px',
    ':hover': {
      backgroundColor: color.format(-0.06),
    },
  }),
};
