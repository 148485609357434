/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { TextLink } from '@seeeverything/ui.primitives/src/components/TextLink/TextLink.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { propsAreEqualFastCompare } from '@seeeverything/ui.util/src/react/memoFastCompare.ts';
import { Fragment, memo, useMemo } from 'react';
import { headerPathClicked } from '../../../redux/actions.ts';
import {
  useDashboardsDispatch,
  useDashboardsSelector,
} from '../../../redux/store.ts';
import { DashboardHeaderMetrics } from './DashboardHeaderMetrics.tsx';

const ALLOWED_COLORS = ['#1A3A9E', '#1B5717', '#261741', '#8F3A69'];

type RelatedTeam = {
  id: string;
  path: string;
};

export interface IDashboardHeaderContentProps {
  height: number;
  relatedTeams?: RelatedTeam[];
}

const View: React.FC<IDashboardHeaderContentProps> = ({
  height,
  relatedTeams,
}) => {
  const dispatch = useDashboardsDispatch();

  const header = useDashboardsSelector((state) => state.dashboardsV2.HEADER);

  const logo = useDashboardsSelector(
    (state) => state.tenantState.tenant.configuration.logo,
  );

  const Icon = Icons[header.icon];

  const { team, description, name, personRole, kind } =
    header.dataState.data.data;

  const teamPathParts = (team?.path as string)?.split('/');
  const hasTeams = teamPathParts?.length > 1;

  const linkableTeams = relatedTeamsFromTeamPath(
    team,
    relatedTeams,
    kind !== 'team',
  );

  const isCollapsed = height <= 75;

  const entityColor = useMemo(() => {
    const nameHash = (name as string)
      .split('')
      .reduce((acc, char) => acc + char.charCodeAt(0), 0);

    return color.create(ALLOWED_COLORS[nameHash % ALLOWED_COLORS.length]).hex();
  }, [name]);

  const elTeams = hasTeams ? (
    <>
      {teamPathParts.map((pathPart, index) => {
        if (index === teamPathParts.length - 1 && kind === 'team') return;

        const key = `${pathPart}-${index}`;
        const isLast = index === teamPathParts.length - 1;

        const matchedTeam = linkableTeams.find((t) => t.name === pathPart);
        if (!matchedTeam)
          return (
            <Text
              key={key}
              ellipsis={true}
              size={14}
              color={color.format(-0.5)}
            >
              {[pathPart, !isLast && '/'].filter(Boolean).join(' ')}
            </Text>
          );

        return (
          <Fragment key={key}>
            <Button
              onClick={() =>
                dispatch(headerPathClicked(matchedTeam.id, matchedTeam.name))
              }
              tooltip={`Click to view ${matchedTeam.name}`}
              style={styles.viewTeamButton}
            >
              <TextLink size={14} ellipsis={true}>
                {matchedTeam.name}
              </TextLink>
            </Button>
            {!isLast && (
              <Text size={14} color={color.format(-0.4)} ellipsis={true}>
                {'/'}
              </Text>
            )}
          </Fragment>
        );
      })}
      {personRole && (
        <Text
          size={12}
          ellipsis={true}
          uppercase={true}
          color={'white'}
          style={styles.roleBadge}
        >
          {personRole}
        </Text>
      )}
    </>
  ) : undefined;

  const elNameGroup = isCollapsed ? (
    <Text size={24} color={color.format(-0.7)} weight={300} ellipsis={true}>
      {name}
    </Text>
  ) : (
    <div css={styles.title(hasTeams)}>
      {hasTeams && <div css={styles.teams}>{elTeams}</div>}
      <Text
        size={24}
        color={color.format(-0.7)}
        weight={300}
        ellipsis={true}
        style={styles.name}
      >
        {name}
      </Text>
      {description && (
        <Text
          size={13}
          color={color.format(-0.6)}
          ellipsis={true}
          style={styles.description}
        >
          {description}
        </Text>
      )}
    </div>
  );

  const elIconTitle = (
    <div css={styles.iconTitle(height, isCollapsed)}>
      <Icon size={height * 1.5} style={styles.icon(entityColor)} />
      {elNameGroup}
    </div>
  );

  const elLogoMetrics = (
    <div css={styles.logoMetrics(height)}>
      {!isCollapsed && <img css={styles.logo(logo.opacity)} src={logo.logo} />}
      <DashboardHeaderMetrics isCollapsed={isCollapsed} />
    </div>
  );

  return (
    <div css={styles.base} data-test={'shell-content-headerContentTitle'}>
      {elIconTitle}
      {elLogoMetrics}
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
  }),
  iconTitle: (height: number, isCollapsed: boolean) =>
    css({
      display: 'flex',
      alignItems: 'center',
      transform: `translate(-${height / 2.5}px)`,
      gap: 10,
      overflow: isCollapsed ? 'hidden' : 'visible',
    }),
  icon: (col: string) => ({
    fill: color.create(col).alpha(0.4).hex(),
  }),
  logo: (opacity: number) =>
    css({
      width: 'auto',
      height: 60,
      padding: '10px 5px',
      alignSelf: 'flex-end',
      opacity,
    }),
  logoMetrics: (height: number) =>
    css({
      display: 'flex',
      flexDirection: 'column',
      maxHeight: height,
      height: '100%',
      alignContent: 'space-between',
      justifyContent: 'space-between',
      flex: '1 0 auto',
    }),
  teams: css({
    position: 'absolute',
    inset: '-33px auto auto auto',
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-start',
    alignItems: 'center',
    gap: 3,
  }),
  name: css({
    position: 'absolute',
    inset: '-13px auto auto -1px',
  }),
  description: css({
    position: 'absolute',
    inset: '18px auto auto auto',
  }),
  metrics: css({
    padding: '10px 5px',
    alignSelf: 'flex-end',
  }),
  title: (hasTeams: boolean) =>
    css({
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
      position: 'relative',
      flex: '1 1 auto',
      top: hasTeams ? 'auto' : -9,
    }),
  roleBadge: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.SEE_BRANDING.BLUE,
    padding: '2px 6px',
    marginLeft: 3,
    borderRadius: 3,
  }),
  viewTeamButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 5,
    margin: -3,
    padding: 3,
    marginRight: 'auto',
  } as const,
};

const relatedTeamsFromTeamPath = (
  team?: RelatedTeam,
  relatedTeams?: RelatedTeam[],
  includeSelf = true,
) => {
  if (!team) return [];
  if (!relatedTeams) return [];
  if (!relatedTeams.length) return [];

  const teamPaths = team.path.split('/');
  return Array.from({ length: teamPaths.length })
    .map((_, index) => {
      const teamPath = teamPaths.slice(0, index + 1).join('/');
      if (teamPath === team.path && includeSelf) return team;

      return relatedTeams.find((t) => t.path === teamPath);
    })
    .filter(Boolean)
    .map((relatedTeam) => ({
      id: relatedTeam.id,
      path: relatedTeam.path,
      name: relatedTeam.path.split('/').pop(),
    }));
};

export const DashboardHeaderContent = memo(View, propsAreEqualFastCompare);
