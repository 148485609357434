/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { ScrollContainer } from '@seeeverything/ui.primitives/src/components/Scroll/ScrollContainer.tsx';
import { useCallback, useMemo, useRef, useState } from 'react';
import { SheetIndexItemContainer } from './components/SheetIndexItem/index.ts';
import { ISheetIndexItem } from './types.ts';

export interface ISheetIndexProps {
  isExpanded: boolean;
  onCollapseExpandClicked: (toExpanded: boolean) => void;
  items?: ISheetIndexItem[];
  scrollOffset: number;
  scrollSmooth: boolean;
}

/**
 * A flexible icon/label list providing an index of a sheet.
 */
export const SheetIndex: React.FC<ISheetIndexProps> = ({
  isExpanded,
  items,
  onCollapseExpandClicked,
  scrollOffset,
  scrollSmooth,
}) => {
  const [isShowingCollapseExpand, setIsShowingCollapseExpand] = useState(false);

  const trackTimer = useRef<NodeJS.Timeout>(null);

  const toggleExpanded = useCallback(() => {
    onCollapseExpandClicked(!isExpanded);
  }, [isExpanded, onCollapseExpandClicked]);

  const showCollapseExpand = useCallback(() => {
    if (trackTimer.current) clearTimeout(trackTimer.current);
    setIsShowingCollapseExpand(true);
  }, []);

  const hideCollapseExpand = useCallback(() => {
    trackTimer.current = setTimeout(() => {
      setIsShowingCollapseExpand(false);
    }, 1000);
  }, []);

  const hasItems = Boolean(items.length);

  const elIndexItems = useMemo(
    () =>
      items.map((item, index) => (
        <SheetIndexItemContainer
          error={item.error}
          icon={item.icon}
          iconColor={item.iconColor}
          id={item.id}
          key={`${item.type}-${index}-${item.label}`}
          label={item.label}
          labelColor={item.labelColor}
          status={item.status}
          scrollOffset={scrollOffset}
          scrollSmooth={scrollSmooth}
          type={item.type}
        />
      )),
    [items, scrollOffset, scrollSmooth],
  );

  const computedStyles = useMemo(
    () => ({
      base: css({
        flex: '1 1 auto',
      }),
      collapsedIndex: css({
        width: 30,
      }),
      showHideIndex: css({
        position: 'absolute',
        inset: '30px -15px auto auto',
        zIndex: 10,
        opacity: !isExpanded || isShowingCollapseExpand ? 1 : 0,
        transition: 'opacity 0.33s',
        backgroundColor: '#fafafa',
        border: 'solid 1px #e0e0e0',
        borderRadius: '100%',
      }),
      indexOuter: css({
        minWidth: hasItems ? 270 : 0,
        width: '100%',
        height: '100%',
      }),
      indexItemsOuter: css({
        margin: 20,
      }),
    }),
    [hasItems, isExpanded, isShowingCollapseExpand],
  );

  const Icon = isExpanded ? Icons.chevronLeft : Icons.chevronRight;

  const elShowHideIndex = hasItems && onCollapseExpandClicked && (
    <div css={computedStyles.showHideIndex}>
      <Button
        style={{ height: 28, width: 28, borderRadius: '100%' }}
        onClick={toggleExpanded}
      >
        <Icon fill={'#8f8f8f'} size={28} />
      </Button>
    </div>
  );

  const elExpandedIndex = isExpanded && (
    <div css={computedStyles.indexOuter}>
      <ScrollContainer>
        <div css={computedStyles.indexItemsOuter}>{elIndexItems}</div>
      </ScrollContainer>
    </div>
  );

  const elCollapsedIndex = !isExpanded && (
    <div css={computedStyles.collapsedIndex} />
  );

  return (
    <div
      css={computedStyles.base}
      onMouseEnter={showCollapseExpand}
      onMouseLeave={hideCollapseExpand}
    >
      {elShowHideIndex}
      {elExpandedIndex}
      {elCollapsedIndex}
    </div>
  );
};
