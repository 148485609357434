/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { LabelButton } from '@seeeverything/ui.primitives/src/components/LabelButton/LabelButton.tsx';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { useCallback } from 'react';
import {
  DistributionListRuleType,
  DistributionListType,
} from '../../../redux/distributionList/types.ts';
import { editDistributionListSlice } from '../../../redux/editDistributionList/index.ts';
import { DistributionListRule } from '../../../redux/editDistributionList/types.ts';
import { useFormsDispatch } from '../../../redux/store.ts';
import { RuleContainer } from './RuleContainer.tsx';

const RULE_SELECTIONS: ISelectionListItem[] = [
  {
    id: 'Teams Below',
    content: { text: 'Teams Below' },
    value: 'Teams Below',
  },
  {
    id: 'Include Teams',
    content: { text: 'Include Teams' },
    value: 'Include Teams',
  },
  {
    id: 'Exclude Teams',
    content: { text: 'Exclude Teams' },
    value: 'Exclude Teams',
  },
  {
    id: 'Exclude Position Titles',
    content: { text: 'Exclude Position Titles' },
    value: 'Exclude Position Titles',
  },
  {
    id: 'Include Position Titles',
    content: { text: 'Include Position Titles' },
    value: 'Include Position Titles',
  },
];

export type IDistributionListRulesFieldContainerProps = {
  label: string;
  rules: DistributionListRule[];
  type: DistributionListType;
};

export const DistributionListRulesFieldContainer: React.FC<
  IDistributionListRulesFieldContainerProps
> = ({ label, rules, type }) => {
  const dispatch = useFormsDispatch();

  const handleRuleAdd = useCallback(() => {
    dispatch(editDistributionListSlice.addRuleRow());
  }, [dispatch]);

  const selectableRuleTypes = filterSelectableRuleSelections(
    RULE_SELECTIONS,
    rules,
    type,
  );

  return (
    <div css={styles.base}>
      <Text style={styles.label}>{label}</Text>
      {Boolean(rules.length) && (
        <div css={styles.rule}>
          {rules.map((rule) => (
            <RuleContainer
              key={`rule-${rule.id}`}
              ruleId={rule.id}
              listType={type}
              ruleDropdownSelections={selectableRuleTypes}
            />
          ))}
        </div>
      )}
      <div>
        <LabelButton
          bold={true}
          dataTest={'distribution-list-add-rule'}
          isEnabled={Boolean(selectableRuleTypes?.length)}
          label={'Add'}
          leftIcon={Icons.plus}
          onClick={handleRuleAdd}
        />
      </div>
    </div>
  );
};

const filterSelectableRuleSelections = (
  ruleDropdownSelections: ISelectionListItem[],
  rules: DistributionListRule[],
  type: DistributionListType,
) => {
  const typeRuleSelections =
    type === 'Team'
      ? [
          DistributionListRuleType.ExcludeTeams,
          DistributionListRuleType.IncludeTeams,
          DistributionListRuleType.IncludeTeamsBelow,
        ]
      : [
          DistributionListRuleType.IncludePositionTitles,
          DistributionListRuleType.ExcludePositionTitles,
          DistributionListRuleType.ExcludeTeams,
          DistributionListRuleType.IncludeTeams,
        ];

  return ruleDropdownSelections
    .filter((selection) =>
      typeRuleSelections.some((value) => value === selection.id),
    )
    .filter(
      (selection) =>
        !rules?.some(
          (rule) =>
            rule.type === selection.id &&
            selection.value !== DistributionListRuleType.IncludeTeamsBelow,
        ),
    );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    gap: 10,
  }),
  label: css({
    color: color.format(-0.45),
    fontSize: 12,
    fontWeight: 400,
  }),
  rule: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  }),
};
