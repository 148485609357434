/** @jsxImportSource @emotion/react */
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import {
  LabelButton,
  LabelButtonDropdownClickEvent,
} from '@seeeverything/ui.primitives/src/components/LabelButton/index.ts';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { useCallback, useState } from 'react';

export interface IRuleRowButtonProps {
  dropdownItems: ISelectionListItem[];
  isEnabled: boolean;
  label: string;
  buttonWidth?: string | number;
  dataTestId?: string;
  dropdownWidth?: number;
  error?: string;
  onSelect: (e: LabelButtonDropdownClickEvent) => void;
}

export const RuleRowButton: React.FC<IRuleRowButtonProps> = ({
  dropdownItems,
  error,
  isEnabled,
  label,
  onSelect,
  buttonWidth = 134,
  dataTestId,
  dropdownWidth = 230,
}) => {
  const [isShowingDropdown, setShowDropdown] = useState(false);

  const handleDropdownClick = useCallback(
    (e: LabelButtonDropdownClickEvent) => {
      setShowDropdown(false);
      onSelect(e);
    },
    [onSelect],
  );

  const handleShowDropdown = useCallback(() => setShowDropdown(true), []);
  const handleHideDropdown = useCallback(() => setShowDropdown(false), []);

  const dropdown = isShowingDropdown
    ? { width: dropdownWidth, items: dropdownItems }
    : undefined;

  return (
    <LabelButton
      bold={!error}
      capitalized={false}
      dataTest={dataTestId}
      dropdown={dropdown}
      ellipsis={true}
      isEnabled={isEnabled}
      label={label}
      onClick={handleShowDropdown}
      onDropdownClick={handleDropdownClick}
      onDropdownHide={handleHideDropdown}
      rightIcon={isEnabled ? Icons.arrowDropDown : undefined}
      theme={error ? 'ERROR' : undefined}
      width={buttonWidth}
      emptyDropdownMessage={'Nothing to display.'}
    />
  );
};
