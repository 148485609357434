import { FormLineByIdExtended } from '../../components/FormDesignerContent/types.ts';
import {
  FormDesignerReduxDesignerState,
  FormsDesignerDesignerChangeComponentProperty,
} from './types.ts';

export function changeComponentPropertyReducer(
  state: FormDesignerReduxDesignerState,
  action: FormsDesignerDesignerChangeComponentProperty,
) {
  if (!state.draft || !state.draft.designerLines) {
    return state;
  }

  return {
    ...state,
    draft: {
      ...state.draft,
      designerLines: withUpdatedProperty(
        state.draft.designerLines,
        action.payload.id,
        action.payload.to,
      ),
    },
  };
}

const STANDARD_HEADER_MAP: { [key: string]: { key: string; update: string } } =
  {
    'header-section-title': {
      key: 'sectionTitle',
      update: 'RAW',
    },
    reportingDate: {
      key: 'reportingDateLabel',
      update: 'RAW',
    },
    dueDate: {
      key: 'dueDateLabel',
      update: 'RAW',
    },
    'assignedTo.label': {
      key: 'reviewer',
      update: 'label',
    },
    'subject.label': {
      key: 'subject',
      update: 'label',
    },
    'subject.dropdown': {
      key: 'subject',
      update: 'kind',
    },
  };

const SIGNOFF_MAP: { [key: string]: { key: string; update: string } } = {
  'signoff-title': {
    key: 'title',
    update: 'RAW',
  },
  'signoff-reviewer.label': {
    key: 'reviewerLabel',
    update: 'RAW',
  },
  'signoff-subject.label': {
    key: 'subject',
    update: 'label',
  },
  'signoff-subject.checkbox': {
    key: 'subject',
    update: 'isRequired',
  },
};

const ACTION_PLAN_MAP: { [key: string]: { key: string; update: string } } = {
  'action-plan-title': {
    key: 'title',
    update: 'RAW',
  },
  'action-plan-enabled': {
    key: 'isEnabled',
    update: 'RAW',
  },
};

const FILE_UPLOAD_MAP: { [key: string]: { key: string; update: string } } = {
  'file-upload-title': {
    key: 'title',
    update: 'RAW',
  },
  'file-upload-enabled': {
    key: 'isEnabled',
    update: 'RAW',
  },
};

const ATTENDANCE_MAP: { [key: string]: { key: string; update: string } } = {
  'attendance-title': {
    key: 'title',
    update: 'RAW',
  },
  'attendance-enabled': {
    key: 'isEnabled',
    update: 'RAW',
  },
  'attendance-mandatoryFollowUp': {
    key: 'mandatoryFollowUp',
    update: 'RAW',
  },
};

const DIGITAL_CONTENT_MAP: { [key: string]: { key: string; update: string } } =
  {
    'digital-content-comments-enabled': {
      key: 'commentsEnabled',
      update: 'RAW',
    },
    'digital-content-comments-guidance': {
      key: 'commentsGuidance',
      update: 'RAW',
    },
    'digital-content-description': {
      key: 'description',
      update: 'RAW',
    },
    'digital-content-enabled': {
      key: 'isEnabled',
      update: 'RAW',
    },
    'digital-content-title': {
      key: 'title',
      update: 'RAW',
    },
  };

const withUpdatedProperty = (
  designerLines: FormLineByIdExtended,
  id: string | number,
  to: string,
): FormLineByIdExtended => {
  // Special values - used in the designer standard heading.
  const headerValue = STANDARD_HEADER_MAP[id];
  if (headerValue) {
    const existingValue = designerLines.headers[headerValue.key];

    const value =
      headerValue.update === 'RAW'
        ? { [headerValue.key]: to }
        : { [headerValue.key]: { ...existingValue, [headerValue.update]: to } };

    const remainingFieldsOnError = (
      designerLines.headers?.validationError?.fields as string[]
    )?.filter((field) => field !== headerValue?.key);

    const validationError = {
      hasValidationError: remainingFieldsOnError?.length > 0,
      fields: remainingFieldsOnError,
    };

    return {
      ...designerLines,
      headers: {
        ...designerLines.headers,
        ...value,
        validationError,
      },
    };
  }

  const signoffValue = SIGNOFF_MAP[id];
  if (signoffValue) {
    const existingValue = designerLines.signoff[signoffValue.key];

    const value =
      signoffValue.update === 'RAW'
        ? { [signoffValue.key]: to }
        : {
            [signoffValue.key]: { ...existingValue, [signoffValue.update]: to },
          };

    return {
      ...designerLines,
      signoff: {
        ...designerLines.signoff,
        ...value,
        validationError: {
          hasValidationError: false,
          fields: [],
        },
      },
    };
  }

  const actionPlanValue = ACTION_PLAN_MAP[id];
  if (actionPlanValue) {
    const existingValue = designerLines.actionPlan[actionPlanValue.key];

    const value =
      actionPlanValue.update === 'RAW'
        ? { [actionPlanValue.key]: to }
        : {
            [actionPlanValue.key]: {
              ...existingValue,
              [actionPlanValue.update]: to,
            },
          };

    return {
      ...designerLines,
      actionPlan: {
        ...designerLines.actionPlan,
        ...value,
        validationError: {
          hasValidationError: false,
          fields: [],
        },
      },
    };
  }
  const attendanceValue = ATTENDANCE_MAP[id];
  if (attendanceValue) {
    const existingValue = designerLines.attendance[attendanceValue.key]?.value;

    const value =
      attendanceValue.update === 'RAW'
        ? { [attendanceValue.key]: to }
        : {
            [attendanceValue.key]: {
              ...existingValue,
              [attendanceValue.update]: to,
            },
          };

    return {
      ...designerLines,
      attendance: {
        ...designerLines.attendance,
        ...value,
        validationError: {
          hasValidationError: false,
          fields: [],
        },
      },
    };
  }
  const digitalContentValue = DIGITAL_CONTENT_MAP[id];
  if (digitalContentValue) {
    const existingValue =
      designerLines.digitalContent[digitalContentValue.key]?.value;

    const value =
      digitalContentValue.update === 'RAW'
        ? { [digitalContentValue.key]: to }
        : {
            [digitalContentValue.key]: {
              ...existingValue,
              [digitalContentValue.update]: to,
            },
          };

    const remainingFieldsOnError = (
      designerLines.digitalContent.validationError?.fields as string[]
    )?.filter((field) => field !== digitalContentValue.key);

    const validationError = {
      hasValidationError: remainingFieldsOnError?.length > 0,
      fields: remainingFieldsOnError,
    };

    return {
      ...designerLines,
      digitalContent: {
        ...designerLines.digitalContent,
        ...value,
        validationError,
      },
    };
  }

  const fileUploadValue = FILE_UPLOAD_MAP[id];
  if (fileUploadValue) {
    const existingValue = designerLines.fileUpload[fileUploadValue.key].value;

    const value =
      fileUploadValue.update === 'RAW'
        ? { [fileUploadValue.key]: to }
        : {
            [fileUploadValue.key]: {
              ...existingValue,
              [fileUploadValue.update]: to,
            },
          };

    return {
      ...designerLines,
      fileUpload: {
        ...designerLines.fileUpload,
        ...value,
        validationError: {
          hasValidationError: false,
          fields: [],
        },
      },
    };
  }

  // All other values.
  const key = Object.keys(designerLines).find(
    (lineKey) => designerLines[lineKey].id === id,
  );

  if (!key) {
    return designerLines;
  }

  if (designerLines[key].type === 'options') {
    const remainingFieldsOnError = (
      designerLines[key].validationError?.fields as string[]
    )?.filter((field) => field !== 'title');

    const validationError = {
      hasValidationError: remainingFieldsOnError?.length > 0,
      fields: remainingFieldsOnError,
    };

    return {
      ...designerLines,
      [key]: {
        ...designerLines[key],
        optionItems: {
          ...designerLines[key].optionItems,
          title: to,
        },
        validationError,
      },
    };
  }

  return {
    ...designerLines,
    [key]: {
      ...designerLines[key],
      title: to,
      validationError: {
        hasValidationError: false,
        fields: [],
      },
    },
  };
};
