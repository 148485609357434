import { IDashboardTrendMapping } from '../types.ts';

export function parseTrendMap(
  trendMap?: any,
): IDashboardTrendMapping[] | undefined {
  if (!trendMap?.length) {
    return undefined;
  }

  return trendMap.map((setting: any) => ({
    icon: setting.icon,
    color: setting.color,
    label: setting.label,
    size: setting.size,
    minValueInclusive: setting['min-number-inclusive'],
    minValue: setting['min-number'],
    maxValueInclusive: setting['max-number-inclusive'],
    maxValue: setting['max-number'],
  }));
}
