import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';

export const DROPDOWN_TYPES: ISelectionListItem[] = [
  {
    id: '1',
    value: 'Teams',
    content: { text: 'Teams', description: 'Teams, Locations, Sites, etc.' },
    icon: Icons.store,
  },
  {
    id: '2',
    value: 'People',
    content: { text: 'People', description: 'Staff/Team members.' },
    icon: Icons.person,
  },
];
