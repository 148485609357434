/** @jsxImportSource @emotion/react */
import { useCallback, useState } from 'react';
import { DataGridColumnFilter } from '../DataGridColumnFilter/DataGridColumnFilter.tsx';
import { GridClickEvent } from '../Grid/types.ts';
import { Icons } from '../Icon/Icons.tsx';
import { Popup } from '../Popup/Popup.tsx';
import { IDataGridProps } from './DataGrid.tsx';
import { DataGridFilter } from './types.ts';

export interface IDataGridStatusToggleSortableBehaviorProps {
  onColumnStatusToggled?: (
    columnId: string,
    toggleId: string,
    to: boolean,
  ) => void;
  onColumnSort?: (columnId: string, direction: 'ASC' | 'DESC') => void;
  filters: DataGridFilter[];
}

export const DataGridStatusToggleSortableBehavior = (
  DataGrid: React.FC<IDataGridProps>,
): React.FC<IDataGridStatusToggleSortableBehaviorProps & IDataGridProps> =>
  function StatusToggleDataGrid({
    columns,
    filters = [],
    onColumnStatusToggled,
    onColumnSort,
    onHeaderClick,
    ...rest
  }) {
    const [showPopupForColumnId, setShowPopupForColumnId] = useState<string>();

    const handleHeaderClick = useCallback(
      (e: GridClickEvent) => {
        onHeaderClick?.(e);

        if (!e.isLastRow) return;

        const column = columns[e.columnIndex];
        const isFilterable = column.isFilterable ?? false;
        const isSortable = column.isSortable ?? true;

        if (isFilterable) {
          setShowPopupForColumnId(e.columnId);
          return;
        }

        if (!isSortable) return;

        const currentSort = filters.find(
          (filter) => filter.columnId === column.id,
        )?.sort;
        const nextSort = currentSort === 'DESC' ? 'ASC' : 'DESC';

        onColumnSort?.(column.id, nextSort);
      },
      [columns, filters, onColumnSort, onHeaderClick],
    );

    const handleRenderHeaderPopup = useCallback(
      (columnId: string) => {
        if (!showPopupForColumnId) return;
        if (showPopupForColumnId !== columnId) return;

        const column = columns.find((c) => c.id === columnId);
        if (!column) return;

        const filter = filters.find((f) => f.columnId === columnId);

        return (
          <Popup
            onHide={() => setShowPopupForColumnId(undefined)}
            hideOnClick={false}
          >
            <DataGridColumnFilter
              isSortAllowed={column.isSortable !== false}
              isToggleAllowed={column.isFilterable !== false}
              onSortChanged={(to) => onColumnSort?.(columnId, to)}
              onToggleChanged={(toggleId, to) =>
                onColumnStatusToggled?.(columnId, toggleId, to)
              }
              sort={filter?.sort}
              title={column.label}
              toggles={filter?.toggles}
            />
          </Popup>
        );
      },
      [
        columns,
        filters,
        onColumnStatusToggled,
        onColumnSort,
        showPopupForColumnId,
      ],
    );

    const getHeaderIcon = useCallback(
      (columnId: string) => {
        const filter = filters.find((f) => f.columnId === columnId);
        if (!filter) return;

        const isFiltered = filter.toggles?.some((t) => !t.isToggled);
        const sort = filter.sort;

        if (!isFiltered && !sort) return;
        if (isFiltered && sort) return Icons.viewHeadline;
        if (isFiltered) return Icons.filter;
        return sort === 'ASC' ? Icons.sortAscending : Icons.sortDescending;
      },
      [filters],
    );

    return (
      <DataGrid
        {...rest}
        columns={columns}
        onHeaderClick={handleHeaderClick}
        getHeaderIcon={getHeaderIcon}
        renderHeaderPopup={handleRenderHeaderPopup}
      />
    );
  };
