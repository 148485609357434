/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { authApi } from '@seeeverything/ui.auth/src/api/api.ts';
import { Spinner } from '@seeeverything/ui.primitives/src/components/Spinner/index.ts';
import { TitledDialog } from '@seeeverything/ui.primitives/src/components/TitledDialog/TitledDialog.tsx';
import { useRouter } from 'next/router.js';
import { ChangeEvent, useCallback, useState } from 'react';
import { hideModalDialog } from '../../redux/modalDialog/actions.ts';
import { showStatusBar } from '../../redux/sheets/actions.ts';
import { useShellDispatch, useShellSelector } from '../../redux/store.ts';
import {
  ChangePasswordInApp,
  PasswordValidation,
} from './ChangePasswordInApp.tsx';

const NEW_PASSWORD_VALIDATIONS = [
  {
    label: 'Passwords must match',
    validate: (newPassword: string, newPasswordRepeated: string) =>
      newPassword === newPasswordRepeated,
  },
  {
    label: 'Password must contain a lower case letter',
    validate: (newPassword: string) =>
      newPassword?.match(/(.*[a-z].*)/)?.length > 0,
  },
  {
    label: 'Password must contain an upper case letter',
    validate: (newPassword: string) =>
      newPassword?.match(/(.*[A-Z].*)/)?.length > 0,
  },
  {
    label: 'Password must contain a number',
    validate: (newPassword: string) =>
      newPassword?.match(/(.*[0-9].*)$/)?.length > 0,
  },
  {
    label: 'Password must contain at least 8 characters',
    validate: (newPassword: string) => newPassword.length >= 8,
  },
];

const validateNewPassword = (
  password: string,
  passwordRepeated: string,
): PasswordValidation[] =>
  NEW_PASSWORD_VALIDATIONS.map(({ label, validate }) => ({
    label,
    valid: validate(password, passwordRepeated),
  }));

export const ChangePasswordInAppContainer = () => {
  const dispatch = useShellDispatch();
  const router = useRouter();

  const tenant = useShellSelector((state) => state.tenantState.tenant.id);
  const module = useShellSelector((state) => state.tenantState.tenant.module);

  const [loading, setIsLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordRepeated, setNewPasswordRepeated] = useState<string>('');
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [validationResults, setValidationResults] = useState<
    PasswordValidation[]
  >([]);

  const updateCurrentPassword = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setCurrentPassword(event.target.value);
    },
    [],
  );

  const updateNewPassword = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const toNewPassword = event.target.value;
      setNewPassword(toNewPassword);

      const validations = validateNewPassword(
        toNewPassword,
        newPasswordRepeated,
      );
      setValidationResults(validations);
      setCanSubmit(validations.some((result) => !result.valid));
    },
    [newPasswordRepeated],
  );

  const updateNewPasswordRepeated = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const toNewPasswordRepeated = event.target.value;
      setNewPasswordRepeated(toNewPasswordRepeated);

      const validations = validateNewPassword(
        newPassword,
        toNewPasswordRepeated,
      );
      setValidationResults(validations);
      setCanSubmit(validations.every((result) => result.valid));
    },
    [newPassword],
  );

  const cancelChangePassword = useCallback(() => {
    dispatch(hideModalDialog());
  }, [dispatch]);

  const submitChangePassword = useCallback(async () => {
    if (!canSubmit) return;

    setIsLoading(true);

    const result = await authApi.changePassword({
      currentPassword,
      newPassword,
      tenant,
      module,
    });
    setIsLoading(false);

    if (!result.success) {
      dispatch(showStatusBar('ERROR', result.failReason));
      return;
    }

    // History changed event is useful for password managers to do their thing.
    router.push(window.location.search, undefined, {
      shallow: false,
    });
    dispatch(hideModalDialog());
    dispatch(showStatusBar('SUCCESS', 'Your password has been changed.'));
  }, [
    canSubmit,
    currentPassword,
    dispatch,
    module,
    newPassword,
    router,
    tenant,
  ]);

  const elLoading = loading && (
    <div css={styles.loadingMask}>
      <Spinner size={32} center={true} />
    </div>
  );

  return (
    <div css={styles.base}>
      {elLoading}
      <TitledDialog
        title={'Change Password'}
        actions={[
          {
            id: 'cancel',
            label: 'Cancel',
            onAction: cancelChangePassword,
            isEnabled: !loading,
          },
          {
            id: 'changePassword',
            label: 'Change Password',
            onAction: submitChangePassword,
            isEnabled: !loading,
          },
        ]}
      >
        <div css={loading ? styles.loading : undefined}>
          <ChangePasswordInApp
            onChangeCurrentPassword={updateCurrentPassword}
            onChangeNewPassword={updateNewPassword}
            onChangeNewPasswordRepeated={updateNewPasswordRepeated}
            currentPassword={currentPassword}
            newPassword={newPassword}
            newPasswordRepeated={newPasswordRepeated}
            validationResults={validationResults}
          />
        </div>
      </TitledDialog>
    </div>
  );
};

const styles = {
  base: css({
    flex: '1 1 auto',
    position: 'relative',
  }),
  loading: css({
    opacity: 0.3,
  }),
  loadingMask: css({
    position: 'absolute',
    inset: 0,
    zIndex: 10,
  }),
};
