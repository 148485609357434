import { FontWeight, ModuleType } from '@seeeverything/ui.util/src/types.ts';
import { IDashboardExtendedGridColumn, IDashboardGrid } from '../../types.ts';

const COACHING_COLUMNS: IDashboardExtendedGridColumn[] = [
  {
    id: 'session.created',
    label: 'Created',
    width: '*',
    borderRight: true,
    type: 'DATE',
    isFilterable: false,
  },
  {
    id: 'session.name',
    label: 'Name',
    width: '2*',
    borderRight: true,
    canLaunch: true,
    isFilterable: true,
  },
  {
    id: 'session.coach',
    label: 'Coached by',
    width: '2*',
    isFilterable: true,
  },
  {
    id: 'session.coachee',
    label: 'Coachee',
    width: '2*',
    borderRight: true,
    isFilterable: true,
  },
  {
    id: 'session.updated',
    label: 'Updated',
    width: '*',
    type: 'DATE',
    isFilterable: false,
  },
  {
    id: 'session.due',
    label: 'Due',
    width: '*',
    borderRight: true,
    type: 'DATE',
    isFilterable: false,
    format: 'D MMM YY',
  },
  {
    id: 'session.status',
    label: 'Status',
    type: 'ENUM',
    width: '*',
    isFilterable: true,
  },
];

const COMPLIANCE_COLUMNS: IDashboardExtendedGridColumn[] = [
  {
    id: 'session.reportingDate',
    label: 'Review Date',
    width: '*',
    borderRight: true,
    type: 'DATE',
    isFilterable: false,
    format: 'D MMM YY',
  },
  {
    id: 'session.name',
    label: 'Name',
    width: '2*',
    borderRight: true,
    canLaunch: true,
    isFilterable: true,
  },
  {
    id: 'session.coach',
    label: 'Reviewer',
    width: '2*',
    isFilterable: true,
  },
  {
    id: 'session.coachee',
    label: 'Under Test',
    width: '2*',
    borderRight: true,
    isFilterable: true,
  },
  {
    id: 'session.updated',
    label: 'Updated',
    width: '*',
    type: 'DATE',
    isFilterable: false,
  },
  {
    id: 'session.due',
    label: 'Due',
    width: '*',
    borderRight: true,
    type: 'DATE',
    isFilterable: false,
    format: 'D MMM YY',
  },
  {
    id: 'session.status',
    label: 'Status',
    type: 'ENUM',
    width: '*',
    isFilterable: true,
  },
];

const CADENCE_COLUMNS: IDashboardExtendedGridColumn[] = [
  {
    id: 'session.created',
    label: 'Created',
    width: '*',
    borderRight: true,
    type: 'DATE',
    isFilterable: false,
    format: 'D MMM YY',
  },
  {
    id: 'session.category',
    label: 'Category',
    width: '2*',
    isFilterable: true,
  },
  {
    id: 'session.name',
    label: 'Meeting',
    width: '2*',
    borderRight: true,
    canLaunch: true,
    isFilterable: true,
  },
  {
    id: 'session.facilitator',
    label: 'Facilitator',
    width: '2*',
    isFilterable: true,
  },
  {
    id: 'session.team',
    label: 'Team',
    width: '2*',
    borderRight: true,
    isFilterable: true,
  },
  {
    id: 'session.reviewDate',
    label: 'Meeting Date',
    width: '*',
    type: 'DATE',
    isFilterable: false,
    format: 'D MMM YY',
  },
  {
    id: 'session.due',
    label: 'Due',
    width: '*',
    borderRight: false,
    type: 'DATE',
    isFilterable: false,
    format: 'D MMM YY',
  },
  {
    id: 'session.daysUntilDue',
    label: '',
    width: '*',
    borderRight: true,
    type: 'NUMBER',
    isFilterable: false,
    format: 'OVERDUE',
    contentMap: [
      {
        weight: FontWeight.bold,
        rowTag: 'IsOverdue',
      },
    ],
  },
  {
    id: 'session.status',
    label: 'Status',
    type: 'ENUM',
    width: '*',
    isFilterable: true,
  },
];

const getColumns = (module: ModuleType) => {
  if (module === 'cadence') return CADENCE_COLUMNS;
  if (module === 'compliance') return COMPLIANCE_COLUMNS;
  return COACHING_COLUMNS;
};

type Args = {
  sourceGridId: string;
  sourceGridDataSetId: string;
  clickThroughDataSetId: string;
  clickThroughColumnId: string;
  clickThroughRowId: string;
  gridName: string;
  module?: ModuleType;
  entityPath: string;
};

export const sessionsClickThroughGrid = ({
  sourceGridId,
  sourceGridDataSetId,
  clickThroughDataSetId,
  clickThroughColumnId,
  clickThroughRowId,
  gridName,
  module,
  entityPath,
}: Args): IDashboardGrid => ({
  componentType: 'CLICK_THROUGH_GRID',
  id: sourceGridId,
  columns: getColumns(module),
  name: gridName,
  title: gridName,
  isHidden: false,
  dataState: {
    dataSetId: clickThroughDataSetId,
    isLoaded: false,
    isLoading: false,
    forceReload: false,
    data: {},
  },
  entityPath,
  clickThrough: {
    columnId: clickThroughColumnId,
    rowId: clickThroughRowId,
    dataSetId: sourceGridDataSetId,
  },
  group: 'FULLSCREEN_CLICK_THROUGH_GRID',
  index: 0,
  pageSize: 100,
  initialOrderBys: [
    { fieldName: 'session.status', direction: 'Descending' },
    { fieldName: 'session.due', direction: 'Ascending' },
  ],
});
