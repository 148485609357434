/**
 * Gets business entities from the data endpoint.
 * Optionally filter by business unit.
 */
export function businessEntities(chipType?: string) {
  switch (chipType) {
    case 'bu':
      return ['Team'];

    case undefined:
      return ['Team', 'People', 'Forms'];

    case 'team':
      return ['Team', 'People'];

    case 'forms':
      return [];

    case 'people':
      return ['Forms'];

    default:
      return [];
  }
}
