/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { mergeDeepRightAll } from '@seeeverything/ui.util/src/object/object.ts';
import { propsAreEqualFastCompare } from '@seeeverything/ui.util/src/react/memoFastCompare.ts';
import { isValidElement, memo } from 'react';
import { Text } from '../../Text/Text.tsx';
import { CellTheme, IDataGridCellTheme, getCellTheme } from '../themes.ts';
import { IDataGridColumn } from '../types.ts';

export interface ICellProps {
  key: string;
  data: React.ReactNode;
  column: IDataGridColumn;
  size: 'LARGE' | 'MEDIUM';
  isFirstRow: boolean;
  isLastRow: boolean;
  isFirstColumn: boolean;
  isLastColumn: boolean;
  theme?: CellTheme;
  themeOverrides?: IDataGridCellTheme;
}

const BORDER_COLOR = color.format(-0.12);

const View: React.FC<ICellProps> = ({
  data,
  column,
  size,
  isLastColumn,
  theme: themeKey,
  themeOverrides,
}) => {
  const theme = mergeDeepRightAll(
    getCellTheme(themeKey || 'base'),
    themeOverrides || {},
  );

  const isHidden = column.isHidden === true;

  const border = `solid 1px ${BORDER_COLOR}`;
  const borderBottom =
    theme.borderBottom === false
      ? undefined
      : theme.borderBottom || `solid 1px ${BORDER_COLOR}`;

  const computedStyles = {
    base: css({
      textAlign: column.align,
      backgroundColor: theme.bgColor,
      borderTop: theme.borderTop,
      borderRight: !isLastColumn && column.borderRight ? border : undefined,
      borderBottom,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      padding: isHidden ? 0 : size === 'LARGE' ? 8 : '0px 8px',
      width: isHidden ? 0 : undefined,
      boxSizing: 'border-box',
    }),
  };

  const elData = isValidElement(data) ? (
    data
  ) : (
    <Text
      size={theme.fontSize}
      ellipsis={true}
      color={theme.textColor}
      cursor={'default'}
      style={styles.text}
      italic={theme.italic}
      weight={theme.fontWeight}
    >
      {data}
    </Text>
  );

  return <div css={[computedStyles.base, theme.baseStyle]}>{elData}</div>;
};

const styles = {
  text: css({
    width: '100%',
  }),
};

export const Cell = memo(View, propsAreEqualFastCompare);
