import {
  IQuery,
  IQueryData,
  IQuerySelection,
} from '../../api/api.queryBuilder/types.ts';
import {
  QueryBuilderAutocompleteDropdownAction,
  QueryBuilderDropdownInlineFilterClickedAction,
  QueryBuilderDropdownLoadedAction,
  QueryBuilderHideQueryDropdownAction,
  QueryBuilderRequestQueryDropdownAction,
  QueryBuilderShowQueryDropdownAction,
  QueryDropdownState,
} from './types.ts';

export function requestDropdown(
  data: IQueryData,
  query: IQuery,
  selection?: IQuerySelection,
): QueryBuilderRequestQueryDropdownAction {
  return {
    type: 'ui.shell/query/DROPDOWN_REQUEST',
    payload: { data, query, selection },
  };
}
export function showDropdown(
  dropdown: QueryDropdownState,
): QueryBuilderShowQueryDropdownAction {
  return {
    type: 'ui.shell/query/DROPDOWN_SHOW',
    payload: {
      dropdown,
    },
  };
}

export function hideDropdown(): QueryBuilderHideQueryDropdownAction {
  return {
    type: 'ui.shell/query/DROPDOWN_HIDE',
    payload: {},
  };
}

export function dropdownLoaded(data: any): QueryBuilderDropdownLoadedAction {
  return {
    type: 'ui.shell/query/DROPDOWN_LOADED',
    payload: { data },
  };
}

export function autocomplete(
  query: IQuery,
  editingChip?: { index: number },
): QueryBuilderAutocompleteDropdownAction {
  return {
    type: 'ui.shell/query/DROPDOWN_AUTOCOMPLETE',
    payload: {
      query,
      editingChip,
    },
  };
}

export function inlineFilterClicked(
  id: string,
  data: IQueryData,
  query: IQuery,
  selection?: IQuerySelection,
): QueryBuilderDropdownInlineFilterClickedAction {
  return {
    type: 'ui.shell/query/DROPDOWN_INLINE_FILTER_CLICKED',
    payload: { id, data, query, selection },
  };
}
