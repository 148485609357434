/** @jsxImportSource @emotion/react */
import {
  ISelectionListColumn,
  ISelectionListItem,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { IQueryRequest } from '@seeeverything/ui.shell/src/api/api.queryBuilder/types.ts';
import { last } from 'ramda';
import React from 'react';
import { getInitializedApp } from '../../../app.ts';
import { getBusinessEntities } from '../entities.ts';

export const MAX_HEIGHT = 400;

export const lastChipFromQuery = (request: IQueryRequest) => {
  if (!request.query.chips) {
    return undefined;
  }

  const selectedIndex = request.selection && request.selection.chipIndex;

  /**
   * Don't include any chips beyond the current selected index.
   */
  const queryChips =
    selectedIndex !== undefined
      ? [...request.query.chips.slice(0, selectedIndex)]
      : [...request.query.chips];

  const result = last(queryChips.filter((chip) => chip.value));

  return result
    ? {
        type: result.type,
        value: result.value as string,
      }
    : undefined;
};

export const businessUnitFromQuery = (request: IQueryRequest) => {
  const buChip =
    request.query.chips &&
    request.query.chips.find((chip) => chip.type === 'bu');

  return buChip ? buChip.value : undefined;
};

/**
 * Constructs a dropdown showing the next level of items, depending on the filters
 * determined in the request (through query chips).
 */
export async function optionsDropdown(
  request: IQueryRequest,
  filter: string,
): Promise<any> {
  const app = getInitializedApp();
  const locale = app.store.getState().tenantState.tenant?.locale;

  const chip = lastChipFromQuery(request);
  const businessEntities = getBusinessEntities(
    locale,
    chip?.value ? chip.type : undefined,
  );

  const items: ISelectionListItem[] = Object.keys(businessEntities).map(
    (key) => ({
      id: key,
      icon: businessEntities[key].icon,
      content: businessEntities[key].label,
    }),
  );

  const height = calculateDropdownHeight(items);

  const column: ISelectionListColumn = {
    isScrollable: height > MAX_HEIGHT,
    items,
  };

  return {
    filter,
    column: items.length === 0 ? undefined : column,
    height: Math.min(height, MAX_HEIGHT),
  };
}

export const calculateDropdownHeight = (items: ISelectionListItem[] = []) => {
  type ItemType = 'ITEM' | 'SECTION' | 'DIVIDER' | 'CONTENT' | undefined;

  // Despite having one, `ITEM`s don't have a type specified, so anything that
  // isn't a `section` will be an item. Technically this could be `Divider` but
  // these aren't yet used anywhere.
  const calc = (type: ItemType, hasDescription: boolean): number =>
    type === 'SECTION' ? 25 : hasDescription ? 54 : 42;

  return items.length
    ? items
        .map((item) =>
          calc(
            item.type,
            React.isValidElement(item.content) ||
              (item.content && (item.content as any).description),
          ),
        )
        .reduce((height, next) => height + next)
    : 42;
};
