import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import * as R from 'ramda';
import { REQUIRED_FIELD_MESSAGE } from '../common/constants.ts';
import {
  DesignerChangeEventHandler,
  DesignerChangeEventKind,
  DesignerEventOccurredHandler,
  DesignerEventOccurredKind,
} from './types.ts';

export interface IHandleChangeEventOptions {
  preventText?: boolean;
  toLowerCase?: boolean;
}

export const handleChangeEvent =
  (
    id: string,
    kind: DesignerChangeEventKind,
    handler?: DesignerChangeEventHandler,
    payload?: object,
    { preventText, toLowerCase }: IHandleChangeEventOptions = {
      preventText: false,
      toLowerCase: false,
    },
  ) =>
  (to: string | boolean | ISelectionListItem) => {
    if (handler && to !== undefined) {
      if (preventText && R.is(String, to)) {
        return;
      }

      const value = R.is(Boolean, to)
        ? (to as boolean)
        : R.is(String, to)
          ? (to as string)
          : ((to as ISelectionListItem).value as string);

      const updatedTo = toLowerCase ? (value as string).toLowerCase() : value;

      handler(id, updatedTo, kind, payload);
    }
  };

export const handleEventOccurred =
  (
    id: string,
    kind: DesignerEventOccurredKind,
    handler?: DesignerEventOccurredHandler,
    payload?: object,
  ) =>
  () =>
    handler?.(id, kind, payload);

export const getValidationError = (
  invalidFields: string[] | undefined,
  fieldToValidate: string,
) =>
  invalidFields?.some((field) => field === fieldToValidate)
    ? REQUIRED_FIELD_MESSAGE
    : undefined;
