import { combineEpics, ofType } from 'redux-observable';
import { Observable, filter, map } from 'rxjs';
import { ShellAction, ShellState } from '../types.ts';
import { removeSheet } from './actions.ts';
import { SheetToolbarClickAction } from './types.ts';

export const epics = combineEpics<ShellAction, ShellAction, ShellState>(
  sheetToolbarClick,
);

function sheetToolbarClick(action$: Observable<SheetToolbarClickAction>) {
  return action$.pipe(
    ofType('ui.shell/sheets/TOOLBAR_CLICK'),
    filter((action) => action.payload.toolId === 'SHEET_UP'),
    map(removeSheet),
  );
}
