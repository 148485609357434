/**
 * Implementation of `setTimeout` with:
 * - Promise
 * - The timeout value as the first parameter.
 */
export function delay<T>(msecs = 0, callback?: () => T) {
  return new Promise<T>((resolve, reject) => {
    setTimeout(() => {
      try {
        resolve(callback?.());
      } catch (error) {
        reject(error);
      }
    }, msecs);
  });
}
