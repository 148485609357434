/** @jsxImportSource @emotion/react */
import { useCallback, useRef, useState } from 'react';
import { css } from '@emotion/react';
import Scrollbars from 'react-custom-scrollbars';
import { AutoSizer, Grid, GridCellProps, Index } from 'react-virtualized';

export interface IVirtualizedScrollProps {
  cellRenderer: (props: GridCellProps) => React.ReactElement;
  rowCount: number;
  rowHeight: number | ((params: Index) => number);
}

/**
 * Wraps a React component based on React Virtualized,
 * providing virtualization and styling.
 *
 * http://www.reactvirtualized.com/
 *
 * NOTE:
 *    1. Elements that use VirtualizedScroll assume absolute positioning
 *    to fill their entire parent. Make sure the parent is set with
 *    `position: relative` so that it relative sizing is assumed
 *    by the Scroll-ing component.
 *
 *    2. VirtualizedScroll expects to be the direct parent of a
 *    React Virtualized Grid.
 */
export const VirtualizedScroll: React.FC<IVirtualizedScrollProps> = ({
  cellRenderer,
  rowCount,
  rowHeight,
}) => {
  const [scrollTop, setScrollTop] = useState(0);
  const scrollbarsRef = useRef<Scrollbars>(null);

  const handleScroll = useCallback(() => {
    setScrollTop(scrollbarsRef.current?.getScrollTop() ?? 0);
  }, []);

  const elTrackVertical = useCallback(
    () => <div css={styles.trackVertical} />,
    [],
  );

  const elView = useCallback(() => <div css={styles.view} />, []);

  return (
    <AutoSizer>
      {({ width, height }) => (
        <div css={styles.base}>
          <Scrollbars
            className={'scroll-behavior'}
            ref={scrollbarsRef}
            onScroll={handleScroll}
            renderTrackVertical={elTrackVertical}
            renderView={elView}
          >
            <Grid
              autoHeight={true}
              cellRenderer={cellRenderer}
              columnCount={1}
              columnWidth={width}
              height={height}
              overscanRowCount={20}
              rowCount={rowCount}
              rowHeight={rowHeight}
              scrollTop={scrollTop}
              style={{ outline: 'none' }}
              width={width}
            />
          </Scrollbars>
        </div>
      )}
    </AutoSizer>
  );
};

const styles = {
  base: css({
    position: 'absolute',
    inset: 0,
  }),
  trackVertical: css({
    position: 'absolute',
    borderRadius: 3,
    bottom: 2,
    right: 2,
    top: 2,
    width: 15,
    zIndex: 5,
  }),
  view: css({
    WebkitOverflowScrolling: 'touch',
    inset: 0,
    marginRight: -15,
    position: 'absolute',
    overflowY: 'scroll',
    overflowX: 'hidden',
    marginBottom: 0,
  }),
};
