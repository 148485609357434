import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import gql from 'graphql-tag';
import { DashboardV2Template, DashboardV2TemplateSummary } from './types.ts';

export type DashboardV2Templates = {
  person?: DashboardV2Template;
  team?: DashboardV2Template;
  source?: string;
};

export async function templates(
  client: IGraphQLClient,
  module: ModuleType,
): Promise<DashboardV2Templates> {
  const response = await client.query<any>({
    fetchPolicy: 'network-only',
    query: GqlDashboardTemplates,
    variables: { module },
  });

  return response.data.dashboards.templates.nodes
    .filter(teamOrPerson)
    .map(toTemplate)
    .reduce((a: any, v: any) => ({ ...a, [v.kind]: { ...v } }), {});
}

const teamOrPerson = (node: any) =>
  Boolean(
    node.type &&
      node.definition &&
      (node.type === 'Person' || node.type === 'Team'),
  );

const toTemplate = (node: any): DashboardV2TemplateSummary => {
  const definition = str.decodeBase64(node.definition, false);
  if (!definition) {
    throw new Error(`Template with id ${node.id} is not properly encoded.`);
  }

  return {
    id: node.id,
    kind: node.type.toLowerCase(),
    definition,
  };
};

const GqlDashboardTemplates = gql`
  query DashboardTemplates {
    dashboards {
      templates {
        nodes {
          id
          type
          definition
        }
      }
    }
  }
`;
