import gql from 'graphql-tag';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';

type DeactivateDigitalContentPackResponse = {
  digitalContent: {
    deactivateDigitalContentPack: {
      ok: boolean;
    };
  };
};

export interface IDeactivateDigitalContentPackResult {
  success: boolean;
  exception?: any;
}

export async function deactivateDigitalContentPack(
  client: IGraphQLClient,
  id: string,
): Promise<IDeactivateDigitalContentPackResult> {
  try {
    const response = await client.mutate<DeactivateDigitalContentPackResponse>({
      mutation,
      variables: { id },
    });

    return succeeded(response?.data as DeactivateDigitalContentPackResponse)
      ? { success: true }
      : fail(response.errors);
  } catch (error) {
    return fail(error);
  }
}

const succeeded = (data: DeactivateDigitalContentPackResponse) =>
  data?.digitalContent?.deactivateDigitalContentPack?.ok;

const fail = (exception: any) => {
  log.error(
    `Error while deactivating digital content pack: ${exception}.`,
    exception,
  );

  return {
    success: false,
    exception,
  };
};

const mutation = gql`
  mutation DeactivateDigitalContentPack($id: ID!) {
    digitalContent {
      deactivateDigitalContentPack(id: $id) {
        ok
      }
    }
  }
`;
