/** @jsxImportSource @emotion/react */
import { ErrorScreen } from '@seeeverything/ui.shell/src/components/ErrorScreen/ErrorScreen.tsx';
import { ShellContainer } from '@seeeverything/ui.shell/src/components/Shell/ShellContainer.tsx';
import React from 'react';
import { IInitializedApp } from '../../types.ts';

/**
 * Root level application (entry point).
 */
export interface IApplicationProps {
  app: IInitializedApp;
}

export const App: React.FC<IApplicationProps> = ({ app }) =>
  app ? (
    <ShellContainer
      queryData={app.queryBuilder.data}
      sheetContentFactory={app.sheetContentFactory}
      dashboardV2Templates={app.dashboardV2Templates}
    />
  ) : (
    <ErrorScreen generalError={true} />
  );
