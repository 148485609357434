import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { combineEpics, StateObservable } from 'redux-observable';
import { filter, map, mergeMap, Observable } from 'rxjs';
import * as data from '../data/index.ts';
import * as formsDesignerViewTemplatesSlice from './formsDesignerViewTemplatesSlice.ts';
import {
  GlobalFormsDesignerEpicDependencies,
  GlobalFormsDesignerState,
} from './store.ts';

export const viewTemplatesEpics = combineEpics<
  ReduxAction,
  ReduxAction,
  GlobalFormsDesignerState
>(
  loadDesignerTemplatesEpic,
  loadDesignerTemplatesOnOrderByChangedEpic,
  deletePublishedAndOrDraftTemplate,
);

function loadDesignerTemplatesOnOrderByChangedEpic(
  action$: Observable<ReduxAction>,
) {
  return action$.pipe(
    filter(formsDesignerViewTemplatesSlice.sortTemplates.match),
    map(() => formsDesignerViewTemplatesSlice.load()),
  );
}

function loadDesignerTemplatesEpic(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsDesignerState>,
  { client }: GlobalFormsDesignerEpicDependencies,
) {
  return action$.pipe(
    filter(formsDesignerViewTemplatesSlice.load.match),
    mergeMap(async () => {
      try {
        const templates = await data.drafts(
          client,
          state$.value.formsDesignerViewTemplates.orderBy,
        );
        return formsDesignerViewTemplatesSlice.loaded({ templates });
      } catch {
        return formsDesignerViewTemplatesSlice.loadFailed();
      }
    }),
  );
}

/**
 * Deletes a published form template and/or designer draft.
 */
function deletePublishedAndOrDraftTemplate(
  action$: Observable<ReduxAction>,
  state$: StateObservable<GlobalFormsDesignerState>,
  { client }: GlobalFormsDesignerEpicDependencies,
) {
  return action$.pipe(
    filter(formsDesignerViewTemplatesSlice.deleteTemplate.match),
    filter((action) =>
      state$.value.formsDesignerViewTemplates.templates?.some(
        (template) => template.id === action.payload.id,
      ),
    ),
    mergeMap(async (action) => {
      const designerTemplate =
        state$.value.formsDesignerViewTemplates.templates.find(
          (template) => template.id === action.payload.id,
        );

      const templateId = action.payload.deletePublishedTemplate
        ? designerTemplate.publishedTemplateId
        : undefined;

      const result = await data.deleteFormTemplate(
        client,
        designerTemplate.id,
        templateId,
      );

      return result.success
        ? formsDesignerViewTemplatesSlice.deletedTemplate({
            id: designerTemplate.id,
            name: designerTemplate.name,
          })
        : formsDesignerViewTemplatesSlice.deleteTemplateFailed({
            id: designerTemplate.id,
            name: designerTemplate.name,
            graphQLErrors: result.exception?.graphQLErrors,
          });
    }),
  );
}
