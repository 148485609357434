/** @jsxImportSource @emotion/react */
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { css } from '@emotion/react';

export interface IEntryContentProps {
  header: string;
}

export const EntryTextContent: React.FC<IEntryContentProps> = ({ header }) => (
  <div css={styles.base}>
    <Text
      color={color.format(-0.5)}
      block={true}
      weight={FontWeight.black}
      size={18}
    >
      {header}
    </Text>
  </div>
);

const styles = {
  base: css({
    boxSizing: 'border-box',
    padding: '47px 20px 40px 60px',
  }),
};
