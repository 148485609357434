/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CommentsList } from '@seeeverything/ui.dashboards/src/components/CommentsList/CommentsList.tsx';
import { Spinner } from '@seeeverything/ui.primitives/src/components/Spinner/index.ts';
import { useMemo } from 'react';
import { useShellSelector } from '../../redux/store.ts';
import { SheetError } from '../SheetError/SheetError.tsx';
import { SheetLayout } from '../SheetLayout/SheetLayout.tsx';

export interface ISheetCommentsListContainerProps {
  dashboardComponentId: string;
}

export const SheetCommentsListContainer: React.FC<
  ISheetCommentsListContainerProps
> = ({ dashboardComponentId }) => {
  const commentsList = useShellSelector(
    (state) => state.dashboardsV2.COMMENTS_LIST[dashboardComponentId],
  );

  const template = useShellSelector((state) => state.dashboardsV2.template);

  const isLoading = useMemo(() => {
    if (!commentsList) return true;
    if (commentsList.dataState.isLoading) return true;
    if (commentsList.isLoadingPage) return 'MORE';
    return false;
  }, [commentsList]);

  const dashboardError = useShellSelector((state) => state.dashboardsV2.error);

  const isError = useMemo(() => {
    if (!commentsList) return false;
    if (dashboardError) return true;
    if (commentsList.dataState.error) return true;
    return false;
  }, [commentsList, dashboardError]);

  const chips = useShellSelector((state) => state.query.query.chips);
  const title = useMemo(() => {
    if (!chips?.length) return;
    return chips[chips.length - 1]?.label;
  }, [chips]);

  if (isError)
    return (
      <SheetError
        isSpinning={isLoading === true}
        message={
          'There was a problem retrieving your data. You may not have permission to view this.'
        }
        detail={
          'Please contact your administrator if you think you are seeing this in error or would like to change your permissions.'
        }
      />
    );

  if (!template) return <Spinner center={true} />;

  return (
    <SheetLayout title={title}>
      <div css={styles.base}>
        <CommentsList
          dashboardComponentId={dashboardComponentId}
          isLoading={isLoading}
          canLoadMore={
            !isLoading && commentsList?.dataState.data.pagination.hasNextPage
          }
          type={'FULL_SHEET'}
        />
      </div>
    </SheetLayout>
  );
};

const styles = {
  base: css({
    margin: '5px 10px',
  }),
};
