import { IDashboardContentMapping } from '../types.ts';

export function parseContentMap(
  contentMap?: any,
): IDashboardContentMapping[] | undefined {
  if (!contentMap || !Array.isArray(contentMap)) {
    return;
  }

  return contentMap.map((setting) => ({
    icon: setting.icon,
    color: setting.color,
    label: setting.label,
    weight: setting.weight,
    minValueInclusive: setting['min-number-inclusive'],
    minValue: setting['min-number'],
    maxValueInclusive: setting['max-number-inclusive'],
    maxValue: setting['max-number'],
    equalsValue: setting['equals-value'],
    rowTag: setting['row-tag'],
    bgColor: setting['bg-color'],
    textIcon: setting['text-icon'],
    textIconColor: setting['text-icon-color'],
  }));
}
