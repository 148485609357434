import gql from 'graphql-tag';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/client/types.ts';
import { QueryResult } from '../../types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';

type AuthUserPermissionsDto = {
  canAccessReporting: boolean;
};

export const getUserPermissions = async (
  client: IGraphQLClient,
): Promise<QueryResult<AuthUserPermissionsDto>> => {
  try {
    const response = await client.query<{
      authorization: { userPermissions: AuthUserPermissionsDto };
    }>({
      query: gql`
        query AuthUserPermissions {
          authorization {
            userPermissions {
              canAccessReporting
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
    });

    const userPermissions = response.data.authorization.userPermissions;
    if (!userPermissions) {
      log.error(`Failed to retrieve user permissions`);
      return { isSuccess: false, errorReason: 'NOT_FOUND' };
    }

    return {
      isSuccess: true,
      data: userPermissions,
    };
  } catch (error) {
    log.error(
      `Something went wrong trying to query user permissions - ${error.message}`,
      error,
    );
    return {
      isSuccess: false,
      errorReason: 'UNKNOWN',
      error,
    };
  }
};
