export const FILE_UPLOAD_DEFAULTS = {
  maxSize: 209715200, // 200 MB.
  allowedMimeTypes: {
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
      '.docx',
    ],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
      '.xlsx',
    ],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      ['.pptx'],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.ms-powerpoint': ['.ppt'],
    'application/vnd.ms-outlook': ['.msg'],
    'text/plain': ['.txt'],
    'image/gif': ['.gif'],
    'image/svg+xml': ['.svg'],
    'application/pdf': ['.pdf'],
    'video/mp4': ['.mp4'],
    'application/x-mpegURL': ['.mp4'],
    'video/MP2T': ['.ts'],
    'video/3gpp': ['.3gp'],
    'video/x-msvideo': ['.avi'],
  },
  allowedTypesReadable: `jpg, png, Office, text, gif, svg, pdf, mp4, flv, wmv, 3gp, mpeg`,
};

export const REQUIRED_FIELD_MESSAGE = 'This field is required.';
