import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import { CsvDateRangeType } from '../../components/CsvDialog/types.ts';
import {
  ChangeCsvExportDateAction,
  ChangeCsvExportFiltersAction,
  CsvExportConfirmedAction,
  CsvExportDoneAction,
  CsvExportEventData,
  CsvExportFilter,
  CsvExportFiltersLoadedAction,
  CsvExportLoadAction,
  CsvExportReadyStatusCheckAction,
  CsvExportReadyStatusCheckCompletedAction,
  CsvExportReadyStatusCheckFailedAction,
  CsvExportReinitializeAction,
  CsvExportSheetArgs,
} from './types.ts';

export const dateChanged = (
  to: string,
  type: CsvDateRangeType,
): ChangeCsvExportDateAction => ({
  type: 'ui.shell/csvExport/DATE_CHANGED',
  payload: { to, type },
});

export const selectedFiltersChanged = (
  to: string[],
): ChangeCsvExportFiltersAction => ({
  type: 'ui.shell/csvExport/SELECTED_FILTERS_CHANGED',
  payload: { to },
});

export const exportConfirmed = (): CsvExportConfirmedAction => ({
  type: 'ui.shell/csvExport/CONFIRMED',
  payload: {},
});

export const exportReadyStatusCheck = (
  exportJobId: string,
  retryCounter: number,
  module: ModuleType,
  eventData: CsvExportEventData,
): CsvExportReadyStatusCheckAction => ({
  type: 'ui.shell/csvExport/EXPORT_READY_STATUS_CHECK',
  payload: { exportJobId, retryCounter, module, _eventData: eventData },
});

export const exportReadyStatusCheckCompleted = (
  exportJobId: string,
  retryCounter: number,
  downloadUrl: string,
  eventData: CsvExportEventData,
): CsvExportReadyStatusCheckCompletedAction => ({
  type: 'ui.shell/csvExport/EXPORT_READY_STATUS_CHECK_COMPLETED',
  payload: { exportJobId, retryCounter, downloadUrl, _eventData: eventData },
});

export const exportReadyStatusCheckFailed = (
  exportJobId: string,
  retryCounter: number,
  reason: CsvExportReadyStatusCheckFailedAction['payload']['reason'],
  eventData: CsvExportEventData,
  exception?: string,
): CsvExportReadyStatusCheckFailedAction => ({
  type: 'ui.shell/csvExport/EXPORT_READY_STATUS_CHECK_FAILED',
  payload: {
    exportJobId,
    retryCounter,
    reason,
    exception,
    _eventData: eventData,
  },
});

export const filtersLoaded = (
  templates: CsvExportFilter[],
  categories: CsvExportFilter[],
): CsvExportFiltersLoadedAction => ({
  type: 'ui.shell/csvExport/FILTERS_LOADED',
  payload: { templates, categories },
});

export const exportDone = (): CsvExportDoneAction => ({
  type: 'ui.shell/csvExport/DONE',
  payload: {},
});

export const load = (
  startDate: string,
  endDate: string,
  dataSetId: string,
  sheetArgs?: CsvExportSheetArgs,
  shouldLoadTemplatesAndCategories?: boolean,
): CsvExportLoadAction => ({
  type: 'ui.shell/csvExport/LOAD',
  payload: {
    startDate,
    endDate,
    dataSetId,
    sheetArgs,
    shouldLoadTemplatesAndCategories,
  },
});

export const reinitialize = (): CsvExportReinitializeAction => ({
  type: 'ui.shell/csvExport/REINITIALIZE',
  payload: {},
});
