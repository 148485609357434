import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AuditLogEntry = ISheetAuditLogEntry | ISheetAuditLogEntryAggregate;

export interface ISheetAuditLogEntry {
  kind: 'entry' | 'markdown';
  action: string;
  actionedAt: string;
  actionedBy: {
    id: string;
    name: string;
  };
}

export interface ISheetAuditLogEntryAggregate {
  kind: 'aggregate';
  action: string;
  actionedAt: {
    start: string;
    end: string;
  };
  actionedBy: {
    id: string;
    name: string;
  };
  entries: ISheetAuditLogEntry[];
}

export type AuditState = {
  instanceId?: string;
  logEntries: AuditLogEntry[];
  isLoading: boolean;
  isError: boolean;
};

const DEFAULT_STATE: AuditState = {
  logEntries: [],
  isLoading: false,
  isError: false,
};

const slice = createSlice({
  name: 'libs/shell/audit',
  initialState: DEFAULT_STATE,
  reducers: {
    loaded(
      state,
      action: PayloadAction<{
        instanceId: string;
        logEntries: AuditLogEntry[];
      }>,
    ) {
      if (action.payload.instanceId !== state.instanceId) return state;

      state.logEntries = action.payload.logEntries;
      state.isLoading = false;
      state.isError = false;
    },
    loadError(state, action: PayloadAction<{ instanceId: string }>) {
      if (action.payload.instanceId !== state.instanceId) return state;

      state.logEntries = [];
      state.isLoading = false;
      state.isError = true;
    },
    load(state, action: PayloadAction<{ instanceId: string }>) {
      state.logEntries = [];
      state.instanceId = action.payload.instanceId;
      state.isLoading = true;
      state.isError = false;
    },
    reload(state, action: PayloadAction<{ instanceId: string }>) {
      state.instanceId = action.payload.instanceId;
      state.isLoading = true;
      state.isError = false;
    },
  },
});

export const { loaded, loadError, load, reload } = slice.actions;
export const reducer = slice.reducer;
export type State = AuditState;
