/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import React from 'react';
import { SpeechBlockBullet } from '../Bullet/index.ts';
import { IDesignerCommonProps } from '../types.ts';
import {
  getValidationError,
  handleChangeEvent,
  handleEventOccurred,
} from '../util.ts';

export interface IDesignerSpeechBlockProps extends IDesignerCommonProps {
  title?: string;
}

export class DesignerSpeechBlock extends React.Component<
  IDesignerSpeechBlockProps,
  object
> {
  public render() {
    const {
      id,
      title,
      canDelete,
      showBoundaryLines,
      showBullet = true,
      style,
      onChange,
      onEventOccurred,
      validationError,
    } = this.props;

    const computedStyles = {
      base: css({
        border: showBoundaryLines
          ? `dashed 1px ${color.format(-0.2)}`
          : 'solid 1px rgba(0, 0, 0, 0)',
        borderRadius: showBoundaryLines ? 8 : undefined,
      }),
    };

    const elDesignerContent = (
      <TextField
        id={`designer-speechBlock-title-${id}`}
        onChange={handleChangeEvent(id, 'LABEL', onChange)}
        label={'Speech Block - Text'}
        style={styles.fullWidth}
        value={title}
        multiline={true}
        error={getValidationError(validationError?.fields, 'title')}
      />
    );

    const elDelete = canDelete && (
      <div title={'Remove Question'}>
        <Icons.delete
          onClick={handleEventOccurred(id, 'DELETE', onEventOccurred)}
          cursor={'pointer'}
          fill={color.format(-0.4)}
          size={20}
        />
      </div>
    );

    const elOuter = (
      <div css={styles.content}>
        <div css={styles.editIcons}>{elDelete}</div>
        {elDesignerContent}
      </div>
    );

    return (
      <div css={[styles.outer, style]}>
        {showBullet && <SpeechBlockBullet />}
        <div css={[styles.base, computedStyles.base]}>{elOuter}</div>
      </div>
    );
  }
}

const styles = {
  outer: css({
    position: 'relative',
  }),
  base: css({
    display: 'flex',
    justifyContent: 'center',
    minHeight: 80,
    padding: '8px 20px 6px 10px',
    marginBottom: 5,
  }),
  content: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  }),
  editIcons: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingRight: 30,
  }),
  fullWidth: css({
    width: '100%',
  }),
};
