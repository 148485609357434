/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react';
import { useTrackInViewport } from '@seeeverything/ui.primitives/src/hooks/useTrackInViewport.ts';

export interface IFormDesignerContentItemContainerProps {
  dataId: string;
  parentDataId?: string;
  style?: SerializedStyles;
  children: React.ReactElement;
}

export const FormDesignerContentItemContainer: React.FC<
  IFormDesignerContentItemContainerProps
> = ({ dataId, parentDataId, children, style }) => {
  const [forwardedRef] = useTrackInViewport({ dataId, parentDataId });

  return (
    <div
      data-id={dataId}
      parent-data-id={parentDataId}
      ref={forwardedRef}
      css={style}
    >
      {children}
    </div>
  );
};
