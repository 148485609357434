import { Observable, filter, map } from 'rxjs';
import { combineEpics } from 'redux-observable';
import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import {
  hideModalDialog,
  showModalDialog,
} from '@seeeverything/ui.shell/src/redux/modalDialog/actions.ts';
import { IssueSecondaryCausesDialogContainer } from '@seeeverything/ui.forms/src/components/IssueSecondaryCauses/IssueSecondaryCausesDialogContainer.tsx';
import { issueSlice } from '@seeeverything/ui.forms/src/redux/issue/index.ts';
import { showStatusBar } from '@seeeverything/ui.shell/src/redux/sheets/actions.ts';
import {
  GlobalAppActionType,
  GlobalAppEpicDependencies,
  GlobalAppState,
} from '../../../types.ts';

export const epics = combineEpics<
  GlobalAppActionType,
  GlobalAppActionType,
  GlobalAppState,
  GlobalAppEpicDependencies
>(
  displayIssueSecondaryCauseDialogEpic,
  hideIssueSecondaryCauseDialogEpic,
  showIssueSecondaryCausesStatusBarErrorEpic,
);

function displayIssueSecondaryCauseDialogEpic(
  action$: Observable<ReduxAction>,
) {
  return action$.pipe(
    filter(issueSlice.displaySecondaryCausesDialog.match),
    map((action) =>
      showModalDialog({
        content: (
          <IssueSecondaryCausesDialogContainer
            issueId={action.payload.issueId}
            instanceId={action.payload.instanceId}
          />
        ),
        width: 1500,
      }),
    ),
  );
}

function hideIssueSecondaryCauseDialogEpic(action$: Observable<ReduxAction>) {
  return action$.pipe(
    filter(issueSlice.hideSecondaryCausesDialog.match),
    map(() => hideModalDialog()),
  );
}

function showIssueSecondaryCausesStatusBarErrorEpic(
  action$: Observable<ReduxAction>,
) {
  return action$.pipe(
    filter(issueSlice.saveErrorSecondaryCauses.match),
    map(() =>
      showStatusBar(
        'ERROR',
        'There was a problem saving the changes. Please try again.',
      ),
    ),
  );
}
