import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { IInitializedApp } from './types.ts';

/**
 * This serves as a point to get the state of the app running in instance.
 *
 * The purpose of this file is that in init.tsx, there is a single init() function which creates
 * an app instance. If subsequently called the init() returns the app instance. This has
 * caused the init() function to serve other purposes outside of "initializing app", namely
 * a getter for accessing the redux store or certain config options. This has created many instances
 * of circular dependencies within the application and so this file provides access to an initialized app
 * without having to worry about importing the entity of the init module in to additional files.
 *
 * This file is designed as a refactor such that the init file is only imported when the app is initialized and
 * any subsequent app related features should import this file instead to avoid the circular dependencies.
 */

let app: IInitializedApp;

export const setAppInitialized = (initializedApp: IInitializedApp) => {
  app = initializedApp;
};

export const getInitializedApp = () => {
  if (!app) {
    log.error(`[app] Accessing the app's states without it being initialized.`);
  }
  return app;
};

export const isAppInitialized = () => Boolean(app);

/**
 * Convenience function for getting the tenant features from an
 * already-initialized app.
 */
export const tenantFeaturesFromApp = (initApp: IInitializedApp) => {
  if (!initApp || !initApp.store) {
    throw new Error('Fatal error in application');
  }

  return initApp?.store.getState().tenantState.tenant?.features;
};
