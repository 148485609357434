import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/constants.ts';

export const STATUS_SELECTIONS = [
  {
    id: 'NotStarted',
    content: { text: 'Active (Not Started)' },
    value: 'NotStarted',
    icon: Icons.settings,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
  {
    id: 'Started',
    content: { text: 'Active (Started)' },
    value: 'Started',
    icon: Icons.settings,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
  {
    id: 'Active',
    content: { text: 'Active (Reactivate)' },
    value: 'Active',
    icon: Icons.settings,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
  {
    id: 'Inactive',
    content: { text: 'Inactive' },
    value: 'Inactive',
    icon: Icons.settings,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
];
