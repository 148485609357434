/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Spinner } from '@seeeverything/ui.primitives/src/components/Spinner/index.ts';
import { useViewport } from '@seeeverything/ui.primitives/src/hooks/useViewport.ts';
import { useEffect, useState } from 'react';

export interface ILoadMoreProps {
  onInView: () => void;
}

export const LoadMore: React.FC<ILoadMoreProps> = ({ onInView }) => {
  const [forwardedRef, inViewport] = useViewport();
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    if (inViewport && !isInView) {
      onInView();
      setIsInView(true);
    }

    if (!inViewport && isInView) setIsInView(false);
  }, [inViewport, isInView, onInView]);

  return (
    <div css={styles.base} ref={forwardedRef}>
      <Spinner center={true} />
    </div>
  );
};

const styles = {
  base: css({
    height: 30,
  }),
};
