/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { OptionCircle } from './OptionCircle.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';

export interface IOptionAddProps {
  onClick?: () => void;
}
export const OptionAdd = ({ onClick }: IOptionAddProps) => (
  <div css={styles.base}>
    <div css={styles.line} />
    <div css={styles.optionAdd}>
      <OptionCircle innerIcon={'add'} onClick={onClick} />
      <Text color={'rgba(0, 0, 0, 0.87)'}>{'Add new option'}</Text>
    </div>
  </div>
);

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }),
  line: css({
    height: 1,
    borderBottom: 'solid 1px #BBBBBB',
  }),
  optionAdd: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '25px 0 10px 0',
    width: '100%',
  }),
};
