import { FormLineByIdExtended } from '../../components/FormDesignerContent/types.ts';
import {
  FormDesignerReduxDesignerState,
  FormsDesignerDesignerOptionsChangeOptionText,
} from './types.ts';

export function optionsChangeOptionTextReducer(
  state: FormDesignerReduxDesignerState,
  action: FormsDesignerDesignerOptionsChangeOptionText,
) {
  if (!state.draft || !state.draft.designerLines) {
    return state;
  }

  return {
    ...state,
    draft: {
      ...state.draft,
      designerLines: updateOptionText(
        state.draft.designerLines,
        action.payload.id,
        action.payload.optionId,
        action.payload.to,
      ),
    },
  };
}

const updateOptionText = (
  designerLines: FormLineByIdExtended,
  id: string | number,
  optionId: string | number,
  to: string,
): FormLineByIdExtended => {
  const remainingFieldsOnError = (
    designerLines[id].validationError?.fields as string[]
  )?.filter((field) => field !== optionId);

  const validationError = {
    error: remainingFieldsOnError?.length > 0,
    fields: remainingFieldsOnError,
  };
  return {
    ...designerLines,
    [id]: {
      ...designerLines[id],
      optionItems: {
        ...designerLines[id].optionItems,
        options: {
          ...designerLines[id].optionItems.options,
          [optionId]: {
            ...designerLines[id].optionItems.options[optionId],
            label: to,
          },
        },
      },
      validationError,
    },
  };
};
