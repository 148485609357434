/** @jsxImportSource @emotion/react */
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/constants.ts';
import React from 'react';
import { ErrorScreen } from '../ErrorScreen/ErrorScreen.tsx';
import { SheetLayout } from '../SheetLayout/index.ts';

export interface ISheetErrorProps {
  detail?: string;
  header?: React.ReactElement;
  icon?: string;
  iconColor?: string;
  isSpinning?: boolean;
  message?: string;
  onClick?: () => void;
  showIcon?: boolean;
}

/**
 * A SheetLayout that displays an error state.
 */
export const SheetError: React.FC<ISheetErrorProps> = ({
  detail,
  header,
  icon,
  isSpinning,
  message,
  onClick,
}) => (
  <SheetLayout header={header} isSpinning={isSpinning}>
    {!isSpinning && (
      <ErrorScreen
        background={'none'}
        errorColor={color.format(-0.8)}
        errorDetail={detail}
        errorMessage={message}
        icon={icon}
        iconColor={COLORS.BLUE}
        onClick={onClick}
        generalError={false}
      />
    )}
  </SheetLayout>
);
