/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useMouseOver } from '@seeeverything/ui.primitives/src/hooks/useMouseOver.ts';
import { AutomatedActionsContent } from './components/AutomatedActionsContent.tsx';
import { AutomatedActionsHeader } from './components/AutomatedActionsHeader.tsx';

export const AutomatedActionsConfiguration: React.FC = () => {
  const [
    dropdownHighlight,
    handleShowDropdownHighlight,
    handleHideDropdownHighlight,
  ] = useMouseOver();

  return (
    <div css={styles.base}>
      <div css={styles.header}>
        <AutomatedActionsHeader dropdownHighlight={dropdownHighlight} />
      </div>
      <AutomatedActionsContent
        onShowDropdownHighlight={handleShowDropdownHighlight}
        onHideDropdownHighlight={handleHideDropdownHighlight}
      />
    </div>
  );
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
    height: '100%',
  }),
  header: css({
    padding: '20px 20px 0 15px',
  }),
};
