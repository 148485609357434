/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useTrackInViewport } from '@seeeverything/ui.primitives/src/hooks/useTrackInViewport.ts';
import { propsAreEqualFastCompare } from '@seeeverything/ui.util/src/react/memoFastCompare.ts';
import { memo } from 'react';
import { DASHBOARD_INTERSECTION_OPTIONS } from '../common/constants.ts';
import { DashboardChartContainer } from '../components/DashboardChart/DashboardChartContainer.tsx';

export interface IFactoryChartContainerProps {
  id: string;
  parentId?: string;
}

const View: React.FC<IFactoryChartContainerProps> = ({ parentId, id }) => {
  const [forwardedRef, inViewport] = useTrackInViewport({
    dataId: id,
    parentDataId: parentId,
    observerOptions: DASHBOARD_INTERSECTION_OPTIONS,
  });

  return (
    <div
      css={styles.base}
      data-id={id}
      parent-data-id={parentId}
      ref={forwardedRef}
    >
      <DashboardChartContainer id={id} inViewport={inViewport} />
    </div>
  );
};

const styles = {
  base: css({
    margin: '3px 30px 30px 35px',
  }),
};

export const FactoryChartContainer = memo(View, propsAreEqualFastCompare);
