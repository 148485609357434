/** @jsxImportSource @emotion/react */
import { useCallback, useMemo } from 'react';
import { FormActionVerificationStatus } from '@se/data/forms/types.ts';
import { IconWrapper } from '@seeeverything/ui.primitives/src/components/IconWrapper/IconWrapper.tsx';
import { TextFieldDropdown } from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';
import { FormActionPermissions } from '../../../redux/formAction/formActionSlice.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';

export interface IActionVerificationStatusDropdownProps {
  actionLabel: string;
  computedStatusChange?: FormActionVerificationStatus;
  onChange: (to: FormActionVerificationStatus) => void;
  permissions?: FormActionPermissions;
  initialStatus?: FormActionVerificationStatus;
  draftStatus?: FormActionVerificationStatus;
  isEnabled: boolean;
}

export const ActionVerificationStatusDropdown: React.FC<
  IActionVerificationStatusDropdownProps
> = ({
  actionLabel,
  computedStatusChange,
  draftStatus,
  initialStatus,
  isEnabled,
  onChange,
  permissions,
}) => {
  const isInitial = initialStatus === draftStatus;
  const isComputed = Boolean(computedStatusChange);

  const statusSelection = useMemo(
    () =>
      getSelectionOption(
        actionLabel,
        computedStatusChange ?? draftStatus,
        isInitial,
        isComputed,
      ),
    [actionLabel, computedStatusChange, draftStatus, isComputed, isInitial],
  );

  const selections = useMemo(
    () => getSelections(actionLabel, draftStatus, initialStatus, permissions),
    [actionLabel, draftStatus, initialStatus, permissions],
  );

  const handleStatusChange = useCallback(
    ({ id }: { id: FormActionVerificationStatus }) => onChange(id),
    [onChange],
  );

  return (
    <IconWrapper icon={'verifiedUser'}>
      <TextFieldDropdown
        id={'actionVerificationStatus'}
        isEnabled={isEnabled}
        label={'Verification'}
        onChange={handleStatusChange}
        selections={selections}
        value={statusSelection}
        variant={'standard'}
      />
    </IconWrapper>
  );
};

const excludedStatus = (isInitial: boolean, actionLabel: string) => ({
  id: 'Excluded',
  icon: () => <Icons.removeModeratorIcon fill={'#808080'} />,
  content: {
    text: 'Excluded',
    secondaryText: isInitial ? '(Current)' : undefined,
    description: isInitial
      ? `This ${actionLabel} is excluded from verification.`
      : `Mark as excluded from verification.`,
  },
});

const unverifiedStatus = (isInitial: boolean, actionLabel: string) => ({
  id: 'Unverified',
  icon: () => <Icons.safetyCheck fill={'#808080'} />,
  content: {
    text: 'Unverified',
    secondaryText: isInitial ? '(Current)' : undefined,
    description: isInitial
      ? `This ${actionLabel} is unverified.`
      : `Mark this ${actionLabel} as unverified.`,
  },
});

const returnedStatus = (
  isInitial: boolean,
  isComputed: boolean,
  actionLabel: string,
) => ({
  id: 'Returned',
  icon: () => <Icons.undo fill={COLORS.BLUE} />,
  content: {
    text: isComputed || !isInitial ? 'Return' : 'Returned',
    secondaryText: isInitial ? '(Current)' : undefined,
    description: isInitial
      ? `This ${actionLabel} is returned for correction.`
      : `Reopen ${actionLabel} for correction and recheck later.`,
  },
});

const recheckStatus = (isInitial: boolean, actionLabel: string) => ({
  id: 'Recheck',
  icon: () => <Icons.policy fill={COLORS.BLUE} />,
  content: {
    text: 'Recheck',
    secondaryText: isInitial ? '(Current)' : undefined,
    description: `This ${actionLabel} is ready for recheck.`,
  },
});

const verifiedStatus = (isInitial: boolean, actionLabel: string) => ({
  id: 'Verified',
  icon: () => <Icons.verifiedUser fill={'#2FAA47'} />,
  content: {
    text: 'Verified',
    secondaryText: isInitial ? '(Current)' : undefined,
    description: isInitial
      ? `${str.titleCase(actionLabel)} verification is completed.`
      : `Marks this ${actionLabel} as verified.`,
  },
});

const getSelectionOption = (
  actionLabel: string,
  status: FormActionVerificationStatus,
  isInitial: boolean,
  isComputed: boolean,
) => {
  if (!status) return;

  if (status === 'Excluded') return excludedStatus(isInitial, actionLabel);
  if (status === 'Unverified') return unverifiedStatus(isInitial, actionLabel);
  if (status === 'Returned')
    return returnedStatus(isInitial, isComputed, actionLabel);
  if (status === 'Recheck') return recheckStatus(isInitial, actionLabel);
  if (status === 'Verified') return verifiedStatus(isInitial, actionLabel);
};

const getSelections = (
  actionLabel: string,
  status: FormActionVerificationStatus,
  initialStatus: FormActionVerificationStatus,
  permissions?: FormActionPermissions,
) => {
  if (!permissions) return [];
  if (!permissions.verify) return [];

  const isInitial = status === initialStatus;

  return [
    !isInitial && getSelectionOption(actionLabel, initialStatus, true, false),
    initialStatus === 'Unverified' && verifiedStatus(false, actionLabel),
    initialStatus === 'Unverified' && returnedStatus(false, false, actionLabel),
    initialStatus === 'Recheck' && verifiedStatus(false, actionLabel),
    initialStatus === 'Recheck' && returnedStatus(false, false, actionLabel),
    initialStatus === 'Recheck' && unverifiedStatus(false, actionLabel),
    initialStatus === 'Verified' && returnedStatus(false, false, actionLabel),
    initialStatus === 'Verified' && unverifiedStatus(false, actionLabel),
  ].filter(Boolean);
};
