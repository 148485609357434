/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { IconWrapper } from '@seeeverything/ui.primitives/src/components/IconWrapper/IconWrapper.tsx';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { TextFieldDropdown } from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';

export interface ICreateFromCopyProps {
  templates?: ISelectionListItem[];
  templatesIsLoading?: boolean;
  onNameChange: (to: string) => void;
  onSourceTemplateChange: (to: string | ISelectionListItem) => void;
  name?: string;
  nameErrorText?: string;
  sourceTemplate?: ISelectionListItem;
  sourceTemplateErrorText?: string;
}

export const CreateFromCopy = ({
  name = '',
  nameErrorText,
  onNameChange,
  onSourceTemplateChange,
  sourceTemplate,
  sourceTemplateErrorText,
  templates,
  templatesIsLoading,
}: ICreateFromCopyProps) => (
  <div css={styles.body}>
    <IconWrapper icon={'fileCopy'} style={styles.field}>
      <TextFieldDropdown
        id={'sourceTemplate'}
        error={sourceTemplateErrorText}
        isLoading={templatesIsLoading}
        label={'Source'}
        onChange={onSourceTemplateChange}
        selections={templates}
        value={sourceTemplate}
      />
    </IconWrapper>
    {sourceTemplate ? (
      <IconWrapper icon={'arrowForward'} style={styles.field}>
        <TextField
          id={'name'}
          error={nameErrorText}
          label={'Rename draft? (Optional)'}
          onChange={onNameChange}
          value={name}
        />
      </IconWrapper>
    ) : undefined}
  </div>
);

const styles = {
  field: css({
    width: '100%',
    padding: '15px',
    boxSizing: 'border-box',
  }),
  body: css({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'stretch',
  }),
};
