import * as R from 'ramda';
import {
  DashboardDateFilter,
  IDashboardHeader,
  IDashboardHeaderMetric,
  ParserOptions,
} from '../types.ts';
import { parseContentMap } from './parse.contentMap.ts';
import { defaultDateFilter, parseDateFilter } from './parse.dateFilter.ts';
import { parseTrendMap } from './parse.trendMap.ts';

export function parser(
  item: any,
  index: number,
  options?: ParserOptions,
): IDashboardHeader | undefined {
  if (!item.header) {
    return;
  }

  const dateFiltersRaw = item.header['date-filters'] || [];
  const dateFilters: DashboardDateFilter[] = R.flatten(
    dateFiltersRaw.map((definition: any) =>
      parseDateFilter(definition, options),
    ),
  );

  const metricsRaw = item.header.metrics || [];
  const metrics = metricsRaw.map(parseMetric);

  return {
    componentType: 'HEADER',
    id: item.header.id || 'header',
    icon: item.header.icon,
    metrics,
    dateFilters,
    index,
    dataState: {
      isLoaded: false,
      isLoading: false,
      forceReload: false,
      dataSetId: '',
    },
    defaultDateFilter: defaultDateFilter(dateFilters),
  };
}

function parseMetric(metric: any): IDashboardHeaderMetric {
  return {
    label: metric.label,
    icon: metric.icon,
    valueFormatter: metric['value-formatter'] || 'raw',
    dataKey: metric['data-key'],
    valueLabel: metric['value-label'] ?? 'complete',
    precision: metric.precision,
    contentMap: parseContentMap(metric['content-map']),
    trendMap: parseTrendMap(metric['trend-map']),
  };
}
