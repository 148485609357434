import {
  shallowEqual,
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  GlobalReduxEpicDependenciesType,
  GlobalReduxStateType,
} from '@seeeverything/ui.util/src/redux/types.ts';
import { FormDesignerReduxDesignerState } from './designer/types.ts';
import { FormsDesignerReduxCreateTemplatesState } from './formsDesignerCreateTemplatesSlice.ts';
import { FormsDesignerReduxDropdownsState } from './formsDesignerDropdownsSlice.ts';
import { FormsDesignerReduxImportTemplatesState } from './formsDesignerImportTemplatesSlice.ts';
import { FormsDesignerReduxPublishTemplatesState } from './formsDesignerPublishTemplatesSlice.ts';
import { FormsDesignerReduxViewTemplatesState } from './formsDesignerViewTemplatesSlice.ts';

export type FormsDesignerRootState = {
  formsDesignerEditor: FormDesignerReduxDesignerState;
  formsDesignerCreateTemplates: FormsDesignerReduxCreateTemplatesState;
  formsDesignerDropdowns: FormsDesignerReduxDropdownsState;
  formsDesignerImportTemplates: FormsDesignerReduxImportTemplatesState;
  formsDesignerPublishTemplates: FormsDesignerReduxPublishTemplatesState;
  formsDesignerViewTemplates: FormsDesignerReduxViewTemplatesState;
};
export type GlobalFormsDesignerState =
  GlobalReduxStateType<FormsDesignerRootState>;
export type GlobalFormsDesignerEpicDependencies =
  GlobalReduxEpicDependenciesType;

export const useFormsDesignerDispatch = () => useDispatch<any>();
export const useFormsDesignerSelector: TypedUseSelectorHook<
  GlobalFormsDesignerState
> = (selector) => useSelector(selector, shallowEqual);
