import { Subject, Observable, filter } from 'rxjs';
import {
  IQueryDataChangeAction,
  IQueryRoutes,
  IQueryHandler,
  IChipFormatters,
  IChipFormatHandler,
} from './types.ts';

export interface IQueryHandlerOptions {
  description?: string;
  handlers$?: Array<Subject<IQueryHandler>>;
}

export interface IChipFormatterOptions {
  handlers$?: Array<Subject<IChipFormatHandler>>; // NB: Used internally when merging routers.
}

/**
 * Configures a set of routes for managing the QueryBuilder state.
 */
export class QueryRouter {
  private routes: IQueryRoutes;
  private chipFormatters: IChipFormatters;

  public toRoutes = () => this.routes;
  public toChipFormatters = () => this.chipFormatters;

  constructor(routes: IQueryRoutes = {}, chipFormatters: IChipFormatters = {}) {
    this.routes = routes;
    this.chipFormatters = chipFormatters;
  }

  /**
   * Merges the given set of routes into the local set.
   */
  public merge(router: QueryRouter) {
    const routes = router.toRoutes();
    const chipFormatters = router.toChipFormatters();
    Object.keys(routes)
      .map((key) => ({ key, route: routes[key] }))
      .forEach(({ key, route }) => {
        const { pathPattern, description, handlers$ } = route;
        this.handle(key, pathPattern, { description, handlers$ });
      });
    Object.keys(chipFormatters)
      .map((key) => chipFormatters[key])
      .forEach((formatter) => {
        const { type, handlers$ } = formatter;
        this.formatChip(type, { handlers$ });
      });
    return this;
  }

  /**
   * Registers a query handler.
   */
  public handle(
    key: string,
    pathPattern: RegExp,
    options: IQueryHandlerOptions | string = {},
  ): Observable<IQueryHandler> {
    // Setup initial conditions.
    const args: IQueryHandlerOptions =
      typeof options === 'string' ? { description: options } : options;
    const description = args.description || '';

    // Get or create the array of handlers.
    const handlers$ = (args.handlers$ && [...args.handlers$]) || [];

    // Check for existing route and re-use the existing handler where possible.
    const route = this.routes[key];
    if (route) {
      route.handlers$ = [...handlers$, ...route.handlers$];
      route.description = route.description || description;
      return route.handlers$[0];
    }

    // Ensure there is at least one handler.
    if (handlers$.length === 0) {
      handlers$.push(new Subject<IQueryHandler>());
    }

    // Store route.
    this.routes[key] = {
      key,
      pathPattern,
      handlers$,
      description,
    };

    return handlers$[handlers$.length - 1];
  }

  /**
   * Registers a dropdown handler.
   */
  public dropdown(
    key: string,
    pathPattern: RegExp,
    options?: IQueryHandlerOptions | string,
  ) {
    return this.actionHandler('DROPDOWN', key, pathPattern, options);
  }

  /**
   * Registers an autocomplete handler.
   */
  public autocomplete(
    key: string,
    pathPattern: RegExp,
    options?: IQueryHandlerOptions | string,
  ) {
    return this.actionHandler('AUTOCOMPLETE', key, pathPattern, options);
  }

  /**
   * Registers an chip formatting handler.
   */
  public formatChip(
    type: string,
    options: IChipFormatterOptions = {},
  ): Observable<IChipFormatHandler> {
    // Get or create the array of handlers.
    const handlers$ = (options.handlers$ && [...options.handlers$]) || [];

    // Check for existing formatter and re-use the existing handler where possible.
    const formatter = this.chipFormatters[type];
    if (formatter) {
      formatter.handlers$ = [...handlers$, ...formatter.handlers$];
      return formatter.handlers$[0];
    }

    // Ensure there is at least one handler.
    if (handlers$.length === 0) {
      handlers$.push(new Subject<IChipFormatHandler>());
    }

    // Store route.
    this.chipFormatters[type] = {
      type,
      handlers$,
    };

    return handlers$[0];
  }

  /**
   * PRIVATE
   */
  private actionHandler(
    action: IQueryDataChangeAction,
    key: string,
    pathPattern: RegExp,
    options?: IQueryHandlerOptions | string,
  ) {
    options = typeof options === 'string' ? { description: options } : options;
    return this.handle(key, pathPattern, options).pipe(
      filter(({ req }) => req.action === action),
    );
  }
}
