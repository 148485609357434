import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/constants.ts';

export const FACILITATOR_SELECTIONS = [
  {
    id: 'Manager',
    content: { text: 'Manager' },
    value: 'Manager',
    icon: Icons.person,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
  {
    id: 'LineManager',
    content: { text: 'Line Manager' },
    value: 'LineManager',
    icon: Icons.person,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
];

export const STATUS_SELECTIONS = [
  {
    id: 'NotStarted',
    content: { text: 'Active (Not Started)' },
    value: 'NotStarted',
    icon: Icons.settings,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
  {
    id: 'Started',
    content: { text: 'Active (Started)' },
    value: 'Started',
    icon: Icons.settings,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
  {
    id: 'Inactive',
    content: { text: 'Inactive' },
    value: 'Inactive',
    icon: Icons.settings,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
  {
    id: 'Ended',
    content: { text: 'Inactive (Ended)' },
    value: 'Ended',
    icon: Icons.settings,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
];

export const FREQUENCY_SELECTIONS = [
  {
    id: 'AddedToDistributionList',
    content: { text: 'Added to Distribution List' },
    value: 'ContinuousIncludingExisting',
    icon: Icons.update,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
  {
    id: 'OneOff',
    content: { text: 'One Off' },
    value: 'OneOff',
    icon: Icons.update,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
  {
    id: 'Weekly',
    content: { text: 'Repeating Weekly' },
    value: 'Weekly',
    icon: Icons.update,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
  {
    id: 'Monthly',
    content: { text: 'Repeating Monthly' },
    value: 'Monthly',
    icon: Icons.update,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
  {
    id: 'Quarterly',
    content: { text: 'Repeating Quarterly' },
    value: 'Quarterly',
    icon: Icons.update,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
  {
    id: 'Annual',
    content: { text: 'Repeating Annual' },
    value: 'Annual',
    icon: Icons.update,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
];

export const DUE_PERIOD_SELECTIONS = [
  {
    id: '1 Week',
    content: { text: '1 Week' },
    value: '1 Week',
    icon: Icons.clock,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
  {
    id: '2 Weeks',
    content: { text: '2 Weeks' },
    value: '2 Weeks',
    icon: Icons.clock,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
  {
    id: '3 Weeks',
    content: { text: '3 Weeks' },
    value: '3 Weeks',
    icon: Icons.clock,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
  {
    id: '1 Month',
    content: { text: '1 Month' },
    value: '1 Month',
    icon: Icons.clock,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
  {
    id: '2 Months',
    content: { text: '2 Months' },
    value: '2 Months',
    icon: Icons.clock,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
  {
    id: '3 Months',
    content: { text: '3 Months' },
    value: '3 Months',
    icon: Icons.clock,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
];

export const DAY_OF_WEEK_SELECTIONS = [
  {
    id: 'Monday',
    content: { text: 'Monday' },
    value: 'Monday',
    icon: Icons.update,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
  {
    id: 'Tuesday',
    content: { text: 'Tuesday' },
    value: 'Tuesday',
    icon: Icons.update,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
  {
    id: 'Wednesday',
    content: { text: 'Wednesday' },
    value: 'Wednesday',
    icon: Icons.update,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
  {
    id: 'Thursday',
    content: { text: 'Thursday' },
    value: 'Thursday',
    icon: Icons.update,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
  {
    id: 'Friday',
    content: { text: 'Friday' },
    value: 'Friday',
    icon: Icons.update,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
  {
    id: 'Saturday',
    content: { text: 'Saturday' },
    value: 'Saturday',
    icon: Icons.update,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
  {
    id: 'Sunday',
    content: { text: 'Sunday' },
    value: 'Sunday',
    icon: Icons.update,
    iconColor: { default: COLORS.BLUE, selected: COLORS.BLUE },
  },
];
