import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { head, is } from 'ramda';
import { GridCellDecorator } from '../../types.ts';

export const trendDecorator: GridCellDecorator = (args) => {
  const { datapoint, columnDefinition } = args;
  // Trend can be 0 or negative, don't use it in standard truthy checks.
  const trend = datapoint?.trend;

  if (columnDefinition?.trendMap && trend !== undefined) {
    if (!is(Number, trend) || isNaN(trend)) {
      return {};
    }

    const match = head(
      columnDefinition.trendMap.filter((mapping) => {
        const { minValueInclusive, minValue, maxValueInclusive, maxValue } =
          mapping;

        if (minValueInclusive === undefined && minValue === undefined) {
          // Doesn't have a valid min number.
          return false;
        }

        if (maxValueInclusive === undefined && maxValue === undefined) {
          // Doesn't have a valid max number.
          return false;
        }

        if (minValueInclusive !== undefined && trend < minValueInclusive) {
          // Less than minimum allowed (inclusive).
          return false;
        }

        if (
          minValue !== undefined &&
          minValueInclusive !== undefined &&
          trend <= minValueInclusive
        ) {
          // Less than minimum allowed.
          return false;
        }

        if (maxValueInclusive !== undefined && trend > maxValueInclusive) {
          // Less than maximum allowed (inclusive).
          return false;
        }

        if (maxValue !== undefined && trend >= maxValue) {
          // Less than minimum allowed.
          return false;
        }

        return true;
      }),
    );

    if (!match) {
      return {};
    }

    const { icon, color, label } = match;
    const Icon = icon && Icons[icon];
    if (!Icon && color) {
      return color ? { text: { color } } : {};
    }

    return {
      icon: { label, icon, color },
      text: { datapoint },
    };
  }
  return {};
};
