/** @jsxImportSource @emotion/react */
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import React from 'react';
import {
  Bar,
  CartesianGrid,
  Legend,
  Line,
  ComposedChart as RechartsComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from 'recharts';
import { CHART_COLORS } from '../../common/constants.ts';
import {
  IBarProps,
  ILegendProps,
  ILineProps,
  ITooltipProps,
} from '../types.ts';

export interface IComposedChartProps {
  id: string;
  data: any;
  chartProps: (typeof RechartsComposedChart)['defaultProps'];
  barsProps?: IBarProps[];
  linesProps?: ILineProps[];
  tooltipProps?: ITooltipProps;
  legendProps?: ILegendProps;
  xAxisProps?: XAxisProps;
  yAxisProps?: YAxisProps;
}

/**
 * A composed chart demonstrating the options available.
 */
export const ComposedChart: React.FC<IComposedChartProps> = ({
  id,
  data,
  chartProps,
  barsProps,
  linesProps,
  tooltipProps,
  legendProps,
  xAxisProps,
  yAxisProps,
}) => {
  const isVertical = chartProps.layout === 'vertical';

  const elXAxis = React.useMemo(() => {
    const verticalXAxis: XAxisProps = {
      hide: true,
      axisLine: false,
      type: 'number',
    };

    const props = isVertical ? verticalXAxis : xAxisProps;
    return props ? <XAxis {...props} /> : undefined;
  }, [isVertical, xAxisProps]);

  const elYAxis = React.useMemo(() => {
    const verticalYAxis: YAxisProps = {
      dataKey: xAxisProps?.dataKey ?? yAxisProps?.dataKey,
      type: 'category',
      axisLine: false,
      tickLine: false,
      interval: 0,
      tick: xAxisProps?.tick,
      width: yAxisProps?.width,
      dy: -1,
    };

    const props = isVertical ? verticalYAxis : yAxisProps;
    return props ? <YAxis {...props} /> : undefined;
  }, [isVertical, xAxisProps?.dataKey, xAxisProps?.tick, yAxisProps]);

  const elToolTip = tooltipProps ? <Tooltip {...tooltipProps} /> : undefined;

  const elLegend = legendProps ? <Legend {...legendProps} /> : undefined;

  const elDefaultDefs = (
    <>
      <linearGradient
        id={'barFill-default'}
        x1={'0'}
        y1={'0'}
        x2={'0'}
        y2={'1'}
      >
        <stop offset={'5%'} stopColor={CHART_COLORS.BLUE} stopOpacity={1.0} />
        <stop offset={'95%'} stopColor={CHART_COLORS.BLUE} stopOpacity={0.65} />
      </linearGradient>
    </>
  );

  const elBarColors: JSX.Element[] = [];

  const elBars = barsProps?.map((item, index) => {
    const barFillId = `barFill-${id}-${index}`;
    const { isHighlighted = false, ...barProps } = item;
    if (item.color) {
      elBarColors.push(
        <linearGradient
          id={barFillId}
          key={barFillId}
          x1={'0'}
          y1={'0'}
          x2={'0'}
          y2={'1'}
        >
          <stop offset={'5%'} stopColor={item.color} stopOpacity={1.0} />
          <stop offset={'95%'} stopColor={item.color} stopOpacity={0.65} />
        </linearGradient>,
      );
    }
    return (
      <Bar
        key={`bar-${index}`}
        fill={
          isHighlighted
            ? (item.color ?? CHART_COLORS.DARK_GREY)
            : item.color
              ? `url(#${barFillId})`
              : 'url(#barFill-default)'
        }
        isAnimationActive={true}
        {...barProps}
      />
    );
  });

  const elLines = linesProps?.map((item, index) => (
    <Line key={`line-${index}`} isAnimationActive={true} {...item} />
  ));

  return (
    <>
      <ResponsiveContainer debounce={1}>
        <RechartsComposedChart {...chartProps} data={data}>
          <defs>
            {elDefaultDefs}
            {elBarColors}
          </defs>
          <CartesianGrid stroke={color.format(-0.04)} />
          {elXAxis}
          {elYAxis}
          {elToolTip}
          {elLegend}
          {elBars}
          {elLines}
        </RechartsComposedChart>
      </ResponsiveContainer>
    </>
  );
};
