/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Skeleton } from '@seeeverything/ui.primitives/src/components/Skeleton/Skeleton.tsx';

export const CheckboxGroupSkeleton: React.FC = () => (
  <div css={styles.base}>
    <div css={styles.row}>
      <Skeleton height={20} width={20} variant={'rect'} />
      <Skeleton height={20} width={'85%'} variant={'rect'} />
    </div>
    <div css={styles.row}>
      <Skeleton height={20} width={20} variant={'rect'} />
      <Skeleton height={20} width={'85%'} variant={'rect'} />
    </div>
    <div css={styles.row}>
      <Skeleton height={20} width={20} variant={'rect'} />
      <Skeleton height={20} width={'85%'} variant={'rect'} />
    </div>
  </div>
);

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '15px 0px',
    padding: '15px 0',
  }),
  row: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '0px 6px',
  }),
};
