import { ReduxAction } from '@seeeverything/ui.util/src/redux/types.ts';
import { combineEpics } from 'redux-observable';
import { epics as appEpics } from './app/epics.ts';
import { epics as queryEpics } from './query/epics.ts';
import { sheetSchedulesEpics } from './sheetSchedules/sheetSchedulesEpics.ts';
import { epics as sheetsEpics } from './sheets/epics.ts';
import { ShellState } from './types.ts';

export const shellRootEpic = combineEpics<ReduxAction, ReduxAction, ShellState>(
  appEpics,
  queryEpics,
  sheetsEpics,
  sheetSchedulesEpics,
);
