import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { toPercentageString } from '@seeeverything/ui.util/src/value/value.ts';
import moment from 'moment';
import { includes, is, reverse } from 'ramda';
import { DashboardV2Template } from '../data/types.ts';
import {
  IDashboardComponentsState,
  IDashboardV2ComponentState,
} from '../redux/types.ts';
import {
  DashboardComponent,
  IDashboardContentMapping,
  IGridCellDatapoint,
} from '../types.ts';

export function formatPercentageString(
  percentage: number | '',
  precision?: number,
) {
  return percentage === ''
    ? '-'
    : toPercentageString(percentage / 100, precision);
}

export interface IFormatDateStringOptions {
  format?: string;
  defaultValue?: string;
}
export function formatDateString(
  date: string,
  options: IFormatDateStringOptions = {},
) {
  const { format = 'D MMM YY, h:mm a', defaultValue } = options;
  return date ? moment(date).format(format) : defaultValue;
}

export function formatEnumString(value?: string) {
  if (!value) return;
  return americanize(str.humanize(value, true));
}

const americanize = (value: string) =>
  value === 'Cancelled' ? 'Canceled' : value;

/**
 * Locates the parent section ID for a dashboard item, given the dashboard items id.
 */
export const parentSectionIdFromState = (
  state: IDashboardV2ComponentState,
  childItemId: string,
) =>
  findItemInState(state, childItemId) ??
  findItemFromCachedComponents(state, childItemId);

const findItemInState = (
  state: IDashboardV2ComponentState,
  childItemId: string,
): string | undefined => {
  const dashboardItem =
    state.CHART_TOOLS[childItemId] ??
    state.CHART[childItemId] ??
    state.CLICK_THROUGH_GRID[childItemId] ??
    state.COMMENTS_LIST[childItemId] ??
    state.GRID_TOOLS[childItemId] ??
    state.GRID[childItemId] ??
    state.GOALS_AND_ACTIONS[childItemId];

  if (!dashboardItem) return undefined;

  return dashboardItem
    ? (reverse(Object.values(state.SECTION)).find(
        (section) => section.index < dashboardItem.index,
      )?.id ?? '')
    : undefined;
};

const findItemFromCachedComponents = (
  state: IDashboardV2ComponentState,
  childItemId: string,
): string | undefined => {
  const cachedTemplates = Object.values(state.cachedComponents ?? {});
  if (!cachedTemplates.length) return undefined;

  type MatchedTemplate = {
    template: IDashboardComponentsState & { template?: DashboardV2Template };
    dashboardItem: DashboardComponent;
  };
  const matchedTemplate = cachedTemplates.reduce(
    (result, template): MatchedTemplate | undefined => {
      const dashboardItem =
        template.CHART_TOOLS?.[childItemId] ??
        template.CHART?.[childItemId] ??
        template.CLICK_THROUGH_GRID?.[childItemId] ??
        template.COMMENTS_LIST?.[childItemId] ??
        template.GRID_TOOLS?.[childItemId] ??
        template.GRID?.[childItemId] ??
        template.GOALS_AND_ACTIONS?.[childItemId];

      return dashboardItem ? { template, dashboardItem } : result;
    },
    undefined as MatchedTemplate,
  );

  if (!matchedTemplate) return undefined;

  const { template, dashboardItem } = matchedTemplate;

  const sections = template.SECTION;
  if (!sections) return undefined;

  return (
    reverse(Object.values(sections)).find(
      (section) => section.index < dashboardItem.index,
    )?.id ?? ''
  );
};

/**
 * Tries to find a suitable parent for the given child item.
 */
export const parentSectionIdFromItems = (
  items: DashboardComponent[],
  childItemId: string,
) => {
  const childItem = items.find((item) => item.id === childItemId);
  if (!childItem || isParent(childItem)) return undefined;

  const reversedItems = reverse(items);
  const childItemIndex = reversedItems.findIndex(
    (item) => item.id === childItemId,
  );
  const previousItemsToChild = reversedItems.slice(childItemIndex);
  return firstItemWithIndex(previousItemsToChild)?.id;
};

const isParent = (item: DashboardComponent) => item.componentType === 'SECTION';

const firstItemWithIndex = (items: DashboardComponent[]) => {
  if (!items || !items.length) return undefined;
  return items.find(isParent);
};

/**
 * Locates a matching content map for target values
 */
export function matchTargetValue(
  contentMappings: IDashboardContentMapping[],
  dataPoint: IGridCellDatapoint,
): IDashboardContentMapping | undefined {
  const match = contentMappings.find((mapping) => {
    const {
      minValueInclusive,
      minValue,
      maxValueInclusive,
      maxValue,
      equalsValue,
      rowTag,
    } = mapping;

    if (
      equalsValue !== undefined &&
      matchTargetExactValue(dataPoint.value, equalsValue)
    ) {
      return true;
    }

    if (rowTag && dataPoint.rowTags && includes(rowTag, dataPoint.rowTags)) {
      return true;
    }

    // All max and min value mappings require a number value.
    const asNumber = parseFloat(dataPoint.value?.toString());
    if (!is(Number, asNumber) || isNaN(asNumber)) {
      return false;
    }

    if (minValueInclusive === undefined && minValue === undefined) {
      // Doesn't have a valid min number.
      return false;
    }

    if (maxValueInclusive === undefined && maxValue === undefined) {
      // Doesn't have a valid max number.
      return false;
    }

    if (minValueInclusive !== undefined && asNumber < minValueInclusive) {
      // Less than minimum allowed (inclusive).
      return false;
    }

    if (minValue !== undefined && asNumber <= minValue) {
      // Less than minimum allowed.
      return false;
    }

    if (maxValueInclusive !== undefined && asNumber > maxValueInclusive) {
      // Less than maximum allowed (inclusive).
      return false;
    }

    if (maxValue !== undefined && asNumber >= maxValue) {
      // Less than minimum allowed.
      return false;
    }

    return true;
  });

  return match;
}

function matchTargetExactValue(value: any, targetValues: string | string[]) {
  return (
    (is(Array, targetValues) && includes(value, targetValues as string[])) ||
    value === targetValues
  );
}
