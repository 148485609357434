export const CHART_COLORS = {
  BLUE: '#88B7EF',
  ORANGE: '#F6A623',
  GREEN: '#229922',
  GREY: '#DDDDDD',
  MID_GREY: '#a0a0a0',
  DARK_GREY: '#808080',
};

export const IS_DEV =
  typeof window !== 'undefined'
    ? window.location.hostname === 'localhost'
    : false;
