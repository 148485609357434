import { FormsUserPermissions } from '@se/data/forms/types.ts';
import {
  AppErrorStateAction,
  AppInitializedAction,
  AppInitializePermissionsAction,
  AppInitializingAction,
  AppLoggingOutAction,
  AppLogoutAction,
  AppReturnHomeAction,
  AppShowChangePasswordDialogAction,
} from './types.ts';

export function returnHome(): AppReturnHomeAction {
  return {
    type: 'ui.shell/app/RETURN_HOME',
    payload: {},
  };
}

export function loggingOut(): AppLoggingOutAction {
  return {
    type: 'ui.shell/app/LOGGING_OUT',
    payload: {},
  };
}

export function logout(): AppLogoutAction {
  return {
    type: 'ui.shell/app/LOG_OUT',
    payload: {},
  };
}

export function errorState(): AppErrorStateAction {
  return {
    type: 'ui.shell/app/ERROR_STATE',
    payload: {},
  };
}

export function initializing(): AppInitializingAction {
  return {
    type: 'ui.shell/app/INITIALIZING',
    payload: {},
  };
}

export function initialized(): AppInitializedAction {
  return {
    type: 'ui.shell/app/INITIALIZED',
    payload: {},
  };
}

export function showChangePasswordDialog(): AppShowChangePasswordDialogAction {
  return {
    type: 'ui.shell/app/SHOW_CHANGE_PASSWORD_DIALOG',
    payload: {},
  };
}

export function initializePermissions(
  permissions: FormsUserPermissions,
): AppInitializePermissionsAction {
  return {
    type: 'ui.shell/app/INITIALIZE_PERMISSIONS',
    payload: { permissions },
  };
}
