import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/client/types.ts';

type UpdateDigitalContentPackResponse = {
  digitalContent: {
    updateDigitalContentPack: {
      ok: boolean;
    };
  };
};

export interface IUpdateDigitalContentPackResult {
  success: boolean;
  exception?: any;
}

export interface UpdateDigitalContentPackInputs {
  id: string;
  commentsEnabled: boolean;
  commentGuidance?: string;
  description: string;
  summary: string;
  publishDate?: string;
}

export async function updateDigitalContentPack(
  client: IGraphQLClient,
  inputs: UpdateDigitalContentPackInputs,
): Promise<IUpdateDigitalContentPackResult> {
  const {
    id,
    commentsEnabled,
    commentGuidance,
    description,
    summary,
    publishDate,
  } = inputs;
  try {
    const response = await client.mutate<UpdateDigitalContentPackResponse>({
      mutation,
      variables: {
        id,
        commentsEnabled,
        commentGuidance,
        description,
        publishDate,
        summary,
      },
    });

    return succeeded(response?.data as UpdateDigitalContentPackResponse)
      ? { success: true }
      : fail(response.errors);
  } catch (error) {
    return fail(error);
  }
}

const succeeded = (data: UpdateDigitalContentPackResponse) =>
  data?.digitalContent?.updateDigitalContentPack?.ok === true;

const fail = (exception: any) => {
  log.error(
    `Error while updating digital content pack: ${exception}.`,
    exception,
  );

  return {
    success: false,
    exception,
  };
};

const mutation = gql`
  mutation UpdateDigitalContentPack(
    $id: ID!
    $commentsEnabled: Boolean!
    $commentGuidance: String
    $description: String!
    $publishDate: DateTime
    $summary: String!
  ) {
    digitalContent {
      updateDigitalContentPack(
        input: {
          id: $id
          commentsEnabled: $commentsEnabled
          commentGuidance: $commentGuidance
          description: $description
          publishDate: $publishDate
          summary: $summary
        }
      ) {
        ok
      }
    }
  }
`;
