/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { Tooltip } from '@seeeverything/ui.primitives/src/components/Tooltip/Tooltip.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { FontWeight } from '@seeeverything/ui.util/src/types.ts';
import { is } from 'ramda';
import React from 'react';
import { ILegendPayloadProps } from '../../types.ts';
import { LegendIcons } from '../types.ts';

export interface ICustomLegendItemProps {
  fontSize?: number;
  fontWeight?: FontWeight;
  textColor?: string;
  icons?: LegendIcons;
  shouldReverseItems?: boolean;
  shouldSortAlphabetically?: boolean;
  inactiveKeys?: string[];
  item: ILegendPayloadProps;
  isActive: boolean;
  onClick?: (dataKey: string) => void;
  onMouseEnter?: (dataKey: string) => void;
  onMouseLeave?: (dataKey: string) => void;
}

export const LegendItem: React.FC<ICustomLegendItemProps> = ({
  item,
  fontSize = 14,
  fontWeight = FontWeight.bold,
  textColor = color.format(-0.5),
  icons = 'team',
  isActive = true,
  ...rest
}) => {
  const onClick = () => rest.onClick?.(item.dataKey);
  const onMouseEnter = () => rest.onMouseEnter?.(item.dataKey);
  const onMouseLeave = () => rest.onMouseLeave?.(item.dataKey);

  const hasClickHandling = Boolean(rest.onClick);

  const toIcon = (key: string, defaultIcon: string): string => {
    if (is(String, icons)) return icons as string;
    return icons[key] ?? defaultIcon;
  };

  const icon = toIcon(item.dataKey, 'team');

  const Icon = Icons[icon];

  const elInner = (
    <div
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      css={styles.inner(isActive, hasClickHandling)}
    >
      <Icon fill={item.color} />
      <Text
        marginLeft={3}
        marginRight={20}
        weight={fontWeight}
        size={fontSize}
        color={textColor}
        selectable={false}
        cursor={'inherit'}
        style={hasClickHandling ? styles.textHover(textColor) : undefined}
      >
        {item.value}
      </Text>
    </div>
  );

  return hasClickHandling ? (
    <Tooltip
      title={`Click to ${isActive ? 'hide from' : 'show on'} the above chart.`}
    >
      {elInner}
    </Tooltip>
  ) : (
    elInner
  );
};

const styles = {
  textHover: (borderBottomColor?: string) =>
    borderBottomColor &&
    css({
      ':hover': {
        borderBottom: `solid 1px ${borderBottomColor}`,
      },
    }),

  inner: (isActive: boolean, hasClickHandling: boolean) =>
    css({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: isActive ? 1 : 0.4,
      ':hover': {
        cursor: hasClickHandling ? 'pointer' : undefined,
      },
    }),
};
