/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { TextField } from '@seeeverything/ui.primitives/src/components/TextField/TextField.tsx';
import { TextFieldDropdown } from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import React from 'react';
import { Bullet } from '../Bullet/index.ts';
import { DesignerFormParticipant, IDesignerCommonProps } from '../types.ts';
import { handleChangeEvent, handleEventOccurred } from '../util.ts';

export interface IDesignerDropdownProps extends IDesignerCommonProps {
  inputProps?: DesignerFormParticipant;
  dropdownLabel?: string;
  dropdownTypes?: ISelectionListItem[];
}

export class DesignerDropdown extends React.Component<
  IDesignerDropdownProps,
  object
> {
  public render() {
    const defaultInputProps: DesignerFormParticipant = {
      label: '',
      kind: 'people',
      canChangeKind: false,
    };
    const {
      id,
      inputProps = defaultInputProps,
      dropdownLabel = 'Participant Label',
      canDelete,
      showBoundaryLines,
      dropdownTypes,
      style,
      showBullet = true,
      onChange,
      onEventOccurred,
      error,
    } = this.props;

    const computedStyles = {
      base: css({
        border: showBoundaryLines
          ? `dashed 1px ${color.format(-0.2)}`
          : 'solid 1px rgba(0, 0, 0, 0)',
        borderRadius: showBoundaryLines ? 8 : undefined,
      }),
    };

    const elDropdownContent = (
      <div css={styles.contentOuter}>
        <TextField
          id={`designer-dropdown-title-${id}`}
          onChange={handleChangeEvent(`${id}.label`, 'LABEL', onChange)}
          label={dropdownLabel}
          style={styles.fullWidth}
          value={inputProps.label}
          error={error}
        />
        {inputProps.canChangeKind && (
          <>
            <div css={styles.height} />
            <TextFieldDropdown
              id={`designer-dropdown-type-${id}`}
              onChange={handleChangeEvent(
                `${id}.dropdown`,
                'DROPDOWN',
                onChange,
                undefined,
                { preventText: true, toLowerCase: true },
              )}
              label={'Type'}
              style={styles.fullWidth}
              value={
                inputProps.kind &&
                dropdownTypes?.find(
                  (dropdown) =>
                    (dropdown.value as string)?.toLowerCase() ===
                    inputProps.kind,
                )
              }
              selections={dropdownTypes}
            />
          </>
        )}
      </div>
    );

    const elDelete = canDelete && (
      <div title={'Remove Question'}>
        <Icons.delete
          onClick={handleEventOccurred(id, 'DELETE', onEventOccurred)}
          cursor={'pointer'}
          fill={color.format(-0.4)}
          size={20}
        />
      </div>
    );

    const elOuter = (
      <div css={styles.content}>
        <div css={styles.editIcons}>{elDelete}</div>
        {elDropdownContent}
      </div>
    );

    return (
      <div css={[styles.outer, style]}>
        {showBullet && <Bullet />}
        <div css={[styles.base, computedStyles.base]}>{elOuter}</div>
      </div>
    );
  }
}

const styles = {
  outer: css({
    position: 'relative',
  }),
  base: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 112,
    padding: '8px 20px 6px 10px',
    marginBottom: 5,
  }),
  height: css({ height: 15 }),
  contentOuter: css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }),
  content: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  }),
  editIcons: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingRight: 30,
  }),
  fullWidth: css({
    width: '100%',
  }),
};
