/** @jsxImportSource @emotion/react */
import { DropdownListContainer } from '@seeeverything/ui.shell/src/components/QueryBuilder.DropdownList/DropdownListContainer.tsx';
import { IQueryDropdownProps } from '@seeeverything/ui.shell/src/api/api.queryBuilder/types.ts';

export function dropdownFactory(props: IQueryDropdownProps) {
  if (props.type !== 'LIST') {
    return undefined;
  }

  return <DropdownListContainer {...props} />;
}
