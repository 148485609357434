import * as R from 'ramda';
import {
  ISelectionListItem,
  ISelectionListColumn,
} from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';

export type MapColumnsHandler = (
  item: ISelectionListItem,
) => ISelectionListItem | undefined;

/**
 * Runs a map operation across all items in all columns.
 *    Map handler function that return `undefined` will cause
 *    items to be removed from the list.
 */
export function mapColumns(
  func: MapColumnsHandler,
  columns: ISelectionListColumn[],
) {
  return columns.map((column) => {
    column = { ...column };
    column.items = R.reject(
      R.isNil,
      column.items.map((item) => func(item)),
    );
    return column;
  });
}

export interface IColumnItemResult {
  item: ISelectionListItem;
  column: number;
}
export type ColumnItemHandler = (
  item: ISelectionListItem,
) => ISelectionListItem | undefined | false;

/**
 * Finds the first item within a set of columns that
 * matches the given function tester.
 */
export function findInColumns(
  func: ColumnItemHandler,
  columns: ISelectionListColumn[] = [],
): IColumnItemResult | undefined {
  let index = -1;
  for (const column of columns) {
    index++;
    const item = column.items.find((columnItem) => Boolean(func(columnItem)));
    if (item) {
      return { item, column: index };
    }
  }
  return;
}

/**
 * Performs a deep search of a column looking for a match on an item.
 */
export function findInColumn(
  func: ColumnItemHandler,
  column: ISelectionListColumn | undefined,
  columnIndex = -1,
): { item: ISelectionListItem; column: number } | undefined {
  if (!column) {
    return;
  }

  columnIndex++;

  // Look directly in the column.
  let item = column.items.find((columnItem) => Boolean(func(columnItem)));
  if (item) {
    return { item, column: columnIndex };
  }

  // Check children.
  for (item of column.items) {
    if (item.children) {
      const childItem = findInColumn(func, item.children, columnIndex); // <== RECURSION.
      if (childItem) {
        return { item: childItem.item, column: columnIndex };
      }
    }
  }

  // Not found.
  return;
}

/**
 * Filter down on a set of items within a group of columns.
 */
export function filterColumns(
  func: ColumnItemHandler,
  columns: ISelectionListColumn[] = [],
) {
  const result: IColumnItemResult[] = [];
  columns.forEach((column, i) => {
    column.items.forEach((columnItem) => {
      const item = func(columnItem);
      if (item) {
        result.push({ item, column: i });
      }
    });
  });
  return result;
}

/**
 * Finds the first item within a set of columns that
 * has a label "fuzzy" matches the given filter string.
 */
export function firstFuzzyMatch(
  filter: string | undefined,
  columns: ISelectionListColumn[] = [],
) {
  return findInColumns(
    (item) =>
      isFuzzyMatch(item.content, filter) &&
      item.type !== 'SECTION' &&
      item.type !== 'DIVIDER'
        ? item
        : undefined,
    columns,
  );
}

/**
 * Filters down on all items within a set of columns that
 * have labels that "fuzzy" matches the given filter.
 */
export function fuzzyMatches(
  filter: string | undefined,
  columns: ISelectionListColumn[] = [],
) {
  return filterColumns(
    (item) => (isFuzzyMatch(item.content, filter) ? item : undefined),
    columns,
  );
}

/**
 * Performs a fuzzy string match.
 */
export const isFuzzyMatch = (text?: any, filter?: string) => {
  if (!filter || !text || !R.is(String, text)) {
    return false;
  }
  return str.isFuzzyMatch(filter, text);
};
