import gql from 'graphql-tag';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';

type CreateDigitalContentPackResponse = {
  digitalContent: {
    createDigitalContentPack: {
      ok: boolean;
    };
  };
};

export interface ICreateDigitalContentPackResult {
  success: boolean;
  exception?: any;
}

export async function createDigitalContentPack(
  client: IGraphQLClient,
  id: string,
  commentsEnabled: boolean,
  commentGuidance: string,
  description: string,
  summary: string,
): Promise<ICreateDigitalContentPackResult> {
  try {
    const response = await client.mutate<CreateDigitalContentPackResponse>({
      mutation,
      variables: {
        id,
        commentsEnabled,
        commentGuidance,
        description,
        summary,
      },
    });

    return succeeded(response?.data as CreateDigitalContentPackResponse)
      ? { success: true }
      : fail(response.errors);
  } catch (error) {
    return fail(error);
  }
}

const succeeded = (data: CreateDigitalContentPackResponse) =>
  data?.digitalContent?.createDigitalContentPack?.ok;

const fail = (exception: any) => {
  log.error(
    `Error while creating digital content pack: ${exception}.`,
    exception,
  );

  return {
    success: false,
    exception,
  };
};

const mutation = gql`
  mutation CreateDigitalContentPack(
    $id: ID!
    $commentsEnabled: Boolean!
    $commentGuidance: String
    $description: String!
    $summary: String!
  ) {
    digitalContent {
      createDigitalContentPack(
        input: {
          id: $id
          commentsEnabled: $commentsEnabled
          commentGuidance: $commentGuidance
          description: $description
          summary: $summary
        }
      ) {
        ok
      }
    }
  }
`;
