/** @jsxImportSource @emotion/react */
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { css } from '@emotion/react';
import { Markdown } from '@seeeverything/ui.primitives/src/components/Markdown/Markdown.tsx';

export interface IEntryMarkdownContentProps {
  markdown: string;
}

export const EntryMarkdownContent: React.FC<IEntryMarkdownContentProps> = ({
  markdown,
}) => (
  <div css={styles.base}>
    <Markdown text={markdown} style={styles.content} />
  </div>
);

const styles = {
  base: css({
    boxSizing: 'border-box',
    padding: '17px 20px 40px 60px',
  }),
  content: css({
    color: color.format(-0.5),
    fontSize: '18px',
  }),
};
