import { IDashboardSection } from '../types.ts';

let id = 0;

/**
 * Attempts to parse a grid.
 */
export function parser(
  item: any,
  index: number,
): IDashboardSection | undefined {
  if (!item.section) {
    return;
  }

  return {
    componentType: 'SECTION',
    id: item.section.id ?? item.section.title ?? `section-${id++}`,
    title: item.section.title,
    index,
    level: item.section.level || 'h1',
    icon: item.section.icon,
    showBullet: item.section.showBullet !== false,
    dataState: {
      isLoaded: false,
      isLoading: false,
      forceReload: false,
      dataSetId: '',
    },
  };
}
