import { IDashboardGoalsAndActions } from '../types.ts';

/**
 * Attempts to parse values to construct a goals and actions grid
 */
export function parser(
  item: any,
  index: number,
): IDashboardGoalsAndActions | undefined {
  const goalsAndActions = item['goals-and-actions-grid'];
  if (!goalsAndActions) return;

  return {
    id: goalsAndActions.id ?? 'goals-and-actions-grid',
    index,
    componentType: 'GOALS_AND_ACTIONS',
    dataState: {
      dataSetId: '',
      isLoaded: false,
      isLoading: false,
      forceReload: false,
      data: {},
    },
  };
}
