import gql from 'graphql-tag';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/client/types.ts';

type ActivateDigitalContentPackResponse = {
  digitalContent: {
    activateDigitalContentPack: {
      ok: boolean;
    };
  };
};
export interface IActivateDigitalContentPackResult {
  success: boolean;
  exception?: any;
}

export async function activateDigitalContentPack(
  client: IGraphQLClient,
  id: string,
): Promise<IActivateDigitalContentPackResult> {
  try {
    const response = await client.mutate<ActivateDigitalContentPackResponse>({
      mutation,
      variables: { id },
    });

    return succeeded(response?.data as ActivateDigitalContentPackResponse)
      ? { success: true }
      : fail(response.errors);
  } catch (error) {
    return fail(error);
  }
}

const succeeded = (data: ActivateDigitalContentPackResponse) =>
  data?.digitalContent?.activateDigitalContentPack?.ok;

const fail = (error: any) => {
  log.error(`Error while activating digital content pack: ${error}.`, error);

  return {
    success: false,
    error,
  };
};

const mutation = gql`
  mutation ActivateDigitalContentPack($id: ID!) {
    digitalContent {
      activateDigitalContentPack(id: $id) {
        ok
      }
    }
  }
`;
