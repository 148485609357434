/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Spinner } from '@seeeverything/ui.primitives/src/components/Spinner/index.ts';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import React from 'react';

export interface IDropdownProps {
  children?: React.ReactNode;
  left?: number;
  top?: number;
  width?: number;
  height?: number;
  padding?: number | string;
  isLoading?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onMouseDown?: () => void;
  onHidden?: () => void;
}

/**
 * Standard styling for a dropdown.
 */
export const Dropdown: React.FC<IDropdownProps> = ({
  left = 0,
  top = 0,
  padding,
  isLoading,
  width,
  height,
  onMouseDown,
  onHidden,
  children,
  onMouseEnter,
  onMouseLeave,
}) => {
  React.useEffect(() => () => onHidden?.());

  const handleMouseDown = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation(); // NB: Ensure that the QueryBuilder does not react to bubbled events within dropdown.
      onMouseDown?.();
    },
    [onMouseDown],
  );

  const computedStyles = {
    base: css({
      boxSizing: 'border-box',
      position: 'absolute',
      marginRight: 7,
      backgroundColor: '#fff',
      borderTop: 'none',
      boxShadow: `0 2px 4px ${color.format(-0.15)}, 0 0 0 1px ${color.format(
        -0.15,
      )}`,
      display: 'flex',
      left,
      top,
      width,
      height,
      padding,
      minHeight: 42,
    }),
  };

  return (
    <div
      data-test={'shell-dropdown-queryBuilderDropdown'}
      css={computedStyles.base}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={handleMouseDown}
    >
      {isLoading ? <Spinner center={true} /> : children}
    </div>
  );
};
