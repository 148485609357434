import * as R from 'ramda';
import { ChipKey, IChip } from '../../../../api/api.queryBuilder/types.ts';
import { IInternalState } from '../../types.ts';

/**
 * SELECTORS
 * =============
 *
 * This file contains selectors for the Query Builder state.
 * A selector is something which takes some state (or a sub-set of), and returns some information about that state.
 * Side-effects are not allowed in selectors.
 * Selectors should take state as the data parameter as much as possible. This makes composition easier.
 * The data parameter is ALWAYS the first parameter.
 *
 */

/**
 * Return a chip given a key, if it exists, from the current state.
 * @param state the Query Builder state
 * @param the key of the chip to find
 */
export function getChipForKey(
  state: IInternalState,
  key: ChipKey | undefined,
): IChip | undefined {
  if (key === undefined) return;
  return state.chips.find((chip) => chip.key === key);
}

/**
 * Get the selected chip from the state
 * @param state the Query Builder state
 */
export function getSelectedChip(state: IInternalState): IChip | undefined {
  return getChipForKey(state, state.selectedChip && state.selectedChip.key);
}

/**
 * Get the current editing chip from the state
 * @param state the Query Builder state
 */
export function getEditingChip(state: IInternalState): IChip | undefined {
  return getChipForKey(state, state.editingChip && state.editingChip.key);
}

/**
 * Get the current active chip from the state (editing first, then fall back to selected)
 * @param state the Query Builder state
 */
export function getActiveChip(state: IInternalState): IChip | undefined {
  return getEditingChip(state) || getSelectedChip(state);
}

/**
 * Find the chip (if any) before the target chip.
 * @param state the Query Builder state
 * @param key the key of the chip to search before
 */
export function getChipBefore(
  state: IInternalState,
  key: ChipKey | undefined,
): IChip | undefined {
  if (state.chips.length <= 1) {
    return undefined;
  }
  const chip = getChipForKey(state, key);
  if (chip === undefined) {
    return undefined;
  }

  const indexOf = state.chips.indexOf(chip);
  if (indexOf <= 0) {
    return undefined;
  } // First chip, or chip not found.

  return state.chips[indexOf - 1];
}

/**
 * Find the chip (if any) after the target the chip
 * @param state the Query Builder state
 * @param key the key of the chip search after
 */
export function getChipAfter(
  state: IInternalState,
  key: ChipKey | undefined,
): IChip | undefined {
  if (state.chips.length <= 1) {
    return undefined;
  }
  const chip = getChipForKey(state, key);
  if (chip === undefined) {
    return undefined;
  }

  const indexOf = state.chips.indexOf(chip);
  if (indexOf === -1 || indexOf === state.chips.length - 1) {
    return undefined;
  } // Last chip, or chip not found.

  return state.chips[indexOf + 1];
}

/**
 * Get a sanitized version of the text input value.
 */
export function getRequestedTypeFromState(state: IInternalState) {
  return state.textInput.trim().toLowerCase();
}

/**
 * Check if the overall input is focused (chip, or filter input)
 * @param state the Query Builder sate
 */
export function isInputFocussed(state: IInternalState): boolean {
  // The input should only be set as NOT focused if inputBlurred is true AND all other state indicators are true.
  // For all other cases, the input should be focused (even if inputBlurred is true).
  return !(
    state.inputBlurred &&
    !(
      !R.isNil(state.editingChip) ||
      !R.isNil(state.selectedChip) ||
      state.textInputFocused
    )
  );
}
