import { ModuleType } from '@seeeverything/ui.util/src/types.ts';
import { IDashboardExtendedGridColumn, IDashboardGrid } from '../../types.ts';

const GOALS_ACTIONS_COLUMNS: IDashboardExtendedGridColumn[] = [
  {
    id: 'due-date',
    label: 'Due',
    width: '*',
    borderRight: true,
    isFilterable: false,
    isSortable: false,
    type: 'DATE',
    format: 'D MMM YY',
  },
  {
    id: 'description',
    label: 'Description',
    width: '3*',
    borderRight: true,
    canLaunch: true,
    isFilterable: false,
    isSortable: false,
  },
  {
    id: 'assignee',
    label: 'Assignee',
    width: '*',
    borderRight: true,
    isFilterable: false,
    isSortable: false,
  },
  {
    id: 'status',
    label: 'Status',
    width: '*',
    borderRight: true,
    isFilterable: false,
    isSortable: false,
    type: 'ENUM',
  },
];

const CADENCE_COLUMNS: IDashboardExtendedGridColumn[] = [
  {
    id: 'description',
    label: 'Description',
    width: '3*',
    borderRight: true,
    canLaunch: true,
    isFilterable: false,
  },
  {
    id: 'assignee',
    label: 'Assignee',
    width: '*',
    borderRight: true,
    isFilterable: true,
  },
  {
    id: 'due-date',
    label: 'Due',
    width: '*',
    borderRight: true,
    isFilterable: false,
    type: 'DATE',
    format: 'D MMM YY',
  },

  {
    id: 'status',
    label: 'Status',
    width: '*',
    borderRight: true,
    isFilterable: true,
    type: 'ENUM',
  },
];

const COACHING_COMPLIANCE_ACTIONS_COLUMNS: IDashboardExtendedGridColumn[] = [
  {
    id: 'due-date',
    label: 'Due',
    width: '*',
    borderRight: true,
    isFilterable: false,
    type: 'DATE',
    format: 'D MMM YY',
  },
  {
    id: 'description',
    label: 'Description',
    width: '3*',
    borderRight: true,
    canLaunch: true,
    isFilterable: false,
  },
  {
    id: 'assignee',
    label: 'Assignee',
    width: '*',
    borderRight: true,
    isFilterable: true,
  },
  {
    id: 'status',
    label: 'Status',
    width: '*',
    borderRight: true,
    isFilterable: true,
    type: 'ENUM',
  },
];

const getColumns = (module: ModuleType, clickThroughDataSetId: string) => {
  if (clickThroughDataSetId === 'default.grid.goals-and-actions')
    return GOALS_ACTIONS_COLUMNS;

  if (module === 'cadence') return CADENCE_COLUMNS;

  return COACHING_COMPLIANCE_ACTIONS_COLUMNS;
};

const getInitialOrderBys = (clickThroughDataSetId: string) =>
  clickThroughDataSetId === 'default.grid.goals-and-actions'
    ? []
    : [{ fieldName: 'due-date', direction: 'Ascending' as const }];

type Args = {
  sourceGridId: string;
  sourceGridDataSetId: string;
  clickThroughDataSetId: string;
  clickThroughColumnId: string;
  clickThroughRowId: string;
  gridName: string;
  module?: ModuleType;
  entityPath: string;
};

export const goalsAndActionsClickThroughGrid = ({
  sourceGridId,
  sourceGridDataSetId,
  clickThroughDataSetId,
  clickThroughColumnId,
  clickThroughRowId,
  gridName,
  module,
  entityPath,
}: Args): IDashboardGrid => ({
  componentType: 'CLICK_THROUGH_GRID',
  id: sourceGridId,
  columns: getColumns(module, clickThroughDataSetId),
  name: gridName,
  title: gridName,
  isHidden: false,
  dataState: {
    dataSetId: clickThroughDataSetId,
    isLoaded: false,
    isLoading: false,
    forceReload: false,
  },
  entityPath,
  clickThrough: {
    columnId: clickThroughColumnId,
    rowId: clickThroughRowId,
    dataSetId: sourceGridDataSetId,
  },
  group: 'FULLSCREEN_CLICK_THROUGH_GRID',
  index: 0,
  initialOrderBys: getInitialOrderBys(clickThroughDataSetId),
});
