import { IDigitalContentComment } from '@seeeverything/ui.forms/src/components/DigitalContentComments/types.ts';
import { commentsActions } from '@seeeverything/ui.forms/src/redux/form-digital-content/comments/index.ts';
import { ReduxFormDigitalContentCommentsLoad } from '@seeeverything/ui.forms/src/redux/form-digital-content/comments/types.ts';
import { ReduxFormDigitalContentLoaded } from '@seeeverything/ui.forms/src/redux/form-digital-content/types.ts';
import { log } from '@seeeverything/ui.util/src/log/log.ts';
import gql from 'graphql-tag';
import { StateObservable, combineEpics, ofType } from 'redux-observable';
import { Observable, filter, switchMap } from 'rxjs';
import { batchClient } from '../../../common/db.ts';
import {
  GlobalAppActionType,
  GlobalAppEpicDependencies,
  GlobalAppState,
} from '../../../types.ts';

export const epics = combineEpics<
  GlobalAppActionType,
  GlobalAppActionType,
  GlobalAppState,
  GlobalAppEpicDependencies
>(loadCommentsEpic, loadMoreCommentsEpic, reloadCommentsEpic);

function loadCommentsEpic(
  action$: Observable<ReduxFormDigitalContentLoaded>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.forms/digital-content/LOADED'),
    filter(
      ({
        payload: {
          content: { commentsEnabled },
        },
      }) => commentsEnabled,
    ),
    switchMap(async (action) => {
      const id = action.payload.content.id;
      const canView = Boolean(state$.value.app.permissions.templateManage);
      const userId = state$.value.tenantState.tenant.authenticatedUser.id;
      const gqlComments = await queryDigitalContentComment(id);

      const comments = gqlComments?.nodes?.map((comment) =>
        toDigitalContentComment(comment, canView, userId),
      );

      return comments
        ? commentsActions.digitalContentCommentsLoaded(
            comments,
            gqlComments?.pageInfo.hasNextPage,
          )
        : commentsActions.digitalContentCommentsLoadError(
            'Something went wrong while loading comments for this content. Click here to retry.',
            'LOAD',
          );
    }),
  );
}

function reloadCommentsEpic(
  action$: Observable<ReduxFormDigitalContentCommentsLoad>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.forms/digital-content/comments/LOAD'),
    filter(({ payload: { reason } }) =>
      Boolean(reason === 'LOAD' && state$.value.formDigitalContent.contentMeta),
    ),
    switchMap(async () => {
      const contentMeta = state$.value.formDigitalContent.contentMeta;
      const canView = Boolean(state$.value.app.permissions.templateManage);
      const userId = state$.value.tenantState.tenant.authenticatedUser.id;

      const gqlComments = await queryDigitalContentComment(contentMeta.id);

      const comments = gqlComments?.nodes?.map((comment) =>
        toDigitalContentComment(comment, canView, userId),
      );

      return comments
        ? commentsActions.digitalContentCommentsLoaded(
            comments,
            gqlComments?.pageInfo.hasNextPage,
          )
        : commentsActions.digitalContentCommentsLoadError(
            'Something went wrong while loading the comments for this content. Click here to retry.',
            'LOAD',
          );
    }),
  );
}

function loadMoreCommentsEpic(
  action$: Observable<ReduxFormDigitalContentCommentsLoad>,
  state$: StateObservable<GlobalAppState>,
) {
  return action$.pipe(
    ofType('ui.forms/digital-content/comments/LOAD'),
    filter(({ payload: { reason } }) =>
      Boolean(
        reason === 'LOAD_MORE' && state$.value.formDigitalContent.contentMeta,
      ),
    ),
    switchMap(async () => {
      const { contentMeta, comments } = state$.value.formDigitalContent;
      const canView = Boolean(state$.value.app.permissions.templateManage);
      const userId = state$.value.tenantState.tenant.authenticatedUser.id;
      const nextPage = comments?.pagination?.currentPage
        ? comments.pagination.currentPage + 1
        : 1;
      const gqlComments = await queryDigitalContentComment(
        contentMeta.id,
        nextPage,
      );

      const existingComments = comments?.comments || [];

      const moreComments = gqlComments?.nodes
        ?.filter(
          (comment) =>
            !existingComments.find(
              (existingComment) => existingComment.id === comment.id,
            ),
        )
        .map((comment) => toDigitalContentComment(comment, canView, userId));

      return moreComments
        ? commentsActions.digitalContentCommentsLoaded(
            [...existingComments, ...moreComments],
            gqlComments?.pageInfo.hasNextPage,
            nextPage,
          )
        : commentsActions.digitalContentCommentsLoadError(
            'Something went wrong while loading more comments for this content. Click here to retry.',
            'LOAD_MORE',
          );
    }),
  );
}

const toDigitalContentComment = (
  comment: IGraphQLContentComment,
  canDelete = false,
  userId: string,
  parentId?: string,
): IDigitalContentComment => ({
  id: comment.id,
  body: comment.body,
  canDelete: canDelete || userId === comment.createdBy.id,
  canReply: true,
  commentedAt: comment.createdAt,
  commentedBy: `${comment.createdBy.firstName} ${comment.createdBy.lastName}`,
  team: comment.team?.name,
  isDeleted: comment.isDeleted,
  parentId,
  replies: comment.replies?.map((reply) =>
    toDigitalContentComment(reply, canDelete, userId, comment.id),
  ),
});

const queryDigitalContentComment = async (packId: string, pageNumber = 1) => {
  try {
    const response = await batchClient.query<IGraphQLContentCommentsResponse>({
      query: getDigitalContentComments,
      variables: { packId, pageNumber },
      fetchPolicy: 'network-only',
    });
    return response.data.digitalContent.contentPackComments;
  } catch (error) {
    log.error(`GraphQL Error: Error loading digital content comments for ${packId}
     ${error}`);
    return;
  }
};

const getDigitalContentComments = gql`
  query DigitalContentPackComments($packId: ID!, $pageNumber: Int!) {
    digitalContent {
      contentPackComments(
        pagination: { pageNumber: $pageNumber, size: 10 }
        contentPackId: $packId
      ) {
        pageInfo {
          hasNextPage
        }
        nodes {
          id
          body
          createdAt
          createdBy {
            id
            firstName
            lastName
          }
          replies {
            id
            body
            createdAt
            createdBy {
              id
              firstName
              lastName
            }
            team {
              name
            }
            isDeleted
          }
          team {
            name
          }
        }
      }
    }
  }
`;

interface IGraphQLContentComment {
  id: string;
  body: string;
  createdAt: string;
  createdBy: {
    id: string;
    firstName: string;
    lastName: string;
  };
  team?: {
    name: string;
  };
  replies?: IGraphQLContentComment[];
  isDeleted?: boolean;
}

interface IGraphQLContentCommentsResponse {
  digitalContent: {
    contentPackComments: {
      pageInfo: {
        hasNextPage: boolean;
      };
      nodes?: IGraphQLContentComment[];
    };
  };
}
