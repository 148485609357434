/** @jsxImportSource @emotion/react */
import { FormIssue } from '@se/data/forms/types.ts';
import { isLineVisible } from '@seeeverything/ui.forms/src/hooks/util.ts';
import {
  FormAnswerByKey,
  FormLineById,
} from '@seeeverything/ui.forms/src/redux/form-instance/types.ts';
import { appealSlice } from '@seeeverything/ui.forms/src/redux/formInstanceAppeal/index.ts';
import { issueSlice } from '@seeeverything/ui.forms/src/redux/issue/index.ts';
import {
  FormLineComponent,
  IIssueCoachingPlanLine,
  ISectionLine,
  ISignoffLine,
} from '@seeeverything/ui.forms/src/types/types.ts';
import { TenantLocale } from '@seeeverything/ui.util/src/redux/tenant/types.ts';
import { useMemo } from 'react';
import { useShellSelector } from '../../redux/store.ts';
import { ISheetIndexItem } from '../SheetIndex/types.ts';

const INDEX_LINE_TYPES = [
  'actionPlan',
  'attendance',
  'digitalContent',
  'fileUpload',
  'issueCheckIn',
  'issueCoachingPlan',
  'issueSummary',
  'section',
  'signoff',
];

export type UseSheetFormIndexItemsOptions = {
  instanceId?: string;
  lines: FormLineById;
};

export const useSheetFormIndexItems = ({
  instanceId,
  lines = {},
}: UseSheetFormIndexItemsOptions): ISheetIndexItem[] => {
  const formLines = useMemo(() => Object.values(lines), [lines]);

  const formAnswers = useShellSelector(
    (state) =>
      (instanceId && state.formInstance.instances[instanceId]?.answers) ?? {},
  );

  const formLineErrors = useShellSelector(
    (state) => state.formInstance.instances[instanceId]?.questionErrors ?? {},
  );

  const locale = useShellSelector((state) => state.tenantState.tenant.locale);
  const tenantTimezone = useShellSelector(
    (state) => state.tenantState.tenant.configuration.timezone,
  );

  const issueState = useShellSelector((state) => state.formIssue);
  const appealState = useShellSelector((state) => state.formInstanceAppeal);

  return useMemo(
    () =>
      formLines
        .filter((line) => isLineIndexVisible(line, formAnswers, tenantTimezone))
        .map((parentLine, index) =>
          generateIndexItem(
            parentLine,
            formLines,
            index === 0,
            formLineErrors,
            locale,
            issueState,
            appealState,
          ),
        )
        .filter(Boolean),
    [
      formLines,
      formAnswers,
      tenantTimezone,
      formLineErrors,
      locale,
      issueState,
      appealState,
    ],
  );
};

const isLineIndexVisible = (
  line: FormLineComponent,
  answers: FormAnswerByKey,
  tenantTimezone: string,
) => {
  if (!line) return false;
  if (!INDEX_LINE_TYPES.includes(line.type)) return false;
  if (!line.showIndex) return false;

  return isLineVisible(line, answers, tenantTimezone);
};

const issueCoachingPlanIndex = (
  line: IIssueCoachingPlanLine,
  issueState: issueSlice.State,
): ISheetIndexItem => {
  const hasIssues = issueState.issues?.length;
  if (!hasIssues) return;

  if (line.id === 'issueCoachingPlan')
    return { id: line.id, label: line.title, type: 'CHILD' };

  const noMandatoryCoaching =
    line.id === 'issueCoachingPlan-coachingRequired' &&
    issueState.issues.every(
      (issue) => issue.coaching.requirement !== 'MANDATORY',
    );
  if (noMandatoryCoaching) return;

  const noCoachingRecommendedIssues =
    line.id === 'issueCoachingPlan-coachingRecommended' &&
    issueState.issues.every(
      (issue) => issue.coaching.requirement !== 'RECOMMENDED',
    );
  if (noCoachingRecommendedIssues) return;

  const coachingPlanErrors = coachingIssueErrorCount(issueState, line.id);

  return {
    id: line.id,
    label: line.title,
    type: 'CHILD',
    error: coachingPlanErrors > 0,
    status: coachingPlanErrors > 0 ? coachingPlanErrors : undefined,
  };
};

const coachingIssueErrorCount = (
  state: issueSlice.IssueState,
  parentSectionLineId: string,
) => {
  if (!state) return 0;
  if (!parentSectionLineId) return 0;
  if (!state.issues.length) return 0;

  const validateCoachingRequired =
    parentSectionLineId === 'issueCoachingPlan-coachingRequired';

  const validateCoachingRecommended =
    parentSectionLineId === 'issueCoachingPlan-coachingRecommended';

  if (!validateCoachingRequired && !validateCoachingRecommended) return 0;

  const isCoachingRecommendedIssue = (issue: FormIssue) =>
    validateCoachingRecommended &&
    issue.coaching.requirement === 'RECOMMENDED' &&
    issue.coaching.specifyCauseAndCoaching.isSelected;

  const isCoachingRequiredIssue = (issue: FormIssue) =>
    validateCoachingRequired && issue.coaching.requirement === 'MANDATORY';

  const issues = state.issues.filter(
    (issue) =>
      isCoachingRequiredIssue(issue) || isCoachingRecommendedIssue(issue),
  );
  if (!issues?.length) return 0;

  return issues.reduce(
    (errorCount, issue) =>
      errorCount + Object.values(issue.errors.coaching).filter(Boolean).length,
    0,
  );
};

const signoffIndex = (
  line: ISignoffLine,
  appealState: appealSlice.State,
): ISheetIndexItem => {
  const signoffErrors = appealState.errors?.errorCount ?? 0;

  return {
    id: line.id,
    label: line.title,
    type: 'CHILD',
    error: signoffErrors > 0,
    status: signoffErrors > 0 ? signoffErrors : undefined,
  };
};

const generateIndexItem = (
  parentLine: FormLineComponent,
  lines: FormLineComponent[],
  isFirst: boolean,
  lineErrors: Record<string, string>,
  locale: TenantLocale,
  issueState: issueSlice.State,
  appealState: appealSlice.State,
): ISheetIndexItem => {
  if (!parentLine) return;

  if (parentLine.type === 'issueCheckIn') {
    return {
      id: parentLine.id,
      label: locale.forms.closeTheLoop.title,
      type: 'CHILD',
    };
  }

  if (parentLine.type === 'issueCoachingPlan') {
    return issueCoachingPlanIndex(parentLine, issueState);
  }

  if (parentLine.type === 'signoff') {
    return signoffIndex(parentLine, appealState);
  }

  const formErrors = getAllChildrenErrors(
    parentLine,
    lines,
    lineErrors,
    issueState,
  );

  return {
    id: parentLine.id,
    label: label(parentLine),
    type: isFirst ? 'ROOT' : 'CHILD',
    icon: isFirst ? 'clipboardTick' : undefined,
    error: formErrors > 0,
    status: formErrors || undefined,
  };
};

const label = (line: FormLineComponent) =>
  line.showIndex ? (line as ISectionLine)?.title : undefined;

const getAllChildrenErrors = (
  parentLine: FormLineComponent,
  lines: FormLineComponent[],
  lineErrors: Record<string, string>,
  issueState: issueSlice.State,
) => {
  const currentParentLineIndex = lines.findIndex(
    ({ id }) => id === parentLine.id,
  );

  const nextParentLineIndex = lines.findIndex(
    (line, index) => isParent(line) && index > currentParentLineIndex,
  );

  const endIndex = nextParentLineIndex >= 0 ? nextParentLineIndex : undefined;

  const currentParentChildLines = lines.slice(
    currentParentLineIndex + 1,
    endIndex,
  );

  const childLineErrors = currentParentChildLines.reduce(
    (sum, line) => sum + getNumErrorsForLine(line, lineErrors),
    0,
  );

  const complianceIssueErrors = complianceIssueErrorCount(
    issueState,
    parentLine.id,
  );

  return childLineErrors + complianceIssueErrors;
};

const complianceIssueErrorCount = (
  state: issueSlice.IssueState,
  parentSectionLineId: string,
) => {
  if (!state) return 0;
  if (!parentSectionLineId) return 0;
  if (!state.issues.length) return 0;

  const issues = state.issues.filter(
    (issue) => issue.parentSectionLineId === parentSectionLineId,
  );
  if (!issues?.length) return 0;

  const notesErrors = issues.reduce(
    (errorCount, issue) =>
      issue.errors.compliance.notes ? errorCount + 1 : errorCount,
    0,
  );
  const classificationErrors = issues.reduce(
    (errorCount, issue) =>
      issue.errors.compliance.classifications ? errorCount + 1 : errorCount,
    0,
  );

  return notesErrors + classificationErrors;
};

const getNumErrorsForLine = (
  line: FormLineComponent,
  lineErrors: Record<string, string>,
) => {
  if (line.type === 'inputs' && line.inputs)
    return line.inputs.reduce(
      (sum, input) => (lineErrors[input.id] ? sum + 1 : sum),
      0,
    );

  return lineErrors[line.id] ? 1 : 0;
};

const isParent = (line: FormLineComponent) => {
  if (line.type === 'actionPlan' && line.isDetachable) return false;
  return INDEX_LINE_TYPES.includes(line.type);
};
