import { StateObservable, ofType } from 'redux-observable';
import { Observable, filter, map } from 'rxjs';
import { toggleChartTool } from '../actions.ts';
import {
  DashboardActionChartToolButtonClicked,
  GlobalDashboardsState,
} from '../types.ts';

export function toggleOnChartToolsClickEpic(
  action$: Observable<DashboardActionChartToolButtonClicked>,
  state$: StateObservable<GlobalDashboardsState>,
) {
  return action$.pipe(
    ofType('ui.dashboards/dashboard/CHART_TOOLS/BUTTON_CLICKED'),
    filter((action) => action.payload.id !== 'OPEN_CHART'),
    map((action) => {
      const { id, parentId } = action.payload;
      return toggleChartTool(
        parentId,
        id.toString(),
        state$.value.tenantState.tenant.module,
      );
    }),
  );
}
