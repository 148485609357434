/** @jsxImportSource @emotion/react */
import React from 'react';
import * as R from 'ramda';
import { ISelectionListItem } from '@seeeverything/ui.primitives/src/components/SelectionList/types.ts';
import { TextFieldDropdown } from '@seeeverything/ui.primitives/src/components/TextFieldDropdown/TextFieldDropdown.tsx';
import { Switch } from '@seeeverything/ui.primitives/src/components/Switch/Switch.tsx';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { css } from '@emotion/react';

export type InputOption = {
  id: string;
  label: string;
  description: string;
  isDisabled?: boolean;
  icon?: (props?: any) => JSX.Element;
};

export interface IInputProps {
  selectionOptions: InputOption[];
  input?: ISelectionListItem;

  isToggleChecked?: boolean;

  onRememberClick?: (to: boolean) => void;
  onInputChanged?: (to: ISelectionListItem) => void;
}

export const Input: React.FC<IInputProps> = (props) => {
  const {
    selectionOptions,
    input,
    isToggleChecked = true,
    onInputChanged,
    onRememberClick,
  } = props;

  const toggleSwitch = (to: boolean) => onRememberClick?.(to);
  const textOnChange = (to: ISelectionListItem) => onInputChanged?.(to);

  return (
    <div css={styles.base}>
      <TextFieldDropdown
        id={'tenant-select'}
        onChange={textOnChange}
        selections={toSelectionListItems(selectionOptions, input)}
        value={input}
        variant={'outlined'}
        label={'Tenant'}
      />
      <Switch
        label={'Remember until switched.'}
        onChange={toggleSwitch}
        isChecked={isToggleChecked}
      />
    </div>
  );
};

function toSelectionListItems(
  selectionOptions: InputOption[],
  filter?: string | ISelectionListItem,
): ISelectionListItem[] {
  return selectionOptions
    .filter((option) => {
      if (filter && R.is(String, filter)) {
        return str.isFuzzyMatch(filter as string, [
          option.id,
          option.description,
          option.label,
        ]);
      }
      return true;
    })
    .map((option) => ({
      id: option.id,
      content: {
        text: option.label,
        description: option.description,
      },
      icon: option.icon || Icons.company,
      isEnabled: option.isDisabled !== true,
    }));
}

const styles = {
  base: css({
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-around',
    height: 120,
    padding: 20,
  }),
};
