/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CommonStyles } from '@seeeverything/ui.primitives/src/common/commonStyles.ts';
import { Button } from '@seeeverything/ui.primitives/src/components/Button/Button.tsx';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Markdown } from '@seeeverything/ui.primitives/src/components/Markdown/Markdown.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { getLocale } from '@seeeverything/ui.util/src/redux/tenant/util.ts';
import { str } from '@seeeverything/ui.util/src/str/index.ts';
import { urlDeepLinkUtil } from '@seeeverything/ui.util/src/urlDeepLink/index.ts';
import { useCallback, useMemo } from 'react';
import { useFormsSelector } from '../../../redux/store.ts';

export const ActionIssueReviewDetailsContainer: React.FC = () => {
  const configuration = useFormsSelector(
    (state) => state.tenantState.tenant.configuration,
  );

  const complianceLocale = useMemo(
    () => getLocale(configuration, 'compliance'),
    [configuration],
  );

  const complianceFormLabel = complianceLocale.label.form;
  const complianceFormSubjectLabel = complianceLocale.label.formSubject;

  const issueDetails = useFormsSelector((state) => state.formActionV2.issue);

  const tenant = useFormsSelector((state) => state.tenantState.tenant.id);

  const openInstanceUrl = useCallback(() => {
    if (!issueDetails) return;
    if (!issueDetails.instance) return;
    if (!tenant) return;

    const url = urlDeepLinkUtil.generateUrlPath({
      tenant,
      module: 'compliance',
      chips: [
        {
          type: 'forms',
          value: issueDetails.instance.id,
          label: issueDetails.instance.template.name,
        },
      ],
    });
    window.open(url, '_blank');
  }, [issueDetails, tenant]);

  const elReviewDetails = issueDetails?.instance && (
    <div css={styles.contentGroup}>
      <div css={styles.contentRow}>
        <Icons.dashboard fill={color.format(-0.5)} />
        <div css={styles.headerText}>
          <Text>{`${complianceFormLabel}: `}</Text>
          <Button onClick={openInstanceUrl}>
            <div css={styles.linkButton}>
              <Text color={COLORS.BLUE} cursor={'pointer'}>
                {issueDetails.instance.template.name}
              </Text>
              <Icons.launch />
            </div>
          </Button>
        </div>
      </div>
      <div css={styles.reviewFields}>
        {issueDetails.instance.subject && (
          <div css={styles.reviewField}>
            {issueDetails.instance.subject.type === 'Person' ? (
              <Icons.face
                fill={color.format(-0.5)}
                tooltip={`${complianceFormLabel} Person ${complianceFormSubjectLabel}`}
              />
            ) : (
              <Icons.group
                fill={color.format(-0.5)}
                tooltip={`${complianceFormLabel} Team ${complianceFormSubjectLabel}`}
              />
            )}
            <Text>{issueDetails.instance.subject.name}</Text>
          </div>
        )}
        <div css={styles.reviewField}>
          <Icons.info
            fill={color.format(-0.5)}
            tooltip={`${complianceFormLabel} Status`}
          />
          <Text>{issueDetails.instance.statusDisplayValue}</Text>
        </div>
        {issueDetails.instance.datesDisplayValue && (
          <div css={styles.reviewField}>
            <Icons.notifications
              fill={color.format(-0.5)}
              tooltip={`${complianceFormLabel} Dates`}
            />
            <Text>{issueDetails.instance.datesDisplayValue}</Text>
          </div>
        )}
      </div>
    </div>
  );

  const elIssueTitle = issueDetails?.label && (
    <div css={styles.contentGroup}>
      <div css={styles.contentRow}>
        <Icons.localLibrary
          fill={issueColor(issueDetails.issueCoachingRequired)}
          tooltip={issueCoachingRequirementText(
            issueDetails.issueCoachingRequired,
          )}
        />
        <div css={styles.headerText}>
          <Text color={issueColor(issueDetails.issueCoachingRequired)}>
            {'Issue: '}
          </Text>
          <Text>{issueDetails.label}</Text>
        </div>
      </div>
    </div>
  );

  const sectionName = issueDetails.formSectionName
    ? `*${str.titleCase(
        str.removeMarkdownAndSpecialCharacters(issueDetails.formSectionName),
      )}*`
    : '';

  const questionText = issueDetails.formQuestionText
    ? str.removeMarkdownAndSpecialCharacters(issueDetails.formQuestionText)
    : '';

  const questionTextLabel =
    questionText &&
    (questionText.endsWith('?') ? questionText : `${questionText}:`);

  const answerText = issueDetails.answer
    ? `**${issueDetails.answer.displayValue ?? issueDetails.answer.value}**`
    : '';

  const formQuestionText = [
    [sectionName, questionTextLabel].filter(Boolean).join(' -- '),
    answerText,
  ]
    .filter(Boolean)
    .join(' ');

  const elFormQuestion = (
    <div css={styles.contentGroup}>
      <div css={styles.contentRow}>
        <Icons.helpOutline
          fill={color.format(-0.5)}
          tooltip={`${complianceFormLabel} section, question, and response details`}
        />
        <div css={styles.headerText}>
          <Markdown text={formQuestionText} />
        </div>
      </div>
      {issueDetails.classifications?.map((classification, index) => (
        <Markdown
          key={`${issueDetails.id}-${index}`}
          style={styles.classificationText}
          text={`- ${classification}`}
        />
      ))}
    </div>
  );

  const elReviewerNotes = issueDetails.notes && (
    <div css={styles.contentGroup}>
      <div css={styles.contentRow}>
        <Icons.questionAnswerIcon
          fill={color.format(-0.5)}
          tooltip={'Reviewer Notes'}
          cursor={'pointer'}
        />
        <div css={styles.headerText}>
          <Markdown text={`Reviewer Notes: *${issueDetails.notes}*`} />
        </div>
      </div>
    </div>
  );

  return (
    <div css={styles.base}>
      {elIssueTitle}
      {elReviewDetails}
      {elFormQuestion}
      {elReviewerNotes}
    </div>
  );
};

const issueColor = (coachingRequired?: boolean) => {
  if (coachingRequired === undefined) return COLORS.COACHING_REQUIREMENT.NONE;

  return coachingRequired
    ? COLORS.COACHING_REQUIREMENT.MANDATORY
    : COLORS.COACHING_REQUIREMENT.RECOMMENDED;
};

const issueCoachingRequirementText = (coachingRequired?: boolean) => {
  if (coachingRequired === undefined) return 'Issue Identified';
  return coachingRequired
    ? 'Issue Coaching Required'
    : 'Issue Coaching Recommended';
};

const styles = {
  base: css({
    ...CommonStyles.BoxShadowGroup,
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
    padding: 12,
  }),
  contentGroup: css({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    ':hover': {
      backgroundColor: color.format(-0.06),
      padding: 10,
      margin: -10,
    },
  }),
  contentRow: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    alignItems: 'center',
  }),
  headerText: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    alignItems: 'center',
  }),
  reviewFields: css({
    display: 'flex',
    flexDirection: 'row',
    gap: '5%',
    paddingLeft: 40,
    alignItems: 'center',
  }),
  reviewField: css({
    display: 'flex',
    flexDirection: 'row',
    gap: 5,
    alignItems: 'center',
  }),
  linkButton: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
  }),
  classificationText: css({
    paddingLeft: 25,
  }),
};
