import { QueryOptions } from '@apollo/client';
import { IGraphQLClient } from '@seeeverything/ui.util/src/graphql/types.ts';
import gql from 'graphql-tag';
import * as R from 'ramda';
import { DashboardDateFilterSelection, IDataPayload } from '../types.ts';
import { NotFoundError } from './errors.ts';
import { metricsToDictionary } from './headerMetric.ts';
import { toTimespanVariable } from './util.ts';

export interface IPersonHeaderDetails {
  personId: string;
  templateId: string;
  dateFilter?: DashboardDateFilterSelection;
  options?: QueryOptions;
}

export async function personHeaderDetails(
  client: IGraphQLClient,
  options: IPersonHeaderDetails,
): Promise<IDataPayload> {
  const { personId, templateId, dateFilter, options: gqlOptions } = options;

  const response = await client.query<any>({
    fetchPolicy: 'network-only', // Default to network-only.
    ...gqlOptions,
    query: GqlPersonDetails,
    variables: {
      personId,
      templateId,
      timespan: toTimespanVariable(dateFilter),
    },
  });

  const header = response.data.dashboards.person.header;
  if (R.isNil(response.data.dashboards.person.header)) {
    throw new NotFoundError('person', personId);
  }

  return {
    pagination: {
      hasNextPage: false,
      currentPage: 1,
      totalCount: 0,
      pageSize: 0,
    },
    data: {
      name: header.name,
      description: header.positionTitle,
      path: header.primaryTeam?.team.path,
      personRole:
        header.primaryTeam?.role === 'Manager' ? 'Manager' : 'Team Member',
      kind: 'person',
      metrics: metricsToDictionary(header.metrics),
      team: header.primaryTeam?.team,
    },
  };
}

const GqlPersonDetails = gql`
  query DashboardPersonHeaderData(
    $personId: ID!
    $templateId: ID!
    $timespan: DashboardTimespanFilterInput
  ) {
    dashboards {
      person(id: $personId) {
        header(templateId: $templateId, timespan: $timespan) {
          id
          name
          positionTitle
          primaryTeam {
            team {
              id
              path
            }
            role
          }
          metrics {
            key
            value
          }
        }
      }
    }
  }
`;
