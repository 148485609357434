/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Icons } from '@seeeverything/ui.primitives/src/components/Icon/Icons.tsx';
import { Text } from '@seeeverything/ui.primitives/src/components/Text/Text.tsx';
import { Tooltip } from '@seeeverything/ui.primitives/src/components/Tooltip/Tooltip.tsx';
import { color } from '@seeeverything/ui.util/src/color/index.ts';
import { COLORS } from '@seeeverything/ui.util/src/constants/colors.ts';
import { propsAreEqualFastCompare } from '@seeeverything/ui.util/src/react/memoFastCompare.ts';
import { round } from '@seeeverything/ui.util/src/value/value.ts';
import { memo, useMemo } from 'react';
import { useDashboardsSelector } from '../../../redux/store.ts';
import {
  IDashboardContentMapping,
  IDashboardTrendMapping,
} from '../../../types.ts';

export interface IDashboardHeaderMetricsProps {
  isCollapsed: boolean;
}

const View: React.FC<IDashboardHeaderMetricsProps> = () => {
  const metricsTemplate = useDashboardsSelector(
    (state) => state.dashboardsV2.HEADER.metrics,
  );
  const metricsData = useDashboardsSelector(
    (state) => state.dashboardsV2.HEADER.dataState.data.data.metrics,
  );

  const splashMaskColor = useDashboardsSelector(
    (state) => state.tenantState.tenant.configuration.splash.maskColor,
  );

  const metrics = useMemo(() => {
    if (!metricsTemplate) return;
    if (!metricsData) return;

    return metricsTemplate
      .map((metric) => {
        const formatPercentage = metric.valueFormatter === 'percentage';

        const data = metricsData[metric.dataKey];
        const comparisonPeriod =
          metricsData[`${metric.dataKey}.comparison-period`];

        return {
          icon: metric.icon,
          dataKey: metric.dataKey,
          label: metric.label,
          shortValueText: shortValueText(
            data,
            formatPercentage,
            metric.precision,
          ),
          longValueText: longValueText(
            data,
            formatPercentage,
            metric.precision,
            metric.valueLabel,
          ),
          comparisonPeriod: longValueText(
            comparisonPeriod,
            formatPercentage,
            metric.precision,
            metric.valueLabel,
          ),
          trendMapping: trendMapping(data, comparisonPeriod, metric.trendMap),
          contentMapping: contentMapping(data, metric.contentMap),
        };
      })
      .reverse();
  }, [metricsData, metricsTemplate]);

  if (!metrics?.length) return;

  return (
    <div css={styles.base}>
      {metrics.map((metric) => {
        const Icon = metric.icon && Icons[metric.icon];
        const TrendIcon =
          metric.trendMapping?.icon && Icons[metric.trendMapping.icon];

        const elContent = (
          <div key={metric.dataKey} css={styles.metric}>
            {Icon && (
              <Icon
                size={18}
                fill={color
                  .create(splashMaskColor || COLORS.SEE_BRANDING.BLUE)
                  .alpha(0.7)
                  .hex()}
              />
            )}
            <div css={styles.metricLabels}>
              <Text uppercase={true} color={color.format(-0.8)} size={11}>
                {metric.label}
              </Text>
              <div css={styles.metricValueLabel}>
                {TrendIcon && (
                  <TrendIcon
                    style={{ margin: '-4px -5px -4px -8px' }}
                    fill={metric.trendMapping.color}
                  />
                )}
                <Text
                  color={metric.contentMapping?.color ?? color.format(-0.7)}
                  size={12}
                >
                  {metric.longValueText}
                </Text>
              </div>
            </div>
          </div>
        );

        const elTooltip = metric.trendMapping ? (
          <div css={css({ display: 'flex', flexDirection: 'column' })}>
            <Text size={14}>
              {[metric.label, metric.trendMapping.label]
                .filter(Boolean)
                .join(' - ')}
            </Text>
            <Text
              size={14}
              color={color.format(-0.6)}
            >{`Previous Period: ${metric.comparisonPeriod}`}</Text>
          </div>
        ) : undefined;

        return metric.trendMapping ? (
          <Tooltip
            key={`${metric.dataKey}-tooltip`}
            title={elTooltip}
            arrow={false}
            theme={'LIGHT'}
          >
            {elContent}
          </Tooltip>
        ) : (
          elContent
        );
      })}
    </div>
  );
};

const shortValueText = (
  value: string,
  formatPercentage: boolean,
  precision: number,
) => {
  if (value === undefined || value === '') return '-';

  const numberValue = Number(value);
  if (isNaN(numberValue)) return '-';

  if (!formatPercentage) return value;

  return numberValue === 0 ? `${value}%` : `${round(numberValue, precision)}%`;
};

const longValueText = (
  value: string,
  formatPercentage: boolean,
  precision: number,
  valueLabel: string,
) => {
  if (value === undefined || value === '') return '-';

  const numberValue = Number(value);
  if (isNaN(numberValue)) return '-';

  if (!formatPercentage) return `${value} ${valueLabel}`;
  return numberValue === 0
    ? `${value}% ${valueLabel}`
    : `${round(numberValue, precision)}% ${valueLabel}`;
};

const contentMapping = (
  value: string,
  mappings: IDashboardContentMapping[],
) => {
  if (value === undefined || value === '') return;
  if (!mappings?.length) return;

  const numberValue = Number(value);

  return mappings.find((mapping) => {
    if (mapping.maxValue !== undefined && numberValue >= mapping.maxValue)
      return false;

    if (
      mapping.maxValueInclusive !== undefined &&
      numberValue > mapping.maxValueInclusive
    )
      return false;

    if (mapping.minValue !== undefined && numberValue <= mapping.minValue)
      return false;

    if (
      mapping.minValueInclusive !== undefined &&
      numberValue < mapping.minValueInclusive
    )
      return false;

    if (mapping.equalsValue !== undefined) {
      const equalsValue = Array.isArray(mapping.equalsValue)
        ? mapping.equalsValue
        : [mapping.equalsValue];

      if (equalsValue.every((v) => v !== value)) return false;
    }

    return true;
  });
};

const trendMapping = (
  value: string,
  comparisonValue: string,
  mappings: IDashboardTrendMapping[],
) => {
  if (
    value === undefined ||
    value === '' ||
    comparisonValue === undefined ||
    comparisonValue === ''
  )
    return;

  if (!mappings?.length) return;

  const numberValue = Number(value);
  const numberComparisonValue = Number(comparisonValue);

  if (isNaN(numberValue) || isNaN(numberComparisonValue)) return;

  const trend = numberValue - numberComparisonValue;
  return mappings.find((mapping) => {
    if (mapping.maxValue !== undefined && trend >= mapping.maxValue)
      return false;

    if (
      mapping.maxValueInclusive !== undefined &&
      trend > mapping.maxValueInclusive
    )
      return false;

    if (mapping.minValue !== undefined && trend <= mapping.minValue)
      return false;

    if (
      mapping.minValueInclusive !== undefined &&
      trend < mapping.minValueInclusive
    )
      return false;

    return true;
  });
};

const styles = {
  base: css({
    display: 'flex',
    flexDirection: 'row-reverse',
    flexWrap: 'wrap-reverse',
    alignItems: 'center',
    padding: 13,
    gap: 20,
  }),
  metric: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 5,
    margin: '-8px -10px -6px -10px',
    padding: '8px 10px 6px 10px',
    borderRadius: 3,
    ':hover': {
      backgroundColor: color.format(-0.05),
    },
  }),
  metricLabels: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  metricValueLabel: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  }),
};

export const DashboardHeaderMetrics = memo(View, propsAreEqualFastCompare);
