import { SheetType } from '@seeeverything/ui.shell/src/redux/sheets/index.ts';

export const queryPathMap: { [key: string]: IQueryPath } = {
  gridV2: {
    path: 'V2_GRID',
    matchPattern: /gridV2:[^/]*$/,
  },
  goalsActionsGrid: {
    path: 'GOALS_ACTIONS_GRID',
    matchPattern: /goalsActionsGrid:[^/]*$/,
  },
  commentsList: {
    path: 'COMMENTS_LIST',
    matchPattern: /commentsList:[^/]*$/,
  },
  'forms-auditLog': {
    path: 'FORM_AUDIT_LOG',
    matchPattern: /forms:[^/]*\/auditLog:[^/]*$/, // Matches /forms:*/auditLog at the end.
  },
  forms: {
    path: 'FORM',
    matchPattern: /forms:[^/]*$/, // Matches if forms is the last chip.
  },
  people: {
    path: 'COACHING_DASHBOARD_PERSON',
    hasDashboard: true,
    matchPattern: /people:[^/]*$/, // Matches if people is the last chip.
  },
  team: {
    path: 'COACHING_DASHBOARD_TEAM',
    hasDashboard: true,
    matchPattern: /team:[^/]*$/, // Matches if team is the last chip.
  },
  'settings-schedules': {
    path: 'SETTINGS_SCHEDULES',
    matchPattern: /settings:schedules$/, // Matches /settings:schedules at the end.
  },
  'settings-automatedActions': {
    path: 'SETTINGS_AUTOMATED_ACTIONS',
    matchPattern: /settings:automatedActions$/, // Matches /settings:automatedActions at the end.
  },
  'formDesigner-templates': {
    path: 'FORMS_DESIGNER_LIST',
    matchPattern: /settings:formsDesignerTemplates$/, // Matches /settings:formTemplates at the end.
  },
  formDesigner: {
    path: 'FORMS_DESIGNER',
    matchPattern: /formsDesignerTemplate:[^/]*$/, // Matches /formDesigner:* at the end.
  },
  formsBulkUpload: {
    path: 'FORMS_BULK_UPLOAD_HISTORY',
    matchPattern: /settings:formsBulkUpload$/, // Matches /settings:formsBulkUpload at the end.
  },
  bulkUploadSucceeded: {
    path: 'BULK_UPLOAD_CLICK_THROUGH_GRID',
    matchPattern: /bulkUploadSucceeded:[^/]*$/,
  },
  bulkUploadFailed: {
    path: 'BULK_UPLOAD_CLICK_THROUGH_GRID',
    matchPattern: /bulkUploadFailed:[^/]*$/,
  },
  dashboardClickThroughGrid: {
    path: 'DASHBOARD_CLICK_THROUGH_GRID',
    matchPattern: /clickThroughGrid:[^/]*$/,
  },
};

export interface IQueryPath {
  path: SheetType;
  matchPattern: RegExp;
  hasDashboard?: boolean;
}
